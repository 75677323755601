// https://github.com/kontentino/react-multilevel-dropdown/tree/master/src

import React, {
	useState, useRef, useEffect, useCallback
  } from 'react';
  import './index.css';
  import PropTypes from 'prop-types';
//   import classes from 'react-style-classes';
  import Item from './Item';
  import Submenu from './Submenu';
  import Divider from './Divider';
//   import './styles.scss';

  import './index.css';
import Title from './Title';

  const CusDropdown =
	({
		children = null,
		isDisabled = false,
		title = null,
		className = null,
		style = null,
		...props
	}) => {

		return (
			<div
				className={"cus-dropdown-menu " + (className ? className : "")}
				style={style}
			>
				{/* <ul className='cus-dropdown-ul'> */}
					{children}
				{/* </ul> */}
			</div>

		);
	
	}
  
  CusDropdown.propTypes = {
		title: PropTypes.any,
		children: PropTypes.node,
		isDisabled: PropTypes.bool,
		className: PropTypes.string,
		style: PropTypes.object,
  };

  CusDropdown.Item = Item;
  CusDropdown.Title = Title;
  CusDropdown.Submenu = Submenu;
  CusDropdown.Divider = Divider;
  
  export default CusDropdown;