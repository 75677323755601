import DownArrow from 'components/Icon/DownArrow';
import PropTypes from 'prop-types';
import React from 'react';

const Size = Object.freeze(
    {
        "none" : "",
        "max": " w-full",
    }
)


const Tab = ({
    name = "",
    isMenu = false,
    children = null,
    size = Size.max,
    className = "",
    active = false,
    disabled = false,
    onClick = null,
    activeTabStyle = "border-blue04",
    activeChild = "text-blue04 font-medium",
    inactiveChild = "text-grey06",
    childUnderLine = false,
    ...rest
}) => (
    <button title={name} className={`flex justify-center items-center ${size} h-full truncate ${className}`}
        onClick={disabled ? null : onClick}
        {...rest}
    >
        <span className={`flex justify-center items-baseline text-sm w-full h-full border-b-4 truncate
            ${active ? `${activeTabStyle} ${activeChild}` : `border-white ${disabled ? "" : `hover:${activeTabStyle}`} ${inactiveChild}`}`}
        >
            {childUnderLine ?
                children
                :
                <>
                    <span className={` flex items-center justify-center w-full h-full truncate`}>
                        <span className="truncate">{name}</span>                
                    </span>
                    {isMenu ? 
                        <div className='pl-1 text-greyblack'><DownArrow className="h-2 w-2"/></div>
                        : null
                    }
                </>
            }
        </span>
        {childUnderLine ? null : children}
    </button>
)


Tab.propTypes = {
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    isMenu: PropTypes.bool,
    children: PropTypes.node,
    size: PropTypes.string,
    className: PropTypes.string,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    activeTabStyle: PropTypes.string,
    activeChild: PropTypes.string,
    inactiveChild: PropTypes.string,
};

export { Tab, Size};