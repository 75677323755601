import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './index.css';
// import classes from 'react-style-classes';
// import './styles.scss';

const Title = ({
  children = null,
  unstyled = false,
  className = null,
  ...props
}) =>{
	
	return (
	  <li
      className={unstyled ? "cus-dropdown-title-unstyle" : "cus-dropdown-title"}
      onClick={(e) => e.stopPropagation()}
      onContextMenu={(e) => e.stopPropagation()}      
      {...props}
	  >
		  {children}
	  </li>
	);
	
}

Title.propTypes = {
  children: PropTypes.node,
  unstyled: PropTypes.bool,
  className: PropTypes.string,
};

export default Title;