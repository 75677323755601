
import LeftArrow from 'components/Icon/LeftArrow';
import RightArrow from 'components/Icon/RightArrow';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import DynamicContent from 'components/DynamicContent';
import CusGroupDropdown from 'components/CusGroupDropdown';

import TextMenu from 'components/TextMenu';
import Tick from 'components/Icon/Tick';
import { useIntl } from 'react-intl';
import { tablePrefix } from 'lang/locales/prefix';

const Footer = ({
    children = null,
    pageOption = 10,
    onSelectPageOption = null,
    onPrev = null,
    onNext = null,
    hasPrev = true,
    hasNext = true,
}) => {

    const intl = useIntl();

    const [showMenu, setShowMenu] = useState(false);
    const [menuPos, setMenuPos] = useState(null);

    return (
        children ?
                children
            :
                <>
                    <>
                        <div className="flex items-center text-grey07 text-sm pl-4">
                            <div className="pl-4">{intl.formatMessage({ "id": `${tablePrefix}.footer.show`})}</div>
                                <TextMenu
                                    className="flex justify-center items-baseline cursor-pointer px-4"
                                    onClick={(e) => {
                                        let pos = e.target.closest('.menu-pos').getBoundingClientRect()
                                        setMenuPos({x: pos.left + 5, y: pos.top + 5})
                                        setShowMenu(true)
                                    }}
                                    title={`${pageOption}`}
                                />
                            <div className="pr-4">{intl.formatMessage({ "id": `${tablePrefix}.footer.records`})}</div>
                        </div>
                        <div className="flex items-center pr-4">
                            <div className={`flex text-blue04  justify-center items-baseline px-4 ${hasPrev ? "cursor-pointer" : "opacity-50"}`} onClick={hasPrev ? onPrev : () => {}}>
                                <div className={`px-2`}><LeftArrow/></div>
                                <div className="text-sm">{intl.formatMessage({ "id": `${tablePrefix}.footer.button.prev`})}</div>
                            </div>
                            <div className={`flex text-blue04  justify-center items-baseline px-4 ${hasNext ? "cursor-pointer" : "opacity-50"}`} onClick={hasNext ? onNext : () => {}}>
                                <div className="text-sm">{intl.formatMessage({ "id": `${tablePrefix}.footer.button.next`})}</div>
                                <div className={`px-2`}><RightArrow/></div>
                            </div>
                        </div>
                    </>
                    <DynamicContent screenX={menuPos ? menuPos.x : undefined} screenY={menuPos ? menuPos.y : -1} show={showMenu} setShow={setShowMenu} onUnFocus={() => setMenuPos(null)}>
                        <CusGroupDropdown
                                cardSize="w-[125px] h-auto"
                                contentSize="max-h-[45vh] overflow-auto"
                            >
                                <>
                                    <CusGroupDropdown.Item
                                        className="justify-between"
                                        onClick={(e) => {
                                            onSelectPageOption(10)
                                            setMenuPos(null)
                                            setShowMenu(false)
                                        }}
                                    >
                                        <span className='mx-3 text-sm truncate'>10</span>
                                        {pageOption === 10 ?
                                            <span className='text-blue04 mx-2 text-sm'><Tick/></span>
                                            :
                                            null
                                        }
                                    </CusGroupDropdown.Item>
                                    <CusGroupDropdown.Item
                                        className="justify-between"
                                        onClick={(e) => {
                                            onSelectPageOption(25)
                                            setMenuPos(null)
                                            setShowMenu(false)
                                        }}
                                    >
                                        <span className='mx-3 text-sm truncate'>25</span>
                                        {pageOption === 25 ?
                                            <span className='text-blue04 mx-2 text-sm'><Tick/></span>
                                            :
                                            null
                                        }
                                    </CusGroupDropdown.Item>
                                    <CusGroupDropdown.Item
                                        className="justify-between"
                                        onClick={(e) => {
                                            onSelectPageOption(50)
                                            setMenuPos(null)
                                            setShowMenu(false)
                                        }}
                                    >
                                        <span className='mx-3 text-sm truncate'>50</span>
                                        {pageOption === 50 ?
                                            <span className='text-blue04 mx-2 text-sm'><Tick/></span>
                                            :
                                            null
                                        }
                                    </CusGroupDropdown.Item>
                                </>   
                        </CusGroupDropdown>
                    </DynamicContent>
                </>
    )
}

Footer.propTypes = {
    children: PropTypes.node,
    pageOption: PropTypes.number,
    onSelectPageOption: PropTypes.func,
    onPrev: PropTypes.func,
    onNext: PropTypes.func,
    hasPrev: PropTypes.bool,
    hasNext: PropTypes.bool,
};

export default Footer;