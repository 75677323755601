import CusEditable, { EDITABLE_TYPE, setDefaultField, validateField } from 'components/CusEditable';
import CusEditableLeftLabel from 'components/CusEditable/CusEditableLeftLabel';
import CusModal from 'components/CusModal';
import { Color as ButtonColor, CusRoundedButton, PaddingType as ButtonPadding, RoundType as ButtonRoundType } from 'components/CusRoundedButton';
import ExpanableListItem from 'components/ExpanableListItem';
import Bin from 'components/Icon/Bin';
import Cross from 'components/Icon/Cross';
import Plus from 'components/Icon/Plus';
import { useLanguage } from 'hooks/useLanguage';
import { errorPrefix, generalPrefix, settingPrefix, editChatbotPrefix, editKeywordPrefix } from 'lang/locales/prefix';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useIntl } from 'react-intl';
import { capitalise, checkListItemDuplication } from 'util/helper';
import { addFieldItemAction, removeFieldItemAction, updateFieldItemAction } from 'util/helper';

const KEYWORD_SETUP_FIELD_TYPE = [
    {
        "fieldName": "name",
        "displayNameKey": `${settingPrefix}.${editKeywordPrefix}.field.setup.name`,
        "displayNameEn": "Name",
        "displayNameTc": "關鍵詞名稱",
        "displayNameSc": "关键字名称",
        "fieldType": EDITABLE_TYPE.TEXT,
        "required": true,
        "editable": true,
        "creatable": true,
    },
]

const KEYWORD_VALUE_FIELD_TYPE = {
    "fieldName": "value",
    "displayNameKey": `${settingPrefix}.${editKeywordPrefix}.field.setup.list`,
    "displayNameEn": "Keyword",
    "displayNameTc": "關鍵詞",
    "displayNameSc": "关键词",
    "fieldType": EDITABLE_TYPE.TEXT,
    "required": true,
    "editable": true,
    "creatable": true,
}

const KEYWORD_VALUE_FIELD_TEMPLATE = Object.freeze({
    "value" : "",
})


const EditKeyword = ({
    isEditable = false,
    isEdit = false,
    setIsEdit = () => {},
    item = null,
    updateAction = null,
    removeAction = null
}) => {

    const [errorMap, setErrorMap] = useState({});
    const [editableKeyword, setEditableKeyword] = useState({})

    const [keywordList, setKeywordList] = useState([])
    const [keywordErrorList, setKeywordErrorList] = useState([]);

    const intl = useIntl();
    const { language } = useLanguage()

    const getOptions = (fieldType, fieldName, options) => {
        if(fieldType !== EDITABLE_TYPE.PICKLIST)
            return []

        return options.map(option => ({label: option, value: option}))
    }

    const onChangeHandler = (isCreate, field, e) => {
        
        let value = e.target.value;
        let errorMap = {};

        switch (field.fieldType) {
            case EDITABLE_TYPE.BOOLEAN:
                value = !editableKeyword[field.fieldName]
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;        
            default:
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;
        }

        if(errorMap && Object.keys(errorMap).length > 0)
            setErrorMap((erMap) => ({...erMap, ...errorMap}))
        else{
            setErrorMap((erMap) => {
                let newErrorMap = {...erMap}
                delete newErrorMap[field.fieldName]
                return newErrorMap 
            })
        }

        setEditableKeyword((editableFields) => ({...editableFields, [field.fieldName]: value}))
    }

    const onKeywordListItemChange = (isCreate, field, index, e) => {
        let value = e.target.value;
        updateKeyword({value: value}, validateField(field, value, intl.formatMessage, isCreate), index)
    }

    const addKeyword = () => addFieldItemAction({itemTemplate: KEYWORD_VALUE_FIELD_TEMPLATE, setFieldList: setKeywordList, setFieldErrorList: setKeywordErrorList})
    const updateKeyword = (field, error, index) => updateFieldItemAction({field, error, index, fieldList: keywordList, fieldErrorList: keywordErrorList, setFieldList: setKeywordList, setFieldErrorList: setKeywordErrorList})
    const removeKeyword = (index) => removeFieldItemAction({index, setFieldList: setKeywordList, setFieldErrorList: setKeywordErrorList})

    const validateKeywordListItems = (isCreate) => {
        let errorList = [];
    
        for(let index = 0; index < keywordList.length; index++){
            let conditionListItem = {...keywordList[index]}
            let errorMap = {}
    
            let mapping = [{...KEYWORD_VALUE_FIELD_TYPE}]
    
            for(let field of mapping){
                if(!conditionListItem[field.fieldName])
                    setDefaultField(field, conditionListItem, field.fieldName, isCreate)
                errorMap = {...errorMap, ...validateField(field, conditionListItem[field.fieldName], intl.formatMessage, isCreate)}
            }
    
            errorList[index] = errorMap
        }

        let keywordSet = keywordList.map(field => field.value)

        let keywordSetDuplicates = checkListItemDuplication(keywordSet)
        console.log(keywordSetDuplicates)
        keywordSetDuplicates.forEach(i => {
            if(!errorList[i])
                errorList[i] = {}
    
            errorList[i].value = { message: intl.formatMessage({ "id": `${errorPrefix}.duplicates` }) }
        })
    
        return errorList
    }

    const validateFields = (isCreate) => {
        let errorMap = {}

        let mapping = [ ...KEYWORD_SETUP_FIELD_TYPE ]

        for(let field of mapping){
            if(!editableKeyword[field.fieldName])
                setDefaultField(field, editableKeyword, field.fieldName, isCreate)
            errorMap = {...errorMap, ...validateField(field, editableKeyword[field.fieldName], intl.formatMessage, isCreate)}
        }

        let keywordErrorList = validateKeywordListItems(isCreate)

        let hasError = false;

        if(errorMap && Object.keys(errorMap).length > 0)
            hasError = true;

        console.log(keywordErrorList)
        
        if(keywordErrorList.filter(error => Object.keys(error).length > 0).length > 0)
            hasError = true;

        if(hasError){
            console.log(errorMap)
            setKeywordErrorList(keywordErrorList)
            setErrorMap(errorMap)
            return;
        }

        let completeObject = {...editableKeyword}
        completeObject.keywordList = keywordList.map(keyword => keyword.value)

        updateAction(completeObject)
    }

    const refresh = () => {
        let editableKeyword = JSON.parse(JSON.stringify(item))

        console.log(editableKeyword)

        let keywordList = [];

        if(!!editableKeyword.keywordList && editableKeyword.keywordList.length > 0)
            keywordList = editableKeyword.keywordList.map(keyword => ({value: keyword}))

        console.log(keywordList)

        if(keywordList.length < 1){
            setKeywordList([{...KEYWORD_VALUE_FIELD_TEMPLATE}])
            setKeywordErrorList([{}])
        }
        else{
            setKeywordList(keywordList)
            setKeywordErrorList(Array(keywordList.length).fill({}))
        }

        setEditableKeyword(editableKeyword)
        setErrorMap({})

        setIsEdit(isEditable && !item.id)
    }

    useEffect(() => {
        refresh()
    }, [item])

    return (
        <div className="w-full h-full">
            <div className='w-full'><hr/></div>
            <div className={`w-full ${isEditable ? "h-[calc(100%-72px)]" : "h-full"}`}>
                <Scrollbars
                    className="w-full h-full"
                    autoHide={false}
                    autoHideDuration={100}
                >
                    <div className='w-full h-full flex flex-col'>
                        <span className='px-4 pt-[18px] text-[#64748B]'>DETAILS</span>
                        <div className='px-4'>
                            {KEYWORD_SETUP_FIELD_TYPE.map((field) => (
                                <div key={field.fieldName} className='py-2'>
                                    <CusEditableLeftLabel
                                        readOnly={!isEdit || !isEditable || (!!item.id ? !field.editable : !field.creatable)}
                                        fieldType={field.fieldType}
                                        label={field.displayNameEn}
                                        value={editableKeyword[field.fieldName] ?? field.defaultValue}
                                        options={getOptions(field.fieldType, field.fieldName, field.options)}
                                        errors={errorMap}
                                        required={field.required}
                                        errorName={field.fieldName}
                                        onChange={(e) => onChangeHandler(item.isCreate, field, e)}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className='w-full'><hr/></div>
                        <span className='px-4 pt-[18px] text-[#64748B]'>SETUP</span>
                        <div className='p-4'>
                            {isEdit &&
                                <div className="flex flex-col">
                                    <div className="flex w-full items-center">
                                        <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex text-base h-[40px] items-center'
                                            color={ButtonColor.primaryNoBorder}
                                            onClick={() => addKeyword()}
                                        >
                                            <Plus/>
                                            <span className='pl-2 text-base'>{intl.formatMessage({ "id": `${settingPrefix}.${editKeywordPrefix}.button.addkeyword` })}</span>
                                        </CusRoundedButton>
                                    </div>
                                    {keywordList ?
                                        keywordList.map((field, index) => (
                                            <div
                                                key={`${KEYWORD_VALUE_FIELD_TYPE.fieldName}-${index}`}
                                                className="flex items-center"
                                            >
                                                <div className="py-[8px] w-full flex justify-between ">
                                                    <div title={`${index + 1}`.padStart(2, '0')} className={`w-[30px] h-[40px] flex items-center truncate overflow-hidden`}>
                                                        <span className="pt-1 text-[13px] text-[#94A3B8] truncate">{`${index + 1}`.padStart(2, '0')}</span>
                                                    </div>
                                                    <div className='w-[calc(100%-30px-30px)]'>
                                                        <CusEditable
                                                            readOnly={!isEdit || !isEditable || (!!item.id ? !KEYWORD_VALUE_FIELD_TYPE.editable : !KEYWORD_VALUE_FIELD_TYPE.creatable)}
                                                            fieldType={KEYWORD_VALUE_FIELD_TYPE.fieldType}
                                                            value={field[KEYWORD_VALUE_FIELD_TYPE.fieldName]}
                                                            errors={(!!keywordErrorList && keywordErrorList[index]) ? keywordErrorList[index] : {}}
                                                            required={KEYWORD_VALUE_FIELD_TYPE.required}
                                                            errorName={KEYWORD_VALUE_FIELD_TYPE.fieldName}
                                                            onChange={(e) => onKeywordListItemChange(!item.id, KEYWORD_VALUE_FIELD_TYPE, index, e)}
                                                        />
                                                    </div>
                                                    {(!isEdit || !isEditable) ?
                                                        null
                                                        :
                                                        <div className="w-[30px] h-[40px] flex justify-center items-center">
                                                            <div className={`flex justify-center items-center text-[#94A3B8] ${index === 0 ? "" : "cursor-pointer"} `}><div className={`${index === 0 ? "opacity-50" : "cursor-pointer"}`} onClick={index === 0 ? () => {} : () => removeKeyword(index)}><Cross className="w-[11px] h-[11px]"/></div></div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>                       
                                        ))
                                        :
                                        null
                                    }
                                </div>
                            }
                            {!isEdit && keywordList.length > 0 &&
                                <div className='px-[10px] py-2 w-full flex flex-col border-[#CBD5E1] border-[1px] rounded-[8px]'>
                                    <div className='flex flex-wrap w-full'>
                                        {keywordList && keywordList.map((field, index) => (
                                            <div
                                                title={field[KEYWORD_VALUE_FIELD_TYPE.fieldName]}
                                                key={`${KEYWORD_VALUE_FIELD_TYPE.fieldName}-${index}`}
                                                className="m-1 flex px-3 max-w-[100px] h-[26px] truncate overflow-hidden items-center rounded-[50px] bg-[#E2E8F0] text-[#94A3B8] text-base"
                                            >
                                                <span className='truncate'>{field[KEYWORD_VALUE_FIELD_TYPE.fieldName]}</span>
                                            </div>                       
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </Scrollbars>
            </div>
            {isEditable && 
                isEdit ?
                    <div className="p-4 w-full flex justify-center items-center h-[72px] border-t-[1px] border-[#CBD5E140]">
                            <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex justify-center text-base flex-1 px-4 h-[40px] items-center rounded-[5px]' color={ButtonColor.primary}
                                    onClick={() => validateFields(!item.id)}
                            >
                                    <span className="px-4">{!item.id ? "Create" : "Update"}</span>
                            </CusRoundedButton>
                    </div>
                :
                    <div className="p-4 w-full flex justify-between items-center h-[72px] border-t-[1px] border-[#CBD5E140]">
                            <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex justify-center text-base flex-shrink-0 px-4 h-[40px] items-center rounded-[5px]' color={ButtonColor.darkNoBorder}
                                    onClick={() => removeAction()}
                            >
                                    <span className="">Delete</span>
                            </CusRoundedButton>
                            <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex justify-center text-base flex-shrink-0 px-4 h-[40px] items-center rounded-[5px]' color={ButtonColor.primary}
                                    onClick={() => setIsEdit(true)}
                            >
                                    <span className="">Edit</span>
                            </CusRoundedButton>
                    </div>
            }
        </div>
    )
}

EditKeyword.propTypes = {
    isEditable: PropTypes.bool,
    isEdit: PropTypes.bool,
    setIsEdit: PropTypes.func,
    item: PropTypes.object,
    updateAction: PropTypes.func,
    removeAction: PropTypes.func
};

export default EditKeyword;

