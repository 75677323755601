import { errorPrefix } from "lang/locales/prefix";
import { useIntl } from "react-intl";

const NoPermission = () => {
    const intl = useIntl();
    
    return (
        <div className="flex justify-center items-center w-full h-full p-4 text-2xl">
            {intl.formatMessage({ "id": `${errorPrefix}.permission` })}
        </div>
    )
}

export default NoPermission;