// import CusDropdown from 'components/CusDropdown';
// import DynamicMenu from 'components/CusDropdown/DynamicMenu';
import DynamicContent from 'components/DynamicContent';
import CusGroupDropdown from 'components/CusGroupDropdown';
import Cancel from 'components/Icon/Cancel';
import Flag from 'components/Icon/Flag';
import FlagFilled from 'components/Icon/FlagFilled';
import MessageDelivered from 'components/Icon/Message/MessageDelivered';
import MessageSent from 'components/Icon/Message/MessageSent';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getChatFormatedTime, MESSAGE_STATUS, EXTENDED_MESSAGE_TYPE } from 'util/helper';
import AudioMessage from './MediaMessage/AudioMessage';
import ContactMessage from './MediaMessage/ContactMessage';
import FileMessage from './MediaMessage/FileMessage';
import ImageMessage from './MediaMessage/ImageMessage';
import LocationMessage from './MediaMessage/LocationMessage';
import StickerMessage from './MediaMessage/StickerMessage';
import TextMessage from './MediaMessage/TextMessage';
import VideoMessage from './MediaMessage/VideoMessage';
import { MESSAGE_CONTAINER_TYPE } from './util';
import { useIntl } from 'react-intl';
import { chatpanelConversationsPrefix, chatpanelPrefix, generalPrefix } from 'lang/locales/prefix';
import { WarningSnackbar } from 'components/WarningSnackbar';
import DownArrow from 'components/Icon/DownArrow';


const ChatMessage = ({
    name = "",
    timestamp = 0,
    status = "",
    templateMessage = "",
    messageId = "",
    message = "",
    messageType = EXTENDED_MESSAGE_TYPE.TEXT,
    mimeType = "",
    messageMedia = null,
    downloading = false,
    downloadDisabled = false,
    containerType = MESSAGE_CONTAINER_TYPE.AGENT,
    containerSize = "max-w-[75%]",
    onClick = null,
    onContextMenu = () => {},
    isBookmarked = false, 
    readOnlyBookmark = false, 
    bookmarkAction = null,
    active = false,
}) => {

    const intl = useIntl();

    const [showMenu, setShowMenu] = useState(false);
    const [menuPos, setMenuPos] = useState(null);
    const [defaultPos, setDefaultPos] = useState(null);

    const [warningOpen, setWarningOpen] = useState(false);
    const [warning, setWarning] = useState("");

    const MENU_OPTION_ACTION_NAME = Object.freeze({
        "COPY": intl.formatMessage({ "id": `${generalPrefix}.button.copy`}),
        "PIN": intl.formatMessage({ "id": `${chatpanelPrefix}.${chatpanelConversationsPrefix}.nameList.optionMenu.pin`}),
        "UNPIN": intl.formatMessage({ "id": `${chatpanelPrefix}.${chatpanelConversationsPrefix}.nameList.optionMenu.unPin`}),
    })
    
    const copyAction = (text) => {
        navigator.clipboard.writeText(text)
        setWarning(intl.formatMessage({ "id": `${generalPrefix}.notice.copy`}))
        setWarningOpen(true)
    }

    let blockClass = "";
    let containerClass = "";
    let bookmarkClass = "";
    
    if(containerType === MESSAGE_CONTAINER_TYPE.CUSTOMER){
        blockClass = "justify-start"
        containerClass = `${active ? "active-message font-semibold bg-blue04 after:border-r-blue04 text-white": "bg-blue06 after:border-r-blue06"} rounded-br-md  after:border-r-[10px] after:-left-[10px]`
        bookmarkClass = "-right-14"
    }
    else {
        blockClass = "justify-end"
        containerClass = `${active ? "active-message font-semibold bg-blue04 after:border-l-blue04 text-white": "bg-grey02 after:border-l-grey02"} rounded-bl-md  after:border-l-[10px] after:-right-[10px]`
        bookmarkClass = "-left-14"
    }

    let msgStsContent = null;
    let content = null;

    switch (status) {
        case MESSAGE_STATUS.DELIVERED:
            msgStsContent = <MessageDelivered/>
            break;
        case MESSAGE_STATUS.SENT:
            msgStsContent = <MessageSent/>
            break;
        case MESSAGE_STATUS.FAILED:
            msgStsContent = <div className="text-[#A90014]"><Cancel className="w-4 h-4"/></div>
            break;
        default:
            msgStsContent = null
            break;
    }

    switch (messageType) {
        case EXTENDED_MESSAGE_TYPE.IMAGE:
            content = <ImageMessage
                            downloading={downloading}
                            disabled={downloadDisabled}
                            messageId={messageId}
                            message={message}
                            messageMedia={messageMedia}
                            onClick={onClick}
                            onContextMenu={onContextMenu}
                            containerType={containerType}
                        />
            break;
        case EXTENDED_MESSAGE_TYPE.AUDIO:
            content = <AudioMessage
                            downloading={downloading}
                            disabled={downloadDisabled}
                            messageId={messageId}
                            message={message}
                            messageMedia={messageMedia}
                            onClick={onClick}
                            onContextMenu={onContextMenu}
                            containerType={containerType}
                        />
            break;
        case EXTENDED_MESSAGE_TYPE.VIDEO:
            content = <VideoMessage
                            downloading={downloading}
                            disabled={downloadDisabled}
                            messageId={messageId}
                            message={message}
                            messageMedia={messageMedia}
                            onClick={onClick}
                            onContextMenu={onContextMenu}
                            containerType={containerType}
                        />
            break;
        case EXTENDED_MESSAGE_TYPE.FILE:
            content = <FileMessage
                            downloading={downloading}
                            disabled={downloadDisabled}
                            messageId={messageId}
                            message={message}
                            messageMedia={messageMedia}
                            mimeType={mimeType}
                            onClick={onClick}
                            onContextMenu={onContextMenu}
                            containerType={containerType}
                        />
            break;
        case EXTENDED_MESSAGE_TYPE.LOCATION:
            content = <LocationMessage message={message} />
            break;
        case EXTENDED_MESSAGE_TYPE.STICKER:
            content = <StickerMessage/>
            break;
        case EXTENDED_MESSAGE_TYPE.CONTACT:
            content = <ContactMessage
                            messageId={messageId}
                            message={message}
                            onClick={onClick}
                            onContextMenu={onContextMenu}
                        />
            break;
        case EXTENDED_MESSAGE_TYPE.TEMPLATE:
            content = <div className={`w-full whitespace-pre-wrap`}>{templateMessage}</div>
            break;
        case EXTENDED_MESSAGE_TYPE.TEXT:
            content = <TextMessage message={message} />
            break;
        default:
            content = <TextMessage message={"Unknown"} />
            break;
    }

    let canOpenActionMenu = !!bookmarkAction || messageType === EXTENDED_MESSAGE_TYPE.TEXT

    return (
        (containerType === MESSAGE_CONTAINER_TYPE.SYSTEM || containerType === MESSAGE_CONTAINER_TYPE.DATE) ?
            <div className={`flex w-full justify-center`}>
                <div className={`p-3 ${containerSize} text-center text-sm text-grey07`}>
                    <TextMessage message={message} />
                </div>
            </div>
            :
            <>
                <WarningSnackbar message={warning} open={warningOpen} setOpen={setWarningOpen}/>            
                <div className={`group flex w-full items-center ${blockClass}`}>
                    <div className={`group ${containerSize} px-2 flex-col`}>
                        <div
                            className={`relative flex ${blockClass}`}            
                        >
                            <div
                                className={`w-full flex items-center min-w-[10px] p-3 break-words rounded-t-md after:absolute after:w-0 after:h-0 after:border-transparent after:content-[""] after:top-auto after:bottom-0 after:border-t-[10px] ${containerClass}`}
                            >
                                <div className={`${canOpenActionMenu ? "w-[calc(100%-18px)]" : "w-full"}`}>
                                    {content}
                                </div>
                                {canOpenActionMenu ? 
                                    <div
                                        className={`pl-2 ${active ? "text-white" : ""} ${(canOpenActionMenu) ? "msg-menu-pos cursor-pointer" : ""}`}
                                        onClick={(e) => {
                                            let pos = e.target.closest('.msg-menu-pos').parentNode.getBoundingClientRect()
                                            setMenuPos({x: containerType === MESSAGE_CONTAINER_TYPE.CUSTOMER ? pos.left : pos.right, y: pos.y})
                                            setDefaultPos({ hPos: containerType === MESSAGE_CONTAINER_TYPE.CUSTOMER ? "Right" : "Left", vPos: "Top" })
                                            setShowMenu(true)                                    
                                        }}                                    
                                    >
                                        <DownArrow/>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            {/* {bookmarkAction ?
                                <div onClick={bookmarkAction ?
                                    (e) => {
                                        e.preventDefault();
                                        setMenuPos({x: e.clientX, y: e.clientY})
                                        setShowMenu(true)
                                    } : null
                                } title="Bookmark" className={`absolute top-1/2 -translate-y-1/2 ${bookmarkClass} hidden group-hover:block group-focus:block cursor-pointer px-4 disabled:text-[#AFAFAF]`}>{isBookmarked ? <FlagFilled/> : <Flag/>}</div>
                                : null
                            } */}
                            {/* {(readOnlyBookmark && isBookmarked) || bookmarkAction ?
                                <div
                                    title="Bookmark" className={`absolute top-1/2 -translate-y-1/2 ${bookmarkClass} px-4 disabled:text-[#AFAFAF]`}>{isBookmarked ? <FlagFilled/> : <Flag/>}</div>
                                : null
                            } */}
                        </div>
                        {/* <div className={`flex px-2 py-2 text-grey07 text-xs ${blockClass}`}> */}
                        <div className={`flex pl-2 py-2 text-grey07 text-xs justify-end`}>
                            {name ?
                                <div>{name}</div>
                                : null
                            }
                            {timestamp ?
                                <div className={`pl-2 font-light`}>{getChatFormatedTime(timestamp)}</div>
                                : null
                            }
                            {isBookmarked ?
                                <div className={`pl-1 disabled:text-[#AFAFAF]`}><Flag className="w-[16px] h-[16px]"/></div>
                                : null
                            }                            
                            {status ?
                                <div className={"pl-1"}>{msgStsContent}</div>
                                : null
                            }
                        </div>
                    </div>
                    <DynamicContent defaultPos={showMenu ? defaultPos : null} screenX={menuPos ? menuPos.x : undefined} screenY={menuPos ? menuPos.y : -1} show={showMenu} setShow={setShowMenu} onUnFocus={() => setMenuPos(null)}>
                        <CusGroupDropdown cardSize="w-[160px] h-auto">
                            <>
                                {messageType === EXTENDED_MESSAGE_TYPE.TEXT ? 
                                        <CusGroupDropdown.Item
                                            onClick={(e) => {
                                                copyAction(message)
                                                setMenuPos(null);
                                                setDefaultPos(null);
                                                setShowMenu(false);
                                            }}
                                        >
                                            <span className='mx-[2px] text-sm text-greyblack'>{MENU_OPTION_ACTION_NAME.COPY}</span>
                                        </CusGroupDropdown.Item>
                                    :
                                        null
                                }
                                {bookmarkAction ?
                                        <CusGroupDropdown.Item
                                            onClick={(e) => {
                                                bookmarkAction()
                                                setMenuPos(null);
                                                setDefaultPos(null);
                                                setShowMenu(false);
                                            }}
                                        >
                                            <span className='mx-[2px] text-sm text-greyblack'>{isBookmarked ? MENU_OPTION_ACTION_NAME.UNPIN : MENU_OPTION_ACTION_NAME.PIN}</span>
                                        </CusGroupDropdown.Item>
                                    :
                                        null
                                }
                            </>
                        </CusGroupDropdown>
                    </DynamicContent>                
                    {/* <DynamicContent screenX={menuPos ? menuPos.x : undefined} screenY={menuPos ? menuPos.y : -1} show={showMenu} setShow={setShowMenu} onUnFocus={() => setMenuPos(null)}>
                        <CusGroupDropdown cardSize="w-[160px] h-auto">
                            <>
                                <CusGroupDropdown.Item
                                    onClick={(e) => {
                                        bookmarkAction();
                                        setMenuPos(null);
                                        setShowMenu(false);
                                    }}
                                >
                                    <span className='mx-[2px] text-sm text-greyblack'>{isBookmarked ? "Unpin" : "Pin"}</span>
                                </CusGroupDropdown.Item>
                            </>
                        </CusGroupDropdown>
                    </DynamicContent> */}
                    {/* <DynamicMenu screenX={menuPos ? menuPos.x : undefined} screenY={menuPos ? menuPos.y : -1} show={showMenu} setShow={setShowMenu} onUnFocus={() => setMenuPos(null)}>
                        <CusDropdown.Item onClick={(e)=>{
                            e.stopPropagation()
                            bookmarkAction();
                            document.activeElement.blur();
                            setMenuPos(null);
                            setShowMenu(false);
                        }}>
                            <span className='mx-4 text-base'>{isBookmarked ? "Unpin" : "Pin"}</span>
                        </CusDropdown.Item>
                    </DynamicMenu> */}
                </div>        
            </>
    )
}

ChatMessage.propTypes = {
    name: PropTypes.string,
    timestamp: PropTypes.number,
    status: PropTypes.string,

    extraInfoTextSize: PropTypes.string,

    messageId: PropTypes.string,
    message: PropTypes.string,
    messageType: PropTypes.string,
    mimeType: PropTypes.string,
    messageMedia: PropTypes.object,
    // mediaDownloadFailed: PropTypes.bool,
    // mediaDownloading: PropTypes.bool,
    // loading: PropTypes.bool,
    // enableFailAction: PropTypes.bool,

    containerType: PropTypes.string,
    containerSize: PropTypes.string,

    onClick: PropTypes.func,
    onContextMenu: PropTypes.func,
    isBookmarked: PropTypes.bool,
    readOnlyBookmark: PropTypes.bool,
    bookmarkAction: PropTypes.func,
    active: PropTypes.bool,
};

export default ChatMessage;