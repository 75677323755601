import PropTypes from "prop-types";
import React from 'react';

import { CusRoundedInput, LabelType as InputLabelType, BorderType as InputBorderType } from 'components/CusRoundedInput';
import Cancel from "components/Icon/Cancel";
import Search from "components/Icon/Search";

const CusRoundedSearch = React.forwardRef(({
  value = "",
  borderType = "",
  placeholder = "Search",
  cancelSearch = null,
  onChange = null,
  ...rest
}, ref) =>   (
  <CusRoundedInput
    ref={ref}
    icon={value ? <Cancel/> : <Search/>}
    iconAction={cancelSearch}
    value={value}
    onChange={onChange}
    borderType={borderType ? borderType : InputBorderType.Thin}
    labelType={InputLabelType.NoLabel}
    placeholder={placeholder}
    {...rest}
  />    
));

CusRoundedSearch.propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    cancelSearch: PropTypes.func,
    onChange: PropTypes.func,
  }

export default CusRoundedSearch;