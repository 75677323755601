

import PropTypes from "prop-types";
import React, { useEffect, useRef } from 'react';
import Scrollbars from "react-custom-scrollbars-2";
import './index.css';

const CusUnderlineTextArea = React.forwardRef(({
    hasError = false,
    value = undefined,
    placeholder = "",
    type = "text",
    disabled = false,
    readOnly = false,
    onChange = ()=>{},
    className = "",
    list = "",
    paddingType = " py-1 px-3 ",
    textSize = " text-base ",
    placeholderSize = " placeholder:text-base ",
    innerContainerSize = "",
    ...rest
}, ref) => {        

        return(
            <div className={className}>
                <div>
                    {readOnly || disabled ?
                        <div
                            className={"w-full whitespace-pre-wrap outline-none text-[#94A3B8] placeholder:text-[#CBD5E1] bg-transparent "
                                + textSize
                                + placeholderSize
                                + paddingType
                                + " border-b-2 border-[#CBD5E1] rounded-t-[4px] "
                            }
                            {...rest}
                        >
                            <Scrollbars
                                autoHeightMin="40px"
                                autoHeightMax={innerContainerSize ?? "100px"}
                                autoHeight
                                autoHide
                                autoHideDuration={100}
                            >
                                {value}
                            </Scrollbars>
                        </div>
                        :
                        <textarea 
                            ref={ref}
                            className={"resize-none cus-textarea w-full outline-none text-greyblack bg-transparent "
                                + innerContainerSize
                                + " h-[100px] "
                                + paddingType
                                + textSize
                                + placeholderSize
                                + (hasError ? " placeholder:text-red01 " : " placeholder:text-[#CBD5E1]")
                                + " border-b-2 "
                                + (hasError ? " border-[#BE123C] focus-within:bg-transparent focus-within:border-[#BE123C] " : " focus-within:bg-[#E0F6FA] focus-within:border-[#00B7D4] border-[#CBD5E1] ")
                            } 
                            list={list}
                            value={value}
                            placeholder={placeholder}
                            disabled={disabled}
                            readOnly={readOnly}
                            onChange={(e) => onChange(e)}
                            {...rest}
                        />
                    }
                </div>
            </div>
        )
});

CusUnderlineTextArea.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  list: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  paddingType: PropTypes.string,
  textSize: PropTypes.string,
  placeholderSize: PropTypes.string,
  innerContainerSize: PropTypes.string,
}

export {
    CusUnderlineTextArea
};
