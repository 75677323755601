import {
    GET_NODES,
    GET_NODES_FAILURE,
    GET_NODES_SUCCESS,
    CREATE_NODE,
    CREATE_NODE_FAILURE,
    CREATE_NODE_SUCCESS,
    UPDATE_NODE,
    UPDATE_NODE_FAILURE,
    UPDATE_NODE_SUCCESS,
    CLONE_NODE,
    CLONE_NODE_FAILURE,
    CLONE_NODE_SUCCESS,
    REMOVE_NODE,
    REMOVE_NODE_FAILURE,
    REMOVE_NODE_SUCCESS,
    SELECT_NODE,
    FOCUS_NODE,
    EXPAND_NODE,
} from "store/reducers/setting/automation/chatbot/node";

import {
    getNode as getNodeService,
    createNode as createNodeService,
    updateNode as updateNodeService,
    cloneNode as cloneNodeService,
    removeNode as removeNodeService,
} from "services/chatbot/node";
import { isJson, isSuccessResponse } from "util/helper";
import { handleAuthError } from "store/actions/auth/user";
import { ERROR_REPSONSE_LOCALE_MAP, ERROR_RESPONSE_MAP, getErrorByLocale } from "util/ErrorResponse";
import { errorPrefix } from "lang/locales/prefix";
import { getToken } from "store/reducers/auth/user";

export const expandNode = ({id}) => (dispatch) => {
    dispatch(EXPAND_NODE(id))
}

export const foucsNode = (data) => (dispatch) => {
    console.log(data)
    dispatch(FOCUS_NODE(null))
    dispatch(FOCUS_NODE(data))
}

export const selectNode = (data) => (dispatch) => {
    console.log(data)
    dispatch(SELECT_NODE(null))
    dispatch(SELECT_NODE(data))
}

export const getNodeList = (data) => (dispatch, getState) => {
    dispatch(GET_NODES());    

    let authToken = getToken(getState().auth.user)
    getNodeService(data, authToken)
    .then(response => {
        const bodyString = response.data.body;
        const body = isJson(bodyString) ? JSON.parse(bodyString) : bodyString;

        console.log(body)

        if(isSuccessResponse(response)) {
            dispatch(GET_NODES_SUCCESS(body));
        } else {
            if(body === ERROR_RESPONSE_MAP.AUTH){
                dispatch(handleAuthError())
                return
            }
            if(body === ERROR_RESPONSE_MAP.PERMISSION){
                dispatch(GET_NODES_FAILURE({error: getErrorByLocale(getState, ERROR_REPSONSE_LOCALE_MAP.PERMISSION)}))
                return
            }

            dispatch(GET_NODES_FAILURE({error: body.message }));
        }

    })
    .catch(error=> {
        const message = (typeof error.response === "string") ? error.response : 'System Error, Please contact admin for more information'
        dispatch(GET_NODES_FAILURE({error: message}))
    })

}
 
export const createNode = (data) => (dispatch, getState) => {

    dispatch(CREATE_NODE());

    console.log(data)
    
    let authToken = getToken(getState().auth.user)
    createNodeService(data, authToken)
    .then(response => {
        const bodyString = response.data.body;
        const body = isJson(bodyString) ? JSON.parse(bodyString) : bodyString;

        console.log(body);
        
        if(isSuccessResponse(response)) {
            dispatch(CREATE_NODE_SUCCESS(body))
            dispatch(foucsNode(body))
            dispatch(selectNode(body))
        }else {
            if(body === ERROR_RESPONSE_MAP.AUTH){
                dispatch(handleAuthError())
                return
            }
            if(body === ERROR_RESPONSE_MAP.PERMISSION){
                dispatch(CREATE_NODE_FAILURE({error: getErrorByLocale(getState, ERROR_REPSONSE_LOCALE_MAP.PERMISSION)}))
                return
            }

            dispatch(CREATE_NODE_FAILURE({error: body.message }));
        }
    })
    .catch(error=> {
        const message = (typeof error.response === "string") ? error.response : 'System Error, Please contact admin for more information'
        dispatch(CREATE_NODE_FAILURE({error: message}))
    })

}

export const updateNode = (data, andSelect = false) => (dispatch, getState) => {

    dispatch(UPDATE_NODE());

    console.log(data)

    if(!data.id){
        dispatch(UPDATE_NODE_FAILURE({error: 'no existing node'}))
        return;
    }

    let authToken = getToken(getState().auth.user)
    updateNodeService(data, authToken)
    .then(response => {
        const bodyString = response.data.body;
        const body = isJson(bodyString) ? JSON.parse(bodyString) : bodyString;

        console.log(body);
        
        if(isSuccessResponse(response)) {
            dispatch(UPDATE_NODE_SUCCESS(data))
            dispatch(foucsNode(data))
            dispatch(selectNode(data))
        }else {
            if(body === ERROR_RESPONSE_MAP.AUTH){
                dispatch(handleAuthError())
                return
            }
            if(body === ERROR_RESPONSE_MAP.PERMISSION){
                dispatch(UPDATE_NODE_FAILURE({error: getErrorByLocale(getState, ERROR_REPSONSE_LOCALE_MAP.PERMISSION)}))
                return
            }

            dispatch(UPDATE_NODE_FAILURE({error: body.message }));
        }
    })
    .catch(error=> {
        const message = (typeof error.response === "string") ? error.response : 'System Error, Please contact admin for more information'
        dispatch(UPDATE_NODE_FAILURE({error: message}))
    })

}

export const cloneNode = (data) => (dispatch, getState) => {

    dispatch(CLONE_NODE());

    let authToken = getToken(getState().auth.user)
    cloneNodeService(data, authToken)
    .then(response => {
        const bodyString = response.data.body;
        const body = isJson(bodyString) ? JSON.parse(bodyString) : bodyString;

        console.log(body);
        
        if(isSuccessResponse(response)) {
            dispatch(CLONE_NODE_SUCCESS({}))
            dispatch(getNodeList({flowId: data.flowId}))
        } else {
            if(body === ERROR_RESPONSE_MAP.AUTH){
                dispatch(handleAuthError())
                return
            }
            if(body === ERROR_RESPONSE_MAP.PERMISSION){
                dispatch(CLONE_NODE_FAILURE({error: getErrorByLocale(getState, ERROR_REPSONSE_LOCALE_MAP.PERMISSION)}))
                return
            }

            dispatch(CLONE_NODE_FAILURE({error: body.message }));
        }
    })
    .catch(error=> {
        const message = (typeof error.response === "string") ? error.response : 'System Error, Please contact admin for more information'
        dispatch(CLONE_NODE_FAILURE({error: message}))
    })

}

export const removeNode = ({id, flowId}) => (dispatch, getState) => {

    dispatch(REMOVE_NODE());

    console.log(id)

    if(!id){
        dispatch(REMOVE_NODE_FAILURE({error: 'no existing node'}))
        return;
    }

    if(!flowId){
        dispatch(REMOVE_NODE_FAILURE({error: 'no existing flow'}))
        return;
    }

    let authToken = getToken(getState().auth.user)
    removeNodeService({id}, authToken)
    .then(response => {
        const bodyString = response.data.body;
        const body = isJson(bodyString) ? JSON.parse(bodyString) : bodyString;

        console.log(body);
        
        if(isSuccessResponse(response)) {
            dispatch(REMOVE_NODE_SUCCESS({}))
            dispatch(getNodeList({flowId: flowId}))
        } else {
            if(body === ERROR_RESPONSE_MAP.AUTH){
                dispatch(handleAuthError())
                return
            }
            if(body === ERROR_RESPONSE_MAP.PERMISSION){
                dispatch(REMOVE_NODE_FAILURE({error: getErrorByLocale(getState, ERROR_REPSONSE_LOCALE_MAP.PERMISSION)}))
                return
            }

            dispatch(REMOVE_NODE_FAILURE({error: body.message }));
        }
    })
    .catch(error=> {
        const message = (typeof error.response === "string") ? error.response : 'System Error, Please contact admin for more information'
        dispatch(REMOVE_NODE_FAILURE({error: message}))
    })

}
