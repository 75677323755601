const HidePreview = (rest) => {
    return(
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path fill="currentColor" d="M19.97 21.385L16.614 18.029C15.1661 18.6882 13.5908 19.0204 12 19.002C10.3599 19.0223 8.73671 18.6684 7.254 17.967C6.10468 17.4062 5.07264 16.6317 4.213 15.685C3.30049 14.7068 2.5833 13.5633 2.1 12.316L2 12.002L2.105 11.686C2.82781 9.84224 4.04426 8.23312 5.621 7.03495L3 4.41395L4.413 3.00195L21.382 19.971L19.972 21.385H19.97ZM7.036 8.45095C5.75792 9.34687 4.74865 10.5747 4.117 12.002C5.47142 15.1269 8.59587 17.1086 12 17.002C13.0498 17.0106 14.0936 16.8415 15.087 16.502L13.287 14.702C12.8863 14.8984 12.4462 15.0009 12 15.002C10.3475 14.9916 9.01037 13.6545 9 12.002C9.00048 11.5547 9.10309 11.1135 9.3 10.712L7.036 8.45095ZM19.852 15.612L18.46 14.221C19.0456 13.5589 19.5256 12.8104 19.883 12.002C18.5304 8.87553 15.4047 6.89303 12 7.00195C11.753 7.00195 11.505 7.01095 11.265 7.02795L9.5 5.26095C10.3216 5.08519 11.1598 4.99835 12 5.00195C13.6401 4.9816 15.2633 5.33557 16.746 6.03695C17.8953 6.59769 18.9274 7.37215 19.787 8.31895C20.6991 9.29592 21.4163 10.438 21.9 11.684L22 12.002L21.895 12.318C21.4268 13.536 20.7342 14.6554 19.853 15.618L19.852 15.612Z"/>
        </svg>

    )
}

export default HidePreview;