import PropTypes from "prop-types";
const CusInputError = ({ name = "", errors = {} }) => {
    return(
      errors && errors[name] && errors[name]["message"] ?
        <span className="inline-block text-red01 text-[10px] px-[10px]">{errors && errors[name] && errors[name]["message"]}</span>
        :
        <></>
    );
}

CusInputError.propTypes = {
  errors: PropTypes.object,
  name: PropTypes.string
}

export { 
    CusInputError
};