import PropTypes from 'prop-types';
import React from 'react';

const ListItem = ({children = null}) => {

    return (
        children ?
            <div className={`w-full flex-shrink truncate flex-col`}>
                <div className={`w-full flex px-2 py-1 text-grey06 text-xs justify-between items-center`}>
                    {children[0]}
                    {children[1] ? children[1] : null}
                </div>
                {children[2] ? children[2] : null}
            </div>
            :
            null
    )
}


ListItem.propTypes = {
    children: PropTypes.node,
};

export default ListItem;