import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import PluginLayout from 'layouts/PluginLayout';
import { useEffect } from 'react';
import LocalizedProvider from 'util/LocalizedService/LocalizedProvider';
import NoPermission from 'pages/404/404';
import HomeSetting from 'router/setting';
import { handleAuthError, removeToken, setToken } from 'store/actions/auth/user';
import { useIsLoggedIn } from 'hooks/user/useIsLoggedIn';

const App = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const iframeListener = (e) => {
    // console.log('3002: get post msg')
    // if(permittedDomains.includes(e.origin)){
        // console.log('get from iframe')

        if(!e.data.token){
          // window.parent.postMessage({'status': 'failed'}, e.origin)
          return;
        }

        const token = e.data.token;

        console.log('iframeListener')
        console.log(token)

        dispatch(setToken(token, () => {
          console.log("navigate('chatbot')")
          navigate('app/chatbot')
        }));
        
        // window.removeEventListener('message', iframeListener);
      // }
  }

  useEffect(() => {      
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/parent
      // console.log(isIframe)
      // console.log(window.name)
      // if(isIframe) window.addEventListener('message', iframeListener);
      window.addEventListener('message', iframeListener);

      return () => {
          // if(isIframe) window.removeEventListener('message', iframeListener);
          window.removeEventListener('message', iframeListener);
      }

  }, [])

  return (
    <LocalizedProvider>
      <Routes>
        <Route path="*" element={<ProtectedRoute/>} />
      </Routes>
    </LocalizedProvider>
  );
}

const ProtectedRoute = () => {
  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();
  const isIframe = window.location !== window.parent.location;

  useEffect(() => {
    if(isLoggedIn)
      navigate('app/chatbot')
    else
      navigate('app/404')
  }, [isLoggedIn])

  return (
    <Routes>
      <Route path="app" element={<PluginLayout/>}>
        <Route path="chatbot" element={<HomeSetting/>} />
        <Route path="404" element={<NoPermission/>} />
      </Route>
      <Route path="*" element={<Navigate to="app/404" replace />} />
    </Routes>
  )
}

export default App;
