
import { tablePrefix } from 'lang/locales/prefix';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

const Action = ({
    className = "",
    children = null,
    isHeader = false
}) => {
    
    const intl = useIntl();

    return (
        <>
            {
                isHeader ?
                    <th className={`sticky top-0 text-left text-grey07 text-sm truncate h-[64px] pl-4 pr-8 rounded-tr-[5px] ${className}`}><div className="w-full h-full flex items-center truncate" title={intl.formatMessage({ "id": `${tablePrefix}.header.action`})}><div className="truncate">{intl.formatMessage({ "id": `${tablePrefix}.header.action`})}</div></div></th>
                :
                    <td className={`text-blue04 text-sm truncate h-[64px] pl-4 pr-8 ${className}`}>
                        <div className="flex items-center">
                            {children}
                        </div>
                    </td>
            }
        </>
    )
}

Action.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    isHeader: PropTypes.bool,
};

export default Action;