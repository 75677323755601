import PropTypes from 'prop-types';
import React from 'react';
import CircularLoading from 'components/CircularLoading'

import Overlay from '..';

const LoadingOverlay = ({show = false, full = false, hasRoundedCorner = false}) => {

    return (
        <Overlay className={`bg-black opacity-70 z-[1500] ${hasRoundedCorner ? "rounded-md" : ""}`} full={full} show={show}>
            <CircularLoading/>
        </Overlay>
    )
}

LoadingOverlay.propTypes = {
    show: PropTypes.bool,
    full: PropTypes.bool,
    hasRoundedCorner: PropTypes.bool,
};

export default LoadingOverlay;