import { infoPrefix } from "../prefix";

export const info = {
    [`${infoPrefix}.forgotPassword.success`]: "忘记密码成功，临时密码已经发送到您的邮箱",
    [`${infoPrefix}.forgotPassword.failed`]: "忘记密码失败",

    [`${infoPrefix}.signup.success`]: "创建账号成功，临时密码已发送至您的邮箱",
    [`${infoPrefix}.signup.failed`]: "创建账户失败",

    [`${infoPrefix}.template.send.success`]: "模板消息发送成功",
    [`${infoPrefix}.template.send.failed`]: "模板消息发送失败",
 }