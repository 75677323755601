const Preview = (rest) => {
    return(
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path stroke="currentColor" d="M15.5319 12C15.5319 13.645 14.178 15 12.4813 15C10.7846 15 9.43066 13.645 9.43066 12C9.43066 10.355 10.7846 9 12.4813 9C14.178 9 15.5319 10.355 15.5319 12Z" strokeWidth="2"/>
            <path stroke="currentColor" d="M20.7658 10.926C21.1619 11.4003 21.3599 11.6374 21.3599 12C21.3599 12.3626 21.1619 12.5997 20.7658 13.074C19.3245 14.8002 16.157 18 12.4811 18C8.80527 18 5.63769 14.8002 4.19637 13.074C3.80035 12.5997 3.60234 12.3626 3.60234 12C3.60234 11.6374 3.80035 11.4003 4.19637 10.926C5.63769 9.19984 8.80527 6 12.4811 6C16.157 6 19.3245 9.19984 20.7658 10.926Z" strokeWidth="2"/>
        </svg>

    )
}

export default Preview;