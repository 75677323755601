import CirclePlus from 'components/Icon/CirclePlus';
import React, { memo } from 'react';
import { Handle } from 'reactflow';
import BottomHandle from 'components/Flow/Handle/BottomHandle';
import TopHandle from 'components/Flow/Handle/TopHandle';
import { useIntl } from 'react-intl';
import { editChatbotPrefix, settingPrefix } from 'lang/locales/prefix';

const AddNode = memo(({data}) => {

    const intl = useIntl();

    return (
        <>
            <TopHandle/>
            <div
                className={`flex cursor-pointer items-center justify-center w-full h-[calc(100%+10px)] rounded-[8px] border-[1px] border-[#CBD5E1] text-[#CBD5E1] hover:border-[#34BECD] hover:text-[#34BECD] menu-pos`}
                // className={`flex cursor-pointer items-center justify-center w-full h-[calc(100%+10px)] rounded-[8px] border-[1px] ${data.selected ? "border-[#34BECD] text-[#34BECD]" : "border-[#CBD5E1] text-[#CBD5E1]"} hover:border-[#34BECD] hover:text-[#34BECD] menu-pos`}
                onClick={(e) => data.onAddNode()}
            >
                <CirclePlus className="w-[24px] h-[24px]"/>
            </div>
            <BottomHandle/>
        </>
    );
});

export default AddNode