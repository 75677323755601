import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './index.css';
// import classes from 'react-style-classes';
// import './styles.scss';

const Submenu = ({
	children = null,
  vPosition = 'Bottom',
  hPosition = 'Left',
  className = null,
  scroll = false,
  disabled = false,
  isActive = false,
	...props
}) => {

	return (disabled ? null :
		<div
			className={
				"cus-dropdown-submenu "
				+ (isActive ? " cus-submenu-active " : "")
				+ 'cus-dropdown-submenu' + vPosition + hPosition + " "
				+ (className ? className : "")
				+ " "
			}
			onClick={(e) => e.stopPropagation()}
			onContextMenu={(e) => e.stopPropagation()}			
			{...props}
		>
			<ul className={scroll ? "cus-dropdown-ul scroll-list" : "cus-dropdown-ul"}>
				{children}
			</ul>
		</div>
	)
}

Submenu.propTypes = {
  children: PropTypes.node,
  vPosition: PropTypes.oneOf(['Top', 'Bottom', '']),
  hPosition: PropTypes.oneOf(['Left', 'Right']),
  className: PropTypes.string,
  scroll: PropTypes.bool,
  disabled: PropTypes.bool,
  isActive: PropTypes.bool,
}

export default Submenu;