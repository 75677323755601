import { generalPrefix } from "../prefix";

export const general = {
    [`${generalPrefix}.button.create`]: "Create",
    [`${generalPrefix}.button.update`]: "Update",
    [`${generalPrefix}.button.cancel`]: "Cancel",
    [`${generalPrefix}.button.clone`]: "Clone",
    [`${generalPrefix}.button.copy`]: "Copy",
    [`${generalPrefix}.button.remove`]: "Remove",
    [`${generalPrefix}.button.delete`]: "Delete",
    [`${generalPrefix}.button.select`]: "Select",
    [`${generalPrefix}.button.disable`]: "Disable",
    [`${generalPrefix}.button.confirm`]: "Confirm",
    [`${generalPrefix}.button.save`]: "Save changes",
    [`${generalPrefix}.button.reset`]: "Reset",
    
    [`${generalPrefix}.button.closeCountdown`]: "Close ",

    [`${generalPrefix}.button.prev`]: "Prev",
    [`${generalPrefix}.button.next`]: "Next ",

    [`${generalPrefix}.textarea.placeholder`]: "Type message here",
    
    [`${generalPrefix}.image.upload`]: "Upload",
    
    [`${generalPrefix}.modal.title.confirmation`]: "CONFIRMATION",
    [`${generalPrefix}.modal.title.reminder`]: "REMINDER",
    [`${generalPrefix}.modal.title.status`]: "STATUS",

    [`${generalPrefix}.modal.title.editImage`]: "EDIT IMAGE",
    
    [`${generalPrefix}.modal.content.chatTransfer`]: "Chat is transferred to ",
    [`${generalPrefix}.modal.content.warning.quitEdit`]: `Are you sure you want to quit edit?\n Changes will not be saved.`,

    [`${generalPrefix}.modal.content.warning.redirect`]: `You will be redirected.`,

    [`${generalPrefix}.notice.copy`]: `Copied to clipboard.`,
    
    [`${generalPrefix}.popout.text.moreTaggings`]: "More taggings",

    [`${generalPrefix}.text.status`]: "Status",
    [`${generalPrefix}.text.language`]: "Language",
    [`${generalPrefix}.text.period`]: "Period",
    [`${generalPrefix}.text.channels`]: "Channels",
    [`${generalPrefix}.text.role`]: "Role",
    [`${generalPrefix}.text.table`]: "Table",

    [`${generalPrefix}.text.edit`]: "Edit",
    [`${generalPrefix}.text.remove`]: "Remove",
    [`${generalPrefix}.text.search`]: "Search",

    [`${generalPrefix}.filter.title.text`]: "FILTER",
    [`${generalPrefix}.filter.title.text.sorting`]: "SORTING",

    [`${generalPrefix}.dropdown.tags.search`]: "Search Tags",
    [`${generalPrefix}.dropdown.tags.add`]: "Add tags",
    [`${generalPrefix}.dropdown.tag.add`]: "Add Tag",
    [`${generalPrefix}.dropdown.tag.update`]: "Update Tag",
    [`${generalPrefix}.dropdown.tag.name`]: "Name a tag",
    [`${generalPrefix}.dropdown.tag.warn`]: "All leads or contacts using this tag will be updated also.",

    [`${generalPrefix}.nameList.all`]: "All",

    [`${generalPrefix}.nameList.status.active`]: "Active",
    [`${generalPrefix}.nameList.status.inactive`]: "Inactive",

    [`${generalPrefix}.nameList.channels.all`]: "All channels",
    [`${generalPrefix}.nameList.channels.livechat`]: "LiveChat",
    [`${generalPrefix}.nameList.channels.facebook`]: "Facebook",
    [`${generalPrefix}.nameList.channels.whatsapp`]: "WhatsApp",
    [`${generalPrefix}.nameList.channels.line`]: "LINE",
    [`${generalPrefix}.nameList.channels.wechat`]: "WeChat",

    [`${generalPrefix}.nameList.language.all`]: "All language",
    [`${generalPrefix}.nameList.language.english`]: "English",
    [`${generalPrefix}.nameList.language.traditionalChinese`]: "Traditional Chinese",
    [`${generalPrefix}.nameList.language.simplifiedChinese`]: "Simplified Chinese",

    [`${generalPrefix}.nameList.table.contact`]: "Contact",
    [`${generalPrefix}.nameList.table.ticket`]: "Ticket",

    [`${generalPrefix}.nameList.role.agent`]: "Agent",
    [`${generalPrefix}.nameList.role.supervisor`]: "Supervisor",
    [`${generalPrefix}.nameList.role.admin`]: "Admin",

    [`${generalPrefix}.progress.last`]: "Confirm Submission",

    [`${generalPrefix}.status.empty`]: "N/A",

    [`${generalPrefix}.templateMessage.preview.title`]: "Template Preview",
    [`${generalPrefix}.templateMessage.config.para`]: "Parameters",
    [`${generalPrefix}.templateMessage.button.send`]: "Send Message",

}