import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import NodeSettingListItem from './NodeSettingListItem';
import NodeSettingAddItem from './NodeSettingAddItem';

const NodeSettingFolder = ({
	readOnly = false,
	selectedNode = null,
	nodeId = 'ROOT_NODE',
	preNodeId = 'ROOT_NODE',
	name = '',
	nodeExpandedMap = {},
	nodeMapping = {},
	onExpand = () => {},
	selectAction = () => {},
	addNewAction = () => {},
}) => {

	const getSortedNameList = () => {
		if(!selectAction || !nodeMapping[nodeId])
			return []

		let nodeList = JSON.parse(JSON.stringify(nodeMapping[nodeId]));
		nodeList.sort((a, b) => a['name'].toLowerCase().localeCompare(b['name'].toLowerCase()))

		return nodeList
	}

	let sortedNameList = getSortedNameList();
	let isExpanded = !!selectAction ? (nodeId === 'ROOT_NODE' || !!nodeExpandedMap[nodeId]) : false

	console.log(selectAction)

	return (
		<div className='flex flex-1 justify-end w-full'>
			<div className="w-[10px]"/>
			<div className={`w-[calc(100%-10px)] ${preNodeId !== 'ROOT_NODE' ? "border-[#CBD5E1] border-l-[1px]" : ""} `}>
				{nodeId !== 'ROOT_NODE' && !!selectAction &&
					<NodeSettingListItem
						isChild={preNodeId !== 'ROOT_NODE'}
						isExpanded={isExpanded}
						isSelected={selectedNode && selectedNode.id === nodeId}
						title={name}
						onExpand={() => onExpand(nodeId)}
						selectAction={() => selectAction(nodeId)}
					/>
				}
				{preNodeId !== 'ROOT_NODE' && nodeId !== 'ROOT_NODE' && !selectAction &&
					<NodeSettingAddItem
						isChild
						addNewAction={() => addNewAction(preNodeId)}
					/>
				}
				<div className='flex justify-end w-full truncate overflow-hidden'>
					{preNodeId !== 'ROOT_NODE' && <div className="w-[10px]"/>}
					<div className={`truncate overflow-hidden flex-1 ${preNodeId === 'ROOT_NODE' ? "w-full" : "w-[100%-10px]"}`}>
						{isExpanded && !!selectAction && sortedNameList.length > 0 && sortedNameList.map(node => (
							<NodeSettingFolder
									key={node.id}
									readOnly={readOnly}
									selectedNode={selectedNode}
									nodeId={node.id}
									preNodeId={nodeId}
									name={node.name}
									nodeExpandedMap={nodeExpandedMap}
									nodeMapping={nodeMapping}
									onExpand={onExpand}
									selectAction={selectAction}
									addNewAction={addNewAction}
							/>
						))}
						{isExpanded && !readOnly &&
								<NodeSettingFolder
									nodeId={""}
									preNodeId={nodeId}
									selectAction={null}
									addNewAction={addNewAction}
							/>
						}
					</div>
				</div>
			</div>
		</div>
	)
}

NodeSettingFolder.propTypes = {
	readOnly: PropTypes.bool,
	selectedNode: PropTypes.object,
	nodeId: PropTypes.string,
	name: PropTypes.string,
	nodeExpandedMap: PropTypes.object,
	nodeMapping: PropTypes.object,
	onExpand: PropTypes.func,
	selectAction: PropTypes.func,
	addNewAction: PropTypes.func,
}

export default NodeSettingFolder