const AnyMedia = (rest) => {
    return(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M8.34021 14.3926H15.7246C15.8769 14.3926 15.9898 14.3236 16.0632 14.1855C16.1366 14.0472 16.1252 13.9133 16.0291 13.7838L14.0211 11.0939C13.9422 10.9924 13.8407 10.9417 13.7166 10.9417C13.5924 10.9417 13.4909 10.9924 13.4122 11.0939L11.3522 13.7773L9.97242 12.0088C9.89368 11.9128 9.79354 11.8648 9.672 11.8648C9.5506 11.8648 9.45046 11.9156 9.37158 12.0171L8.04379 13.7838C7.94232 13.9133 7.92828 14.0472 8.00168 14.1855C8.07509 14.3236 8.18793 14.3926 8.34021 14.3926ZM6.97979 17.6316L5.29389 19.3173C5.05432 19.557 4.77874 19.6113 4.46716 19.4802C4.15572 19.349 4 19.1128 4 18.7716V6.52232C4 6.09691 4.14737 5.73684 4.44211 5.44211C4.73684 5.14737 5.09691 5 5.52232 5H18.4777C18.9031 5 19.2632 5.14737 19.5579 5.44211C19.8526 5.73684 20 6.09691 20 6.52232V16.1093C20 16.5347 19.8526 16.8947 19.5579 17.1895C19.2632 17.4842 18.9031 17.6316 18.4777 17.6316H6.97979ZM6.44211 16.3684H18.4777C18.5425 16.3684 18.6019 16.3414 18.6558 16.2874C18.7098 16.2335 18.7368 16.1741 18.7368 16.1093V6.52232C18.7368 6.45747 18.7098 6.3981 18.6558 6.34421C18.6019 6.29017 18.5425 6.26316 18.4777 6.26316H5.52232C5.45747 6.26316 5.39811 6.29017 5.34421 6.34421C5.29018 6.3981 5.26316 6.45747 5.26316 6.52232V17.5345L6.44211 16.3684Z" fill="#64748B"/>
            <path d="M8.34021 14.3926H15.7246C15.8769 14.3926 15.9898 14.3236 16.0632 14.1855C16.1366 14.0472 16.1252 13.9133 16.0291 13.7838L14.0211 11.0939C13.9422 10.9924 13.8407 10.9417 13.7166 10.9417C13.5924 10.9417 13.4909 10.9924 13.4122 11.0939L11.3522 13.7773L9.97242 12.0088C9.89368 11.9128 9.79354 11.8648 9.672 11.8648C9.5506 11.8648 9.45046 11.9156 9.37158 12.0171L8.04379 13.7838C7.94232 13.9133 7.92828 14.0472 8.00168 14.1855C8.07509 14.3236 8.18793 14.3926 8.34021 14.3926ZM6.97979 17.6316L5.29389 19.3173C5.05432 19.557 4.77874 19.6113 4.46716 19.4802C4.15572 19.349 4 19.1128 4 18.7716V6.52232C4 6.09691 4.14737 5.73684 4.44211 5.44211C4.73684 5.14737 5.09691 5 5.52232 5H18.4777C18.9031 5 19.2632 5.14737 19.5579 5.44211C19.8526 5.73684 20 6.09691 20 6.52232V16.1093C20 16.5347 19.8526 16.8947 19.5579 17.1895C19.2632 17.4842 18.9031 17.6316 18.4777 17.6316H6.97979ZM6.44211 16.3684H18.4777C18.5425 16.3684 18.6019 16.3414 18.6558 16.2874C18.7098 16.2335 18.7368 16.1741 18.7368 16.1093V6.52232C18.7368 6.45747 18.7098 6.3981 18.6558 6.34421C18.6019 6.29017 18.5425 6.26316 18.4777 6.26316H5.52232C5.45747 6.26316 5.39811 6.29017 5.34421 6.34421C5.29018 6.3981 5.26316 6.45747 5.26316 6.52232V17.5345L6.44211 16.3684Z" fill="black" fillOpacity="0.2"/>
            <path d="M8.34021 14.3926H15.7246C15.8769 14.3926 15.9898 14.3236 16.0632 14.1855C16.1366 14.0472 16.1252 13.9133 16.0291 13.7838L14.0211 11.0939C13.9422 10.9924 13.8407 10.9417 13.7166 10.9417C13.5924 10.9417 13.4909 10.9924 13.4122 11.0939L11.3522 13.7773L9.97242 12.0088C9.89368 11.9128 9.79354 11.8648 9.672 11.8648C9.5506 11.8648 9.45046 11.9156 9.37158 12.0171L8.04379 13.7838C7.94232 13.9133 7.92828 14.0472 8.00168 14.1855C8.07509 14.3236 8.18793 14.3926 8.34021 14.3926ZM6.97979 17.6316L5.29389 19.3173C5.05432 19.557 4.77874 19.6113 4.46716 19.4802C4.15572 19.349 4 19.1128 4 18.7716V6.52232C4 6.09691 4.14737 5.73684 4.44211 5.44211C4.73684 5.14737 5.09691 5 5.52232 5H18.4777C18.9031 5 19.2632 5.14737 19.5579 5.44211C19.8526 5.73684 20 6.09691 20 6.52232V16.1093C20 16.5347 19.8526 16.8947 19.5579 17.1895C19.2632 17.4842 18.9031 17.6316 18.4777 17.6316H6.97979ZM6.44211 16.3684H18.4777C18.5425 16.3684 18.6019 16.3414 18.6558 16.2874C18.7098 16.2335 18.7368 16.1741 18.7368 16.1093V6.52232C18.7368 6.45747 18.7098 6.3981 18.6558 6.34421C18.6019 6.29017 18.5425 6.26316 18.4777 6.26316H5.52232C5.45747 6.26316 5.39811 6.29017 5.34421 6.34421C5.29018 6.3981 5.26316 6.45747 5.26316 6.52232V17.5345L6.44211 16.3684Z" fill="black" fillOpacity="0.2"/>
            <path d="M8.34021 14.3926H15.7246C15.8769 14.3926 15.9898 14.3236 16.0632 14.1855C16.1366 14.0472 16.1252 13.9133 16.0291 13.7838L14.0211 11.0939C13.9422 10.9924 13.8407 10.9417 13.7166 10.9417C13.5924 10.9417 13.4909 10.9924 13.4122 11.0939L11.3522 13.7773L9.97242 12.0088C9.89368 11.9128 9.79354 11.8648 9.672 11.8648C9.5506 11.8648 9.45046 11.9156 9.37158 12.0171L8.04379 13.7838C7.94232 13.9133 7.92828 14.0472 8.00168 14.1855C8.07509 14.3236 8.18793 14.3926 8.34021 14.3926ZM6.97979 17.6316L5.29389 19.3173C5.05432 19.557 4.77874 19.6113 4.46716 19.4802C4.15572 19.349 4 19.1128 4 18.7716V6.52232C4 6.09691 4.14737 5.73684 4.44211 5.44211C4.73684 5.14737 5.09691 5 5.52232 5H18.4777C18.9031 5 19.2632 5.14737 19.5579 5.44211C19.8526 5.73684 20 6.09691 20 6.52232V16.1093C20 16.5347 19.8526 16.8947 19.5579 17.1895C19.2632 17.4842 18.9031 17.6316 18.4777 17.6316H6.97979ZM6.44211 16.3684H18.4777C18.5425 16.3684 18.6019 16.3414 18.6558 16.2874C18.7098 16.2335 18.7368 16.1741 18.7368 16.1093V6.52232C18.7368 6.45747 18.7098 6.3981 18.6558 6.34421C18.6019 6.29017 18.5425 6.26316 18.4777 6.26316H5.52232C5.45747 6.26316 5.39811 6.29017 5.34421 6.34421C5.29018 6.3981 5.26316 6.45747 5.26316 6.52232V17.5345L6.44211 16.3684Z" fill="black" fillOpacity="0.2"/>
        </svg>
    )
}

export default AnyMedia;