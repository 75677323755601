import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import CusEditable, { EDITABLE_TYPE, setDefaultField, validateField } from 'components/CusEditable';
import { Color as ButtonColor, CusRoundedButton } from 'components/CusRoundedButton';
import Cancel from 'components/Icon/Cancel';
import { useLanguage } from 'hooks/useLanguage';
import { useIntl } from 'react-intl';
import { chatbotPrefix, chatbotIconBarPrefix, createOrCloneChatbotPrefix, generalPrefix, settingPrefix } from 'lang/locales/prefix';

const FLOW_CLONE_FIELD_TYPE = [
    {
        "fieldName": "name",
        "displayNameKey": `${settingPrefix}.${createOrCloneChatbotPrefix}.field.clone.name`,
        "displayNameEn": "From Flow",
        "displayNameTc": "來自流",
        "displayNameSc": "来自流",
        "fieldType": EDITABLE_TYPE.TEXT,
        "required": false,
        "editable": false,
        "creatable": true,
    },
    {
        "fieldName": "version",
        "displayNameKey": `${settingPrefix}.${createOrCloneChatbotPrefix}.field.clone.version`,
        "displayNameEn": "Version",
        "displayNameTc": "版本",
        "displayNameSc": "版本",
        "fieldType": EDITABLE_TYPE.TEXT,
        "required": false,
        "editable": false,
        "creatable": true,
    },
    {
        "fieldName": "newFlowName",
        "displayNameKey": `${settingPrefix}.${createOrCloneChatbotPrefix}.field.clone.newFlowName`,
        "displayNameEn": "Flow name",
        "displayNameTc": "流名稱",
        "displayNameSc": "流名称",
        "fieldType": EDITABLE_TYPE.TEXT,
        "required": true,
        "editable": true,
        "creatable": true,
    },
]

const CloneFlow = ({
    flow = null,
    saveAction = null,
    cancelAction = null,
}) => {

    const [errorMap, setErrorMap] = useState({});
    const [editableFlow, setEditableFlow] = useState({})

    const intl = useIntl();
    const { language } = useLanguage()

    // const getOptions = (fieldType, fieldName, options) => {
    //     if(fieldType !== EDITABLE_TYPE.PICKLIST)
    //         return []

    //     return options.map(option => ({label: capitalise(option, "_"), value: option}))
    // }

    const onChangeHandler = (isCreate, field, e) => {
        
        let value = e.target.value;
        let errorMap = {};

        switch (field.fieldType) {
            case EDITABLE_TYPE.BOOLEAN:
                value = !editableFlow[field.fieldName]
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;        
            default:
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;
        }

        if(errorMap && Object.keys(errorMap).length > 0)
            setErrorMap((erMap) => ({...erMap, ...errorMap}))
        else{
            setErrorMap((erMap) => {
                let newErrorMap = {...erMap}
                delete newErrorMap[field.fieldName]
                return newErrorMap 
            })
        }
        
        setEditableFlow((editableFields) => ({...editableFields, [field.fieldName]: value}))
    }

    const validateFields = (isCreate) => {
        let errorMap = {}

        let submitEditableFields = {...editableFlow};
        let mapping = [...FLOW_CLONE_FIELD_TYPE]

        // if(!isCreate){
        //     mapping = [...mapping, ...ACCOUNT_STATUS_FIELD_TYPE]
        // }

        for(let field of mapping){
            if(!submitEditableFields[field.fieldName])
                setDefaultField(field, submitEditableFields, field.fieldName, isCreate)
            errorMap = {...errorMap, ...validateField(field, submitEditableFields[field.fieldName], intl.formatMessage, isCreate)}
        }

        if(errorMap && Object.keys(errorMap).length > 0){
            console.log(errorMap)
            setErrorMap(errorMap)
            return;
        }

        saveAction({...submitEditableFields, version: flow.type === "RELEASED" ? parseInt(flow.version) : flow.version})
    }

    useEffect(() => {
        setEditableFlow({
            name: flow.name,
            version: flow.type === "RELEASED" ? `${flow.version}` : intl.formatMessage({ "id": `${settingPrefix}.${chatbotPrefix}.nameList.flowType.draft`}),
            newFlowName: "",
        })
        setErrorMap({})
    }, [flow])

    return(
        <>
            <div className="flex justify-between items-end w-full px-7 py-4">
            <div className="truncate">{intl.formatMessage({ "id": `${settingPrefix}.${createOrCloneChatbotPrefix}.modal.page.clone.text.title`})}</div>
                {cancelAction ? <div className="cursor-pointer text-blue04" onClick={() => cancelAction()}><Cancel className="w-[32px] h-[32px]"/></div> : null}
            </div>            
            {FLOW_CLONE_FIELD_TYPE.map((field) => (
                <CusEditable
                    key={field.fieldName}
                    readOnly={!field.editable}
                    className={"w-full px-7 py-2"}
                    fieldType={field.fieldType}
                    label={intl.formatMessage({ "id": field.displayNameKey }) + (field.required ? "*" : "")}
                    value={editableFlow[field.fieldName] ?? field.defaultValue}
                    // options={getOptions(field.fieldType, field.fieldName, field.options)}
                    errors={errorMap}
                    required={field.required}
                    errorName={field.fieldName}
                    onChange={(e) => onChangeHandler(false, field, e)}
                />
            ))}
            <div className="pt-2 px-5 w-full flex min-h-[82px]">
                <>
                    <CusRoundedButton className='w-full flex px-2 mx-2 justify-center items-center' color={ButtonColor.primaryFrame}
                        onClick={cancelAction}
                    >
                        <span>{intl.formatMessage({ "id": `${generalPrefix}.button.cancel`})}</span>
                    </CusRoundedButton>
                    <CusRoundedButton className='w-full flex px-2 mx-2 justify-center items-center' color={ButtonColor.primary}
                        onClick={() => validateFields(false)}
                    >
                        <span>{intl.formatMessage({ "id": `${generalPrefix}.button.clone`})}</span>
                    </CusRoundedButton>
                </>
            </div>        
        </>
    )
}

CloneFlow.propTypes = {
    flow: PropTypes.object,
    saveAction: PropTypes.func,
    cancelAction: PropTypes.func,
};

export default CloneFlow;
