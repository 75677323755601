import PropTypes from 'prop-types';

import { EDITABLE_TYPE, setDefaultField, validateField } from 'components/CusEditable';
import CusEditableLeftLabel from 'components/CusEditable/CusEditableLeftLabel';
import NonExpanableListItem from 'components/NonExpanableListItem';
import { useLanguage } from 'hooks/useLanguage';
import { checkListItemDuplication } from 'util/helper';
import Info2 from 'components/Icon/Info2';
import { useIntl } from 'react-intl';
import { editNodePrefix, settingPrefix, errorPrefix } from 'lang/locales/prefix';
import { SETTING_SUB_MENU_TYPE } from '../EditFlow';

export const EXTERNAL_ACTION_FIELD_TEMPLATE = Object.freeze({
    "externalActionId" : "",
})

const EXTERNAL_ACTION_FIELD_TYPE = [
    {
        "fieldName": "externalActionId",
        "displayNameKey": `${settingPrefix}.${editNodePrefix}.field.externalAction.externalActionId`,
        "displayNameEn": "Action",
        "displayNameTc": "動作",
        "displayNameSc": "动作",
        "fieldType": EDITABLE_TYPE.PICKLIST,
        "options": [],
        "required": true,
        "editable": true,
        "creatable": true,
    },
]

export const validateExternalActionListItems = (errorFormater, isCreate, fieldList) => {
    let errorList = [];

    for(let index = 0; index < fieldList.length; index++){
        let actionListItem = {...fieldList[index]}
        let errorMap = {}

        let mapping = [...EXTERNAL_ACTION_FIELD_TYPE]

        for(let field of mapping){
            if(!actionListItem[field.fieldName])
                setDefaultField(field, actionListItem, field.fieldName, isCreate)
            errorMap = {...errorMap, ...validateField(field, actionListItem[field.fieldName], errorFormater, isCreate)}
        }

        errorList[index] = errorMap
    }

    let fieldSet = fieldList.map(field => field.externalActionId)

    let duplicates = checkListItemDuplication(fieldSet)
    duplicates.forEach(i => errorList[i] = { externalActionId: { message: errorFormater({ "id": `${errorPrefix}.duplicates` }) }})

    return errorList
}

const ExternalActionListItem = ({
    isCreate = false,
    readOnly = false,
    itemIndex = -1,
    actionList = [],
    actionListItem = null,
    error = null,
    updateExternalAction = null,
    removeExternalAction = null,
    openEditSettingAction = null,
    getFlowSettingById = null,
}) => {
    
    const intl = useIntl();
    const { language } = useLanguage()

    const getOptions = (fieldType, fieldName, options) => {
        if(fieldType !== EDITABLE_TYPE.PICKLIST)
            return []

        if(fieldName === "externalActionId")
            return actionList.map(action => ({label: action.name, value: action.id}))

        return options.map(option => ({label: option, value: option}))
    }

    const onChangeHandler = (isCreate, field, e) => {
        
        let value = e.target.value;
        let currentErrorMap = {...error};
        let errorMap = {};

        switch (field.fieldType) {
            case EDITABLE_TYPE.BOOLEAN:
            case EDITABLE_TYPE.BOOLEAN_CHECKBOX:
                value = !actionListItem[field.fieldName]
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;        
            default:
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;
        }

        if(errorMap && Object.keys(errorMap).length > 0)
            currentErrorMap = {...currentErrorMap, ...errorMap}
        else
            delete currentErrorMap[field.fieldName]

        updateExternalAction({...actionListItem, [field.fieldName]: value}, currentErrorMap)
    }

    return(
        <NonExpanableListItem
            title={`${intl.formatMessage({ "id": `${settingPrefix}.${editNodePrefix}.expanable.text.action`})}#${itemIndex + 1}`}
            remove={readOnly ? null : removeExternalAction}
            readOnly={readOnly || itemIndex === 0}
        >
            <div className="flex flex-col py-4">
                {EXTERNAL_ACTION_FIELD_TYPE.map((field) => (
                    <div
                        key={field.fieldName}
                        className="py-2 flex"
                    >
                        <CusEditableLeftLabel
                            readOnly={readOnly || (!isCreate ? !field.editable : !field.creatable)}
                            fieldType={field.fieldType}
                            label={intl.formatMessage({ "id": field.displayNameKey })}
                            labelSize={45}
                            value={actionListItem[field.fieldName] ?? field.defaultValue}
                            options={getOptions(field.fieldType, field.fieldName, field.options)}
                            errors={error}
                            required={field.required}
                            errorName={field.fieldName}
                            onChange={(e) => onChangeHandler(isCreate, field, e)}
                        />
                        <div className={`p-2 text-grey051 responseMsg-pos`}>
                            <div className={`${(actionListItem[field.fieldName] ?? field.defaultValue) ? "cursor-pointer" : "opacity-50" }`} onClick={(actionListItem[field.fieldName] ?? field.defaultValue) ? (e) => openEditSettingAction(getFlowSettingById(SETTING_SUB_MENU_TYPE.ACTION, actionListItem[field.fieldName] ?? field.defaultValue), SETTING_SUB_MENU_TYPE.ACTION) : (e) => {} }>
                                <span className="text-[13px]">EDIT</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </NonExpanableListItem>
    )
}

ExternalActionListItem.propTypes = {
    isCreate: PropTypes.bool,
    readOnly: PropTypes.bool,
    itemIndex: PropTypes.number,
    actionList: PropTypes.arrayOf(PropTypes.object),
    actionListItem: PropTypes.object,
    error: PropTypes.object,
    updateExternalAction: PropTypes.func,
    removeExternalAction: PropTypes.func,
    openEditSettingAction: PropTypes.func,
    getFlowSettingById: PropTypes.func,
};

export default ExternalActionListItem;
