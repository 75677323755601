import { Handle } from 'reactflow';

const BottomHandle = () => (
    <Handle
        type="source"
        position="bottom"
        className="invisible bg-white"
    />
)

export default BottomHandle