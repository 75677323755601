import {
    GET_ATTACHMENTS,
    GET_ATTACHMENTS_FAILURE,
    GET_ATTACHMENTS_SUCCESS,
} from "store/reducers/setting/automation/chatbot/attachment";

import {
    getAttachmentList as getAttachmentService,
} from "services/attachment/attachment";
import { isJson, isSuccessResponse } from "util/helper";
import { handleAuthError } from "store/actions/auth/user";
import { ERROR_REPSONSE_LOCALE_MAP, ERROR_RESPONSE_MAP, getErrorByLocale } from "util/ErrorResponse";
import { errorPrefix } from "lang/locales/prefix";
import { getToken } from "store/reducers/auth/user";

export const getAttachmentList = () => (dispatch, getState) => {
    dispatch(GET_ATTACHMENTS());
    dispatch(GET_ATTACHMENTS_SUCCESS([]));

    // let authToken = getToken(getState().auth.user)
    // getAttachmentService({}, authToken)
    // .then(response => {
    //     const bodyString = response.data.body;
    //     const body = isJson(bodyString) ? JSON.parse(bodyString) : bodyString;
    //
    //     console.log(body)
    //
    //     if(isSuccessResponse(response)) {
    //         dispatch(GET_ATTACHMENTS_SUCCESS(body));
    //     } else {
    //         if(body === ERROR_RESPONSE_MAP.AUTH){
    //             dispatch(handleAuthError())
    //             return
    //         }
    //         if(body === ERROR_RESPONSE_MAP.PERMISSION){
    //             dispatch(GET_ATTACHMENTS_FAILURE({error: getErrorByLocale(getState, ERROR_REPSONSE_LOCALE_MAP.PERMISSION)}))
    //             return
    //         }
    //
    //         dispatch(GET_ATTACHMENTS_FAILURE({error: body.message }));
    //     }

    // })
    // .catch(error=> {
    //     const message = (typeof error.response === "string") ? error.response : 'System Error, Please contact admin for more information'
    //     dispatch(GET_ATTACHMENTS_FAILURE({error: message}))
    // })

}
