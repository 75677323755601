import DownArrow from 'components/Icon/DownArrow';
import Plus from 'components/Icon/Plus';
import UpArrow from 'components/Icon/UpArrow';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const NodeSettingListItem = ({
	isChild = false,
	isSelected = false,
	isExpanded = false,
	title = "",
	onExpand = () => {},
	selectAction = () => {},
}) => {

	return (
		<div className='w-full h-[48px] flex items-center truncate overflow-hidden'>
			{/* {isChild && <div className='w-[1px] h-full border-[#CBD5E1] border-l-[1px]'/>} */}
			{isChild && <div className='w-[10px] h-[1px] border-[#CBD5E1] border-b-[1px]'/>}
			<div
				className={`
					flex cursor-pointer truncate overflow-hidden 
					items-center border-[1px] rounded-[8px] my-3 px-3 py-2 
					border-[#CBD5E1] border-b-[1px] 
					${isChild ? "w-[calc(100%-10px)]" : "w-full"}
					${isSelected ? "bg-[#C6F0F7]" : "hover:bg-[#C6F0F7]"}`
				}
			>
				<div
					className='flex justify-center items-center text-[#94A3B8] w-[24px] h-[24px]'
					onClick={() => onExpand()}
				>
					{isExpanded ? <UpArrow/> : <DownArrow className="w-[14px] h-[8px]"/>}
				</div>
				<div
					title={title}
					className={`truncate text-base w-[calc(100%-24px)]`}
					onClick={() => selectAction()}
				>
					{title}
				</div>
			</div>
		</div>
	)
}

NodeSettingListItem.propTypes = {
	isChild: PropTypes.bool,
	isExpanded: PropTypes.bool,
	isSelected: PropTypes.bool,
	title: PropTypes.string,
	onExpand: PropTypes.func,
	selectAction: PropTypes.func,
}

export default NodeSettingListItem;