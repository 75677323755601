import BottomHandle from 'components/Flow/Handle/BottomHandle';
import TopHandle from 'components/Flow/Handle/TopHandle';
import FlowNode from 'components/Icon/FlowNode';
import Plus from 'components/Icon/Plus';
import { editChatbotPrefix, settingPrefix } from 'lang/locales/prefix';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';

const AddNode2 = memo(({data}) => {

    const intl = useIntl();

    let name = "Untitled Node"

    return (
        <>
            <TopHandle/>
            <div title={name} className={`noDrag  flex items-center justify-center rounded-[8px] bg-[#CBD5E1] w-full`}
                // onClick={(e) => data.onAddNode(e)}
            >
                <div className='w-full h-full flex flex-col p-1'>
                    <span className={`px-1 text-[13px] text-[#94A3B8]`}>{name}</span>
                    <div onClick={(e) => data.onAddNode(() => data.onShowChatCondition())} className={`flex cursor-pointer items-center rounded-[8px] bg-white w-full h-[32px] my-1 px-1 text-[#94A3B8]`}>
                        <Plus className="w-[24px] h-[24px]"/>
                        <span className={`px-2 py-1 text-[13px]s`}>Chat Condition</span>
                    </div>
                    <div onClick={(e) => data.onAddNode(() => data.onShowResponse())} className={`flex cursor-pointer items-center rounded-[8px] bg-white w-full h-[32px] my-1 px-1 text-[#94A3B8]`}>
                        <Plus className="w-[24px] h-[24px]"/>
                        <span className={`px-2 py-1 text-[13px]s`}>Response</span>
                    </div>
                    <div onClick={(e) => data.onAddNode(() => data.onShowAction())} className={`flex cursor-pointer items-center rounded-[8px] bg-white w-full h-[32px] my-1 px-1 text-[#94A3B8]`}>
                        <Plus className="w-[24px] h-[24px]"/>
                        <span className={`px-2 py-1 text-[13px]s`}>Action</span>
                    </div>
                </div>
            </div>
            <BottomHandle/>
        </>
    );
});

export default AddNode2