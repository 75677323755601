import DownArrow from "components/Icon/DownArrow";
import Minimise from "components/Icon/Minimise";
import Plus from "components/Icon/Plus";
import UpArrow from "components/Icon/UpArrow";
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';

const Expandable = ({
    className = "",
    expandedClassName = " text-[#94A3B8] ",
    notExpandedableClassName = " text-[#94A3B8] ",
    expand = true,
    setExpand = () => {},
    right = false,
    children = null,
    ...rest
}) => {

    let arrow = <div className={`${expand ? `${expandedClassName}` : `${notExpandedableClassName}`}`}>{expand ? <Minimise className="w-[24px] h-[24px]"/> : <Plus className="w-[24px] h-[24px]"/>}</div>

    return(
        children && children.length > 1 ?
            <div className={`flex flex-col w-full ${className}`} {...rest}>
                <div className={`flex items-center justify-between w-full cursor-pointer select-none ${expand ? `${expandedClassName}` : `${notExpandedableClassName}`}`} onClick={() => setExpand(!expand)}>                    
                    {right ?
                        <>
                            {children[0]}
                            {arrow}
                        </>
                        :
                        <>
                            {arrow}
                            {children[0]}
                        </>
                    }
                </div>
                {expand ? children[1] : null}
            </div>
            : null
    );
}

Expandable.propTypes = {
    className: PropTypes.string,
    expandedClassName: PropTypes.string,
    notExpandedableClassName: PropTypes.string,
    expand: PropTypes.bool,
    setExpand: PropTypes.func,
    right: PropTypes.bool,
    children: PropTypes.node,
}

export { Expandable }