const Flag = (rest) => {
    return(
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path fill="currentColor" d="M5.5 20.5C4.94772 20.5 4.5 20.0523 4.5 19.5V4.50005C4.5 3.94776 4.94772 3.50002 5.5 3.50002H11.88C12.2602 3.4974 12.6089 3.71054 12.78 4.05005L13.5 5.50005H18.5C19.0523 5.50005 19.5 5.94776 19.5 6.50005V14.5C19.5 15.0523 19.0523 15.5 18.5 15.5H13.11C12.7334 15.4989 12.3895 15.2863 12.22 14.95L11.5 13.5H6.5V19.5C6.5 20.0523 6.05228 20.5 5.5 20.5ZM6.5 5.50005V11.5H12.5L13.5 13.5H17.5V7.50005H12.5L11.5 5.50005H6.5Z"/>
        </svg>

    )
}

export default Flag;