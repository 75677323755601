

import HidePreview from "components/Icon/HidePreview";
import Preview from "components/Icon/Preview";
import PropTypes from "prop-types";
import React from 'react';
// import './style.scss';

const LabelType = Object.freeze(
    {
        "labelOnLeft": "labelOnLeft",
        "NoLabel": "NoLabel",
    }
)

const RoundType = Object.freeze(
    {
        "none": " ",
        "normal": " rounded-md "
    }
)

const BorderType = Object.freeze(
    {
        "none": " ",
        "Default": " border-2 focus-within:border-gray-700 border-gray-300 ",
        "Thin": " border-[1px] focus-within:border-gray-700 border-gray-300 ",
        "Error": " border-2 border-red01 focus-within:border-red01 "
    }
)

const TextSize = Object.freeze(
    {
        "default": " text-sm ",
        "flat": " text-[15px] "
    }
)

const PaddingType = Object.freeze(
    {
        "noPadding": " ",
        "normalPadding": " h-[40px] px-5 ",
        // "labelPadding": " pb-1 h-[34px] px-5 ",
        "labelPadding": " pb-1 h-[58px] px-5 pt-5",
        "labelPaddingFlat": " py-1 h-[58px] px-5 ",
        "flat": " py-1 h-[48px] px-5 ",
    }
)

const LabelPaddingType = Object.freeze(
    {
        "noPadding": " ",
        "normalPadding": " pt-1 px-5 ",
        "flat": " pt-1 px-8 ",
    }
)

const IconPaddingType = Object.freeze(
    {
        "noPadding": " ",
        "normalPadding": " right-3 ",
        "flat": " right-8 ",
    }
)

const CusRoundedInput = React.forwardRef(({
    hasError = false,
    value = undefined,
    placeholder = "",
    type = "text",
    disabled = false,
    readOnly = false,
    onChange = ()=>{},
    labelType = LabelType.NoLabel,
    label = "",
    className = "",
    list  = "",
    paddingType = PaddingType.normalPadding,
    labelPaddingType = "",
    textSize = TextSize.default,
    borderType = BorderType.Default,
    roundType = RoundType.normal,
    toggle = false,
    setToggle = null,
    icon = null,
    iconAction = null,
    iconPaddingType = IconPaddingType.normalPadding,
    ...rest
}, ref) => {
    return(
        <div className={`
            ${label ? `relative flex flex-col outline-none 
                ${roundType}
                ${(disabled || !!setToggle || icon) ? ""  : "pointer-events-none"}
                ${borderType ? borderType: BorderType.Default}
                ${(readOnly && !disabled) ? `opacity-100 bg-grey02`: "bg-white"}`
            : ""}           
            ${className}`}
        >
            {label ?
                <span
                    className={
                        "text-sm absolute "
                        + ((disabled || value) ? ` h-auto opacity-100 translate-y-0 transition-[opacity,transform] delay-100` : " opacity-0 h-0 -translate-y-[3px] transition-[opacity,transform] delay-100")
                        + (labelPaddingType? labelPaddingType: LabelPaddingType.normalPadding)
                        + (hasError ? " text-red01 " : " text-grey06 ")
                    }
                    // style={(disabled || value) ?
                    //     { "opacity": 1, "transform": "translateY(0)", "transitionProperty": "opacity, transform", "transitionDuration": "150ms" }
                    //     : { "opacity": 0, "transform": "translateY(-3)", "transitionProperty": "opacity, transform", "transitionDuration": "150ms" }
                    // }
                >
                    {label}
                </span>
                :
                null
            }
            {/* {label && (disabled || value) ?
                <span
                    className={
                        "text-sm "
                        + (labelPaddingType? labelPaddingType: LabelPaddingType.normalPadding)
                        + (hasError ? " text-red01 " : " text-grey06 ")
                    }                     
                >
                    {label}
                </span>
                :
                null
            } */}
            <div className={` ${!label && (icon || (!!setToggle && type === "password")) ? "relative w-full" : ""}`}>
                {disabled ?
                        <div 
                            className={"flex items-center w-full truncate outline-none text-greyblack placeholder:text-greyblack placeholder:text-sm"
                                + roundType
                                + (textSize? textSize: TextSize.default)
                                // + (label ? PaddingType.labelPadding : (paddingType? paddingType: PaddingType.normalPadding))
                                + (label ? (value? PaddingType.labelPadding : PaddingType.labelPaddingFlat) : (paddingType? paddingType: PaddingType.normalPadding))
                                + (label ? BorderType.none : (borderType? borderType: BorderType.Default))
                                + (label ? "  disabled:bg-transparent" : "disabled:bg-disabled disabled:text-black disabled:opacity-100 ")
                            } 
                            {...rest}
                        >
                            {value}
                        </div>
                    :
                        <input 
                            ref={ref}
                            className={"w-full outline-none text-greyblack transition-[padding] delay-100 "
                                + roundType
                                + (textSize? textSize: TextSize.default)
                                + (hasError ? " placeholder:text-red01 " : " placeholder:text-grey06 ")
                                + (label ? (value? "placeholder:text-sm" : "placeholder:text-base") : "")
                                + (label ? (value? PaddingType.labelPadding : PaddingType.labelPaddingFlat) : (paddingType? paddingType: PaddingType.normalPadding))
                                + (label ? BorderType.none : (borderType? borderType: BorderType.Default))
                                + (label ? " pointer-events-auto read-only:bg-transparent" : " read-only:bg-grey02 disabled:bg-disabled disabled:text-black disabled:opacity-100 ")
                            }
                            // style={(disabled || value) ?
                            //     { "transition": "150ms ease" }
                            //     : { "transition": "150ms ease" }
                            // }
                            list={list}
                            value={value}
                            placeholder={placeholder}
                            type={toggle && type === "password" ? "text" : type}
                            disabled={disabled}
                            readOnly={readOnly}
                            onChange={(e) => onChange(e)}
                            {...rest}
                        />
                }
                {(!label && !!setToggle) ?
                    <div className={`${hasError ? " text-red01" : " text-blue04"} absolute top-1/2 -translate-y-1/2 ${iconPaddingType? iconPaddingType: IconPaddingType.normalPadding} cursor-pointer`} onClick={setToggle}>
                        {toggle ? <HidePreview/> : <Preview/>}
                    </div>                        
                    : null
                }
                {(!label && icon) ?
                    <div className={`text-blue04 absolute top-1/2 -translate-y-1/2 ${iconPaddingType? iconPaddingType: IconPaddingType.normalPadding} ${iconAction ? "cursor-pointer" : ""}`} onClick={iconAction}>
                        {icon}
                    </div>                        
                    : null
                }
            </div>
            {label && !!setToggle ?
                <div className={`${hasError ? " text-red01" : " text-blue04"} flex items-center absolute top-1/2 -translate-y-1/2 ${IconPaddingType.flat} cursor-pointer`} onClick={setToggle}>
                    {toggle ? <HidePreview/> : <Preview/>}
                </div>                        
                : null
            }
            {label && icon ?
                <div className={`text-blue04 flex items-center absolute top-1/2 -translate-y-1/2 ${IconPaddingType.flat} ${iconAction ? "cursor-pointer" : ""}`} onClick={iconAction}>
                    {icon}
                </div>                        
                : null
            }
        </div>

    )
});

CusRoundedInput.propTypes = {
  className: PropTypes.string,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  label: PropTypes.string,
  labelType: PropTypes.string,
  list: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  borderType: PropTypes.string,
  roundType: PropTypes.string,
  paddingType: PropTypes.string,
  textSize: PropTypes.string,
  toggle: PropTypes.bool,
  setToggle: PropTypes.func,
  icon: PropTypes.element,
  iconAction: PropTypes.func,
  iconPaddingType: PropTypes.string,
}

export {
    CusRoundedInput,
    LabelType,
    BorderType,
    PaddingType,
    LabelPaddingType,
    IconPaddingType,
    RoundType,
    TextSize
};
