import { createSlice } from '@reduxjs/toolkit'

const INIT_STATE = { 
    token: "",
    loading: false,
    error: null
}

const User = createSlice({
    name: 'User',
    initialState: INIT_STATE,
    reducers: {
        VAILDATE_USER_SUCCESS : (state, action) => {
            state.token = action.payload
            state.loading = false;
            state.error = null;
        },
        VAILDATE_USER_FAILURE : (state, action) => {
            state.token = ""
            state.loading = false;
            state.error = null;
        }
    }
})

export const {
    VAILDATE_USER_SUCCESS,
    VAILDATE_USER_FAILURE
} = User.actions;

export default User.reducer;

export const getToken = state => state.token;
export const getLoading = state => state.loading;
export const getError = state => state.error;