import { createSlice } from '@reduxjs/toolkit'

const INIT_STATE = {
    keywordList: [],
    loading: false,
    error: null
}

const Keyword = createSlice({
    name: 'Keyword',
    initialState: INIT_STATE,
    reducers: {
        GET_KEYWORDS: (state) => {
            state.loading = true;
            state.error = null;
        },
        GET_KEYWORDS_SUCCESS: (state, action) => {
            state.loading = false;
            state.keywordList = action.payload;
            state.error = null;
        },
        GET_KEYWORDS_FAILURE: (state, action) => {
            state.loading = false;
            state.keywordList = [];
            state.error = action.payload.error;
        },

        BULK_KEYWORD: (state) => {
            state.loading = true;
            state.error = null;
        },
        BULK_KEYWORD_SUCCESS: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        BULK_KEYWORD_FAILURE: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
        },

    }
})

export const {
    GET_KEYWORDS,
    GET_KEYWORDS_FAILURE,
    GET_KEYWORDS_SUCCESS,
    BULK_KEYWORD,
    BULK_KEYWORD_SUCCESS,
    BULK_KEYWORD_FAILURE,
} = Keyword.actions;


export default Keyword.reducer;

export const getKeywordList = state => state.keywordList;
export const getLoading = state => state.loading;
export const getError = state => state.error;