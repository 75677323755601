import PropTypes from 'prop-types';


const LivechatPanel = ({
    children = null,
    showShadow = false,
    hasFooter = false,
    containerHeight = ""
}) => {

    return (
        <div className={`flex flex-col w-[350px] rounded-[20px] overflow-hidden ${showShadow ? "shadow" : ""} ${containerHeight}`}>
            <div className="rounded-t-[20px] flex bg-blue04 text-white w-full h-[50px]">
                {children[0]}
            </div>
            <div className="flex-1">
                {children[1]}
            </div>
            {(hasFooter && !!children[2]) ?
                children[2]
                :
                null
            }
        </div>
    )
}

LivechatPanel.propTypes = {
    children: PropTypes.node,
    showShadow: PropTypes.bool,
    hasFooter: PropTypes.bool,
    containerHeight: PropTypes.string,
};

export default LivechatPanel;