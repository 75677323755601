
// https://stackoverflow.com/questions/5805059/how-do-i-make-a-placeholder-for-a-select-box
import DownArrow from "components/Icon/DownArrow";
import PropTypes from "prop-types";
import React from 'react';
import './index.css'

const CusUnderlineSelect = React.forwardRef(({
    hasError = false,
    name = "",
    placeholder = "",
    invalidValueLabel = "",
    optionEmptyLabel = "",
    value = "",
    type = "",
    disabled = false,
    readOnly = false,
    onChange = ()=>{},
    options = [],
    className = "",
    paddingType = " h-[40px] px-3 ",
    textSize = " text-base ",
    ...rest
}, ref) => {

    const hasEmptyOptionsValue = () => {
        if(options.length < 1)
            return false
        let index = options.findIndex(option => !option.value);
        return index !== -1
    }

    const isNonExistedValue = (value) => {
        if(!value)
            return false

        let index = options.findIndex(option => option.value === value);

        return index === -1
    }

    const getLabelFromValue = (value) => {
        if(!value)
            return ""

        let index = options.findIndex(option => option.value === value);

        return index === -1 ? "" : options[index].label
    }

    return(
        <div className={
            "relative w-full truncate"
            + textSize
            + paddingType
        }>
            {!readOnly && !disabled &&
                <select
                    placeholder={placeholder}
                    value={value}
                    type={type}
                    name={name}
                    className={
                        "peer cus-select w-full outline-none text-greyblack pointer-events-auto cursor-pointer rounded-[4px] opacity-0 "
                        + textSize
                        + paddingType
                    }
                    readOnly={readOnly}
                    disabled={readOnly || disabled}
                    onChange={(e) => onChange(e)}
                    {...rest}
                    ref={ref} 
                    >
                        {hasEmptyOptionsValue() ?
                            null
                            :
                            <option value="" disabled hidden>{placeholder}</option>
                        }
                        {invalidValueLabel && isNonExistedValue(value) ?
                            <option value={value} disabled hidden>{invalidValueLabel}</option>
                            :
                            null
                        }
                        {options.length < 1 ?
                            <option value="" disabled>{placeholder}</option>
                            :
                            null
                        }
                        {options.map(option => 
                            <option key={option.value} value={option.value} >{option.label}</option>
                        )} 
                                                
                </select>
            }
            <div className={
                "absolute top-0 left-0 w-full h-full pointer-events-none bg-transparent rounded-[4px] "
                + (hasError ? " bg-[#FFF1F2] " : ((disabled || readOnly) ? "" : " peer-hover:bg-[#E0F6FA] "))
            }>
                <div 
                    className={
                        "flex items-center w-full overflow-hidden outline-none text-base bg-inherit z-[2] "
                    }
                >
                    <div 
                        className={"flex items-center max-w-[calc(100%-40px)] truncate overflow-hidden outline-none text-base "
                            + (!!value ?
                                    `${(disabled || readOnly) ? " text-[#94A3B8] " : " text-greyblack "}`
                                :
                                    " text-[#CBD5E1] "
                            )
                            + textSize
                            + paddingType
                        } 
                    >
                        <span className="truncate">
                            {!!value ? (getLabelFromValue(value) ?? placeholder) : placeholder}
                        </span>
                    </div>
                    <div className={`${!!value ? ((disabled || readOnly) ? " text-[#94A3B8] " : " text-greyblack ") : " text-[#94A3B8] "} w-[40px] h-[10px] flex items-end justify-center`}>
                        <DownArrow/>
                    </div>
                </div>
            </div>
        </div>
    )

});

CusUnderlineSelect.propTypes = {
  className: PropTypes.string,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  invalidValueLabel: PropTypes.string,
  optionEmptyLabel: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  paddingType: PropTypes.string,
  textSize: PropTypes.string,
}

export { 
    CusUnderlineSelect,
};