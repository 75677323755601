import PropTypes from 'prop-types';
import Frame from './Frame';
import Title from './Title';
import Overlay from 'components/Overlay';
import Page from './Page';

const CusModal = ({
    show = false,
    className = "",
    full = false,
    title = "",
    children = null
}) => (
    <Overlay full={full} className={` bg-[#d6d6d6ab] text-[#989898] z-[1150] ${className}`} show={show}>
        <div className='flex flex-col items-center'>
            {title ? <b className="p-4 text-2xl">{title}</b> : null}
            {children}
        </div>
    </Overlay>
)

CusModal.propTypes = {
    show: PropTypes.bool,
    className: PropTypes.string,
    full: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.node,
};

CusModal.Frame = Frame;
CusModal.Page = Page;
CusModal.Title = Title;

export default CusModal