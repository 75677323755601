import { chatpanelPrefix, chatpanelConversationsPrefix, chatpanelMessagesPrefix, chatTransferPrefix } from "../prefix";

const chatpanelBase = {
    [`${chatpanelPrefix}.modal.content.warning.offline`]: "打開在線開始聊天。",
    [`${chatpanelPrefix}.modal.content.warning.reconnect`]: "重新連接",

    [`${chatpanelPrefix}.button.keepOffline`]: "保持離線狀態",

    [`${chatpanelPrefix}.loadingMessage.offline`]: "請將用戶會話更改為在線以激活聊天面板",
    [`${chatpanelPrefix}.loadingMessage.networkDisconnected`]: "網絡斷開",
    [`${chatpanelPrefix}.loadingMessage.pending`]: "正在更新會話...",
    [`${chatpanelPrefix}.loadingMessage.webSocketClosed`]: "聊天面板已關閉",
}

const chatPanelConversation = {
    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.tab.title.open`]: "打開",
    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.tab.title.closed`]: "關閉",

    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.notice.message.search`]: "已找到消息",

    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.notice.message.empty`]: "找不到消息。",
    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.notice.conversation.closed`]: "1天內關閉的聊天記錄",
    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.notice.conversation.empty`]: "沒有打開聊天。",

    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.nameList.sorting.timestamp`]: "時間（最近）",
    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.nameList.sorting.channel`]: "頻道",

    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.nameList.optionMenu.pin`]: "Pin Chat",
    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.nameList.optionMenu.unPin`]: "Unpin Chat",
    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.nameList.optionMenu.markUnread`]: "標記為未讀",
}

const chatPanelMessage = {
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.button.close`]: "關閉聊天",

    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.modal.title.warning`]: "警告",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.modal.content.warning.close`]: "關閉聊天？",

    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.text.chatTags`]: "聊天標籤",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.text.preview`]: "預覽",

    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.notice.conversation.empty`]: "沒有打開或選擇聊天。",

    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.search.text.pinned`]: "PINNED MESSAGE",

    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.quickText.expandAll`]: "全部展開",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.quickText.folderType.public`]: "公開",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.quickText.folderType.personal`]: "個人",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.quickText.categories`]: "所有類別",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.quickText.subCategories`]: "所有子類別",
    
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.messageInput.textarea.placeholder.typeMessage`]: "輸入消息",

    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.messageInput.button.quickText`]: "快速文本",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.messageInput.button.send`]: "發送",

    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.messageInput.nameList.menu.title`]: "分享",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.messageInput.nameList.menu.document`]: "文檔",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.messageInput.nameList.menu.image`]: "圖片",
}

const chatTransfer = {
    [`${chatpanelPrefix}.${chatTransferPrefix}.nameList.menu.title`]: "代理或隊列",
    [`${chatpanelPrefix}.${chatTransferPrefix}.nameList.menu.all`]: "所有代理/隊列",
    [`${chatpanelPrefix}.${chatTransferPrefix}.nameList.menu.queue`]: "隊列",
    [`${chatpanelPrefix}.${chatTransferPrefix}.nameList.menu.agent`]: "個人代理",

    [`${chatpanelPrefix}.${chatTransferPrefix}.info.count`]: "在線: ",

    [`${chatpanelPrefix}.${chatTransferPrefix}.text.transferMessage`]: "轉移消息",
    [`${chatpanelPrefix}.${chatTransferPrefix}.input.placeholder.transferMessage`]: "寫消息",
}

export const chatpanel = {
    ...chatpanelBase,
    ...chatPanelConversation,
    ...chatPanelMessage,
    ...chatTransfer,
}