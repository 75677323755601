import PropTypes from 'prop-types';
import React from 'react';
import "./index.css"

const CircularLoading = ({
    width = '70px',
    height = '70px',
    border = 'solid 5px #00B1FF'
}) => {

    return (
        <div className="circular-load"
            style={{
                width: width, height: height,
                border: border,
                borderRadius: '50%',
                borderRightColor: 'transparent',
                borderBottomColor: 'transparent'
            }}
        />
    )
}

CircularLoading.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    border: PropTypes.string,
};

export default CircularLoading;