import PropTypes from "prop-types";
import React from 'react';

const Color = Object.freeze(
    {
        "primary": " text-blue04 hover:text-blue04-dark ",
        "small": " text-grey06 ",
        "none": ""
    }
)

const TextSize = Object.freeze(
    {
        "default": "text-sm",
        "flat": "text-base",
        "none": ""
    }
)

const CusLink = ({
    className = "",
    onClick = null,
    children = null,
    color = Color.primary,
    textSize = TextSize.default,
    ...rest
}) => (
    <a href="!#"
        className={`self-center mb-2 cursor-pointer ${color} ${textSize} ${className}`}
        onClick={(e) => {e.preventDefault();onClick()}}
        {...rest}
    >
        {children}
    </a>
)


CusLink.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.node,
    color: PropTypes.string,
    textSize: PropTypes.string,
  }

export {
    CusLink,
    Color,
    TextSize
};