const List = (rest) => {
    return(
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M4.6665 21.9935V9.82682C4.6665 9.15749 4.9025 8.58738 5.3745 8.11649C5.8465 7.6456 6.41395 7.41016 7.07684 7.41016H24.9228C25.5857 7.41016 26.1532 7.6456 26.6252 8.11649C27.0972 8.58738 27.3332 9.15749 27.3332 9.82682V21.9935C27.3332 22.6626 27.0972 23.2326 26.6252 23.7035C26.1532 24.1746 25.5857 24.4102 24.9228 24.4102H7.07684C6.41395 24.4102 5.8465 24.1746 5.3745 23.7035C4.9025 23.2326 4.6665 22.6626 4.6665 21.9935ZM6.6665 12.4102H9.6665V9.41016H7.07684C6.95706 9.41016 6.85873 9.4486 6.78184 9.52549C6.70495 9.60238 6.6665 9.70071 6.6665 9.82049V12.4102ZM11.6665 12.4102H25.3332V9.82049C25.3332 9.70071 25.2947 9.60238 25.2178 9.52549C25.1409 9.4486 25.0426 9.41016 24.9228 9.41016H11.6665V12.4102ZM11.6665 17.4102H25.3332V14.4102H11.6665V17.4102ZM11.6665 22.4102H24.9228C25.0426 22.4102 25.1409 22.3717 25.2178 22.2948C25.2947 22.2177 25.3332 22.1194 25.3332 21.9998V19.4102H11.6665V22.4102ZM7.07684 22.4102H9.6665V19.4102H6.6665V21.9998C6.6665 22.1194 6.70495 22.2177 6.78184 22.2948C6.85873 22.3717 6.95706 22.4102 7.07684 22.4102ZM6.6665 17.4102H9.6665V14.4102H6.6665V17.4102Z" fill="#64748B"/>
            <path d="M4.6665 21.9935V9.82682C4.6665 9.15749 4.9025 8.58738 5.3745 8.11649C5.8465 7.6456 6.41395 7.41016 7.07684 7.41016H24.9228C25.5857 7.41016 26.1532 7.6456 26.6252 8.11649C27.0972 8.58738 27.3332 9.15749 27.3332 9.82682V21.9935C27.3332 22.6626 27.0972 23.2326 26.6252 23.7035C26.1532 24.1746 25.5857 24.4102 24.9228 24.4102H7.07684C6.41395 24.4102 5.8465 24.1746 5.3745 23.7035C4.9025 23.2326 4.6665 22.6626 4.6665 21.9935ZM6.6665 12.4102H9.6665V9.41016H7.07684C6.95706 9.41016 6.85873 9.4486 6.78184 9.52549C6.70495 9.60238 6.6665 9.70071 6.6665 9.82049V12.4102ZM11.6665 12.4102H25.3332V9.82049C25.3332 9.70071 25.2947 9.60238 25.2178 9.52549C25.1409 9.4486 25.0426 9.41016 24.9228 9.41016H11.6665V12.4102ZM11.6665 17.4102H25.3332V14.4102H11.6665V17.4102ZM11.6665 22.4102H24.9228C25.0426 22.4102 25.1409 22.3717 25.2178 22.2948C25.2947 22.2177 25.3332 22.1194 25.3332 21.9998V19.4102H11.6665V22.4102ZM7.07684 22.4102H9.6665V19.4102H6.6665V21.9998C6.6665 22.1194 6.70495 22.2177 6.78184 22.2948C6.85873 22.3717 6.95706 22.4102 7.07684 22.4102ZM6.6665 17.4102H9.6665V14.4102H6.6665V17.4102Z" fill="black" fillOpacity="0.2"/>
            <path d="M4.6665 21.9935V9.82682C4.6665 9.15749 4.9025 8.58738 5.3745 8.11649C5.8465 7.6456 6.41395 7.41016 7.07684 7.41016H24.9228C25.5857 7.41016 26.1532 7.6456 26.6252 8.11649C27.0972 8.58738 27.3332 9.15749 27.3332 9.82682V21.9935C27.3332 22.6626 27.0972 23.2326 26.6252 23.7035C26.1532 24.1746 25.5857 24.4102 24.9228 24.4102H7.07684C6.41395 24.4102 5.8465 24.1746 5.3745 23.7035C4.9025 23.2326 4.6665 22.6626 4.6665 21.9935ZM6.6665 12.4102H9.6665V9.41016H7.07684C6.95706 9.41016 6.85873 9.4486 6.78184 9.52549C6.70495 9.60238 6.6665 9.70071 6.6665 9.82049V12.4102ZM11.6665 12.4102H25.3332V9.82049C25.3332 9.70071 25.2947 9.60238 25.2178 9.52549C25.1409 9.4486 25.0426 9.41016 24.9228 9.41016H11.6665V12.4102ZM11.6665 17.4102H25.3332V14.4102H11.6665V17.4102ZM11.6665 22.4102H24.9228C25.0426 22.4102 25.1409 22.3717 25.2178 22.2948C25.2947 22.2177 25.3332 22.1194 25.3332 21.9998V19.4102H11.6665V22.4102ZM7.07684 22.4102H9.6665V19.4102H6.6665V21.9998C6.6665 22.1194 6.70495 22.2177 6.78184 22.2948C6.85873 22.3717 6.95706 22.4102 7.07684 22.4102ZM6.6665 17.4102H9.6665V14.4102H6.6665V17.4102Z" fill="black" fillOpacity="0.2"/>
            <path d="M4.6665 21.9935V9.82682C4.6665 9.15749 4.9025 8.58738 5.3745 8.11649C5.8465 7.6456 6.41395 7.41016 7.07684 7.41016H24.9228C25.5857 7.41016 26.1532 7.6456 26.6252 8.11649C27.0972 8.58738 27.3332 9.15749 27.3332 9.82682V21.9935C27.3332 22.6626 27.0972 23.2326 26.6252 23.7035C26.1532 24.1746 25.5857 24.4102 24.9228 24.4102H7.07684C6.41395 24.4102 5.8465 24.1746 5.3745 23.7035C4.9025 23.2326 4.6665 22.6626 4.6665 21.9935ZM6.6665 12.4102H9.6665V9.41016H7.07684C6.95706 9.41016 6.85873 9.4486 6.78184 9.52549C6.70495 9.60238 6.6665 9.70071 6.6665 9.82049V12.4102ZM11.6665 12.4102H25.3332V9.82049C25.3332 9.70071 25.2947 9.60238 25.2178 9.52549C25.1409 9.4486 25.0426 9.41016 24.9228 9.41016H11.6665V12.4102ZM11.6665 17.4102H25.3332V14.4102H11.6665V17.4102ZM11.6665 22.4102H24.9228C25.0426 22.4102 25.1409 22.3717 25.2178 22.2948C25.2947 22.2177 25.3332 22.1194 25.3332 21.9998V19.4102H11.6665V22.4102ZM7.07684 22.4102H9.6665V19.4102H6.6665V21.9998C6.6665 22.1194 6.70495 22.2177 6.78184 22.2948C6.85873 22.3717 6.95706 22.4102 7.07684 22.4102ZM6.6665 17.4102H9.6665V14.4102H6.6665V17.4102Z" fill="black" fillOpacity="0.2"/>
        </svg>
    )
}

export default List;