import PropTypes from 'prop-types';

import { EDITABLE_TYPE, setDefaultField, validateField } from 'components/CusEditable';
import CusEditableLeftLabel from 'components/CusEditable/CusEditableLeftLabel';
import NonExpanableListItem from 'components/NonExpanableListItem';
import { useLanguage } from 'hooks/useLanguage';
import { checkListItemDuplication } from 'util/helper';
import { useIntl } from 'react-intl';
import { editResponsePrefix, settingPrefix } from 'lang/locales/prefix';

export const RESPONSE_OPTION_FIELD_TEMPLATE = Object.freeze({
    "name" : "",
    "value" : "",
})

export const OPTION_FIELD_TYPE = [
    {
        "fieldName": "name",
        "displayNameKey": `${settingPrefix}.${editResponsePrefix}.field.option.name`,
        "displayNameEn": "Option name",
        "displayNameTc": "選項名稱",
        "displayNameSc": "选项名称",
        "fieldType": EDITABLE_TYPE.TEXT,
        "required": true,
        "editable": true,
        "creatable": true,
    },
    {
        "fieldName": "value",
        "displayNameKey": `${settingPrefix}.${editResponsePrefix}.field.option.value`,
        "displayNameEn": "Option value",
        "displayNameTc": "選項值",
        "displayNameSc": "选项值",
        "fieldType": EDITABLE_TYPE.TEXT,
        "required": true,
        "editable": true,
        "creatable": true,
    },
]

export const validateOptionListItems = (errorFormater, isCreate, fieldList) => {
    let errorList = [];

    for(let index = 0; index < fieldList.length; index++){
        let fieldListItem = {...fieldList[index]}
        let errorMap = {}

        let mapping = [...OPTION_FIELD_TYPE]

        for(let field of mapping){
            if(!fieldListItem[field.fieldName])
                setDefaultField(field, fieldListItem, field.fieldName, isCreate)
            errorMap = {...errorMap, ...validateField(field, fieldListItem[field.fieldName], errorFormater, isCreate)}
        }

        errorList[index] = errorMap
    }

    let fieldSet = fieldList.map(field => field.name)

    let duplicates = checkListItemDuplication(fieldSet)
    duplicates.forEach(i => {
        if(!errorList[i])
            errorList[i] = {}

        errorList[i].name = { message: "Option name already exists" }
    })

    return errorList
}


const ResponseOptionListItem = ({
    isCreate = false,
    isEdit = false,
    isEditable = false,
    itemIndex = -1,
    fieldListItem = null,
    error = null,
    updateField = null,
    removeField = null,
}) => {

    const intl = useIntl();
    const { language } = useLanguage()

    const getOptions = (fieldType, fieldName, options) => {
        if(fieldType !== EDITABLE_TYPE.PICKLIST)
            return []

        return options.map(option => ({label: option, value: option}))
    }

    const onChangeHandler = (isCreate, field, e) => {
        
        let value = e.target.value;
        let currentErrorMap = {...error};
        let errorMap = {};

        switch (field.fieldType) {
            case EDITABLE_TYPE.BOOLEAN:
            case EDITABLE_TYPE.BOOLEAN_CHECKBOX:
                value = !fieldListItem[field.fieldName]
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;        
            default:
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;
        }

        if(errorMap && Object.keys(errorMap).length > 0)
            currentErrorMap = {...currentErrorMap, ...errorMap}
        else
            delete currentErrorMap[field.fieldName]

        console.log(currentErrorMap)

        updateField({...fieldListItem, [field.fieldName]: value}, currentErrorMap)
    }

    return(
        <NonExpanableListItem
            title={`${intl.formatMessage({ "id": `${settingPrefix}.${editResponsePrefix}.expanable.text.option`})}#${itemIndex + 1}`}
            remove={(!isEdit || !isEditable) ? null : removeField}
            readOnly={(!isEdit || !isEditable)}
        >
            {OPTION_FIELD_TYPE.map((field) => (
                <div key={field.fieldName} className='py-2'>
                    <CusEditableLeftLabel
                        readOnly={(!isEdit || !isEditable) || (!isCreate ? !field.editable : !field.creatable)}
                        fieldType={field.fieldType}
                        showLabelOnLeft={false}
                        label={field.displayNameEn}
                        value={fieldListItem[field.fieldName] ?? field.defaultValue}
                        options={getOptions(field.fieldType, field.fieldName, field.options)}
                        errors={error}
                        required={field.required}
                        errorName={field.fieldName}
                        onChange={(e) => onChangeHandler(isCreate, field, e)}
                    />
                </div>
            ))}
        </NonExpanableListItem>
    )
}

ResponseOptionListItem.propTypes = {
    isCreate: PropTypes.bool,
    isEdit: PropTypes.bool,
    isEditable: PropTypes.bool,
    itemIndex: PropTypes.number,
    fieldListItem: PropTypes.object,
    error: PropTypes.object,
    updateField: PropTypes.func,
    removeField: PropTypes.func,
};

export default ResponseOptionListItem;
