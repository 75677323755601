import PropTypes from 'prop-types';

const TextSize = Object.freeze(
    {
        "default": " text-sm",
        "flat": " "
    }
)

const LabelContentPaddingType = Object.freeze(
    {
        "noPadding": " ",
        "normalPadding": " py-1 h-[34px] pl-5 pr-5 ",
        "flat": " py-1 h-[58px] px-8 ",
    }
)

const PaddingType = Object.freeze(
    {
        "noPadding": " ",
        "normalPadding": " w-2 h-2 ",
        "flat": " w-5 h-5 ",
    }
)

const LabelPaddingType = Object.freeze(
    {
        "noPadding": " ",
        "normalPadding": " pt-1 pl-5 pr-5 ",
        "flat": " pt-1 px-8 ",
    }
)

const ToogleSize = Object.freeze(
    {
        "default": " w-[60px] h-[40px]",
        "small": " w-[50px] h-[28px]"
    }
)

const SilderSize = Object.freeze(
    {
        "default": " w-[26px] h-[26px]",
        "small": " w-[20px] h-[20px]"
    }
)

const CusToggleSwitch = ({
    checked = false,
    disabled = false,
    readOnly = false,
    onChange = ()=>{},
    showSwitchOnDisabled = false,
    className = "",
    label = "",
    paddingType = PaddingType.normalPadding,
    labelPaddingType = "",
    labelContentPaddingType = LabelContentPaddingType.normalPadding,
    textSize = "",
    toogleSize = ToogleSize.default,
    silderSize = SilderSize.default,
    ...rest}) => {

    return (
        <div className={`
            ${label ? `flex flex-col rounded-md select-none outline-none 
                ${disabled ? "" : "pointer-events-none"}`
            : "flex items-center"}           
            ${className}`}
        >
            {label ?
                <span
                    className={
                        "text-sm text-grey06"
                        + (labelPaddingType? labelPaddingType: LabelPaddingType.normalPadding)
                    }                     
                >
                    {label}
                </span>
                :
                null
            }
            <div className={"flex items-center" + (label ? (labelContentPaddingType? labelContentPaddingType: LabelContentPaddingType.normalPadding) : "")}>
                {(disabled && !showSwitchOnDisabled) ?
                        <div 
                            className={"flex items-center w-full truncate outline-none text-greyblack placeholder:text-greyblack placeholder:text-sm"
                                + (textSize? textSize: TextSize.default)
                                + (label ? "  disabled:bg-transparent" : "disabled:bg-disabled disabled:text-black disabled:opacity-100 ")
                            } 
                            {...rest}
                        >
                            {checked ? "True" : "False"}
                        </div>
                    :
                        <span className={`pointer-events-auto relative inline-block rounded-full ${toogleSize ? toogleSize : ToogleSize.default} ${(readOnly || disabled) ? `${checked ? "bg-[#aad9ff]" : "bg-[#ccc]"}` : `cursor-pointer ${checked ? "bg-[#2196F3]" : "bg-[#aaa]"}`} ` }
                            // className={"toogle-switch" + (small ? " small-toogle " : "") + (checked ? " toogle-checked " : " ") + (disabled ? " toogle-disabled " : " ") + className}
                            onClick={(readOnly || disabled) ? () => null : onChange} {...rest}>
                            <span className={` absolute rounded-full content-[""] left-1 bottom-1 bg-white transition ${checked ? `${toogleSize === ToogleSize.small ? "left-[26px]" : "left-[29px]"}` : ""} ${silderSize ? silderSize : SilderSize.default}`}></span>
                        </span>            
                }
            </div>
        </div>        
    )
}


CusToggleSwitch.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    showSwitchOnDisabled: PropTypes.bool,
    paddingType: PropTypes.string,
    labelContentPaddingType: PropTypes.string,
    toogleSize: PropTypes.string,
    silderSize: PropTypes.string,  
}

export {
    CusToggleSwitch,
    PaddingType,
    LabelPaddingType,
    LabelContentPaddingType,
    ToogleSize,
    SilderSize
};