import { errorPrefix } from "../prefix";

export const errors = {
    [`${errorPrefix}.defaulterror.default`]: "Unexpected Error. Please try again later",
    [`${errorPrefix}.10001`]: "Username and password does not match the policy",
    [`${errorPrefix}.10002`]: "Invalid username and password",
    [`${errorPrefix}.10003`]: "User is not confirmed",
    [`${errorPrefix}.10004`]: "Username already exist",
    [`${errorPrefix}.10005`]: "Confirmation code is expired",
    [`${errorPrefix}.10006`]: "Confirmation code is not matched",
    [`${errorPrefix}.10007`]: "Member is not activated",
    [`${errorPrefix}.10008`]: "User does not exist",
    [`${errorPrefix}.10009`]: "New Password Required",
    [`${errorPrefix}.10010`]: "Session not found",
    [`${errorPrefix}.10011`]: "User is disabled. Please contact admin",
    [`${errorPrefix}.10012`]: "Password attempts exceeded. Please try again later",
    [`${errorPrefix}.10013`]: "User Expired. Please change password",
 
    [`${errorPrefix}.permission`]: "You do not have the access right",
    [`${errorPrefix}.getUserSession`]: "Get User Session Failed",
    [`${errorPrefix}.updateUser`]: "Update User Failed",
    [`${errorPrefix}.updateUserSession`]: "Update User Session Failed",
    [`${errorPrefix}.updatePassword`]: "Update Password Failed",
    [`${errorPrefix}.updateUserImage`]: "Update User Image Failed",

    [`${errorPrefix}.required`]: " is required",
    [`${errorPrefix}.invalid`]: "Invalid ",
    [`${errorPrefix}.passwordMismatch`]: "Your passwords do not match",

    [`${errorPrefix}.requiredField`]: "Field is required",
    [`${errorPrefix}.invalidOption`]: "Please Select a valid option",
    [`${errorPrefix}.requiredOption`]: "At least one option should be selected",
    [`${errorPrefix}.invalidNumber`]: "Field is not a Number",
    [`${errorPrefix}.maxNumber`]: "Max Value: ",
    [`${errorPrefix}.minNumber`]: "Min Value: ",
    [`${errorPrefix}.maxNumberChars`]: "Max Integer characters: ",
    [`${errorPrefix}.maxDecimalPlace`]: "Max Decimal Place: ",
    [`${errorPrefix}.invalidRegex`]: "Failed regex condition",
    [`${errorPrefix}.invalidRegex.safeRegex`]: "Field can only contain alphanumeric, space or underscore",
    [`${errorPrefix}.maxChars`]: "Max characters: ",
    [`${errorPrefix}.invalidDateRange`]: "Failed date out of range",
    [`${errorPrefix}.invalidDateTimeRange`]: "Failed date and time out of range",
    [`${errorPrefix}.duplicates`]: "Please remove duplicates",

    [`${errorPrefix}.invalid.file`]: "Invalid file",
    [`${errorPrefix}.invalid.file.size`]: "File size exceed support limit",
    [`${errorPrefix}.invalid.image`]: "Invalid Image Format",

    [`${errorPrefix}.no.image`]: "Image not found",
 
 }