import {
    SET_LANGUAGE,
    SET_PAGE_SIZE,
    SET_PAGE_SORT,
    CLEAN_PAGE_SIZE
} from 'store/reducers/ui/setting'

export const setLanguage = (language) => (dispatch) => {
    localStorage.setItem("locale", JSON.stringify(language) );    
    dispatch(SET_LANGUAGE(language));
}

export const setPageSize = (pageSizeSetting) => (dispatch) => {
    let pageSize = localStorage.getItem("pageSize") ? JSON.parse(localStorage.getItem("pageSize") ) : {}
    localStorage.setItem("pageSize", JSON.stringify({...pageSize, ...pageSizeSetting}))
    dispatch(SET_PAGE_SIZE(pageSizeSetting))
}

export const setPageSort = (pageSortSetting) => (dispatch) => {
    let pageSort = localStorage.getItem("pageSort") ? JSON.parse(localStorage.getItem("pageSort") ) : {}
    localStorage.setItem("pageSort", JSON.stringify({...pageSort, ...pageSortSetting}))
    dispatch(SET_PAGE_SORT(pageSortSetting))
}

export const cleanPageSize = () => (dispatch) => {
    localStorage.removeItem("pageSize")
    dispatch(CLEAN_PAGE_SIZE())
}