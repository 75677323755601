import Axios from 'axios';
import AppConfig from 'constants/AppConfig';


const httpPost = (endpoint, data, authToken, opts) => {

    const requestConfig = {
        method: 'post',
        withCredentials : true,
        url: `${AppConfig.backend_url}/${endpoint}`,
        data: JSON.stringify(data),
        headers:{
            'Content-Type': 'application/json', 
            // 'Authorization': authToken ? ('Bearer ' + authToken) : null,
            // DEV-ONLY
            // 'Authorization': `session=tXBnJhgxzql1LoQFUrLn_tXBnJhgxzql1LoQFUrLnqPwfKYoBqBucKySxMUUAYIuI_DuELWwJwznXWsWQvzFWqTvYR`,
            'Authorization': authToken ? `Bearer ${authToken}` : null,
        },
        ...opts
    }

    return Axios(requestConfig);

}

export{
    httpPost
}
