import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import CusGroupDropdown from 'components/CusGroupDropdown';
import { BorderType as InputBorder } from 'components/CusRoundedInput';
import CusRoundedSearch from 'components/CusRoundedInput/CusRoundedSearch';
import Bin from 'components/Icon/Bin';
import CirclePlus from 'components/Icon/CirclePlus';
import Scrollbars from 'react-custom-scrollbars-2';
import Option from 'components/Icon/NewChatbotIcon/Option';
import { Color as ButtonColor, CusRoundedButton, PaddingType as ButtonPadding, RoundType as ButtonRoundType } from 'components/CusRoundedButton';
import DynamicContent from 'components/DynamicContent';

const FlowSettingList = ({
    readOnly = false,
    searchTitle = "",
    addTitle = "",
    settingList = [],
    fieldName = "",
    addNewAction = () => {},
    editAction = () => {},
    // checkRemoveable = () => true,
    cloneAction = () => {},
    removeAction = () => {},
}) =>{

    const [selectedSetting, setSelectedSetting] = useState(null);

    const [showMenu, setShowMenu] = useState(false);
    const [menuPos, setMenuPos] = useState(null);

    const [searchInput, setSearchInput] = useState("");

    const getFilteredSettingList = () => {
        let filteredSettingList = JSON.parse(JSON.stringify(settingList));

        if(filteredSettingList.length < 1)
            return filteredSettingList;

        filteredSettingList = filteredSettingList.filter(setting => setting[fieldName].toLowerCase().includes(searchInput.toLowerCase()))

        return filteredSettingList;

    }

    const openSettingAction = (e, setting) => {
        let pos = e.target.closest('.menu-pos').getBoundingClientRect()
        console.log(pos)
        setSelectedSetting(setting)
        setMenuPos({x: pos.right, y: pos.bottom + 5})
        setShowMenu(true)          
    }

    const closeSettingAction = () => {
        setMenuPos(null);
        setShowMenu(false);

        setSelectedSetting(null)
    }

    const onCloneAction = () => {
        if(cloneAction)
            cloneAction(selectedSetting)

        closeSettingAction()
    }

    const onRemoveAction = () => {
        if(removeAction)
            removeAction(selectedSetting)

        closeSettingAction()
    }

    useEffect(() => {
        setSearchInput("")
    }, [settingList])

    let filteredSettingList = getFilteredSettingList();

	return (
        <>
            <div className='px-4 w-full h-full'>
                <div className="px-2 w-full h-[56px]">
                    <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex justify-center text-base w-full h-[40px] items-center rounded-[5px]' color={ButtonColor.primary}
                        onClick={() => addNewAction()}
                    >
                        <span className="px-3">{addTitle}</span>
                    </CusRoundedButton>
                </div>
                <span className='h-[18px] text-[#64748B]'>LIST</span>
                <div className="w-full py-4 h-[calc(100%-56px-18px)]">
                    <Scrollbars
                            autoHide={false}
                            className='w-full h-full'
                    >
                        {filteredSettingList.map((setting) => (
                            <div
                                key={`setting-list-${setting.id}`}
                                className='py-1 w-full flex justify-between items-center'
                            >
                                <div
                                    title={setting[fieldName]}
                                    className='cursor-pointer truncate overflow-hidden w-[calc(100%-24px)] border-[#E2E8F0] border-[1px] rounded-[8px] mr-2 px-4 py-3 menu-pos'
                                    onClick={() => editAction(setting)}
                                >
                                    <span className='truncate text-base'>{setting[fieldName]}</span>
                                </div>
                                {!readOnly &&
                                    <div
                                        className='text-[#94A3B8] cursor-pointer menu-pos'
                                        onClick={(e) => openSettingAction(e, setting)}
                                    >
                                        <Option className='w-[24px] h-[24px]'/>
                                    </div>
                                }
                            </div>
                        ))}
                    </Scrollbars>
                </div>
            </div>
            <DynamicContent screenX={menuPos ? menuPos.x : undefined} screenY={menuPos ? menuPos.y : -1} show={showMenu} setShow={setShowMenu} onUnFocus={closeSettingAction}>
                <CusGroupDropdown cardSize="w-[200px] h-auto">
                    <>
                        <CusGroupDropdown.Item
                            onClick={(e) => onCloneAction()}
                        >
                            <span className='mx-[2px] text-base text-greyblack'>Clone</span>
                        </CusGroupDropdown.Item>
                        <CusGroupDropdown.Item
                            onClick={() => onRemoveAction()}
                        >
                            <span className='mx-[2px] text-base text-[#F43F5E]'>Remove</span>
                        </CusGroupDropdown.Item>                   
                    </>
                </CusGroupDropdown>
            </DynamicContent>            
        </>
	);
	
}

FlowSettingList.propTypes = {
    readOnly: PropTypes.bool,
    searchTitle: PropTypes.string,
    addTitle: PropTypes.string,
    settingList: PropTypes.arrayOf(PropTypes.object),
    fieldName: PropTypes.string,
    addNewAction: PropTypes.func,
    editAction: PropTypes.func,
    cloneAction: PropTypes.func,
    removeAction: PropTypes.func,
};

export default FlowSettingList;
