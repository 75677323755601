import PropTypes from "prop-types";
import React from 'react';

import { TextSize } from 'components/CusRoundedInput';
import { CusCheckBox, LabelContentPaddingType, PaddingType } from ".";

const CusLabelCheckBox = React.forwardRef(({
  label = "",
  disabled = false,
  oneLine = false,
  checked = false,
  readOnly = false,
  placeholder = "",
  onChange = null,
  ...rest
}, ref) => {
  return(
    oneLine ?
      <div className="py-2 w-full flex items-center">              
        <div className={`${disabled ? "px-2" : "pr-2"}`} onClick={readOnly || disabled ? null : onChange}>
          <CusCheckBox
            ref={ref}
            checked={checked}
            onChange={() => {}}
            disabled={disabled}
            readOnly={readOnly}
            paddingType={PaddingType.flat}
            textSize={rest.textSize ?? TextSize.flat}
            {...rest}
          />
        </div>
        <span className={`text-greyblack ${rest.textSize ?? ""}`}>{label}</span>
      </div>
      :
      <CusCheckBox
        ref={ref}
        label={label}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder}
        paddingType={PaddingType.flat}
        textSize={rest.textSize ?? TextSize.flat}
        {...rest}
      />
  )
});

CusLabelCheckBox.propTypes = {
    label: PropTypes.string,
    oneLine: PropTypes.bool,
    checked: PropTypes.bool,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
  }

export default CusLabelCheckBox;