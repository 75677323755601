import { createSlice } from '@reduxjs/toolkit'
import { convertChatbotMessage, currentTimestamp, isJson } from 'util/helper';

const INIT_STATE = {
    language: 'ENGLISH',
    nodeHistory: [],
    messages: [],
    nodeOptionValueMap: {},
    parameterMap: {},
    loading: false,
    error: null
}

const TestChatbot = createSlice({
    name: 'TestChatbot',
    initialState: INIT_STATE,
    reducers: {
        RESET_TEST_CHATBOT: (state) => {
            state.language = 'ENGLISH';
            state.messages = [];
            state.nodeHistory = [];
            state.nodeOptionValueMap = {}
            state.parameterMap = {}
            state.loading = false;
            state.error = null;
        },
        SEND_TEST_MESSAGE: (state, action) => {
            let timestamp = currentTimestamp();
            let type = "CLIENT";
            state.messages.push({messageId: `${type}-${timestamp}`, message: action.payload, timestamp, type});
        },
        TEST_CHATBOT: (state) => {
            state.loading = true;
            state.error = null;
        },
        TEST_CHATBOT_SUCCESS: (state, action) => {
            console.log(action.payload)

            state.error = null;
            state.loading = false;

            state.language = action.payload.chatbotContent.language
            state.nodeOptionValueMap = action.payload.chatbotContent.nodeOptionValueMap
            state.parameterMap = action.payload.chatbotContent.parameterMap

            let nodeName = "";
            let timestamp = currentTimestamp();
            let type = "RECEIVED";

            if(action.payload.currentNode){
                state.nodeHistory.push({node: action.payload.currentNode, messageId: `${type}-${timestamp}`});
                nodeName = action.payload.currentNode.name
            }

            if(action.payload.messageList && action.payload.messageList.length > 0){
                action.payload.messageList.forEach((message, index) => {
                    let newMessage = message;
        
                    if(isJson(message))
                        newMessage = convertChatbotMessage(message)
        
                    state.messages.push({messageId: `${type}-${timestamp}`, message: newMessage, timestamp, type, nodeName})
                })
      
                // state.messages = [...state.messages, ...newMessages]
            }

        },
        TEST_CHATBOT_FAILURE: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
        },

    }
})

export const {
    RESET_TEST_CHATBOT,
    SEND_TEST_MESSAGE,
    TEST_CHATBOT,
    TEST_CHATBOT_FAILURE,
    TEST_CHATBOT_SUCCESS,
} = TestChatbot.actions;


export default TestChatbot.reducer;

export const getLanguage = state => state.language;
export const getNodeHistory = state => state.nodeHistory;
export const getMessages = state => state.messages;
export const getNodeOptionValueMap = state => state.nodeOptionValueMap;
export const getParameterMap = state => state.parameterMap;
export const getLoading = state => state.loading;
export const getError = state => state.error;