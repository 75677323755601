const List = (rest) => {
    return(
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M14.3925 20.4872L20.2002 16.7565C20.4855 16.5754 20.6282 16.3237 20.6282 16.0015C20.6282 15.6793 20.4855 15.4267 20.2002 15.2438L14.3925 11.5132C14.0934 11.3081 13.7865 11.2916 13.4718 11.4638C13.1574 11.6361 13.0002 11.9046 13.0002 12.2695V19.7308C13.0002 20.0957 13.1574 20.3643 13.4718 20.5365C13.7865 20.7087 14.0934 20.6923 14.3925 20.4872ZM16.0025 28.6668C14.2505 28.6668 12.6037 28.3344 11.0622 27.6695C9.52061 27.0046 8.17972 26.1023 7.0395 24.9625C5.89927 23.8227 4.9965 22.4824 4.33116 20.9415C3.66605 19.4006 3.3335 17.7543 3.3335 16.0025C3.3335 14.2505 3.66594 12.6037 4.33083 11.0622C4.99572 9.52061 5.89805 8.17972 7.03783 7.0395C8.17761 5.89927 9.51794 4.9965 11.0588 4.33116C12.5997 3.66605 14.2461 3.3335 15.9978 3.3335C17.7498 3.3335 19.3966 3.66594 20.9382 4.33083C22.4797 4.99572 23.8206 5.89805 24.9608 7.03783C26.1011 8.17761 27.0038 9.51794 27.6692 11.0588C28.3343 12.5997 28.6668 14.2461 28.6668 15.9978C28.6668 17.7498 28.3344 19.3966 27.6695 20.9382C27.0046 22.4797 26.1023 23.8206 24.9625 24.9608C23.8227 26.1011 22.4824 27.0038 20.9415 27.6692C19.4006 28.3343 17.7543 28.6668 16.0025 28.6668ZM16.0002 26.6668C18.9779 26.6668 21.5002 25.6335 23.5668 23.5668C25.6335 21.5002 26.6668 18.9779 26.6668 16.0002C26.6668 13.0224 25.6335 10.5002 23.5668 8.4335C21.5002 6.36683 18.9779 5.3335 16.0002 5.3335C13.0224 5.3335 10.5002 6.36683 8.4335 8.4335C6.36683 10.5002 5.3335 13.0224 5.3335 16.0002C5.3335 18.9779 6.36683 21.5002 8.4335 23.5668C10.5002 25.6335 13.0224 26.6668 16.0002 26.6668Z" fill="#64748B"/>
            <path d="M14.3925 20.4872L20.2002 16.7565C20.4855 16.5754 20.6282 16.3237 20.6282 16.0015C20.6282 15.6793 20.4855 15.4267 20.2002 15.2438L14.3925 11.5132C14.0934 11.3081 13.7865 11.2916 13.4718 11.4638C13.1574 11.6361 13.0002 11.9046 13.0002 12.2695V19.7308C13.0002 20.0957 13.1574 20.3643 13.4718 20.5365C13.7865 20.7087 14.0934 20.6923 14.3925 20.4872ZM16.0025 28.6668C14.2505 28.6668 12.6037 28.3344 11.0622 27.6695C9.52061 27.0046 8.17972 26.1023 7.0395 24.9625C5.89927 23.8227 4.9965 22.4824 4.33116 20.9415C3.66605 19.4006 3.3335 17.7543 3.3335 16.0025C3.3335 14.2505 3.66594 12.6037 4.33083 11.0622C4.99572 9.52061 5.89805 8.17972 7.03783 7.0395C8.17761 5.89927 9.51794 4.9965 11.0588 4.33116C12.5997 3.66605 14.2461 3.3335 15.9978 3.3335C17.7498 3.3335 19.3966 3.66594 20.9382 4.33083C22.4797 4.99572 23.8206 5.89805 24.9608 7.03783C26.1011 8.17761 27.0038 9.51794 27.6692 11.0588C28.3343 12.5997 28.6668 14.2461 28.6668 15.9978C28.6668 17.7498 28.3344 19.3966 27.6695 20.9382C27.0046 22.4797 26.1023 23.8206 24.9625 24.9608C23.8227 26.1011 22.4824 27.0038 20.9415 27.6692C19.4006 28.3343 17.7543 28.6668 16.0025 28.6668ZM16.0002 26.6668C18.9779 26.6668 21.5002 25.6335 23.5668 23.5668C25.6335 21.5002 26.6668 18.9779 26.6668 16.0002C26.6668 13.0224 25.6335 10.5002 23.5668 8.4335C21.5002 6.36683 18.9779 5.3335 16.0002 5.3335C13.0224 5.3335 10.5002 6.36683 8.4335 8.4335C6.36683 10.5002 5.3335 13.0224 5.3335 16.0002C5.3335 18.9779 6.36683 21.5002 8.4335 23.5668C10.5002 25.6335 13.0224 26.6668 16.0002 26.6668Z" fill="black" fillOpacity="0.2"/>
            <path d="M14.3925 20.4872L20.2002 16.7565C20.4855 16.5754 20.6282 16.3237 20.6282 16.0015C20.6282 15.6793 20.4855 15.4267 20.2002 15.2438L14.3925 11.5132C14.0934 11.3081 13.7865 11.2916 13.4718 11.4638C13.1574 11.6361 13.0002 11.9046 13.0002 12.2695V19.7308C13.0002 20.0957 13.1574 20.3643 13.4718 20.5365C13.7865 20.7087 14.0934 20.6923 14.3925 20.4872ZM16.0025 28.6668C14.2505 28.6668 12.6037 28.3344 11.0622 27.6695C9.52061 27.0046 8.17972 26.1023 7.0395 24.9625C5.89927 23.8227 4.9965 22.4824 4.33116 20.9415C3.66605 19.4006 3.3335 17.7543 3.3335 16.0025C3.3335 14.2505 3.66594 12.6037 4.33083 11.0622C4.99572 9.52061 5.89805 8.17972 7.03783 7.0395C8.17761 5.89927 9.51794 4.9965 11.0588 4.33116C12.5997 3.66605 14.2461 3.3335 15.9978 3.3335C17.7498 3.3335 19.3966 3.66594 20.9382 4.33083C22.4797 4.99572 23.8206 5.89805 24.9608 7.03783C26.1011 8.17761 27.0038 9.51794 27.6692 11.0588C28.3343 12.5997 28.6668 14.2461 28.6668 15.9978C28.6668 17.7498 28.3344 19.3966 27.6695 20.9382C27.0046 22.4797 26.1023 23.8206 24.9625 24.9608C23.8227 26.1011 22.4824 27.0038 20.9415 27.6692C19.4006 28.3343 17.7543 28.6668 16.0025 28.6668ZM16.0002 26.6668C18.9779 26.6668 21.5002 25.6335 23.5668 23.5668C25.6335 21.5002 26.6668 18.9779 26.6668 16.0002C26.6668 13.0224 25.6335 10.5002 23.5668 8.4335C21.5002 6.36683 18.9779 5.3335 16.0002 5.3335C13.0224 5.3335 10.5002 6.36683 8.4335 8.4335C6.36683 10.5002 5.3335 13.0224 5.3335 16.0002C5.3335 18.9779 6.36683 21.5002 8.4335 23.5668C10.5002 25.6335 13.0224 26.6668 16.0002 26.6668Z" fill="black" fillOpacity="0.2"/>
            <path d="M14.3925 20.4872L20.2002 16.7565C20.4855 16.5754 20.6282 16.3237 20.6282 16.0015C20.6282 15.6793 20.4855 15.4267 20.2002 15.2438L14.3925 11.5132C14.0934 11.3081 13.7865 11.2916 13.4718 11.4638C13.1574 11.6361 13.0002 11.9046 13.0002 12.2695V19.7308C13.0002 20.0957 13.1574 20.3643 13.4718 20.5365C13.7865 20.7087 14.0934 20.6923 14.3925 20.4872ZM16.0025 28.6668C14.2505 28.6668 12.6037 28.3344 11.0622 27.6695C9.52061 27.0046 8.17972 26.1023 7.0395 24.9625C5.89927 23.8227 4.9965 22.4824 4.33116 20.9415C3.66605 19.4006 3.3335 17.7543 3.3335 16.0025C3.3335 14.2505 3.66594 12.6037 4.33083 11.0622C4.99572 9.52061 5.89805 8.17972 7.03783 7.0395C8.17761 5.89927 9.51794 4.9965 11.0588 4.33116C12.5997 3.66605 14.2461 3.3335 15.9978 3.3335C17.7498 3.3335 19.3966 3.66594 20.9382 4.33083C22.4797 4.99572 23.8206 5.89805 24.9608 7.03783C26.1011 8.17761 27.0038 9.51794 27.6692 11.0588C28.3343 12.5997 28.6668 14.2461 28.6668 15.9978C28.6668 17.7498 28.3344 19.3966 27.6695 20.9382C27.0046 22.4797 26.1023 23.8206 24.9625 24.9608C23.8227 26.1011 22.4824 27.0038 20.9415 27.6692C19.4006 28.3343 17.7543 28.6668 16.0025 28.6668ZM16.0002 26.6668C18.9779 26.6668 21.5002 25.6335 23.5668 23.5668C25.6335 21.5002 26.6668 18.9779 26.6668 16.0002C26.6668 13.0224 25.6335 10.5002 23.5668 8.4335C21.5002 6.36683 18.9779 5.3335 16.0002 5.3335C13.0224 5.3335 10.5002 6.36683 8.4335 8.4335C6.36683 10.5002 5.3335 13.0224 5.3335 16.0002C5.3335 18.9779 6.36683 21.5002 8.4335 23.5668C10.5002 25.6335 13.0224 26.6668 16.0002 26.6668Z" fill="black" fillOpacity="0.2"/>
        </svg>
    )
}

export default List;