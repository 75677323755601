import PropTypes from "prop-types";
import React from 'react';

import { BorderType, CusRoundedTextArea, TextSize} from 'components/CusRoundedTextArea';

const CusLabelRoundedTextArea = React.forwardRef(({
  label = "",
  value = "",
  borderType = "",
  disabled = false,
  readOnly = false,
  required = false,
  onChange = null,
  ...rest
}, ref) =>   (
  <CusRoundedTextArea
    ref={ref}
    label={label}
    value={value}
    onChange={onChange}
    borderType={disabled ? BorderType.none : borderType}
    disabled={disabled}
    readOnly={readOnly}
    placeholder={label}
    textSize={TextSize.flat}
    {...rest}
  />    
));

CusLabelRoundedTextArea.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    borderType: PropTypes.string,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    onChange: PropTypes.func,
  }

export default CusLabelRoundedTextArea;