import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './index.css';
// import classes from 'react-style-classes';
// import './styles.scss';

const Item = ({
  children = null,
  onClick = () => null,
  onContextMenu = () => null,
  unstyled = false,
  isActive = false,
  disabled = false,
  showByClick = false,
  className = null,
  ...props
}) =>{
	
	return (
	  <li
      className={(unstyled ? ("cus-dropdown-item-unstyle" + (showByClick ? "-from-click " : " ") + (className ? className : " "))
      : (disabled ? "cus-dropdown-disabled" : ("cus-dropdown-item" + (showByClick ? "-from-click " : " ")))) + (isActive ? " cus-dropdown-active" : "")}
      onClick={disabled ? null : onClick}
      onContextMenu={onContextMenu}
      tabIndex={0}
      {...props}
	  >
		  {children}
	  </li>
	);
	
}

Item.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  onContextMenu: PropTypes.func,
  unstyled: PropTypes.bool,
  isActive: PropTypes.bool,
  disabled: PropTypes.bool,
  showByClick: PropTypes.bool,
  className: PropTypes.string,
};

export default Item;