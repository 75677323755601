import { createSlice } from '@reduxjs/toolkit'

const INIT_STATE = {
    attachmentList: [],
    loading: false,
    error: null
}

const Attachment = createSlice({
    name: 'Attachment',
    initialState: INIT_STATE,
    reducers: {
        GET_ATTACHMENTS: (state) => {
            state.loading = true;
            state.error = null;
        },
        GET_ATTACHMENTS_SUCCESS: (state, action) => {
            state.loading = false;
            state.attachmentList = action.payload;
            state.error = null;
        },
        GET_ATTACHMENTS_FAILURE: (state, action) => {
            state.loading = false;
            state.attachmentList = [];
            state.error = action.payload.error;
        },
    }
})

export const {
    GET_ATTACHMENTS,
    GET_ATTACHMENTS_FAILURE,
    GET_ATTACHMENTS_SUCCESS,
} = Attachment.actions;


export default Attachment.reducer;

export const getAttachmentList = state => state.attachmentList;
export const getLoading = state => state.loading;
export const getError = state => state.error;