
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { generalPrefix } from 'lang/locales/prefix';

const ContactItem = ({contact = null, contactMapping = null}) => {
    const intl = useIntl()

    let firstField = contactMapping.length > 0 && contact && contact.customFields ? contact.customFields[contactMapping[0].fieldName] : intl.formatMessage({ "id": `${generalPrefix}.status.empty` })
    let secondField = contactMapping.length > 1 && contact && contact.customFields ? contact.customFields[contactMapping[1].fieldName] : intl.formatMessage({ "id": `${generalPrefix}.status.empty` })
        
    return (

        <div title={`${firstField}\n${secondField}`} className="w-full h-full truncate flex flex-col justify-center">
            <div className="text-sm font-bold truncate">{firstField}</div>
            <div className="text-blue04 text-xs truncate">{secondField}</div>
        </div>
    )
}

ContactItem.propTypes = {
    contact: PropTypes.object,
    contactMapping: PropTypes.arrayOf(PropTypes.object),
};

export default ContactItem;