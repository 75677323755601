import { Handle } from 'reactflow';

const TopHandle = () => (
    <Handle
        type="target"
        position="top"
        className="invisible bg-white"
    />
)

export default TopHandle