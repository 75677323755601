import {
    GET_ACTIONS,
    GET_ACTIONS_FAILURE,
    GET_ACTIONS_SUCCESS,
    BULK_ACTION,
    BULK_ACTION_SUCCESS,
    BULK_ACTION_FAILURE
} from "store/reducers/setting/automation/chatbot/action";

import {
    getAction as getActionService,
    bulkAction as bulkActionService
} from "services/chatbot/action";
import { isJson, isSuccessResponse } from "util/helper";
import { handleAuthError } from "store/actions/auth/user";
import { ERROR_REPSONSE_LOCALE_MAP, ERROR_RESPONSE_MAP, getErrorByLocale } from "util/ErrorResponse";
import { errorPrefix } from "lang/locales/prefix";
import { getToken } from "store/reducers/auth/user";

export const getActionList = (data) => (dispatch, getState) => {
    dispatch(GET_ACTIONS());    

    let authToken = getToken(getState().auth.user)
    getActionService(data, authToken)
    .then(response => {
        const bodyString = response.data.body;
        const body = isJson(bodyString) ? JSON.parse(bodyString) : bodyString;

        console.log(body)

        if(isSuccessResponse(response)) {
            dispatch(GET_ACTIONS_SUCCESS(body));
        } else {
            if(body === ERROR_RESPONSE_MAP.AUTH){
                dispatch(handleAuthError())
                return
            }
            if(body === ERROR_RESPONSE_MAP.PERMISSION){
                dispatch(GET_ACTIONS_FAILURE({error: getErrorByLocale(getState, ERROR_REPSONSE_LOCALE_MAP.PERMISSION)}))
                return
            }

            dispatch(GET_ACTIONS_FAILURE({error: body.message }));
        }

    })
    .catch(error=> {
        const message = (typeof error.response === "string") ? error.response : 'System Error, Please contact admin for more information'
        dispatch(GET_ACTIONS_FAILURE({error: message}))
    })

}

export const createAction = ({flowId, action}) => (dispatch, getState) => {
    dispatch(bulkAction({insertList: [{flowId, ...action}], flowId}))
}

export const updateAction = ({flowId, action}) => (dispatch, getState) => {
    dispatch(bulkAction({updateList: [{flowId, ...action}], flowId}))
}

export const removeAction = ({flowId, id}) => (dispatch, getState) => {
    dispatch(bulkAction({removeIdList: [id], flowId}))
}

export const bulkAction = ({insertList, updateList, removeIdList, flowId}) => (dispatch, getState) => {
    dispatch(BULK_ACTION());

    insertList = insertList ? insertList : []
    updateList = updateList ? updateList : []
    removeIdList = removeIdList ? removeIdList : []

    let authToken = getToken(getState().auth.user)
    bulkActionService({insertActionList: insertList, updateActionList: updateList, deleteActionIdList: removeIdList}, authToken)
        .then(response => {
            console.log(response)
            const bodyString = response.data.body;
            const body = isJson(bodyString) ? JSON.parse(bodyString) : bodyString;

            console.log(body)

            if(isSuccessResponse(response)){
                dispatch(BULK_ACTION_SUCCESS())
                if(flowId)
                    dispatch(getActionList({flowId: flowId}))

            }
            else{
                if(body === ERROR_RESPONSE_MAP.AUTH){
                    dispatch(handleAuthError())
                    return
                }
                if(body === ERROR_RESPONSE_MAP.PERMISSION){
                    dispatch(BULK_ACTION_FAILURE({error: getErrorByLocale(getState, ERROR_REPSONSE_LOCALE_MAP.PERMISSION)}))
                    return
                }    

                dispatch(BULK_ACTION_FAILURE({error: body.message}))
            }
        })
        .catch((error) => {
            const message = (typeof error.response === "string") ? error.response : 'System Error, Please contact admin for more information'
            dispatch(BULK_ACTION_FAILURE({error: message}))
        })
}