import { errorPrefix } from "lang/locales/prefix";
import { errors as enErrors } from "lang/locales/en_US";
import { errors as zhErrors } from "lang/locales/zh-Hans";
import { errors as tzhErrors } from "lang/locales/zh-THans";
import { getLanguage } from "store/reducers/ui/setting";

export const ERROR_RESPONSE_MAP = {
    "AUTH" : "Authentication Error",
    "PERMISSION" : "Insufficient Access Rights",
}

export const ERROR_REPSONSE_LOCALE_MAP = {
    "PERMISSION" : `${errorPrefix}.permission`
}

export const getErrorByLocale = (getState, errorname) =>{
    const locale = getLanguage(getState().ui.setting);

    switch (locale) {
        case "zh":            
            return zhErrors[errorname] ?? errorname;
        case "tzh":            
            return tzhErrors[errorname] ?? errorname;
        default:
            return enErrors[errorname] ?? errorname;
    }

}