import PropTypes from "prop-types";
import React from 'react';
// import { usePopperTooltip } from 'react-popper-tooltip';
// import 'react-popper-tooltip/dist/styles.css';

const Color = Object.freeze(
    {
        "primary": " bg-blue04 text-white hover:text-white hover:bg-blue04-dark ",
        "primaryFrame": " border-[1px] border-blue04 text-blue04 bg-white hover:text-blue04-dark hover:border-bg-blue04-dark ",
        "darkFrame": " border-[1px] border-greyblack text-greyblack ",
        "primaryNoBorder": " border-[1px] border-none text-blue04 bg-transparent hover:text-blue04-dark ",
        "darkNoBorder": " border-[1px] border-none text-greyblack ",
        "warn": " bg-warn text-white hover:text-white hover:bg-warn-dark ",
        "secondary" : " bg-grey07 text-white hover:text-white hover:bg-secondary-dark ",
        "disable": " opacity-50 ",
        "transparent" : " bg-transparent text-black hover:text-black hover:bg-opacity-30 hover:bg-gray-200 ",
        "primaryButton": " bg-blue04-button text-white hover:bg-blue04-button-dark ",
        "modified": " bg-modified-bg text-white ",
        "transparentWhite": " bg-transparent text-white hover:text-white hover:bg-opacity-30 hover:bg-white "
    }
)

const TextSize = Object.freeze(
    {
        "default": "text-sm",
        "flat": "text-base"
    }
)

const PaddingType = Object.freeze(
    {
        "noPadding": " ",
        "normalPadding": "  h-[40px] px-4 ",
        "flat": "  h-[42px] px-11 ",
    }
)

const RoundType = Object.freeze(
    {
        "none" : " ",
        "small"     : " rounded-sm ",
        "default"   : " rounded-md ",
        "large"   : " rounded-lg ",
    }
)


const TRANSPARENT_DISABLE_COLOR = " text-grey-200 ";

const CusRoundedButton = ({
    color = Color.primary,
    children = <></>,
    unstyled = false,
    disabled = false,
    onClick = ()=>{},
    onMouseOver = null,
    onMouseLeave = null,
    className = "",
    toolTip = "",
    toolTipVisible = false,
    paddingType = PaddingType.normalPadding,
    roundType = RoundType.normalPadding,
    textSize = TextSize.default,
    ...rest
}) => {

    // const {
    //     getArrowProps,
    //     getTooltipProps,
    //     setTooltipRef,
    //     setTriggerRef,
    //     visible,
        
    // } = usePopperTooltip({
    // });

    // const [tooltipVisible, setTooltipVisible] = React.useState(false);

    // const handleMouseOver = () => {
    //     setTooltipVisible(true);
    // };

    // const handleMouseOut = () => {
    //     setTooltipVisible(false);
    // };

    return(
        <>
            <button 
                // ref={setTriggerRef}
                className = {unstyled ? className :
                    className + " no-underline select-none focus:outline-none active:shadow-none " 
                    + (roundType ? roundType: RoundType.default)
                    + (textSize? textSize: TextSize.default)
                    + (paddingType? paddingType: PaddingType.normalPadding)
                    + (disabled && color !== Color.transparent ? (color + Color.disable) :  color) 
                    + (disabled && color === Color.transparent && TRANSPARENT_DISABLE_COLOR  ) 
                    + (color === Color.transparent || Color.transparentWhite ? "" : " shadow-md ")
                }
                // onMouseOver={onMouseOver ? onMouseOver : handleMouseOver}
                // onMouseLeave={onMouseLeave ? onMouseLeave : handleMouseOut}
                disabled={disabled}
                onClick={onClick}
                {...rest}
            >
                {children}
            </button>
            {/* {((tooltipVisible || visible) && toolTipVisible) && (
            <div
                className=" bg-blue04 text-white"
                ref={setTooltipRef}
                {...getTooltipProps({ className: 'tooltip-container' })}
            >
                <div {...getArrowProps({ className: 'tooltip-arrow' })} />
                {toolTip}
            </div>
            )} */}
        </>
    );
}

CusRoundedButton.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  unstyled: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseLeave: PropTypes.func,
  toolTip: PropTypes.string,
  toolTipVisible: PropTypes.bool,
  paddingType: PropTypes.string,
  roundType: PropTypes.string,
  textSize: PropTypes.string

}

export {
    Color,
    CusRoundedButton,
    PaddingType,
    TextSize,
    RoundType
}