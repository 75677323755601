import CusEditable, { EDITABLE_TYPE, setDefaultField, validateField } from 'components/CusEditable';
import CusEditableLeftLabel from 'components/CusEditable/CusEditableLeftLabel';
import CusModal from 'components/CusModal';
import { Color as ButtonColor, CusRoundedButton, PaddingType as ButtonPadding, RoundType as ButtonRoundType } from 'components/CusRoundedButton';
import DynamicContent from 'components/DynamicContent';
import ExpanableListItem from 'components/ExpanableListItem';
import Bin from 'components/Icon/Bin';
import Info2 from 'components/Icon/Info2';
import SubTasks from 'components/Icon/SubTasks';
import { useLanguage } from 'hooks/useLanguage';
import { addFieldItemAction, removeFieldItemAction, updateFieldItemAction } from 'util/helper';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { capitalise, checkListItemDuplication } from 'util/helper';
import FlowSettingDropdown from '../FlowSettingDropdown';
import ActionConditionResponseListItem, { ACTION_CONDITION_RESPONSE_FIELD_TEMPLATE, validateConditionResponseListItems } from './FlowSettingList/ActionConditionResponseListItem';
import ActionDefaultValueListItem, { ACTION_DEFAULT_VALUE_FIELD_TEMPLATE, validateDefaultValueListItems } from './FlowSettingList/ActionDefaultValueListItem';
import ResponsePreviewItem from './FlowSettingList/ResponsePreviewItem';
import { useIntl } from 'react-intl';
import { errorPrefix, generalPrefix, settingPrefix, editChatbotPrefix, editActionPrefix } from 'lang/locales/prefix';
import Scrollbars from 'react-custom-scrollbars-2';
import Plus from 'components/Icon/Plus';
import Minimise from 'components/Icon/Minimise';
import NonExpanableListItem from 'components/NonExpanableListItem';

const ACTION_TYPE = Object.freeze({
    "GET": "GET",
    "INSERT": "INSERT",
    "UPDATE": "UPDATE",
    "OPTIONS": "OPTIONS",
    "IDENTIFY_CONTACT": "IDENTIFY_CONTACT",
    "UPLOAD_ATTACHMENT": "UPLOAD_ATTACHMENT",
}) 

const ACTION_TYPE_NAME_LIST = Object.freeze([
    "GET", "INSERT", "UPDATE", "OPTIONS", "IDENTIFY_CONTACT", "UPLOAD_ATTACHMENT"
])

const ACTION_SETUP_FIELD_TYPE = [
    {
        "fieldName": "name",
        "displayNameKey": `${settingPrefix}.${editActionPrefix}.field.setup.name`,
        "displayNameEn": "Name",
        "displayNameTc": "動作名稱",
        "displayNameSc": "动作名称",
        "fieldType": EDITABLE_TYPE.TEXT,
        "required": true,
        "editable": true,
        "creatable": true,
    },
    {
        "fieldName": "action",
        "displayNameKey": `${settingPrefix}.${editActionPrefix}.field.setup.action`,
        "displayNameEn": "List",
        "displayNameTc": "動作類型",
        "displayNameSc": "动作类型",
        "fieldType": EDITABLE_TYPE.PICKLIST,
        "options": ACTION_TYPE_NAME_LIST,
        "required": true,
        "editable": true,
        "creatable": true,
    },
    {
        "fieldName": "targetTable",
        "displayNameKey": `${settingPrefix}.${editActionPrefix}.field.setup.targetTable`,
        "displayNameEn": "Target",
        "displayNameTc": "目標表",
        "displayNameSc": "目标表",
        "fieldType": EDITABLE_TYPE.TEXT,
        "required": true,
        "editable": true,
        "creatable": true,
    }    
]

const ACTION_SETUP_GET_FIELD_TYPE = [
    {
        "fieldName": "queryString",
        "displayNameKey": `${settingPrefix}.${editActionPrefix}.field.conditions.queryString`,
        "displayNameEn": "Query String",
        "displayNameTc": "查詢字符串",
        "displayNameSc": "查询字符串",
        "fieldType": EDITABLE_TYPE.TEXTAREA,
        "required": true,
        "editable": true,
        "creatable": true,
    },
    {
        "fieldName": "recordCount",
        "displayNameKey": `${settingPrefix}.${editActionPrefix}.field.conditions.recordCount`,
        "displayNameEn": "Record Count",
        "displayNameTc": "記錄數",
        "displayNameSc": "记录数",
        "fieldType": EDITABLE_TYPE.NUMBER,
        "minNum": 1,
        "required": true,
        "editable": true,
        "creatable": true,
    },
    {
        "fieldName": "resultAsOptions",
        "displayNameKey": `${settingPrefix}.${editActionPrefix}.field.conditions.resultAsOptions`,
        "displayNameEn": "Result As Options",
        "displayNameTc": "結果作為選項",
        "displayNameSc": "结果作为选项",
        "fieldType": EDITABLE_TYPE.BOOLEAN_CHECKBOX,
        "required": false,
        "editable": true,
        "creatable": true,
    },
]

const ACTION_SETUP_GET_RESULT_AS_OPTIONS_FIELD_TYPE = [
    {
        "fieldName": "queryString",
        "displayNameKey": `${settingPrefix}.${editActionPrefix}.field.conditions.queryString`,        
        "displayNameEn": "Query String",
        "displayNameTc": "查詢字符串",
        "displayNameSc": "查询字符串",
        "fieldType": EDITABLE_TYPE.TEXTAREA,
        "required": true,
        "editable": true,
        "creatable": true,
    },
    {
        "fieldName": "recordCount",
        "displayNameKey": `${settingPrefix}.${editActionPrefix}.field.conditions.recordCount`,        
        "displayNameEn": "Record Count",
        "displayNameTc": "記錄數",
        "displayNameSc": "记录数",
        "fieldType": EDITABLE_TYPE.NUMBER,
        "minNum": 1,
        "required": true,
        "editable": true,
        "creatable": true,
    },
    {
        "fieldName": "resultAsOptions",
        "displayNameKey": `${settingPrefix}.${editActionPrefix}.field.conditions.resultAsOptions`,        
        "displayNameEn": "Result As Options",
        "displayNameTc": "結果作為選項",
        "displayNameSc": "结果作为选项",
        "fieldType": EDITABLE_TYPE.BOOLEAN_CHECKBOX,
        "required": false,
        "editable": true,
        "creatable": true,
    },
    {
        "fieldName": "optionLabelField",
        "displayNameKey": `${settingPrefix}.${editActionPrefix}.field.conditions.optionLabelField`,
        "displayNameEn": "Option Label",
        "displayNameTc": "選項標籤",
        "displayNameSc": "选项标签",
        "fieldType": EDITABLE_TYPE.TEXT,
        "required": true,
        "editable": true,
        "creatable": true,
    },
    {
        "fieldName": "optionValueField",
        "displayNameKey": `${settingPrefix}.${editActionPrefix}.field.conditions.optionValueField`,
        "displayNameEn": "Option Value",
        "displayNameTc": "選項值",
        "displayNameSc": "选项值",
        "fieldType": EDITABLE_TYPE.TEXT,
        "required": true,
        "editable": true,
        "creatable": true,
    },

]


const ACTION_SETUP_INSERT_FIELD_TYPE = [
    {
        "fieldName": "mediaParameterName",
        "displayNameKey": `${settingPrefix}.${editActionPrefix}.field.conditions.mediaParameterName`,
        "displayNameEn": "Media Parameter Name",
        "displayNameTc": "媒體參數名稱",
        "displayNameSc": "媒体参数名称",
        "fieldType": EDITABLE_TYPE.TEXT,
        "required": false,
        "editable": true,
        "creatable": true,
    },
]

const ACTION_SETUP_OPTION_FIELD_TYPE = [
    {
        "fieldName": "optionTableField",
        "displayNameKey": `${settingPrefix}.${editActionPrefix}.field.conditions.optionTableField`,
        "displayNameEn": "Option Target Table",
        "displayNameTc": "選項目標表",
        "displayNameSc": "选项目标表",
        "fieldType": EDITABLE_TYPE.TEXT,
        "required": true,
        "editable": true,
        "creatable": true,
    },
    {
        "fieldName": "recordCount",
        "displayNameKey": `${settingPrefix}.${editActionPrefix}.field.conditions.recordCount`,
        "displayNameEn": "Record Count",
        "displayNameTc": "記錄數",
        "displayNameSc": "记录数",
        "fieldType": EDITABLE_TYPE.NUMBER,
        "minNum": 1,
        "required": true,
        "editable": true,
        "creatable": true,
    },
    {
        "fieldName": "resultAsOptions",
        "displayNameKey": `${settingPrefix}.${editActionPrefix}.field.conditions.resultAsOptions`,
        "displayNameEn": "Result As Options",
        "displayNameTc": "結果作為選項",
        "displayNameSc": "结果作为选项",
        "fieldType": EDITABLE_TYPE.BOOLEAN_CHECKBOX,
        "required": true,
        "editable": false,
        "creatable": false,
    },
    {
        "fieldName": "optionLabelField",
        "displayNameKey": `${settingPrefix}.${editActionPrefix}.field.conditions.optionLabelField`,
        "displayNameEn": "Option Label",
        "displayNameTc": "選項標籤",
        "displayNameSc": "选项标签",
        "fieldType": EDITABLE_TYPE.TEXT,
        "required": true,
        "editable": true,
        "creatable": true,
    },
    {
        "fieldName": "optionValueField",
        "displayNameKey": `${settingPrefix}.${editActionPrefix}.field.conditions.optionValueField`,
        "displayNameEn": "Option Value",
        "displayNameTc": "選項值",
        "displayNameSc": "选项值",
        "fieldType": EDITABLE_TYPE.TEXT,
        "required": true,
        "editable": true,
        "creatable": true,
    },

]

const ACTION_SETUP_IDENTIFY_CONTACT_FIELD_TYPE = [
    {
        "fieldName": "queryString",
        "displayNameKey": `${settingPrefix}.${editActionPrefix}.field.conditions.queryString`,
        "displayNameEn": "Query String",
        "displayNameTc": "查詢字符串",
        "displayNameSc": "查询字符串",
        "fieldType": EDITABLE_TYPE.TEXTAREA,
        "required": true,
        "editable": true,
        "creatable": true,
    },
]

const ACTION_SETUP_RESPONSE_FIELD_TYPE = [
    {
        "fieldName": "responseId",
        "displayNameKey": `${settingPrefix}.${editActionPrefix}.field.response.responseId`,
        "displayNameEn": "Response",
        "displayNameTc": "響應",
        "displayNameSc": "响应",
        "fieldType": EDITABLE_TYPE.PICKLIST,
        "options": [],
        "required": true,
        "editable": true,
        "creatable": true,
    },
    {
        "fieldName": "failedResponseId",
        "displayNameKey": `${settingPrefix}.${editActionPrefix}.field.response.failedResponseId`,
        "displayNameEn": "Failed",
        "displayNameTc": "響應失敗",
        "displayNameSc": "响应失败",
        "fieldType": EDITABLE_TYPE.PICKLIST,
        "options": [],
        "required": true,
        "editable": true,
        "creatable": true,
    }
]

const FIELDLIST_FIELD_TYPE = {
    "fieldName": "name",
    "displayNameKey": `${settingPrefix}.${editActionPrefix}.field.conditions.fieldList.name`,
    "displayNameEn": "Return Field Name",
    "displayNameTc": "返回字段名稱",
    "displayNameSc": "返回字段名称",
    "fieldType": EDITABLE_TYPE.TEXTAREA,
    "required": true,
    "editable": true,
    "creatable": true,
}

const ACTION_FIELD_VALUE_FIELD_TEMPLATE = Object.freeze({
    "name" : "",
})

const EditAction = ({
    isEditable = false,
    isEdit = false,
    setIsEdit = () => {},
    item = null,
    updateAction = null,
    removeAction = null,
    parameterList = [],
    responseList = [],
}) => {

    const [showResponseMsgMenu, setShowResponseMsgMenu] = useState(false);
    const [responseMsgMenuPos, setResponseMsgMenuPos] = useState(null);

    const [selectedResponse, setSelectedResponse] = useState(null);

    const [showParameterListMenu, setShowParameterListMenu] = useState(false);
    const [parameterListMenuPos, setParameterListMenuPos] = useState(null);

    const [selectedFieldName, setSelectedFieldName] = useState("");
    const [selectedFieldIndex, setSelectedFieldIndex] = useState(-1);

    const [errorMap, setErrorMap] = useState({});
    const [editableAction, setEditableAction] = useState({})

    const [fieldList, setFieldList] = useState([])
    const [fieldErrorList, setFieldErrorList] = useState([]);

    const [defaultValueList, setDefaultValueList] = useState([])
    const [defaultValueErrorList, setDefaultValueErrorList] = useState([]);

    const [conditionResponseList, setConditionResponseList] = useState([])
    const [conditionResponseErrorList, setConditionResponseErrorList] = useState([]);

    const intl = useIntl();
    const { language } = useLanguage()

    const ACTION_TYPE_NAME = Object.freeze({
        "GET": `${settingPrefix}.${editActionPrefix}.nameList.action.get`,
        "INSERT": `${settingPrefix}.${editActionPrefix}.nameList.action.insert`,
        "UPDATE": `${settingPrefix}.${editActionPrefix}.nameList.action.update`,
        "OPTIONS": `${settingPrefix}.${editActionPrefix}.nameList.action.options`,
        "IDENTIFY_CONTACT": `${settingPrefix}.${editActionPrefix}.nameList.action.identifyContact`,
        "UPLOAD_ATTACHMENT": `${settingPrefix}.${editActionPrefix}.nameList.action.uploadAttachment`,
    })
    

    const ACTION_TYPE_SECTION = Object.freeze({
        "GET": "get",
        "INSERT": "insert",
        "UPDATE": "update",
        "OPTIONS": "options",
        "IDENTIFY_CONTACT": "identifyContact",
    })
    

    const getOptions = (fieldType, fieldName, options) => {
        if(fieldType !== EDITABLE_TYPE.PICKLIST)
            return []

        if(fieldName === "action")
            return options.map(option => ({label: option ? intl.formatMessage({ "id": ACTION_TYPE_NAME[option] }) : "", value: option}))

        if(fieldName === "responseId")
            return responseList.map(response => ({label: response.name, value: response.id}))

        if(fieldName === "failedResponseId")
            return responseList.map(response => ({label: response.name, value: response.id}))

        return options.map(option => ({label: option, value: option}))
    }

    const getIsRequiredByActionType = (action, field) => {
        switch (action.action) {
            case ACTION_TYPE.GET:
                if(field.fieldName === "optionLabelField" || field.fieldName === "optionValueField")
                    return !!action.resultAsOptions
                return field.required
            default:
                return field.required
        }
    }

    const getFieldByActionType = (action) => {
        switch (action.action) {
            case ACTION_TYPE.GET:
                if(!!action.resultAsOptions)
                    return ACTION_SETUP_GET_RESULT_AS_OPTIONS_FIELD_TYPE
                return ACTION_SETUP_GET_FIELD_TYPE
            case ACTION_TYPE.INSERT:
                return ACTION_SETUP_INSERT_FIELD_TYPE
            case ACTION_TYPE.UPDATE:
                return []
            case ACTION_TYPE.OPTIONS:
                return ACTION_SETUP_OPTION_FIELD_TYPE
            case ACTION_TYPE.IDENTIFY_CONTACT:
                return ACTION_SETUP_IDENTIFY_CONTACT_FIELD_TYPE
            default:
                return []
        }
    }
    
    const getShowFieldList = (action) => {
        switch (action) {
            case ACTION_TYPE.GET:
            case ACTION_TYPE.INSERT:
                return true
            default:
                return false
        }
    }
    
    const getShowDefaultValueList = (action) => {
        switch (action) {
            case ACTION_TYPE.INSERT:
            case ACTION_TYPE.UPDATE:
                return true
            default:
                return false
        }
    }

    const onChangeHandler = (isCreate, field, e) => {
        
        let value = e.target.value;
        let errorMap = {};

        switch (field.fieldType) {
            case EDITABLE_TYPE.BOOLEAN:
            case EDITABLE_TYPE.BOOLEAN_CHECKBOX:
                value = !editableAction[field.fieldName]
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;        
            default:
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;
        }

        if(errorMap && Object.keys(errorMap).length > 0)
            setErrorMap((erMap) => ({...erMap, ...errorMap}))
        else{
            setErrorMap((erMap) => {
                let newErrorMap = {...erMap}
                delete newErrorMap[field.fieldName]
                return newErrorMap 
            })
        }

        if(field.fieldName === "action"){
            if(getShowFieldList(value)){
                setFieldList([{...ACTION_FIELD_VALUE_FIELD_TEMPLATE}])
                setFieldErrorList([{}])
            }
            else{
                setFieldList([])
                setFieldErrorList([])
            }

            if(getShowDefaultValueList(value)){
                setDefaultValueList([{...ACTION_DEFAULT_VALUE_FIELD_TEMPLATE}])
                setDefaultValueErrorList([{}])    
            }
            else{
                setDefaultValueList([])
                setDefaultValueErrorList([])
            }

            if(value === ACTION_TYPE.OPTIONS){
                setEditableAction((editableFields) => ({...editableFields, [field.fieldName]: value, resultAsOptions: true}))
            }
        }


        setEditableAction((editableFields) => ({...editableFields, [field.fieldName]: value}))
    }


    const onFieldListItemChange = (isCreate, field, index, e) => {
        let value = e.target.value;
        updateField({name: value}, validateField(field, value, intl.formatMessage, isCreate), index)
    }

    const openResponseMsg = (e, responseId) => {
        console.log(responseId)
        let index = responseList.findIndex(response => response.id === responseId)

        if(index === -1)
            return

        setSelectedResponse(responseList[index])
        let pos = e.target.closest('.responseMsg-pos').getBoundingClientRect()
        console.log(pos)
        setResponseMsgMenuPos({x: pos.right, y: pos.bottom + 5})
        setShowResponseMsgMenu(true)
    }

    const closeResponseMsg = () => {
        setSelectedResponse(null)
        setResponseMsgMenuPos(null)
        setShowResponseMsgMenu(false)
    }

    const openParameterList = ({e, index, fieldName}) => {        
        setSelectedFieldIndex(index)
        setSelectedFieldName(fieldName)
        let pos = e.target.closest('.parameterList-pos').getBoundingClientRect()
        console.log(pos)
        setParameterListMenuPos({x: pos.right, y: pos.bottom + 5})
        setShowParameterListMenu(true)
    }

    const closeParameterList = () => {
        setSelectedFieldIndex(-1)
        setSelectedFieldName("")
        setParameterListMenuPos(null)
        setShowParameterListMenu(false)
    }

    const copyToMessageField = (parameter) => {
        if(!selectedFieldName)
            return;

        let value = ""

        switch (selectedFieldName) {
            case "defaultValueList":
                value = defaultValueList[selectedFieldIndex].value ?? "";
                updateDefaultValue({...defaultValueList[selectedFieldIndex], value: `${value} {!${parameter.key}}`}, {}, selectedFieldIndex)
                break;
            case "queryString":
                value = editableAction.queryString ?? "";
                setEditableAction((editableFields) => ({...editableFields, queryString: `${value} {!${parameter.key}}`}))
                break;
            default:
                break;
        }
    }

    const addField = () => addFieldItemAction({itemTemplate: ACTION_FIELD_VALUE_FIELD_TEMPLATE, setFieldList: setFieldList, setFieldErrorList: setFieldErrorList})
    const updateField = (field, error, index) => updateFieldItemAction({field, error, index, fieldList: fieldList, fieldErrorList: fieldErrorList, setFieldList: setFieldList, setFieldErrorList: setFieldErrorList})
    const removeField = (index) => removeFieldItemAction({index, setFieldList: setFieldList, setFieldErrorList: setFieldErrorList})

    const addDefaultValue = () => addFieldItemAction({itemTemplate: ACTION_DEFAULT_VALUE_FIELD_TEMPLATE, setFieldList: setDefaultValueList, setFieldErrorList: setDefaultValueErrorList})
    const updateDefaultValue = (field, error, index) => updateFieldItemAction({field, error, index, fieldList: defaultValueList, fieldErrorList: defaultValueErrorList, setFieldList: setDefaultValueList, setFieldErrorList: setDefaultValueErrorList})
    const removeDefaultValue = (index) => removeFieldItemAction({index, setFieldList: setDefaultValueList, setFieldErrorList: setDefaultValueErrorList})

    const addConditionResponse = () => addFieldItemAction({itemTemplate: ACTION_CONDITION_RESPONSE_FIELD_TEMPLATE, setFieldList: setConditionResponseList, setFieldErrorList: setConditionResponseErrorList})
    const updateConditionResponse = (field, error, index) => updateFieldItemAction({field, error, index, fieldList: conditionResponseList, fieldErrorList: conditionResponseErrorList, setFieldList: setConditionResponseList, setFieldErrorList: setConditionResponseErrorList})
    const removeConditionResponse = (index) => removeFieldItemAction({index, setFieldList: setConditionResponseList, setFieldErrorList: setConditionResponseErrorList})

    const validateFieldListItems = (isCreate) => {
        let errorList = [];
    
        for(let index = 0; index < fieldList.length; index++){
            let listItem = {...fieldList[index]}
            let errorMap = {}
    
            let mapping = [{...FIELDLIST_FIELD_TYPE}]
    
            for(let field of mapping){
                if(!listItem[field.fieldName])
                    setDefaultField(field, listItem, field.fieldName, isCreate)
                errorMap = {...errorMap, ...validateField(field, listItem[field.fieldName], intl.formatMessage, isCreate)}
            }
    
            errorList[index] = errorMap
        }

        let actionSet = fieldList.map(field => field.name)

        let actionSetDuplicates = checkListItemDuplication(actionSet)
        console.log(actionSetDuplicates)
        actionSetDuplicates.forEach(i => {
            if(!errorList[i])
                errorList[i] = {}
    
            errorList[i].name = { message: intl.formatMessage({ "id": `${errorPrefix}.duplicates` }) }
        })
    
        return errorList
    }

    const validateFields = (isCreate) => {
        let errorMap = {}

        let mapping = [ ...ACTION_SETUP_FIELD_TYPE, ...getFieldByActionType(editableAction), ...ACTION_SETUP_RESPONSE_FIELD_TYPE ]

        console.log(getFieldByActionType(editableAction))

        for(let field of mapping){
            if(!editableAction[field.fieldName])
                setDefaultField(field, editableAction, field.fieldName, isCreate)
            errorMap = {...errorMap, ...validateField(field, editableAction[field.fieldName], intl.formatMessage, isCreate)}
        }

        let fieldErrorList = []
        let defaultValueErrorList = []
        let conditionResponseErrorList = validateConditionResponseListItems(intl.formatMessage, isCreate, conditionResponseList)


        if(getShowFieldList(editableAction.action))
            fieldErrorList = validateFieldListItems(isCreate)

        if(getShowDefaultValueList(editableAction.action))
            defaultValueErrorList = validateDefaultValueListItems(intl.formatMessage, isCreate, defaultValueList)

        let hasError = false;

        if(errorMap && Object.keys(errorMap).length > 0)
            hasError = true;

        console.log(fieldErrorList)
        
        if(fieldErrorList.filter(error => Object.keys(error).length > 0).length > 0)
            hasError = true;

        if(defaultValueErrorList.filter(error => Object.keys(error).length > 0).length > 0)
            hasError = true;

        if(conditionResponseErrorList.filter(error => Object.keys(error).length > 0).length > 0)
            hasError = true;

        if(hasError){
            console.log(errorMap)
            setFieldErrorList(fieldErrorList)
            setDefaultValueErrorList(defaultValueErrorList)
            setConditionResponseErrorList(conditionResponseErrorList)
            setErrorMap(errorMap)
            return;
        }

        let completeObject = {...editableAction}
        let defaultValueMap = {}
        let conditionResponseIdMap = {}

        if(completeObject.recordCount)
            completeObject.recordCount = parseInt(completeObject.recordCount)

        completeObject.fieldList = fieldList.map(action => action.name)
        defaultValueMap = defaultValueList.reduce((map, action) => ({...map, [action.name]: action.value}), {})
        conditionResponseIdMap = conditionResponseList.reduce((map, action) => ({...map, [action.condition]: action.value}), {})

        updateAction({...completeObject, defaultValueMap, conditionResponseIdMap})
    }

    const refresh = () => {
        let editableAction = JSON.parse(JSON.stringify(item))

        console.log(editableAction)

        if(editableAction.recordCount !== undefined)
            editableAction.recordCount = `${editableAction.recordCount}`
        else
            editableAction.recordCount = "0"

        let fieldList = [];
        let defaultValueList = [];
        let conditionResponseList = [];

        if(!!editableAction.fieldList && editableAction.fieldList.length > 0)
            fieldList = editableAction.fieldList.map(message => ({name: message}))

        console.log(fieldList)

        if(getShowFieldList(editableAction.action) && fieldList.length < 1){
            setFieldList([{...ACTION_FIELD_VALUE_FIELD_TEMPLATE}])
            setFieldErrorList([{}])
        }
        else{
            setFieldList(fieldList)
            setFieldErrorList(Array(fieldList.length).fill({}))
        }

        if(editableAction.defaultValueMap && Object.keys(editableAction.defaultValueMap).length > 0)
            Object.entries(editableAction.defaultValueMap).forEach(([name, value]) => defaultValueList.push({name, value}))

        if(getShowDefaultValueList(editableAction.action) && defaultValueList.length < 1){
            setDefaultValueList([{...ACTION_DEFAULT_VALUE_FIELD_TEMPLATE}])
            setDefaultValueErrorList([{}])
        }
        else{
            setDefaultValueList(defaultValueList)
            setDefaultValueErrorList(Array(defaultValueList.length).fill({}))
        }

        if(editableAction.conditionResponseIdMap && Object.keys(editableAction.conditionResponseIdMap).length > 0)
            Object.entries(editableAction.conditionResponseIdMap).forEach(([condition, value]) => conditionResponseList.push({condition, value}))

        setConditionResponseList(conditionResponseList)
        setConditionResponseErrorList(Array(conditionResponseList.length).fill({}))

        setEditableAction(editableAction)
        setErrorMap({})

        setIsEdit(isEditable && !item.id)
    }

    useEffect(() => {
        refresh()
    }, [item])

    return (
        <>
            <div className="w-full h-full">
                <div className='w-full'><hr/></div>
                <div className={`w-full ${isEditable ? "h-[calc(100%-72px)]" : "h-full"}`}>
                    <Scrollbars
                        className="w-full h-full"
                        autoHide={false}
                        autoHideDuration={100}
                    >
                        <div className='w-full h-full flex flex-col'>
                            <span className='px-4 pt-[18px] text-[#64748B]'>DETAILS</span>
                            <div className='px-4'>
                                {ACTION_SETUP_FIELD_TYPE.map((field) => (
                                    <div key={field.fieldName} className='py-2'>
                                        <CusEditableLeftLabel
                                            readOnly={!isEdit || !isEditable || (!!item.id ? !field.editable : !field.creatable)}
                                            fieldType={field.fieldType}
                                            label={field.displayNameEn}
                                            value={editableAction[field.fieldName] ?? field.defaultValue}
                                            options={getOptions(field.fieldType, field.fieldName, field.options)}
                                            errors={errorMap}
                                            required={field.required}
                                            errorName={field.fieldName}
                                            onChange={(e) => onChangeHandler(item.isCreate, field, e)}
                                        />
                                    </div>
                                ))}
                            </div>
                            {!!editableAction.action && editableAction.action !== ACTION_TYPE.UPLOAD_ATTACHMENT &&
                                <>
                                    <div className='w-full'><hr/></div>
                                    <span className='px-4 pt-[18px] text-[#64748B]'>UPDATE CONDITIONS</span>
                                    <span className='px-4 pt-[8px] text-[#64748B] text-xs'>Setting of default value of input field</span>
                                    <div className='p-4'>
                                        {isEditable && isEdit && getShowFieldList(editableAction.action ?? "") &&
                                            <div className="flex flex-col">
                                                <div className="flex w-full items-center">
                                                    <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex text-base h-[40px] items-center'
                                                        color={ButtonColor.primaryNoBorder}
                                                        onClick={() => addField()}
                                                    >
                                                        <Plus/>
                                                        <span className='pl-2 text-base'>{intl.formatMessage({ "id": `${settingPrefix}.${editActionPrefix}.button.addFieldList` })}</span>
                                                    </CusRoundedButton>
                                                </div>
                                            </div>
                                        }
                                        {(fieldList.length > 0 || (isEdit && isEditable)) && getShowFieldList(editableAction.action ?? "") ?
                                                <>
                                                    <div className='px-4 w-full flex flex-col border-[#CBD5E1] border-[1px] rounded-[8px]'>
                                                        {fieldList.map((field, index) => (
                                                            <NonExpanableListItem
                                                                key={`MESSAGE-${index}`}
                                                                title={`${intl.formatMessage({ "id": `${settingPrefix}.${editActionPrefix}.expanable.text.inputField.defaultValue`})}#${index + 1}`}
                                                                remove={(index === 0 || !isEdit || !isEditable) ? null : () => removeField(index)}
                                                                readOnly={index === 0 || (!isEdit || !isEditable)}
                                                                >
                                                                <div className="flex flex-col">
                                                                    <CusEditableLeftLabel
                                                                        readOnly={(!isEdit || !isEditable) || (!!item.id ? !FIELDLIST_FIELD_TYPE.editable : !FIELDLIST_FIELD_TYPE.creatable)}
                                                                        fieldType={FIELDLIST_FIELD_TYPE.fieldType}
                                                                        label={FIELDLIST_FIELD_TYPE.displayNameEn}
                                                                        showLabelOnLeft={false}
                                                                        value={field[FIELDLIST_FIELD_TYPE.fieldName]}
                                                                        errors={(!!fieldErrorList && fieldErrorList[index]) ? fieldErrorList[index] : {}}
                                                                        required={FIELDLIST_FIELD_TYPE.required}
                                                                        errorName={FIELDLIST_FIELD_TYPE.fieldName}
                                                                        onChange={(e) => onFieldListItemChange(!item.id, FIELDLIST_FIELD_TYPE, index, e)}
                                                                    />     

                                                                </div>
                                                            </NonExpanableListItem>
                                                        ))}
                                                    </div>
                                                    <div className='pt-[18px]'></div>
                                                </>
                                            :
                                                null
                                        }
                                        {isEditable && isEdit && getShowDefaultValueList(editableAction.action ?? "") &&
                                            <div className="flex flex-col">
                                                <div className="flex w-full items-center">
                                                    <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex text-base h-[40px] items-center'
                                                        color={ButtonColor.primaryNoBorder}
                                                        onClick={() => addDefaultValue()}
                                                    >
                                                        <Plus/>
                                                        <span className='pl-2 text-base'>{intl.formatMessage({ "id": `${settingPrefix}.${editActionPrefix}.button.addInputField` })}</span>
                                                    </CusRoundedButton>
                                                </div>
                                            </div>
                                        }
                                        {(defaultValueList.length > 0 || (isEdit && isEditable)) && getShowDefaultValueList(editableAction.action ?? "") ?
                                                <>
                                                    <div className='px-4 w-full flex flex-col border-[#CBD5E1] border-[1px] rounded-[8px]'>
                                                        {defaultValueList.map((defaultValue, index) => (
                                                            <ActionDefaultValueListItem
                                                                key={`CONDITION-RESPONSE-${index}`}
                                                                isCreate={!item.id}
                                                                isEdit={isEdit}
                                                                isEditable={isEditable}
                                                                itemIndex={index}
                                                                fieldListItem={defaultValue}
                                                                error={defaultValueErrorList[index]}
                                                                updateField={(field, error) => updateDefaultValue(field, error, index)}
                                                                removeField={() => removeDefaultValue(index)}
                                                                openParameterList={(e) => openParameterList({e, index, fieldName: "defaultValueList"})}
                                                            />
                                                        ))}
                                                    </div>
                                                    <div className='pt-[18px]'></div>
                                                </>
                                            :
                                                null
                                        }
                                        {getFieldByActionType(editableAction).map((field) => (
                                            field.fieldName === "queryString" ?
                                                <div className='px-4 w-full flex flex-col border-[#CBD5E1] border-[1px] rounded-[8px]'>
                                                    <div className={`flex flex-col py-3 max-w-full`}>
                                                        <div className='flex justify-between items-center h-[24px]'>
                                                            <span className="text-[#94A3B8] text-[13px]">{`Query String`}</span>
                                                            <div className='flex items-center'>
                                                                {isEdit &&
                                                                    <div className={`text-[#94A3B8]`}>
                                                                        <div className="cursor-pointer parameterList-pos" onClick={(e) => openParameterList({e, index: -1, fieldName: "queryString"})}>
                                                                            <SubTasks className="w-[24px] h-[24px]"/>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <CusEditableLeftLabel
                                                                key={field.fieldName}
                                                                readOnly={!isEdit || !isEditable || (!!item.id ? !field.editable : !field.creatable)}
                                                                fieldType={field.fieldType}
                                                                label={field.displayNameEn}
                                                                showLabelOnLeft={false}
                                                                value={editableAction[field.fieldName] ?? field.defaultValue}
                                                                options={getOptions(field.fieldType, field.fieldName, field.options)}
                                                                errors={errorMap}
                                                                required={getIsRequiredByActionType(editableAction, field)}
                                                                errorName={field.fieldName}
                                                                onChange={(e) => onChangeHandler(item.isCreate, field, e)}
                                                            />                                                              
                                                        </div>
                                                    </div>
                                                    <div className='w-full'><hr/></div>
                                                </div>
                                            :
                                                <div key={field.fieldName} className='py-2'>
                                                    <CusEditableLeftLabel
                                                        key={field.fieldName}
                                                        readOnly={!isEdit || !isEditable || (!!item.id ? !field.editable : !field.creatable)}
                                                        fieldType={field.fieldType}
                                                        label={field.displayNameEn}
                                                        showLabelOnLeft={field.fieldType !== EDITABLE_TYPE.BOOLEAN_CHECKBOX}
                                                        value={editableAction[field.fieldName] ?? field.defaultValue}
                                                        options={getOptions(field.fieldType, field.fieldName, field.options)}
                                                        errors={errorMap}
                                                        required={getIsRequiredByActionType(editableAction, field)}
                                                        errorName={field.fieldName}
                                                        onChange={(e) => onChangeHandler(item.isCreate, field, e)}
                                                    />
                                                </div>
                                        ))}
                                    </div>
                                </>
                            }
                            <div className='w-full'><hr/></div>
                            <span className='px-4 pt-[18px] text-[#64748B]'>SETUP</span>
                            <div className='p-4'>
                                <div className='px-4 w-full flex flex-col border-[#CBD5E1] border-[1px] rounded-[8px]'>
                                    {ACTION_SETUP_RESPONSE_FIELD_TYPE.map((field) => (
                                        <div
                                            key={field.fieldName}
                                            className='py-2 flex'
                                        >
                                            <CusEditableLeftLabel
                                                readOnly={!isEdit || !isEditable || (!!item.id ? !field.editable : !field.creatable)}
                                                fieldType={field.fieldType}
                                                label={field.displayNameEn}
                                                value={editableAction[field.fieldName] ?? field.defaultValue}
                                                options={getOptions(field.fieldType, field.fieldName, field.options)}
                                                errors={errorMap}
                                                required={field.required}
                                                errorName={field.fieldName}
                                                onChange={(e) => onChangeHandler(item.isCreate, field, e)}
                                            />
                                            <div className={`p-2 text-[#94A3B8] responseMsg-pos`}>
                                                <div className={`${(editableAction[field.fieldName] ?? field.defaultValue) ? "cursor-pointer" : "opacity-50" }`} onClick={(editableAction[field.fieldName] ?? field.defaultValue) ? (e) => openResponseMsg(e, editableAction[field.fieldName] ?? field.defaultValue) : (e) => {} }>
                                                    <span className="text-[13px]">PREVIEW</span>
                                                </div>
                                            </div>                                        
                                        </div>                        
                                    ))}

                                </div>
                                <div className='pt-[18px]'></div>
                                {isEditable && isEdit && 
                                    <div className="flex flex-col">
                                        <div className="flex w-full items-center">
                                            <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex text-base h-[40px] items-center'
                                                color={ButtonColor.primaryNoBorder}
                                                onClick={() => addConditionResponse()}
                                            >
                                                <Plus/>
                                                <span className='pl-2 text-base'>{intl.formatMessage({ "id": `${settingPrefix}.${editActionPrefix}.button.addAdditional` })}</span>
                                            </CusRoundedButton>
                                        </div>
                                    </div>
                                }
                                {conditionResponseList.length > 0 &&
                                    <div className='px-4 w-full flex flex-col border-[#CBD5E1] border-[1px] rounded-[8px]'>
                                        {conditionResponseList.map((response, index) => (
                                            <ActionConditionResponseListItem
                                                key={`CONDITION-RESPONSE-${index}`}
                                                isCreate={!item.id}
                                                isEdit={isEdit}
                                                isEditable={isEditable}
                                                itemIndex={index}
                                                fieldListItem={response}
                                                responseList={responseList}
                                                error={conditionResponseErrorList[index]}
                                                updateField={(field, error) => updateConditionResponse(field, error, index)}
                                                removeField={() => removeConditionResponse(index)}
                                                openResponseMsg={(e) => openResponseMsg(e, response.value)}
                                            />
                                        ))}
                                    </div>
                                }
                            </div>
                        </div>
                    </Scrollbars>
                </div>
                {isEditable && 
                    isEdit ?
                        <div className="p-4 w-full flex justify-center items-center h-[72px] border-t-[1px] border-[#CBD5E140]">
                                <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex justify-center text-base flex-1 px-4 h-[40px] items-center rounded-[5px]' color={ButtonColor.primary}
                                        onClick={() => validateFields(!item.id)}
                                >
                                        <span className="px-4">{!item.id ? "Create" : "Update"}</span>
                                </CusRoundedButton>
                        </div>
                    :
                        <div className="p-4 w-full flex justify-between items-center h-[72px] border-t-[1px] border-[#CBD5E140]">
                                <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex justify-center text-base flex-shrink-0 px-4 h-[40px] items-center rounded-[5px]' color={ButtonColor.darkNoBorder}
                                        onClick={() => removeAction()}
                                >
                                        <span className="">Delete</span>
                                </CusRoundedButton>
                                <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex justify-center text-base flex-shrink-0 px-4 h-[40px] items-center rounded-[5px]' color={ButtonColor.primary}
                                        onClick={() => setIsEdit(true)}
                                >
                                        <span className="">Edit</span>
                                </CusRoundedButton>
                        </div>
                }
            </div>
            <DynamicContent screenX={parameterListMenuPos ? parameterListMenuPos.x : undefined} screenY={parameterListMenuPos ? parameterListMenuPos.y : -1} show={showParameterListMenu} setShow={setShowParameterListMenu} onUnFocus={closeParameterList}>
                <div className='text-greyblack'>
                    <FlowSettingDropdown
                        readOnly
                        searchTitle={`${intl.formatMessage({ "id": `${settingPrefix}.${editChatbotPrefix}.text.search`})}${intl.formatMessage({ "id": `${settingPrefix}.${editChatbotPrefix}.nameList.setting.parameter`})}`}
                        settingList={parameterList}
                        fieldName="key"
                        editAction={(setting) => copyToMessageField(setting)}
                    />
                </div>
            </DynamicContent>
            <ResponsePreviewItem
                response={selectedResponse}
                showMenu={showResponseMsgMenu}
                setShowMenu={setShowResponseMsgMenu}
                menuPos={responseMsgMenuPos}
                setMenuPos={setResponseMsgMenuPos}
                closeAction={closeResponseMsg}
            />
        </>
    )
}

EditAction.propTypes = {
    isEditable: PropTypes.bool,
    isEdit: PropTypes.bool,
    setIsEdit: PropTypes.func,
    item: PropTypes.object,
    updateAction: PropTypes.func,
    removeAction: PropTypes.func,
    parameterList: PropTypes.arrayOf(PropTypes.object),
    responseList: PropTypes.arrayOf(PropTypes.object),
};

export default EditAction;

