import Download from 'components/Icon/Media/Download';
import PropTypes from 'prop-types';
import { MESSAGE_TYPE } from 'util/helper';
import MediaAction from '.';
import { MESSAGE_CONTAINER_TYPE } from "components/ChatMessage/util";

const MESSAGE_FILE_NAME = {
    "IMAGE": "Image",
    "AUDIO": "Audio",
    "VIDEO": "Video",
    "FILE": "File",
}

const MediaDownloadable = ({
    disabled = false,
    messageId = "",
    messageType = MESSAGE_TYPE.IMAGE,
    mimeType = "",
    onDownload = null,
    onContextMenu = null,
    loading = true,
    containerType = MESSAGE_CONTAINER_TYPE.AGENT,
}) => (
    <MediaAction
        actionIcon={<Download/>}
        title={MESSAGE_FILE_NAME[messageType]}
        messageType={messageType}
        mimeType={mimeType}
        loading={loading}
        disabled={disabled}
        containerType={containerType}
        onClick={() => disabled ? {} : onDownload(messageId)}
        onContextMenu={() => onContextMenu(messageId)}
    />
)


MediaDownloadable.propTypes = {
    messageId: PropTypes.string,
    messageType: PropTypes.string,
    mimeType: PropTypes.string,
    onDownload: PropTypes.func,
    onContextMenu: PropTypes.func,

    loading: PropTypes.bool,
    containerType: PropTypes.string,
};

export default MediaDownloadable;