import { createSlice } from '@reduxjs/toolkit'

// import {
//     SET_LANGUAGE
// } from 'store/types/ui/sidebar';

import AppConfig from 'constants/AppConfig';

const LANGUAGE_MAP = {
	'en': {
		languageId: 'english',
		locale: 'en',
		value: 'en',
		name: 'English',
		label: 'English',
		icon: 'en',
		short: "EN",
		mapping: "En",
	},
	'zh': {
		languageId: 'chinese',
		locale: 'zh',
		value: 'zh',
		name: '简体中文',
		label: '简体中文',
		icon: 'zh',
		short: "SC",
		mapping: "Sc",
	},
	'tzh': {
		languageId: 'tchinese',
		locale: 'tzh',
		value: 'tzh',
		name: '繁體中文',
		label: '繁體中文',
		icon: 'tzh',
		short: "TC",
		mapping: "Tc",
	}	
}

const INIT_STATE = {
    locale: localStorage.getItem("locale") ? JSON.parse(localStorage.getItem("locale") ) : (
		process.env.LOCALE ? LANGUAGE_MAP[process.env.LOCALE] : AppConfig.locale
	),
    languages: [
		{
			languageId: 'english',
			locale: 'en',
			value: 'en',
			name: 'English',
			label: 'English',
			icon: 'en',
			short: "EN",
			mapping: "En",			
		},
		{
			languageId: 'chinese',
			locale: 'zh',
			value: 'zh',
			name: '简体中文',
			label: '简体中文',
			icon: 'zh',
			short: "SC",
			mapping: "Sc",			
        },
		{
			languageId: 'tchinese',
			locale: 'tzh',
			value: 'tzh',
			name: '繁體中文',
			label: '繁體中文',
			icon: 'tzh',
			short: "TC",
			mapping: "Tc",			
		}		
	],
	pageSize: localStorage.getItem("pageSize") ? JSON.parse(localStorage.getItem("pageSize") ) : {},
	pageSort: localStorage.getItem("pageSort") ? JSON.parse(localStorage.getItem("pageSort") ) : {},
}

const Setting = createSlice({
    name: "Setting",
    initialState: INIT_STATE,
    reducers: {
			SET_LANGUAGE: (state, action) => {
				state.locale = action.payload
					},
			SET_PAGE_SIZE: (state, action) => {
				state.pageSize = {...state.pageSize, ...action.payload}
					},
			SET_PAGE_SORT: (state, action) => {
				state.pageSort = {...state.pageSort, ...action.payload}
					},
			CLEAN_PAGE_SIZE: (state) => {
				state.pageSize = {}
					},
    }
})

// export const SettingReducer = (state = INIT_STATE, action) => {
//     switch (action.type){
//         case SET_LANGUAGE:
//             return { ...state, locale: action.payload };

//         default: return { ...state };

//     }
// }

export const {
	SET_LANGUAGE,
	SET_PAGE_SIZE,
	SET_PAGE_SORT,
	CLEAN_PAGE_SIZE
} = Setting.actions

export default Setting.reducer

//selector
export const getLanguages = state => state.languages;
export const getLanguage = state => state.locale;
export const getPageSize = state => state.pageSize;
export const getPageSort = state => state.pageSort;