import {httpPost} from 'lib/requests';

const getNode = (data, authToken) => {
    return httpPost('chatbot/node/get', data, authToken);
}

const createNode = (data, authToken) => {
    return httpPost('chatbot/node/create', data, authToken);
}

const updateNode = (data, authToken) => {
    return httpPost('chatbot/node/update', data, authToken);
}

const removeNode = (data, authToken) => {
    return httpPost('chatbot/node/delete', data, authToken);
}

const cloneNode = (data, authToken) => {
    return httpPost('chatbot/node/clone', data, authToken);
}

export {
    getNode,
    createNode,
    updateNode,
    removeNode,
    cloneNode,
};