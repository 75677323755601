import { createSlice } from '@reduxjs/toolkit'

const INIT_STATE = {
    actionList: [],
    loading: false,
    error: null
}

const Action = createSlice({
    name: 'Action',
    initialState: INIT_STATE,
    reducers: {
        GET_ACTIONS: (state) => {
            state.loading = true;
            state.error = null;
        },
        GET_ACTIONS_SUCCESS: (state, action) => {
            state.loading = false;
            state.actionList = action.payload;
            state.error = null;
        },
        GET_ACTIONS_FAILURE: (state, action) => {
            state.loading = false;
            state.actionList = [];
            state.error = action.payload.error;
        },

        BULK_ACTION: (state) => {
            state.loading = true;
            state.error = null;
        },
        BULK_ACTION_SUCCESS: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        BULK_ACTION_FAILURE: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
        },

    }
})

export const {
    GET_ACTIONS,
    GET_ACTIONS_FAILURE,
    GET_ACTIONS_SUCCESS,
    BULK_ACTION,
    BULK_ACTION_SUCCESS,
    BULK_ACTION_FAILURE,
} = Action.actions;


export default Action.reducer;

export const getActionList = state => state.actionList;
export const getLoading = state => state.loading;
export const getError = state => state.error;