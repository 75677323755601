const Search = (rest) => {
    return(
        <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path fill="currentColor" d="M15.7064 16.2436L10.9006 11.5081C8.76275 13.0056 5.81685 12.6393 4.12363 10.6655C2.43041 8.6918 2.55474 5.76906 4.40965 3.94182C6.26407 2.11378 9.23102 1.99076 11.2348 3.65883C13.2386 5.3269 13.6105 8.22947 12.0905 10.3359L16.8963 15.0714L15.7073 16.2428L15.7064 16.2436ZM7.97678 4.14229C6.38217 4.14194 5.00643 5.24472 4.6825 6.78297C4.35857 8.32122 5.17547 9.87216 6.63862 10.4968C8.10178 11.1214 9.80904 10.6481 10.7268 9.3633C11.6445 8.07855 11.5204 6.3355 10.4297 5.18948L10.9385 5.68656L10.365 5.1232L10.3549 5.11326C9.7257 4.48958 8.86932 4.13992 7.97678 4.14229Z"/>
        </svg>

    );
}

export default Search;