// import appLocaleData from 'react-intl/locale-data/en';
import {
    general,
    table,
    errors,
    info,
    chatpanel,
    
    setting
} from '../locales/en_US';

const EnLang = {
    messages: {
        ...general,
        ...table,
        ...errors,
        ...info,
        ...chatpanel,

        ...setting
    },
    locale: 'en-US',
    // data: appLocaleData
};
export default EnLang;