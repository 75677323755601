import ChatMessage from 'components/ChatMessage';
import { MESSAGE_CONTAINER_TYPE } from 'components/ChatMessage/util';
import CusCard, { Color as CardColor, PaddingType as CardPaddingType } from 'components/CusCard';
import { Color as ButtonColor, CusRoundedButton, PaddingType as ButtonPadding, RoundType as ButtonRoundType } from 'components/CusRoundedButton';
import DynamicContent from 'components/DynamicContent';
import Edit from 'components/Icon/Edit';
import Info2 from 'components/Icon/Info2';
import { editNodePrefix, settingPrefix } from 'lang/locales/prefix';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

const ResponsePreviewItem = ({
    isEditable = false,
    showMenu = false,
    response = null,
    setShowMenu = () => {},
    menuPos = null,
    setMenuPos = () => {},
    selectAction = () => {},
    closeAction = () => {},
}) => {

    const intl = useIntl();

    return (
        <DynamicContent screenX={menuPos ? menuPos.x : -1} screenY={menuPos ? menuPos.y : -1} show={showMenu} setShow={setShowMenu} onUnFocus={closeAction}>
            <CusCard color={CardColor.white} paddingType={CardPaddingType.small} className={`select-none flex flex-col drop-shadow-md rounded-lg w-[300px] text-greyblack`}>
                <div className="flex justify-between items-center text-[#94A3B8] text-xs px-2 pt-2 pb-4">
                    <div className="pr-2">
                        {intl.formatMessage({ "id": `${settingPrefix}.${editNodePrefix}.text.response.preview`})}
                    </div>
                    {isEditable ?
                        <div className="cursor-pointer" onClick={() => selectAction(response)}>
                            <span className="text-[13px]">EDIT</span>
                        </div>
                        :
                        null
                    }
                </div>
                {!!response ?
                    <>
                        {response.messageList && response.messageList.map((message, index) => 
                            <ChatMessage
                                key={`preview-message-${index}`}
                                message={message}
                                containerType={MESSAGE_CONTAINER_TYPE.CUSTOMER}
                                containerSize=""
                            />
                        )}
                        {response.optionMap && Object.entries(response.optionMap)
                            .sort((a, b) => a[1].toLowerCase().localeCompare(b[1].toLowerCase()))
                            .map(([key, value], index) => (
                                <div
                                    key={`preview-option-${index}`}
                                >
                                    <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex text-xs flex-shrink-0 my-2 mx-4 px-6 h-[40px] items-center rounded-[5px]' color={ButtonColor.primaryFrame}>
                                        <span className="truncate">{key}</span>
                                    </CusRoundedButton>                        
                                </div>
                            ))
                        }
                    </>
                    :
                    null
                }
            </CusCard>
        </DynamicContent>
    )
}


ResponsePreviewItem.propTypes = {
    isEditable: PropTypes.bool,
    response: PropTypes.object,
    showMenu: PropTypes.bool,
    setShowMenu: PropTypes.func,
    menuPos: PropTypes.object,
    setMenuPos: PropTypes.func,
    nodeAction: PropTypes.func,
    selectAction: PropTypes.func,
    closeAction: PropTypes.func,
};

export default ResponsePreviewItem;
