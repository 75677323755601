
import PropTypes from 'prop-types';
import React from 'react';

const TextItem = ({text = "", className = ""}) => (
    <div title={text} className={`w-full h-full flex items-center ${className}`}><div className='truncate'>{text}</div></div>
)

TextItem.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
};

export default TextItem;