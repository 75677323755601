import PropTypes from 'prop-types';
import { useState } from 'react';

import { EDITABLE_TYPE, setDefaultField, validateField } from 'components/CusEditable';
import CusEditableLeftLabel from 'components/CusEditable/CusEditableLeftLabel';
import { Color as ButtonColor, CusRoundedButton, PaddingType as ButtonPadding, RoundType as ButtonRoundType } from 'components/CusRoundedButton';
import NonExpanableListItem from 'components/NonExpanableListItem';
import Bin from 'components/Icon/Bin';
import { useLanguage } from 'hooks/useLanguage';
import { checkListItemDuplication } from 'util/helper';
import { useIntl } from 'react-intl';
import { editEntityPrefix, settingPrefix } from 'lang/locales/prefix';
import Plus from 'components/Icon/Plus';
import Cross from 'components/Icon/Cross';
import Minimise from 'components/Icon/Minimise';

export const ENTITY_OPTION_FIELD_TEMPLATE = Object.freeze({
    "name": "",
    "list": [""],
})

export const OPTION_FIELD_TYPE = [
    {
        "fieldName": "name",
        "displayNameKey": `${settingPrefix}.${editEntityPrefix}.field.option.name`,
        "displayNameEn": "Option name",
        "displayNameTc": "選項名稱",
        "displayNameSc": "选项名称",
        "fieldType": EDITABLE_TYPE.TEXT,
        "required": true,
        "editable": true,
        "creatable": true,
    },
]

export const OPTION_VALUE_FIELD_TYPE = {
    "fieldName": "value",
    "displayNameKey": `${settingPrefix}.${editEntityPrefix}.field.option.value`,
    "displayNameEn": "Option",
    "displayNameTc": "選項",
    "displayNameSc": "选项",
    "fieldType": EDITABLE_TYPE.TEXT,
    "required": true,
    "editable": true,
    "creatable": true,
}

const validateOptionValues = (errorFormater, isCreate, fieldList) => {
    let errorList = [];

    for (let index = 0; index < fieldList.length; index++) {
        let valueListItem = fieldList[index]
        let errorMap = {}

        let mapping = [{ ...OPTION_VALUE_FIELD_TYPE }]

        for (let field of mapping)
            errorMap = { ...errorMap, ...validateField(field, valueListItem, errorFormater, isCreate) }

        errorList[index] = errorMap
    }

    let duplicates = checkListItemDuplication(fieldList)
    duplicates.forEach(i => {
        if (!errorList[i])
            errorList[i] = {}

        errorList[i].value = { message: errorFormater({ "id": `${settingPrefix}.${editEntityPrefix}.duplicates.option` }) }
    })

    return errorList
}

export const validateOptionListItems = (errorFormater, isCreate, fieldList) => {
    let errorList = [];

    for (let index = 0; index < fieldList.length; index++) {
        let fieldListItem = { ...fieldList[index] }
        let errorMap = {}

        let mapping = [...OPTION_FIELD_TYPE]

        for (let field of mapping) {
            if (!fieldListItem[field.fieldName])
                setDefaultField(field, fieldListItem, field.fieldName, isCreate)
            errorMap = { ...errorMap, ...validateField(field, fieldListItem[field.fieldName], errorFormater, isCreate) }
        }

        errorMap.list = validateOptionValues(errorFormater, isCreate, fieldListItem.list)
        errorList[index] = errorMap
    }

    let fieldSet = fieldList.map(field => field.name)

    let duplicates = checkListItemDuplication(fieldSet)
    duplicates.forEach(i => {
        if (!errorList[i])
            errorList[i] = {}

        errorList[i].name = { message: errorFormater({ "id": `${settingPrefix}.${editEntityPrefix}.duplicates.option` }) }
    })

    return errorList
}

const EnityOptionListItem = ({
    isCreate = false,
    isEdit = false,
    isEditable = false,
    itemIndex = -1,
    fieldListItem = null,
    error = null,
    updateField = null,
    removeField = null,
}) => {

    const intl = useIntl();
    const { language } = useLanguage()

    const getOptions = (fieldType, fieldName, options) => {
        if (fieldType !== EDITABLE_TYPE.PICKLIST)
            return []

        return options.map(option => ({ label: option, value: option }))
    }

    const onChangeHandler = (isCreate, field, e) => {

        let value = e.target.value;
        let currentErrorMap = { ...error };
        let errorMap = {};

        switch (field.fieldType) {
            case EDITABLE_TYPE.BOOLEAN:
            case EDITABLE_TYPE.BOOLEAN_CHECKBOX:
                value = !fieldListItem[field.fieldName]
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;
            default:
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;
        }

        if (errorMap && Object.keys(errorMap).length > 0)
            currentErrorMap = { ...currentErrorMap, ...errorMap }
        else
            delete currentErrorMap[field.fieldName]

        updateField({ ...fieldListItem, [field.fieldName]: value }, currentErrorMap)
    }


    const onOptionChangeHandler = (isCreate, field, index, e) => {
        let value = e.target.value;

        let newOptionList = [...fieldListItem.list]
        let newOptionErrorList = error.list ? [...error.list] : []
        let currentError = error.list && error.list[index] ? { ...error.list[index] } : {}
        let errorMap = validateField(field, value, intl.formatMessage, isCreate);

        if (errorMap && Object.keys(errorMap).length > 0)
            currentError = { ...currentError, ...errorMap }
        else
            delete currentError[field.fieldName]

        newOptionList[index] = value
        newOptionErrorList[index] = currentError
        updateField({ ...fieldListItem, list: newOptionList }, { ...error, list: newOptionErrorList })
    }

    const addOption = () => {
        let newOptionList = fieldListItem.list ? [...fieldListItem.list] : []
        let newOptionErrorList = error.list ? [...error.list] : []

        newOptionList.push(``)
        newOptionErrorList.push({})

        updateField({ ...fieldListItem, list: newOptionList }, { ...error, list: newOptionErrorList })
    }

    const removeOption = (index) => {
        let newOptionList = [...fieldListItem.list]
        let newOptionErrorList = error.list ? [...error.list] : []

        newOptionList.splice(index, 1)
        newOptionErrorList.splice(index, 1)

        updateField({ ...fieldListItem, list: newOptionList }, { ...error, list: newOptionErrorList })

    }

    return (
        <NonExpanableListItem
            title={`${intl.formatMessage({ "id": `${settingPrefix}.${editEntityPrefix}.expanable.text.option`})}#${itemIndex + 1}`}
            remove={(!isEdit || !isEditable) ? null : removeField}
            readOnly={itemIndex === 0 || (!isEdit || !isEditable)}
        >
            {OPTION_FIELD_TYPE.map((field) => (
                <div key={field.fieldName} className="py-2">
                    <CusEditableLeftLabel
                        readOnly={(!isEdit || !isEditable) || (!isCreate ? !field.editable : !field.creatable)}
                        fieldType={field.fieldType}
                        showLabelOnLeft={false}
                        label={field.displayNameEn}
                        value={fieldListItem[field.fieldName] ?? field.defaultValue}
                        options={getOptions(field.fieldType, field.fieldName, field.options)}
                        errors={error}
                        required={field.required}
                        errorName={field.fieldName}
                        onChange={(e) => onChangeHandler(isCreate, field, e)}
                    />
                </div>
            ))}
            {fieldListItem.list ?
                fieldListItem.list.map((option, index) => (
                    <div
                        key={`${OPTION_VALUE_FIELD_TYPE.fieldName}-${index}`}
                        className="py-2 flex"
                    >
                        <CusEditableLeftLabel
                            readOnly={(!isEdit || !isEditable) || (!isCreate ? !OPTION_VALUE_FIELD_TYPE.editable : !OPTION_VALUE_FIELD_TYPE.creatable)}
                            fieldType={OPTION_VALUE_FIELD_TYPE.fieldType}
                            showLabelOnLeft={false}
                            label={`${OPTION_VALUE_FIELD_TYPE.displayNameEn} ${index + 1}`}
                            value={option}
                            errors={(!!error && !!error.list && error.list[index]) ? error.list[index] : {}}
                            required={OPTION_VALUE_FIELD_TYPE.required}
                            errorName={OPTION_VALUE_FIELD_TYPE.fieldName}
                            onChange={(e) => onOptionChangeHandler(isCreate, OPTION_VALUE_FIELD_TYPE, index, e)}
                        />
                        {(!isEdit || !isEditable) ?
                            null
                            :
                            <div className="w-[30px] h-[40px] flex justify-center items-center">
                                <div className={`flex justify-center items-center text-[#94A3B8] ${index === 0 ? "" : "cursor-pointer"} `}><div className={`${index === 0 ? "opacity-50" : "cursor-pointer"}`} onClick={index === 0 ? () => {} : () => removeOption(index)}><Minimise/></div></div>
                            </div>
                        }
                    </div>
                ))
                :
                null
            }

            {(!isEdit || !isEditable) ?
                null
                :
                <div className="flex flex-col">
                    <div className="flex w-full items-center">
                        <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex text-base h-[40px] items-center'
                            color={ButtonColor.primaryNoBorder}
                            onClick={() => addOption()}
                        >
                            <Plus/>
                            <span className='pl-2 text-base'>{intl.formatMessage({ "id": `${settingPrefix}.${editEntityPrefix}.button.addOption` })}</span>
                        </CusRoundedButton>
                    </div>
                </div>
                // <div className="flex w-full py-4">
                //     <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex text-xs flex-shrink-0 px-10 mr-6 h-[40px] items-center rounded-[5px]'
                //         color={ButtonColor.primaryNoBorder}
                //         onClick={() => addOption()}
                //     >
                //         <span>{intl.formatMessage({ "id": `${settingPrefix}.${editEntityPrefix}.button.addOption` })}</span>
                //     </CusRoundedButton>
                // </div>
            }
        </NonExpanableListItem>
    )
}

EnityOptionListItem.propTypes = {
    isCreate: PropTypes.bool,
    isEdit: PropTypes.bool,
    isEditable: PropTypes.bool,
    itemIndex: PropTypes.number,
    fieldListItem: PropTypes.object,
    error: PropTypes.object,
    updateField: PropTypes.func,
    removeField: PropTypes.func,
};

export default EnityOptionListItem;
