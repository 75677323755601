import { editNodePrefix, editTemplatePrefix, rolePrefix, roleSettingPrefix, templatePrefix } from "../prefix"
import { editChatbotPrefix } from "../prefix"
import { editKeywordPrefix } from "../prefix"
import { editLivechatSettingPrefix } from "../prefix"
import { editEntityPrefix } from "../prefix"
import { editResponsePrefix } from "../prefix"
import { editActionPrefix } from "../prefix"
import { editParameterPrefix } from "../prefix"
import { businessGeneralSettingPrefix, businessPrefix, channelAccountPrefix, chatAutomationPrefix, chatbotPrefix, chatbotIconBarPrefix, chatGroupPrefix, chatRoutingPrefix, editChannelAccountPrefix, editChatAutomationPrefix, createOrCloneChatbotPrefix, editChatRoutingPrefix, editFieldConfigPrefix, editOpenaiPrefix, editQuickTextPrefix, editSystemMessagePrefix, fieldConfigPrefix, livechatSettingPrefix, messagePrefix, openaiPrefix, planSubscriptionPrefix, quickTextPrefix, settingPrefix, systemGeneralSettingPrefix, systemMessagePrefix, systemPrefix, taggingPrefix, userListPrefix, wrapUpPrefix } from "../prefix"

const layout = {
    [`${settingPrefix}.general`]: "GENERAL SETTINGS",
    [`${settingPrefix}.general.business`]: "Business",
    [`${settingPrefix}.general.user`]: "User",
    [`${settingPrefix}.general.systemFeature`]: "System feature",
    [`${settingPrefix}.general.role`]: "Role",

    [`${settingPrefix}.chat`]: "CHAT SETUP",
    [`${settingPrefix}.chat.channelAccount`]: "Channel account",
    [`${settingPrefix}.chat.chatGroup`]: "Chat group",
    [`${settingPrefix}.chat.chatRouting`]: "Chat routing",

    [`${settingPrefix}.conversations`]: "CONVERSATIONS",
    [`${settingPrefix}.conversations.message`]: "Message",
    [`${settingPrefix}.conversations.tagging`]: "Tagging",
    [`${settingPrefix}.conversations.wrapUp`]: "Wrap-up",

    [`${settingPrefix}.automation`]: "AUTOMATION",
    [`${settingPrefix}.automation.chatAutomation`]: "Chat automation",
    [`${settingPrefix}.automation.chatbot`]: "Chatbot settings",
    [`${settingPrefix}.automation.openaiSetting`]: "OpenAI settings",

    [`${settingPrefix}.widget`]: "WIDGET",
    [`${settingPrefix}.widget.liveChatbot`]: "Live chat settings",

}

const business = {
    [`${settingPrefix}.${businessPrefix}.page.text.title`]: "Business settings",

    [`${settingPrefix}.${businessPrefix}.tab.title.general`]: "General",
    [`${settingPrefix}.${businessPrefix}.tab.title.planSubscription`]: "Plan subscription",
}

const businessGeneralSetting = {
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.title.profile`]: "Business profile",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.subTitle.profile`]: "Tell us about your business",
    
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.title.logo`]: "Business logo",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.subTitle.logo`]: "This logo will be applied on the system only. It should be in PNG or SVG format, in maximum 120px x 35px resolution.",
    
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.title.address`]: "Business address",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.subTitle.address`]: "Default as the key business address on system",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.title.timeAndLang`]: "Time Zone and Language",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.subTitle.timeAndLang`]: "Default as key time zone and language on system",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.title.regularHours`]: "Regular business hours and days",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.subTitle.regularHours`]: "Default as regular business hours and days for chat interaction",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.profile.name`]: "Business name",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.profile.email`]: "Email",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.address.address`]: "Address",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.address.zipCode`]: "Zip code",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.address.country`]: "Region / Country",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.extra.timezone`]: "Time Zone",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.extra.defaultLanguage`]: "Language",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.regularHours.name`]: "Name",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.regularHours.day`]: "Day",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.regularHours.timeFrom`]: "Time from",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.regularHours.timeTo`]: "Time to",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.nameList.type.mondays`]: "Mondays",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.nameList.type.tuesdays`]: "Tuesdays",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.nameList.type.wednesdays`]: "Wednesdays",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.nameList.type.thursdays`]: "Thursdays",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.nameList.type.fridays`]: "Fridays",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.nameList.type.saturdays`]: "Saturdays",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.nameList.type.sundays`]: "Sundays",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.button.addDay`]: "Add day(s)",
}

const planSubscription = {
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.table.text.title`]: "Plan subcription",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.button.contact`]: "Contact now",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.button.modify`]: "Modify Plan",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.button.subscribe`]: "Subscribe Plan",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.modal.warning`]: "Contact Recur to upgrade or cancel the plan subscription",

    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.table.displayName.0`]: "Plan",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.table.displayName.1`]: "Subscription date",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.table.displayName.2`]: "Expiry date",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.table.displayName.3`]: "Total licenses",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.table.displayName.4`]: "Status",

    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.card.text.current`]: "Current plan",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.card.text.total`]: "Total licenses",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.card.text.quota`]: " licenses",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.card.text.used`]: "Used licences",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.card.text.unused`]: "Unused licenses",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.card.text.expiry`]: "Expiry date (Upcoming)",

    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.nameList.status.expired`]: "Expired",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.nameList.status.canceled`]: "Canceled",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.nameList.status.pending`]: "Pending",

    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.text.title`]: "CHOOSE YOUR LICENSE",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.text.subTitle`]: "Choose the right solution for your business",

    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.card.text.priceTag`]: "PER MONTH",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.card.text.subscribe`]: "SUBSCRIBE",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.card.text.back`]: "BACK",

    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.field.priceTag`]: "Price Tag",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.field.quantity`]: "Quantity",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.field.option.monthly`]: "Monthly",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.field.option.yearly`]: "Yearly",
}

const userList = {
    [`${settingPrefix}.${userListPrefix}.table.text.title`]: "User settings",
    [`${settingPrefix}.${userListPrefix}.table.text.subTitle`]: `Edit users' role and profile here`,
    [`${settingPrefix}.${userListPrefix}.table.placeholder.search`]: "Search users",

    [`${settingPrefix}.${userListPrefix}.modal.warning.disable`]: "Do you confirm to disable this user?",
    [`${settingPrefix}.${userListPrefix}.modal.warning.remove`]: "Do you confirm to remove this user?",

    [`${settingPrefix}.${userListPrefix}.table.displayName.0`]: "Display name",
    [`${settingPrefix}.${userListPrefix}.table.displayName.1`]: "Username",
    [`${settingPrefix}.${userListPrefix}.table.displayName.2`]: "Email",
    [`${settingPrefix}.${userListPrefix}.table.displayName.3`]: "Role",
    [`${settingPrefix}.${userListPrefix}.table.displayName.4`]: "Active",

    [`${settingPrefix}.${userListPrefix}.button.addUsers`]: "Add users",
}

const system = {
    [`${settingPrefix}.${systemPrefix}.page.text.title`]: "System feature settings",

    [`${settingPrefix}.${systemPrefix}.tab.title.general`]: "General",
    [`${settingPrefix}.${systemPrefix}.tab.title.fieldConfig`]: "Field Config",
}

const systemGeneralSetting = {
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.section.text.title.scheduler`]: "Scheduler config",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.section.text.subTitle.scheduler`]: "Set up Scheduler config",
    
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.section.text.title.iframe`]: "Iframe Domain Config",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.section.text.subTitle.iframe`]: "Set up Iframe Domain Config",
    
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.section.text.title.crossDomain`]: "Cross Domain Config",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.section.text.subTitle.crossDomain`]: "Set up Cross Domain Config",

    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.section.text.title.integration`]: "System Integration",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.section.text.subTitle.integration`]: "Set up System Integration Config",

    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.button.addDomain`]: "Add Domain(s)",

    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.scheduler.autoOffline`]: "Auto Offline",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.scheduler.chatbotConversationAutoClose`]: "Chatbot Conversation Auto Close",

    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.enableExternalSystem.isEnableExternalSystem`]: "Enable External System",
    
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.externalSystem.externalSystemType`]: "External System Type",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.externalSystem.endpoint`]: "Server Endpoint",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.externalSystem.clientId`]: "Client Id",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.externalSystem.secretKey`]: "SecretKey",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.externalSystem.username`]: "Username",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.externalSystem.password`]: "Password",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.externalSystem.createConversation`]: "Create Conversation",
    
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.domain.domain`]: "Domain",

}

const fieldConfig = {
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.text.title`]: "Field Config",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.placeholder.search`]: "Search field",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.modal.warning`]: "Confirm to disable this field?",

    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.displayName.0`]: "Field name",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.displayName.1`]: "Table name",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.displayName.2`]: "Display name",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.displayName.3`]: "Field type",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.displayName.4`]: "Required",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.displayName.5`]: "Editable",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.displayName.6`]: "Creatable",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.displayName.7`]: "Status",

    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.button.addConfig`]: "Add config",
}

const editfieldConfig = {
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.text.title.edit`]: "EDIT FIELD CONFIG",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.text.title.add`]: "NEW FIELD CONFIG",

    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.title.type`]: "Field type settings",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.subTitle.type`]: "Set up Field type",

    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.title.field`]: "Field config settings",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.subTitle.field`]: "Set up Field config",
    
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.title.option`]: "Field config optional settings",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.subTitle.option`]: "Set up Field optional config",
    
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.title.extra`]: "Field extra config settings",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.subTitle.extra`]: "Set up Type specific Field config",
    
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.title.status`]: "Field status",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.subTitle.status`]: "Enable or disable Field config",

    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.progress.0`]: "Field type settings",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.progress.1`]: "Field config settings",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.progress.2`]: "Field config optional settings",

    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.fieldName`]: "Field Name",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.tableName`]: "Table Name",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.displayNameEn`]: "Display Name (English)",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.displayNameTc`]: "Display Name (Traditional Chinese)",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.displayNameSc`]: "Display Name (Simplified Chinese)",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.displayOrder`]: "Display Order",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.listViewOrder`]: "ListView Order",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.required`]: "Required field",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.editable`]: "Editable field",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.searchable`]: "Searchable field",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.creatable`]: "Creatable field",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.traceable`]: "Traceable field",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.displayInListview`]: "Display In Listview",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.fieldType`]: "Field Type",

    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.extra.length`]: "Length",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.extra.validationRegex`]: "Validation Regex",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.extra.decimalPlace`]: "Decimal Place",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.extra.format`]: "Running Number Format",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.extra.picklist`]: "Picklist Option",

}

const role = {
    [`${settingPrefix}.${rolePrefix}.table.text.title`]: "Role settings",
    [`${settingPrefix}.${rolePrefix}.table.text.subTitle`]: "Setup role permission",
    
    [`${settingPrefix}.${rolePrefix}.table.placeholder.search`]: "Search roles",
    [`${settingPrefix}.${rolePrefix}.text.type`]: "Type",

    [`${settingPrefix}.${rolePrefix}.table.displayName.0`]: "name",
    [`${settingPrefix}.${rolePrefix}.table.displayName.1`]: "Display name",
    [`${settingPrefix}.${rolePrefix}.table.displayName.2`]: "Type",

    [`${settingPrefix}.${rolePrefix}.nameList.type.system`]: "System",
    [`${settingPrefix}.${rolePrefix}.nameList.type.custom`]: "Custom",
}

const roleSetting = {
    [`${settingPrefix}.${roleSettingPrefix}.progress.0`]: "General config",
    [`${settingPrefix}.${roleSettingPrefix}.progress.1`]: "Permission",

    [`${settingPrefix}.${roleSettingPrefix}.modal.warning`]: "Are you sure you want to reset?\n Changes will not be saved.",
    [`${settingPrefix}.${roleSettingPrefix}.modal.warning.permission`]: "Are you sure you want to reset permisson?",

    [`${settingPrefix}.${roleSettingPrefix}.field.config.name`]: "Name",
    [`${settingPrefix}.${roleSettingPrefix}.field.config.displayName`]: "Display name",

    [`${settingPrefix}.${roleSettingPrefix}.text.title.new`]: "New Role",
    
    [`${settingPrefix}.${roleSettingPrefix}.section.text.title.general`]: "General config",
    [`${settingPrefix}.${roleSettingPrefix}.section.text.subTitle.general`]: "Setup general role config",

    [`${settingPrefix}.${roleSettingPrefix}.table.text.title`]: "Permission",
    [`${settingPrefix}.${roleSettingPrefix}.table.text.subTitle`]: "Setup role permission",

    [`${settingPrefix}.${roleSettingPrefix}.table.displayName.0`]: "Request Model",
    [`${settingPrefix}.${roleSettingPrefix}.table.displayName.1`]: "Read",
    [`${settingPrefix}.${roleSettingPrefix}.table.displayName.2`]: "Edit",
    [`${settingPrefix}.${roleSettingPrefix}.table.displayName.3`]: "Delete",
    [`${settingPrefix}.${roleSettingPrefix}.table.displayName.4`]: "Tab",

    [`${settingPrefix}.${roleSettingPrefix}.table.displayName.5`]: "All",
}

const channelAccount = {
    [`${settingPrefix}.${channelAccountPrefix}.page.text.title`]: "Channel account settings",

    [`${settingPrefix}.${channelAccountPrefix}.tab.title.general`]: "General",
    [`${settingPrefix}.${channelAccountPrefix}.tab.title.advanced`]: "Advanced",

    [`${settingPrefix}.${channelAccountPrefix}.text.region`]: "Region",
    [`${settingPrefix}.${channelAccountPrefix}.nameList.region.all`]: "All Regions",

    [`${settingPrefix}.${channelAccountPrefix}.table.text.title`]: "Advanced channel accounts",
    [`${settingPrefix}.${channelAccountPrefix}.table.text.subTitle`]: "Add multiple channel accounts for different businesses, languages and channels etc.",
    [`${settingPrefix}.${channelAccountPrefix}.table.placeholder.search`]: "Search account",
    [`${settingPrefix}.${channelAccountPrefix}.button.addAccounts`]: "Add accounts",
    
    [`${settingPrefix}.${channelAccountPrefix}.modal.warning.disable`]: "Confirm to disable this channels? No chats can be received after that.",
    [`${settingPrefix}.${channelAccountPrefix}.modal.warning.remove`]: "Confirm to remove this channel?",

    [`${settingPrefix}.${channelAccountPrefix}.table.displayName.0`]: "Channel ID",
    [`${settingPrefix}.${channelAccountPrefix}.table.displayName.1`]: "Channel",
    [`${settingPrefix}.${channelAccountPrefix}.table.displayName.2`]: "Account name",
    [`${settingPrefix}.${channelAccountPrefix}.table.displayName.3`]: "Region",
    [`${settingPrefix}.${channelAccountPrefix}.table.displayName.4`]: "Language",
    [`${settingPrefix}.${channelAccountPrefix}.table.displayName.5`]: "Active",

    [`${settingPrefix}.${channelAccountPrefix}.list.text.title`]: "Channel setup",
    [`${settingPrefix}.${channelAccountPrefix}.list.text.subTitle`]: "Set up basic chat channel account here. For multiple accounts, please go to “Advanced”",
    [`${settingPrefix}.${channelAccountPrefix}.button.addChannel`]: "Add channel",
}

const editChannelAccount = {
    [`${settingPrefix}.${editChannelAccountPrefix}.text.title.edit`]: "EDIT CHANNEL ACCOUNT",
    [`${settingPrefix}.${editChannelAccountPrefix}.text.title.add`]: "NEW CHANNEL ACCOUNT",

    [`${settingPrefix}.${editChannelAccountPrefix}.section.text.title.setup`]: "Channel setup",
    [`${settingPrefix}.${editChannelAccountPrefix}.section.text.subTitle.setup`]: "Set up a new channel for chat interaction",
    
    [`${settingPrefix}.${editChannelAccountPrefix}.section.text.title.mapping`]: "Channel mapping",
    [`${settingPrefix}.${editChannelAccountPrefix}.section.text.subTitle.mapping`]: "Map the channel to the business",
    
    [`${settingPrefix}.${editChannelAccountPrefix}.section.text.title.profile`]: "Chat profile",
    [`${settingPrefix}.${editChannelAccountPrefix}.section.text.subTitle.profile`]: "Information will be seen by customers. Logo must be in SVG or PNG format, in maximum 100px x 100px",
    
    [`${settingPrefix}.${editChannelAccountPrefix}.section.text.title.status`]: "Account status",
    [`${settingPrefix}.${editChannelAccountPrefix}.section.text.subTitle.status`]: "Enable or disable channel account",

    [`${settingPrefix}.${editChannelAccountPrefix}.field.setup.id`]: "ID (Auto-generated)",
    [`${settingPrefix}.${editChannelAccountPrefix}.field.setup.channelId`]: "Channel ID",
    [`${settingPrefix}.${editChannelAccountPrefix}.field.setup.omniChannel`]: "Channel",
    [`${settingPrefix}.${editChannelAccountPrefix}.field.setup.displayName`]: "Account name",
    [`${settingPrefix}.${editChannelAccountPrefix}.field.setup.url`]: "URL",
    [`${settingPrefix}.${editChannelAccountPrefix}.field.setup.quickAccessUrl`]: "Quick access URL",
    
    [`${settingPrefix}.${editChannelAccountPrefix}.field.mapping.timezone`]: "Time zone",
    [`${settingPrefix}.${editChannelAccountPrefix}.field.mapping.defaultLanguage`]: "Language",

    [`${settingPrefix}.${editChannelAccountPrefix}.field.profile.chatbotFlowName`]: "Chatbot name",
    [`${settingPrefix}.${editChannelAccountPrefix}.field.profile.aIChatbotId`]: "OpenAI Bot",
    
    [`${settingPrefix}.${editChannelAccountPrefix}.button.addChannel`]: "Add channel",
    [`${settingPrefix}.${editChannelAccountPrefix}.button.editAccount`]: "Edit account",
    [`${settingPrefix}.${editChannelAccountPrefix}.button.removeAccount`]: "Remove account",
}

const chatGroup = {
    [`${settingPrefix}.${chatGroupPrefix}.table.text.title`]: "Chat group settings",
    [`${settingPrefix}.${chatGroupPrefix}.table.text.subTitle`]: "Create chat groups for chat routing",
    [`${settingPrefix}.${chatGroupPrefix}.table.placeholder.search`]: "Search group(s)",

    [`${settingPrefix}.${chatGroupPrefix}.modal.warning`]: "Confirm to remove this chat group?",

    [`${settingPrefix}.${chatGroupPrefix}.table.displayName.0`]: "Group label",
    [`${settingPrefix}.${chatGroupPrefix}.table.displayName.1`]: "Group name",
    [`${settingPrefix}.${chatGroupPrefix}.table.displayName.2`]: "# of users",

    [`${settingPrefix}.${chatGroupPrefix}.button.addGroups`]: "Add groups",

    [`${settingPrefix}.${chatGroupPrefix}.text.title.edit`]: "EDIT CHAT GROUP",
    [`${settingPrefix}.${chatGroupPrefix}.text.title.add`]: "CREATE CHAT GROUP",

    [`${settingPrefix}.${chatGroupPrefix}.field.group.groupName`]: "Group name",
    [`${settingPrefix}.${chatGroupPrefix}.field.group.groupLabel`]: "Group label",
    [`${settingPrefix}.${chatGroupPrefix}.field.group.method`]: "Routing Method",

    [`${settingPrefix}.${chatGroupPrefix}.groupUser.text.title.edit`]: "EDIT CHAT GROUP",
    [`${settingPrefix}.${chatGroupPrefix}.groupUser.text.title.add`]: "CREATE CHAT GROUP",

    [`${settingPrefix}.${chatGroupPrefix}.groupUser.table.placeholder.search`]: "Search users",
    
    [`${settingPrefix}.${chatGroupPrefix}.groupUser.table.displayName.0`]: "Display name",
    [`${settingPrefix}.${chatGroupPrefix}.groupUser.table.displayName.1`]: "Username",
    [`${settingPrefix}.${chatGroupPrefix}.groupUser.table.displayName.2`]: "Email",
    [`${settingPrefix}.${chatGroupPrefix}.groupUser.table.displayName.3`]: "Role",

    [`${settingPrefix}.${chatGroupPrefix}.groupUser.table.footer.total`]: "Total",
    [`${settingPrefix}.${chatGroupPrefix}.groupUser.table.footer.selected`]: "recipients selected",

}

const chatRouting = {
    [`${settingPrefix}.${chatRoutingPrefix}.table.text.title`]: "Chat routing",
    [`${settingPrefix}.${chatRoutingPrefix}.table.text.subTitle`]: "Allocate chats to different chat groups and channel routeSettings with conditions",
    [`${settingPrefix}.${chatRoutingPrefix}.table.placeholder.search`]: "Search routing",

    [`${settingPrefix}.${chatRoutingPrefix}.text.type`]: "Type of routing",

    [`${settingPrefix}.${chatRoutingPrefix}.nameList.type.default`]: "Default",
    [`${settingPrefix}.${chatRoutingPrefix}.nameList.type.condition`]: "Condition",

    [`${settingPrefix}.${chatRoutingPrefix}.modal.warning.disable`]: "Confirm to disable this routing?",
    [`${settingPrefix}.${chatRoutingPrefix}.modal.warning.remove`]: "Confirm to remove this routing?",

    [`${settingPrefix}.${chatRoutingPrefix}.table.displayName.0`]: "Priority",
    [`${settingPrefix}.${chatRoutingPrefix}.table.displayName.1`]: "Routing name",
    [`${settingPrefix}.${chatRoutingPrefix}.table.displayName.2`]: "Type",
    [`${settingPrefix}.${chatRoutingPrefix}.table.displayName.3`]: "Assigned chat group",
    [`${settingPrefix}.${chatRoutingPrefix}.table.displayName.4`]: "Active",

    [`${settingPrefix}.${chatRoutingPrefix}.button.addRouting`]: "Add routing",
}

const editChatRouting = {
    [`${settingPrefix}.${editChatRoutingPrefix}.text.title.edit`]: "EDIT CHAT ROUTING",
    [`${settingPrefix}.${editChatRoutingPrefix}.text.title.add`]: "ADD CHAT ROUTING",

    [`${settingPrefix}.${editChatRoutingPrefix}.section.text.title.setup`]: "Routing setup",
    [`${settingPrefix}.${editChatRoutingPrefix}.section.text.subTitle.setup`]: "Allocate chats to different chat groups and channel accounts with conditions",
    
    [`${settingPrefix}.${editChatRoutingPrefix}.section.text.title.condition`]: "Routing condition(s)",
    [`${settingPrefix}.${editChatRoutingPrefix}.section.text.subTitle.condition`]: "Set up conditions for routing",
    
    [`${settingPrefix}.${editChatRoutingPrefix}.section.text.title.allocation`]: "Chat group allocation",
    [`${settingPrefix}.${editChatRoutingPrefix}.section.text.subTitle.allocation`]: "Select chat group for chat allocation",
    
    [`${settingPrefix}.${editChatRoutingPrefix}.section.text.title.users`]: "Chat group users",
    [`${settingPrefix}.${editChatRoutingPrefix}.section.table.placeholder.search`]: "Search users",

    [`${settingPrefix}.${editChatRoutingPrefix}.progress.0`]: "Routing setup",
    [`${settingPrefix}.${editChatRoutingPrefix}.progress.1`]: "Chat group allocation",

    [`${settingPrefix}.${editChatRoutingPrefix}.field.setup.name`]: "Routing name",
    [`${settingPrefix}.${editChatRoutingPrefix}.field.setup.type`]: "Type of routing",
    [`${settingPrefix}.${editChatRoutingPrefix}.field.setup.priority`]: "Priority",
    
    [`${settingPrefix}.${editChatRoutingPrefix}.field.condition.fieldType`]: "Field type",
    [`${settingPrefix}.${editChatRoutingPrefix}.field.condition.fieldName`]: "Field name",
    [`${settingPrefix}.${editChatRoutingPrefix}.field.condition.condition`]: "Condition",
    [`${settingPrefix}.${editChatRoutingPrefix}.field.condition.value`]: "Value",

    [`${settingPrefix}.${editChatRoutingPrefix}.field.allocation.groupId`]: "Chat group",

    [`${settingPrefix}.${editChatRoutingPrefix}.error.condition.fieldName.invalidOption`]: "Field Removed",

    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.fieldType.conversationStandardField`]: "Conversation",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.fieldType.prechatFormField`]: "Prechat form",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.fieldType.contact`]: "Contact",
    
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.fieldName.name`]: "Name",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.fieldName.language`]: "Language",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.fieldName.omniChannel`]: "Omni channel",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.fieldName.omniExternal id`]: "Omni external Id",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.fieldName.omniChannel id`]: "Omni channel Id",

    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.equalsTo`]: "Equals to",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.notEqualsTo`]: "Not equals to",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.greaterThan`]: "Greater than",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.greaterThanOrEquals`]: "Greater than or equals",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.lessThan`]: "Less than",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.lessThanOrEqualsTo`]: "Less than or equals to",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.startWith`]: "Start with",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.endWith`]: "End with",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.empty`]: "Empty",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.notEmpty`]: "Not empty",
    
    [`${settingPrefix}.${editChatRoutingPrefix}.button.addCondition`]: "Add condition(s)",
    [`${settingPrefix}.${editChatRoutingPrefix}.button.addRouting`]: "Add routing",
    [`${settingPrefix}.${editChatRoutingPrefix}.button.editRouting`]: "Edit routing",
}

const message = {
    [`${settingPrefix}.${messagePrefix}.page.text.title`]: "Message settings",

    [`${settingPrefix}.${messagePrefix}.tab.title.quickText`]: "Quick Text",
    [`${settingPrefix}.${messagePrefix}.tab.title.template`]: "Template",
    [`${settingPrefix}.${messagePrefix}.tab.title.systemMessage`]: "System Message",
}

const quickText = {
    [`${settingPrefix}.${messagePrefix}.${quickTextPrefix}.table.text.title`]: "Quick text settings",
    [`${settingPrefix}.${messagePrefix}.${quickTextPrefix}.table.text.subTitle`]: "Set up quick text in public folder for chat interaction",
    [`${settingPrefix}.${messagePrefix}.${quickTextPrefix}.table.placeholder.search`]: "Search quickText",
    [`${settingPrefix}.${messagePrefix}.${quickTextPrefix}.modal.warning`]: "Confirm to remove this quick text?",

    [`${settingPrefix}.${messagePrefix}.${quickTextPrefix}.table.displayName.0`]: "Label",
    [`${settingPrefix}.${messagePrefix}.${quickTextPrefix}.table.displayName.1`]: "Language",
    [`${settingPrefix}.${messagePrefix}.${quickTextPrefix}.table.displayName.2`]: "Owner",

    [`${settingPrefix}.${messagePrefix}.${quickTextPrefix}.button.addQuickText`]: "Add quick texts",
}

const editQuickText = {
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.text.title.edit`]: "EDIT QUICK TEXT",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.text.title.add`]: "ADD QUICK TEXT",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.section.text.title.setup`]: "Quick text settings",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.section.text.subTitle.setup`]: "Set up quick text for agents to use during chat interaction",
    
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.section.text.title.list`]: "Quick text list",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.section.text.subTitle.list`]: "Set up Quick text",
    
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.section.text.title.subCategory`]: "Sub category quick text list",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.section.text.subTitle.subCategory`]: "Set up Sub Category Quick text",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.expanable.text.quickText`]: "Quick Text ",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.expanable.text.subCategory`]: "Sub Category ",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.progress.0`]: "Quick text settings",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.progress.1`]: "Quick text list",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.progress.2`]: "Sub category quick text list",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.field.setup.label`]: "Quick text title",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.field.setup.language`]: "Language",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.field.setup.userId`]: "Assigned to",
    
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.field.item.title`]: "Title",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.field.item.text`]: "Text",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.field.subCategory.label`]: "Label",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.button.addItem`]: "Add item(s)",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.button.addCategory`]: "Add category(s)",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.button.addQuickText`]: "Add quick text",
}

const template = {
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.table.text.title`]: "Template settings",
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.table.text.subTitle`]: "Set up templates in public folder for chat initiation or broadcasting.",
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.table.placeholder.search`]: "Search template",
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.modal.warning`]: "Confirm to remove this template?",
    
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.table.displayName.0`]: "Name",
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.table.displayName.1`]: "Omni Channel",
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.table.displayName.2`]: "Omni Channel Id",
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.table.displayName.3`]: "Template Id",
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.table.displayName.4`]: "Language",

    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.button.addTemplate`]: "Add templates",
}

const editTemplate = {
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.text.title.edit`]: "EDIT TEMPLATE",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.text.title.add`]: "ADD TEMPLATE",

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.section.text.title.setup`]: "Template settings",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.section.text.subTitle.setup`]: "Set up templates for chat initiation or broadcasting",

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.section.text.title.content`]: "Template content",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.section.text.subTitle.content`]: "Draft content for the template",
    
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.section.text.title.list`]: "Template data config",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.section.text.subTitle.list`]: "Set up Template Data Config",
    
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.expanable.text.template`]: "Template ",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.expanable.text.subCategory`]: "Sub Category ",

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.progress.0`]: "Template settings",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.progress.1`]: "Template content",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.progress.2`]: "Template data config",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.progress.last`]: "Preview and Confirm",

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.setup.name`]: "Template name",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.setup.omniChannelId`]: "Omni channel Id",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.setup.templateId`]: "Template Id",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.setup.language`]: "Language",

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.content.templateFormat`]: "Template message",
    
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.item.componentType`]: "Component Type",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.item.messageType`]: "Message Type",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.item.dataFormat`]: "Data Format",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.item.imagePath`]: "Image Url",

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.nameList.item.componentType.header`]: "Header",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.nameList.item.componentType.body`]: "Body",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.nameList.item.componentType.footer`]: "Footer",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.nameList.item.messageType.text`]: "Text",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.nameList.item.messageType.image`]: "Image",

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.notice.empty`]: `No data key need to be config, please skip current step`,

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.error.empty`]: `Data key in between "{{" and "}}" cannot be empty`,
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.error.regex`]: `Data key can only contain alphanumeric, space or underscore`,
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.error.same`]: `Data key in between "{{" and "}}" cannot be the same as config key`,
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.error.duplicates`]: `Please remove duplicate data key in between "{{" and "}}"`,

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.button.addTemplate`]: "Add template",
}

const systemMessage = {
    [`${settingPrefix}.${messagePrefix}.${systemMessagePrefix}.table.text.title`]: "System message settings",
    [`${settingPrefix}.${messagePrefix}.${systemMessagePrefix}.table.text.subTitle`]: "Create sets of system messages for chat routing",
    [`${settingPrefix}.${messagePrefix}.${systemMessagePrefix}.table.placeholder.search`]: "Search set",
    [`${settingPrefix}.${messagePrefix}.${systemMessagePrefix}.modal.warning`]: "Confirm to remove this system message?",

    [`${settingPrefix}.${messagePrefix}.${systemMessagePrefix}.table.displayName.0`]: "Language",

    [`${settingPrefix}.${messagePrefix}.${systemMessagePrefix}.button.addSets`]: "Add sets",
}

const editSystemMessage = {
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.text.title.edit`]: "EDIT SYSTEM MESSAGE SET",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.text.title.add`]: "ADD SYSTEM MESSAGE SET",

    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.section.text.title.setup`]: "System message settings",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.section.text.subTitle.setup`]: "Set up a new set of system message for chat routing",
    
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.section.text.title.content`]: "System message content",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.section.text.subTitle.content`]: "Draft content for different scenarios",

    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.setup.language`]: "Language",
    
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.blockedConversationMessage`]: "Blocked Conversation Message",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.closeConversationByAgentMessage`]: "Close Conversation By Agent Message",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.closeConversationBySystemMessage`]: "Close Conversation By System Message",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.closeConversationByUserMessage`]: "Close Conversation By User Message",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.conversationAssignedMessage`]: "Conversation Assigned Message",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.conversationNoResponseMessage`]: "Conversation No Response Message",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.fileSizeOverLimitMessage`]: "File Size Over Limit Message",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.holidayAlert`]: "Holiday Alert",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.liveChatClientDisconnected`]: "Live Chat Client Disconnected",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.liveChatClientReconnected`]: "Live Chat Client Reconnected",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.messageTooLongMessage`]: "Message Too Long Message",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.noActiveAgentMessage`]: "No Active Agent Message",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.openLiveChatMessage`]: "Open Live Chat Message",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.specialDate`]: "Special Date",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.subscriptionGreetingMessage`]: "Subscription Greeting Message",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.templateMessageBlockedByUser`]: "Template Message Blocked By User",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.templateMessageFailed`]: "Template Message Failed",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.templateMessageSuccessSend`]: "Template Message Success Send",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.unsupportMediaTypeMessage`]: "Unsupport Media Type Message",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.userSessionTimeout`]: "User Session Timeout",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.workingDateAlert`]: "Working Date Alert",

    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.expanable.text.systemLabels`]: "System labels",

    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.button.addSystemMessage`]: "Add system message",
}

const tagging = {
    [`${settingPrefix}.${taggingPrefix}.page.text.title`]: "Tagging settings",
    [`${settingPrefix}.${taggingPrefix}.page.text.subTitle`]: "Tag",

    [`${settingPrefix}.${taggingPrefix}.text.addTags`]: "Add tags here.",
    [`${settingPrefix}.${taggingPrefix}.button.addTags`]: "Add tags",
}

const wrapUp = {
    [`${settingPrefix}.${wrapUpPrefix}.page.text.title`]: "Wrap-up settings",

    [`${settingPrefix}.${wrapUpPrefix}.tab.title.wrapUp`]: "Wrap-up",
    [`${settingPrefix}.${wrapUpPrefix}.tab.title.autoEmail`]: "Auto-email",

    [`${settingPrefix}.${wrapUpPrefix}.table.text.title`]: "Auto-email settings",
    [`${settingPrefix}.${wrapUpPrefix}.table.text.subTitle`]: "Set up auto-email template for post-chat interaction",
    [`${settingPrefix}.${wrapUpPrefix}.button.addTemplates`]: "Add templates",
    
    [`${settingPrefix}.${wrapUpPrefix}.modal.warning`]: "Confirm to remove this template?",

    [`${settingPrefix}.${wrapUpPrefix}.table.displayName.0`]: "Display name",
    [`${settingPrefix}.${wrapUpPrefix}.table.displayName.1`]: "Subject",

    [`${settingPrefix}.${wrapUpPrefix}.list.text.title`]: "Wrap-up settings",
    [`${settingPrefix}.${wrapUpPrefix}.list.text.subTitle`]: "Create and manage chat objectives for post-chat analysis and reporting",

    [`${settingPrefix}.${wrapUpPrefix}.text.title.edit`]: "EDIT EMAIL TEMPLATE",
    [`${settingPrefix}.${wrapUpPrefix}.text.title.add`]: "ADD EMAIL TEMPLATE",

    [`${settingPrefix}.${wrapUpPrefix}.section.text.title.setup`]: "Email template settings",
    [`${settingPrefix}.${wrapUpPrefix}.section.text.subTitle.setup`]: "Set up auto-email template for post-chat interaction",

    [`${settingPrefix}.${wrapUpPrefix}.field.setup.name`]: "Display name",
    [`${settingPrefix}.${wrapUpPrefix}.field.setup.subject`]: "Subject",
    [`${settingPrefix}.${wrapUpPrefix}.field.setup.content`]: "Email content",

    [`${settingPrefix}.${wrapUpPrefix}.button.addtemplate`]: "Add template"
}

const chatAutomation = {
    [`${settingPrefix}.${chatAutomationPrefix}.table.text.title`]: "Chat automation",
    [`${settingPrefix}.${chatAutomationPrefix}.table.text.subTitle`]: "Apply automation here. For chat assignment, go to Chat Routing.",
    [`${settingPrefix}.${chatAutomationPrefix}.table.placeholder.search`]: "Search rules",

    [`${settingPrefix}.${chatAutomationPrefix}.modal.warning.disable`]: "Do you confirm to disable this automation?",
    [`${settingPrefix}.${chatAutomationPrefix}.modal.warning.remove`]: "Do you confirm to remove this automation?",

    [`${settingPrefix}.${chatAutomationPrefix}.table.displayName.0`]: "Rule name",
    [`${settingPrefix}.${chatAutomationPrefix}.table.displayName.1`]: "Table name",
    [`${settingPrefix}.${chatAutomationPrefix}.table.displayName.2`]: "Conditions",
    [`${settingPrefix}.${chatAutomationPrefix}.table.displayName.3`]: "Actions",
    [`${settingPrefix}.${chatAutomationPrefix}.table.displayName.4`]: "Active",

    [`${settingPrefix}.${chatAutomationPrefix}.button.addRules`]: "Add rules",
}

const editChatAutomation = {
    [`${settingPrefix}.${editChatAutomationPrefix}.text.and`]: "And",

    [`${settingPrefix}.${editChatAutomationPrefix}.text.title.edit`]: "EDIT AUTOMATION",
    [`${settingPrefix}.${editChatAutomationPrefix}.text.title.add`]: "NEW AUTOMATION",
    
    [`${settingPrefix}.${editChatAutomationPrefix}.input.placeholder`]: "Enter Rule Name",
    [`${settingPrefix}.${editChatAutomationPrefix}.text.defaultName`]: "New Automation",
    
    [`${settingPrefix}.${editChatAutomationPrefix}.button.activate`]: "Activate",

    [`${settingPrefix}.${editChatAutomationPrefix}.field.attribute.tableName`]: "Table Name",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.attribute.executeType`]: "Execute Type",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.attribute.executeActionTypeSet`]: "Execute Action Type Set",

    [`${settingPrefix}.${editChatAutomationPrefix}.field.criteria.fieldConfigId`]: "Field Config",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.criteria.conditionOperator`]: "Condition Operator",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.criteria.value1`]: "Value 1",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.criteria.value2`]: "Value 2",

    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.base.type`]: "Type",

    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.update.retrigger`]: "Retrigger",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.update.fieldConfigId`]: "Field Config",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.update.value`]: "Value",

    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.error.message`]: "Error Message",

    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.sendEmail.emailTemplateId`]: "Email Template Id",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.sendEmail.emailFieldType`]: "Email Field Type",

    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.sendEmail.userId`]: "User Id",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.sendEmail.userGroupId`]: "User Group Id",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.sendEmail.fieldConfigIdList`]: "Field Config List",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.sendEmail.toEmailList`]: "Emails (Separate by ,)",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.start.title`]: "Automation Starts",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.end.title`]: "Automation Ends",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.addFlow.title`]: "Add flow",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.addCriteria.title`]: "Add condition(s)",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.addAction.title`]: "Add action(s) (AND only)",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.criteriaTop.title`]: "Conditions",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.actionTop.title`]: "Actions",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.attribute.title`]: "Flow",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.criteria.edit.title`]: "Condition (IF)",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.action.edit.title`]: "Action (THEN)",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.executeType.realtime`]: "Realtime",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.executeType.schedule`]: "Schedule",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.equalsTo`]: "Equals To",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.notEqualsTo`]: "Not equals to",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.greaterThan`]: "Greater than",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.greaterThanOrEquals`]: "Greater than or equals",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.lessThan`]: "less than",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.lessThanOrEqualsTo`]: "Less than or equals to",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.startWith`]: "Start with",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.endWith`]: "End with",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.isNull`]: "Is null",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.notNull`]: "Not null",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.between`]: "between",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.contains`]: "contains",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.within`]: "within",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.actionType.title`]: "ACTION TYPE",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.actionType.fieldUpdate`]: "Field Update",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.actionType.throwError`]: "Throw Error",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.actionType.sendEmail`]: "Send Email",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.action.emailFieldTypeuserId`]: "User Id",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.action.emailFieldTypeuserGroupId`]: "User Group Id", 
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.action.emailFieldTypetoOwner`]: "To Owner", 
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.action.emailFieldTypefieldConfigIdList`]: "Field Config Id List", 
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.action.emailFieldTypetoEmailList`]: "To Email List", 
}

const chatbot = {
    [`${settingPrefix}.${chatbotPrefix}.page.text.title`]: "Chatbot settings",

    [`${settingPrefix}.${chatbotPrefix}.tab.title.chatbotAutomation`]: 'Chatbot automation',
    [`${settingPrefix}.${chatbotPrefix}.tab.title.nodes`]: 'Nodes',
    [`${settingPrefix}.${chatbotPrefix}.tab.title.variables`]: 'Variables',
    [`${settingPrefix}.${chatbotPrefix}.tab.title.labelling`]: 'Labelling',

    [`${settingPrefix}.${chatbotPrefix}.table.text.title`]: "Chatbot flows",
    [`${settingPrefix}.${chatbotPrefix}.table.text.subTitle`]: "Set up automated conversations initiated by chatbot.",
    [`${settingPrefix}.${chatbotPrefix}.table.placeholder.search`]: "Search flow",

    [`${settingPrefix}.${chatbotPrefix}.modal.warning.remove`]: "Confirm to remove this flow? It cannot be recovered.",

    [`${settingPrefix}.${chatbotPrefix}.table.displayName.0`]: "Flow name",
    [`${settingPrefix}.${chatbotPrefix}.table.displayName.1`]: "Type",
    [`${settingPrefix}.${chatbotPrefix}.table.displayName.2`]: "Version",
    [`${settingPrefix}.${chatbotPrefix}.table.displayName.3`]: "Updated on",

    [`${settingPrefix}.${chatbotPrefix}.button.createFlows`]: "Create flows",

    [`${settingPrefix}.${chatbotPrefix}.nameList.flowType.draft`]: "Draft",
    [`${settingPrefix}.${chatbotPrefix}.nameList.flowType.released`]: "Released",

}

const createOrCloneChatbot = {
    [`${settingPrefix}.${createOrCloneChatbotPrefix}.modal.page.create.text.title`]: "CREATE CHATBOT FLOW",
    [`${settingPrefix}.${createOrCloneChatbotPrefix}.field.create.name`]: "Flow name",

    [`${settingPrefix}.${createOrCloneChatbotPrefix}.modal.page.clone.text.title`]: "CLONE CHATBOT FLOW",
    [`${settingPrefix}.${createOrCloneChatbotPrefix}.field.clone.name`]: "From Flow",
    [`${settingPrefix}.${createOrCloneChatbotPrefix}.field.clone.version`]: "Version",
    [`${settingPrefix}.${createOrCloneChatbotPrefix}.field.clone.newFlowName`]: "Flow name",

    [`${settingPrefix}.${createOrCloneChatbotPrefix}.button.clone`]: "Clone",
}

const chatbotIconBar = {
    [`${settingPrefix}.${chatbotIconBarPrefix}.list`]: "List",
    [`${settingPrefix}.${chatbotIconBarPrefix}.keywords`]: "Keywords",
    [`${settingPrefix}.${chatbotIconBarPrefix}.parameter`]: "Parameter",
    [`${settingPrefix}.${chatbotIconBarPrefix}.entity`]: "Entity",
    [`${settingPrefix}.${chatbotIconBarPrefix}.response`]: "Response",
    [`${settingPrefix}.${chatbotIconBarPrefix}.action`]: "Action",
    
    [`${settingPrefix}.${chatbotIconBarPrefix}.edit.keywords`]: "Edit Keywords",
    [`${settingPrefix}.${chatbotIconBarPrefix}.edit.parameter`]: "Edit Parameter",
    [`${settingPrefix}.${chatbotIconBarPrefix}.edit.entity`]: "Edit Entity",
    [`${settingPrefix}.${chatbotIconBarPrefix}.edit.response`]: "Edit Response",
    [`${settingPrefix}.${chatbotIconBarPrefix}.edit.action`]: "Edit Action",
}

const editChatbot = {
    [`${settingPrefix}.${editChatbotPrefix}.page.extra.time`]: "Last updated on - ",
    [`${settingPrefix}.${editChatbotPrefix}.page.placeholder.search`]: "Search node",

    [`${settingPrefix}.${editChatbotPrefix}.modal.title.import`]: "IMPORT",

    [`${settingPrefix}.${editChatbotPrefix}.modal.warning.publish`]: "Confirm to publish flow?",
    [`${settingPrefix}.${editChatbotPrefix}.modal.warning.remove`]: "Confirm to remove this ",
    [`${settingPrefix}.${editChatbotPrefix}.modal.warning.exitEdit`]: "Confirm to cancel edit?",
    [`${settingPrefix}.${editChatbotPrefix}.modal.warning.exitCreate`]: "Confirm to cancel creation?",

    [`${settingPrefix}.${editChatbotPrefix}.text.search`]: "Search ",
    [`${settingPrefix}.${editChatbotPrefix}.text.new`]: "New ",

    [`${settingPrefix}.${editChatbotPrefix}.text.node`]: "Node",

    [`${settingPrefix}.${editChatbotPrefix}.nameList.setting.keyword`]: "Keyword",
    [`${settingPrefix}.${editChatbotPrefix}.nameList.setting.entity`]: "Entity",
    [`${settingPrefix}.${editChatbotPrefix}.nameList.setting.response`]: "Response",
    [`${settingPrefix}.${editChatbotPrefix}.nameList.setting.action`]: "Action",
    [`${settingPrefix}.${editChatbotPrefix}.nameList.setting.parameter`]: "Parameter",

    [`${settingPrefix}.${editChatbotPrefix}.button.import`]: "Import",
    [`${settingPrefix}.${editChatbotPrefix}.button.export`]: "Export",
    [`${settingPrefix}.${editChatbotPrefix}.button.preview`]: "Preview",
    [`${settingPrefix}.${editChatbotPrefix}.button.publish`]: "Publish",

    [`${settingPrefix}.${editChatbotPrefix}.flow.start.title`]: "Chat starts",
    [`${settingPrefix}.${editChatbotPrefix}.flow.add.title`]: "Add node(s)",
    [`${settingPrefix}.${editChatbotPrefix}.flow.first.title`]: "First node",

    [`${settingPrefix}.${editChatbotPrefix}.flow.preview.title`]: "Flow Preview",
    [`${settingPrefix}.${editChatbotPrefix}.flow.preview.loading`]: "Chatbot processing",
}

const editNode = {
    [`${settingPrefix}.${editNodePrefix}.text.title.edit`]: "EDIT NODE",
    [`${settingPrefix}.${editNodePrefix}.text.title.add`]: "CREATE NODE",

    [`${settingPrefix}.${editNodePrefix}.section.text.title.setup`]: "Node setup",
    [`${settingPrefix}.${editNodePrefix}.section.text.subTitle.setup`]: "Create a new node for chatbot automation",

    [`${settingPrefix}.${editNodePrefix}.section.text.title.conditions`]: "Chat conditions",
    [`${settingPrefix}.${editNodePrefix}.section.text.subTitle.conditions`]: "Set up conditions for incoming customer conversations",

    [`${settingPrefix}.${editNodePrefix}.section.text.title.variable`]: "Variables",
    [`${settingPrefix}.${editNodePrefix}.section.text.subTitle.variable`]: "Set up Global Variables",

    [`${settingPrefix}.${editNodePrefix}.section.text.title.response`]: "Response",
    [`${settingPrefix}.${editNodePrefix}.section.text.subTitle.response`]: "Set up response",

    [`${settingPrefix}.${editNodePrefix}.section.text.title.action`]: "Actions",
    [`${settingPrefix}.${editNodePrefix}.section.text.subTitle.action`]: "Set up actions when conditions are fulfilled.",

    [`${settingPrefix}.${editNodePrefix}.field.setup.name`]: "Node name",
    [`${settingPrefix}.${editNodePrefix}.field.setup.priority`]: "Priority",
    [`${settingPrefix}.${editNodePrefix}.field.setup.language`]: "Node language",
    [`${settingPrefix}.${editNodePrefix}.field.setup.isFirstNode`]: "Must be the first node in conversation",

    [`${settingPrefix}.${editNodePrefix}.field.conditions.conditionType`]: "Condition Type",

    [`${settingPrefix}.${editNodePrefix}.field.variable.option`]: "Option",
    [`${settingPrefix}.${editNodePrefix}.field.externalAction.externalActionId`]: "Action",
    
    [`${settingPrefix}.${editNodePrefix}.field.extra.type`]: "Type",
    [`${settingPrefix}.${editNodePrefix}.field.extra.value`]: "Value",
    [`${settingPrefix}.${editNodePrefix}.field.extra.parameterName`]: "Name",

    [`${settingPrefix}.${editNodePrefix}.field.response.responseId`]: "Response Message",
    [`${settingPrefix}.${editNodePrefix}.field.response.responseType`]: "Response Method",

    [`${settingPrefix}.${editNodePrefix}.field.action.afterAction`]: "Action",
    [`${settingPrefix}.${editNodePrefix}.field.action.jumpNodeId`]: "Jump to Node",

    [`${settingPrefix}.${editNodePrefix}.nameList.language.default`]: "Node language",
    
    [`${settingPrefix}.${editNodePrefix}.nameList.conditionType.others`]: "Others",
    [`${settingPrefix}.${editNodePrefix}.nameList.conditionType.anyMessage`]: "Any text message",
    [`${settingPrefix}.${editNodePrefix}.nameList.conditionType.anyMedia`]: "Any media",
        
    [`${settingPrefix}.${editNodePrefix}.nameList.responseType.allMessage`]: "Return All Message",
    [`${settingPrefix}.${editNodePrefix}.nameList.responseType.randomDisplay`]: "Display Message Randomly",
    
    [`${settingPrefix}.${editNodePrefix}.nameList.action.doNothing`]: "Nothing To Do",
    [`${settingPrefix}.${editNodePrefix}.nameList.action.liveChat`]: "Request Live Chat",
    [`${settingPrefix}.${editNodePrefix}.nameList.action.jumpNode`]: "Jump to Node",
    [`${settingPrefix}.${editNodePrefix}.nameList.action.runAction`]: "External Action",

    [`${settingPrefix}.${editNodePrefix}.expanable.text.condition`]: "Condition",
    [`${settingPrefix}.${editNodePrefix}.expanable.text.variable`]: "Variable",
    [`${settingPrefix}.${editNodePrefix}.expanable.text.action`]: "Action",

    [`${settingPrefix}.${editNodePrefix}.text.response.preview`]: "Response Message(s)",

    [`${settingPrefix}.${editNodePrefix}.button.addCondition`]: "Add condition(s)",
    [`${settingPrefix}.${editNodePrefix}.button.addVariable`]: "Add variable(s)",
    [`${settingPrefix}.${editNodePrefix}.button.addAction`]: "Add external action(s)",

    [`${settingPrefix}.${editNodePrefix}.button.createNode`]: "Create node",
    [`${settingPrefix}.${editNodePrefix}.button.updateNode`]: "Update node",
    [`${settingPrefix}.${editNodePrefix}.button.editNode`]: "Edit node",
    [`${settingPrefix}.${editNodePrefix}.button.removeNode`]: "Remove node",
}

const editKeyword = {
    [`${settingPrefix}.${editKeywordPrefix}.text.title.edit`]: "EDIT KEYWORD",
    [`${settingPrefix}.${editKeywordPrefix}.text.title.add`]: "NEW KEYWORD",

    [`${settingPrefix}.${editKeywordPrefix}.section.text.title.setup`]: "Keyword setup",
    [`${settingPrefix}.${editKeywordPrefix}.section.text.subTitle.setup`]: "Create a new Keyword for chatbot automation",

    [`${settingPrefix}.${editKeywordPrefix}.section.text.title.list`]: "Keyword list",
    [`${settingPrefix}.${editKeywordPrefix}.section.text.subTitle.list`]: "Set up Keyword list",

    [`${settingPrefix}.${editKeywordPrefix}.field.setup.name`]: "Keyword name",
    [`${settingPrefix}.${editKeywordPrefix}.field.setup.list`]: "Value",

    [`${settingPrefix}.${editKeywordPrefix}.expanable.text.List`]: "Keyword List",

    [`${settingPrefix}.${editKeywordPrefix}.button.addkeyword`]: "Add keyword",

    [`${settingPrefix}.${editKeywordPrefix}.button.createKeyword`]: "Create keyword",
    [`${settingPrefix}.${editKeywordPrefix}.button.updateKeyword`]: "Update node",
    [`${settingPrefix}.${editKeywordPrefix}.button.cloneKeyword`]: "Clone keyword",
    [`${settingPrefix}.${editKeywordPrefix}.button.editKeyword`]: "Edit keyword",
}

const editEntity = {
    [`${settingPrefix}.${editEntityPrefix}.text.title.edit`]: "EDIT ENTITY",
    [`${settingPrefix}.${editEntityPrefix}.text.title.add`]: "NEW ENTITY",

    [`${settingPrefix}.${editEntityPrefix}.section.text.title.setup`]: "Entity setup",
    [`${settingPrefix}.${editEntityPrefix}.section.text.subTitle.setup`]: "Create a new Entity for chatbot automation",

    [`${settingPrefix}.${editEntityPrefix}.section.text.title.option`]: "Entity option",
    [`${settingPrefix}.${editEntityPrefix}.section.text.subTitle.option`]: "Set up Entity option",

    [`${settingPrefix}.${editEntityPrefix}.section.text.title.regex`]: "Entity regex",
    [`${settingPrefix}.${editEntityPrefix}.section.text.subTitle.regex`]: "Set up Entity regex",

    [`${settingPrefix}.${editEntityPrefix}.section.text.title.test`]: "Entity regex test",
    [`${settingPrefix}.${editEntityPrefix}.section.text.subTitle.test`]: "Test Entity regex",

    [`${settingPrefix}.${editEntityPrefix}.field.setup.name`]: "Entity name",
    [`${settingPrefix}.${editEntityPrefix}.field.setup.entityType`]: "Entity Type",

    [`${settingPrefix}.${editEntityPrefix}.field.regex.name`]: "Regex name",
    [`${settingPrefix}.${editEntityPrefix}.field.regex.value`]: "Regex value",
    
    [`${settingPrefix}.${editEntityPrefix}.field.option.name`]: "Option name",
    [`${settingPrefix}.${editEntityPrefix}.field.option.value`]: "Option",

    [`${settingPrefix}.${editEntityPrefix}.table.placeholder.test`]: "Test Input",

    [`${settingPrefix}.${editEntityPrefix}.table.displayName.0`]: "Name",
    [`${settingPrefix}.${editEntityPrefix}.table.displayName.1`]: "Regex",
    [`${settingPrefix}.${editEntityPrefix}.table.displayName.2`]: "Result",

    [`${settingPrefix}.${editEntityPrefix}.nameList.entityType.regex`]: "Match Regular Expression",
    [`${settingPrefix}.${editEntityPrefix}.nameList.entityType.option`]: "Match Option List",

    [`${settingPrefix}.${editEntityPrefix}.expanable.text.regexlist`]: "Entity Regex",
    [`${settingPrefix}.${editEntityPrefix}.expanable.text.regex`]: "Regex ",
    [`${settingPrefix}.${editEntityPrefix}.expanable.text.optionlist`]: "Entity Option",
    [`${settingPrefix}.${editEntityPrefix}.expanable.text.option`]: "Option ",

    [`${settingPrefix}.${editEntityPrefix}.button.addRegex`]: "Add regex",
    [`${settingPrefix}.${editEntityPrefix}.button.addOptionList`]: "Add option list(s)",
    [`${settingPrefix}.${editEntityPrefix}.button.addOption`]: "Add option(s)",

    [`${settingPrefix}.${editEntityPrefix}.button.createEntity`]: "Create entity",
    [`${settingPrefix}.${editEntityPrefix}.button.updateEntity`]: "Update entity",
    [`${settingPrefix}.${editEntityPrefix}.button.editEntity`]: "Edit entity",
    [`${settingPrefix}.${editEntityPrefix}.button.cloneEntity`]: "Clone entity",

    [`${settingPrefix}.${editEntityPrefix}.duplicates.regex`]: "Regex name already exists",
    [`${settingPrefix}.${editEntityPrefix}.duplicates.option`]: "Option already exists",
}

const editResponse = {
    [`${settingPrefix}.${editResponsePrefix}.text.title.edit`]: "EDIT RESPONSE",
    [`${settingPrefix}.${editResponsePrefix}.text.title.add`]: "NEW RESPONSE",

    [`${settingPrefix}.${editResponsePrefix}.section.text.title.setup`]: "Response setup",
    [`${settingPrefix}.${editResponsePrefix}.section.text.subTitle.setup`]: "Create a new Response for chatbot automation",

    [`${settingPrefix}.${editResponsePrefix}.section.text.title.message`]: "Response message",
    [`${settingPrefix}.${editResponsePrefix}.section.text.subTitle.message`]: "Set up Response message",

    [`${settingPrefix}.${editResponsePrefix}.section.text.title.option`]: "Response option",
    [`${settingPrefix}.${editResponsePrefix}.section.text.subTitle.option`]: "Set up Response option",

    [`${settingPrefix}.${editResponsePrefix}.field.setup.name`]: "Response name",
    [`${settingPrefix}.${editResponsePrefix}.field.setup.attachmentId`]: "Attachment",

    [`${settingPrefix}.${editResponsePrefix}.field.message.value`]: "Response message",

    [`${settingPrefix}.${editResponsePrefix}.field.option.name`]: "Option name",
    [`${settingPrefix}.${editResponsePrefix}.field.option.value`]: "Option value",

    [`${settingPrefix}.${editResponsePrefix}.expanable.text.message`]: "Response Message",
    [`${settingPrefix}.${editResponsePrefix}.expanable.text.optionList`]: "Response Option",
    [`${settingPrefix}.${editResponsePrefix}.expanable.text.option`]: "Option ",

    [`${settingPrefix}.${editResponsePrefix}.button.addMessage`]: "Add message(s)",
    [`${settingPrefix}.${editResponsePrefix}.button.addOption`]: "Add option(s)",

    [`${settingPrefix}.${editResponsePrefix}.button.createResponse`]: "Create response",
    [`${settingPrefix}.${editResponsePrefix}.button.updateResponse`]: "Update response",
    [`${settingPrefix}.${editResponsePrefix}.button.editResponse`]: "Edit response",
    [`${settingPrefix}.${editResponsePrefix}.button.cloneResponse`]: "Clone response",
}

const editAction = {
    [`${settingPrefix}.${editActionPrefix}.text.title.edit`]: "EDIT ACTION",
    [`${settingPrefix}.${editActionPrefix}.text.title.add`]: "NEW ACTION",

    [`${settingPrefix}.${editActionPrefix}.section.text.title.setup`]: "Action setup",
    [`${settingPrefix}.${editActionPrefix}.section.text.subTitle.setup`]: "Create a new Action for chatbot automation",

    [`${settingPrefix}.${editActionPrefix}.section.text.title.conditions.get`]: "Get conditions",
    [`${settingPrefix}.${editActionPrefix}.section.text.subTitle.conditions.get`]: "Set up Get conditions",

    [`${settingPrefix}.${editActionPrefix}.section.text.title.conditions.insert`]: "Insert conditions",
    [`${settingPrefix}.${editActionPrefix}.section.text.subTitle.conditions.insert`]: "Set up Insert conditions",

    [`${settingPrefix}.${editActionPrefix}.section.text.title.conditions.update`]: "Update conditions",
    [`${settingPrefix}.${editActionPrefix}.section.text.subTitle.conditions.update`]: "Set up Update conditions",

    [`${settingPrefix}.${editActionPrefix}.section.text.title.conditions.options`]: "Options conditions",
    [`${settingPrefix}.${editActionPrefix}.section.text.subTitle.conditions.options`]: "Set up Options conditions",

    [`${settingPrefix}.${editActionPrefix}.section.text.title.conditions.identifyContact`]: "Identify Contact conditions",
    [`${settingPrefix}.${editActionPrefix}.section.text.subTitle.conditions.identifyContact`]: "Set up Identify Contact conditions",

    [`${settingPrefix}.${editActionPrefix}.section.text.title.response`]: "Action response",
    [`${settingPrefix}.${editActionPrefix}.section.text.subTitle.response`]: "Set up Action response",

    [`${settingPrefix}.${editActionPrefix}.field.setup.name`]: "Action name",
    [`${settingPrefix}.${editActionPrefix}.field.setup.action`]: "Action Type",
    [`${settingPrefix}.${editActionPrefix}.field.setup.targetTable`]: "Target Table",

    [`${settingPrefix}.${editActionPrefix}.field.conditions.queryString`]: "Query String",
    [`${settingPrefix}.${editActionPrefix}.field.conditions.recordCount`]: "Record Count",
    [`${settingPrefix}.${editActionPrefix}.field.conditions.resultAsOptions`]: "Result As Options",
    [`${settingPrefix}.${editActionPrefix}.field.conditions.optionLabelField`]: "Option Label",
    [`${settingPrefix}.${editActionPrefix}.field.conditions.optionValueField`]: "Option Value",
    [`${settingPrefix}.${editActionPrefix}.field.conditions.mediaParameterName`]: "Media Parameter Name",
    [`${settingPrefix}.${editActionPrefix}.field.conditions.optionTableField`]: "Option Target Table",

    [`${settingPrefix}.${editActionPrefix}.field.conditions.fieldList.name`]: "Return Field Name",
    [`${settingPrefix}.${editActionPrefix}.field.conditions.inputField.name`]: "Input Field name",
    [`${settingPrefix}.${editActionPrefix}.field.conditions.inputField.value`]: "Input Field value",

    [`${settingPrefix}.${editActionPrefix}.field.response.responseId`]: "Response",
    [`${settingPrefix}.${editActionPrefix}.field.response.failedResponseId`]: "Failed Response",
    [`${settingPrefix}.${editActionPrefix}.field.response.additional.condition`]: "Response",
    [`${settingPrefix}.${editActionPrefix}.field.response.additional.value`]: "Failed Response",

    [`${settingPrefix}.${editActionPrefix}.nameList.action.get`]: "Get",
    [`${settingPrefix}.${editActionPrefix}.nameList.action.insert`]: "Insert",
    [`${settingPrefix}.${editActionPrefix}.nameList.action.update`]: "Update",
    [`${settingPrefix}.${editActionPrefix}.nameList.action.options`]: "Options",
    [`${settingPrefix}.${editActionPrefix}.nameList.action.identifyContact`]: "Identify contact",
    [`${settingPrefix}.${editActionPrefix}.nameList.action.uploadAttachment`]: "Upload attachment",

    [`${settingPrefix}.${editActionPrefix}.nameList.additional.condition.recordNotFound`]: "Record Not Found",
    [`${settingPrefix}.${editActionPrefix}.nameList.additional.condition.insertFailed`]: "Insert Failed",
    [`${settingPrefix}.${editActionPrefix}.nameList.additional.condition.updateFailed`]: "Update Failed",
    [`${settingPrefix}.${editActionPrefix}.nameList.additional.condition.uploadFailed`]: "Upload Failed",

    [`${settingPrefix}.${editActionPrefix}.expanable.text.fieldList`]: "Return Field List",
    [`${settingPrefix}.${editActionPrefix}.expanable.text.inputField`]: "Input Field List",
    [`${settingPrefix}.${editActionPrefix}.expanable.text.additional`]: "Additional Failed Response",

    [`${settingPrefix}.${editActionPrefix}.expanable.text.inputField.defaultValue`]: "Default Value ",
    [`${settingPrefix}.${editActionPrefix}.expanable.text.additional.response`]: "Condition Response ",

    [`${settingPrefix}.${editActionPrefix}.button.addFieldList`]: "Add Return Field(s)",
    [`${settingPrefix}.${editActionPrefix}.button.addInputField`]: "Add Input Field(s)",
    [`${settingPrefix}.${editActionPrefix}.button.addAdditional`]: "Add Failed Response(s)",

    [`${settingPrefix}.${editActionPrefix}.button.createAction`]: "Create action",
    [`${settingPrefix}.${editActionPrefix}.button.updateAction`]: "Update action",
    [`${settingPrefix}.${editActionPrefix}.button.editAction`]: "Edit action",
    [`${settingPrefix}.${editActionPrefix}.button.cloneAction`]: "Clone action",

    [`${settingPrefix}.${editActionPrefix}.duplicates.inputField`]: "Input Field name already exists",
    [`${settingPrefix}.${editActionPrefix}.duplicates.additional`]: "Condition already exists",

}

const editParameter = {
    [`${settingPrefix}.${editParameterPrefix}.text.title.edit`]: "EDIT PARAMETER",
    [`${settingPrefix}.${editParameterPrefix}.text.title.add`]: "NEW PARAMETER",

    [`${settingPrefix}.${editParameterPrefix}.section.text.title.setup`]: "Parameter setup",
    [`${settingPrefix}.${editParameterPrefix}.section.text.subTitle.setup`]: "Create a new Parameter for chatbot automation",

    [`${settingPrefix}.${editParameterPrefix}.field.setup.key`]: "Parameter Key",
    [`${settingPrefix}.${editParameterPrefix}.field.setup.type`]: "Type",
    [`${settingPrefix}.${editParameterPrefix}.field.setup.value`]: "Value",

    [`${settingPrefix}.${editParameterPrefix}.nameList.type.input`]: "Input",
    [`${settingPrefix}.${editParameterPrefix}.nameList.type.keyword`]: "Keyword",
    [`${settingPrefix}.${editParameterPrefix}.nameList.type.entity`]: "Entity",
    [`${settingPrefix}.${editParameterPrefix}.nameList.type.option`]: "Option",
    [`${settingPrefix}.${editParameterPrefix}.nameList.type.media`]: "Media",
    [`${settingPrefix}.${editParameterPrefix}.nameList.type.custom`]: "Custom",

    [`${settingPrefix}.${editParameterPrefix}.button.createParameter`]: "Create parameter",
    [`${settingPrefix}.${editParameterPrefix}.button.updateParameter`]: "Update parameter",
    [`${settingPrefix}.${editParameterPrefix}.button.editParameter`]: "Edit parameter",
    [`${settingPrefix}.${editParameterPrefix}.button.cloneParameter`]: "Clone parameter",
}

const openai = {
    [`${settingPrefix}.${openaiPrefix}.table.text.title`]: "OpenAI bots",
    [`${settingPrefix}.${openaiPrefix}.table.text.subTitle`]: "Set up automated conversations initiated by openAI.",
    [`${settingPrefix}.${openaiPrefix}.table.placeholder.search`]: "Search bot",

    [`${settingPrefix}.${openaiPrefix}.dropdown.lang.search`]: "Search Language",
    [`${settingPrefix}.${openaiPrefix}.dropdown.lang.add`]: "Add Language",

    [`${settingPrefix}.${openaiPrefix}.modal.warning.remove`]: "Confirm to remove this Bot? It cannot be recovered.",
    [`${settingPrefix}.${openaiPrefix}.modal.warning.removeConfig`]: "Confirm to remove this supported language? It cannot be recovered.",

    [`${settingPrefix}.${openaiPrefix}.table.displayName.0`]: "Bot name",
    [`${settingPrefix}.${openaiPrefix}.table.displayName.1`]: "Supported Language",

    [`${settingPrefix}.${openaiPrefix}.button.createBots`]: "Create bots",

    [`${settingPrefix}.${openaiPrefix}.modal.page.text.title`]: "CREATE OPENAI BOT",
    [`${settingPrefix}.${openaiPrefix}.field.create.name`]: "Bot name",

}

const editOpenai = {
    [`${settingPrefix}.${editOpenaiPrefix}.flow.preview.title`]: "Bot Preview",
    [`${settingPrefix}.${editOpenaiPrefix}.flow.preview.loading`]: "OpenAI processing",

    [`${settingPrefix}.${editOpenaiPrefix}.button.preview`]: "Preview bot",

    [`${settingPrefix}.${editOpenaiPrefix}.progress.0`]: "General config",
    [`${settingPrefix}.${editOpenaiPrefix}.progress.1`]: "Personality",
    [`${settingPrefix}.${editOpenaiPrefix}.progress.2`]: "Services",

    [`${settingPrefix}.${editOpenaiPrefix}.modal.warning`]: "Are you sure you want to quit create config?\n Changes will not be saved.",
    [`${settingPrefix}.${editOpenaiPrefix}.modal.warning.remove`]: "Confirm to remove this service?",

    [`${settingPrefix}.${editOpenaiPrefix}.section.text.title.config`]: "General config",
    [`${settingPrefix}.${editOpenaiPrefix}.section.text.subTitle.config`]: "General config for a OpenAI chatbot",

    [`${settingPrefix}.${editOpenaiPrefix}.section.text.title.personalities`]: "Personality",
    [`${settingPrefix}.${editOpenaiPrefix}.section.text.subTitle.personalities`]: "Set up OpenAI chatbot's personalities",

    [`${settingPrefix}.${editOpenaiPrefix}.table.text.title`]: "Services",
    [`${settingPrefix}.${editOpenaiPrefix}.table.text.subTitle`]: "Set up OpenAI chatbot's services",

    [`${settingPrefix}.${editOpenaiPrefix}.table.displayName.0`]: "Service name",
    [`${settingPrefix}.${editOpenaiPrefix}.table.displayName.1`]: "Table name",
    [`${settingPrefix}.${editOpenaiPrefix}.table.displayName.2`]: "Service type",

    [`${settingPrefix}.${editOpenaiPrefix}.section.text.title.service.config`]: "Service general config",
    [`${settingPrefix}.${editOpenaiPrefix}.section.text.subTitle.service.config`]: "General config for a OpenAI Service",

    [`${settingPrefix}.${editOpenaiPrefix}.section.text.title.service.response`]: "Service response config",
    [`${settingPrefix}.${editOpenaiPrefix}.section.text.subTitle.service.response`]: "Response config for a OpenAI Service",

    [`${settingPrefix}.${editOpenaiPrefix}.section.text.title.service.predicate`]: "Predicate config",
    [`${settingPrefix}.${editOpenaiPrefix}.section.text.subTitle.service.predicate`]: "Predicate config for a OpenAI Service",

    [`${settingPrefix}.${editOpenaiPrefix}.section.text.title.service.field`]: "Field config",
    [`${settingPrefix}.${editOpenaiPrefix}.section.text.subTitle.service.field`]: "Field config for a OpenAI Service",

    [`${settingPrefix}.${editOpenaiPrefix}.field.select.useExistingConfig`]: "Use Existing Config",
    [`${settingPrefix}.${editOpenaiPrefix}.field.select.id`]: "Chatbot Config",

    [`${settingPrefix}.${editOpenaiPrefix}.field.config.name`]: "Config name",
    [`${settingPrefix}.${editOpenaiPrefix}.field.config.chatbotName`]: "AI Chatbot name",
    [`${settingPrefix}.${editOpenaiPrefix}.field.config.coreTemplateId`]: "Core Template",
    
    [`${settingPrefix}.${editOpenaiPrefix}.field.personality.personality`]: "Personality",

    [`${settingPrefix}.${editOpenaiPrefix}.text.title.edit`]: "EDIT SERVICE",
    [`${settingPrefix}.${editOpenaiPrefix}.text.title.add`]: "NEW SERVICE",

    [`${settingPrefix}.${editOpenaiPrefix}.field.service.name`]: "Service Name",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.tableName`]: "Table Name",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.query`]: "Extra Query",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.type`]: "Service Type",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.serviceTemplateEntryId`]: "Service Template Entry",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.serviceTemplateId`]: "Service Template",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.successResponse`]: "Success Response",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.failedResponse`]: "Failed Response",

    [`${settingPrefix}.${editOpenaiPrefix}.field.service.item.name`]: "Display Name",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.item.apiName`]: "API Name",

    [`${settingPrefix}.${editOpenaiPrefix}.field.service.predicate.type`]: "Predicate Type",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.predicate.operator`]: "Predicate Operator",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.predicate.defaultValue`]: "Default Value",

    [`${settingPrefix}.${editOpenaiPrefix}.field.service.field.valueType`]: "Field Value Type",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.field.value`]: "Option",

    [`${settingPrefix}.${editOpenaiPrefix}.nameList.personality.polite`]: "Polite",
    [`${settingPrefix}.${editOpenaiPrefix}.nameList.personality.proactive`]: "Proactive",

    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.query`]: "Query Record",
    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.create`]: "Create Record",

    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.predicate.type.text`]: "Text",
    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.predicate.type.number`]: "Number",

    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.predicate.operator.equal`]: "Equal",
    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.predicate.operator.greaterThan`]: "Greater Than",
    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.predicate.operator.greaterThanOrEqualTo`]: "Greater Than or Equal To",
    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.predicate.operator.lessThan`]: "Less Than",
    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.predicate.operator.lessThanOrEqualTo`]: "Less Than or Equal To",

    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.field.type.text`]: "Text",
    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.field.type.picklist`]: "Picklist",

    [`${settingPrefix}.${editOpenaiPrefix}.expanable.text.personality`]: "Personality",
    [`${settingPrefix}.${editOpenaiPrefix}.expanable.text.service.field`]: "Field ",
    [`${settingPrefix}.${editOpenaiPrefix}.expanable.text.service.predicate`]: "Predicate ",

    [`${settingPrefix}.${editOpenaiPrefix}.button.addPersonality`]: "Add Personality(s)",

    [`${settingPrefix}.${editOpenaiPrefix}.button.addPredicates`]: "Add Predicate(s)",
    [`${settingPrefix}.${editOpenaiPrefix}.button.addFields`]: "Add Field(s)",
    [`${settingPrefix}.${editOpenaiPrefix}.button.addOption`]: "Add Option(s)",
    
    [`${settingPrefix}.${editOpenaiPrefix}.button.addService`]: "Add service",
    [`${settingPrefix}.${editOpenaiPrefix}.button.saveService`]: "Save service",
    [`${settingPrefix}.${editOpenaiPrefix}.button.removeService`]: "Remove service",

    [`${settingPrefix}.${editOpenaiPrefix}.button.addServices`]: "Add services",
}

const livechatSetting = {
    [`${settingPrefix}.${livechatSettingPrefix}.page.text.title`]: "Live chat settings",

    [`${settingPrefix}.${livechatSettingPrefix}.modal.warning`]: "Confirm to remove this prechat form?",
    
    [`${settingPrefix}.${livechatSettingPrefix}.modal.title.copy`]: "COPY PRE-CHAT SCRIPT",
    [`${settingPrefix}.${livechatSettingPrefix}.modal.subTitle.copy`]: "Add this script to <body></body>",

    [`${settingPrefix}.${livechatSettingPrefix}.tab.title.prechatForm`]: 'Pre-chat form',

    [`${settingPrefix}.${livechatSettingPrefix}.table.text.title`]: "Pre-chat form setup",
    [`${settingPrefix}.${livechatSettingPrefix}.table.text.subTitle`]: "Manage pre-chat forms for live chatbot",
    [`${settingPrefix}.${livechatSettingPrefix}.table.placeholder.search`]: "Search form",

    [`${settingPrefix}.${livechatSettingPrefix}.table.displayName.0`]: "Form name",
    [`${settingPrefix}.${livechatSettingPrefix}.table.displayName.1`]: "Language",

    [`${settingPrefix}.${livechatSettingPrefix}.text.preview`]: "Form Preview",
    [`${settingPrefix}.${livechatSettingPrefix}.text.preview.tilte`]: "Live Chat",
    [`${settingPrefix}.${livechatSettingPrefix}.text.preview.empty`]: "Please draft template content.",
    [`${settingPrefix}.${livechatSettingPrefix}.text.preview.button`]: "Start chat",

    [`${settingPrefix}.${livechatSettingPrefix}.button.addForms`]: "Add forms",

    [`${settingPrefix}.${livechatSettingPrefix}.modal.page.clone.text.title`]: "CLONE PRE-CHAT FORM",
    [`${settingPrefix}.${livechatSettingPrefix}.field.settings.name`]: "New Form name",
}

const editLivechatSetting = {
    [`${settingPrefix}.${editLivechatSettingPrefix}.text.title.edit`]: "EDIT PRE-CHAT FORM",
    [`${settingPrefix}.${editLivechatSettingPrefix}.text.title.add`]: "ADD PRE-CHAT FORM",

    [`${settingPrefix}.${editLivechatSettingPrefix}.modal.warning`]: "Confirm to remove this field?",

    [`${settingPrefix}.${editLivechatSettingPrefix}.progress.0`]: "Form settings",
    [`${settingPrefix}.${editLivechatSettingPrefix}.progress.1`]: "Form content",
    [`${settingPrefix}.${editLivechatSettingPrefix}.progress.2`]: "Form label setting",
    [`${settingPrefix}.${editLivechatSettingPrefix}.progress.3`]: "Form display channel",

    [`${settingPrefix}.${editLivechatSettingPrefix}.section.text.title.settings`]: "Form settings",
    [`${settingPrefix}.${editLivechatSettingPrefix}.section.text.subTitle.settings`]: "Set up pre-chat form for chat diversion",

    [`${settingPrefix}.${editLivechatSettingPrefix}.section.text.title.content`]: "Form content",
    [`${settingPrefix}.${editLivechatSettingPrefix}.section.text.subTitle.content`]: "Add fields for customer submission",

    [`${settingPrefix}.${editLivechatSettingPrefix}.section.text.title.label`]: "Form label setting",
    [`${settingPrefix}.${editLivechatSettingPrefix}.section.text.subTitle.label`]: "Set up pre-chat form labels",

    [`${settingPrefix}.${editLivechatSettingPrefix}.section.text.title.channel`]: "Form display channel",
    [`${settingPrefix}.${editLivechatSettingPrefix}.section.text.subTitle.channel`]: "Set up pre-chat form display channel",

    [`${settingPrefix}.${editLivechatSettingPrefix}.field.settings.name`]: "Form name",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.settings.language`]: "Language",

    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.apiName`]: "Field API",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.displayName`]: "Display name",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.type`]: "Type of field",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.length`]: "Text length",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.regex`]: "Regex",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.option`]: "Option",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.isName`]: "Is Name field",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.isRequired`]: "Required field",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.errorMsg`]: "Error message",

    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.attachmentNotSupportWarning`]: "Attachment Not Support Warning",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.attachmentOverSizeWarning`]: "Attachment Over Size Warning",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.attachmentPanelTitle`]: "Attachment Panel Title",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.attachmentTooltip`]: "Attachment Tooltip",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.cancelButton`]: "Cancel Button",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.closeConversationTitle`]: "Close Conversation Title",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.confirmButton`]: "Confirm Button ",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.conversationPanelTitle`]: "Conversation Panel Title",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.conversationTitle`]: "Conversation Title",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.emojiTooltip`]: "Emoji Tooltip",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.feedbackCommentTitle`]: "Feedback Comment Title ",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.feedbackPanelTitle`]: "Feedback Panel Title",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.firstMessage`]: "First Message",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.invalidMessageInput`]: "Invalid Message Input",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.liveChatTitle`]: "Live Chat Title",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.messageSendFailedWarning`]: "Message Send Failed Warning",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.prechatRequiredFieldWarning`]: "Prechat Required Field Warning",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.searchKnowledgeBasedTitle`]: "Search Knowledge Based Title",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.serverReconnectingWarning`]: "Server Reconnecting Warning",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.serverUnavailableWarning`]: "Server Unavailable Warning",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.startConversationButton`]: "Start Conversation Button",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.typeMessagePlaceholder`]: "Type Message Placeholder",

    [`${settingPrefix}.${editLivechatSettingPrefix}.field.channel.type`]: "Type of Channel",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.channel.channelAccount`]: "Channel Account",

    [`${settingPrefix}.${editLivechatSettingPrefix}.nameList.content.text`]: "Text",
    [`${settingPrefix}.${editLivechatSettingPrefix}.nameList.content.number`]: "Number",
    [`${settingPrefix}.${editLivechatSettingPrefix}.nameList.content.textarea`]: "Textarea",
    [`${settingPrefix}.${editLivechatSettingPrefix}.nameList.content.select`]: "Drop-down selection",
    [`${settingPrefix}.${editLivechatSettingPrefix}.nameList.content.checkbox`]: "Checkbox",
    [`${settingPrefix}.${editLivechatSettingPrefix}.nameList.content.link`]: "Hyperlink",

    [`${settingPrefix}.${editLivechatSettingPrefix}.expanable.text.content`]: "Form content",
    [`${settingPrefix}.${editLivechatSettingPrefix}.expanable.text.label`]: "Form Labels",
    [`${settingPrefix}.${editLivechatSettingPrefix}.expanable.text.channel`]: "Display Channel",
    
    [`${settingPrefix}.${editLivechatSettingPrefix}.expanable.text.content.item`]: "Field ",
    [`${settingPrefix}.${editLivechatSettingPrefix}.expanable.text.channel.item`]: "Channel ",

    [`${settingPrefix}.${editLivechatSettingPrefix}.button.addField`]: "Add field(s)",
    [`${settingPrefix}.${editLivechatSettingPrefix}.button.addAccount`]: "Add channel account(s)",

    [`${settingPrefix}.${editLivechatSettingPrefix}.button.addOption`]: "Add option(s)",

}

export const setting = {
    ...layout,

    ...business,
    ...businessGeneralSetting,
    ...planSubscription,

    ...userList,

    ...system,
    ...systemGeneralSetting,
    ...fieldConfig,
    ...editfieldConfig,

    ...role,
    ...roleSetting,

    ...channelAccount,
    ...editChannelAccount,

    ...chatGroup,

    ...chatRouting,
    ...editChatRouting,

    ...message,
    ...quickText,
    ...editQuickText,

    ...template,
    ...editTemplate,
    
    ...systemMessage,
    ...editSystemMessage,

    ...tagging,
    ...wrapUp,

    ...chatAutomation,
    ...editChatAutomation,
    
    ...chatbot,
    ...createOrCloneChatbot,
    ...chatbotIconBar,
    ...editChatbot,
    ...editNode,
    ...editKeyword,
    ...editEntity,
    ...editResponse,
    ...editAction,
    ...editParameter,
    
    ...openai,
    ...editOpenai,

    ...livechatSetting,
    ...editLivechatSetting,
}