// import { addLocaleData } from 'react-intl';
import enLang from './entries/en-US';
import zhLang from './entries/zh-Hans-CN';
import TZhLan from './entries/zh-THans-CN';

const AppLocale = {
    en: enLang,
    zh: zhLang,
    tzh: TZhLan,
};

// addLocaleData(AppLocale.en.data)
// addLocaleData(AppLocale.zh.data)
// addLocaleData(AppLocale.tzh.data)

export default AppLocale;
