import PropTypes from 'prop-types';

import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import AppLocale from 'lang';
import { getLanguage } from 'store/reducers/ui/setting';

const LocalizedProvider = ({children = null}) => {
  const { locale } = useSelector(state=>({
      locale: getLanguage(state.ui.setting)
  }))

  const curLocale = AppLocale[locale.locale];

  return (
    <IntlProvider locale={curLocale.locale} messages={curLocale.messages}>
        {children}
    </IntlProvider>
  );
}

LocalizedProvider.propTypes = {
    children: PropTypes.node,
};

export default LocalizedProvider;
