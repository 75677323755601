import { useSelector } from 'react-redux';
import { getLanguage, getLanguages } from 'store/reducers/ui/setting';

export const useLanguage = () => {
    const { language, languages } = useSelector(state => ({
        language: getLanguage(state.ui.setting),
        languages: getLanguages(state.ui.setting)
    }))

    return { language, languages };
}