const Export = (rest) => {
    return(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M6.30775 22.1461C5.80258 22.1461 5.375 21.9711 5.025 21.6211C4.675 21.2711 4.5 20.8435 4.5 20.3384V9.95388C4.5 9.44872 4.675 9.02113 5.025 8.67113C5.375 8.32113 5.80258 8.14613 6.30775 8.14613H8.1155C8.32817 8.14613 8.50633 8.21797 8.65 8.36163C8.7935 8.50513 8.86525 8.6833 8.86525 8.89613C8.86525 9.10897 8.7935 9.28713 8.65 9.43063C8.50633 9.5743 8.32817 9.64613 8.1155 9.64613H6.30775C6.23075 9.64613 6.16025 9.67822 6.09625 9.74238C6.03208 9.80638 6 9.87688 6 9.95388V20.3384C6 20.4154 6.03208 20.4859 6.09625 20.5499C6.16025 20.614 6.23075 20.6461 6.30775 20.6461H17.6923C17.7692 20.6461 17.8398 20.614 17.9038 20.5499C17.9679 20.4859 18 20.4154 18 20.3384V9.95388C18 9.87688 17.9679 9.80638 17.9038 9.74238C17.8398 9.67822 17.7692 9.64613 17.6923 9.64613H15.8845C15.6718 9.64613 15.4937 9.5743 15.35 9.43063C15.2065 9.28713 15.1348 9.10897 15.1348 8.89613C15.1348 8.6833 15.2065 8.50513 15.35 8.36163C15.4937 8.21797 15.6718 8.14613 15.8845 8.14613H17.6923C18.1974 8.14613 18.625 8.32113 18.975 8.67113C19.325 9.02113 19.5 9.44872 19.5 9.95388V20.3384C19.5 20.8435 19.325 21.2711 18.975 21.6211C18.625 21.9711 18.1974 22.1461 17.6923 22.1461H6.30775ZM11.25 4.36538L9.927 5.68838C9.77817 5.83722 9.60408 5.91063 9.40475 5.90863C9.20542 5.9068 9.02817 5.8283 8.873 5.67313C8.72817 5.51797 8.65317 5.3423 8.648 5.14613C8.643 4.94997 8.718 4.77438 8.873 4.61938L11.3672 2.12513C11.5481 1.9443 11.759 1.85388 12 1.85388C12.241 1.85388 12.4519 1.9443 12.6328 2.12513L15.127 4.61938C15.2653 4.75771 15.3362 4.92913 15.3395 5.13363C15.3427 5.33813 15.2718 5.51797 15.127 5.67313C14.9718 5.8283 14.7936 5.90588 14.5922 5.90588C14.3911 5.90588 14.2129 5.8283 14.0577 5.67313L12.75 4.36538V14.6461C12.75 14.859 12.6782 15.0371 12.5345 15.1806C12.391 15.3243 12.2128 15.3961 12 15.3961C11.7872 15.3961 11.609 15.3243 11.4655 15.1806C11.3218 15.0371 11.25 14.859 11.25 14.6461V4.36538Z" fill="currentColor"/>
            <path d="M6.30775 22.1461C5.80258 22.1461 5.375 21.9711 5.025 21.6211C4.675 21.2711 4.5 20.8435 4.5 20.3384V9.95388C4.5 9.44872 4.675 9.02113 5.025 8.67113C5.375 8.32113 5.80258 8.14613 6.30775 8.14613H8.1155C8.32817 8.14613 8.50633 8.21797 8.65 8.36163C8.7935 8.50513 8.86525 8.6833 8.86525 8.89613C8.86525 9.10897 8.7935 9.28713 8.65 9.43063C8.50633 9.5743 8.32817 9.64613 8.1155 9.64613H6.30775C6.23075 9.64613 6.16025 9.67822 6.09625 9.74238C6.03208 9.80638 6 9.87688 6 9.95388V20.3384C6 20.4154 6.03208 20.4859 6.09625 20.5499C6.16025 20.614 6.23075 20.6461 6.30775 20.6461H17.6923C17.7692 20.6461 17.8398 20.614 17.9038 20.5499C17.9679 20.4859 18 20.4154 18 20.3384V9.95388C18 9.87688 17.9679 9.80638 17.9038 9.74238C17.8398 9.67822 17.7692 9.64613 17.6923 9.64613H15.8845C15.6718 9.64613 15.4937 9.5743 15.35 9.43063C15.2065 9.28713 15.1348 9.10897 15.1348 8.89613C15.1348 8.6833 15.2065 8.50513 15.35 8.36163C15.4937 8.21797 15.6718 8.14613 15.8845 8.14613H17.6923C18.1974 8.14613 18.625 8.32113 18.975 8.67113C19.325 9.02113 19.5 9.44872 19.5 9.95388V20.3384C19.5 20.8435 19.325 21.2711 18.975 21.6211C18.625 21.9711 18.1974 22.1461 17.6923 22.1461H6.30775ZM11.25 4.36538L9.927 5.68838C9.77817 5.83722 9.60408 5.91063 9.40475 5.90863C9.20542 5.9068 9.02817 5.8283 8.873 5.67313C8.72817 5.51797 8.65317 5.3423 8.648 5.14613C8.643 4.94997 8.718 4.77438 8.873 4.61938L11.3672 2.12513C11.5481 1.9443 11.759 1.85388 12 1.85388C12.241 1.85388 12.4519 1.9443 12.6328 2.12513L15.127 4.61938C15.2653 4.75771 15.3362 4.92913 15.3395 5.13363C15.3427 5.33813 15.2718 5.51797 15.127 5.67313C14.9718 5.8283 14.7936 5.90588 14.5922 5.90588C14.3911 5.90588 14.2129 5.8283 14.0577 5.67313L12.75 4.36538V14.6461C12.75 14.859 12.6782 15.0371 12.5345 15.1806C12.391 15.3243 12.2128 15.3961 12 15.3961C11.7872 15.3961 11.609 15.3243 11.4655 15.1806C11.3218 15.0371 11.25 14.859 11.25 14.6461V4.36538Z" fill="currentColor" fillOpacity="0.2"/>
            <path d="M6.30775 22.1461C5.80258 22.1461 5.375 21.9711 5.025 21.6211C4.675 21.2711 4.5 20.8435 4.5 20.3384V9.95388C4.5 9.44872 4.675 9.02113 5.025 8.67113C5.375 8.32113 5.80258 8.14613 6.30775 8.14613H8.1155C8.32817 8.14613 8.50633 8.21797 8.65 8.36163C8.7935 8.50513 8.86525 8.6833 8.86525 8.89613C8.86525 9.10897 8.7935 9.28713 8.65 9.43063C8.50633 9.5743 8.32817 9.64613 8.1155 9.64613H6.30775C6.23075 9.64613 6.16025 9.67822 6.09625 9.74238C6.03208 9.80638 6 9.87688 6 9.95388V20.3384C6 20.4154 6.03208 20.4859 6.09625 20.5499C6.16025 20.614 6.23075 20.6461 6.30775 20.6461H17.6923C17.7692 20.6461 17.8398 20.614 17.9038 20.5499C17.9679 20.4859 18 20.4154 18 20.3384V9.95388C18 9.87688 17.9679 9.80638 17.9038 9.74238C17.8398 9.67822 17.7692 9.64613 17.6923 9.64613H15.8845C15.6718 9.64613 15.4937 9.5743 15.35 9.43063C15.2065 9.28713 15.1348 9.10897 15.1348 8.89613C15.1348 8.6833 15.2065 8.50513 15.35 8.36163C15.4937 8.21797 15.6718 8.14613 15.8845 8.14613H17.6923C18.1974 8.14613 18.625 8.32113 18.975 8.67113C19.325 9.02113 19.5 9.44872 19.5 9.95388V20.3384C19.5 20.8435 19.325 21.2711 18.975 21.6211C18.625 21.9711 18.1974 22.1461 17.6923 22.1461H6.30775ZM11.25 4.36538L9.927 5.68838C9.77817 5.83722 9.60408 5.91063 9.40475 5.90863C9.20542 5.9068 9.02817 5.8283 8.873 5.67313C8.72817 5.51797 8.65317 5.3423 8.648 5.14613C8.643 4.94997 8.718 4.77438 8.873 4.61938L11.3672 2.12513C11.5481 1.9443 11.759 1.85388 12 1.85388C12.241 1.85388 12.4519 1.9443 12.6328 2.12513L15.127 4.61938C15.2653 4.75771 15.3362 4.92913 15.3395 5.13363C15.3427 5.33813 15.2718 5.51797 15.127 5.67313C14.9718 5.8283 14.7936 5.90588 14.5922 5.90588C14.3911 5.90588 14.2129 5.8283 14.0577 5.67313L12.75 4.36538V14.6461C12.75 14.859 12.6782 15.0371 12.5345 15.1806C12.391 15.3243 12.2128 15.3961 12 15.3961C11.7872 15.3961 11.609 15.3243 11.4655 15.1806C11.3218 15.0371 11.25 14.859 11.25 14.6461V4.36538Z" fill="currentColor" fillOpacity="0.2"/>
            <path d="M6.30775 22.1461C5.80258 22.1461 5.375 21.9711 5.025 21.6211C4.675 21.2711 4.5 20.8435 4.5 20.3384V9.95388C4.5 9.44872 4.675 9.02113 5.025 8.67113C5.375 8.32113 5.80258 8.14613 6.30775 8.14613H8.1155C8.32817 8.14613 8.50633 8.21797 8.65 8.36163C8.7935 8.50513 8.86525 8.6833 8.86525 8.89613C8.86525 9.10897 8.7935 9.28713 8.65 9.43063C8.50633 9.5743 8.32817 9.64613 8.1155 9.64613H6.30775C6.23075 9.64613 6.16025 9.67822 6.09625 9.74238C6.03208 9.80638 6 9.87688 6 9.95388V20.3384C6 20.4154 6.03208 20.4859 6.09625 20.5499C6.16025 20.614 6.23075 20.6461 6.30775 20.6461H17.6923C17.7692 20.6461 17.8398 20.614 17.9038 20.5499C17.9679 20.4859 18 20.4154 18 20.3384V9.95388C18 9.87688 17.9679 9.80638 17.9038 9.74238C17.8398 9.67822 17.7692 9.64613 17.6923 9.64613H15.8845C15.6718 9.64613 15.4937 9.5743 15.35 9.43063C15.2065 9.28713 15.1348 9.10897 15.1348 8.89613C15.1348 8.6833 15.2065 8.50513 15.35 8.36163C15.4937 8.21797 15.6718 8.14613 15.8845 8.14613H17.6923C18.1974 8.14613 18.625 8.32113 18.975 8.67113C19.325 9.02113 19.5 9.44872 19.5 9.95388V20.3384C19.5 20.8435 19.325 21.2711 18.975 21.6211C18.625 21.9711 18.1974 22.1461 17.6923 22.1461H6.30775ZM11.25 4.36538L9.927 5.68838C9.77817 5.83722 9.60408 5.91063 9.40475 5.90863C9.20542 5.9068 9.02817 5.8283 8.873 5.67313C8.72817 5.51797 8.65317 5.3423 8.648 5.14613C8.643 4.94997 8.718 4.77438 8.873 4.61938L11.3672 2.12513C11.5481 1.9443 11.759 1.85388 12 1.85388C12.241 1.85388 12.4519 1.9443 12.6328 2.12513L15.127 4.61938C15.2653 4.75771 15.3362 4.92913 15.3395 5.13363C15.3427 5.33813 15.2718 5.51797 15.127 5.67313C14.9718 5.8283 14.7936 5.90588 14.5922 5.90588C14.3911 5.90588 14.2129 5.8283 14.0577 5.67313L12.75 4.36538V14.6461C12.75 14.859 12.6782 15.0371 12.5345 15.1806C12.391 15.3243 12.2128 15.3961 12 15.3961C11.7872 15.3961 11.609 15.3243 11.4655 15.1806C11.3218 15.0371 11.25 14.859 11.25 14.6461V4.36538Z" fill="currentColor" fillOpacity="0.2"/>
        </svg>
    )
}

export default Export;