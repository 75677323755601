import PropTypes from "prop-types";
import React, { useState } from 'react';
import CusEditable, { CONTAINER_STYLE, EDITABLE_TYPE } from ".";

const CusEditableLeftLabel = React.forwardRef(({
	className = "",
	fieldType = EDITABLE_TYPE.TEXT,
	label = "",
	showLabelOnLeft = true,
	labelSize = 72,
	invalidValueLabel = "",
	value = "",
	pattern = "",
	options = [],
	readOnly = false,
	disabled = false,
	framed = true,
	onChange = null,
	errors = null,
	errorName = null,
	...rest
}, ref) => (
	<div className="w-full flex justify-between">
		{showLabelOnLeft && label &&
			<div title={label} className={`h-[40px] flex items-center truncate overflow-hidden`} style={label ? { "width" : `${labelSize}px` } : {}}>
				<span className="pt-1 text-[13px] text-[#94A3B8] truncate">{label}</span>
			</div>
		}
		<div className={(showLabelOnLeft && label) ? "px-2" : ""} style={(showLabelOnLeft && label) ? { "width" : `calc(100% - ${labelSize}px)` } : {"width" : "100%"}}>
			<CusEditable
				label={label}
				className={className}
				fieldType={fieldType}
				invalidValueLabel={invalidValueLabel}
				value={value}
				pattern={pattern}
				options={options}
				readOnly={readOnly}
				disabled={disabled}
				framed={framed}
				onChange={onChange}
				errors={errors}
				errorName={errorName}
				containerStyle={CONTAINER_STYLE.UNDERLINE}
				{...rest}
			/>
		</div>
	</div>
));

CusEditableLeftLabel.propTypes = {
	className: PropTypes.string,
	fieldType: PropTypes.string,
	label: PropTypes.string,
	showLabelOnLeft: PropTypes.bool,
	labelSize: PropTypes.number,
	invalidValueLabel: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.arrayOf(PropTypes.string)]),
	pattern: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.object),
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	framed: PropTypes.bool,
	onChange: PropTypes.func,
	errors: PropTypes.object,
	errorName: PropTypes.string,
}

export default CusEditableLeftLabel;