import { editNodePrefix, editTemplatePrefix, rolePrefix, roleSettingPrefix, templatePrefix } from "../prefix"
import { editChatbotPrefix } from "../prefix"
import { editKeywordPrefix } from "../prefix"
import { editLivechatSettingPrefix } from "../prefix"
import { editEntityPrefix } from "../prefix"
import { editResponsePrefix } from "../prefix"
import { editActionPrefix } from "../prefix"
import { editParameterPrefix } from "../prefix"
import { businessGeneralSettingPrefix, businessPrefix, channelAccountPrefix, chatAutomationPrefix, chatbotPrefix, chatbotIconBarPrefix, chatGroupPrefix, chatRoutingPrefix, editChannelAccountPrefix, editChatAutomationPrefix, createOrCloneChatbotPrefix, editChatRoutingPrefix, editFieldConfigPrefix, editOpenaiPrefix, editQuickTextPrefix, editSystemMessagePrefix, fieldConfigPrefix, livechatSettingPrefix, messagePrefix, openaiPrefix, planSubscriptionPrefix, quickTextPrefix, settingPrefix, systemGeneralSettingPrefix, systemMessagePrefix, systemPrefix, taggingPrefix, userListPrefix, wrapUpPrefix } from "../prefix"

const layout = {
    [`${settingPrefix}.general`]: "通用设置",
    [`${settingPrefix}.general.business`]: "商业",
    [`${settingPrefix}.general.user`]: "用户",
    [`${settingPrefix}.general.systemFeature`]: "系统功能",
    [`${settingPrefix}.general.role`]: "角色",

    [`${settingPrefix}.chat`]: "聊天设置",
    [`${settingPrefix}.chat.channelAccount`]: "频道账号",
    [`${settingPrefix}.chat.chatGroup`]: "聊天组",
    [`${settingPrefix}.chat.chatRouting`]: "聊天路由",

    [`${settingPrefix}.conversations`]: "对话",
    [`${settingPrefix}.conversations.message`]: "消息",
    [`${settingPrefix}.conversations.tagging`]: "标记",
    [`${settingPrefix}.conversations.wrapUp`]: "总结",

    [`${settingPrefix}.automation`]: "自动化",
    [`${settingPrefix}.automation.chatAutomation`]: "聊天自动化",
    [`${settingPrefix}.automation.chatbot`]: "聊天机器人设置",
    [`${settingPrefix}.automation.openaiSetting`]: "OpenAI 设置",

    [`${settingPrefix}.widget`]: "小部件",
    [`${settingPrefix}.widget.liveChatbot`]: "实时聊天设置",

}

const business = {
    [`${settingPrefix}.${businessPrefix}.page.text.title`]: "业务设置",

    [`${settingPrefix}.${businessPrefix}.tab.title.general`]: "一般",
    [`${settingPrefix}.${businessPrefix}.tab.title.planSubscription`]: "计划订阅",
}

const businessGeneralSetting = {
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.title.profile`]: "业务简介",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.subTitle.profile`]: "告诉我们您的业务",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.title.logo`]: "企业标志",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.subTitle.logo`]: "此徽标将仅应用于系统。它应为 PNG 或 SVG 格式，最大分辨率为 120px x 35px。",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.title.address`]: "公司地址",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.subTitle.address`]: "默认为系统关键业务地址",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.title.timeAndLang`]: "时区和语言",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.subTitle.timeAndLang`]: "默认为系统的关键时区和语言",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.title.regularHours`]: "正常营业时间和日期",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.subTitle.regularHours`]: "默认为聊天交互的正常营业时间和日期",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.profile.name`]: "商家名称",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.profile.email`]: "电子邮件",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.address.address`]: "地址",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.address.zipCode`]: "邮政编码",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.address.country`]: "地区/国家",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.extra.timezone`]: "时区",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.extra.defaultLanguage`]: "语言",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.regularHours.name`]: "姓名",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.regularHours.day`]: "天",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.regularHours.timeFrom`]: "时间从",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.regularHours.timeTo`]: "时间到",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.nameList.type.mondays`]: "星期一",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.nameList.type.tuesdays`]: "星期二",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.nameList.type.wednesdays`]: "星期三",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.nameList.type.thursdays`]: "星期四",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.nameList.type.fridays`]: "星期五",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.nameList.type.saturdays`]: "星期六",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.nameList.type.sundays`]: "星期天",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.button.addDay`]: "添加日期",
}

const planSubscription = {
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.table.text.title`]: "计划订阅",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.button.contact`]: "现在联系",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.button.modify`]: "修改计划",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.button.subscribe`]: "订阅计划",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.modal.warning`]: "联系 Recur 升级或取消计划订阅",

    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.table.displayName.0`]: "计划",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.table.displayName.1`]: "订阅日期",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.table.displayName.2`]: "到期日",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.table.displayName.3`]: "许可证总数",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.table.displayName.4`]: "状况",

    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.card.text.current`]: "当前计划",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.card.text.total`]: "许可证总数",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.card.text.quota`]: "许可证",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.card.text.used`]: "已用许可证",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.card.text.unused`]: "未使用的许可证",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.card.text.expiry`]: "到期日（即将到来）",

    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.nameList.status.expired`]: "已过期",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.nameList.status.canceled`]: "已取消",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.nameList.status.pending`]: "待定",

    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.text.title`]: "选择您的许可证",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.text.subTitle`]: "为您的业务选择正确的解决方案",

    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.card.text.priceTag`]: "每月",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.card.text.subscribe`]: "订阅",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.card.text.back`]: "返回",

    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.field.priceTag`]: "价格标签",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.field.quantity`]: "数量", 
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.field.option.monthly`]: "每月",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.field.option.yearly`]: "每年",       
}

const userList = {
    [`${settingPrefix}.${userListPrefix}.table.text.title`]: "用户设置",
    [`${settingPrefix}.${userListPrefix}.table.text.subTitle`]: "在此处编辑用户的角色和个人资料",
    [`${settingPrefix}.${userListPrefix}.table.placeholder.search`]: "搜索用户",

    [`${settingPrefix}.${userListPrefix}.modal.warning.disable`]: "你确认禁用这个用户吗？",
    [`${settingPrefix}.${userListPrefix}.modal.warning.remove`]: "你确认删除这个用户吗？",

    [`${settingPrefix}.${userListPrefix}.table.displayName.0`]: "显示名称",
    [`${settingPrefix}.${userListPrefix}.table.displayName.1`]: "用户名",
    [`${settingPrefix}.${userListPrefix}.table.displayName.2`]: "电子邮件",
    [`${settingPrefix}.${userListPrefix}.table.displayName.3`]: "角色",
    [`${settingPrefix}.${userListPrefix}.table.displayName.4`]: "活动",

    [`${settingPrefix}.${userListPrefix}.button.addUsers`]: "添加用户",
}

const system = {
    [`${settingPrefix}.${systemPrefix}.page.text.title`]: "系统功能设置",

    [`${settingPrefix}.${systemPrefix}.tab.title.general`]: "一般",
    [`${settingPrefix}.${systemPrefix}.tab.title.fieldConfig`]: "字段配置",
}

const systemGeneralSetting = {
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.section.text.title.scheduler`]: "调度器配置",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.section.text.subTitle.scheduler`]: "设置调度程序配置",

    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.section.text.title.iframe`]: "iframe 域配置",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.section.text.subTitle.iframe`]: "设置 Iframe 域配置",

    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.section.text.title.crossDomain`]: "跨域配置",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.section.text.subTitle.crossDomain`]: "设置跨域配置",

    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.section.text.title.integration`]: "系统集成",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.section.text.subTitle.integration`]: "设置系统集成配置",

    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.button.addDomain`]: "添加域",

    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.scheduler.autoOffline`]: "自动离线",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.scheduler.chatbotConversationAutoClose`]: "聊天机器人对话自动关闭",

    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.enableExternalSystem.isEnableExternalSystem`]: "启用外部系统",

    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.externalSystem.externalSystemType`]: "外部系统类型",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.externalSystem.endpoint`]: "服务器端点",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.externalSystem.clientId`]: "客户编号",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.externalSystem.secretKey`]: "密钥",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.externalSystem.username`]: "用户名",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.externalSystem.password`]: "密码",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.externalSystem.createConversation`]: "创建对话",
    
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.domain.domain`]: "域",

}

const fieldConfig = {
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.text.title`]: "字段配置",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.placeholder.search`]: "搜索字段",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.modal.warning`]: "确认禁用该字段？",

    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.displayName.0`]: "字段名",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.displayName.1`]: "表名",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.displayName.2`]: "显示名称",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.displayName.3`]: "字段类型",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.displayName.4`]: "必填",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.displayName.5`]: "可编辑",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.displayName.6`]: "可创建",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.displayName.7`]: "状态",

    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.button.addConfig`]: "添加配置",
}

const editfieldConfig = {
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.text.title.edit`]: "编辑字段配置",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.text.title.add`]: "新字段配置",

    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.title.type`]: "字段类型配置设置",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.subTitle.type`]: "设置字段类型配置",

    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.title.field`]: "字段配置设置",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.subTitle.field`]: "设置字段配置",

    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.title.option`]: "字段額外選項設置",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.subTitle.option`]: "設置字段額外選項",

    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.title.extra`]: "字段额外配置设置",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.subTitle.extra`]: "设置特定于类型的字段配置",

    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.title.status`]: "字段状态",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.subTitle.status`]: "启用或禁用字段配置",

    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.progress.0`]: "字段类型设置",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.progress.1`]: "字段配置设置",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.progress.2`]: "字段配置可选设置",
    
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.fieldName`]: "字段名称",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.tableName`]: "表名",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.displayNameEn`]: "显示名称（英文）",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.displayNameTc`]: "显示名称(繁体中文)",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.displayNameSc`]: "显示名称（简体中文）",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.displayOrder`]: "显示顺序",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.listViewOrder`]: "列表视图顺序",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.required`]: "必填字段",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.editable`]: "可编辑字段",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.searchable`]: "可搜索字段",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.creatable`]: "可创建字段",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.traceable`]: "可追溯字段",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.displayInListview`]: "在列表视图中显示",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.fieldType`]: "字段类型",

    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.extra.length`]: "长度",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.extra.validationRegex`]: "验证正则表达式",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.extra.decimalPlace`]: "小数位",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.extra.format`]: "流水号格式",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.extra.picklist`]: "选择列表选项",

}

const role = {
    [`${settingPrefix}.${rolePrefix}.table.text.title`]: "角色设置",
    [`${settingPrefix}.${rolePrefix}.table.text.subTitle`]: "设置角色权限",
   
    [`${settingPrefix}.${rolePrefix}.table.placeholder.search`]: "搜索角色",
    [`${settingPrefix}.${rolePrefix}.text.type`]: "类型",

    [`${settingPrefix}.${rolePrefix}.table.displayName.0`]: "名称",
    [`${settingPrefix}.${rolePrefix}.table.displayName.1`]: "显示名称",
    [`${settingPrefix}.${rolePrefix}.table.displayName.2`]: "类型",

    [`${settingPrefix}.${rolePrefix}.nameList.type.system`]: "系统",
    [`${settingPrefix}.${rolePrefix}.nameList.type.custom`]: "自定义",
}

const roleSetting = {
    [`${settingPrefix}.${roleSettingPrefix}.progress.0`]: "常规配置",
    [`${settingPrefix}.${roleSettingPrefix}.progress.1`]: "权限",

    [`${settingPrefix}.${roleSettingPrefix}.modal.warning`]: "您确定要重置吗？\n 将不会保存更改。",
    [`${settingPrefix}.${roleSettingPrefix}.modal.warning.permission`]: "您确定要重置权限吗？",

    [`${settingPrefix}.${roleSettingPrefix}.field.config.name`]: "名称",
    [`${settingPrefix}.${roleSettingPrefix}.field.config.displayName`]: "显示名称",

    [`${settingPrefix}.${roleSettingPrefix}.text.title.new`]: "新角色",
   
    [`${settingPrefix}.${roleSettingPrefix}.section.text.title.general`]: "常规配置",
    [`${settingPrefix}.${roleSettingPrefix}.section.text.subTitle.general`]: "设置通用角色配置",

    [`${settingPrefix}.${roleSettingPrefix}.table.text.title`]: "权限",
    [`${settingPrefix}.${roleSettingPrefix}.table.text.subTitle`]: "设置角色权限",

    [`${settingPrefix}.${roleSettingPrefix}.table.displayName.0`]: "请求模型",
    [`${settingPrefix}.${roleSettingPrefix}.table.displayName.1`]: "读取",
    [`${settingPrefix}.${roleSettingPrefix}.table.displayName.2`]: "编辑",
    [`${settingPrefix}.${roleSettingPrefix}.table.displayName.3`]: "删除",
    [`${settingPrefix}.${roleSettingPrefix}.table.displayName.4`]: "标签",

    [`${settingPrefix}.${roleSettingPrefix}.table.displayName.5`]: "全部",
}

const channelAccount = {
    [`${settingPrefix}.${channelAccountPrefix}.page.text.title`]: "频道账户设置",

    [`${settingPrefix}.${channelAccountPrefix}.tab.title.general`]: "常规",
    [`${settingPrefix}.${channelAccountPrefix}.tab.title.advanced`]: "高级",

    [`${settingPrefix}.${channelAccountPrefix}.text.region`]: "地区",
    [`${settingPrefix}.${channelAccountPrefix}.nameList.region.all`]: "所有地区",

    [`${settingPrefix}.${channelAccountPrefix}.table.text.title`]: "高级频道账户",
    [`${settingPrefix}.${channelAccountPrefix}.table.text.subTitle`]: "为不同的业务、语言和渠道等添加多个渠道帐户。",
    [`${settingPrefix}.${channelAccountPrefix}.table.placeholder.search`]: "搜索账户",
    [`${settingPrefix}.${channelAccountPrefix}.button.addAccounts`]: "添加账户",

    [`${settingPrefix}.${channelAccountPrefix}.modal.warning.disable`]: "确认禁用此频道？之后将无法接收聊天。",
    [`${settingPrefix}.${channelAccountPrefix}.modal.warning.remove`]: "确认删除这个频道？",

    [`${settingPrefix}.${channelAccountPrefix}.table.displayName.0`]: "频道 ID",
    [`${settingPrefix}.${channelAccountPrefix}.table.displayName.1`]: "频道",
    [`${settingPrefix}.${channelAccountPrefix}.table.displayName.2`]: "账户名",
    [`${settingPrefix}.${channelAccountPrefix}.table.displayName.3`]: "地区",
    [`${settingPrefix}.${channelAccountPrefix}.table.displayName.4`]: "语言",
    [`${settingPrefix}.${channelAccountPrefix}.table.displayName.5`]: "有效",

    [`${settingPrefix}.${channelAccountPrefix}.list.text.title`]: "频道设置",
    [`${settingPrefix}.${channelAccountPrefix}.list.text.subTitle`]: "在这里设置基本的聊天频道帐户。对于多个帐户，请转到“高级”",
    [`${settingPrefix}.${channelAccountPrefix}.button.addChannel`]: "添加频道",
}

const editChannelAccount = {
    [`${settingPrefix}.${editChannelAccountPrefix}.text.title.edit`]: "编辑频道账户",
    [`${settingPrefix}.${editChannelAccountPrefix}.text.title.add`]: "新频道账户",

    [`${settingPrefix}.${editChannelAccountPrefix}.section.text.title.setup`]: "频道设置",
    [`${settingPrefix}.${editChannelAccountPrefix}.section.text.subTitle.setup`]: "为聊天互动设置新频道",

    [`${settingPrefix}.${editChannelAccountPrefix}.section.text.title.mapping`]: "频道映射",
    [`${settingPrefix}.${editChannelAccountPrefix}.section.text.subTitle.mapping`]: "将频道映射到业务",

    [`${settingPrefix}.${editChannelAccountPrefix}.section.text.title.profile`]: "聊天资料",
    [`${settingPrefix}.${editChannelAccountPrefix}.section.text.subTitle.profile`]: "信息将被客户看到。标志必须是 SVG 或 PNG 格式，最大 100px x 100px",

    [`${settingPrefix}.${editChannelAccountPrefix}.section.text.title.status`]: "账户状态",
    [`${settingPrefix}.${editChannelAccountPrefix}.section.text.subTitle.status`]: "启用或禁用频道帐户",

    [`${settingPrefix}.${editChannelAccountPrefix}.field.setup.id`]: "ID (自动生成)",
    [`${settingPrefix}.${editChannelAccountPrefix}.field.setup.channelId`]: "频道 ID",
    [`${settingPrefix}.${editChannelAccountPrefix}.field.setup.omniChannel`]: "频道",
    [`${settingPrefix}.${editChannelAccountPrefix}.field.setup.displayName`]: "账户名",
    [`${settingPrefix}.${editChannelAccountPrefix}.field.setup.url`]: "URL",
    [`${settingPrefix}.${editChannelAccountPrefix}.field.setup.quickAccessUrl`]: "快速访问网址",

    [`${settingPrefix}.${editChannelAccountPrefix}.field.mapping.timezone`]: "时区",
    [`${settingPrefix}.${editChannelAccountPrefix}.field.mapping.defaultLanguage`]: "语言",

    [`${settingPrefix}.${editChannelAccountPrefix}.field.profile.chatbotFlowName`]: "聊天机器人名称",
    [`${settingPrefix}.${editChannelAccountPrefix}.field.profile.aIChatbotId`]: "OpenAI Bot",

    [`${settingPrefix}.${editChannelAccountPrefix}.button.addChannel`]: "添加频道",
    [`${settingPrefix}.${editChannelAccountPrefix}.button.editAccount`]: "编辑账户",
    [`${settingPrefix}.${editChannelAccountPrefix}.button.removeAccount`]: "删除账户",
}

const chatGroup = {
    [`${settingPrefix}.${chatGroupPrefix}.table.text.title`]: "聊天组设置",
    [`${settingPrefix}.${chatGroupPrefix}.table.text.subTitle`]: "为聊天路由创建聊天组",
    [`${settingPrefix}.${chatGroupPrefix}.table.placeholder.search`]: "搜索群组",

    [`${settingPrefix}.${chatGroupPrefix}.modal.warning`]: "确认删除这个聊天组？",

    [`${settingPrefix}.${chatGroupPrefix}.table.displayName.0`]: "群组标签",
    [`${settingPrefix}.${chatGroupPrefix}.table.displayName.1`]: "群组名称",
    [`${settingPrefix}.${chatGroupPrefix}.table.displayName.2`]: "# 用户数",

    [`${settingPrefix}.${chatGroupPrefix}.button.addGroups`]: "添加组",

    [`${settingPrefix}.${chatGroupPrefix}.text.title.edit`]: "编辑聊天组",
    [`${settingPrefix}.${chatGroupPrefix}.text.title.add`]: "创建聊天组",

    [`${settingPrefix}.${chatGroupPrefix}.field.group.groupName`]: "群组名称",
    [`${settingPrefix}.${chatGroupPrefix}.field.group.groupLabel`]: "群组标签",
    [`${settingPrefix}.${chatGroupPrefix}.field.group.method`]: "路由方法",

    [`${settingPrefix}.${chatGroupPrefix}.groupUser.text.title.edit`]: "编辑聊天组",
    [`${settingPrefix}.${chatGroupPrefix}.groupUser.text.title.add`]: "创建聊天组",

    [`${settingPrefix}.${chatGroupPrefix}.groupUser.table.placeholder.search`]: "搜索用户",

    [`${settingPrefix}.${chatGroupPrefix}.groupUser.table.displayName.0`]: "显示名称",
    [`${settingPrefix}.${chatGroupPrefix}.groupUser.table.displayName.1`]: "用户名",
    [`${settingPrefix}.${chatGroupPrefix}.groupUser.table.displayName.2`]: "电子邮件",
    [`${settingPrefix}.${chatGroupPrefix}.groupUser.table.displayName.3`]: "角色",

    [`${settingPrefix}.${chatGroupPrefix}.groupUser.table.footer.total`]: "总计",
    [`${settingPrefix}.${chatGroupPrefix}.groupUser.table.footer.selected`]: "已选择收件人",
}

const chatRouting = {
    [`${settingPrefix}.${chatRoutingPrefix}.table.text.title`]: "聊天路由",
    [`${settingPrefix}.${chatRoutingPrefix}.table.text.subTitle`]: "根据条件将聊天分配到不同的聊天组和通道路由设定",
    [`${settingPrefix}.${chatRoutingPrefix}.table.placeholder.search`]: "搜索路由",

    [`${settingPrefix}.${chatRoutingPrefix}.text.type`]: "路由类型",

    [`${settingPrefix}.${chatRoutingPrefix}.nameList.type.default`]: "默认",
    [`${settingPrefix}.${chatRoutingPrefix}.nameList.type.condition`]: "条件",

    [`${settingPrefix}.${chatRoutingPrefix}.modal.warning.disable`]: "确认禁用此路由？",
    [`${settingPrefix}.${chatRoutingPrefix}.modal.warning.remove`]: "确认删除此路由？",

    [`${settingPrefix}.${chatRoutingPrefix}.table.displayName.0`]: "优先级",
    [`${settingPrefix}.${chatRoutingPrefix}.table.displayName.1`]: "路由名称",
    [`${settingPrefix}.${chatRoutingPrefix}.table.displayName.2`]: "类型",
    [`${settingPrefix}.${chatRoutingPrefix}.table.displayName.3`]: "分配的聊天组",
    [`${settingPrefix}.${chatRoutingPrefix}.table.displayName.4`]: "有效",

    [`${settingPrefix}.${chatRoutingPrefix}.button.addRouting`]: "添加路由",
}

const editChatRouting = {
    [`${settingPrefix}.${editChatRoutingPrefix}.text.title.edit`]: "编辑聊天路由",
    [`${settingPrefix}.${editChatRoutingPrefix}.text.title.add`]: "添加聊天路由",

    [`${settingPrefix}.${editChatRoutingPrefix}.section.text.title.setup`]: "路由设置",
    [`${settingPrefix}.${editChatRoutingPrefix}.section.text.subTitle.setup`]: "根据条件将聊天分配到不同的聊天组和频道帐户",

    [`${settingPrefix}.${editChatRoutingPrefix}.section.text.title.condition`]: "路由条件",
    [`${settingPrefix}.${editChatRoutingPrefix}.section.text.subTitle.condition`]: "设置路由条件",

    [`${settingPrefix}.${editChatRoutingPrefix}.section.text.title.allocation`]: "聊天组分配",
    [`${settingPrefix}.${editChatRoutingPrefix}.section.text.subTitle.allocation`]: "选择聊天组进行聊天分配",

    [`${settingPrefix}.${editChatRoutingPrefix}.section.text.title.users`]: "聊天组用户",
    [`${settingPrefix}.${editChatRoutingPrefix}.section.table.placeholder.search`]: "搜索用户",

    [`${settingPrefix}.${editChatRoutingPrefix}.progress.0`]: "路由设置",
    [`${settingPrefix}.${editChatRoutingPrefix}.progress.1`]: "选择聊天组进行聊天分配",

    [`${settingPrefix}.${editChatRoutingPrefix}.field.setup.name`]: "路由名称",
    [`${settingPrefix}.${editChatRoutingPrefix}.field.setup.type`]: "路由类型",
    [`${settingPrefix}.${editChatRoutingPrefix}.field.setup.priority`]: "优先级",

    [`${settingPrefix}.${editChatRoutingPrefix}.field.condition.fieldType`]: "字段类型",
    [`${settingPrefix}.${editChatRoutingPrefix}.field.condition.fieldName`]: "字段名称",
    [`${settingPrefix}.${editChatRoutingPrefix}.field.condition.condition`]: "条件",
    [`${settingPrefix}.${editChatRoutingPrefix}.field.condition.value`]: "值",

    [`${settingPrefix}.${editChatRoutingPrefix}.field.allocation.groupId`]: "聊天组",

    [`${settingPrefix}.${editChatRoutingPrefix}.error.condition.fieldName.invalidOption`]: "字段已删除",

    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.fieldType.conversationStandardField`]: "对话",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.fieldType.prechatFormField`]: "预聊天表单",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.fieldType.contact`]: "联系人",
   
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.fieldName.name`]: "姓名",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.fieldName.language`]: "语言",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.fieldName.omniChannel`]: "全渠道",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.fieldName.omniExternal id`]: "Omni 外部 id",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.fieldName.omniChannel id`]: "全渠道id",

    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.equalsTo`]: "等于",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.notEqualsTo`]: "不等于",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.greaterThan`]: "大于",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.greaterThanOrEquals`]: "大于或等于",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.lessThan`]: "小于",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.lessThanOrEqualsTo`]: "小于或等于",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.startWith`]: "开始于",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.endWith`]: "结束于",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.empty`]: "空",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.notEmpty`]: "非空",

    [`${settingPrefix}.${editChatRoutingPrefix}.button.addCondition`]: "添加条件",
    [`${settingPrefix}.${editChatRoutingPrefix}.button.addRouting`]: "添加路由",
    [`${settingPrefix}.${editChatRoutingPrefix}.button.editRouting`]: "编辑路由",
}

const message = {
    [`${settingPrefix}.${messagePrefix}.page.text.title`]: "消息设置",

    [`${settingPrefix}.${messagePrefix}.tab.title.quickText`]: "快速文本",
    [`${settingPrefix}.${messagePrefix}.tab.title.template`]: "模板",
    [`${settingPrefix}.${messagePrefix}.tab.title.systemMessage`]: "系统消息",
}

const quickText = {
    [`${settingPrefix}.${messagePrefix}.${quickTextPrefix}.table.text.title`]: "快速文本设置",
    [`${settingPrefix}.${messagePrefix}.${quickTextPrefix}.table.text.subTitle`]: "在公共文件夹中设置快速文本以进行聊天交互",
    [`${settingPrefix}.${messagePrefix}.${quickTextPrefix}.table.placeholder.search`]: "搜索快速文本",
    [`${settingPrefix}.${messagePrefix}.${quickTextPrefix}.modal.warning`]: "确认删除这个快速文本？",

    [`${settingPrefix}.${messagePrefix}.${quickTextPrefix}.table.displayName.0`]: "标签",
    [`${settingPrefix}.${messagePrefix}.${quickTextPrefix}.table.displayName.1`]: "语言",
    [`${settingPrefix}.${messagePrefix}.${quickTextPrefix}.table.displayName.2`]: "所有者",

    [`${settingPrefix}.${messagePrefix}.${quickTextPrefix}.button.addQuickText`]: "添加快速文本",
}

const editQuickText = {
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.text.title.edit`]: "编辑快速文本",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.text.title.add`]: "添加快速文本",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.section.text.title.setup`]: "快速文本设置",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.section.text.subTitle.setup`]: "设置代理在聊天交互期间使用的快速文本",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.section.text.title.list`]: "快速文本列表",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.section.text.subTitle.list`]: "设置快速文本",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.section.text.title.subCategory`]: "子类别快速文本列表",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.section.text.subTitle.subCategory`]: "设置子类别快速文本",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.expanable.text.quickText`]: "快速文本",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.expanable.text.subCategory`]: "子类别",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.progress.0`]: "快捷文本设置",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.progress.1`]: "快速文本列表",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.progress.2`]: "子目录快速文本列表",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.field.setup.label`]: "快速文本标题",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.field.setup.language`]: "语言",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.field.setup.userId`]: "分配给",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.field.item.title`]: "标题",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.field.item.text`]: "文本",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.field.subCategory.label`]: "标签",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.button.addItem`]: "添加项目",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.button.addCategory`]: "添加类别",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.button.addQuickText`]: "添加快速文本",
}

const template = {
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.table.text.title`]: "模板设置",
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.table.text.subTitle`]: "在公用文件夹中设置用于聊天发起或广播的模板。",
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.table.placeholder.search`]: "搜索模板",
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.modal.warning`]: "确认删除此模板？",
   
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.table.displayName.0`]: "名称",
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.table.displayName.1`]: "全渠道",
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.table.displayName.2`]: "渠道 ID",
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.table.displayName.3`]: "模板 ID",
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.table.displayName.4`]: "语言",

    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.button.addTemplate`]: "添加模板",
}

const editTemplate = {
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.text.title.edit`]: "编辑模板",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.text.title.add`]: "添加模板",

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.section.text.title.setup`]: "模板设置",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.section.text.subTitle.setup`]: "设置聊天发起或广播模板",

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.section.text.title.content`]: "模板内容",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.section.text.subTitle.content`]: "模板的草稿内容",
   
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.section.text.title.list`]: "模板数据配置",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.section.text.subTitle.list`]: "设置模板数据配置",
   
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.expanable.text.template`]: "模板",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.expanable.text.subCategory`]: "子类别",

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.progress.0`]: "模板设置",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.progress.1`]: "模板内容",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.progress.2`]: "模板数据配置",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.progress.last`]: "预览并确认",

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.setup.name`]: "模板名称",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.setup.omniChannelId`]: "全渠道 ID",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.setup.templateId`]: "模板 ID",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.setup.language`]: "语言",

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.content.templateFormat`]: "模板消息",
   
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.item.componentType`]: "组件类型",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.item.messageType`]: "消息类型",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.item.dataFormat`]: "数据格式",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.item.imagePath`]: "图片网址",

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.nameList.item.componentType.header`]: "标头",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.nameList.item.componentType.body`]: "正文",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.nameList.item.componentType.footer`]: "页脚",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.nameList.item.messageType.text`]: "文本",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.nameList.item.messageType.image`]: "图片",

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.notice.empty`]: `没有数据密钥需要配置，请跳过当前步骤`,

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.error.empty`]: `"{{" 和 "}}"之间的数据键不能为空`,
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.error.regex`]: `数据键只能包含字母数字、空格或下划线`,
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.error.same`]: `"{{" 和 "}}"之间的数据键不能与配置键相同`,
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.error.duplicates`]: `请删除"{{" 和 "}}"之间的重复数据键`,

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.button.addTemplate`]: "添加模板",
}

const systemMessage = {
    [`${settingPrefix}.${messagePrefix}.${systemMessagePrefix}.table.text.title`]: "系统消息设置",
    [`${settingPrefix}.${messagePrefix}.${systemMessagePrefix}.table.text.subTitle`]: "为聊天路由创建系统消息集",
    [`${settingPrefix}.${messagePrefix}.${systemMessagePrefix}.table.placeholder.search`]: "搜索集",
    [`${settingPrefix}.${messagePrefix}.${systemMessagePrefix}.modal.warning`]: "确认删除这条系统消息？",

    [`${settingPrefix}.${messagePrefix}.${systemMessagePrefix}.table.displayName.0`]: "语言",

    [`${settingPrefix}.${messagePrefix}.${systemMessagePrefix}.button.addSets`]: "添加集",
}

const editSystemMessage = {
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.text.title.edit`]: "编辑系统消息集",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.text.title.add`]: "添加系统消息集",

    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.section.text.title.setup`]: "系统消息设置",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.section.text.subTitle.setup`]: "为聊天路由设置一组新的系统消息",

    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.section.text.title.content`]: "系统消息内容",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.section.text.subTitle.content`]: "不同场景的草稿内容",

    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.setup.language`]: "语言",

    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.blockedConversationMessage`]: "被阻止的对话信息",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.closeConversationByAgentMessage`]: "通过代理消息关闭对话",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.closeConversationBySystemMessage`]: "通过系统消息关闭对话",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.closeConversationByUserMessage`]: "通过用户消息关闭对话",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.conversationAssignedMessage`]: "对话无响应消息",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.conversationNoResponseMessage`]: "对话无响应消息",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.fileSizeOverLimitMessage`]: "文件大小超过限制消息",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.holidayAlert`]: "假期提醒",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.liveChatClientDisconnected`]: "实时聊天客户端断开连接",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.liveChatClientReconnected`]: "实时聊天客户端已重新连接",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.messageTooLongMessage`]: "消息太长消息",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.noActiveAgentMessage`]: "没有活动代理消息",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.openLiveChatMessage`]: "打开实时聊天消息",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.specialDate`]: "特殊日期",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.subscriptionGreetingMessage`]: "订阅问候语",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.templateMessageBlockedByUser`]: "模板消息被用户阻止",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.templateMessageFailed`]: "模板消息失败",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.templateMessageSuccessSend`]: "模板消息发送成功",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.unsupportMediaTypeMessage`]: "不支持媒体类型消息",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.userSessionTimeout`]: "用户会话超时",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.workingDateAlert`]: "工作日期提醒",

    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.expanable.text.systemLabels`]: "系统标签",

    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.button.addSystemMessage`]: "添加系统消息",
}

const tagging = {
    [`${settingPrefix}.${taggingPrefix}.page.text.title`]: "标记设置",
    [`${settingPrefix}.${taggingPrefix}.page.text.subTitle`]: "标签",

    [`${settingPrefix}.${taggingPrefix}.text.addTags`]: "在此处添加标签。",
    [`${settingPrefix}.${taggingPrefix}.button.addTags`]: "添加标签",
}

const wrapUp = {
    [`${settingPrefix}.${wrapUpPrefix}.page.text.title`]: "总结设置",

    [`${settingPrefix}.${wrapUpPrefix}.tab.title.wrapUp`]: "总结",
    [`${settingPrefix}.${wrapUpPrefix}.tab.title.autoEmail`]: "自动电子邮件",

    [`${settingPrefix}.${wrapUpPrefix}.table.text.title`]: "自动电子邮件设置",
    [`${settingPrefix}.${wrapUpPrefix}.table.text.subTitle`]: "为聊天后互动设置自动电子邮件模板",
    [`${settingPrefix}.${wrapUpPrefix}.button.addTemplates`]: "添加模板",

    [`${settingPrefix}.${wrapUpPrefix}.modal.warning`]: "确认删除此模板？",

    [`${settingPrefix}.${wrapUpPrefix}.table.displayName.0`]: "显示名称",
    [`${settingPrefix}.${wrapUpPrefix}.table.displayName.1`]: "主题",

    [`${settingPrefix}.${wrapUpPrefix}.list.text.title`]: "总结设置",
    [`${settingPrefix}.${wrapUpPrefix}.list.text.subTitle`]: "为聊天后分析和报告创建和管理聊天目标",

    [`${settingPrefix}.${wrapUpPrefix}.text.title.edit`]: "编辑电子邮件模板",
    [`${settingPrefix}.${wrapUpPrefix}.text.title.add`]: "添加电子邮件模板",

    [`${settingPrefix}.${wrapUpPrefix}.section.text.title.setup`]: "邮件模板设置",
    [`${settingPrefix}.${wrapUpPrefix}.section.text.subTitle.setup`]: "为聊天后互动设置自动电子邮件模板",

    [`${settingPrefix}.${wrapUpPrefix}.field.setup.name`]: "显示名称",
    [`${settingPrefix}.${wrapUpPrefix}.field.setup.subject`]: "主题",
    [`${settingPrefix}.${wrapUpPrefix}.field.setup.content`]: "邮件内容",

    [`${settingPrefix}.${wrapUpPrefix}.button.addtemplate`]: "添加模板"
}

const chatAutomation = {
    [`${settingPrefix}.${chatAutomationPrefix}.table.text.title`]: "聊天自动化",
    [`${settingPrefix}.${chatAutomationPrefix}.table.text.subTitle`]: "在此处应用自动化。对于聊天分配，请转到聊天路由。",
    [`${settingPrefix}.${chatAutomationPrefix}.table.placeholder.search`]: "搜索规则",

    [`${settingPrefix}.${chatAutomationPrefix}.modal.warning.disable`]: "你确认禁用这个自动化吗？",
    [`${settingPrefix}.${chatAutomationPrefix}.modal.warning.remove`]: "你确认删除这个自动化吗？",

    [`${settingPrefix}.${chatAutomationPrefix}.table.displayName.0`]: "规则名称",
    [`${settingPrefix}.${chatAutomationPrefix}.table.displayName.1`]: "表名",
    [`${settingPrefix}.${chatAutomationPrefix}.table.displayName.2`]: "条件",
    [`${settingPrefix}.${chatAutomationPrefix}.table.displayName.3`]: "动作",
    [`${settingPrefix}.${chatAutomationPrefix}.table.displayName.4`]: "有效",

    [`${settingPrefix}.${chatAutomationPrefix}.button.addRules`]: "添加规则",
}

const editChatAutomation = {
    [`${settingPrefix}.${editChatAutomationPrefix}.text.and`]: "和",

    [`${settingPrefix}.${editChatAutomationPrefix}.text.title.edit`]: "编辑自动化",
    [`${settingPrefix}.${editChatAutomationPrefix}.text.title.add`]: "新自动化",

    [`${settingPrefix}.${editChatAutomationPrefix}.input.placeholder`]: "输入规则名称",
    [`${settingPrefix}.${editChatAutomationPrefix}.text.defaultName`]: "新自动化",

    [`${settingPrefix}.${editChatAutomationPrefix}.button.activate`]: "激活",

    [`${settingPrefix}.${editChatAutomationPrefix}.field.attribute.tableName`]: "表名",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.attribute.executeType`]: "执行类型",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.attribute.executeActionTypeSet`]: "执行操作类型集",

    [`${settingPrefix}.${editChatAutomationPrefix}.field.criteria.fieldConfigId`]: "字段配置",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.criteria.conditionOperator`]: "条件运算符",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.criteria.value1`]: "值 1",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.criteria.value2`]: "值 2",

    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.base.type`]: "类型",

    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.update.retrigger`]: "重新触发",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.update.fieldConfigId`]: "字段配置",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.update.value`]: "值",

    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.error.message`]: "错误消息",

    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.sendEmail.emailTemplateId`]: "电子邮件模板 ID",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.sendEmail.emailFieldType`]: "电子邮件字段类型",

    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.sendEmail.userId`]: "用户 ID",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.sendEmail.userGroupId`]: "用户组 ID",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.sendEmail.fieldConfigIdList`]: "字段配置列表",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.sendEmail.toEmailList`]: "电子邮件（以 , 分隔）",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.start.title`]: "自动化开始",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.end.title`]: "自动化结束",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.addFlow.title`]: "添加流",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.addCriteria.title`]: "添加条件",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.addAction.title`]: "添加操作（且仅）",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.criteriaTop.title`]: "条件",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.actionTop.title`]: "动作",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.attribute.title`]: "流",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.criteria.edit.title`]: "条件 (如果)",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.action.edit.title`]: "行动（然后）",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.executeType.realtime`]: "实时",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.executeType.schedule`]: "日程",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.equalsTo`]: "等于",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.notEqualsTo`]: "不等于",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.greaterThan`]: "大于",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.greaterThanOrEquals`]: "大于或等于",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.lessThan`]: "小于",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.lessThanOrEqualsTo`]: "小于或等于",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.startWith`]: "开始于",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.endWith`]: "结束于",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.isNull`]: "为空",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.notNull`]: "不为空",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.between`]: "之间",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.contains`]: "包含",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.within`]: "之内",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.actionType.title`]: "动作类型",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.actionType.fieldUpdate`]: "字段更新",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.actionType.throwError`]: "抛出错误",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.actionType.sendEmail`]: "发送邮件",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.action.emailFieldTypeuserId`]: "用户 ID",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.action.emailFieldTypeuserGroupId`]: "用户组 ID",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.action.emailFieldTypetoOwner`]: "致所有者",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.action.emailFieldTypefieldConfigIdList`]: "字段配置 ID 列表",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.action.emailFieldTypetoEmailList`]: "至电子邮件列表",
}

const chatbot = {
    [`${settingPrefix}.${chatbotPrefix}.page.text.title`]: "聊天机器人设置",

    [`${settingPrefix}.${chatbotPrefix}.tab.title.chatbotAutomation`]: '聊天机器人自动化',
    [`${settingPrefix}.${chatbotPrefix}.tab.title.nodes`]: '节点',
    [`${settingPrefix}.${chatbotPrefix}.tab.title.variables`]: '变量',
    [`${settingPrefix}.${chatbotPrefix}.tab.title.labelling`]: '标签',

    [`${settingPrefix}.${chatbotPrefix}.table.text.title`]: "聊天机器人流程",
    [`${settingPrefix}.${chatbotPrefix}.table.text.subTitle`]: "设置由聊天机器人发起的自动对话。",
    [`${settingPrefix}.${chatbotPrefix}.table.placeholder.search`]: "搜索流程",

    [`${settingPrefix}.${chatbotPrefix}.modal.warning.remove`]: "确认删除此流？无法恢复。",

    [`${settingPrefix}.${chatbotPrefix}.table.displayName.0`]: "流程名称",
    [`${settingPrefix}.${chatbotPrefix}.table.displayName.1`]: "类型",
    [`${settingPrefix}.${chatbotPrefix}.table.displayName.2`]: "版本",
    [`${settingPrefix}.${chatbotPrefix}.table.displayName.3`]: "更新于",

    [`${settingPrefix}.${chatbotPrefix}.button.createFlows`]: "创建流",

    [`${settingPrefix}.${chatbotPrefix}.nameList.flowType.draft`]: "草稿",
    [`${settingPrefix}.${chatbotPrefix}.nameList.flowType.released`]: "已发布",
}

const createOrCloneChatbot = {
    [`${settingPrefix}.${createOrCloneChatbotPrefix}.modal.page.create.text.title`]: "创建聊天机器人流程",
    [`${settingPrefix}.${createOrCloneChatbotPrefix}.field.create.name`]: "流名称",

    [`${settingPrefix}.${createOrCloneChatbotPrefix}.modal.page.clone.text.title`]: "克隆聊天机器人流程",
    [`${settingPrefix}.${createOrCloneChatbotPrefix}.field.clone.name`]: "來自流",
    [`${settingPrefix}.${createOrCloneChatbotPrefix}.field.clone.version`]: "版本",
    [`${settingPrefix}.${createOrCloneChatbotPrefix}.field.clone.newFlowName`]: "流名称",

    [`${settingPrefix}.${createOrCloneChatbotPrefix}.button.clone`]: "克隆",
}

const chatbotIconBar = {
    [`${settingPrefix}.${chatbotIconBarPrefix}.list`]: "List",
    [`${settingPrefix}.${chatbotIconBarPrefix}.keywords`]: "Keywords",
    [`${settingPrefix}.${chatbotIconBarPrefix}.parameter`]: "Parameter",
    [`${settingPrefix}.${chatbotIconBarPrefix}.entity`]: "Entity",
    [`${settingPrefix}.${chatbotIconBarPrefix}.response`]: "Response",
    [`${settingPrefix}.${chatbotIconBarPrefix}.action`]: "Action",
    
    [`${settingPrefix}.${chatbotIconBarPrefix}.edit.keywords`]: "Edit Keywords",
    [`${settingPrefix}.${chatbotIconBarPrefix}.edit.parameter`]: "Edit Parameter",
    [`${settingPrefix}.${chatbotIconBarPrefix}.edit.entity`]: "Edit Entity",
    [`${settingPrefix}.${chatbotIconBarPrefix}.edit.response`]: "Edit Response",
    [`${settingPrefix}.${chatbotIconBarPrefix}.edit.action`]: "Edit Action",
}

const editChatbot = {
    [`${settingPrefix}.${editChatbotPrefix}.page.extra.time`]: "最后更新于 - ",
    [`${settingPrefix}.${editChatbotPrefix}.page.placeholder.search`]: "搜索节点",

    [`${settingPrefix}.${editChatbotPrefix}.modal.title.import`]: "导入",

    [`${settingPrefix}.${editChatbotPrefix}.modal.warning.publish`]: "确认发布流程？",
    [`${settingPrefix}.${editChatbotPrefix}.modal.warning.remove`]: "确认删除这个",
    [`${settingPrefix}.${editChatbotPrefix}.modal.warning.exitEdit`]: "确认取消编辑？",
    [`${settingPrefix}.${editChatbotPrefix}.modal.warning.exitCreate`]: "确认取消创建？",

    [`${settingPrefix}.${editChatbotPrefix}.text.search`]: "搜索",
    [`${settingPrefix}.${editChatbotPrefix}.text.new`]: "新建",

    [`${settingPrefix}.${editChatbotPrefix}.text.node`]: "节点",

    [`${settingPrefix}.${editChatbotPrefix}.nameList.setting.keyword`]: "关键字",
    [`${settingPrefix}.${editChatbotPrefix}.nameList.setting.entity`]: "实体",
    [`${settingPrefix}.${editChatbotPrefix}.nameList.setting.response`]: "回应",
    [`${settingPrefix}.${editChatbotPrefix}.nameList.setting.action`]: "动作",
    [`${settingPrefix}.${editChatbotPrefix}.nameList.setting.parameter`]: "参数",

    [`${settingPrefix}.${editChatbotPrefix}.button.import`]: "Import",
    [`${settingPrefix}.${editChatbotPrefix}.button.export`]: "Export",
    [`${settingPrefix}.${editChatbotPrefix}.button.preview`]: "预览流程",
    [`${settingPrefix}.${editChatbotPrefix}.button.publish`]: "发布流程",

    [`${settingPrefix}.${editChatbotPrefix}.flow.start.title`]: "聊天开始",
    [`${settingPrefix}.${editChatbotPrefix}.flow.add.title`]: "添加节点",
    [`${settingPrefix}.${editChatbotPrefix}.flow.first.title`]: "第一个节点",

    [`${settingPrefix}.${editChatbotPrefix}.flow.preview.title`]: "流程预览",
    [`${settingPrefix}.${editChatbotPrefix}.flow.preview.loading`]: "聊天机器人处理",
}

const editNode = {
    [`${settingPrefix}.${editNodePrefix}.text.title.edit`]: "编辑节点",
    [`${settingPrefix}.${editNodePrefix}.text.title.add`]: "创建节点",

    [`${settingPrefix}.${editNodePrefix}.section.text.title.setup`]: "节点设置",
    [`${settingPrefix}.${editNodePrefix}.section.text.subTitle.setup`]: "为聊天机器人自动化创建一个新节点",

    [`${settingPrefix}.${editNodePrefix}.section.text.title.conditions`]: "聊天条件",
    [`${settingPrefix}.${editNodePrefix}.section.text.subTitle.conditions`]: "设置传入客户对话的条件",

    [`${settingPrefix}.${editNodePrefix}.section.text.title.variable`]: "变量",
    [`${settingPrefix}.${editNodePrefix}.section.text.subTitle.variable`]: "设置全局变量",

    [`${settingPrefix}.${editNodePrefix}.section.text.title.response`]: "响应",
    [`${settingPrefix}.${editNodePrefix}.section.text.subTitle.response`]: "设置响应",

    [`${settingPrefix}.${editNodePrefix}.section.text.title.action`]: "动作",
    [`${settingPrefix}.${editNodePrefix}.section.text.subTitle.action`]: "设置满足条件时的动作。",

    [`${settingPrefix}.${editNodePrefix}.field.setup.name`]: "节点名称",
    [`${settingPrefix}.${editNodePrefix}.field.setup.priority`]: "优先级",
    [`${settingPrefix}.${editNodePrefix}.field.setup.language`]: "节点语言",
    [`${settingPrefix}.${editNodePrefix}.field.setup.isFirstNode`]: "必须是对话中的第一个节点",

    [`${settingPrefix}.${editNodePrefix}.field.conditions.conditionType`]: "条件类型",

    [`${settingPrefix}.${editNodePrefix}.field.variable.option`]: "选项",
    [`${settingPrefix}.${editNodePrefix}.field.externalAction.externalActionId`]: "动作",

    [`${settingPrefix}.${editNodePrefix}.field.extra.type`]: "类型",
    [`${settingPrefix}.${editNodePrefix}.field.extra.value`]: "值",
    [`${settingPrefix}.${editNodePrefix}.field.extra.parameterName`]: "变量名",

    [`${settingPrefix}.${editNodePrefix}.field.response.responseId`]: "响应消息",
    [`${settingPrefix}.${editNodePrefix}.field.response.responseType`]: "响应方式",

    [`${settingPrefix}.${editNodePrefix}.field.action.afterAction`]: "动作",
    [`${settingPrefix}.${editNodePrefix}.field.action.jumpNodeId`]: "跳转到节点",

    [`${settingPrefix}.${editNodePrefix}.nameList.language.default`]: "节点语言",

    [`${settingPrefix}.${editNodePrefix}.nameList.conditionType.others`]: "其他",
    [`${settingPrefix}.${editNodePrefix}.nameList.conditionType.anyMessage`]: "任意短信",
    [`${settingPrefix}.${editNodePrefix}.nameList.conditionType.anyMedia`]: "任何媒体",

    [`${settingPrefix}.${editNodePrefix}.nameList.responseType.allMessage`]: "返回所有消息",
    [`${settingPrefix}.${editNodePrefix}.nameList.responseType.randomDisplay`]: "随机显示消息",

    [`${settingPrefix}.${editNodePrefix}.nameList.action.doNothing`]: "什么都不做",
    [`${settingPrefix}.${editNodePrefix}.nameList.action.liveChat`]: "请求实时聊天",
    [`${settingPrefix}.${editNodePrefix}.nameList.action.jumpNode`]: "跳转到节点",
    [`${settingPrefix}.${editNodePrefix}.nameList.action.runAction`]: "外部操作",

    [`${settingPrefix}.${editNodePrefix}.expanable.text.condition`]: "条件",
    [`${settingPrefix}.${editNodePrefix}.expanable.text.variable`]: "变量",
    [`${settingPrefix}.${editNodePrefix}.expanable.text.action`]: "动作",

    [`${settingPrefix}.${editNodePrefix}.text.response.preview`]: "响应消息",

    [`${settingPrefix}.${editNodePrefix}.button.addCondition`]: "添加条件",
    [`${settingPrefix}.${editNodePrefix}.button.addVariable`]: "添加变量",
    [`${settingPrefix}.${editNodePrefix}.button.addAction`]: "添加外部操作",

    [`${settingPrefix}.${editNodePrefix}.button.createNode`]: "创建节点",
    [`${settingPrefix}.${editNodePrefix}.button.updateNode`]: "更新节点",
    [`${settingPrefix}.${editNodePrefix}.button.editNode`]: "编辑节点",
    [`${settingPrefix}.${editNodePrefix}.button.removeNode`]: "删除节点",
}

const editKeyword = {
    [`${settingPrefix}.${editKeywordPrefix}.text.title.edit`]: "编辑关键词",
    [`${settingPrefix}.${editKeywordPrefix}.text.title.add`]: "新关键词",

    [`${settingPrefix}.${editKeywordPrefix}.section.text.title.setup`]: "关键字设置",
    [`${settingPrefix}.${editKeywordPrefix}.section.text.subTitle.setup`]: "为聊天机器人自动化创建新关键字",

    [`${settingPrefix}.${editKeywordPrefix}.section.text.title.list`]: "关键字列表",
    [`${settingPrefix}.${editKeywordPrefix}.section.text.subTitle.list`]: "设置关键字列表",

    [`${settingPrefix}.${editKeywordPrefix}.field.setup.name`]: "关键词名称",
    [`${settingPrefix}.${editKeywordPrefix}.field.setup.list`]: "值",

    [`${settingPrefix}.${editKeywordPrefix}.expanable.text.List`]: "关键字列表",

    [`${settingPrefix}.${editKeywordPrefix}.button.addkeyword`]: "添加关键字",

    [`${settingPrefix}.${editKeywordPrefix}.button.createKeyword`]: "创建关键字",
    [`${settingPrefix}.${editKeywordPrefix}.button.updateKeyword`]: "更新节点",
    [`${settingPrefix}.${editKeywordPrefix}.button.cloneKeyword`]: "克隆关键字",
    [`${settingPrefix}.${editKeywordPrefix}.button.editKeyword`]: "编辑关键字",
}

const editEntity = {
    [`${settingPrefix}.${editEntityPrefix}.text.title.edit`]: "编辑实体",
    [`${settingPrefix}.${editEntityPrefix}.text.title.add`]: "新实体",

    [`${settingPrefix}.${editEntityPrefix}.section.text.title.setup`]: "实体设置",
    [`${settingPrefix}.${editEntityPrefix}.section.text.subTitle.setup`]: "为聊天机器人自动化创建一个新实体",

    [`${settingPrefix}.${editEntityPrefix}.section.text.title.option`]: "实体选项",
    [`${settingPrefix}.${editEntityPrefix}.section.text.subTitle.option`]: "设置实体选项",

    [`${settingPrefix}.${editEntityPrefix}.section.text.title.regex`]: "实体正则表达式",
    [`${settingPrefix}.${editEntityPrefix}.section.text.subTitle.regex`]: "设置实体正则表达式",

    [`${settingPrefix}.${editEntityPrefix}.section.text.title.test`]: "实体正则表达式测试",
    [`${settingPrefix}.${editEntityPrefix}.section.text.subTitle.test`]: "测试实体正则表达式",

    [`${settingPrefix}.${editEntityPrefix}.field.setup.name`]: "实体名称",
    [`${settingPrefix}.${editEntityPrefix}.field.setup.entityType`]: "实体类型",

    [`${settingPrefix}.${editEntityPrefix}.field.regex.name`]: "正则表达式名称",
    [`${settingPrefix}.${editEntityPrefix}.field.regex.value`]: "正则表达式值",
   
    [`${settingPrefix}.${editEntityPrefix}.field.option.name`]: "选项名称",
    [`${settingPrefix}.${editEntityPrefix}.field.option.value`]: "选项",

    [`${settingPrefix}.${editEntityPrefix}.table.placeholder.test`]: "文本输入",
    
    [`${settingPrefix}.${editEntityPrefix}.table.displayName.0`]: "名称",
    [`${settingPrefix}.${editEntityPrefix}.table.displayName.1`]: "正则表达式",
    [`${settingPrefix}.${editEntityPrefix}.table.displayName.2`]: "结果",

    [`${settingPrefix}.${editEntityPrefix}.nameList.entityType.regex`]: "匹配正则表达式",
    [`${settingPrefix}.${editEntityPrefix}.nameList.entityType.option`]: "匹配选项列表",

    [`${settingPrefix}.${editEntityPrefix}.expanable.text.regexlist`]: "实体正则表达式",
    [`${settingPrefix}.${editEntityPrefix}.expanable.text.regex`]: "正则表达式",
    [`${settingPrefix}.${editEntityPrefix}.expanable.text.optionlist`]: "实体选项",
    [`${settingPrefix}.${editEntityPrefix}.expanable.text.option`]: "选项",

    [`${settingPrefix}.${editEntityPrefix}.button.addRegex`]: "添加正则表达式",
    [`${settingPrefix}.${editEntityPrefix}.button.addOptionList`]: "添加选项列表",
    [`${settingPrefix}.${editEntityPrefix}.button.addOption`]: "添加选项",

    [`${settingPrefix}.${editEntityPrefix}.button.createEntity`]: "创建实体",
    [`${settingPrefix}.${editEntityPrefix}.button.updateEntity`]: "更新实体",
    [`${settingPrefix}.${editEntityPrefix}.button.editEntity`]: "编辑实体",
    [`${settingPrefix}.${editEntityPrefix}.button.cloneEntity`]: "克隆实体",

    [`${settingPrefix}.${editEntityPrefix}.duplicates.regex`]: "正则表达式名称已经存在",
    [`${settingPrefix}.${editEntityPrefix}.duplicates.option`]: "选项已经存在",    
}

const editResponse = {
    [`${settingPrefix}.${editResponsePrefix}.text.title.edit`]: "编辑回复",
    [`${settingPrefix}.${editResponsePrefix}.text.title.add`]: "新回复",

    [`${settingPrefix}.${editResponsePrefix}.section.text.title.setup`]: "响应设置",
    [`${settingPrefix}.${editResponsePrefix}.section.text.subTitle.setup`]: "为聊天机器人自动化创建一个新的响应",

    [`${settingPrefix}.${editResponsePrefix}.section.text.title.message`]: "回复信息",
    [`${settingPrefix}.${editResponsePrefix}.section.text.subTitle.message`]: "设置响应消息",

    [`${settingPrefix}.${editResponsePrefix}.section.text.title.option`]: "响应选项",
    [`${settingPrefix}.${editResponsePrefix}.section.text.subTitle.option`]: "设置响应选项",

    [`${settingPrefix}.${editResponsePrefix}.field.setup.name`]: "响应名称",
    [`${settingPrefix}.${editResponsePrefix}.field.setup.attachmentId`]: "附件",

    [`${settingPrefix}.${editResponsePrefix}.field.message.value`]: "响应消息",

    [`${settingPrefix}.${editResponsePrefix}.field.option.name`]: "选项名称",
    [`${settingPrefix}.${editResponsePrefix}.field.option.value`]: "选项值",

    [`${settingPrefix}.${editResponsePrefix}.expanable.text.message`]: "响应消息",
    [`${settingPrefix}.${editResponsePrefix}.expanable.text.optionList`]: "响应选项",
    [`${settingPrefix}.${editResponsePrefix}.expanable.text.option`]: "动作",

    [`${settingPrefix}.${editResponsePrefix}.button.addMessage`]: "添加消息",
    [`${settingPrefix}.${editResponsePrefix}.button.addOption`]: "添加选项",

    [`${settingPrefix}.${editResponsePrefix}.button.createResponse`]: "创建响应",
    [`${settingPrefix}.${editResponsePrefix}.button.updateResponse`]: "更新响应",
    [`${settingPrefix}.${editResponsePrefix}.button.editResponse`]: "编辑回复",
    [`${settingPrefix}.${editResponsePrefix}.button.cloneResponse`]: "克隆响应",
}

const editAction = {
    [`${settingPrefix}.${editActionPrefix}.text.title.edit`]: "编辑操作",
    [`${settingPrefix}.${editActionPrefix}.text.title.add`]: "新动作",

    [`${settingPrefix}.${editActionPrefix}.section.text.title.setup`]: "动作设置",
    [`${settingPrefix}.${editActionPrefix}.section.text.subTitle.setup`]: "为聊天机器人自动化创建一个新的动作",

    [`${settingPrefix}.${editActionPrefix}.section.text.title.conditions.get`]: "获取条件",
    [`${settingPrefix}.${editActionPrefix}.section.text.subTitle.conditions.get`]: "设置获取条件",

    [`${settingPrefix}.${editActionPrefix}.section.text.title.conditions.insert`]: "插入条件",
    [`${settingPrefix}.${editActionPrefix}.section.text.subTitle.conditions.insert`]: "设置插入条件",

    [`${settingPrefix}.${editActionPrefix}.section.text.title.conditions.update`]: "更新条件",
    [`${settingPrefix}.${editActionPrefix}.section.text.subTitle.conditions.update`]: "设置更新条件",

    [`${settingPrefix}.${editActionPrefix}.section.text.title.conditions.options`]: "选项条件",
    [`${settingPrefix}.${editActionPrefix}.section.text.subTitle.conditions.options`]: "设置选项条件",

    [`${settingPrefix}.${editActionPrefix}.section.text.title.conditions.identifyContact`]: "识别联系条件",
    [`${settingPrefix}.${editActionPrefix}.section.text.subTitle.conditions.identifyContact`]: "设置识别联系人条件",

    [`${settingPrefix}.${editActionPrefix}.section.text.title.response`]: "动作响应",
    [`${settingPrefix}.${editActionPrefix}.section.text.subTitle.response`]: "设置动作响应",

    [`${settingPrefix}.${editActionPrefix}.field.setup.name`]: "动作名称",
    [`${settingPrefix}.${editActionPrefix}.field.setup.action`]: "动作类型",
    [`${settingPrefix}.${editActionPrefix}.field.setup.targetTable`]: "目标表",

    [`${settingPrefix}.${editActionPrefix}.field.conditions.queryString`]: "查询字符串",
    [`${settingPrefix}.${editActionPrefix}.field.conditions.recordCount`]: "记录数",
    [`${settingPrefix}.${editActionPrefix}.field.conditions.resultAsOptions`]: "结果作为选项",
    [`${settingPrefix}.${editActionPrefix}.field.conditions.optionLabelField`]: "选项标签",
    [`${settingPrefix}.${editActionPrefix}.field.conditions.optionValueField`]: "选项值",
    [`${settingPrefix}.${editActionPrefix}.field.conditions.mediaParameterName`]: "媒体参数名称",
    [`${settingPrefix}.${editActionPrefix}.field.conditions.optionTableField`]: "选项目标表",

    [`${settingPrefix}.${editActionPrefix}.field.conditions.fieldList.name`]: "返回字段名称",
    [`${settingPrefix}.${editActionPrefix}.field.conditions.inputField.name`]: "输入字段名称",
    [`${settingPrefix}.${editActionPrefix}.field.conditions.inputField.value`]: "输入字段值",

    [`${settingPrefix}.${editActionPrefix}.field.response.responseId`]: "响应",
    [`${settingPrefix}.${editActionPrefix}.field.response.failedResponseId`]: "响应失败",
    [`${settingPrefix}.${editActionPrefix}.field.response.additional.condition`]: "响应",
    [`${settingPrefix}.${editActionPrefix}.field.response.additional.value`]: "响应失败",

    [`${settingPrefix}.${editActionPrefix}.nameList.action.get`]: "获取",
    [`${settingPrefix}.${editActionPrefix}.nameList.action.insert`]: "插入",
    [`${settingPrefix}.${editActionPrefix}.nameList.action.update`]: "更新",
    [`${settingPrefix}.${editActionPrefix}.nameList.action.options`]: "选项",
    [`${settingPrefix}.${editActionPrefix}.nameList.action.identifyContact`]: "识别联系人",
    [`${settingPrefix}.${editActionPrefix}.nameList.action.uploadAttachment`]: "上传附件",

    [`${settingPrefix}.${editActionPrefix}.nameList.additional.condition.recordNotFound`]: "记录未找到",
    [`${settingPrefix}.${editActionPrefix}.nameList.additional.condition.insertFailed`]: "插入失败",
    [`${settingPrefix}.${editActionPrefix}.nameList.additional.condition.updateFailed`]: "更新失败",
    [`${settingPrefix}.${editActionPrefix}.nameList.additional.condition.uploadFailed`]: "上传失败",

    [`${settingPrefix}.${editActionPrefix}.expanable.text.fieldList`]: "返回字段列表",
    [`${settingPrefix}.${editActionPrefix}.expanable.text.inputField`]: "输入字段列表",
    [`${settingPrefix}.${editActionPrefix}.expanable.text.additional`]: "额外的失败响应",

    [`${settingPrefix}.${editActionPrefix}.expanable.text.inputField.defaultValue`]: "默认值",
    [`${settingPrefix}.${editActionPrefix}.expanable.text.additional.response`]: "条件响应",

    [`${settingPrefix}.${editActionPrefix}.button.addFieldList`]: "添加返回字段",
    [`${settingPrefix}.${editActionPrefix}.button.addInputField`]: "添加输入字段",
    [`${settingPrefix}.${editActionPrefix}.button.addAdditional`]: "添加失败响应",

    [`${settingPrefix}.${editActionPrefix}.button.createAction`]: "创建动作",
    [`${settingPrefix}.${editActionPrefix}.button.updateAction`]: "更新操作",
    [`${settingPrefix}.${editActionPrefix}.button.editAction`]: "编辑操作",
    [`${settingPrefix}.${editActionPrefix}.button.cloneAction`]: "克隆操作",

    [`${settingPrefix}.${editActionPrefix}.duplicates.inputField`]: "输入字段名称已存在",
    [`${settingPrefix}.${editActionPrefix}.duplicates.additional`]: "条件已经存在",    
}

const editParameter = {
    [`${settingPrefix}.${editParameterPrefix}.text.title.edit`]: "编辑参数",
    [`${settingPrefix}.${editParameterPrefix}.text.title.add`]: "新参数",

    [`${settingPrefix}.${editParameterPrefix}.section.text.title.setup`]: "参数设置",
    [`${settingPrefix}.${editParameterPrefix}.section.text.subTitle.setup`]: "为聊天机器人自动化创建一个新参数",

    [`${settingPrefix}.${editParameterPrefix}.field.setup.key`]: "参数键",
    [`${settingPrefix}.${editParameterPrefix}.field.setup.type`]: "类型",
    [`${settingPrefix}.${editParameterPrefix}.field.setup.value`]: "值",

    [`${settingPrefix}.${editParameterPrefix}.nameList.type.input`]: "输入",
    [`${settingPrefix}.${editParameterPrefix}.nameList.type.keyword`]: "关键字",
    [`${settingPrefix}.${editParameterPrefix}.nameList.type.entity`]: "实体",
    [`${settingPrefix}.${editParameterPrefix}.nameList.type.option`]: "选项",
    [`${settingPrefix}.${editParameterPrefix}.nameList.type.media`]: "媒体",
    [`${settingPrefix}.${editParameterPrefix}.nameList.type.custom`]: "自定义",

    [`${settingPrefix}.${editParameterPrefix}.button.createParameter`]: "创建参数",
    [`${settingPrefix}.${editParameterPrefix}.button.updateParameter`]: "更新参数",
    [`${settingPrefix}.${editParameterPrefix}.button.editParameter`]: "编辑参数",
    [`${settingPrefix}.${editParameterPrefix}.button.cloneParameter`]: "克隆参数",
}

const openai = {
    [`${settingPrefix}.${openaiPrefix}.table.text.title`]: "OpenAI 机器人",
    [`${settingPrefix}.${openaiPrefix}.table.text.subTitle`]: "设置由 openAI 发起的自动对话。",
    [`${settingPrefix}.${openaiPrefix}.table.placeholder.search`]: "搜索机器人",

    [`${settingPrefix}.${openaiPrefix}.dropdown.lang.search`]: "搜索语言",
    [`${settingPrefix}.${openaiPrefix}.dropdown.lang.add`]: "添加语言",

    [`${settingPrefix}.${openaiPrefix}.modal.warning.remove`]: "确认删除这个机器人？它无法恢复。",
    [`${settingPrefix}.${openaiPrefix}.modal.warning.removeConfig`]: "确认删除这个支持的语言？它无法恢复。",

    [`${settingPrefix}.${openaiPrefix}.table.displayName.0`]: "机器人名称",
    [`${settingPrefix}.${openaiPrefix}.table.displayName.1`]: "支持的语言",

    [`${settingPrefix}.${openaiPrefix}.button.createBots`]: "创建机器人",

    [`${settingPrefix}.${openaiPrefix}.modal.page.text.title`]: "创建 OPENAI 机器人",
    [`${settingPrefix}.${openaiPrefix}.field.create.name`]: "机器人名称",    
}

const editOpenai = {
    [`${settingPrefix}.${editOpenaiPrefix}.flow.preview.title`]: "机器人预览",
    [`${settingPrefix}.${editOpenaiPrefix}.flow.preview.loading`]: "OpenAI 处理",

    [`${settingPrefix}.${editOpenaiPrefix}.button.preview`]: "预览机器人",

    [`${settingPrefix}.${editOpenaiPrefix}.progress.0`]: "常规配置",
    [`${settingPrefix}.${editOpenaiPrefix}.progress.1`]: "个性",
    [`${settingPrefix}.${editOpenaiPrefix}.progress.2`]: "服务",

    [`${settingPrefix}.${editOpenaiPrefix}.modal.warning`]: "您确定要退出创建配置吗？\n 将不会保存更改。",
    [`${settingPrefix}.${editOpenaiPrefix}.modal.warning.remove`]: "确认删除此服务？",

    [`${settingPrefix}.${editOpenaiPrefix}.section.text.title.config`]: "常规配置",
    [`${settingPrefix}.${editOpenaiPrefix}.section.text.subTitle.config`]: "OpenAI 聊天机器人的一般配置",

    [`${settingPrefix}.${editOpenaiPrefix}.section.text.title.personalities`]: "个性",
    [`${settingPrefix}.${editOpenaiPrefix}.section.text.subTitle.personalities`]: "设置 OpenAI 聊天机器人的个性",

    [`${settingPrefix}.${editOpenaiPrefix}.table.text.title`]: "服务",
    [`${settingPrefix}.${editOpenaiPrefix}.table.text.subTitle`]: "设置 OpenAI 聊天机器人的服务",

    [`${settingPrefix}.${editOpenaiPrefix}.table.displayName.0`]: "服务名称",
    [`${settingPrefix}.${editOpenaiPrefix}.table.displayName.1`]: "表名",
    [`${settingPrefix}.${editOpenaiPrefix}.table.displayName.2`]: "服务类型",   
    
    [`${settingPrefix}.${editOpenaiPrefix}.section.text.title.service.config`]: "服务通用配置",
    [`${settingPrefix}.${editOpenaiPrefix}.section.text.subTitle.service.config`]: "OpenAI 服务的一般配置",

    [`${settingPrefix}.${editOpenaiPrefix}.section.text.title.service.response`]: "服务响应配置",
    [`${settingPrefix}.${editOpenaiPrefix}.section.text.subTitle.service.response`]: "OpenAI 服务的响应配置",

    [`${settingPrefix}.${editOpenaiPrefix}.section.text.title.service.predicate`]: "谓词配置",
    [`${settingPrefix}.${editOpenaiPrefix}.section.text.subTitle.service.predicate`]: "OpenAI 服务的谓词配置",

    [`${settingPrefix}.${editOpenaiPrefix}.section.text.title.service.field`]: "字段配置",
    [`${settingPrefix}.${editOpenaiPrefix}.section.text.subTitle.service.field`]: "OpenAI 服务的字段配置",
    
    [`${settingPrefix}.${editOpenaiPrefix}.field.select.useExistingConfig`]: "使用现有配置",
    [`${settingPrefix}.${editOpenaiPrefix}.field.select.id`]: "聊天机器人配置",

    [`${settingPrefix}.${editOpenaiPrefix}.field.config.name`]: "配置名称",
    [`${settingPrefix}.${editOpenaiPrefix}.field.config.chatbotName`]: "AI 聊天机器人名称",
    [`${settingPrefix}.${editOpenaiPrefix}.field.config.coreTemplateId`]: "核心模板",
   
    [`${settingPrefix}.${editOpenaiPrefix}.field.personality.personality`]: "个性",

    [`${settingPrefix}.${editOpenaiPrefix}.text.title.edit`]: "编辑服务",
    [`${settingPrefix}.${editOpenaiPrefix}.text.title.add`]: "新服务",

    [`${settingPrefix}.${editOpenaiPrefix}.field.service.name`]: "服务名称",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.tableName`]: "表名",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.query`]: "额外查询",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.type`]: "服务类型",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.serviceTemplateEntryId`]: "服务模板入口",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.serviceTemplateId`]: "服务模板",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.successResponse`]: "成功响应",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.failedResponse`]: "响应失败",

    [`${settingPrefix}.${editOpenaiPrefix}.field.service.item.name`]: "显示名称",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.item.apiName`]: "API 名称",

    [`${settingPrefix}.${editOpenaiPrefix}.field.service.predicate.type`]: "谓词类型",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.predicate.operator`]: "谓词运算符",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.predicate.defaultValue`]: "默认值",

    [`${settingPrefix}.${editOpenaiPrefix}.field.service.field.valueType`]: "字段值类型",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.field.value`]: "选项",
    
    [`${settingPrefix}.${editOpenaiPrefix}.nameList.personality.polite`]: "礼貌",
    [`${settingPrefix}.${editOpenaiPrefix}.nameList.personality.proactive`]: "积极主动的",

    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.query`]: "查询记录",
    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.create`]: "创建记录",

    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.predicate.type.text`]: "文本",
    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.predicate.type.number`]: "数字",

    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.predicate.operator.equal`]: "等于",
    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.predicate.operator.greaterThan`]: "大于",
    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.predicate.operator.greaterThanOrEqualTo`]: "大于或等于",
    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.predicate.operator.lessThan`]: "小于",
    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.predicate.operator.lessThanOrEqualTo`]: "小于或等于",

    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.field.type.text`]: "文本",
    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.field.type.picklist`]: "选择列表",
    
    [`${settingPrefix}.${editOpenaiPrefix}.expanable.text.personality`]: "个性",
    [`${settingPrefix}.${editOpenaiPrefix}.expanable.text.service.field`]: "字段",
    [`${settingPrefix}.${editOpenaiPrefix}.expanable.text.service.predicate`]: "谓词",

    [`${settingPrefix}.${editOpenaiPrefix}.button.addPersonality`]: "添加个性",

    [`${settingPrefix}.${editOpenaiPrefix}.button.addPredicates`]: "添加谓词",
    [`${settingPrefix}.${editOpenaiPrefix}.button.addFields`]: "添加字段",
    [`${settingPrefix}.${editOpenaiPrefix}.button.addOption`]: "添加选项",
   
    [`${settingPrefix}.${editOpenaiPrefix}.button.addService`]: "添加服务",
    [`${settingPrefix}.${editOpenaiPrefix}.button.saveService`]: "保存服务",
    [`${settingPrefix}.${editOpenaiPrefix}.button.removeService`]: "删除服务",

    [`${settingPrefix}.${editOpenaiPrefix}.button.addServices`]: "添加服务",    
}

const livechatSetting = {
    [`${settingPrefix}.${livechatSettingPrefix}.page.text.title`]: "实时聊天设置",

    [`${settingPrefix}.${livechatSettingPrefix}.modal.warning`]: "确认删除这个聊天前表单？",

    [`${settingPrefix}.${livechatSettingPrefix}.modal.title.copy`]: "COPY PRE-CHAT SCRIPT",
    [`${settingPrefix}.${livechatSettingPrefix}.modal.subTitle.copy`]: "将此脚本添加到 <body></body>",
    
    [`${settingPrefix}.${livechatSettingPrefix}.tab.title.prechatForm`]: '聊天前表格',

    [`${settingPrefix}.${livechatSettingPrefix}.table.text.title`]: "聊天前表单设置",
    [`${settingPrefix}.${livechatSettingPrefix}.table.text.subTitle`]: "管理实时聊天机器人的预聊天表单",
    [`${settingPrefix}.${livechatSettingPrefix}.table.placeholder.search`]: "搜索表单",

    [`${settingPrefix}.${livechatSettingPrefix}.table.displayName.0`]: "表单名称",
    [`${settingPrefix}.${livechatSettingPrefix}.table.displayName.1`]: "语言",

    [`${settingPrefix}.${livechatSettingPrefix}.text.preview`]: "表单预览",
    [`${settingPrefix}.${livechatSettingPrefix}.text.preview.tilte`]: "Live Chat",
    [`${settingPrefix}.${livechatSettingPrefix}.text.preview.empty`]: "请草拟模板内容",
    [`${settingPrefix}.${livechatSettingPrefix}.text.preview.button`]: "开始聊天",

    [`${settingPrefix}.${livechatSettingPrefix}.button.addForms`]: "添加表单",

    [`${settingPrefix}.${livechatSettingPrefix}.modal.page.clone.text.title`]: "克隆聊天前表格",
    [`${settingPrefix}.${livechatSettingPrefix}.field.settings.name`]: "新表单名称",

}

const editLivechatSetting = {
    [`${settingPrefix}.${editLivechatSettingPrefix}.text.title.edit`]: "编辑聊天前表格",
    [`${settingPrefix}.${editLivechatSettingPrefix}.text.title.add`]: "添加聊天前表格",

    [`${settingPrefix}.${editLivechatSettingPrefix}.modal.warning`]: "确认删除该字段？",

    [`${settingPrefix}.${editLivechatSettingPrefix}.progress.0`]: "表单设置",
    [`${settingPrefix}.${editLivechatSettingPrefix}.progress.1`]: "表单内容",
    [`${settingPrefix}.${editLivechatSettingPrefix}.progress.2`]: "表单标签设置",
    [`${settingPrefix}.${editLivechatSettingPrefix}.progress.3`]: "表单显示频道",

    [`${settingPrefix}.${editLivechatSettingPrefix}.section.text.title.settings`]: "表单设置",
    [`${settingPrefix}.${editLivechatSettingPrefix}.section.text.subTitle.settings`]: "为聊天转移设置聊天前表单",

    [`${settingPrefix}.${editLivechatSettingPrefix}.section.text.title.content`]: "表单内容",
    [`${settingPrefix}.${editLivechatSettingPrefix}.section.text.subTitle.content`]: "为客户提交添加字段",

    [`${settingPrefix}.${editLivechatSettingPrefix}.section.text.title.label`]: "表单标签设置",
    [`${settingPrefix}.${editLivechatSettingPrefix}.section.text.subTitle.label`]: "设置聊天前表单标签",

    [`${settingPrefix}.${editLivechatSettingPrefix}.section.text.title.channel`]: "表单显示频道",
    [`${settingPrefix}.${editLivechatSettingPrefix}.section.text.subTitle.channel`]: "设置聊天前表单显示通道",
    
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.settings.name`]: "表单名称",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.settings.language`]: "语言",

    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.apiName`]: "字段 API",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.displayName`]: "显示名称",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.type`]: "字段类型",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.length`]: "文字长度",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.regex`]: "正则表达式",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.option`]: "选项",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.isName`]: "是名称字段",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.isRequired`]: "必填字段",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.errorMsg`]: "错误信息",
    
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.attachmentNotSupportWarning`]: "附件不支持警告",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.attachmentOverSizeWarning`]: "附件超大警告",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.attachmentPanelTitle`]: "附件面板标题",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.attachmentTooltip`]: "附件工具提示",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.cancelButton`]: "取消按钮",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.closeConversationTitle`]: "关闭对话标题",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.confirmButton`]: "确认按钮",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.conversationPanelTitle`]: "对话面板标题",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.conversationTitle`]: "对话标题",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.emojiTooltip`]: "表情工具提示",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.feedbackCommentTitle`]: "反馈评论标题",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.feedbackPanelTitle`]: "反馈面板标题",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.firstMessage`]: "第一条消息",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.invalidMessageInput`]: "无效消息输入",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.liveChatTitle`]: "实时聊天标题",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.messageSendFailedWarning`]: "消息发送失败警告",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.prechatRequiredFieldWarning`]: "预聊天必填字段警告",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.searchKnowledgeBasedTitle`]: "搜索基于知识的标题",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.serverReconnectingWarning`]: "服务器重新连接警告",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.serverUnavailableWarning`]: "服务器不可用警告",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.startConversationButton`]: "开始对话按钮",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.typeMessagePlaceholder`]: "键入消息占位符",
    
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.channel.type`]: "频道类型",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.channel.channelAccount`]: "频道账户",

    [`${settingPrefix}.${editLivechatSettingPrefix}.nameList.content.text`]: "文本",
    [`${settingPrefix}.${editLivechatSettingPrefix}.nameList.content.number`]: "数字",
    [`${settingPrefix}.${editLivechatSettingPrefix}.nameList.content.textarea`]: "文本区域",
    [`${settingPrefix}.${editLivechatSettingPrefix}.nameList.content.select`]: "下拉选择",
    [`${settingPrefix}.${editLivechatSettingPrefix}.nameList.content.checkbox`]: "复选框",
    [`${settingPrefix}.${editLivechatSettingPrefix}.nameList.content.link`]: "超链接",

    [`${settingPrefix}.${editLivechatSettingPrefix}.expanable.text.content`]: "表单内容",
    [`${settingPrefix}.${editLivechatSettingPrefix}.expanable.text.label`]: "表单标签",
    [`${settingPrefix}.${editLivechatSettingPrefix}.expanable.text.channel`]: "显示频道",
   
    [`${settingPrefix}.${editLivechatSettingPrefix}.expanable.text.content.item`]: "字段",
    [`${settingPrefix}.${editLivechatSettingPrefix}.expanable.text.channel.item`]: "频道",

    [`${settingPrefix}.${editLivechatSettingPrefix}.button.addField`]: "添加字段",
    [`${settingPrefix}.${editLivechatSettingPrefix}.button.addAccount`]: "添加频道帐户",

    [`${settingPrefix}.${editLivechatSettingPrefix}.button.addOption`]: "添加选项",    
}

export const setting = {
    ...layout,

    ...business,
    ...businessGeneralSetting,
    ...planSubscription,

    ...userList,

    ...system,
    ...systemGeneralSetting,
    ...fieldConfig,
    ...editfieldConfig,

    ...role,
    ...roleSetting,

    ...channelAccount,
    ...editChannelAccount,

    ...chatGroup,

    ...chatRouting,
    ...editChatRouting,

    ...message,
    ...quickText,
    ...editQuickText,

    ...template,
    ...editTemplate,

    ...systemMessage,
    ...editSystemMessage,

    ...tagging,
    ...wrapUp,

    ...chatAutomation,
    ...editChatAutomation,

    ...chatbot,
    ...createOrCloneChatbot,
    ...chatbotIconBar,
    ...editChatbot,
    ...editNode,
    ...editKeyword,
    ...editEntity,
    ...editResponse,
    ...editAction,
    ...editParameter,

    ...openai,
    ...editOpenai,

    ...livechatSetting,
    ...editLivechatSetting,
}