
import PropTypes from 'prop-types';
import React from 'react';
import { getChatFormatedFullDateTime } from 'util/helper';

const TimeItem = ({
    timestamp = 0,
    hideTime = false
}) => {
    let dateStr = getChatFormatedFullDateTime(timestamp);
    let dateList = dateStr.split(" ")

    return (
        <div title={`${dateList[0]}\n${dateList[1]}`} className="w-full h-full truncate flex flex-col justify-center">
            <div className="text-sm truncate">{dateList[0]}</div>
            {hideTime ? null : <div className="text-blue04 text-[10px] truncate">{dateList[1]}</div>}            
        </div>
    )
}

TimeItem.propTypes = {
    timestamp: PropTypes.number,
    hideTime: PropTypes.bool,
};

export default TimeItem;