import PropTypes from 'prop-types';
import { useEffect } from 'react';

const Position = Object.freeze(
    {
        "top": "top-[30px] left-1/2 -translate-x-1/2",
        "bottom": "bottom-[30px] left-1/2 -translate-x-1/2"
    }
)

const WarningSnackbar = ({
    open = false,
    position = Position.top,
    message = '',
    timeout = 5000,
    setOpen = null
}) => {
    
    useEffect(() => {
        if(message && open)
            setTimeout(() => setOpen(false), timeout);
    }, [message, open, setOpen, timeout])

    return (
        <div className={` text-sm fixed ${position} ${open ? "animate-[warning-fadein_0.5s,_warning-fadeout_0.5s_4.5s] visible z-[1200]" : "invisible -z-10"}`}>
            <div className={` bg-[#333] text-white p-4 flex max-w-[50vw] min-w-[250px]`}>
                {message}
            </div>
        </div>
    )
}

WarningSnackbar.propTypes = {
    open: PropTypes.bool,
    position: PropTypes.string,
    message: PropTypes.string,
    timeout: PropTypes.number,
    setOpen: PropTypes.func
}

export {
    WarningSnackbar,
    Position
};