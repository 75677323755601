import { createSlice } from '@reduxjs/toolkit'

const INIT_STATE = {
    focusedNode: null,
    selectedNode: null,
    nodeList: [],
    nodeExpandedMap: {},
    nodeMap: {},
    loading: false,
    error: null
}

const getSortedNodeFolderMap = (nodeList) => {
    let sortedNodeList = JSON.parse(JSON.stringify(nodeList));

    if(sortedNodeList.length > 0)
        sortedNodeList.sort((a, b) => b['name'].toLowerCase().localeCompare(a['name'].toLowerCase()))

    let nodeMap = {'ROOT_NODE': []}

    for(let node of sortedNodeList){
        if(node.preNodeId.length > 0){
            if(!nodeMap[node.preNodeId])
                nodeMap[node.preNodeId] = []

            nodeMap[node.preNodeId].push({
                id: node.id,
                name: node.name
            })
        }
        else{
            nodeMap['ROOT_NODE'].push({
                id: node.id,
                name: node.name
            })
        }
    }

    return nodeMap
}

const getNodeIndexFromId = (id, nodeList) => {
    return nodeList.findIndex((n) => n.id === id)
}

const getExpandedThroughSelectNode = (node, nodeList) => {
    if(!node || !node.id)
        return {}

    let nodeExpandedMap = {};

    let nextId = node.preNodeId;

    while(nextId){
        let index = getNodeIndexFromId(nextId, nodeList);

        if(index === -1)
            return {}

        let nextNode = nodeList[index]

        nodeExpandedMap[nextNode.id] = true
        nextId = nextNode.preNodeId
    }

    return nodeExpandedMap
}


const ChatbotNode = createSlice({
    name: 'ChatbotNode',
    initialState: INIT_STATE,
    reducers: {
        FOCUS_NODE: (state, action) => {
            state.focusedNode = action.payload;
            // state.nodeExpandedMap = {...state.nodeExpandedMap, ...getExpandedThroughSelectNode(action.payload, state.nodeList)}
        },
        SELECT_NODE: (state, action) => {
            state.selectedNode = action.payload;
            state.focusedNode = action.payload;
            // state.nodeExpandedMap = {...state.nodeExpandedMap, ...getExpandedThroughSelectNode(action.payload, state.nodeList)}
        },
        GET_NODES: (state) => {
            state.loading = true;
            state.error = null;
        },
        GET_NODES_SUCCESS: (state, action) => {
            state.loading = false;
            state.nodeList = action.payload;

            state.nodeMap = {...getSortedNodeFolderMap(action.payload)};

            if(state.selectedNode && !state.selectedNode.isCreate){
                let index = state.nodeList.findIndex(node => node.id === state.selectedNode)
                if(index === -1)
                    state.selectedNode = null

                state.nodeExpandedMap = getExpandedThroughSelectNode(action.payload, state.nodeList)
            }

            state.error = null;
        },
        GET_NODES_FAILURE: (state, action) => {
            state.loading = false;
            state.nodeList = [];
            state.nodeMap = {}
            state.error = action.payload.error;
        },

        CREATE_NODE: (state) => {
            state.loading = true;
            state.error = null;
        },
        CREATE_NODE_SUCCESS: (state, action) => {
            state.loading = false;
            state.nodeList.push(action.payload);

            let node = action.payload;
            let nodeMap = {...state.nodeMap};

            if(node.preNodeId.length > 0){
                if(!nodeMap[node.preNodeId])
                    nodeMap[node.preNodeId] = []
    
                nodeMap[node.preNodeId].push({
                    id: node.id,
                    name: node.name
                })
            }
            else{
                nodeMap['ROOT_NODE'].push({
                    id: node.id,
                    name: node.name
                })
            }

            state.nodeMap = nodeMap
            state.nodeExpandedMap = getExpandedThroughSelectNode(action.payload, state.nodeList)

            state.error = null;
        },
        CREATE_NODE_FAILURE: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
        },

        UPDATE_NODE: (state) => {
            state.loading = true;
            state.error = null;
        },
        UPDATE_NODE_SUCCESS: (state, action) => {
            state.loading = false;
            if(action.payload){
                let index = state.nodeList.findIndex(node => node.id === action.payload.id); 
    
                if(index !== -1)
                    state.nodeList[index] = action.payload

                let node = action.payload;
                let nodeMap = {...state.nodeMap};
    
                if(node.preNodeId.length > 0){
                    if(!nodeMap[node.preNodeId])
                        nodeMap[node.preNodeId] = []
        
                    nodeMap[node.preNodeId].push({
                        id: node.id,
                        name: node.name
                    })
                }
                else{
                    nodeMap['ROOT_NODE'].push({
                        id: node.id,
                        name: node.name
                    })
                }
    
                state.nodeMap = nodeMap
                state.nodeExpandedMap = getExpandedThroughSelectNode(action.payload, state.nodeList)
            }

            state.error = null;
        },
        UPDATE_NODE_FAILURE: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
        },

        CLONE_NODE: (state) => {
            state.loading = true;
            state.error = null;
        },
        CLONE_NODE_SUCCESS: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        CLONE_NODE_FAILURE: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
        },

        REMOVE_NODE: (state) => {
            state.loading = true;
            state.error = null;
        },
        REMOVE_NODE_SUCCESS: (state, action) => {
            state.loading = false;
            state.error = null;
            state.selectedNode = null;
            state.focusedNode = null;
        },
        REMOVE_NODE_FAILURE: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
        },

        EXPAND_NODE: (state, action) => {
            if(!!state.nodeExpandedMap[action.payload])
                state.nodeExpandedMap = {...state.nodeExpandedMap, [action.payload]: false}
            else
                state.nodeExpandedMap = {...state.nodeExpandedMap, [action.payload]: true}
        },

    }
})

export const {
    FOCUS_NODE,
    SELECT_NODE,
    GET_NODES,
    GET_NODES_FAILURE,
    GET_NODES_SUCCESS,
    CREATE_NODE,
    CREATE_NODE_FAILURE,
    CREATE_NODE_SUCCESS,
    UPDATE_NODE,
    UPDATE_NODE_FAILURE,
    UPDATE_NODE_SUCCESS,
    CLONE_NODE,
    CLONE_NODE_FAILURE,
    CLONE_NODE_SUCCESS,
    REMOVE_NODE,
    REMOVE_NODE_FAILURE,
    REMOVE_NODE_SUCCESS,
    EXPAND_NODE
} = ChatbotNode.actions;


export default ChatbotNode.reducer;

export const getFocusedNode = state => state.focusedNode;
export const getSelectedNode = state => state.selectedNode;
export const getNodeExpandedMap = state => state.nodeExpandedMap;
export const getNodeMap = state => state.nodeMap;
export const getNodeList = state => state.nodeList;
export const getLoading = state => state.loading;
export const getError = state => state.error;