import { generalPrefix } from "../prefix";

export const general = {
    [`${generalPrefix}.button.create`]: "創建",
    [`${generalPrefix}.button.update`]: "更新",
    [`${generalPrefix}.button.cancel`]: "取消",
    [`${generalPrefix}.button.clone`]: "克隆",
    [`${generalPrefix}.button.open`]: "打開",    
    [`${generalPrefix}.button.remove`]: "刪除",
    [`${generalPrefix}.button.delete`]: "刪除",
    [`${generalPrefix}.button.select`]: "選擇",
    [`${generalPrefix}.button.disable`]: "禁用",
    [`${generalPrefix}.button.confirm`]: "確認",
    [`${generalPrefix}.button.save`]: "保存更改",
    [`${generalPrefix}.button.reset`]: "重置",

    [`${generalPrefix}.button.closeCountdown`]: "關閉 ",

    [`${generalPrefix}.button.prev`]: "上一步",
    [`${generalPrefix}.button.next`]: "下一步",

    [`${generalPrefix}.textarea.placeholder`]: "在此輸入消息",
    
    [`${generalPrefix}.image.upload`]: "上傳",
    
    [`${generalPrefix}.modal.title.confirmation`]: "確認",
    [`${generalPrefix}.modal.title.reminder`]: "提醒",
    [`${generalPrefix}.modal.title.status`]: "狀態",
    
    [`${generalPrefix}.modal.title.editImage`]: "編輯圖像",

    [`${generalPrefix}.modal.content.chatTransfer`]: "聊天被轉移到",
    [`${generalPrefix}.modal.content.warning.quitEdit`]: `你確定要退出編輯嗎？\n 更改不會被保存。`,
    
    [`${generalPrefix}.modal.content.warning.redirect`]: `你將被重定向。`,

    [`${generalPrefix}.notice.copy`]: `已復製到剪貼板。`,

    [`${generalPrefix}.popout.text.moreTaggings`]: "更多標籤",

    [`${generalPrefix}.text.status`]: "狀態",
    [`${generalPrefix}.text.language`]: "語言",
    [`${generalPrefix}.text.period`]: "期間",
    [`${generalPrefix}.text.channels`]: "頻道",
    [`${generalPrefix}.text.role`]: "角色",
    [`${generalPrefix}.text.table`]: "表名",

    [`${generalPrefix}.text.edit`]: "編輯",
    [`${generalPrefix}.text.remove`]: "刪除",
    [`${generalPrefix}.text.search`]: "搜索",

    [`${generalPrefix}.filter.title.text`]: "FILTER",
    [`${generalPrefix}.filter.title.text.sorting`]: "排序",

    [`${generalPrefix}.dropdown.tags.search`]: "搜索標籤",
    [`${generalPrefix}.dropdown.tags.add`]: "添加標籤",
    [`${generalPrefix}.dropdown.tag.add`]: "添加標籤",
    [`${generalPrefix}.dropdown.tag.update`]: "更新標籤",
    [`${generalPrefix}.dropdown.tag.name`]: "命名標籤",
    [`${generalPrefix}.dropdown.tag.warn`]: "所有使用此標籤的線索或聯繫人也將更新。",

    [`${generalPrefix}.nameList.all`]: "全部",

    [`${generalPrefix}.nameList.status.active`]: "有效",
    [`${generalPrefix}.nameList.status.inactive`]: "無效",

    [`${generalPrefix}.nameList.channels.all`]: "所有頻道",
    [`${generalPrefix}.nameList.channels.livechat`]: "LiveChat",
    [`${generalPrefix}.nameList.channels.facebook`]: "Facebook",
    [`${generalPrefix}.nameList.channels.whatsapp`]: "WhatsApp",
    [`${generalPrefix}.nameList.channels.line`]: "LINE",
    [`${generalPrefix}.nameList.channels.wechat`]: "微信",

    [`${generalPrefix}.nameList.language.all`]: "所有語言",
    [`${generalPrefix}.nameList.language.english`]: "英語",
    [`${generalPrefix}.nameList.language.traditionalChinese`]: "繁體中文",
    [`${generalPrefix}.nameList.language.simplifiedChinese`]: "簡體中文",

    [`${generalPrefix}.nameList.table.contact`]: "聯繫人",
    [`${generalPrefix}.nameList.table.ticket`]: "門票",

    [`${generalPrefix}.nameList.role.agent`]: "代理",
    [`${generalPrefix}.nameList.role.supervisor`]: "主管",
    [`${generalPrefix}.nameList.role.admin`]: "管理員",

    [`${generalPrefix}.progress.last`]: "確認提交",

    [`${generalPrefix}.status.empty`]: "N/A",
    
    [`${generalPrefix}.templateMessage.preview.title`]: "模板預覽",
    [`${generalPrefix}.templateMessage.config.para`]: "參數",
    [`${generalPrefix}.templateMessage.button.send`]: "發送消息",    
}