import {
    VAILDATE_USER_FAILURE,
    VAILDATE_USER_SUCCESS
} from 'store/reducers/auth/user';

export const setToken = (token, callback) => (dispatch)  => {
    dispatch(VAILDATE_USER_SUCCESS(token));
    callback()
}

export const handleAuthError = () => (dispatch, getState) => {
    dispatch(VAILDATE_USER_FAILURE({error: 'Vaildation Error'}));
}