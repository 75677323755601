import {httpPost} from 'lib/requests';

const getAction = (data, authToken) => {
    return httpPost('chatbot/action/get', data, authToken);
}

const bulkAction = (data, authToken) => {
    return httpPost('chatbot/action/bulk', data, authToken);
}

export {
    getAction,
    bulkAction,
};