import PropTypes from "prop-types";
import React from 'react';

import { TextSize } from 'components/CusRoundedInput';
import { CusToggleSwitch, LabelContentPaddingType, PaddingType, SilderSize, ToogleSize } from ".";

const CusLabelSwitch = React.forwardRef(({
  label = "",
  oneLine = false,
  checked = false,
  readOnly = false,
  disabled = false,
  placeholder = "",
  showSwitchOnDisabled = false,
  onChange = null,
  ...rest}, ref) => 
  (
    oneLine ?
      <div className="py-2 w-full flex justify-between items-center">
          <span className="text-greyblack">{label}</span>
          <CusToggleSwitch
            ref={ref}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            showSwitchOnDisabled={showSwitchOnDisabled}
            readOnly={readOnly}
            paddingType={PaddingType.flat}
            textSize={TextSize.flat}
            toogleSize={ToogleSize.small}
            silderSize={SilderSize.small}
            {...rest}
          />
      </div>
    :
    <CusToggleSwitch
      ref={ref}
      label={label}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      showSwitchOnDisabled={showSwitchOnDisabled}
      readOnly={readOnly}
      placeholder={placeholder}
      paddingType={PaddingType.flat}
      textSize={TextSize.flat}
      toogleSize={ToogleSize.small}
      silderSize={SilderSize.small}
      {...rest}
    />    
  )
);

CusLabelSwitch.propTypes = {
    label: PropTypes.string,
    oneLine: PropTypes.bool,
    checked: PropTypes.bool,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    showSwitchOnDisabled: PropTypes.bool,
    onChange: PropTypes.func,
  }

export default CusLabelSwitch;