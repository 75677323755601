const AnyText = (rest) => {
    return(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M8.63158 12.0606C8.83775 12.0606 9.01347 11.9881 9.15874 11.8429C9.30386 11.6977 9.37642 11.522 9.37642 11.3158C9.37642 11.1096 9.30386 10.9339 9.15874 10.7886C9.01347 10.6435 8.83775 10.5709 8.63158 10.5709C8.4254 10.5709 8.24968 10.6435 8.10442 10.7886C7.9593 10.9339 7.88674 11.1096 7.88674 11.3158C7.88674 11.522 7.9593 11.6977 8.10442 11.8429C8.24968 11.9881 8.4254 12.0606 8.63158 12.0606ZM12 12.0606C12.2062 12.0606 12.3819 11.9881 12.5272 11.8429C12.6723 11.6977 12.7448 11.522 12.7448 11.3158C12.7448 11.1096 12.6723 10.9339 12.5272 10.7886C12.3819 10.6435 12.2062 10.5709 12 10.5709C11.7938 10.5709 11.6181 10.6435 11.4728 10.7886C11.3277 10.9339 11.2552 11.1096 11.2552 11.3158C11.2552 11.522 11.3277 11.6977 11.4728 11.8429C11.6181 11.9881 11.7938 12.0606 12 12.0606ZM15.3684 12.0606C15.5746 12.0606 15.7503 11.9881 15.8956 11.8429C16.0407 11.6977 16.1133 11.522 16.1133 11.3158C16.1133 11.1096 16.0407 10.9339 15.8956 10.7886C15.7503 10.6435 15.5746 10.5709 15.3684 10.5709C15.1622 10.5709 14.9865 10.6435 14.8413 10.7886C14.6961 10.9339 14.6236 11.1096 14.6236 11.3158C14.6236 11.522 14.6961 11.6977 14.8413 11.8429C14.9865 11.9881 15.1622 12.0606 15.3684 12.0606ZM6.97979 17.6316L5.29389 19.3173C5.05432 19.557 4.77874 19.6113 4.46716 19.4802C4.15572 19.349 4 19.1128 4 18.7716V6.52232C4 6.09691 4.14737 5.73684 4.44211 5.44211C4.73684 5.14737 5.09691 5 5.52232 5H18.4777C18.9031 5 19.2632 5.14737 19.5579 5.44211C19.8526 5.73684 20 6.09691 20 6.52232V16.1093C20 16.5347 19.8526 16.8947 19.5579 17.1895C19.2632 17.4842 18.9031 17.6316 18.4777 17.6316H6.97979ZM6.44211 16.3684H18.4777C18.5425 16.3684 18.6019 16.3414 18.6558 16.2874C18.7098 16.2335 18.7368 16.1741 18.7368 16.1093V6.52232C18.7368 6.45747 18.7098 6.3981 18.6558 6.34421C18.6019 6.29017 18.5425 6.26316 18.4777 6.26316H5.52232C5.45747 6.26316 5.39811 6.29017 5.34421 6.34421C5.29018 6.3981 5.26316 6.45747 5.26316 6.52232V17.5345L6.44211 16.3684Z" fill="#64748B"/>
            <path d="M8.63158 12.0606C8.83775 12.0606 9.01347 11.9881 9.15874 11.8429C9.30386 11.6977 9.37642 11.522 9.37642 11.3158C9.37642 11.1096 9.30386 10.9339 9.15874 10.7886C9.01347 10.6435 8.83775 10.5709 8.63158 10.5709C8.4254 10.5709 8.24968 10.6435 8.10442 10.7886C7.9593 10.9339 7.88674 11.1096 7.88674 11.3158C7.88674 11.522 7.9593 11.6977 8.10442 11.8429C8.24968 11.9881 8.4254 12.0606 8.63158 12.0606ZM12 12.0606C12.2062 12.0606 12.3819 11.9881 12.5272 11.8429C12.6723 11.6977 12.7448 11.522 12.7448 11.3158C12.7448 11.1096 12.6723 10.9339 12.5272 10.7886C12.3819 10.6435 12.2062 10.5709 12 10.5709C11.7938 10.5709 11.6181 10.6435 11.4728 10.7886C11.3277 10.9339 11.2552 11.1096 11.2552 11.3158C11.2552 11.522 11.3277 11.6977 11.4728 11.8429C11.6181 11.9881 11.7938 12.0606 12 12.0606ZM15.3684 12.0606C15.5746 12.0606 15.7503 11.9881 15.8956 11.8429C16.0407 11.6977 16.1133 11.522 16.1133 11.3158C16.1133 11.1096 16.0407 10.9339 15.8956 10.7886C15.7503 10.6435 15.5746 10.5709 15.3684 10.5709C15.1622 10.5709 14.9865 10.6435 14.8413 10.7886C14.6961 10.9339 14.6236 11.1096 14.6236 11.3158C14.6236 11.522 14.6961 11.6977 14.8413 11.8429C14.9865 11.9881 15.1622 12.0606 15.3684 12.0606ZM6.97979 17.6316L5.29389 19.3173C5.05432 19.557 4.77874 19.6113 4.46716 19.4802C4.15572 19.349 4 19.1128 4 18.7716V6.52232C4 6.09691 4.14737 5.73684 4.44211 5.44211C4.73684 5.14737 5.09691 5 5.52232 5H18.4777C18.9031 5 19.2632 5.14737 19.5579 5.44211C19.8526 5.73684 20 6.09691 20 6.52232V16.1093C20 16.5347 19.8526 16.8947 19.5579 17.1895C19.2632 17.4842 18.9031 17.6316 18.4777 17.6316H6.97979ZM6.44211 16.3684H18.4777C18.5425 16.3684 18.6019 16.3414 18.6558 16.2874C18.7098 16.2335 18.7368 16.1741 18.7368 16.1093V6.52232C18.7368 6.45747 18.7098 6.3981 18.6558 6.34421C18.6019 6.29017 18.5425 6.26316 18.4777 6.26316H5.52232C5.45747 6.26316 5.39811 6.29017 5.34421 6.34421C5.29018 6.3981 5.26316 6.45747 5.26316 6.52232V17.5345L6.44211 16.3684Z" fill="black" fillOpacity="0.2"/>
            <path d="M8.63158 12.0606C8.83775 12.0606 9.01347 11.9881 9.15874 11.8429C9.30386 11.6977 9.37642 11.522 9.37642 11.3158C9.37642 11.1096 9.30386 10.9339 9.15874 10.7886C9.01347 10.6435 8.83775 10.5709 8.63158 10.5709C8.4254 10.5709 8.24968 10.6435 8.10442 10.7886C7.9593 10.9339 7.88674 11.1096 7.88674 11.3158C7.88674 11.522 7.9593 11.6977 8.10442 11.8429C8.24968 11.9881 8.4254 12.0606 8.63158 12.0606ZM12 12.0606C12.2062 12.0606 12.3819 11.9881 12.5272 11.8429C12.6723 11.6977 12.7448 11.522 12.7448 11.3158C12.7448 11.1096 12.6723 10.9339 12.5272 10.7886C12.3819 10.6435 12.2062 10.5709 12 10.5709C11.7938 10.5709 11.6181 10.6435 11.4728 10.7886C11.3277 10.9339 11.2552 11.1096 11.2552 11.3158C11.2552 11.522 11.3277 11.6977 11.4728 11.8429C11.6181 11.9881 11.7938 12.0606 12 12.0606ZM15.3684 12.0606C15.5746 12.0606 15.7503 11.9881 15.8956 11.8429C16.0407 11.6977 16.1133 11.522 16.1133 11.3158C16.1133 11.1096 16.0407 10.9339 15.8956 10.7886C15.7503 10.6435 15.5746 10.5709 15.3684 10.5709C15.1622 10.5709 14.9865 10.6435 14.8413 10.7886C14.6961 10.9339 14.6236 11.1096 14.6236 11.3158C14.6236 11.522 14.6961 11.6977 14.8413 11.8429C14.9865 11.9881 15.1622 12.0606 15.3684 12.0606ZM6.97979 17.6316L5.29389 19.3173C5.05432 19.557 4.77874 19.6113 4.46716 19.4802C4.15572 19.349 4 19.1128 4 18.7716V6.52232C4 6.09691 4.14737 5.73684 4.44211 5.44211C4.73684 5.14737 5.09691 5 5.52232 5H18.4777C18.9031 5 19.2632 5.14737 19.5579 5.44211C19.8526 5.73684 20 6.09691 20 6.52232V16.1093C20 16.5347 19.8526 16.8947 19.5579 17.1895C19.2632 17.4842 18.9031 17.6316 18.4777 17.6316H6.97979ZM6.44211 16.3684H18.4777C18.5425 16.3684 18.6019 16.3414 18.6558 16.2874C18.7098 16.2335 18.7368 16.1741 18.7368 16.1093V6.52232C18.7368 6.45747 18.7098 6.3981 18.6558 6.34421C18.6019 6.29017 18.5425 6.26316 18.4777 6.26316H5.52232C5.45747 6.26316 5.39811 6.29017 5.34421 6.34421C5.29018 6.3981 5.26316 6.45747 5.26316 6.52232V17.5345L6.44211 16.3684Z" fill="black" fillOpacity="0.2"/>
            <path d="M8.63158 12.0606C8.83775 12.0606 9.01347 11.9881 9.15874 11.8429C9.30386 11.6977 9.37642 11.522 9.37642 11.3158C9.37642 11.1096 9.30386 10.9339 9.15874 10.7886C9.01347 10.6435 8.83775 10.5709 8.63158 10.5709C8.4254 10.5709 8.24968 10.6435 8.10442 10.7886C7.9593 10.9339 7.88674 11.1096 7.88674 11.3158C7.88674 11.522 7.9593 11.6977 8.10442 11.8429C8.24968 11.9881 8.4254 12.0606 8.63158 12.0606ZM12 12.0606C12.2062 12.0606 12.3819 11.9881 12.5272 11.8429C12.6723 11.6977 12.7448 11.522 12.7448 11.3158C12.7448 11.1096 12.6723 10.9339 12.5272 10.7886C12.3819 10.6435 12.2062 10.5709 12 10.5709C11.7938 10.5709 11.6181 10.6435 11.4728 10.7886C11.3277 10.9339 11.2552 11.1096 11.2552 11.3158C11.2552 11.522 11.3277 11.6977 11.4728 11.8429C11.6181 11.9881 11.7938 12.0606 12 12.0606ZM15.3684 12.0606C15.5746 12.0606 15.7503 11.9881 15.8956 11.8429C16.0407 11.6977 16.1133 11.522 16.1133 11.3158C16.1133 11.1096 16.0407 10.9339 15.8956 10.7886C15.7503 10.6435 15.5746 10.5709 15.3684 10.5709C15.1622 10.5709 14.9865 10.6435 14.8413 10.7886C14.6961 10.9339 14.6236 11.1096 14.6236 11.3158C14.6236 11.522 14.6961 11.6977 14.8413 11.8429C14.9865 11.9881 15.1622 12.0606 15.3684 12.0606ZM6.97979 17.6316L5.29389 19.3173C5.05432 19.557 4.77874 19.6113 4.46716 19.4802C4.15572 19.349 4 19.1128 4 18.7716V6.52232C4 6.09691 4.14737 5.73684 4.44211 5.44211C4.73684 5.14737 5.09691 5 5.52232 5H18.4777C18.9031 5 19.2632 5.14737 19.5579 5.44211C19.8526 5.73684 20 6.09691 20 6.52232V16.1093C20 16.5347 19.8526 16.8947 19.5579 17.1895C19.2632 17.4842 18.9031 17.6316 18.4777 17.6316H6.97979ZM6.44211 16.3684H18.4777C18.5425 16.3684 18.6019 16.3414 18.6558 16.2874C18.7098 16.2335 18.7368 16.1741 18.7368 16.1093V6.52232C18.7368 6.45747 18.7098 6.3981 18.6558 6.34421C18.6019 6.29017 18.5425 6.26316 18.4777 6.26316H5.52232C5.45747 6.26316 5.39811 6.29017 5.34421 6.34421C5.29018 6.3981 5.26316 6.45747 5.26316 6.52232V17.5345L6.44211 16.3684Z" fill="black" fillOpacity="0.2"/>
        </svg>
    )
}

export default AnyText;