import { infoPrefix } from "../prefix";

export const info = {
    [`${infoPrefix}.forgotPassword.success`]: "忘記密碼成功，臨時密碼已經發送到您的郵箱",
    [`${infoPrefix}.forgotPassword.failed`]: "忘記密碼失敗",

    [`${infoPrefix}.signup.success`]: "創建賬號成功，臨時密碼已發送至您的郵箱",
    [`${infoPrefix}.signup.failed`]: "創建賬戶失敗",

    [`${infoPrefix}.template.send.success`]: "模板消息發送成功",
    [`${infoPrefix}.template.send.failed`]: "模板消息發送失敗",
 }