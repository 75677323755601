import { generalPrefix } from "../prefix";

export const general = {
    [`${generalPrefix}.button.create`]: "创建",
    [`${generalPrefix}.button.update`]: "更新",
    [`${generalPrefix}.button.cancel`]: "取消",
    [`${generalPrefix}.button.clone`]: "克隆",
    [`${generalPrefix}.button.copy`]: "复制",
    [`${generalPrefix}.button.remove`]: "删除",
    [`${generalPrefix}.button.delete`]: "删除",
    [`${generalPrefix}.button.select`]: "选择",
    [`${generalPrefix}.button.disable`]: "禁用",
    [`${generalPrefix}.button.confirm`]: "确认",
    [`${generalPrefix}.button.save`]: "保存更改",
    [`${generalPrefix}.button.reset`]: "重置",

    [`${generalPrefix}.button.closeCountdown`]: "关闭 ",
    
    [`${generalPrefix}.button.prev`]: "上一步",
    [`${generalPrefix}.button.next`]: "下一步",
    
    [`${generalPrefix}.textarea.placeholder`]: "在此输入消息",
    
    [`${generalPrefix}.image.upload`]: "上传",

    [`${generalPrefix}.modal.title.confirmation`]: "确认",
    [`${generalPrefix}.modal.title.reminder`]: "提醒",
    [`${generalPrefix}.modal.title.status`]: "状态",

    [`${generalPrefix}.modal.title.editImage`]: "编辑图像",

    [`${generalPrefix}.modal.content.chatTransfer`]: "聊天被转移到",
    [`${generalPrefix}.modal.content.warning.quitEdit`]: `你确定要退出编辑吗？\n 更改不会被保存。`,
    
    [`${generalPrefix}.modal.content.warning.redirect`]: `你将被重定向。`,

    [`${generalPrefix}.notice.copy`]: `已复制到剪贴板。`,

    [`${generalPrefix}.popout.text.moreTaggings`]: "更多标签",

    [`${generalPrefix}.text.status`]: "状态",
    [`${generalPrefix}.text.language`]: "语言",
    [`${generalPrefix}.text.period`]: "期间",
    [`${generalPrefix}.text.channels`]: "频道",
    [`${generalPrefix}.text.role`]: "角色",
    [`${generalPrefix}.text.table`]: "表名",

    [`${generalPrefix}.text.edit`]: "编辑",
    [`${generalPrefix}.text.remove`]: "删除",
    [`${generalPrefix}.text.search`]: "搜索",

    [`${generalPrefix}.filter.title.text`]: "FILTER",
    [`${generalPrefix}.filter.title.text.sorting`]: "排序",

    [`${generalPrefix}.dropdown.tags.search`]: "搜索标签",
    [`${generalPrefix}.dropdown.tags.add`]: "添加标签",
    [`${generalPrefix}.dropdown.tag.add`]: "添加标签",
    [`${generalPrefix}.dropdown.tag.update`]: "更新标签",
    [`${generalPrefix}.dropdown.tag.name`]: "命名标签",
    [`${generalPrefix}.dropdown.tag.warn`]: "所有使用此标签的线索或联系人也将更新。",

    [`${generalPrefix}.nameList.all`]: "全部",

    [`${generalPrefix}.nameList.status.active`]: "有效",
    [`${generalPrefix}.nameList.status.inactive`]: "无效",

    [`${generalPrefix}.nameList.channels.all`]: "所有频道",
    [`${generalPrefix}.nameList.channels.livechat`]: "LiveChat",
    [`${generalPrefix}.nameList.channels.facebook`]: "Facebook",
    [`${generalPrefix}.nameList.channels.whatsapp`]: "WhatsApp",
    [`${generalPrefix}.nameList.channels.line`]: "LINE",
    [`${generalPrefix}.nameList.channels.wechat`]: "微信",

    [`${generalPrefix}.nameList.language.all`]: "所有语言",
    [`${generalPrefix}.nameList.language.english`]: "英语",
    [`${generalPrefix}.nameList.language.traditionalChinese`]: "繁体中文",
    [`${generalPrefix}.nameList.language.simplifiedChinese`]: "简体中文",

    [`${generalPrefix}.nameList.table.contact`]: "联系人",
    [`${generalPrefix}.nameList.table.ticket`]: "门票",

    [`${generalPrefix}.nameList.role.agent`]: "代理",
    [`${generalPrefix}.nameList.role.supervisor`]: "主管",
    [`${generalPrefix}.nameList.role.admin`]: "管理员",

    [`${generalPrefix}.progress.last`]: "确认提交",

    [`${generalPrefix}.status.empty`]: "N/A",
    
    [`${generalPrefix}.templateMessage.preview.title`]: "模板预览",
    [`${generalPrefix}.templateMessage.config.para`]: "参数",
    [`${generalPrefix}.templateMessage.button.send`]: "发送消息",    
}