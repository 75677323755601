import { errorPrefix } from "../prefix";

export const errors = {
    [`${errorPrefix}.defaulterror.default`]: "Unexpected Error. Please try again later",
    [`${errorPrefix}.10001`]: "Username and password does not match the policy",
    [`${errorPrefix}.10002`]: "Invalid username and password",
    [`${errorPrefix}.10003`]: "User is not confirmed",
    [`${errorPrefix}.10004`]: "Username already exist",
    [`${errorPrefix}.10005`]: "Confirmation code is expired",
    [`${errorPrefix}.10006`]: "Confirmation code is not matched",
    [`${errorPrefix}.10007`]: "Member is not activated",
    [`${errorPrefix}.10008`]: "User does not exist",
    [`${errorPrefix}.10009`]: "New Password Required",
    [`${errorPrefix}.10010`]: "Session not found",
    [`${errorPrefix}.10011`]: "User is disabled. Please contact admin",
    [`${errorPrefix}.10012`]: "Password attempts exceeded. Please try again later",
    [`${errorPrefix}.10013`]: "User Expired. Please change password",
 
    [`${errorPrefix}.permission`]: "您沒有訪問權限",
    [`${errorPrefix}.getUserSession`]: "獲取用戶會話失敗",
    [`${errorPrefix}.updateUser`]: "更新用戶失敗",
    [`${errorPrefix}.updateUserSession`]: "更新用戶會話失敗",
    [`${errorPrefix}.updatePassword`]: "更新密碼失敗",
    [`${errorPrefix}.updateUserImage`]: "更新用戶圖像失敗",
        
    [`${errorPrefix}.required`]: "是必須的",
    [`${errorPrefix}.invalid`]: "無效的",
    [`${errorPrefix}.passwordMismatch`]: "您的密碼不匹配",

    [`${errorPrefix}.requiredField`]: "必填字段",
    [`${errorPrefix}.requiredOption`]: "至少應選擇一個選項",
    [`${errorPrefix}.invalidOption`]: "請選擇一個有效的選項",
    [`${errorPrefix}.invalidNumber`]: "字段不是數字",
    [`${errorPrefix}.maxNumber`]: "最大值：",
    [`${errorPrefix}.minNumber`]: "最小值：",
    [`${errorPrefix}.maxNumberChars`]: "最大整數字符數：",
    [`${errorPrefix}.maxDecimalPlace`]: "最大小數位數：",
    [`${errorPrefix}.invalidRegex`]: "正則表達式條件不匹配",
    [`${errorPrefix}.invalidRegex.safeRegex`]: "字段只能包含字母數字、空格或下劃線",
    [`${errorPrefix}.maxChars`]: "最大字符數：",
    [`${errorPrefix}.invalidDateRange`]: "日期超出範圍",
    [`${errorPrefix}.invalidDateTimeRange`]: "日期和時間超出範圍",
    [`${errorPrefix}.duplicates`]: "請刪除重複項",

    [`${errorPrefix}.invalid.file`]: "無效文件",
    [`${errorPrefix}.invalid.file.size`]: "文件大小超出支持限制",
    [`${errorPrefix}.invalid.image`]: "無效的圖像格式",

    [`${errorPrefix}.no.image`]: "找不到圖片",
 }