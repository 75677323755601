import React, { useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

import { useDefaultPageLoading } from 'hooks/pageConditions/useDefaultPageLoading';

import LoadingOverlay from 'components/Overlay/LoadingOverlay';
import { useIsLoggedIn } from 'hooks/user/useIsLoggedIn';

const PluginLayout = () => {
    const loading = useDefaultPageLoading();

    return( 
        <div className="relative overflow-hidden flex flex-col md:flex-row">
            <div className="flex flex-col md:flex-col w-full">
                <div className="relative flex w-full h-[100vh] select-none">
                    <LoadingOverlay show={loading}/>
                    <Scrollbars
                        autoHide
                        autoHideDuration={100}
                    >
                        <div className=" bg-[#F6F6F6] h-full w-full">
                            {/* <Route path={`signin`} element={Login} /> */}
                            <Outlet/>
                        </div>
                    </Scrollbars>
                </div>
            </div>
        </div>
    );
};
export default PluginLayout;