import PropTypes from "prop-types";
import React, { useState } from 'react';

import { CusInputError } from "components/CusInputError";
import { BorderType as InputBorderType } from 'components/CusRoundedInput';
import { BorderType as SelectBorderType } from 'components/CusRoundedSelect';
import { BorderType as TextAreaBorderType } from 'components/CusRoundedTextArea';
import CusLabelRoundedInput from "components/CusRoundedInput/CusLabelRoundedInput";
import CusLabelRoundedSelect from "components/CusRoundedSelect/CusLabelRoundedSelect";
import CusLabelRoundedTextArea from "components/CusRoundedTextArea/CusLabelRoundedTextArea";
import CusLabelCheckBox from "components/CusCheckBox/CusLabelCheckBox";
import CusLabelSwitch from "components/CusToggleSwitch/CusLabelSwitch";
import { currentTimestamp, isNumeric, matchRegex } from "util/helper";
import CusMultiSelectSwitch from "components/CusToggleSwitch/CusMultiSelectSwitch";
import CusMultiSelectCheckBox from "components/CusCheckBox/CusMultiSelectCheckBox";
import { Color as LinkColor, CusLink, TextSize as LinkTextSize } from 'components/CusLink';
import CusLabelRoundedDatePicker from "components/CusRoundedDatePicker/CusLabelRoundedDatePicker";
import { PickerType } from "components/CusRoundedDatePicker";
import moment from "moment";

import { errorPrefix } from "lang/locales/prefix"
import { CusUnderlineInput } from "components/CusUnderlineInput";
import { CusUnderlineTextArea } from "components/CusUnderlineTextArea";
import { CusUnderlineSelect } from "components/CusUnderlineSelect";

export const CONTAINER_STYLE = {
    "ROUNDED": "ROUNDED",
    "UNDERLINE": "UNDERLINE",
}

export const EDITABLE_TYPE = {
    "TEXT" : "TEXT",
    "PASSWORD" : "PASSWORD",
    "RUNNING_NUMBER": "RUNNING_NUMBER",
    "EMAIL" : "EMAIL",
    "PHONE_NUMBER" : "PHONE_NUMBER",
    "NUMBER" : "NUMBER",
    "TEXTAREA" : "TEXTAREA",
    "PICKLIST" : "PICKLIST",
    "MULTI_PICKLIST" : "MULTI_PICKLIST",
    "MULTI_PICKLIST_SWITCH" : "MULTI_PICKLIST_SWITCH",
    "BOOLEAN" : "BOOLEAN",
    "BOOLEAN_CHECKBOX" : "BOOLEAN_CHECKBOX",
    "DATE" : "DATE",
    "DATE_TIME" : "DATE_TIME",
    "LINK": "LINK"
}

export const getNumberPattern = (field) => {
    switch (field.fieldType) {
        case EDITABLE_TYPE.PHONE_NUMBER:
            return field.validationRegex
        case EDITABLE_TYPE.RUNNING_NUMBER:
            return field.format
        default:
            return undefined
    }
}

export const setDefaultField = (field, fieldMap, fieldName, isCreate) => {
    if(isCreate && !field.creatable)
        return
        
    if(!isCreate && !field.editable)
        return

    switch (field.fieldType) {
        case EDITABLE_TYPE.MULTI_PICKLIST:
            fieldMap[fieldName] = [];
            if(!!field.defaultValue)
                fieldMap[fieldName].push(field.defaultValue)            
            break;
        default:
            fieldMap[fieldName] = field.defaultValue
            break;
    }
}

export const validatePicklistField = (field, options, value, errorFormater, isCreate, defaultErrorMsg) => {
    if(field.fieldType !== EDITABLE_TYPE.PICKLIST)
        return {}

    let errorMap = {}
    errorMap[field.fieldName] = {}

    if(field.required && (!value || value.length < 1)){
        errorMap[field.fieldName].message = errorFormater({ "id": `${errorPrefix}.requiredOption`})

        if(defaultErrorMsg)
            errorMap[field.fieldName].message = defaultErrorMsg

        return errorMap;
    }

    if(!value)
        return {}

    let values = options.map(option => option.value);

    if(values.includes(value))
        return {}

    errorMap[field.fieldName].message = errorFormater({ "id": `${errorPrefix}.invalidOption`})

    if(defaultErrorMsg)
        errorMap[field.fieldName].message = defaultErrorMsg

    return errorMap;
}

export const validateField = (field, value, errorFormater, isCreate, defaultErrorMsg) => {
    const now = currentTimestamp();
    const maxDate = moment(now).startOf('day').add(20, 'y').year()
    
    if(isCreate && !field.creatable)
        return {}
        
    if(!isCreate && !field.editable)
        return {}            

    let errorMap = {}
    errorMap[field.fieldName] = {}

    if(field.required && (!value || value.length < 1)){
        errorMap[field.fieldName].message = (field.fieldType === EDITABLE_TYPE.MULTI_PICKLIST) ? errorFormater({ "id": `${errorPrefix}.requiredOption`}) : errorFormater({ "id": `${errorPrefix}.requiredField`})

        if(defaultErrorMsg)
            errorMap[field.fieldName].message = defaultErrorMsg

        return errorMap;
    }

    if(!value || value.length < 1)
        return {}

    switch (field.fieldType) {
        case EDITABLE_TYPE.NUMBER:
            if(!isNumeric(value)){
                errorMap[field.fieldName].message = defaultErrorMsg ?? errorFormater({ "id": `${errorPrefix}.invalidNumber`})
                return errorMap;
            }

            if(field.maxNum && parseFloat(value) > field.maxNum){
                errorMap[field.fieldName].message = defaultErrorMsg ?? `${errorFormater({ "id": `${errorPrefix}.maxNumber`})}${field.maxNum}`
                return errorMap;
            }
            if(field.minNum && parseFloat(value) < field.minNum){
                errorMap[field.fieldName].message = defaultErrorMsg ?? `${errorFormater({ "id": `${errorPrefix}.minNumber`})}${field.minNum}`
                return errorMap;
            }

            if(value.includes(".")){
                let valueList = value.split(".");

                console.log(valueList)

                if(field.length && valueList[0].length > field.length){
                    errorMap[field.fieldName].message = defaultErrorMsg ?? `${errorFormater({ "id": `${errorPrefix}.maxNumberChars`})}${field.length}`
                    return errorMap;
                }

                if(field.decimalPlace >= 0 && valueList[1].length > field.decimalPlace){
                    errorMap[field.fieldName].message = defaultErrorMsg ?? `${errorFormater({ "id": `${errorPrefix}.maxDecimalPlace`})}${field.decimalPlace}`
                    return errorMap;
                }
            }
            else{
                if(field.length && value.length > field.length){
                    errorMap[field.fieldName].message = defaultErrorMsg ?? `${errorFormater({ "id": `${errorPrefix}.maxNumberChars`})}${field.length}`
                    return errorMap;
                }
            }

            break;            
        case EDITABLE_TYPE.EMAIL:
            if(!matchRegex("^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6}$", value)){
                // errorMap[field.fieldName].message = `Failed regex condition: ${field.validationRegex}`
                errorMap[field.fieldName].message = defaultErrorMsg ?? errorFormater({ "id": `${errorPrefix}.invalidRegex`})
                return errorMap;                        
            }
            
            if(value && field.length && (value.length > field.length)){
                errorMap[field.fieldName].message = defaultErrorMsg ?? `${errorFormater({ "id": `${errorPrefix}.maxChars`})}${field.length}`
                return errorMap;
            }
            break;
        case EDITABLE_TYPE.DATE:
            if(!!value && (moment(value).isBefore(moment("1970-01-01")) || moment(value).isAfter(moment(`${maxDate}-12-31`)))){
                errorMap[field.fieldName].message = defaultErrorMsg ?? errorFormater({ "id": `${errorPrefix}.invalidDateRange`})
                return errorMap;
            }
            break;
        case EDITABLE_TYPE.DATE_TIME:
            if(!!value && (moment(value).isBefore(moment("1970-01-01T00:00")) || moment(value).isAfter(moment(`${maxDate}-12-31T23:59`)))){
                errorMap[field.fieldName].message = defaultErrorMsg ?? errorFormater({ "id": `${errorPrefix}.invalidDateTimeRange`})
                return errorMap;
            }
            break;
        case EDITABLE_TYPE.RUNNING_NUMBER:
            if(!matchRegex(field.format, value)){
                // errorMap[field.fieldName].message = `Failed regex condition: ${field.validationRegex}`
                errorMap[field.fieldName].message = defaultErrorMsg ?? errorFormater({ "id": `${errorPrefix}.invalidRegex`})
                return errorMap;                        
            }
            
            if(value && field.length && (value.length > field.length)){
                errorMap[field.fieldName].message = defaultErrorMsg ?? `${errorFormater({ "id": `${errorPrefix}.maxChars`})}${field.length}`
                return errorMap;
            }
            break;            
        case EDITABLE_TYPE.TEXT:
        case EDITABLE_TYPE.PHONE_NUMBER:
        case EDITABLE_TYPE.PASSWORD:
        case EDITABLE_TYPE.TEXTAREA:
        default:
            if(field.validationRegex){
                if(!matchRegex(field.validationRegex, value)){
                    // errorMap[field.fieldName].message = `Failed regex condition: ${field.validationRegex}`
                    errorMap[field.fieldName].message = defaultErrorMsg ?? `Failed regex condition`
                    return errorMap;                        
                }
            }
            
            if(value && field.length && (value.length > field.length)){
                errorMap[field.fieldName].message = defaultErrorMsg ?? `Max characters: ${field.length}`
                return errorMap;
            }
            break;
    }

    return {}
}

const CusEditable = React.forwardRef(({
    className = "",
    fieldType = EDITABLE_TYPE.TEXT,
    label = "",
    invalidValueLabel = "",
    value = "",
    pattern = "",
    options = [],
    readOnly = false,
    disabled = false,
    framed = true,
    onChange = null,
    errors = null,
    errorName = null,
    containerStyle = CONTAINER_STYLE.ROUNDED,
    ...rest
}, ref) => {

    let field = null;

    const [showPassword, setShowPassword] = useState(false)

    switch (fieldType) {
        case EDITABLE_TYPE.TEXT:
        case EDITABLE_TYPE.EMAIL:
        case EDITABLE_TYPE.NUMBER:
            if(containerStyle === CONTAINER_STYLE.UNDERLINE)
                field = <CusUnderlineInput hasError={!!(errors && errors[errorName]&& errors[errorName]["message"])} borderType={errors && errors[errorName]&& errors[errorName]["message"] ? InputBorderType.Error : ""} disabled={disabled} readOnly={readOnly} value={value} placeholder={label} onChange={onChange} {...rest}/>            
            else
                field = <CusLabelRoundedInput hasError={!!(errors && errors[errorName]&& errors[errorName]["message"])} borderType={errors && errors[errorName]&& errors[errorName]["message"] ? InputBorderType.Error : ""} disabled={disabled} readOnly={readOnly} value={value} label={label} onChange={onChange} {...rest}/>
            break;
        case EDITABLE_TYPE.PASSWORD:
            if(containerStyle === CONTAINER_STYLE.UNDERLINE)
                field = <CusUnderlineInput toggle={showPassword} setToggle={() => setShowPassword(!showPassword)} type="password" hasError={!!(errors && errors[errorName]&& errors[errorName]["message"])} borderType={errors && errors[errorName]&& errors[errorName]["message"] ? InputBorderType.Error : ""} disabled={disabled} readOnly={readOnly} value={value} placeholder={label} onChange={onChange} {...rest}/>            
            else
                field = <CusLabelRoundedInput toggle={showPassword} setToggle={() => setShowPassword(!showPassword)} type="password" hasError={!!(errors && errors[errorName]&& errors[errorName]["message"])} borderType={errors && errors[errorName]&& errors[errorName]["message"] ? InputBorderType.Error : ""} disabled={disabled} readOnly={readOnly} value={value} label={label} onChange={onChange} {...rest}/>
            break;
        case EDITABLE_TYPE.PHONE_NUMBER:
        case EDITABLE_TYPE.RUNNING_NUMBER:
            if(containerStyle === CONTAINER_STYLE.UNDERLINE)
                field = <CusUnderlineInput type="tel" pattern={pattern} hasError={!!(errors && errors[errorName]&& errors[errorName]["message"])} borderType={errors && errors[errorName]&& errors[errorName]["message"] ? InputBorderType.Error : ""} disabled={disabled} readOnly={readOnly} value={value} placeholder={label} onChange={onChange} {...rest}/>            
            else
                field = <CusLabelRoundedInput type="tel" pattern={pattern} hasError={!!(errors && errors[errorName]&& errors[errorName]["message"])} borderType={errors && errors[errorName]&& errors[errorName]["message"] ? InputBorderType.Error : ""} disabled={disabled} readOnly={readOnly} value={value} label={label} onChange={onChange} {...rest}/>
            break;
        case EDITABLE_TYPE.TEXTAREA:
            if(containerStyle === CONTAINER_STYLE.UNDERLINE)
                field = <CusUnderlineTextArea hasError={!!(errors && errors[errorName]&& errors[errorName]["message"])} borderType={errors && errors[errorName]&& errors[errorName]["message"] ? TextAreaBorderType.Error : ""} disabled={disabled} readOnly={readOnly} value={value} placeholder={label} onChange={onChange} {...rest}/>
            else
                field = <CusLabelRoundedTextArea hasError={!!(errors && errors[errorName]&& errors[errorName]["message"])} borderType={errors && errors[errorName]&& errors[errorName]["message"] ? TextAreaBorderType.Error : ""} disabled={disabled} readOnly={readOnly} value={value} label={label} onChange={onChange} {...rest}/>
            break;
        case EDITABLE_TYPE.PICKLIST:
            if(containerStyle === CONTAINER_STYLE.UNDERLINE)
                field = <CusUnderlineSelect hasError={!!(errors && errors[errorName]&& errors[errorName]["message"])} borderType={errors && errors[errorName]&& errors[errorName]["message"] ? SelectBorderType.Error : ""} disabled={disabled} readOnly={readOnly} options={options} value={value} placeholder={label} invalidValueLabel={invalidValueLabel} onChange={onChange} {...rest}/>
            else
                field = <CusLabelRoundedSelect hasError={!!(errors && errors[errorName]&& errors[errorName]["message"])} borderType={errors && errors[errorName]&& errors[errorName]["message"] ? SelectBorderType.Error : ""} disabled={disabled} readOnly={readOnly} options={options} value={value} label={label} invalidValueLabel={invalidValueLabel} onChange={onChange} {...rest}/>
            break;
        case EDITABLE_TYPE.MULTI_PICKLIST:
            field = <CusMultiSelectCheckBox framed={framed} disabled={disabled} readOnly={readOnly} options={options} value={value ? value : []} label={label} onChange={onChange} {...rest}/>
            break;
        case EDITABLE_TYPE.MULTI_PICKLIST_SWITCH:
            field = <CusMultiSelectSwitch framed={framed} disabled={disabled} readOnly={readOnly} options={options} value={value ? value : []} label={label} onChange={onChange} {...rest}/>
            break;
        case EDITABLE_TYPE.BOOLEAN:
            field = <CusLabelSwitch disabled={disabled} readOnly={readOnly} checked={!!value} label={label} onChange={onChange} {...rest}/>
            break;
        case EDITABLE_TYPE.BOOLEAN_CHECKBOX:
            field = <CusLabelCheckBox oneLine disabled={disabled} readOnly={readOnly} checked={!!value} label={label} onChange={onChange} {...rest}/>
            break;
        case EDITABLE_TYPE.LINK:
            field = <CusLink onClick={() => {}} className="font-semibold">{`${label} >`}</CusLink>
            break;
        case EDITABLE_TYPE.DATE:
            field = <CusLabelRoundedDatePicker type={PickerType.date} hasError={!!(errors && errors[errorName]&& errors[errorName]["message"])} borderType={errors && errors[errorName]&& errors[errorName]["message"] ? InputBorderType.Error : ""} disabled={disabled} readOnly={readOnly} value={value} label={label} onChange={onChange} {...rest}/>
            break;
        case EDITABLE_TYPE.DATE_TIME:
            field = <CusLabelRoundedDatePicker type={PickerType.dateTime} hasError={!!(errors && errors[errorName]&& errors[errorName]["message"])} borderType={errors && errors[errorName]&& errors[errorName]["message"] ? InputBorderType.Error : ""} disabled={disabled} readOnly={readOnly} value={value} label={label} onChange={onChange} {...rest}/>
            break;
        default:
            if(containerStyle === CONTAINER_STYLE.UNDERLINE)
                field = <CusUnderlineInput hasError={!!(errors && errors[errorName]&& errors[errorName]["message"])} borderType={errors && errors[errorName]&& errors[errorName]["message"] ? InputBorderType.Error : ""} disabled={disabled} readOnly={readOnly} value={value} label={label} onChange={onChange} {...rest}/>
            else
                field = <CusLabelRoundedInput hasError={!!(errors && errors[errorName]&& errors[errorName]["message"])} borderType={errors && errors[errorName]&& errors[errorName]["message"] ? InputBorderType.Error : ""} disabled={disabled} readOnly={readOnly} value={value} label={label} onChange={onChange} {...rest}/>
            break;
    }


    return (
        <div className={`${className}`}>
            {field}
            <CusInputError errors={errors} name={errorName}/>
        </div>    
    )
});

CusEditable.propTypes = {
    className: PropTypes.string,
    fieldType: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.arrayOf(PropTypes.string)]),
    pattern: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    framed: PropTypes.bool,
    onChange: PropTypes.func,
    errors: PropTypes.object,
    errorName: PropTypes.string,
  }

export default CusEditable;