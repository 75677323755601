import { Tag } from 'components/Tag';
import PropTypes from 'prop-types';
import React from 'react';

export const ITEM_TYPE = Object.freeze({
    "RADIO": "radio",
    "CHECKBOX": "checkbox",
})

const Item = ({
	type = ITEM_TYPE.RADIO,
    label = "",
    labelColor = "",
    className = "",
    active = false,
    disabled = false,
    onClick = null,
    onChange = () => {},
    children = null,
}) => {

    return (
        <div
            className={`flex items-center px-4 py-3 text-sm ${disabled ? "" : "cursor-pointer"} hover:bg-blue06 ${className}`}
            onClick={(e)=>{
                if(onClick){
                    e.stopPropagation()
                    onClick(e)
                    document.activeElement.blur();
                }
            }}                                
        >
            {children ?
                children
                :
                <>
                    <span className='flex items-center pb-[2px] pr-3'><input disabled={disabled} type={type} checked={active} onChange={onChange}/></span>
                    {labelColor ? 
                            <Tag className='max-w-[62px] min-w-[44px] h-[28px] px-2 mx-2 text-xs' text={label} background={labelColor}/>
                        :
                            <span className="truncate">{label}</span>
                    }
                </>
            }
        </div>
    )
}

Item.propTypes = {
    type: PropTypes.oneOf([ITEM_TYPE.RADIO, ITEM_TYPE.CHECKBOX]),
    label: PropTypes.string,
    labelColor: PropTypes.string,
    className: PropTypes.string,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    children: PropTypes.node
};

export default Item;