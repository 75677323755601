import HighlightText from 'components/HighlightText';
import PropTypes from 'prop-types';

const TextMessage = ({message = "", keyword = "", multiLines = true}) => (
    <HighlightText text={message} keyword={keyword} multiLines={multiLines}/>
)


TextMessage.propTypes = {
    message: PropTypes.string,
    keyword: PropTypes.string,
    multiLines: PropTypes.bool
};

export default TextMessage;