
import PropTypes from 'prop-types';
import React from 'react';
import Item from './Item';

import { CusCheckBox } from 'components/CusCheckBox'

const Row = ({
    className = "",
    children = null,
    rowIndex = 0,
    selectable = false,
    selected = false,
    selectAction = () => {},
}) => (
    <tr className={`w-full h-[64px] border-b-[1px] pl-8 pr-4 ${rowIndex % 2 === 0 ? "bg-white" : "bg-grey02"} ${className}`}>
        {selectable ?
            <Item key={`row-${rowIndex}-select`} onClick={() => selectAction()}>
                <CusCheckBox
                    checked={!!selected}
                    onChange={() => {}}
                />
            </Item>
            : null
        }
        {children}
    </tr>    
)

Row.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    rowIndex: PropTypes.number,
    selectable: PropTypes.bool,
    selected: PropTypes.bool,
    selectAction: PropTypes.func,
};

export default Row;