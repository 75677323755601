import { WarningSnackbar } from "components/WarningSnackbar";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


import CusModal from 'components/CusModal';
import { Color as ButtonColor, PaddingType as ButtonPadding, RoundType as ButtonRoundType, CusRoundedButton } from 'components/CusRoundedButton';
import CusRoundedSearch from "components/CusRoundedInput/CusRoundedSearch";
import { Tab, Size as TabSize } from 'components/Tab';
import Table from "components/Table";
import { usePageSize } from "hooks/pageConditions/usePageSize";
import { setPageSize } from "store/actions/ui/setting";

import Bin from "components/Icon/Bin";
import PageTitle from "components/PageTitle";
import {
    cloneFlow, createFlow, getFlowList as fetchFlowList, removeFlow,
    selectFlow
} from "store/actions/setting/automation/chatbot/flow";
import { getError, getFlowList, getLoading, getNotice, getSelectedFlow, getSelectedFlowLoading } from "store/reducers/setting/automation/chatbot/flow";

import Copy from "components/Icon/Copy";
import Refresh from "components/Icon/Refresh";
import SubTasks from "components/Icon/SubTasks";
import { usePageSort } from "hooks/pageConditions/usePageSort";
import { useLanguage } from "hooks/useLanguage";
import { chatbotPrefix, chatbotIconBarPrefix, generalPrefix, settingPrefix } from "lang/locales/prefix";
import { useIntl } from "react-intl";
import CloneFlow from "./components/CloneFlow";
import CreateFlow from "./components/CreateFlow";
import EditFlow from "./components/EditFlow";
import { useIsLoggedIn } from "hooks/user/useIsLoggedIn";
import { getToken } from "store/reducers/auth/user";

const MODAL_ACTION = Object.freeze({
    'REMOVE': 'REMOVE',
    'DISABLE': 'DISABLE',
    "EXIT_EDIT": "EXIT_EDIT",
})

const TABS = Object.freeze({
    'CHATBOT_AUTOMATION': 'CHATBOT_AUTOMATION',
    'NODES': 'NODES',
    'VARIABLES': 'VARIABLES',
    'LABELLING': 'LABELLING'
})

const HEADER_FIELD_TITLE_LENGTH = 4


const useTokenChange = () => {
    const token = useSelector(state => getToken(state.auth.user));
    return token;
}

const useChatbotFlows = () => {
    const {
        selectedFlow,
        selectedFlowLoading,
        flows,
        loading,
    
        error,
        notice
    } = useSelector(state => ({
        selectedFlow: getSelectedFlow(state.setting.automation.chatbot.flow),
        selectedFlowLoading: getSelectedFlowLoading(state.setting.automation.chatbot.flow),
        flows: getFlowList(state.setting.automation.chatbot.flow),
        loading: getLoading(state.setting.automation.chatbot.flow),
        error: getError(state.setting.automation.chatbot.flow),
        notice: getNotice(state.setting.automation.chatbot.flow),
    }));

    return {
        selectedFlow,
        selectedFlowLoading,
        flows,
        loading,
    
        error,
        notice
    }
}

const Chatbot = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const intl = useIntl();
    const { language } = useLanguage()

    const [searchInput, setSearchInput] = useState("");
    const [searchFocus, setSearchFocus] = useState(false);

    const [warningOpen, setWarningOpen] = useState(false);
    const [warning, setWarning] = useState("");

    const pageSize = usePageSize("setting/automation/chatbot");
    const pageSort = usePageSort("setting/automation/chatbot");

    const [currentTab, setCurrentTab] = useState(TABS.CHATBOT_AUTOMATION); 

    const [page, setPage] = useState(0);

    const [selectedSet, setSelectedSet] = useState(new Set());

    const [showMenu, setShowMenu] = useState(false);
    const [menuPos, setMenuPos] = useState(null);

    const [selectedRemoveFlow, setSelectedRemoveFlow] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [modal, setModal] = useState(null);

    const [createModalOpen, setCreateModalOpen] = useState(false);

    const [selectedCloneFlow, setSelectedCloneFlow] = useState(null);
    const [cloneModalOpen, setCloneModalOpen] = useState(false);

    const [editModalOpen, setEditModalOpen] = useState(false);

    const TAB_NAMES = Object.freeze({
        'CHATBOT_AUTOMATION': intl.formatMessage({ "id": `${settingPrefix}.${chatbotPrefix}.tab.title.chatbotAutomation`}),
        'NODES': intl.formatMessage({ "id": `${settingPrefix}.${chatbotPrefix}.tab.title.nodes`}),
        'VARIABLES': intl.formatMessage({ "id": `${settingPrefix}.${chatbotPrefix}.tab.title.variables`}),
        'LABELLING': intl.formatMessage({ "id": `${settingPrefix}.${chatbotPrefix}.tab.title.labelling`}),
    })

    const token = useTokenChange()

    const {
        selectedFlow,
        selectedFlowLoading,
        flows,
        loading,
    
        error,
        notice
    } = useChatbotFlows();

    const HEADER_FIELD_TITLE_LIST = Array(HEADER_FIELD_TITLE_LENGTH).fill().map((_,index) => intl.formatMessage({ "id": `${settingPrefix}.${chatbotPrefix}.table.displayName.${index}`}))

    const filterGroupAction = (e) => {
        let pos = e.target.closest('.menu-pos').getBoundingClientRect()
        setMenuPos({x: pos.right, y: pos.bottom + 5})
        setShowMenu(true)          
    }

    const openCloneAction = (flow) => {
        setSelectedCloneFlow(flow)
        setCloneModalOpen(true)
    }

    const confirmCloneAction = (flow) => {
        dispatch(cloneFlow({id: selectedCloneFlow.id, newFlowName: flow.newFlowName}))
        closeCloneAction()
    }

    const closeCloneAction = (flow) => {
        setSelectedCloneFlow(null)
        setCloneModalOpen(false)
    }

    const openRemoveAction = (id) => {
        setSelectedRemoveFlow(id)
        setModal({
            action: MODAL_ACTION.REMOVE,
            content: intl.formatMessage({ "id": `${settingPrefix}.${chatbotPrefix}.modal.warning.remove`})
        })
        setModalOpen(true)
    }

    const openExitEditAction = () => {
        setModal({
            action: MODAL_ACTION.EXIT_EDIT,
            content: intl.formatMessage({ "id": `${generalPrefix}.modal.content.warning.quitEdit`})
        })
        setModalOpen(true)
    }


    const modalConfirmAction = () => {
        switch (modal.action) {
            case MODAL_ACTION.REMOVE:
                confirmRemoveAction()
                break;
            case MODAL_ACTION.EXIT_EDIT:
                dispatch(selectFlow(null))
                closeEditAction()
                break;        
            default:
                break;
        }
    }


    const closeEditAction = () => {        
        setModalOpen(false)
        setModal(null)
        setSelectedRemoveFlow("")
    }

    const confirmRemoveAction = () => {
        dispatch(removeFlow({id: selectedRemoveFlow}))

        closeEditAction()
    }

    const getCurrentChatbotFlows = () => {
        // let accounts = getfilteredChannelAccounts()
        let newFlows = JSON.parse(JSON.stringify(flows))

        newFlows.sort((a, b) => {
            if(a.name.toLowerCase() === b.name.toLowerCase()){
                if(b.type === "RELEASED")
                    return -1
                if(a.type === "RELEASED")
                    return 1

                return 0
            }

            return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        })

        // console.log(newFlows)
        return newFlows.slice(page * pageSize, (page + 1) * pageSize);
    }

    const reset = () => {
        dispatch(selectFlow(null))

        setSelectedSet(new Set())

        setPage(0)
        dispatch(fetchFlowList());
    }

    const hasPrev = () => {
        return page !== 0
    }

    const hasNext = () => {
        return (page + 1) * pageSize < flows.length
    }

    const onPrev = () => {
        if(hasPrev())
            setPage(page => page - 1)
    }

    const onNext = () => {
        if(hasNext())
            setPage(page => page + 1)
    }

    const onSelectPageOption = (size) => {
        dispatch(setPageSize({"setting/automation/chatbot": size}))
        setPage(0)
        setSelectedSet(new Set())
    }

    const addToSelection = (selectedSet, id) => {
        let newSet = new Set([...selectedSet])
        newSet.add(id)
        setSelectedSet(newSet);
    }

    const removeFromSelection = (selectedSet, id) => {
        let newSet = new Set([...selectedSet])
        newSet.delete(id)
        setSelectedSet(newSet);
    }

    const addAllToSelection = (selectedSet, list) => {
        let newSet = new Set([...selectedSet])
        list.forEach(item => newSet.add(item.id));
        setSelectedSet(newSet);
    }

    const removeAllFromSelection = (selectedSet, list) => {
        let newSet = new Set([...selectedSet])
        list.forEach(item => newSet.delete(item.id));
        setSelectedSet(newSet);
    }


    useEffect(() => {
        if(notice){
            setWarning(notice)
            setWarningOpen(true)
        }
        if(error){
            setWarning(error)
            setWarningOpen(true)
        }
    }, [error, notice])

    useEffect(() => {
        setCreateModalOpen(false)
        setCloneModalOpen(false)
    }, [selectedFlow])

    useEffect(() => {
        // setEditModalOpen(false);
        setPage(0)
        setSelectedRemoveFlow("")
        closeEditAction()
    }, [flows])

    useEffect(() => {
        reset()

        return () => {
            dispatch(selectFlow(null))
        }
    }, [dispatch, token])

    let tableChatbotFlows = getCurrentChatbotFlows()
    let currentSelected = tableChatbotFlows.filter(item => selectedSet.has(item.id))

    return <>
                <WarningSnackbar message={warning} open={warningOpen} setOpen={setWarningOpen}/>
                <CusModal full show={createModalOpen}>
                    {createModalOpen ?
                        <CusModal.Frame className={`w-[420px]`}>
                            <CreateFlow
                                saveAction={(flow) => dispatch(createFlow(flow))}
                                cancelAction={() => setCreateModalOpen(false)}
                            />
                        </CusModal.Frame>
                        :
                        null
                    }
                </CusModal>
                <CusModal full show={cloneModalOpen}>
                    {cloneModalOpen ?
                        <CusModal.Frame className={`w-[420px]`}>
                            <CloneFlow
                                flow={selectedCloneFlow}
                                saveAction={(flow) => confirmCloneAction(flow)}
                                cancelAction={closeCloneAction}
                            />
                        </CusModal.Frame>
                        :
                        null
                    }
                </CusModal>
                {/* <CusModal full show={editModalOpen}>
                    {editModalOpen ?
                        <CusModal.Frame className={`w-[100vw] md:w-[630px] ${FrameHeight.normal}`}>
                            <EditAccount
                                account={selectedFlow}
                                saveAction={(account) => updateAction(account)}
                                cancelAction={() => {setEditModalOpen(false);setSelectedFlow(null)}}
                                removeAction={() => openRemoveAction(selectedFlow)}
                            />
                        </CusModal.Frame>
                        :
                        null
                    }
                </CusModal> */}
                <CusModal full show={modalOpen}>
                    {modal ? 
                        <CusModal.Frame className={`w-[348px]`}>
                            <CusModal.Title title={intl.formatMessage({ "id": `${generalPrefix}.modal.title.reminder`})} closeAction={closeEditAction}/>
                            <div className="min-h-[71px] pl-7 pr-5 pt-2 pb-4 text-lg text-greyblack">{modal.content}</div>
                            <hr/>
                            <div className="pt-[23px] px-4 w-full flex min-h-[91.82px]">
                                <>
                                    <CusRoundedButton className='w-full flex px-2 mx-2 justify-center items-center' color={ButtonColor.primaryFrame}
                                        onClick={closeEditAction}
                                    >
                                        <span>{intl.formatMessage({ "id": `${generalPrefix}.button.cancel`})}</span>
                                    </CusRoundedButton>
                                    <CusRoundedButton className='w-full flex px-2 mx-2 justify-center items-center' color={ButtonColor.primary}
                                        onClick={modalConfirmAction}
                                    >
                                        <span>{intl.formatMessage({ "id": `${generalPrefix}.button.confirm`})}</span>
                                    </CusRoundedButton>
                                </>
                            </div>
                        </CusModal.Frame>
                        :
                        null
                    }                
                </CusModal>
                <div className="relative flex flex-col w-full h-full">
                    {selectedFlow ? 
                        <EditFlow
                            selectedFlow={selectedFlow}
                            loading={selectedFlowLoading}
                            openExitEditAction={openExitEditAction}
                        />
                        : null
                    }
                    <div className="flex flex-col w-full h-[113px] border-b-[1px] border-grey03">
                        <PageTitle className="px-8 pt-6 pb-2" title={intl.formatMessage({ "id": `${settingPrefix}.${chatbotPrefix}.page.text.title`})}/>
                        <div className={`w-full h-full flex items-center`}>
                        <Tab className="mx-8" name={intl.formatMessage({ "id": `${settingPrefix}.${chatbotPrefix}.tab.title.chatbotAutomation`})} size={TabSize.none} active/>
                        </div>
                    </div>
                    <div className="relative w-full h-[calc(100%-113px)]">
                        <Table
                            pageChangeFactor={(page + 1) * pageSize}
                            // title="Chat Activities"
                            className="px-8"
                            background="bg-white"
                            // resizeFactor={windowSize ? windowSize.width : -1}
                            // getTableSize={(size) => setTableWidth(size ? size.width : 0)}
                        >
                            <div className="flex flex-col">
                                <div className={`py-2 text-greyblack font-bold`}>{intl.formatMessage({ "id": `${settingPrefix}.${chatbotPrefix}.table.text.title`})}</div>
                                <div className={`text-grey051 text-sm`}>{intl.formatMessage({ "id": `${settingPrefix}.${chatbotPrefix}.table.text.subTitle`})}</div>
                            </div>
                            <div className="flex items-center w-full max-w-[433px]">
                                {searchFocus || searchInput ?
                                    null
                                    :
                                    <div className="flex text-blue04">
                                        <div
                                            title="Refresh"
                                            className="cursor-pointer pl-6"
                                            onClick={reset}
                                        >
                                            <Refresh/>
                                        </div>
                                        {/* <div
                                            title="Bin"
                                            className="cursor-pointer pl-6 menu-pos"
                                            // onClick={filterGroupAction}
                                        >
                                            <Bin className="w-[24px] h-[24px]"/>
                                        </div> */}
                                    </div>
                                }
                                <div className=" pl-[22px] py-[10px] w-full">
                                    <CusRoundedSearch
                                        placeholder={intl.formatMessage({ "id": `${settingPrefix}.${chatbotPrefix}.table.placeholder.search`})}
                                        value={searchInput}
                                        onChange={(e) => setSearchInput(e.target.value)}
                                        cancelSearch={() => setSearchInput("")}
                                        onFocus={setSearchFocus ? () => setSearchFocus(true) : () => {}}
                                        onBlur={setSearchFocus ? () => setSearchFocus(false) : () => {}}        
                                    />
                                </div>
                                <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex text-xs flex-shrink-0 ml-4 px-10 h-[40px] items-center rounded-[5px]' color={ButtonColor.primary}
                                        onClick={() => setCreateModalOpen(true)}
                                    >
                                        <span>{intl.formatMessage({ "id": `${settingPrefix}.${chatbotPrefix}.button.createFlows`})}</span>
                                </CusRoundedButton>                                    
                            </div>
                            <Table.Header
                                hasAction
                                // selectable
                                // selected={currentSelected.length === tableChatbotFlows.length}
                                // selectAction={
                                //     () => currentSelected.length === tableChatbotFlows.length ?
                                //         removeAllFromSelection(selectedSet, tableChatbotFlows) : addAllToSelection(selectedSet, tableChatbotFlows)
                                // }
                            >
                                {HEADER_FIELD_TITLE_LIST.map((name) => 
                                    <Fragment key={`header ${name}`}><Table.TextItem text={name}/></Fragment>
                                )}
                            </Table.Header>
                            <>
                                {tableChatbotFlows.map((flow, flowIndex) => 
                                    <Table.Row
                                        rowIndex={flowIndex}
                                        key={`row ${flow.id}`}
                                        // selectable
                                        // selected={selectedSet.has(flow.id)}
                                        // selectAction={() => selectedSet.has(flow.id) ? removeFromSelection(selectedSet, flow.id) : addToSelection(selectedSet, flow.id)}            
                                    >
                                        <Table.Item><Table.TextItem className="font-bold" text={flow.name}/></Table.Item>
                                        <Table.Item>
                                            <Table.TextItem text={flow.type ? intl.formatMessage({ "id": `${settingPrefix}.${chatbotPrefix}.nameList.flowType.released`}) : intl.formatMessage({ "id": `${settingPrefix}.${chatbotPrefix}.nameList.flowType.draft`})}/>
                                        </Table.Item>
                                        <Table.Item>
                                            <Table.TextItem text={`${flow.version}`}/>
                                        </Table.Item>
                                        <Table.Item>
                                            <Table.TimeItem hideTime timestamp={flow.deploymentTimestamp}/>
                                        </Table.Item>
                                        {/* <Table.Item>
                                            <div className="flex">
                                                <div className="cursor-pointer" onClick={() => openActiveAction(account)}>
                                                    <CusToggleSwitch
                                                        checked={account.isActive}
                                                        onChange={() => {}}
                                                        paddingType={PaddingType.flat}
                                                        toogleSize={ToogleSize.small}
                                                        silderSize={SilderSize.small}                    
                                                    />    
                                                </div>
                                            </div>
                                        </Table.Item> */}
                                        <Table.Action>
                                            <div className="cursor-pointer" onClick={() => dispatch(selectFlow(flow))}><SubTasks/></div>
                                            <div className="ml-6"><div className={`cursor-pointer`} onClick={() => openCloneAction(flow)}><Copy className="w-[24px] h-[24px]"/></div></div>
                                            <div className="mx-6"><div className={`cursor-pointer`} onClick={() => openRemoveAction(flow.id)}><Bin className="w-[24px] h-[24px]"/></div></div>
                                            {/* <div className="cursor-pointer px-6" onClick={() => console.log("delete")}><Bin className="w-[24px] h-[24px]"/></div> */}
                                        </Table.Action>
                                    </Table.Row>
                                )}
                            </>
                            <Table.Footer
                                pageOption={pageSize}
                                onPrev={onPrev}
                                onNext={onNext}
                                hasPrev={hasPrev()}
                                hasNext={hasNext()}            
                                onSelectPageOption={onSelectPageOption}                        
                            />
                        </Table>
                    </div>
                </div>
            </>
}

export default Chatbot;