import PropTypes from 'prop-types';
import { MESSAGE_TYPE } from 'util/helper';
import MediaDownloadable from '../MediaAction/MediaDownloadable';
import { MESSAGE_CONTAINER_TYPE } from "components/ChatMessage/util";
import TextMessage from './TextMessage';

const VideoMessage = ({
    messageId = "",
    message = "",
    messageMedia = null,
    downloading = false,
    disabled = false,
    onClick = null,
    onContextMenu = null,
    containerType = MESSAGE_CONTAINER_TYPE.AGENT,
    keyword = "",
    multiLines = true,
}) => (
    <div className="flex flex-col">
        {messageMedia ?
            <video className={`max-w-[250px] max-h-[152px] bg-black`} controls src={`data:${messageMedia.mimeType};base64,${messageMedia.base64}`}/>
            :
            <MediaDownloadable
                loading={downloading}
                disabled={disabled}
                messageId={messageId}
                messageType={MESSAGE_TYPE.VIDEO}
                onDownload={onClick}
                onContextMenu={onContextMenu}
                containerType={containerType}
            />
        }
        {message ?
            <TextMessage message={message} keyword={keyword} multiLines={multiLines}/>
            :
            null
        }
    </div>
)


VideoMessage.propTypes = {
    messageId: PropTypes.string,
    message: PropTypes.string,
    messageMedia: PropTypes.object,
    downloading: PropTypes.bool,
    disabled: PropTypes.bool,
    
    onClick: PropTypes.func,
    onContextMenu: PropTypes.func,
    containerType: PropTypes.string,

    keyword: PropTypes.string,
    multiLines: PropTypes.bool

};

export default VideoMessage;