import { chatpanelPrefix, chatpanelConversationsPrefix, chatpanelMessagesPrefix, chatTransferPrefix } from "../prefix";

const chatpanelBase = {
    [`${chatpanelPrefix}.modal.content.warning.offline`]: "Turn online to start chat now.",
    [`${chatpanelPrefix}.modal.content.warning.reconnect`]: "Reconnect",

    [`${chatpanelPrefix}.button.keepOffline`]: "Keep offline",
    
    [`${chatpanelPrefix}.loadingMessage.offline`]: "Please change user session to online to activate the Chat Panel",
    [`${chatpanelPrefix}.loadingMessage.networkDisconnected`]: "Network Disconnected",
    [`${chatpanelPrefix}.loadingMessage.pending`]: "Updating Session...",
    [`${chatpanelPrefix}.loadingMessage.webSocketClosed`]: "Chat Panel is closed",
}

const chatPanelConversation = {
    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.tab.title.open`]: "Open",
    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.tab.title.closed`]: "Closed",

    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.notice.message.search`]: " MESSAGE FOUNDED",

    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.notice.message.empty`]: "No message is found.",
    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.notice.conversation.closed`]: "CHATS CLOSED IN 1 DAY",
    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.notice.conversation.empty`]: "No chat is opened.",

    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.nameList.sorting.timestamp`]: "Time (recent)",
    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.nameList.sorting.channel`]: "Channels",

    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.nameList.optionMenu.pin`]: "Pin Chat",
    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.nameList.optionMenu.unPin`]: "Unpin Chat",
    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.nameList.optionMenu.markUnread`]: "Mark as Unread",
}

const chatPanelMessage = {
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.button.close`]: "Close chat",

    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.modal.title.warning`]: "WARNING",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.modal.content.warning.close`]: "Close Chat?",

    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.text.chatTags`]: "Chat tags",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.text.preview`]: "PREVIEW",

    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.notice.conversation.empty`]: "No chat is opened or selected.",
    
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.search.text.pinned`]: "PINNED MESSAGE",

    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.quickText.expandAll`]: "Expand All",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.quickText.folderType.public`]: "Public",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.quickText.folderType.personal`]: "Personal",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.quickText.categories`]: "All categories",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.quickText.subCategories`]: "All sub-categories",

    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.messageInput.textarea.placeholder.typeMessage`]: "Type a message",

    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.messageInput.button.quickText`]: "Quick text",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.messageInput.button.send`]: "Send",

    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.messageInput.nameList.menu.title`]: "SHARE",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.messageInput.nameList.menu.document`]: "Document",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.messageInput.nameList.menu.image`]: "Image",
}

const chatTransfer = {
    [`${chatpanelPrefix}.${chatTransferPrefix}.nameList.menu.title`]: "Agent or queue",
    [`${chatpanelPrefix}.${chatTransferPrefix}.nameList.menu.all`]: "All agents/queues",
    [`${chatpanelPrefix}.${chatTransferPrefix}.nameList.menu.queue`]: "Queue",
    [`${chatpanelPrefix}.${chatTransferPrefix}.nameList.menu.agent`]: "Individual Agent",

    [`${chatpanelPrefix}.${chatTransferPrefix}.info.count`]: "Online: ",

    [`${chatpanelPrefix}.${chatTransferPrefix}.text.transferMessage`]: "Transfer message",
    [`${chatpanelPrefix}.${chatTransferPrefix}.input.placeholder.transferMessage`]: "Write message",
}

export const chatpanel = {
    ...chatpanelBase,
    ...chatPanelConversation,
    ...chatPanelMessage,
    ...chatTransfer,
}