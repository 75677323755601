import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import Bin from 'components/Icon/Bin';
import DownArrow from 'components/Icon/DownArrow';
import UpArrow from 'components/Icon/UpArrow';
import Cross from 'components/Icon/Cross';
import Plus from 'components/Icon/Plus';
import Minimise from 'components/Icon/Minimise';

const NonExpanableListItem = ({
    title = "",
    width = "max-w-full",
    children = null,
    remove = null,
    readOnly = false,
}) => {

    return(
        <>
            <div className={`flex flex-col py-3 ${width}`}>
                <div className='flex justify-between items-center h-[24px]'>
                    <span className="text-[#94A3B8] text-[13px]">{title}</span>
                    <div className='flex items-center'>
                        <div className={`text-[#94A3B8]`}>
                            <div className={`${ readOnly ? "opacity-50" : "cursor-pointer"}`} onClick={readOnly ? () => {} : () => remove()}>
                                <Minimise className="w-[24px] h-[24px]"/>
                            </div>
                        </div>
                    </div>
                </div>
                {children}
            </div>
            <div className='w-full'><hr/></div>
        </>
    )
}

NonExpanableListItem.propTypes = {
    title: PropTypes.string,
    width: PropTypes.string,
    children: PropTypes.node,
    remove: PropTypes.func,
    readOnly: PropTypes.bool,
};

export default NonExpanableListItem;
