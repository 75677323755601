import PropTypes from "prop-types";
import React from 'react';
import ListItem from "./ListItem";

const Color = Object.freeze(
    {
        "transparent" : "",
        "white": " bg-white",
        "blue": " bg-blue04",
        "paleBlue": " bg-blue06",
        "grey": " bg-grey022",
    }
)

const PaddingType = Object.freeze(
    {
        "noPadding" : "",
        "small"     : " p-2 ",
        "small-x"   : " px-2 ",
        "small-y"   : " py-2 ",
        "flat"      : " p-4 ",
        "flat-x"    : " px-4 ",
        "flat-y"    : " py-4 ",
    }
)

const MarginType = Object.freeze(
    {
        "noMargin"  : "",
        "small"     : " m-2 ",
        "small-x"   : " mx-2 ",
        "small-y"   : " my-2 ",
        "flat"      : " m-4 ",
        "flat-x"    : " mx-4 ",
        "flat-y"    : " my-4 ",
    }
)

const CusCard = ({
    className = "",
    children = null,
    color = Color.white,
    unstyled = false,
    paddingType = PaddingType.noPadding,
    marginType = MarginType.noMargin,
    ...rest
}) => {

    return(
        <div 
            className = {unstyled ? className :
                `${className} rounded-md ${color}
                    ${paddingType ? paddingType : PaddingType.noPadding} ${marginType ? marginType : MarginType.noMargin}`}
            {...rest}
        >
            {children}
        </div>

    );
}

CusCard.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    color: PropTypes.string,
    unstyled: PropTypes.bool,
    paddingType: PropTypes.string,
    marginType: PropTypes.string,
}

CusCard.ListItem = ListItem

export default CusCard

export {
    Color,
    PaddingType,
    MarginType
}