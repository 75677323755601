import Download from 'components/Icon/Media/Download';
import File from 'components/Icon/Media/File';
import PDF from 'components/Icon/Media/PDF';
import Image from 'components/Icon/Media/Image';
import Video from 'components/Icon/Media/Video';
import Location from 'components/Icon/Media/Location';
import PropTypes from 'prop-types';
import { MESSAGE_TYPE } from 'util/helper';
import { MESSAGE_CONTAINER_TYPE } from "components/ChatMessage/util";

const MESSAGE_MEDIA_ICON = {
    "IMAGE": Image,
    "VIDEO": Video,
    "AUDIO": Video,
    "LOCATION": Location,
}

const MESSAGE_FILE_ICON = {
    "FILE": File,
    "PDF": PDF,
}

const MediaAction = ({
    title = "",
    messageType = MESSAGE_TYPE.IMAGE,
    mimeType = "",
    onClick = null,
    onContextMenu = null,
    actionIcon = false,
    loading = false,
    disabled = false,
    containerType = MESSAGE_CONTAINER_TYPE.AGENT,
}) => {

    const Icon = MESSAGE_MEDIA_ICON[messageType] ?? (mimeType === "application/pdf" ? MESSAGE_FILE_ICON.PDF : MESSAGE_FILE_ICON.FILE);

    return (
        <div className={`relative px-2 py-1 min-w-[250px] flex justify-between items-center rounded-sm ${containerType === MESSAGE_CONTAINER_TYPE.CUSTOMER ? "bg-white" : "bg-blue06"}`} onContextMenu={onContextMenu}>
            <div className='p-2 flex items-center'>
                <div className='text-grey07'><Icon/></div>
                <div className='px-4'>{title}</div>
            </div>
            {loading ?
                <div className="absolute bottom-0 left-0 w-full h-1 overflow-hidden">
                    <div className="w-full h-full bg-blue04-dark animate-[indeterminate_1s_infinite_linear] origin-[0_50%]"/>
                </div>
                :
                <div className={`p-2 ${disabled ? "text-grey06" : "text-blue04 cursor-pointer"}`} onClick={onClick} disabled={disabled}>{actionIcon}</div>
            }
        </div>        
    )
}


MediaAction.propTypes = {
    title: PropTypes.string,
    messageType: PropTypes.string,
    mimeType: PropTypes.string,
    onClick: PropTypes.func,
    onContextMenu: PropTypes.func,

    actionIcon: PropTypes.element,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    containerType: PropTypes.string,
};

export default MediaAction;