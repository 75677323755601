import {httpPost} from 'lib/requests';

const getFlow = (authToken) => {
    return httpPost('chatbot/flow/get', {}, authToken);
}

const createFlow = (data, authToken) => {
    return httpPost('chatbot/flow/create', data, authToken);
}

const updateFlow = (data, authToken) => {
    return httpPost('chatbot/flow/update', data, authToken);
}

const publishFlow = (data, authToken) => {
    return httpPost('chatbot/flow/publish', data, authToken);
}

const cloneFlow = (data, authToken) => {
    return httpPost('chatbot/flow/clone', data, authToken);
}

const exportFlow = (data, authToken) => {
    return httpPost('chatbot/flow/export', data, authToken);
}

const importFlow = (data, authToken) => {
    return httpPost('chatbot/flow/import', data, authToken);
}

const removeFlow = (data, authToken) => {
    return httpPost('chatbot/flow/delete', data, authToken);
}

export {
    getFlow,
    updateFlow,
    createFlow,
    publishFlow,
    cloneFlow,
    exportFlow,
    importFlow,
    removeFlow
};