

import PropTypes from "prop-types";
import React from 'react';

const CusUnderlineInput = React.forwardRef(({
    hasError = false,
    value = undefined,
    placeholder = "",
    type = "text",
    disabled = false,
    readOnly = false,
    onChange = ()=>{},
    className = "",
    list  = "",
    paddingType = " h-[40px] px-3 ",
    textSize = " text-base ",
    placeholderSize = " placeholder:text-base ",
    ...rest
}, ref) => {
    return(
        <div className={className}>
            <div>
                {readOnly || disabled ?
                        <div 
                            className={"flex items-center w-full truncate outline-none text-[#94A3B8] placeholder:text-[#CBD5E1] bg-transparent"
                                + textSize
                                + placeholderSize
                                + paddingType
                                + " border-b-2 border-[#CBD5E1] rounded-t-[4px] "
                            } 
                            {...rest}
                        >
                            {value}
                        </div>
                    :
                        <input 
                            ref={ref}
                            className={"w-full outline-none text-greyblack bg-transparent"
                                + textSize
                                + placeholderSize
                                + paddingType
                                + (hasError ? " placeholder:text-red01 " : " placeholder:text-[#CBD5E1]")
                                + " border-b-2 "
                                + (hasError ? " border-[#BE123C] focus-within:bg-transparent focus-within:border-[#BE123C] " : " focus-within:bg-[#E0F6FA] focus-within:border-[#00B7D4] border-[#CBD5E1] ")
                            }
                            list={list}
                            value={value}
                            placeholder={placeholder}
                            type={type}
                            disabled={disabled}
                            readOnly={readOnly}
                            onChange={(e) => onChange(e)}
                            {...rest}
                        />
                }
            </div>
        </div>

    )
});

CusUnderlineInput.propTypes = {
  className: PropTypes.string,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  list: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  paddingType: PropTypes.string,
  textSize: PropTypes.string,
  placeholderSize: PropTypes.string,
}

export {
    CusUnderlineInput
};
