const List = (rest) => {
    return(
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <g clipPath="url(#clip0_2056_1084)">
                <path d="M7.15365 27.67C6.54439 27.67 6.03432 27.4575 5.62343 27.0466C5.21253 26.6357 5 26.1256 5 25.5163V7.15365C5 6.54439 5.21253 6.03432 5.62343 5.62343C6.03432 5.21253 6.54439 5 7.15365 5H25.5163C26.1256 5 26.6357 5.21253 27.0466 5.62343C27.4575 6.03432 27.67 6.54439 27.67 7.15365V25.5163C27.67 26.1256 27.4575 26.6357 27.0466 27.0466C26.6357 27.4575 26.1256 27.67 25.5163 27.67H7.15365ZM7.15365 25.8847H25.5163C25.6014 25.8847 25.6864 25.8422 25.7714 25.7714C25.8422 25.7005 25.8847 25.6155 25.8847 25.5163V7.15365C25.8847 7.06864 25.8422 6.98362 25.7714 6.89861C25.7005 6.82777 25.6155 6.78526 25.5163 6.78526H7.15365C7.06864 6.78526 6.98362 6.82777 6.89861 6.89861C6.82777 6.96946 6.78526 7.05447 6.78526 7.15365V25.5163C6.78526 25.6014 6.82777 25.6864 6.89861 25.7714C6.96946 25.8422 7.05447 25.8847 7.15365 25.8847Z" fill="#64748B"/>
                <path d="M7.15365 27.67C6.54439 27.67 6.03432 27.4575 5.62343 27.0466C5.21253 26.6357 5 26.1256 5 25.5163V7.15365C5 6.54439 5.21253 6.03432 5.62343 5.62343C6.03432 5.21253 6.54439 5 7.15365 5H25.5163C26.1256 5 26.6357 5.21253 27.0466 5.62343C27.4575 6.03432 27.67 6.54439 27.67 7.15365V25.5163C27.67 26.1256 27.4575 26.6357 27.0466 27.0466C26.6357 27.4575 26.1256 27.67 25.5163 27.67H7.15365ZM7.15365 25.8847H25.5163C25.6014 25.8847 25.6864 25.8422 25.7714 25.7714C25.8422 25.7005 25.8847 25.6155 25.8847 25.5163V7.15365C25.8847 7.06864 25.8422 6.98362 25.7714 6.89861C25.7005 6.82777 25.6155 6.78526 25.5163 6.78526H7.15365C7.06864 6.78526 6.98362 6.82777 6.89861 6.89861C6.82777 6.96946 6.78526 7.05447 6.78526 7.15365V25.5163C6.78526 25.6014 6.82777 25.6864 6.89861 25.7714C6.96946 25.8422 7.05447 25.8847 7.15365 25.8847Z" fill="black" fillOpacity="0.2"/>
                <path d="M7.15365 27.67C6.54439 27.67 6.03432 27.4575 5.62343 27.0466C5.21253 26.6357 5 26.1256 5 25.5163V7.15365C5 6.54439 5.21253 6.03432 5.62343 5.62343C6.03432 5.21253 6.54439 5 7.15365 5H25.5163C26.1256 5 26.6357 5.21253 27.0466 5.62343C27.4575 6.03432 27.67 6.54439 27.67 7.15365V25.5163C27.67 26.1256 27.4575 26.6357 27.0466 27.0466C26.6357 27.4575 26.1256 27.67 25.5163 27.67H7.15365ZM7.15365 25.8847H25.5163C25.6014 25.8847 25.6864 25.8422 25.7714 25.7714C25.8422 25.7005 25.8847 25.6155 25.8847 25.5163V7.15365C25.8847 7.06864 25.8422 6.98362 25.7714 6.89861C25.7005 6.82777 25.6155 6.78526 25.5163 6.78526H7.15365C7.06864 6.78526 6.98362 6.82777 6.89861 6.89861C6.82777 6.96946 6.78526 7.05447 6.78526 7.15365V25.5163C6.78526 25.6014 6.82777 25.6864 6.89861 25.7714C6.96946 25.8422 7.05447 25.8847 7.15365 25.8847Z" fill="black" fillOpacity="0.2"/>
                <path d="M7.15365 27.67C6.54439 27.67 6.03432 27.4575 5.62343 27.0466C5.21253 26.6357 5 26.1256 5 25.5163V7.15365C5 6.54439 5.21253 6.03432 5.62343 5.62343C6.03432 5.21253 6.54439 5 7.15365 5H25.5163C26.1256 5 26.6357 5.21253 27.0466 5.62343C27.4575 6.03432 27.67 6.54439 27.67 7.15365V25.5163C27.67 26.1256 27.4575 26.6357 27.0466 27.0466C26.6357 27.4575 26.1256 27.67 25.5163 27.67H7.15365ZM7.15365 25.8847H25.5163C25.6014 25.8847 25.6864 25.8422 25.7714 25.7714C25.8422 25.7005 25.8847 25.6155 25.8847 25.5163V7.15365C25.8847 7.06864 25.8422 6.98362 25.7714 6.89861C25.7005 6.82777 25.6155 6.78526 25.5163 6.78526H7.15365C7.06864 6.78526 6.98362 6.82777 6.89861 6.89861C6.82777 6.96946 6.78526 7.05447 6.78526 7.15365V25.5163C6.78526 25.6014 6.82777 25.6864 6.89861 25.7714C6.96946 25.8422 7.05447 25.8847 7.15365 25.8847Z" fill="black" fillOpacity="0.2"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M12.7925 11.4337C12.7925 11.0747 13.0835 10.7837 13.4425 10.7837H22.769C23.128 10.7837 23.419 11.0747 23.419 11.4337C23.419 11.7927 23.128 12.0837 22.769 12.0837H13.4425C13.0835 12.0837 12.7925 11.7927 12.7925 11.4337Z" fill="#64748B"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M12.7925 11.4337C12.7925 11.0747 13.0835 10.7837 13.4425 10.7837H22.769C23.128 10.7837 23.419 11.0747 23.419 11.4337C23.419 11.7927 23.128 12.0837 22.769 12.0837H13.4425C13.0835 12.0837 12.7925 11.7927 12.7925 11.4337Z" fill="black" fillOpacity="0.2"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M12.7925 11.4337C12.7925 11.0747 13.0835 10.7837 13.4425 10.7837H22.769C23.128 10.7837 23.419 11.0747 23.419 11.4337C23.419 11.7927 23.128 12.0837 22.769 12.0837H13.4425C13.0835 12.0837 12.7925 11.7927 12.7925 11.4337Z" fill="black" fillOpacity="0.2"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M12.7925 11.4337C12.7925 11.0747 13.0835 10.7837 13.4425 10.7837H22.769C23.128 10.7837 23.419 11.0747 23.419 11.4337C23.419 11.7927 23.128 12.0837 22.769 12.0837H13.4425C13.0835 12.0837 12.7925 11.7927 12.7925 11.4337Z" fill="black" fillOpacity="0.2"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M11.3758 12.2011C11.8316 12.2011 12.2011 11.8316 12.2011 11.3758C12.2011 10.92 11.8316 10.5505 11.3758 10.5505C10.92 10.5505 10.5505 10.92 10.5505 11.3758C10.5505 11.8316 10.92 12.2011 11.3758 12.2011ZM11.3758 13.5011C12.5496 13.5011 13.5011 12.5496 13.5011 11.3758C13.5011 10.202 12.5496 9.25049 11.3758 9.25049C10.202 9.25049 9.25049 10.202 9.25049 11.3758C9.25049 12.5496 10.202 13.5011 11.3758 13.5011Z" fill="#64748B"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M11.3758 12.2011C11.8316 12.2011 12.2011 11.8316 12.2011 11.3758C12.2011 10.92 11.8316 10.5505 11.3758 10.5505C10.92 10.5505 10.5505 10.92 10.5505 11.3758C10.5505 11.8316 10.92 12.2011 11.3758 12.2011ZM11.3758 13.5011C12.5496 13.5011 13.5011 12.5496 13.5011 11.3758C13.5011 10.202 12.5496 9.25049 11.3758 9.25049C10.202 9.25049 9.25049 10.202 9.25049 11.3758C9.25049 12.5496 10.202 13.5011 11.3758 13.5011Z" fill="black" fillOpacity="0.2"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M11.3758 12.2011C11.8316 12.2011 12.2011 11.8316 12.2011 11.3758C12.2011 10.92 11.8316 10.5505 11.3758 10.5505C10.92 10.5505 10.5505 10.92 10.5505 11.3758C10.5505 11.8316 10.92 12.2011 11.3758 12.2011ZM11.3758 13.5011C12.5496 13.5011 13.5011 12.5496 13.5011 11.3758C13.5011 10.202 12.5496 9.25049 11.3758 9.25049C10.202 9.25049 9.25049 10.202 9.25049 11.3758C9.25049 12.5496 10.202 13.5011 11.3758 13.5011Z" fill="black" fillOpacity="0.2"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M11.3758 12.2011C11.8316 12.2011 12.2011 11.8316 12.2011 11.3758C12.2011 10.92 11.8316 10.5505 11.3758 10.5505C10.92 10.5505 10.5505 10.92 10.5505 11.3758C10.5505 11.8316 10.92 12.2011 11.3758 12.2011ZM11.3758 13.5011C12.5496 13.5011 13.5011 12.5496 13.5011 11.3758C13.5011 10.202 12.5496 9.25049 11.3758 9.25049C10.202 9.25049 9.25049 10.202 9.25049 11.3758C9.25049 12.5496 10.202 13.5011 11.3758 13.5011Z" fill="black" fillOpacity="0.2"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M19.8774 21.2343C19.8774 21.5933 19.5864 21.8843 19.2274 21.8843L9.90088 21.8843C9.54189 21.8843 9.25088 21.5933 9.25088 21.2343C9.25088 20.8753 9.54189 20.5843 9.90088 20.5843L19.2274 20.5843C19.5864 20.5843 19.8774 20.8753 19.8774 21.2343Z" fill="#64748B"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M19.8774 21.2343C19.8774 21.5933 19.5864 21.8843 19.2274 21.8843L9.90088 21.8843C9.54189 21.8843 9.25088 21.5933 9.25088 21.2343C9.25088 20.8753 9.54189 20.5843 9.90088 20.5843L19.2274 20.5843C19.5864 20.5843 19.8774 20.8753 19.8774 21.2343Z" fill="black" fillOpacity="0.2"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M19.8774 21.2343C19.8774 21.5933 19.5864 21.8843 19.2274 21.8843L9.90088 21.8843C9.54189 21.8843 9.25088 21.5933 9.25088 21.2343C9.25088 20.8753 9.54189 20.5843 9.90088 20.5843L19.2274 20.5843C19.5864 20.5843 19.8774 20.8753 19.8774 21.2343Z" fill="black" fillOpacity="0.2"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M19.8774 21.2343C19.8774 21.5933 19.5864 21.8843 19.2274 21.8843L9.90088 21.8843C9.54189 21.8843 9.25088 21.5933 9.25088 21.2343C9.25088 20.8753 9.54189 20.5843 9.90088 20.5843L19.2274 20.5843C19.5864 20.5843 19.8774 20.8753 19.8774 21.2343Z" fill="black" fillOpacity="0.2"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M21.2941 20.4688C20.8383 20.4688 20.4688 20.8383 20.4688 21.2941C20.4688 21.7499 20.8383 22.1194 21.2941 22.1194C21.7499 22.1194 22.1194 21.7499 22.1194 21.2941C22.1194 20.8383 21.7499 20.4688 21.2941 20.4688ZM21.2941 19.1688C20.1203 19.1688 19.1688 20.1203 19.1688 21.2941C19.1688 22.4679 20.1203 23.4194 21.2941 23.4194C22.4679 23.4194 23.4194 22.4679 23.4194 21.2941C23.4194 20.1203 22.4679 19.1688 21.2941 19.1688Z" fill="#64748B"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M21.2941 20.4688C20.8383 20.4688 20.4688 20.8383 20.4688 21.2941C20.4688 21.7499 20.8383 22.1194 21.2941 22.1194C21.7499 22.1194 22.1194 21.7499 22.1194 21.2941C22.1194 20.8383 21.7499 20.4688 21.2941 20.4688ZM21.2941 19.1688C20.1203 19.1688 19.1688 20.1203 19.1688 21.2941C19.1688 22.4679 20.1203 23.4194 21.2941 23.4194C22.4679 23.4194 23.4194 22.4679 23.4194 21.2941C23.4194 20.1203 22.4679 19.1688 21.2941 19.1688Z" fill="black" fillOpacity="0.2"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M21.2941 20.4688C20.8383 20.4688 20.4688 20.8383 20.4688 21.2941C20.4688 21.7499 20.8383 22.1194 21.2941 22.1194C21.7499 22.1194 22.1194 21.7499 22.1194 21.2941C22.1194 20.8383 21.7499 20.4688 21.2941 20.4688ZM21.2941 19.1688C20.1203 19.1688 19.1688 20.1203 19.1688 21.2941C19.1688 22.4679 20.1203 23.4194 21.2941 23.4194C22.4679 23.4194 23.4194 22.4679 23.4194 21.2941C23.4194 20.1203 22.4679 19.1688 21.2941 19.1688Z" fill="black" fillOpacity="0.2"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M21.2941 20.4688C20.8383 20.4688 20.4688 20.8383 20.4688 21.2941C20.4688 21.7499 20.8383 22.1194 21.2941 22.1194C21.7499 22.1194 22.1194 21.7499 22.1194 21.2941C22.1194 20.8383 21.7499 20.4688 21.2941 20.4688ZM21.2941 19.1688C20.1203 19.1688 19.1688 20.1203 19.1688 21.2941C19.1688 22.4679 20.1203 23.4194 21.2941 23.4194C22.4679 23.4194 23.4194 22.4679 23.4194 21.2941C23.4194 20.1203 22.4679 19.1688 21.2941 19.1688Z" fill="black" fillOpacity="0.2"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M17.752 16.3961C17.752 16.0371 18.043 15.7461 18.402 15.7461H22.7695C23.1284 15.7461 23.4195 16.0371 23.4195 16.3961C23.4195 16.7551 23.1284 17.0461 22.7695 17.0461H18.402C18.043 17.0461 17.752 16.7551 17.752 16.3961Z" fill="#64748B"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M17.752 16.3961C17.752 16.0371 18.043 15.7461 18.402 15.7461H22.7695C23.1284 15.7461 23.4195 16.0371 23.4195 16.3961C23.4195 16.7551 23.1284 17.0461 22.7695 17.0461H18.402C18.043 17.0461 17.752 16.7551 17.752 16.3961Z" fill="black" fillOpacity="0.2"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M17.752 16.3961C17.752 16.0371 18.043 15.7461 18.402 15.7461H22.7695C23.1284 15.7461 23.4195 16.0371 23.4195 16.3961C23.4195 16.7551 23.1284 17.0461 22.7695 17.0461H18.402C18.043 17.0461 17.752 16.7551 17.752 16.3961Z" fill="black" fillOpacity="0.2"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M17.752 16.3961C17.752 16.0371 18.043 15.7461 18.402 15.7461H22.7695C23.1284 15.7461 23.4195 16.0371 23.4195 16.3961C23.4195 16.7551 23.1284 17.0461 22.7695 17.0461H18.402C18.043 17.0461 17.752 16.7551 17.752 16.3961Z" fill="black" fillOpacity="0.2"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M9.25049 16.3961C9.25049 16.0371 9.5415 15.7461 9.90049 15.7461H14.268C14.627 15.7461 14.918 16.0371 14.918 16.3961C14.918 16.7551 14.627 17.0461 14.268 17.0461H9.90049C9.5415 17.0461 9.25049 16.7551 9.25049 16.3961Z" fill="#64748B"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M9.25049 16.3961C9.25049 16.0371 9.5415 15.7461 9.90049 15.7461H14.268C14.627 15.7461 14.918 16.0371 14.918 16.3961C14.918 16.7551 14.627 17.0461 14.268 17.0461H9.90049C9.5415 17.0461 9.25049 16.7551 9.25049 16.3961Z" fill="black" fillOpacity="0.2"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M9.25049 16.3961C9.25049 16.0371 9.5415 15.7461 9.90049 15.7461H14.268C14.627 15.7461 14.918 16.0371 14.918 16.3961C14.918 16.7551 14.627 17.0461 14.268 17.0461H9.90049C9.5415 17.0461 9.25049 16.7551 9.25049 16.3961Z" fill="black" fillOpacity="0.2"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M9.25049 16.3961C9.25049 16.0371 9.5415 15.7461 9.90049 15.7461H14.268C14.627 15.7461 14.918 16.0371 14.918 16.3961C14.918 16.7551 14.627 17.0461 14.268 17.0461H9.90049C9.5415 17.0461 9.25049 16.7551 9.25049 16.3961Z" fill="black" fillOpacity="0.2"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M16.3348 17.1616C16.7906 17.1616 17.1601 16.7921 17.1601 16.3363C17.1601 15.8804 16.7906 15.5109 16.3348 15.5109C15.879 15.5109 15.5095 15.8804 15.5095 16.3363C15.5095 16.7921 15.879 17.1616 16.3348 17.1616ZM16.3348 18.4616C17.5086 18.4616 18.4601 17.51 18.4601 16.3363C18.4601 15.1625 17.5086 14.2109 16.3348 14.2109C15.161 14.2109 14.2095 15.1625 14.2095 16.3363C14.2095 17.51 15.161 18.4616 16.3348 18.4616Z" fill="#64748B"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M16.3348 17.1616C16.7906 17.1616 17.1601 16.7921 17.1601 16.3363C17.1601 15.8804 16.7906 15.5109 16.3348 15.5109C15.879 15.5109 15.5095 15.8804 15.5095 16.3363C15.5095 16.7921 15.879 17.1616 16.3348 17.1616ZM16.3348 18.4616C17.5086 18.4616 18.4601 17.51 18.4601 16.3363C18.4601 15.1625 17.5086 14.2109 16.3348 14.2109C15.161 14.2109 14.2095 15.1625 14.2095 16.3363C14.2095 17.51 15.161 18.4616 16.3348 18.4616Z" fill="black" fillOpacity="0.2"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M16.3348 17.1616C16.7906 17.1616 17.1601 16.7921 17.1601 16.3363C17.1601 15.8804 16.7906 15.5109 16.3348 15.5109C15.879 15.5109 15.5095 15.8804 15.5095 16.3363C15.5095 16.7921 15.879 17.1616 16.3348 17.1616ZM16.3348 18.4616C17.5086 18.4616 18.4601 17.51 18.4601 16.3363C18.4601 15.1625 17.5086 14.2109 16.3348 14.2109C15.161 14.2109 14.2095 15.1625 14.2095 16.3363C14.2095 17.51 15.161 18.4616 16.3348 18.4616Z" fill="black" fillOpacity="0.2"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M16.3348 17.1616C16.7906 17.1616 17.1601 16.7921 17.1601 16.3363C17.1601 15.8804 16.7906 15.5109 16.3348 15.5109C15.879 15.5109 15.5095 15.8804 15.5095 16.3363C15.5095 16.7921 15.879 17.1616 16.3348 17.1616ZM16.3348 18.4616C17.5086 18.4616 18.4601 17.51 18.4601 16.3363C18.4601 15.1625 17.5086 14.2109 16.3348 14.2109C15.161 14.2109 14.2095 15.1625 14.2095 16.3363C14.2095 17.51 15.161 18.4616 16.3348 18.4616Z" fill="black" fillOpacity="0.2"/>
            </g>
            <defs>
                <clipPath id="clip0_2056_1084">
                    <rect width="22.67" height="22.67" fill="white" transform="translate(5 5)"/>
                </clipPath>
            </defs>
        </svg>

    )
}

export default List;