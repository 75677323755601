import { EDITABLE_TYPE, setDefaultField, validateField } from 'components/CusEditable';
import CusEditableLeftLabel from 'components/CusEditable/CusEditableLeftLabel';
import CusModal from 'components/CusModal';
import { Color as ButtonColor, CusRoundedButton, PaddingType as ButtonPadding, RoundType as ButtonRoundType } from 'components/CusRoundedButton';
import DynamicContent from 'components/DynamicContent';
import ExpanableListItem from 'components/ExpanableListItem';
import Bin from 'components/Icon/Bin';
import SubTasks from 'components/Icon/SubTasks';
import { useLanguage } from 'hooks/useLanguage';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAttachmentList, getError as getAttachmentError, getLoading as getAttachmentLoading } from 'services/attachment/attachment';
import { capitalise, checkListItemDuplication } from 'util/helper';
import FlowSettingDropdown from '../FlowSettingDropdown';
import ResponseOptionListItem, { RESPONSE_OPTION_FIELD_TEMPLATE, validateOptionListItems } from './FlowSettingList/ResponseOptionListItem';
import { WarningSnackbar } from "components/WarningSnackbar";
import { addFieldItemAction, removeFieldItemAction, updateFieldItemAction } from 'util/helper';
import { useIntl } from 'react-intl';
import { generalPrefix, settingPrefix, editChatbotPrefix, editResponsePrefix } from 'lang/locales/prefix';
import Scrollbars from 'react-custom-scrollbars-2';
import Plus from 'components/Icon/Plus';
import Minimise from 'components/Icon/Minimise';
import NonExpanableListItem from 'components/NonExpanableListItem';

const RESPONSE_SETUP_FIELD_TYPE = [
    {
        "fieldName": "name",
        "displayNameKey": `${settingPrefix}.${editResponsePrefix}.field.setup.name`,
        "displayNameEn": "Name",
        "displayNameTc": "響應名稱",
        "displayNameSc": "响应名称",
        "fieldType": EDITABLE_TYPE.TEXT,
        "required": true,
        "editable": true,
        "creatable": true,
    },
    // {
    //     "fieldName": "attachmentId",
    //     "displayNameKey": `${settingPrefix}.${editResponsePrefix}.field.setup.attachmentId`,
    //     "displayNameEn": "Attachment",
    //     "displayNameTc": "附件",
    //     "displayNameSc": "附件",
    //     "fieldType": EDITABLE_TYPE.PICKLIST,
    //     "required": false,
    //     "editable": true,
    //     "creatable": true,
    // },
]

const RESPONSE_VALUE_FIELD_TYPE = {
    "fieldName": "value",
    "displayNameKey": `${settingPrefix}.${editResponsePrefix}.field.message.value`,
    "displayNameEn": "Response message",
    "displayNameTc": "回复信息",
    "displayNameSc": "回复信息",
    "fieldType": EDITABLE_TYPE.TEXTAREA,
    "required": true,
    "editable": true,
    "creatable": true,
}

const RESPONSE_VALUE_FIELD_TEMPLATE = Object.freeze({
    "value" : "",
})

const EditResponse = ({
    isEditable = false,
    isEdit = false,
    setIsEdit = () => {},
    item = null,
    updateAction = null,
    removeAction = null,
    parameterList = [],
    attachmentList = [],
}) => {

    const isIframe = window.location !== window.parent.location;

    const [warningOpen, setWarningOpen] = useState(false);
    const [warning, setWarning] = useState("");

    const [showMenu, setShowMenu] = useState(false);
    const [menuPos, setMenuPos] = useState(null);

    const [errorMap, setErrorMap] = useState({});
    const [editableResponse, setEditableResponse] = useState({})

    const [responseMessageList, setResponseMessageList] = useState([])
    const [responseMessageErrorList, setResponseMessageErrorList] = useState([]);

    const [selectedMessage, setSelectedMessage] = useState(-1);

    const [responseOptionList, setResponseOptionList] = useState([])
    const [responseOptionErrorList, setResponseOptionErrorList] = useState([]);

    const intl = useIntl();
    const { language } = useLanguage()

    const getOptions = (fieldType, fieldName, options) => {
        if(fieldType !== EDITABLE_TYPE.PICKLIST)
            return []

        if(fieldName === "attachmentId"){
            let options = [{label: intl.formatMessage({ "id": `${settingPrefix}.${editResponsePrefix}.field.setup.attachmentId` }), value: ""}]
            return [...options, ...attachmentList.map(attachment => ({label: attachment.fileName, value: attachment.id}))]
        }

        return options.map(option => ({label: option, value: option}))
    }

    const onChangeHandler = (isCreate, field, e) => {
        
        let value = e.target.value;
        let errorMap = {};

        switch (field.fieldType) {
            case EDITABLE_TYPE.BOOLEAN:
                value = !editableResponse[field.fieldName]
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;        
            default:
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;
        }

        if(errorMap && Object.keys(errorMap).length > 0)
            setErrorMap((erMap) => ({...erMap, ...errorMap}))
        else{
            setErrorMap((erMap) => {
                let newErrorMap = {...erMap}
                delete newErrorMap[field.fieldName]
                return newErrorMap 
            })
        }

        setEditableResponse((editableFields) => ({...editableFields, [field.fieldName]: value}))
    }

    const onResponseMessageListItemChange = (isCreate, field, index, e) => {
        let value = e.target.value;
        updateResponseMessage({value: value}, validateField(field, value, intl.formatMessage, isCreate), index)
    }

    const openParameterList = (e, index) => {
        setSelectedMessage(index)
        let pos = e.target.closest('.menu-pos').getBoundingClientRect()
        console.log(pos)
        setMenuPos({x: pos.right, y: pos.bottom + 5})
        setShowMenu(true)
    }

    const copyToMessageField = (parameter) => {
        if(selectedMessage === -1)
            return;

        let value = responseMessageList[selectedMessage].value ?? "";
        updateResponseMessage({value: `${value} {!${parameter.key}}`}, {}, selectedMessage)    

        // https://stackoverflow.com/questions/51805395/navigator-clipboard-is-undefined
        if(isIframe){
            const textArea = document.createElement("textarea");
            textArea.value = `{!${parameter.key}}`;
                
            // Move textarea out of the viewport so it's not visible
            textArea.style.position = "absolute";
            textArea.style.left = "-999999px";
                
            document.body.prepend(textArea);
            textArea.select();
    
            try {
                document.execCommand('copy');
            } catch (error) {
                console.error(error);
            } finally {
                textArea.remove();
            }
        }
        else
            navigator.clipboard.writeText(`{!${parameter.key}}`)

        setWarning(`Copied Parameter ${parameter.key}`)
        setWarningOpen(true)
    }

    const addResponseMessage = () => addFieldItemAction({itemTemplate: RESPONSE_VALUE_FIELD_TEMPLATE, setFieldList: setResponseMessageList, setFieldErrorList: setResponseMessageErrorList})
    const updateResponseMessage = (field, error, index) => updateFieldItemAction({field, error, index, fieldList: responseMessageList, fieldErrorList: responseMessageErrorList, setFieldList: setResponseMessageList, setFieldErrorList: setResponseMessageErrorList})
    const removeResponseMessage = (index) => removeFieldItemAction({index, setFieldList: setResponseMessageList, setFieldErrorList: setResponseMessageErrorList})

    const addResponseOption = () => addFieldItemAction({itemTemplate: RESPONSE_OPTION_FIELD_TEMPLATE, setFieldList: setResponseOptionList, setFieldErrorList: setResponseOptionErrorList})
    const updateResponseOption = (field, error, index) => updateFieldItemAction({field, error, index, fieldList: responseOptionList, fieldErrorList: responseOptionErrorList, setFieldList: setResponseOptionList, setFieldErrorList: setResponseOptionErrorList})
    const removeResponseOption = (index) => removeFieldItemAction({index, setFieldList: setResponseOptionList, setFieldErrorList: setResponseOptionErrorList})

    const validateMessageListItems = (isCreate) => {
        let errorList = [];
    
        for(let index = 0; index < responseMessageList.length; index++){
            let conditionListItem = {...responseMessageList[index]}
            let errorMap = {}
    
            let mapping = [{...RESPONSE_VALUE_FIELD_TYPE}]
    
            for(let field of mapping){
                if(!conditionListItem[field.fieldName])
                    setDefaultField(field, conditionListItem, field.fieldName, isCreate)
                errorMap = {...errorMap, ...validateField(field, conditionListItem[field.fieldName], intl.formatMessage, isCreate)}
            }
    
            errorList[index] = errorMap
        }

        // let responseSet = responseMessageList.map(field => field.value)

        // let responseSetDuplicates = checkListItemDuplication(responseSet)
        // console.log(responseSetDuplicates)
        // responseSetDuplicates.forEach(i => {
        //     if(!errorList[i])
        //         errorList[i] = {}
    
        //     errorList[i].value = { message: ({ "id": `${errorPrefix}.duplicates` }) }
        // })
    
        return errorList
    }

    const validateFields = (isCreate) => {
        let errorMap = {}

        let mapping = [ ...RESPONSE_SETUP_FIELD_TYPE ]

        for(let field of mapping){
            if(!editableResponse[field.fieldName])
                setDefaultField(field, editableResponse, field.fieldName, isCreate)
            errorMap = {...errorMap, ...validateField(field, editableResponse[field.fieldName], intl.formatMessage, isCreate)}
        }

        let responseMessageErrorList = validateMessageListItems(isCreate)
        let responseOptionErrorList = validateOptionListItems(intl.formatMessage, isCreate, responseOptionList)

        let hasError = false;

        if(errorMap && Object.keys(errorMap).length > 0)
            hasError = true;

        console.log(responseMessageErrorList)
        
        if(responseMessageErrorList.filter(error => Object.keys(error).length > 0).length > 0)
            hasError = true;

        if(responseOptionErrorList.filter(error => Object.keys(error).length > 0).length > 0)
            hasError = true;

        if(hasError){
            console.log(errorMap)
            setResponseMessageErrorList(responseMessageErrorList)
            setResponseOptionErrorList(responseOptionErrorList)
            setErrorMap(errorMap)
            return;
        }

        let completeObject = {...editableResponse}
        let optionMap = {}

        completeObject.messageList = responseMessageList.map(response => response.value)
        optionMap = responseOptionList.reduce((map, response) => ({...map, [response.name]: response.value}), {})

        updateAction({...completeObject, optionMap})
    }

    const refresh = () => {
        let editableResponse = JSON.parse(JSON.stringify(item))

        console.log(editableResponse)

        let responseMessageList = [];
        let responseOptionList = [];

        if(!!editableResponse.messageList && editableResponse.messageList.length > 0)
            responseMessageList = editableResponse.messageList.map(message => ({value: message}))

        console.log(responseMessageList)

        if(responseMessageList.length < 1){
            setResponseMessageList([{...RESPONSE_VALUE_FIELD_TEMPLATE}])
            setResponseMessageErrorList([{}])
        }
        else{
            setResponseMessageList(responseMessageList)
            setResponseMessageErrorList(Array(responseMessageList.length).fill({}))
        }

        if(editableResponse.optionMap && Object.keys(editableResponse.optionMap).length > 0)
            Object.entries(editableResponse.optionMap).forEach(([name, value]) => responseOptionList.push({name, value}))

        setResponseOptionList(responseOptionList)
        setResponseOptionErrorList(Array(responseOptionList.length).fill({}))

        setEditableResponse(editableResponse)
        setErrorMap({})

        setIsEdit(isEditable && !item.id)
    }

    useEffect(() => {
        refresh()
    }, [item])

    return (
        <>
            <WarningSnackbar message={warning} open={warningOpen} setOpen={setWarningOpen}/>
            <div className="w-full h-full">
                <div className='w-full'><hr/></div>
                <div className={`w-full ${isEditable ? "h-[calc(100%-72px)]" : "h-full"}`}>
                    <Scrollbars
                        className="w-full h-full"
                        autoHide={false}
                        autoHideDuration={100}
                    >
                        <div className='w-full h-full flex flex-col'>
                            <span className='px-4 pt-[18px] text-[#64748B]'>DETAILS</span>
                            <div className='px-4'>
                                {RESPONSE_SETUP_FIELD_TYPE.map((field) => (
                                    <div key={field.fieldName} className='py-2'>
                                        <CusEditableLeftLabel
                                            readOnly={!isEdit || !isEditable || (!!item.id ? !field.editable : !field.creatable)}
                                            fieldType={field.fieldType}
                                            label={field.displayNameEn}
                                            value={editableResponse[field.fieldName] ?? field.defaultValue}
                                            options={getOptions(field.fieldType, field.fieldName, field.options)}
                                            errors={errorMap}
                                            required={field.required}
                                            errorName={field.fieldName}
                                            onChange={(e) => onChangeHandler(item.isCreate, field, e)}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className='w-full'><hr/></div>
                            <span className='px-4 pt-[18px] text-[#64748B]'>SETUP</span>
                            <div className='p-4'>
                                {isEditable && isEdit && 
                                    <div className="flex flex-col">
                                        <div className="flex w-full items-center">
                                            <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex text-base h-[40px] items-center'
                                                color={ButtonColor.primaryNoBorder}
                                                onClick={() => addResponseMessage()}
                                            >
                                                <Plus/>
                                                <span className='pl-2 text-base'>{intl.formatMessage({ "id": `${settingPrefix}.${editResponsePrefix}.button.addMessage` })}</span>
                                            </CusRoundedButton>
                                        </div>
                                    </div>
                                }
                                {responseMessageList.length > 0 &&
                                    <div className='px-4 w-full flex flex-col border-[#CBD5E1] border-[1px] rounded-[8px]'>
                                        {responseMessageList.map((field, index) => (
                                            <div key={`MESSAGE-${index}`}>
                                                <div className={`flex flex-col py-3 max-w-full`}>
                                                    <div className='flex justify-between items-center h-[24px]'>
                                                        <span className="text-[#94A3B8] text-[13px]">{`Response #${index + 1}`}</span>
                                                        <div className='flex items-center'>
                                                            {isEdit &&
                                                                <div className={`text-[#94A3B8] pr-2`}>
                                                                    <div className="cursor-pointer menu-pos" onClick={(e) => openParameterList(e, index)}>
                                                                        <SubTasks className="w-[24px] h-[24px]"/>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className={`text-[#94A3B8]`}>
                                                                <div className={`${ (index === 0 || !isEdit || !isEditable) ? "opacity-50" : "cursor-pointer"}`} onClick={(index === 0 || !isEdit || !isEditable) ? null : () => removeResponseMessage(index)}>
                                                                    <Minimise className="w-[24px] h-[24px]"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <CusEditableLeftLabel
                                                            readOnly={!isEdit || !isEditable || (!!item.id ? !RESPONSE_VALUE_FIELD_TYPE.editable : !RESPONSE_VALUE_FIELD_TYPE.creatable)}
                                                            fieldType={RESPONSE_VALUE_FIELD_TYPE.fieldType}
                                                            label={RESPONSE_VALUE_FIELD_TYPE.displayNameEn}
                                                            showLabelOnLeft={false}
                                                            value={field[RESPONSE_VALUE_FIELD_TYPE.fieldName]}
                                                            errors={(!!responseMessageErrorList && responseMessageErrorList[index]) ? responseMessageErrorList[index] : {}}
                                                            required={RESPONSE_VALUE_FIELD_TYPE.required}
                                                            errorName={RESPONSE_VALUE_FIELD_TYPE.fieldName}
                                                            onChange={(e) => onResponseMessageListItemChange(!item.id, RESPONSE_VALUE_FIELD_TYPE, index, e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='w-full'><hr/></div>
                                            </div>
                                        ))}
                                    </div>
                                }
                                <div className='pt-[18px]'></div>
                                {isEditable && isEdit && 
                                    <div className="flex flex-col">
                                        <div className="flex w-full items-center">
                                            <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex text-base h-[40px] items-center'
                                                color={ButtonColor.primaryNoBorder}
                                                onClick={() => addResponseOption()}
                                            >
                                                <Plus/>
                                                <span className='pl-2 text-base'>{intl.formatMessage({ "id": `${settingPrefix}.${editResponsePrefix}.button.addOption` })}</span>
                                            </CusRoundedButton>
                                        </div>
                                    </div>
                                }
                                {responseOptionList.length > 0 &&
                                    <div className='px-4 w-full flex flex-col border-[#CBD5E1] border-[1px] rounded-[8px]'>
                                        {responseOptionList.map((field, index) => (
                                            <ResponseOptionListItem
                                                key={`OPTION-${index}`}
                                                isCreate={!item.id}
                                                isEdit={isEdit}
                                                isEditable={isEditable}
                                                itemIndex={index}
                                                fieldListItem={field}
                                                error={responseOptionErrorList[index]}
                                                updateField={(field, error) => updateResponseOption(field, error, index)}
                                                removeField={() => removeResponseOption(index)}
                                            />
                                        ))}
                                    </div>
                                }                       
                            </div>
                        </div>
                    </Scrollbars>
                </div>
                {isEditable && 
                    isEdit ?
                        <div className="p-4 w-full flex justify-center items-center h-[72px] border-t-[1px] border-[#CBD5E140]">
                                <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex justify-center text-base flex-1 px-4 h-[40px] items-center rounded-[5px]' color={ButtonColor.primary}
                                        onClick={() => validateFields(!item.id)}
                                >
                                        <span className="px-4">{!item.id ? "Create" : "Update"}</span>
                                </CusRoundedButton>
                        </div>
                    :
                        <div className="p-4 w-full flex justify-between items-center h-[72px] border-t-[1px] border-[#CBD5E140]">
                                <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex justify-center text-base flex-shrink-0 px-4 h-[40px] items-center rounded-[5px]' color={ButtonColor.darkNoBorder}
                                        onClick={() => removeAction()}
                                >
                                        <span className="">Delete</span>
                                </CusRoundedButton>
                                <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex justify-center text-base flex-shrink-0 px-4 h-[40px] items-center rounded-[5px]' color={ButtonColor.primary}
                                        onClick={() => setIsEdit(true)}
                                >
                                        <span className="">Edit</span>
                                </CusRoundedButton>
                        </div>
                }
            </div>
            <DynamicContent screenX={menuPos ? menuPos.x : undefined} screenY={menuPos ? menuPos.y : -1} show={showMenu} setShow={setShowMenu} onUnFocus={() => {setMenuPos(null);setSelectedMessage(-1)}}>
                <div className='text-greyblack'>
                    <FlowSettingDropdown
                        readOnly
                        searchTitle={`${intl.formatMessage({ "id": `${settingPrefix}.${editChatbotPrefix}.text.search`})}${intl.formatMessage({ "id": `${settingPrefix}.${editChatbotPrefix}.nameList.setting.parameter`})}`}
                        settingList={parameterList}
                        fieldName="key"
                        editAction={(setting) => copyToMessageField(setting)}
                    />
                </div>
            </DynamicContent>
        </>
    )
}

EditResponse.propTypes = {
    isEditable: PropTypes.bool,
    isEdit: PropTypes.bool,
    setIsEdit: PropTypes.func,
    item: PropTypes.object,
    updateAction: PropTypes.func,
    removeAction: PropTypes.func,
    parameterList: PropTypes.arrayOf(PropTypes.object),
    attachmentList: PropTypes.arrayOf(PropTypes.object),
};

export default EditResponse;

