import Plus from 'components/Icon/Plus';
import PropTypes from 'prop-types';
import React from 'react';

const NodeSettingAddItem = ({
	isChild = false,
	addNewAction = () => {},
}) => {

	return (
		<div className='w-full h-[48px] flex items-center truncate overflow-hidden'>
			{/* {isChild && <div className='w-[1px] h-full border-[#CBD5E1] border-l-[1px]'/>} */}
			{isChild && <div className='w-[10px] h-[1px] border-[#CBD5E1] border-b-[1px]'/>}
			<div
				className={`
					flex cursor-pointer truncate overflow-hidden 
					items-center my-3 px-3 py-2
					${isChild ? "w-[calc(100%-10px)]" : "w-full"}`
				}
				onClick={() => addNewAction()}
			>
				<div
					className='flex justify-center items-center text-[#94A3B8] w-[24px] h-[24px]'
				>
					<Plus/>
				</div>
				<div
					title="Add Note"
					className={`truncate text-[#94A3B8] text-base w-[calc(100%-24px)]`}
				>
					Add Note
				</div>
			</div>
		</div>
	)
}

NodeSettingAddItem.propTypes = {
	isChild: PropTypes.bool,
	addNewAction: PropTypes.func,
}

export default NodeSettingAddItem;