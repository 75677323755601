import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import CusGroupDropdown from 'components/CusGroupDropdown';
import { BorderType as InputBorder } from 'components/CusRoundedInput';
import CusRoundedSearch from 'components/CusRoundedInput/CusRoundedSearch';
import Bin from 'components/Icon/Bin';
import CirclePlus from 'components/Icon/CirclePlus';

const FlowSettingDropdown = ({
    readOnly = false,
    searchTitle = "",
    addTitle = "",
    settingList = [],
    fieldName = "",
    addNewAction = () => {},
    editAction = () => {},
    // checkRemoveable = () => true,
    removeAction = () => {},
}) =>{

    const [searchInput, setSearchInput] = useState("");

    const getFilteredSettingList = () => {
        let filteredSettingList = JSON.parse(JSON.stringify(settingList));

        if(filteredSettingList.length < 1)
            return filteredSettingList;

        filteredSettingList = filteredSettingList.filter(setting => setting[fieldName].toLowerCase().includes(searchInput.toLowerCase()))

        return filteredSettingList;

    }

    useEffect(() => {
        setSearchInput("")
    }, [settingList])

    let filteredSettingList = getFilteredSettingList();

	return (
        <CusGroupDropdown
            cardSize="w-[250px] h-auto"
            contentSize="max-h-[45vh] overflow-auto"
        >
            <>
                <CusRoundedSearch
                    placeholder={searchTitle}
                    borderType={InputBorder.none}
                    value={searchInput}
                    onChange={(e) => {
                        e.stopPropagation()
                        setSearchInput(e.target.value)}
                    }
                    cancelSearch={() => setSearchInput("")}
                />
                {readOnly || searchInput.length > 0 ?
                    <></>
                    :
                    <>
                        <hr/>
                        <div
                            className={`flex cursor-pointer p-4 text-blue04 text-sm`}
                            onClick={() => addNewAction()}
                        >
                            <div>{addTitle}</div>
                            <div className="px-4"><CirclePlus/></div>
                        </div>
                    </>
                }
            </>
            {filteredSettingList.map((setting) => (
                <CusGroupDropdown.Item
                    className="justify-between"
                    key={`setting-dropdown-${setting.id}`}
                    onClick={() => editAction(setting)}                    
                >
                    <>
                        <span title={setting[fieldName]} className="flex items-center truncate "><span className="truncate ">{setting[fieldName]}</span></span>
                        <div className="flex shrink-0 text-grey07 items-center">
                            {/* <div
                                className="mx-1"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editAction(setting)
                                }}
                            >
                                <Edit className="w-[14px] h-[14px]"/>
                            </div> */}
                            {/* {!readOnly && checkRemoveable(setting) ? */}
                            {readOnly ?
                                null
                                :
                                <div
                                    className="mx-1"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        removeAction(setting)
                                    }}
                                >
                                    <Bin/>
                                </div>
                            }
                        </div>
                    </>
                </CusGroupDropdown.Item>
            ))}
        </CusGroupDropdown>
	);
	
}

FlowSettingDropdown.propTypes = {
    readOnly: PropTypes.bool,
    searchTitle: PropTypes.string,
    addTitle: PropTypes.string,
    settingList: PropTypes.arrayOf(PropTypes.object),
    fieldName: PropTypes.string,
    addNewAction: PropTypes.func,
    editAction: PropTypes.func,
    // checkRemoveable: PropTypes.func,
    removeAction: PropTypes.func,
};

export default FlowSettingDropdown;
