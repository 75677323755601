import CusEditable, { EDITABLE_TYPE, setDefaultField, validateField } from 'components/CusEditable';
import CusEditableLeftLabel from 'components/CusEditable/CusEditableLeftLabel';
import CusModal from 'components/CusModal';
import { Color as ButtonColor, CusRoundedButton, PaddingType as ButtonPadding, RoundType as ButtonRoundType } from 'components/CusRoundedButton';
import ExpanableListItem from 'components/ExpanableListItem';
import Bin from 'components/Icon/Bin';
import { useLanguage } from 'hooks/useLanguage';
import { generalPrefix, settingPrefix, editChatbotPrefix, editParameterPrefix } from 'lang/locales/prefix';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useIntl } from 'react-intl';
import { capitalise, checkListItemDuplication } from 'util/helper';

const PARAMETER_TYPE = Object.freeze({
    "<?MATCH_INPUT>": "<?MATCH_INPUT>",
    "<?MATCH_KEYWORD>": "<?MATCH_KEYWORD>",
    "<?MATCH_ENTITY>": "<?MATCH_ENTITY>",
    "<?MATCH_OPTION>": "<?MATCH_OPTION>",
    "<?MATCH_MEDIA>": "<?MATCH_MEDIA>",
    "CUSTOM": "CUSTOM",
}) 

const PARAMETER_MAP_CUSTOM_TYPE_LIST = Object.freeze([
    "CUSTOM"
])

export const PARAMETER_MAP_TYPE_LIST = Object.freeze([
    "<?MATCH_INPUT>", "<?MATCH_KEYWORD>", "<?MATCH_ENTITY>", "<?MATCH_OPTION>", "<?MATCH_MEDIA>"
])


const PARAMETER_SETUP_FIELD_TYPE = [
    {
        "fieldName": "key",
        "displayNameKey": `${settingPrefix}.${editParameterPrefix}.field.setup.key`,
        "displayNameEn": "Key",
        "displayNameTc": "參數鍵",
        "displayNameSc": "参数键",
        "fieldType": EDITABLE_TYPE.TEXT,
        "required": true,
        "editable": false,
        "creatable": true,
    },
    {
        "fieldName": "type",
        "displayNameKey": `${settingPrefix}.${editParameterPrefix}.field.setup.type`,
        "displayNameEn": "Type",
        "displayNameTc": "類型",
        "displayNameSc": "类型",
        "fieldType": EDITABLE_TYPE.PICKLIST,
        "options": [...PARAMETER_MAP_TYPE_LIST, ...PARAMETER_MAP_CUSTOM_TYPE_LIST],
        "required": true,
        "editable": true,
        "creatable": true,
    },    
]

const PARAMETER_COMPLEMENTARY_FIELD_TYPE = [
    {
        "fieldName": "value",
        "displayNameKey": `${settingPrefix}.${editParameterPrefix}.field.setup.value`,
        "displayNameEn": "Value",
        "displayNameTc": "值",
        "displayNameSc": "价值",
        "fieldType": EDITABLE_TYPE.TEXT,
        "required": true,
        "editable": true,
        "creatable": true,
    },
]

const getParameterFieldByType = (type) => {
    switch (type) {
        case PARAMETER_TYPE.CUSTOM:
            return [...PARAMETER_SETUP_FIELD_TYPE, ...PARAMETER_COMPLEMENTARY_FIELD_TYPE];
        default:
            return [...PARAMETER_SETUP_FIELD_TYPE]
    }
}


const EditParameter = ({
    isEditable = false,
    isEdit = false,
    setIsEdit = () => {},
    item = null,
    updateAction = null,
    removeAction = null
}) => {

    const [errorMap, setErrorMap] = useState({});
    const [editableParameter, setEditableParameter] = useState({})

    const intl = useIntl();
    const { language } = useLanguage()

    const PARAMETER_TYPE_NAME = Object.freeze({
        "<?MATCH_INPUT>": `${settingPrefix}.${editParameterPrefix}.nameList.type.input`,
        "<?MATCH_KEYWORD>": `${settingPrefix}.${editParameterPrefix}.nameList.type.keyword`,
        "<?MATCH_ENTITY>": `${settingPrefix}.${editParameterPrefix}.nameList.type.entity`,
        "<?MATCH_OPTION>": `${settingPrefix}.${editParameterPrefix}.nameList.type.option`,
        "<?MATCH_MEDIA>": `${settingPrefix}.${editParameterPrefix}.nameList.type.media`,
        "CUSTOM": `${settingPrefix}.${editParameterPrefix}.nameList.type.custom`,
    }) 
    
    const getOptions = (fieldType, fieldName, options) => {
        if(fieldType !== EDITABLE_TYPE.PICKLIST)
            return []

        if(fieldName === "type")
            return options.map(option => ({label: option ? intl.formatMessage({ "id": PARAMETER_TYPE_NAME[option] }) : "", value: option}))

        return options.map(option => ({label: option, value: option}))
    }

    const onChangeHandler = (isCreate, field, e) => {
        
        let value = e.target.value;
        let errorMap = {};

        switch (field.fieldType) {
            case EDITABLE_TYPE.BOOLEAN:
                value = !editableParameter[field.fieldName]
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;        
            default:
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;
        }

        if(errorMap && Object.keys(errorMap).length > 0)
            setErrorMap((erMap) => ({...erMap, ...errorMap}))
        else{
            setErrorMap((erMap) => {
                let newErrorMap = {...erMap}
                delete newErrorMap[field.fieldName]
                return newErrorMap 
            })
        }

        if(field.fieldName === "type")
            setEditableParameter((editableFields) => ({...editableFields, [field.fieldName]: value, value: ""}))
        else
            setEditableParameter((editableFields) => ({...editableFields, [field.fieldName]: value}))

    }

    const validateFields = (isCreate) => {
        let errorMap = {}

        let mapping = [ ...getParameterFieldByType(editableParameter.type) ]

        for(let field of mapping){
            if(!editableParameter[field.fieldName])
                setDefaultField(field, editableParameter, field.fieldName, isCreate)
            errorMap = {...errorMap, ...validateField(field, editableParameter[field.fieldName], intl.formatMessage, isCreate)}
        }

        let hasError = false;

        if(errorMap && Object.keys(errorMap).length > 0)
            hasError = true;

        if(hasError){
            setErrorMap(errorMap)
            return;
        }

        let completeObject = {isSystemVariable: false, ...editableParameter}
        if(completeObject.type !== PARAMETER_TYPE.CUSTOM){
            completeObject.value = editableParameter.type
            delete completeObject.type
        }

        updateAction(completeObject)
    }

    const refresh = () => {
        let editableParameter = JSON.parse(JSON.stringify(item))

        let type = PARAMETER_TYPE['<?MATCH_INPUT>']
        let value = editableParameter.value ?? ""

        if(value)
            type = PARAMETER_TYPE.CUSTOM

        if(PARAMETER_MAP_TYPE_LIST.includes(value)){
            type = editableParameter.value
            value = ""
        }

        setEditableParameter({...editableParameter, type, value})
        setErrorMap({})

        setIsEdit(isEditable && !item.id)
    }

    useEffect(() => {
        refresh()
    }, [item])

    return (
        <div className="w-full h-full">
            <div className='w-full'><hr/></div>
            <div className={`w-full ${isEditable ? "h-[calc(100%-72px)]" : "h-full"}`}>
                <Scrollbars
                    className="w-full h-full"
                    autoHide={false}
                    autoHideDuration={100}
                >
                    <div className='w-full h-full flex flex-col'>
                        <span className='px-4 pt-[18px] text-[#64748B]'>DETAILS</span>
                        <div className='px-4'>
                            {getParameterFieldByType(editableParameter.type).map((field) => (
                                <div key={field.fieldName} className='py-2'>
                                    <CusEditableLeftLabel
                                        readOnly={!isEdit || !isEditable || (!!item.id ? !field.editable : !field.creatable)}
                                        fieldType={field.fieldType}
                                        label={field.displayNameEn}
                                        value={editableParameter[field.fieldName] ?? field.defaultValue}
                                        options={getOptions(field.fieldType, field.fieldName, field.options)}
                                        errors={errorMap}
                                        required={field.required}
                                        errorName={field.fieldName}
                                        onChange={(e) => onChangeHandler(item.isCreate, field, e)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </Scrollbars>
            </div>
            {isEditable && 
                isEdit ?
                    <div className="p-4 w-full flex justify-center items-center h-[72px] border-t-[1px] border-[#CBD5E140]">
                            <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex justify-center text-base flex-1 px-4 h-[40px] items-center rounded-[5px]' color={ButtonColor.primary}
                                    onClick={() => validateFields(!item.id)}
                            >
                                    <span className="px-4">{!item.id ? "Create" : "Update"}</span>
                            </CusRoundedButton>
                    </div>
                :
                    <div className="p-4 w-full flex justify-between items-center h-[72px] border-t-[1px] border-[#CBD5E140]">
                            <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex justify-center text-base flex-shrink-0 px-4 h-[40px] items-center rounded-[5px]' color={ButtonColor.darkNoBorder}
                                    onClick={() => removeAction()}
                            >
                                    <span className="">Delete</span>
                            </CusRoundedButton>
                            <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex justify-center text-base flex-shrink-0 px-4 h-[40px] items-center rounded-[5px]' color={ButtonColor.primary}
                                    onClick={() => setIsEdit(true)}
                            >
                                    <span className="">Edit</span>
                            </CusRoundedButton>
                    </div>
            }
        </div>
    )
}

EditParameter.propTypes = {
    isEditable: PropTypes.bool,
    isEdit: PropTypes.bool,
    setIsEdit: PropTypes.func,
    item: PropTypes.object,
    updateAction: PropTypes.func,
    removeAction: PropTypes.func
};

export default EditParameter;

