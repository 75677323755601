export const infoPrefix = "info";
export const errorPrefix = "error";
export const generalPrefix = "general";
export const tablePrefix = "table";

export const chatpanelPrefix = "chatpanel";
export const chatpanelConversationsPrefix = "chatpanelConversations";
export const chatpanelMessagesPrefix = "chatpanelMessages";
export const chatTransferPrefix = "chatTransfer";

export const contactPrefix = "contact";
export const contactMessagesPrefix = "contactMessages"
export const contactProfilePrefix = "contactProfile";
export const contactTemplateMessagePrefix = "contactTemplateMessagePrefix";
export const contactMemoPrefix = "contactMemo";
export const contactHistoryPrefix = "contactHistory";
export const contactTicketsPrefix = "contactTickets";
export const contactFooterPrefix = "contactFooter";

export const monitorPrefix = "monitor";
export const offlineChatsPrefix = "offlineChats";
export const chatActivitiesPrefix = "chatActivities";
export const agentStatusPrefix = "agentStatus";
export const agentActivitiesPrefix = "agentActivities";
export const agentDashboardPrefix = "agentDashboard";

export const sessionPrefix = "session";
export const loginPrefix = "login";
export const forgotPrefix = "forgot";
export const trialPrefix = "trial";

export const userPrefix = "user";
export const userProfilePrefix = "userProfile";
export const userPasswordPrefix = "userPassword";
export const userAccountPrefix = "userAccount";

export const settingPrefix = "setting";

export const businessPrefix = "business";
export const planSubscriptionPrefix = "planSubscription";
export const businessGeneralSettingPrefix = "businessGeneralSetting";

export const userListPrefix = "userList";

export const systemPrefix = "system";
export const systemGeneralSettingPrefix = "systemGeneralSetting";
export const fieldConfigPrefix = "fieldConfig";
export const editFieldConfigPrefix = "editFieldConfig";

export const rolePrefix = "role";
export const roleSettingPrefix = "roleSetting";

export const channelAccountPrefix = "channelAccount";
export const editChannelAccountPrefix = "editChannelAccount";

export const chatGroupPrefix = "chatGroup";
export const chatRoutingPrefix = "chatRouting";
export const editChatRoutingPrefix = "editChatRouting";

export const messagePrefix = "message";
export const quickTextPrefix = "quickText";
export const editQuickTextPrefix = "editQuickText";

export const templatePrefix = "template";
export const editTemplatePrefix = "editTemplate";

export const systemMessagePrefix = "systemMessage";
export const editSystemMessagePrefix = "editSystemMessage";

export const taggingPrefix = "tagging";
export const wrapUpPrefix = "wrapUp";

export const chatAutomationPrefix = "chatAutomation";
export const editChatAutomationPrefix = "editChatAutomation";

export const chatbotPrefix = "chatbot";
export const createOrCloneChatbotPrefix = "createOrCloneChatbot";
export const chatbotIconBarPrefix = "chatbotIconBarPrefix";
export const editChatbotPrefix = "editChatbot";
export const editNodePrefix = "editNode";
export const editKeywordPrefix = "editKeyword";
export const editEntityPrefix = "editEntity";
export const editResponsePrefix = "editResponse";
export const editActionPrefix = "editAction";
export const editParameterPrefix = "editParameter";

export const openaiPrefix = "openai";
export const editOpenaiPrefix = "editOpenai";

export const livechatSettingPrefix = "livechatSetting";
export const editLivechatSettingPrefix = "editLivechatSetting";
