import { infoPrefix } from "../prefix";

export const info = {
    [`${infoPrefix}.forgotPassword.success`]: "Forgot Password success, Temporary password has already sent to your email",
    [`${infoPrefix}.forgotPassword.failed`]: "Forgot Password failed",

    [`${infoPrefix}.signup.success`]: "Create account Success, Temporary password has already sent to your email",
    [`${infoPrefix}.signup.failed`]: "Create account Failed",

    [`${infoPrefix}.template.send.success`]: "Template message sent success",
    [`${infoPrefix}.template.send.failed`]: "Template message sent Failed",
 }