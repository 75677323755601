import Tick from 'components/Icon/Tick';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

export const ITEM_TYPE = Object.freeze({
    "RADIO": "radio",
    "CHECKBOX": "checkbox",
})

const NavItem = ({
    path = "",
    className = "",
    selected = false,
    disabled = false,
    onClick = () => {},
    children = null,
}) => {

    return (
        <NavLink
            className={`flex flex-1 items-center px-4 py-3 text-sm ${disabled ? "" : "cursor-pointer"} hover:bg-blue06 ${className}`}
            onClick={(e)=>{
                if(onClick){
                    e.stopPropagation()
                    onClick(e)
                    document.activeElement.blur();
                }
            }}
            to={path}
        >
            <div className="w-full flex justify-between items-center">
                <span className='mx-3 text-sm truncate'>{children}</span>
                {selected ?
                    <span className='text-blue04 mx-2 text-sm'><Tick/></span>
                    :
                    null
                }
            </div>
        </NavLink>
    )
}

NavItem.propTypes = {
    path: PropTypes.string,
    className: PropTypes.string,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node,
};

export default NavItem;