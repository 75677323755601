import ChatMessage from "components/ChatMessage";
import { convertChatbotMessage, getMessageTypeFromMime, isJson, MESSAGE_STATUS, MESSAGE_TYPE } from "util/helper";

const AgentMessage = ({
    active,
    message,
    messageMedia,
    containerSize,
    status,
    isBookmarked,
    readOnlyBookmark,
    onClick,
    bookmarkAction,
}) => (
    <ChatMessage
        name={message.fromUser}
        messageId={message.messageId}
        messageType={message.messageType === MESSAGE_TYPE.FILE ? getMessageTypeFromMime(message.mimeType) : message.messageType}
        mimeType={message.mimeType}
        downloading={messageMedia && messageMedia.downloading}
        timestamp={message.timestamp}
        message={message.message ? (message.fromUser === 'CHATBOT' && message.messageType === MESSAGE_TYPE.TEXT && isJson(message.message) ? convertChatbotMessage(message.message) : message.message) : message.message}
        containerSize={containerSize ?? "max-w-[75%]"}

        status={(messageMedia && (messageMedia.success === -1) ? MESSAGE_STATUS.FAILED : status)}
        messageMedia={!!messageMedia && (messageMedia.success === 1) ? messageMedia : null}
        downloadDisabled={status === MESSAGE_STATUS.FAILED || (messageMedia ? (messageMedia.success === -1) : false)}
        isBookmarked={isBookmarked}
        readOnlyBookmark={readOnlyBookmark}
        onClick={onClick}
        bookmarkAction={(status === MESSAGE_STATUS.FAILED || status === MESSAGE_STATUS.PENDING) ? null : bookmarkAction}
        active={active}
    />
)

export default AgentMessage;