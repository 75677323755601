import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';



import CusGroupDropdown from 'components/CusGroupDropdown';
import { BorderType as InputBorder } from 'components/CusRoundedInput';
import CusRoundedSearch from 'components/CusRoundedInput/CusRoundedSearch';
import DynamicContent from 'components/DynamicContent';
import CustomizeNode from 'components/Icon/CustomizeNode';

const NodeOptionDropdown = ({
    showMenu = false,
    setShowMenu = () => {},
    menuPos = null,
    setMenuPos = () => {},
    nodeAction = () => {},
}) =>{

    const [searchInput, setSearchInput] = useState("");

    useEffect(() => {
        if(!showMenu){
            setSearchInput("")
        }
    }, [showMenu])

	return (
        <>
            <DynamicContent screenX={menuPos ? menuPos.x : undefined} screenY={menuPos ? menuPos.y : -1} show={showMenu} setShow={setShowMenu} onUnFocus={() => setMenuPos(null)}>
                <CusGroupDropdown
                    cardSize="w-[425px] h-auto"
                    contentSize="max-h-[45vh] overflow-auto"
                >
                    <>
                        <CusRoundedSearch
                            placeholder="Search template"
                            borderType={InputBorder.none}
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                            cancelSearch={() => setSearchInput("")}
                        />
                    </>
                    <>
                        <CusGroupDropdown.Item
                            className='shadow'
                            onClick={(e) => {
                                nodeAction("customizeNode")
                                setMenuPos(null);
                                setShowMenu(false);                                
                            }}
                        >
                            <div className="w-full flex items-center">
                                <div className="px-4 text-greyblack">
                                    <CustomizeNode/>
                                </div>                            
                                <div className="flex flex-col">
                                    <span className='truncate pb-2 text-greyblack font-bold'>Customize node</span>
                                    <span className='truncate text-grey051 font-normal text-sm'>Set up responses or actions for incoming messages</span>
                                </div>
                            </div>
                        </CusGroupDropdown.Item>
                        {/* <CusGroupDropdown.Item
                            className='shadow'
                            onClick={(e) => {
                                nodeAction("RespondToAnyTextMessage")
                                setMenuPos(null);
                                setShowMenu(false);                                
                            }}
                        >
                            <div className="w-full flex items-center">
                                <div className="px-4 text-greyblack">
                                    <CustomizeNode/>
                                </div>
                                <div className="flex flex-col">
                                    <span className='truncate pb-2 text-greyblack font-bold'>Respond to any text message</span>
                                    <span className='truncate text-grey051 font-normal text-sm'>Set up response with or without selectable options </span>
                                </div>
                            </div>
                        </CusGroupDropdown.Item> */}
                    </>
                </CusGroupDropdown>
            </DynamicContent>
        </>
	);
	
}

NodeOptionDropdown.propTypes = {
    showMenu: PropTypes.bool,
    setShowMenu: PropTypes.func,
    menuPos: PropTypes.object,
    setMenuPos: PropTypes.func,
    nodeAction: PropTypes.func,
};

export default NodeOptionDropdown;
