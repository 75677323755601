
import PropTypes from 'prop-types';
import React from 'react';
import { Tag, BorderType as TagBorderType } from "components/Tag";
import { capitalise } from 'util/helper';
import { useIntl } from 'react-intl';

const StatusItem = ({
    status = "",
    statusMap = {},
    inactive = false,
}) => {
    const intl = useIntl()

    return (
        <Tag borderType={TagBorderType.none} className={`rounded-[5px] w-[74px] h-[24px] px-1 mx-2 ${(inactive || status === "CLOSED" || status === "INACTIVE") ? "bg-blue01" : "bg-blue04"} text-white text-xs`} text={statusMap[status] ? intl.formatMessage({ "id": statusMap[status]}) : status} />
    )
}

StatusItem.propTypes = {
    status: PropTypes.string,
    statusMap: PropTypes.object,
    inactive: PropTypes.bool,
};

export default StatusItem;