import PropTypes from 'prop-types';

import { EDITABLE_TYPE, setDefaultField, validateField } from 'components/CusEditable';
import CusEditableLeftLabel from 'components/CusEditable/CusEditableLeftLabel';
import NonExpanableListItem from 'components/NonExpanableListItem';
import { useLanguage } from 'hooks/useLanguage';
import { checkListItemDuplication } from 'util/helper';
import SubTasks from 'components/Icon/SubTasks';
import { useIntl } from 'react-intl';
import { editActionPrefix, settingPrefix } from 'lang/locales/prefix';

export const ACTION_DEFAULT_VALUE_FIELD_TEMPLATE = Object.freeze({
    "name" : "",
    "value" : "",
})

export const DEFAULT_VALUE_FIELD_TYPE = [
    {
        "fieldName": "name",
        "displayNameKey": `${settingPrefix}.${editActionPrefix}.field.conditions.inputField.name`,
        "displayNameEn": "Input Field name",
        "displayNameTc": "輸入字段名稱",
        "displayNameSc": "输入字段名称",
        "fieldType": EDITABLE_TYPE.TEXT,
        "required": true,
        "editable": true,
        "creatable": true,
    },
    {
        "fieldName": "value",
        "displayNameKey": `${settingPrefix}.${editActionPrefix}.field.conditions.inputField.value`,
        "displayNameEn": "Input Field value",
        "displayNameTc": "輸入字段值",
        "displayNameSc": "输入字段值",
        "fieldType": EDITABLE_TYPE.TEXTAREA,
        "required": true,
        "editable": true,
        "creatable": true,
    },
]

export const validateDefaultValueListItems = (errorFormater, isCreate, fieldList) => {
    let errorList = [];

    for(let index = 0; index < fieldList.length; index++){
        let fieldListItem = {...fieldList[index]}
        let errorMap = {}

        let mapping = [...DEFAULT_VALUE_FIELD_TYPE]

        for(let field of mapping){
            if(!fieldListItem[field.fieldName])
                setDefaultField(field, fieldListItem, field.fieldName, isCreate)
            errorMap = {...errorMap, ...validateField(field, fieldListItem[field.fieldName], errorFormater, isCreate)}
        }

        errorList[index] = errorMap
    }

    let fieldSet = fieldList.map(field => field.name)

    let duplicates = checkListItemDuplication(fieldSet)
    duplicates.forEach(i => {
        if(!errorList[i])
            errorList[i] = {}

        errorList[i].name = { message: errorFormater({ "id": `${settingPrefix}.${editActionPrefix}.duplicates.inputField` }) }
    })

    return errorList
}


const ActionDefaultValueListItem = ({
    isCreate = false,
    isEdit = false,
    isEditable = false,
    itemIndex = -1,
    fieldListItem = null,
    error = null,
    updateField = null,
    removeField = null,
    openParameterList = null,
}) => {

    const intl = useIntl();
    const { language } = useLanguage()

    const getOptions = (fieldType, fieldName, options) => {
        if(fieldType !== EDITABLE_TYPE.PICKLIST)
            return []

        return options.map(option => ({label: option, value: option}))
    }

    const onChangeHandler = (isCreate, field, e) => {
        
        let value = e.target.value;
        let currentErrorMap = {...error};
        let errorMap = {};

        switch (field.fieldType) {
            case EDITABLE_TYPE.BOOLEAN:
            case EDITABLE_TYPE.BOOLEAN_CHECKBOX:
                value = !fieldListItem[field.fieldName]
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;        
            default:
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;
        }

        if(errorMap && Object.keys(errorMap).length > 0)
            currentErrorMap = {...currentErrorMap, ...errorMap}
        else
            delete currentErrorMap[field.fieldName]

        console.log(currentErrorMap)

        updateField({...fieldListItem, [field.fieldName]: value}, currentErrorMap)
    }

    return(
        <NonExpanableListItem
            title={`${intl.formatMessage({ "id": `${settingPrefix}.${editActionPrefix}.expanable.text.inputField.defaultValue`})}#${itemIndex + 1}`}
            remove={(!isEdit || !isEditable) ? null : removeField}
            readOnly={itemIndex === 0 || (!isEdit || !isEditable)}
        >
            <div className="flex flex-col">
                {DEFAULT_VALUE_FIELD_TYPE.map((field) => (
                    field.fieldName === "value" ?
                        <div
                            key={field.fieldName}
                            className="py-2 flex"
                        >
                            <CusEditableLeftLabel
                                readOnly={(!isEdit || !isEditable) || (!isCreate ? !field.editable : !field.creatable)}
                                fieldType={field.fieldType}
                                showLabelOnLeft={false}
                                label={field.displayNameEn}    
                                value={fieldListItem[field.fieldName] ?? field.defaultValue}
                                options={getOptions(field.fieldType, field.fieldName, field.options)}
                                errors={error}
                                required={field.required}
                                errorName={field.fieldName}
                                onChange={(e) => onChangeHandler(isCreate, field, e)}
                            />
                            {(!isEdit || !isEditable) ?
                                null
                                :
                                <div className="w-[30px] h-[40px] flex justify-center items-center">
                                    <div className={`flex justify-center items-center text-[#94A3B8] cursor-pointer parameterList-pos `}><div onClick={(e) => openParameterList(e)}><SubTasks/></div></div>
                                </div>
                            }
                        </div>
                    :
                        <div key={field.fieldName} className='py-2'>
                            <CusEditableLeftLabel
                                readOnly={(!isEdit || !isEditable) || (!isCreate ? !field.editable : !field.creatable)}
                                fieldType={field.fieldType}
                                showLabelOnLeft={false}
                                label={field.displayNameEn}    
                                value={fieldListItem[field.fieldName] ?? field.defaultValue}
                                options={getOptions(field.fieldType, field.fieldName, field.options)}
                                errors={error}
                                required={field.required}
                                errorName={field.fieldName}
                                onChange={(e) => onChangeHandler(isCreate, field, e)}
                            />
                        </div>
                ))}
            </div>
        </NonExpanableListItem>
    )
}

ActionDefaultValueListItem.propTypes = {
    isCreate: PropTypes.bool,
    isEdit: PropTypes.bool,
    isEditable: PropTypes.bool,
    itemIndex: PropTypes.number,
    fieldListItem: PropTypes.object,
    error: PropTypes.object,
    updateField: PropTypes.func,
    removeField: PropTypes.func,
    openParameterList: PropTypes.func,
};

export default ActionDefaultValueListItem;
