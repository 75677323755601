import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Copy from 'components/Icon/Copy';
import Cross from 'components/Icon/Cross';
import LeftArrow from 'components/Icon/LeftArrow';
import Scrollbars from 'react-custom-scrollbars-2';
import Clone from 'components/Icon/NewChatbotIcon/Clone';

const FlowSettingPanel = ({
	title = '',
	backAction = null,
	cloneAction = null,
	closeAction = null,
	children = null
}) => {

	return (
		<div className='flex flex-col h-full w-[320px] bg-[#F8FAFC] border-r-[2px]'>
			<div className='px-4 w-full flex justify-between items-center h-[64px]'>
				<div className="flex items-baseline">
						{backAction &&
							<div className="px-3">
									<div className="cursor-pointer text-[#64748B]" onClick={backAction}>
											<LeftArrow className="w-[11px] h-[11px]"/>
									</div>
							</div>
						}
						<div className="flex items-center">
								<span className="flex items-center text-lg font-bold text-greyblack px-2">{title}</span>
						</div>
				</div>
				<div className="flex items-center">
						{cloneAction &&
							<div className="px-3">
									<div className="cursor-pointer text-[#64748B]" onClick={cloneAction}>
											<Clone className="w-[24px] h-[24px]"/>
									</div>
							</div>
						}
						{closeAction &&
							<div className="px-3">
									<div className="cursor-pointer text-[#64748B]" onClick={closeAction}>
											<Cross className="w-[11px] h-[11px]"/>
									</div>
							</div>
						}
				</div>
			</div>
			<div className="w-full h-[calc(100%-64px)]">
				{children}
			</div>
		</div>
	)
}

FlowSettingPanel.propTypes = {
	title: PropTypes.string,
	backAction: PropTypes.func,
	cloneAction: PropTypes.func,
	closeAction: PropTypes.func,
	children: PropTypes.node,
}

export default FlowSettingPanel;