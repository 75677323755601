import PropTypes from 'prop-types';
import React from 'react';

const BorderType = Object.freeze(
    {
        "none" : "",
        "small"     : "rounded-sm",
        "medium"   : "rounded-lg",
    }
)

const Tag = ({
    text = '',
    className = '',
    background = "",
    borderType = BorderType.small,
    onClick = null,
}) => {

    return (
        <div title={text} className={`${onClick ? "cursor-pointer" : ""} flex justify-center items-center ${borderType ? borderType : BorderType.small} ${className}`} style={background ? {"background" : background} : null} onClick={onClick}>
            <span className="truncate">{text}</span>
        </div>
    )
}

Tag.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
    background: PropTypes.string,
    borderType: PropTypes.string,
    onClick: PropTypes.func,
};

export { Tag, BorderType };