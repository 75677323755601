import PropTypes from "prop-types";
import React from 'react';

import { BorderType, CusRoundedInput, TextSize} from 'components/CusRoundedInput';

const CusLabelRoundedInput = React.forwardRef(({
  label = "",
  value = undefined,
  borderType = "",
  disabled = false,
  readOnly = false,
  required = false,
  onChange = null,
  toggle = false,
  setToggle = null,
  ...rest
}, ref) =>   (
  <CusRoundedInput
    ref={ref}
    // label={label}
    value={value}
    onChange={onChange}
    borderType={disabled ? BorderType.none : borderType}
    disabled={disabled}
    readOnly={readOnly}
    placeholder={label}
    textSize={TextSize.flat}
    toggle={toggle}
    setToggle={setToggle}
    {...rest}
  />    
));

CusLabelRoundedInput.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    borderType: PropTypes.string,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    toggle: PropTypes.bool,
    setToggle: PropTypes.func,  
  }

export default CusLabelRoundedInput;