import {
    GET_RESPONSES,
    GET_RESPONSES_FAILURE,
    GET_RESPONSES_SUCCESS,
    BULK_RESPONSE,
    BULK_RESPONSE_SUCCESS,
    BULK_RESPONSE_FAILURE
} from "store/reducers/setting/automation/chatbot/response";

import {
    getResponse as getResponseService,
    bulkResponse as bulkResponseService
} from "services/chatbot/response";
import { isJson, isSuccessResponse } from "util/helper";
import { handleAuthError } from "store/actions/auth/user";
import { ERROR_REPSONSE_LOCALE_MAP, ERROR_RESPONSE_MAP, getErrorByLocale } from "util/ErrorResponse";
import { errorPrefix } from "lang/locales/prefix";
import { getToken } from "store/reducers/auth/user";

export const getResponseList = (data) => (dispatch, getState) => {
    dispatch(GET_RESPONSES());    

    let authToken = getToken(getState().auth.user)
    getResponseService(data, authToken)
    .then(response => {
        const bodyString = response.data.body;
        const body = isJson(bodyString) ? JSON.parse(bodyString) : bodyString;

        console.log(body)

        if(isSuccessResponse(response)) {
            dispatch(GET_RESPONSES_SUCCESS(body));
        } else {
            if(body === ERROR_RESPONSE_MAP.AUTH){
                dispatch(handleAuthError())
                return
            }
            if(body === ERROR_RESPONSE_MAP.PERMISSION){
                dispatch(GET_RESPONSES_FAILURE({error: getErrorByLocale(getState, ERROR_REPSONSE_LOCALE_MAP.PERMISSION)}))
                return
            }

            dispatch(GET_RESPONSES_FAILURE({error: body.message }));
        }

    })
    .catch(error=> {
        const message = (typeof error.response === "string") ? error.response : 'System Error, Please contact admin for more information'
        dispatch(GET_RESPONSES_FAILURE({error: message}))
    })

}

export const createResponse = ({flowId, response}) => (dispatch, getState) => {
    dispatch(bulkResponse({insertList: [{flowId, ...response}], flowId}))
}

export const updateResponse = ({flowId, response}) => (dispatch, getState) => {
    dispatch(bulkResponse({updateList: [{flowId, ...response}], flowId}))
}

export const removeResponse = ({flowId, id}) => (dispatch, getState) => {
    dispatch(bulkResponse({removeIdList: [id], flowId}))
}

export const bulkResponse = ({insertList, updateList, removeIdList, flowId}) => (dispatch, getState) => {
    dispatch(BULK_RESPONSE());

    insertList = insertList ? insertList : []
    updateList = updateList ? updateList : []
    removeIdList = removeIdList ? removeIdList : []

    let authToken = getToken(getState().auth.user)
    bulkResponseService({insertResponseList: insertList, updateResponseList: updateList, deleteResponseIdList: removeIdList}, authToken)
        .then(response => {
            console.log(response)
            const bodyString = response.data.body;
            const body = isJson(bodyString) ? JSON.parse(bodyString) : bodyString;

            console.log(body)
            console.log(flowId)

            if(isSuccessResponse(response)){
                dispatch(BULK_RESPONSE_SUCCESS())
                if(flowId)
                    dispatch(getResponseList({flowId: flowId}))

            }
            else{
                if(body === ERROR_RESPONSE_MAP.AUTH){
                    dispatch(handleAuthError())
                    return
                }
                if(body === ERROR_RESPONSE_MAP.PERMISSION){
                    dispatch(BULK_RESPONSE_FAILURE({error: getErrorByLocale(getState, ERROR_REPSONSE_LOCALE_MAP.PERMISSION)}))
                    return
                }
    
                dispatch(BULK_RESPONSE_FAILURE({error: body.message}))
            }
        })
        .catch((error) => {
            const message = (typeof error.response === "string") ? error.response : 'System Error, Please contact admin for more information'
            dispatch(BULK_RESPONSE_FAILURE({error: message}))
        })
}