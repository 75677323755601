import { createSlice } from '@reduxjs/toolkit'

const INIT_STATE = {
    responseList: [],
    loading: false,
    error: null
}

const Response = createSlice({
    name: 'Response',
    initialState: INIT_STATE,
    reducers: {
        GET_RESPONSES: (state) => {
            state.loading = true;
            state.error = null;
        },
        GET_RESPONSES_SUCCESS: (state, action) => {
            state.loading = false;
            state.responseList = action.payload;
            state.error = null;
        },
        GET_RESPONSES_FAILURE: (state, action) => {
            state.loading = false;
            state.responseList = [];
            state.error = action.payload.error;
        },

        BULK_RESPONSE: (state) => {
            state.loading = true;
            state.error = null;
        },
        BULK_RESPONSE_SUCCESS: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        BULK_RESPONSE_FAILURE: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
        },

    }
})

export const {
    GET_RESPONSES,
    GET_RESPONSES_FAILURE,
    GET_RESPONSES_SUCCESS,
    BULK_RESPONSE,
    BULK_RESPONSE_SUCCESS,
    BULK_RESPONSE_FAILURE,
} = Response.actions;


export default Response.reducer;

export const getResponseList = state => state.responseList;
export const getLoading = state => state.loading;
export const getError = state => state.error;