import PropTypes from 'prop-types';

import { EDITABLE_TYPE, setDefaultField, validateField } from 'components/CusEditable';
import CusEditableLeftLabel from 'components/CusEditable/CusEditableLeftLabel';
import NonExpanableListItem from 'components/NonExpanableListItem';
import { useLanguage } from 'hooks/useLanguage';
import { checkListItemDuplication } from 'util/helper';
import { useIntl } from 'react-intl';
import { editNodePrefix, settingPrefix, errorPrefix } from 'lang/locales/prefix';

const PARAMETER_TYPE = Object.freeze({
    "<?MATCH_INPUT>": "<?MATCH_INPUT>",
    "<?MATCH_KEYWORD>": "<?MATCH_KEYWORD>",
    "<?MATCH_ENTITY>": "<?MATCH_ENTITY>",
    "<?MATCH_OPTION>": "<?MATCH_OPTION>",
    "<?MATCH_MEDIA>": "<?MATCH_MEDIA>",
    "CUSTOM": "CUSTOM",
}) 

const PARAMETER_TYPE_NAME = Object.freeze({
    "<?MATCH_INPUT>": "Input",
    "<?MATCH_KEYWORD>": "Keyword",
    "<?MATCH_ENTITY>": "Entity",
    "<?MATCH_OPTION>": "Option",
    "<?MATCH_MEDIA>": "Media",
    "CUSTOM": "Custom",
}) 

const PARAMETER_MAP_CUSTOM_TYPE_LIST = Object.freeze([
    "CUSTOM"
])

export const PARAMETER_MAP_TYPE_LIST = Object.freeze([
    "<?MATCH_INPUT>", "<?MATCH_KEYWORD>", "<?MATCH_ENTITY>", "<?MATCH_OPTION>", "<?MATCH_MEDIA>"
])

export const PARAMETER_FIELD_TEMPLATE = Object.freeze({
    "label" : "",
    "type" : '<?MATCH_INPUT>',
    "value" : "",
})

const PARAMETER_FIELD_TYPE = [
    {
        "fieldName": "label",
        "displayNameKey": `${settingPrefix}.${editNodePrefix}.field.extra.parameterName`,
        "displayNameEn": "Name",
        "displayNameTc": "變量名",
        "displayNameSc": "变量名",
        "fieldType": EDITABLE_TYPE.PICKLIST,
        "options": [],
        "required": true,
        "editable": true,
        "creatable": true,
    },
    {
        "fieldName": "type",
        "displayNameKey": `${settingPrefix}.${editNodePrefix}.field.extra.type`,
        "displayNameEn": "Type",
        "displayNameTc": "類型",
        "displayNameSc": "类型",
        "fieldType": EDITABLE_TYPE.PICKLIST,
        "options": [...PARAMETER_MAP_TYPE_LIST, ...PARAMETER_MAP_CUSTOM_TYPE_LIST],
        "required": true,
        "editable": true,
        "creatable": true,
    },
]

export const PRECHAT_CONTENT_SELECT_FIELD_TYPE = {
    "fieldName": "option",
    "displayNameKey": `${settingPrefix}.${editNodePrefix}.field.variable.option`,
    "displayNameEn": "Option",
    "displayNameTc": "選項",
    "displayNameSc": "选项",
    "fieldType": EDITABLE_TYPE.TEXT,
    "required": true,
    "editable": true,
    "creatable": true,
}

export const PARAMETER_COMPLEMENTARY_FIELD_TYPE = [
    {
        "fieldName": "value",
        "displayNameKey": `${settingPrefix}.${editNodePrefix}.field.extra.value`,
        "displayNameEn": "Value",
        "displayNameTc": "值",
        "displayNameSc": "价值",
        "fieldType": EDITABLE_TYPE.TEXT,
        "required": true,
        "editable": true,
        "creatable": true,
    },
]

export const getParameterFieldByType = (type) => {
    switch (type) {
        case PARAMETER_TYPE.CUSTOM:
            return [...PARAMETER_FIELD_TYPE, ...PARAMETER_COMPLEMENTARY_FIELD_TYPE];
        default:
            return [...PARAMETER_FIELD_TYPE]
    }
}

export const validateParameterListItems = (errorFormater, isCreate, fieldList) => {
    let errorList = [];

    for(let index = 0; index < fieldList.length; index++){
        let parameterListItem = {...fieldList[index]}
        let errorMap = {}

        let mapping = [...getParameterFieldByType(parameterListItem.type)]

        for(let field of mapping){
            if(!parameterListItem[field.fieldName])
                setDefaultField(field, parameterListItem, field.fieldName, isCreate)
            errorMap = {...errorMap, ...validateField(field, parameterListItem[field.fieldName], errorFormater, isCreate)}
        }

        errorList[index] = errorMap
    }

    let fieldSet = fieldList.map(field => field.label)

    let duplicates = checkListItemDuplication(fieldSet)
    duplicates.forEach(i => {
        if(!errorList[i])
            errorList[i] = {}

        errorList[i].label = { message: errorFormater({ "id": `${errorPrefix}.duplicates` }) }
    })

    return errorList
}

const ParameterListItem = ({
    isCreate = false,
    readOnly = false,
    itemIndex = -1,
    parameterList = [],
    parameterListItem = null,
    error = null,
    updateParameter = null,
    removeParameter = null,
}) => {

    console.log(parameterListItem)

    const intl = useIntl();
    const { language } = useLanguage()

    const getOptions = (fieldType, fieldName, options) => {
        if(fieldType !== EDITABLE_TYPE.PICKLIST)
            return []

        if(fieldName === "label")
            return parameterList.filter(parameter => parameter.key !== "SYS_LANGUAGE").map(parameter => ({label: parameter.key, value: parameter.key}))

        if(fieldName === "type")
            return options.map(option => ({label: PARAMETER_TYPE_NAME[option], value: option}))

        return options.map(option => ({label: option, value: option}))
    }

    const onChangeHandler = (isCreate, field, e) => {
        
        let value = e.target.value;
        let currentErrorMap = {...error};
        let errorMap = {};

        switch (field.fieldType) {
            case EDITABLE_TYPE.BOOLEAN:
            case EDITABLE_TYPE.BOOLEAN_CHECKBOX:
                value = !parameterListItem[field.fieldName]
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;        
            default:
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;
        }

        if(errorMap && Object.keys(errorMap).length > 0)
            currentErrorMap = {...currentErrorMap, ...errorMap}
        else
            delete currentErrorMap[field.fieldName]
        
        if(field.fieldName === "type"){
            let newFieldListItem = {...parameterListItem}
            newFieldListItem[field.fieldName] = value
            newFieldListItem.value = ""

            updateParameter(newFieldListItem, currentErrorMap)
            return;
        }

        updateParameter({...parameterListItem, [field.fieldName]: value}, currentErrorMap)
    }

    return(
        <NonExpanableListItem
            title={`${intl.formatMessage({ "id": `${settingPrefix}.${editNodePrefix}.expanable.text.variable` })}#${itemIndex + 1}`}
            remove={readOnly ? null : removeParameter}
            readOnly={readOnly}
        >
            <div className="flex flex-col py-4">
                {getParameterFieldByType(parameterListItem.type).map((field) => (
                    <CusEditableLeftLabel
                        key={field.fieldName}
                        readOnly={readOnly || (!isCreate ? !field.editable : !field.creatable)}
                        fieldType={field.fieldType}
                        labelSize={45}
                        label={intl.formatMessage({ "id": field.displayNameKey })}
                        value={parameterListItem[field.fieldName] ?? field.defaultValue}
                        options={getOptions(field.fieldType, field.fieldName, field.options)}
                        errors={error}
                        required={field.required}
                        errorName={field.fieldName}
                        onChange={(e) => onChangeHandler(isCreate, field, e)}
                    />
                ))}
            </div>
        </NonExpanableListItem>
    )
}

ParameterListItem.propTypes = {
    isCreate: PropTypes.bool,
    readOnly: PropTypes.bool,
    itemIndex: PropTypes.number,
    parameterList: PropTypes.arrayOf(PropTypes.object),
    parameterListItem: PropTypes.object,
    error: PropTypes.object,
    updateParameter: PropTypes.func,
    removeParameter: PropTypes.func,
};

export default ParameterListItem;
