import PropTypes from 'prop-types';
import React from 'react';

import CusCard, { Color as CardColor, PaddingType as CardPaddingType } from 'components/CusCard';
import Item, { ITEM_TYPE } from './Item';
import Group from './Group';

import "./index.css";
import NavItem from './NavItem';

const CusGroupDropdown = ({
	title = "",
	cardSize = "w-[214px] h-auto",
	contentSize = "max-h-[40vh] overflow-auto",
	children = null,
}) => {

    return (
        <CusCard color={CardColor.white} paddingType={CardPaddingType['small-y']} className={`select-none flex flex-col drop-shadow-md rounded-lg ${cardSize}`}>
            {children.length > 1 ?
                <>
                    {children[0]}
                    <hr/>
                    <div className={`group-dropdown flex flex-col ${contentSize}`}>
                        {children[1]}
                    </div>
                </>
                :
                <>
                    {title ?
                        <>
                            <span className='mx-4 mb-4 mt-2 text-xs text-grey07'>{title}</span>
                            <hr/>
                        </>
                        :
                        null
                    }
                    <div className={`group-dropdown flex flex-col ${contentSize}`}>
                        {children}
                    </div>
                </>
            }
        </CusCard>
    )
}

CusGroupDropdown.propTypes = {
    title: PropTypes.string,
    cardSize: PropTypes.string,
	children: PropTypes.node
};

CusGroupDropdown.ITEM_TYPE = ITEM_TYPE;
CusGroupDropdown.Item = Item;
CusGroupDropdown.NavItem = NavItem;
CusGroupDropdown.Group = Group;

export default CusGroupDropdown;