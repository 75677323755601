import { useSelector } from 'react-redux';
import { getLoading as getAuthLoading } from 'store/reducers/auth/user';

import { getLoading as getFlowLoading } from "store/reducers/setting/automation/chatbot/flow";
import { getSelectedFlowLoading } from 'store/reducers/setting/automation/chatbot/flow';

import { getLoading as getNodeLoading } from "store/reducers/setting/automation/chatbot/node";
import { getLoading as getKeywordLoading } from "store/reducers/setting/automation/chatbot/keyword";
import { getLoading as getEntityLoading } from "store/reducers/setting/automation/chatbot/entity";
import { getLoading as getResponseLoading } from "store/reducers/setting/automation/chatbot/response";
import { getLoading as getActionLoading } from "store/reducers/setting/automation/chatbot/action";
import { getLoading as getParameterLoading } from "store/reducers/setting/automation/chatbot/parameter";

import { getLoading as getAttachmentLoading } from 'store/reducers/setting/automation/chatbot/attachment';

export const useDefaultPageLoading = () => {

    const loading = useSelector(state => (
        getAuthLoading(state.auth.user)

        || getFlowLoading(state.setting.automation.chatbot.flow)
        || getSelectedFlowLoading(state.setting.automation.chatbot.flow)
        
        || getNodeLoading(state.setting.automation.chatbot.node)
        || getKeywordLoading(state.setting.automation.chatbot.keyword)
        || getEntityLoading(state.setting.automation.chatbot.entity)
        || getResponseLoading(state.setting.automation.chatbot.response)
        || getActionLoading(state.setting.automation.chatbot.action)
        || getParameterLoading(state.setting.automation.chatbot.parameter)

        || getAttachmentLoading(state.setting.automation.chatbot.attachment)
    ));

    return loading;
}