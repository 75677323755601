import React, { memo } from 'react';
import BottomHandle from 'components/Flow/Handle/BottomHandle';
import { useIntl } from 'react-intl';
import { editChatbotPrefix, settingPrefix } from 'lang/locales/prefix';
import Home from 'components/Icon/NewChatbotIcon/Home';

const StartNode = memo(() => {

    const intl = useIntl();

    return (
        <>
            <div className="px-3 flex items-center justify-center rounded-[8px] bg-[#145065] w-full text-white">
                <Home className="w-[24px] h-[24px]"/>
                <span className='p-2 text-white font-normal text-xs'>{intl.formatMessage({ "id": `${settingPrefix}.${editChatbotPrefix}.flow.start.title` })}</span>
            </div>
            <BottomHandle/>
        </>
    );
});

export default StartNode