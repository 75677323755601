// https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
import PropTypes from "prop-types";
import React from 'react';

const HighlightText = ({
    className = "",
    text = "",
    keyword = "",
    highlightStyle = "text-blue04 font-semibold",
    multiLines = false
}) => {

    let textSections = [];

    if(keyword)
        textSections = text.split(new RegExp(`(${keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, "gi"))

    return(
        textSections && textSections.length > 0 ?
            <div className={`w-full flex items-center ${className}`}>
                <div className={`w-full inline-block flex-1 items-center ${multiLines ? "whitespace-pre-wrap" : "truncate"}`}>
                    {textSections.map((text, index) => 
                        <span key={`${text}-${index}`}>
                            {text.toLowerCase() === keyword.toLowerCase() ?
                                <span className={highlightStyle}>{text}</span>
                                :
                                <>{text}</>
                            }
                        </span>
                    )}
                </div>
            </div>
            :
            <div className={`w-full ${multiLines ? "whitespace-pre-wrap" : "truncate"} ${className}`}>{text}</div>        
    );
}

HighlightText.propTypes = {
    className: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    keyword: PropTypes.string,
    highlightStyle: PropTypes.string,
    multiLines: PropTypes.bool
}

export default HighlightText