import {httpPost} from 'lib/requests';

const getEntity = (data, authToken) => {
    return httpPost('chatbot/entity/get', data, authToken);
}

const bulkEntity = (data, authToken) => {
    return httpPost('chatbot/entity/bulk', data, authToken);
}

export {
    getEntity,
    bulkEntity,
};