import {
    GET_KEYWORDS,
    GET_KEYWORDS_FAILURE,
    GET_KEYWORDS_SUCCESS,
    BULK_KEYWORD,
    BULK_KEYWORD_SUCCESS,
    BULK_KEYWORD_FAILURE,
} from "store/reducers/setting/automation/chatbot/keyword";

import {
    getKeyword as getKeywordService,
    bulkKeyword as bulkKeywordService,
} from "services/chatbot/keyword";
import { isJson, isSuccessResponse } from "util/helper";
import { handleAuthError } from "store/actions/auth/user";
import { ERROR_REPSONSE_LOCALE_MAP, ERROR_RESPONSE_MAP, getErrorByLocale } from "util/ErrorResponse";
import { errorPrefix } from "lang/locales/prefix";
import { getToken } from "store/reducers/auth/user";

export const getKeywordList = (data) => (dispatch, getState) => {
    dispatch(GET_KEYWORDS());    

    let authToken = getToken(getState().auth.user)
    getKeywordService(data, authToken)
    .then(response => {
        const bodyString = response.data.body;
        const body = isJson(bodyString) ? JSON.parse(bodyString) : bodyString;

        console.log(body)

        if(isSuccessResponse(response)) {
            dispatch(GET_KEYWORDS_SUCCESS(body));
        } else {
            if(body === ERROR_RESPONSE_MAP.AUTH){
                dispatch(handleAuthError())
                return
            }
            if(body === ERROR_RESPONSE_MAP.PERMISSION){
                dispatch(GET_KEYWORDS_FAILURE({error: getErrorByLocale(getState, ERROR_REPSONSE_LOCALE_MAP.PERMISSION)}))
                return
            }

            dispatch(GET_KEYWORDS_FAILURE({error: body.message }));
        }

    })
    .catch(error=> {
        const message = (typeof error.response === "string") ? error.response : 'System Error, Please contact admin for more information'
        dispatch(GET_KEYWORDS_FAILURE({error: message}))
    })

}

export const createKeyword = ({flowId, keyword}) => (dispatch, getState) => {
    dispatch(bulkKeyword({insertList: [{flowId, ...keyword}], flowId}))
}

export const updateKeyword = ({flowId, keyword}) => (dispatch, getState) => {
    dispatch(bulkKeyword({updateList: [{flowId, ...keyword}], flowId}))
}

export const removeKeyword = ({flowId, id}) => (dispatch, getState) => {
    dispatch(bulkKeyword({removeIdList: [id], flowId}))
}

export const bulkKeyword = ({insertList, updateList, removeIdList, flowId}) => (dispatch, getState) => {
    dispatch(BULK_KEYWORD());

    console.log(flowId)

    insertList = insertList ? insertList : []
    updateList = updateList ? updateList : []
    removeIdList = removeIdList ? removeIdList : []

    let authToken = getToken(getState().auth.user)
    bulkKeywordService({insertKeywordList: insertList, updateKeywordList: updateList, deleteKeywordIdList: removeIdList}, authToken)
        .then(response => {
            console.log(response)
            const bodyString = response.data.body;
            const body = isJson(bodyString) ? JSON.parse(bodyString) : bodyString;
    
            console.log(body)

            if(isSuccessResponse(response)){
                dispatch(BULK_KEYWORD_SUCCESS())
                if(flowId){
                    dispatch(getKeywordList({flowId: flowId}))
                }
            }
            else{
                if(body === ERROR_RESPONSE_MAP.AUTH){
                    dispatch(handleAuthError())
                    return
                }
                if(body === ERROR_RESPONSE_MAP.PERMISSION){
                    dispatch(BULK_KEYWORD_FAILURE({error: getErrorByLocale(getState, ERROR_REPSONSE_LOCALE_MAP.PERMISSION)}))
                    return
                }
    
                dispatch(BULK_KEYWORD_FAILURE({error: body.message}))
            }

        })
        .catch((error) => {
            const message = (typeof error.response === "string") ? error.response : 'System Error, Please contact admin for more information'
            dispatch(BULK_KEYWORD_FAILURE({error: message}))
        })
}