
// https://stackoverflow.com/questions/5805059/how-do-i-make-a-placeholder-for-a-select-box
import DownArrow from "components/Icon/DownArrow";
import PropTypes from "prop-types";
import React from 'react';
import './index.css'

const LabelType = Object.freeze(
    {
        "labelOnLeft":"labelOnLeft",
        "NoLabel":"NoLabel",
    }
)

const BorderType = Object.freeze(
    {
        "none": " ",
        "Default": " border-2 focus-within:border-gray-700 border-gray-300 ",
        "Error": " border-2 border-red01 focus-within:border-red01 "
    }
)

const TextSize = Object.freeze(
    {
        "default": " text-sm",
        "flat": " text-[15px]"
    }
)

const PaddingType = Object.freeze(
    {
        "noPadding": " ",
        "normalPadding": " h-[40px] px-5 ",
        // "labelPadding": " pb-1 h-[34px] px-5 ",
        "labelPadding": " pb-1 h-[58px] px-5 pt-5",
        "labelPaddingFlat": " py-1 h-[58px] px-5 ",
        "flat": " py-1 h-[48px] px-5 ",
    }
)

const LabelPaddingType = Object.freeze(
    {
        "noPadding": " ",
        "normalPadding": " pt-1 px-5 ",
        "flat": " pt-1 px-8 ",
    }
)

const IconPaddingType = Object.freeze(
    {
        "noPadding": " ",
        "normalPadding": " right-1 ",
        "flat": " right-1 ",
    }
)

const CusRoundedSelect = React.forwardRef(({
    hasError = false,
    name = "",
    labelType,
    placeholder = "",
    invalidValueLabel = "",
    optionEmptyLabel = "",
    value = "",
    type = "",
    style = {},
    disabled = false,
    readOnly = false,
    onChange = ()=>{},
    options = [],
    className = "",
    label = "",
    paddingType = PaddingType.normalPadding,
    labelPaddingType,
    textSize = TextSize.default,
    borderType = BorderType.Default,
    iconPaddingType = IconPaddingType.normalPadding,
    ...rest
}, ref) => {

    const hasEmptyOptionsValue = () => {
        if(options.length < 1)
            return false
        let index = options.findIndex(option => !option.value);
        return index !== -1
    }

    const isNonExistedValue = (value) => {
        if(!value)
            return false

        let index = options.findIndex(option => option.value === value);

        return index === -1
    }

    const getLabelFromValue = (value) => {
        if(!value)
            return ""

        let index = options.findIndex(option => option.value === value);

        return index === -1 ? "" : options[index].label
    }

    console.log(options)

    return(
        <div className={`
            ${label ? `relative select-none flex flex-col rounded-md outline-none
                ${disabled ? "" : "pointer-events-none"}
                ${borderType ? borderType: BorderType.Default}
                ${(readOnly && !disabled) ? `opacity-100 bg-grey02`: "bg-white"}`
            : ""}
            ${className}`
        } style={style}>
            {label ?
                <span
                    className={
                        "text-sm absolute "
                        + ((disabled || value) ? ` h-auto opacity-100 translate-y-0 transition-[opacity,transform] delay-100` : " opacity-0 h-0 -translate-y-[3px] transition-[opacity,transform] delay-100")
                        + (labelPaddingType? labelPaddingType: LabelPaddingType.normalPadding)
                        + (hasError ? " text-red01 " : " text-grey06 ")
                    }
                    // style={(disabled || value) ?
                    //     { "opacity": 1, "transform": "translateY(0)", "transitionProperty": "opacity, transform", "transitionDuration": "150ms" }
                    //     : { "opacity": 0, "transform": "translateY(-3)", "transitionProperty": "opacity, transform", "transitionDuration": "150ms" }
                    // }
                >
                    {label}
                </span>
                :
                null
            }            
            {/* {label && (disabled || value) ?
                <span
                    className={
                        "text-sm "
                        + (labelPaddingType? labelPaddingType: LabelPaddingType.normalPadding)
                        + (hasError ? " text-red01 " : " text-grey06 ")
                    }                     
                >
                    {label}
                </span>
                :
                null
            } */}
            <div className={"w-full truncate"}>
                {disabled ?
                        <div 
                            className={"flex items-center w-full truncate rounded-md outline-none text-greyblack placeholder:text-greyblack placeholder:text-sm"
                                + (textSize? textSize: TextSize.default)
                                // + (label ? PaddingType.labelPadding : (paddingType? paddingType: PaddingType.normalPadding))
                                + (label ? (value? PaddingType.labelPadding : PaddingType.labelPaddingFlat) : (paddingType? paddingType: PaddingType.normalPadding))
                                + (label ? BorderType.none : (borderType? borderType: BorderType.Default))
                                + (label ? "  disabled:bg-transparent" : "disabled:bg-disabled disabled:text-black disabled:opacity-100 ")
                            } 
                            {...rest}
                        >
                            {getLabelFromValue(value)}
                        </div>
                    :
                        <fieldset disabled={readOnly || disabled}>
                            <select
                                placeholder={placeholder}
                                value={value}
                                type={type}
                                name={name}
                                className={"cus-select w-full rounded-md outline-none text-greyblack transition-[padding] delay-100 "
                                    + (textSize? textSize: TextSize.default)
                                    + (hasError ? " placeholder:text-red01 " : " placeholder:text-grey06 ")
                                    + (label ? (value? "placeholder:text-sm" : "placeholder:text-base") : "")
                                    + (label ? (value? PaddingType.labelPadding : PaddingType.labelPaddingFlat) : (paddingType? paddingType: PaddingType.normalPadding))
                                    + (label ? BorderType.none : (borderType? borderType: BorderType.Default))
                                    + (label ? " pointer-events-auto read-only:bg-transparent" : "disabled:bg-disabled disabled:text-black disabled:opacity-100 ")
                                }
                                style={style}
                                readOnly={readOnly}
                                disabled={disabled}
                                onChange={(e) => onChange(e)}
                                {...rest}
                                ref={ref} 
                                >
                                    {hasEmptyOptionsValue() ?
                                        null
                                        :
                                        <option value="" disabled hidden>{placeholder}</option>
                                    }
                                    {invalidValueLabel && isNonExistedValue(value) ?
                                        <option value={value} disabled hidden>{invalidValueLabel}</option>
                                        :
                                        null
                                    }
                                    {options.length < 1 ?
                                        <option value="" disabled>{placeholder}</option>
                                        :
                                        null
                                    }
                                    {options.map(option => 
                                        <option key={option.value} value={option.value} >{option.label}</option>
                                    )} 
                                                            
                            </select>
                            {label ?
                                null
                                :
                                <div className={`${hasError ? " text-red01 " : " text-blue04 "} w-[50px] h-[90%] flex items-center justify-center bg-white absolute top-1/2 -translate-y-1/2 ${iconPaddingType? iconPaddingType: IconPaddingType.normalPadding}`}>
                                    <DownArrow/>
                                </div>
                            }                            
                        </fieldset>
                }
            </div>
            {label && !disabled ?
                <div className={`${hasError ? " text-red01 " : " text-blue04 "} w-[50px] h-full flex items-center justify-center ${readOnly ? `bg-grey02`: "bg-white"} absolute top-1/2 -translate-y-1/2 ${iconPaddingType? iconPaddingType: IconPaddingType.normalPadding}`}>
                    <DownArrow/>
                </div>
                :
                null
            }
        </div>
    )

});

CusRoundedSelect.propTypes = {
  className: PropTypes.string,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  labelType: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  invalidValueLabel: PropTypes.string,
  optionEmptyLabel: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string,
  value: PropTypes.string,
  borderType: PropTypes.string,
  paddingType: PropTypes.string,
  textSize: PropTypes.string,
  iconPaddingType: PropTypes.string,
}

export { 
    CusRoundedSelect,
    BorderType,
    LabelType,
    PaddingType,
    LabelPaddingType,
    IconPaddingType,
    TextSize
};