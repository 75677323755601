import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import Cancel from 'components/Icon/Cancel';
import LivechatSend from 'components/Icon/LivechatSend';
import Refresh from 'components/Icon/Refresh';
import LivechatPanel from 'components/LivechatPanel';
import Scrollbars from 'react-custom-scrollbars-2';
import ReceivedMessage from 'components/ChatMessage/UserMessage/ReceivedMessage';
import AgentMessage from 'components/ChatMessage/UserMessage/AgentMessage';
import { MESSAGE_TYPE, onEnter } from 'util/helper';
import FlowNode from 'components/Icon/FlowNode';
import UpArrow from 'components/Icon/UpArrow';
import DownArrow from 'components/Icon/DownArrow';
import MoreOptionsVert from 'components/Icon/MoreOptionsVert';
import MoreOptions from 'components/Icon/MoreOptions';
import { useIntl } from 'react-intl';
import { editChatbotPrefix, generalPrefix, settingPrefix } from 'lang/locales/prefix';

const LOAD_OFFSET = 100;

const LIVECHAT_MESSAGE_USER_TYPE = Object.freeze({
    'RECEIVED': 'RECEIVED',
    'CLIENT': 'CLIENT',
})

const PreviewFlow = ({
    show = false,
    setShow = null,
    loading = false,
    messages = [],
    nodeHistory = [],
    selectedNode = null,
    focusNode = null,
    sendAction = null,
    refresh = null,
}) => {

    const intl = useIntl();

    const [selectedMessageId, setSelectedMessageId] = useState("")
    const [showFlowHistory, setShowFlowHistory] = useState(false);

    const [messageText, setMessageText] = useState("")
    const messageTextAera = useRef();

    const panelScrollBar = useRef(null);
    const panelScrollContainer = useRef(null);

    const focusNodeByMessageId = (messageId) => {
        let index = nodeHistory.findIndex(node => node.messageId === messageId);

        if(index === -1)
            return

        setSelectedMessageId(nodeHistory[index].messageId)
        focusNode(nodeHistory[index].node);
        setShowFlowHistory(false)
    }

    const focusNodeAndMessage = (node) => {
        setSelectedMessageId(node.messageId)
        focusNode(node.node);
        setShowFlowHistory(false)
    }

    const hasContent = () => {
        return !loading && messageText && messageText.trim().length > 0
    }

    const reset = () => {
        setShowFlowHistory(false)
        setMessageText("")
        refresh()
    }

    const send = () => {
        sendAction(messageText)
        setMessageText("")
    }

    const sendByEnter = (e) => {
        onEnter(e, (e) => {
            e.preventDefault();
            send()
        }, hasContent)
    }

    useEffect(() => {
        if(messageTextAera.current){
            messageTextAera.current.style.height = '40px'
            messageTextAera.current.style.height = messageTextAera.current.scrollHeight > 75 ? '75px' : messageTextAera.current.scrollHeight + 'px'

            messageTextAera.current.focus()
        }
    }, [messageText])

    const NodeHistoryItem = ({node, currentNode=false}) => {
        console.log(node)
        return (
            <div
                title={node.node.name}
                className={`py-1 flex items-center w-full cursor-pointer truncate ${currentNode ? "" : ((!!selectedNode && selectedNode.id === node.node.id && selectedMessageId === node.messageId) ? "px-5  bg-blue06" : "px-5 bg-white hover:bg-blue06")}`}
                onClick={() => focusNodeAndMessage(node)}
            >
                <div className={`${node.node.isFirstNode ? "text-greyblack" : "text-yellow01"}`}><FlowNode/></div>
                <div className="px-3 text-grey06 font-semibold text-sm shrink-0">{`${currentNode ? "Current Node" : "Node"}`}</div>
                <div className="px-2 text-greyblack font-bold text-sm truncate">{node.node.name}</div>
            </div>          
        )
    }

    useEffect(() => {
        setShowFlowHistory(false)

        if(panelScrollBar.current)
            panelScrollBar.current.scrollToBottom();
    }, [messages])

    useEffect(() => {
        if(!!selectedMessageId && panelScrollBar.current){

            let els = document.getElementsByClassName("active-message");
            if(els.length > 0){
    
                let pos = els[0].getBoundingClientRect().y
                        - panelScrollContainer.current.getBoundingClientRect().y
                        - els[0].getBoundingClientRect().height;
    
                panelScrollBar.current.scrollTop(pos)
            }

            return;
        }

    }, [selectedMessageId, panelScrollBar])


    return (
        <div className={`absolute px-2 overflow-hidden bottom-1 right-6 transition-all duration-300 z-[2] ${show ? "h-[510px] opacity-100 visible ease-in" : "h-0 opacity-0 invisible ease-out"}`}>
            <LivechatPanel
                containerHeight="h-[504px]"
                showShadow
                hasFooter
            >
                <div className="flex w-full h-full items-center justify-between">
                    <div className="pl-[25px] flex items-center font-semibold">
                        {intl.formatMessage({ "id": `${settingPrefix}.${editChatbotPrefix}.flow.preview.title` })}
                    </div>
                    <div className="pr-[25px] flex items-center text-white">
                        <div
                            title="Refresh"
                            className="cursor-pointer px-2"
                            onClick={reset}
                        >
                            <Refresh/>
                        </div>
                        <div
                            title="Hide"
                            className="cursor-pointer pl-2"
                            onClick={() => setShow(false)}
                        >
                            <Cancel/>
                        </div>
                    </div>   
                </div>
                <div className="bg-white w-full h-full overflow-hidden">
                    {nodeHistory.length > 0 ?
                        <div className="shadow flex items-center w-full h-[47px]">
                            <div className="px-5 flex justify-between items-center w-full h-full">
                                <NodeHistoryItem node={nodeHistory[nodeHistory.length - 1]} currentNode/>
                                <div
                                    className="flex justify-center shrink-0 pl-2 cursor-pointer text-blue04"
                                    onClick={() => setShowFlowHistory(!showFlowHistory)}
                                >
                                    {showFlowHistory ? <UpArrow/> : <DownArrow className="w-[14px] h-[8px]"/>}
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    <div className={`relative py-1 w-full ${nodeHistory.length > 0 ? "h-[calc(100%-47px)]" : "h-full"}`}>
                        {showFlowHistory ?
                            <div className={`absolute bg-white overflow-hidden w-full top-0 left-0 border-t-2 z-[1] h-full`}>
                                <div className="px-5 text-greyblack flex items-center font-bold text-sm py-[1px] h-[40px]">Node testing flow</div>
                                <Scrollbars
                                    className={`w-full h-[calc(100%-40px)]`}
                                >
                                    <div className="flex w-full flex-col">
                                        {nodeHistory.map((node, index) => (
                                            <div
                                                key={'node ' + index}
                                                className="flex w-full flex-col"
                                            >
                                                {index === 0 ?
                                                    null
                                                    :
                                                    <div className="px-5 py-[1px] flex justify-end w-full h-[19px]">
                                                        <div className="w-1/2 h-full border-l-2"/>
                                                    </div>
                                                }
                                                <NodeHistoryItem node={node}/>
                                            </div>
                                        ))}
                                    </div>
                                </Scrollbars>
                            </div>
                            :
                            null
                        }
                        {/* <div className={`absolute bg-white overflow-hidden w-full top-0 left-0 transition-all duration-300 border-t-2 z-[1] ${showFlowHistory ? "h-full opacity-100 visible ease-in" : "h-0 opacity-0 invisible ease-out"}`}>
                            <div className="px-5 text-greyblack flex items-center font-bold text-sm py-[1px] h-[40px]">Node testing flow</div>
                            <Scrollbars
                                className={`w-full h-[calc(100%-40px)]`}
                            >
                                <div className="flex w-full flex-col">
                                    {nodeHistory.map((node, index) => (
                                        <div
                                            key={'node ' + index}
                                            className="flex w-full flex-col"
                                        >
                                            {index === 0 ?
                                                null
                                                :
                                                <div className="px-5 py-[1px] flex justify-end w-full h-[19px]">
                                                    <div className="w-1/2 h-full border-l-2"/>
                                                </div>
                                            }
                                            <NodeHistoryItem node={node}/>
                                        </div>
                                    ))}
                                </div>
                            </Scrollbars>
                        </div> */}
                        <Scrollbars
                            ref={panelScrollBar}
                            className={`w-full h-full`}
                        >
                            <div ref={panelScrollContainer}>
                                {messages.map((msg, index) => (
                                    msg.type === LIVECHAT_MESSAGE_USER_TYPE.RECEIVED ?
                                        <span className="flex w-full px-4 cursor-pointer" key={'msg ' + index} onClick={() => focusNodeByMessageId(msg.messageId)}>
                                            <ReceivedMessage active={selectedMessageId === msg.messageId} containerSize={"max-w-[80%]"} fromUser={msg.nodeName} message={{...msg, messageType: MESSAGE_TYPE.TEXT}}/>
                                        </span>
                                        :
                                        <span className="flex w-full px-4" key={'msg ' + index}>
                                            <AgentMessage containerSize={"max-w-[80%]"} message={{...msg, messageType: MESSAGE_TYPE.TEXT}}/>
                                        </span>
                            ))}
                            </div>
                        </Scrollbars>
                    </div>
                </div>
                <div className="flex w-full flex-col bg-white pb-2 ">
                    {loading ? 
                        <div className="px-5 flex items-center text-grey06 text-sm h-[30px]">
                            <span className="opacity-50"><MoreOptions className="w-[20px] h-[20px]"/></span>
                            <span className="px-2">{intl.formatMessage({ "id": `${settingPrefix}.${editChatbotPrefix}.flow.preview.loading` })}</span>
                        </div>
                        :
                        null
                    }
                    <div className='pt-1 px-2 w-full flex flex-col border-t-2'>
                        <textarea
                            ref={messageTextAera}
                            className={`livechatpanel-textarea p-2 focus-visible:outline-none bg-transparent outline-none resize-none border-none h-auto`}
                            placeholder={intl.formatMessage({ "id": `${generalPrefix}.textarea.placeholder` })}
                            value={messageText}
                            onChange={(e) => setMessageText(e.target.value)}
                            onKeyDown={(e) => sendByEnter(e)}                        
                        />
                        <div className="pb-1 flex justify-end items-center">
                            <div className={`px-2 text-blue04 ${hasContent() ? "" : "opacity-50"}`}>
                                <div
                                    disabled={!hasContent()}
                                    onClick={hasContent() ? () => send() : () => {}}
                                    className="cursor-pointer"
                                >
                                    <LivechatSend/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LivechatPanel>
        </div>
    )
}

PreviewFlow.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    loading: PropTypes.bool,
    messages: PropTypes.arrayOf(PropTypes.object),
    nodeHistory: PropTypes.arrayOf(PropTypes.object),
    selectedNode: PropTypes.object,
    focusNode: PropTypes.func,
    sendAction: PropTypes.func,
    refresh: PropTypes.func,
};

export default PreviewFlow;