import PropTypes from 'prop-types';

const LocationMessage = ({message = ""}) => (
    <div className={`relative flex flex-col min-w-0 break-words rounded box-border bg-clip-border w-full h-full max-w-[250px] max-h-[152px]`}>
        <iframe
            className="border-0"
            title={`location`}
            width="100%"
            height="100%"
            frameBorder="0"
            src={message}
        />
    </div>
)


LocationMessage.propTypes = {
    message: PropTypes.string,
};

export default LocationMessage;