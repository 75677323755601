import {httpPost} from 'lib/requests';

const getResponse = (data, authToken) => {
    return httpPost('chatbot/response/get', data, authToken);
}

const bulkResponse = (data, authToken) => {
    return httpPost('chatbot/response/bulk', data, authToken);
}

export {
    getResponse,
    bulkResponse,
};