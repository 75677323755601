import PropTypes from 'prop-types';
import { Color as ButtonColor, CusRoundedButton, PaddingType as ButtonPadding, RoundType as ButtonRoundType } from 'components/CusRoundedButton';
import Scrollbars from 'react-custom-scrollbars-2';
import { useEffect, useRef, useState } from 'react';
import { useLanguage } from 'hooks/useLanguage';
import CusEditable, { EDITABLE_TYPE, setDefaultField, validateField } from 'components/CusEditable';
import ParameterListItem, { PARAMETER_FIELD_TEMPLATE, PARAMETER_MAP_TYPE_LIST, validateParameterListItems } from './NodeEditPanelList/ParameterListItem';
import ExternalActionListItem, { EXTERNAL_ACTION_FIELD_TEMPLATE, validateExternalActionListItems } from './NodeEditPanelList/ExternalActionListItem';
import ConditionListItem, { CONDITION_SUB_TEMPLATE, CONDITION_SUB_TYPE, validateConditionListItems } from './NodeEditPanelList/ConditionListItem';
import { addFieldItemAction, removeFieldItemAction, updateFieldItemAction } from 'util/helper';
import Info2 from 'components/Icon/Info2';
import ResponsePreviewItem from './FlowSettingModal/FlowSettingList/ResponsePreviewItem';
import { useIntl } from 'react-intl';
import { editNodePrefix, generalPrefix, settingPrefix } from 'lang/locales/prefix';
import { NODE_EDIT_SECTION_TYPE, SETTING_SUB_MENU_TYPE } from './EditFlow';
import CusEditableLeftLabel from 'components/CusEditable/CusEditableLeftLabel';
import Cross from 'components/Icon/Cross';
import { Expandable } from 'components/Expandable';
import Plus from 'components/Icon/Plus';

const CONDITION_TYPE = Object.freeze({
    "OTHERS": "OTHERS",
    "ANY_MESSAGE": "ANY_MESSAGE",
    "ANY_MEDIA": "ANY_MEDIA",
})

const CONDITION_TYPE_NAME_LIST = Object.freeze([
    "ANY_MESSAGE", "ANY_MEDIA", "OTHERS"
])

const RESPONSE_MESSAGE = Object.freeze({
    "": "Response Message",
}) 

const RESPONSE_TYPE = Object.freeze({
    "ALL_MESSAGE": "ALL_MESSAGE",
    "RANDOM_DISPLAY": "RANDOM_DISPLAY",
}) 

const RESPONSE_TYPE_NAME_LIST = Object.freeze([
    "ALL_MESSAGE", "RANDOM_DISPLAY"
]);

const ACTION_TYPE = Object.freeze({
    "DO_NOTHING": "DO_NOTHING",
    "REQUEST_LIVE_CHAT": "REQUEST_LIVE_CHAT",
    "JUMP_NODE": "JUMP_NODE",
    "RUN_ACTION": "RUN_ACTION",
}) 

const ACTION_TYPE_NAME_LIST = Object.freeze([
    "DO_NOTHING", "REQUEST_LIVE_CHAT", "JUMP_NODE", "RUN_ACTION"
]);


const ACTION_JUMP_NODE = Object.freeze({
    "": "Jump to Node",
}) 

const LANGUAGE_NAME_LIST = Object.freeze([
    "", "ENGLISH", "TRADITIONAL_CHINESE"
])

const NODE_NAME_FIELD = {
    "fieldName": "name",
    "displayNameKey": `${settingPrefix}.${editNodePrefix}.field.setup.name`,
    "displayNameEn": "Node name",
    "displayNameTc": "節點名稱",
    "displayNameSc": "节点名称",
    "fieldType": EDITABLE_TYPE.TEXT,
    "required": true,
    "editable": true,
    "creatable": true,
}

const NODE_PRIORITY_FIELD = {
    "fieldName": "priority",
    "displayNameKey": `${settingPrefix}.${editNodePrefix}.field.setup.priority`,
    "displayNameEn": "Priority",
    "displayNameTc": "優先級",
    "displayNameSc": "优先级",
    "fieldType": EDITABLE_TYPE.NUMBER,
    "decimalPlace": 0,
    "minNum": 0,
    "required": true,
    "editable": true,
    "creatable": true,
}

const NODE_LANGUAGE_FIELD = {
    "fieldName": "language",
    "displayNameKey": `${settingPrefix}.${editNodePrefix}.field.setup.language`,
    "displayNameEn": "Node language",
    "displayNameTc": "節點語言",
    "displayNameSc": "节点语言",
    "fieldType": EDITABLE_TYPE.PICKLIST,
    "options": LANGUAGE_NAME_LIST,
    "required": false,
    "editable": true,
    "creatable": true,
}

const NODE_SETUP_FIELD_TYPE = [
    {
        "fieldName": "name",
        "displayNameKey": `${settingPrefix}.${editNodePrefix}.field.setup.name`,
        "displayNameEn": "Node name",
        "displayNameTc": "節點名稱",
        "displayNameSc": "节点名称",
        "fieldType": EDITABLE_TYPE.TEXT,
        "required": true,
        "editable": true,
        "creatable": true,
    },
    {
        "fieldName": "priority",
        "displayNameKey": `${settingPrefix}.${editNodePrefix}.field.setup.priority`,
        "displayNameEn": "Priority",
        "displayNameTc": "優先級",
        "displayNameSc": "优先级",
        "fieldType": EDITABLE_TYPE.NUMBER,
        "decimalPlace": 0,
        "minNum": 0,
        "required": true,
        "editable": true,
        "creatable": true,
    },
    {
        "fieldName": "language",
        "displayNameKey": `${settingPrefix}.${editNodePrefix}.field.setup.language`,
        "displayNameEn": "Node language",
        "displayNameTc": "節點語言",
        "displayNameSc": "节点语言",
        "fieldType": EDITABLE_TYPE.PICKLIST,
        "options": LANGUAGE_NAME_LIST,
        "required": false,
        "editable": true,
        "creatable": true,
    },
]

const CONDITION_SETUP_FIELD_TYPE = [
    {
        "fieldName": "conditionType",
        "displayNameKey": `${settingPrefix}.${editNodePrefix}.field.conditions.conditionType`,
        "displayNameEn": "Type",
        "displayNameTc": "類型",
        "displayNameSc": "类型",
        "fieldType": EDITABLE_TYPE.PICKLIST,
        "options": CONDITION_TYPE_NAME_LIST,
        "required": true,
        "editable": true,
        "creatable": true,
    },
]

const EXTRA_CONDITION_SETUP_FIELD_TYPE = [
    {
        "fieldName": "isFirstNode",
        "displayNameKey": `${settingPrefix}.${editNodePrefix}.field.setup.isFirstNode`,
        "displayNameEn": "Must be the first node in conversation",
        "displayNameTc": "必須是對話中的第一個節點",
        "displayNameSc": "必须是对话中的第一个节点",
        "fieldType": EDITABLE_TYPE.BOOLEAN_CHECKBOX,
        "required": false,
        "editable": false,
        "creatable": true,
    },
]

const NODE_REPSONSE_FIELD_TYPE = [
    {
        "fieldName": "responseId",
        "displayNameKey": `${settingPrefix}.${editNodePrefix}.field.response.responseId`,
        "displayNameEn": "Message",
        "displayNameTc": "消息",
        "displayNameSc": "信息",
        "fieldType": EDITABLE_TYPE.PICKLIST,
        "options": [],
        "required": false,
        "editable": true,
        "creatable": true,
    },
    {
        "fieldName": "invalidResponseId",
        "displayNameKey": `${settingPrefix}.${editNodePrefix}.field.response.invalidResponseId`,
        "displayNameEn": "Invalid Message",
        "displayNameTc": "錯誤消息",
        "displayNameSc": "錯誤消息",
        "fieldType": EDITABLE_TYPE.PICKLIST,
        "options": [],
        "required": false,
        "editable": true,
        "creatable": true,
    },
    {
        "fieldName": "responseType",
        "displayNameKey": `${settingPrefix}.${editNodePrefix}.field.response.responseType`,
        "displayNameEn": "Method",
        "displayNameTc": "方式",
        "displayNameSc": "方式",
        "fieldType": EDITABLE_TYPE.PICKLIST,
        "options": RESPONSE_TYPE_NAME_LIST,
        "required": true,
        "editable": true,
        "creatable": true,
    },
]

const NODE_ACTION_FIELD_TYPE = [
    {
        "fieldName": "afterAction",
        "displayNameKey": `${settingPrefix}.${editNodePrefix}.field.action.afterAction`,
        "displayNameEn": "Action",
        "displayNameTc": "動作",
        "displayNameSc": "动作",
        "fieldType": EDITABLE_TYPE.PICKLIST,
        "options": ACTION_TYPE_NAME_LIST,
        "required": true,
        "editable": true,
        "creatable": true,
    },
]

const EXTRA_NODE_ACTION_FIELD_TYPE = [
    {
        "fieldName": "jumpNodeId",
        "displayNameKey": `${settingPrefix}.${editNodePrefix}.field.action.jumpNodeId`,
        "displayNameEn": "Jump to Node",
        "displayNameTc": "跳轉到節點",
        "displayNameSc": "跳转到节点",
        "fieldType": EDITABLE_TYPE.PICKLIST,
        "options": [],
        "required": true,
        "editable": true,
        "creatable": true,
    },
]


const NodeEditPanel = ({
    isEdit = false,
    setIsEdit = null,
    nodeEditSection = NODE_EDIT_SECTION_TYPE.DEFAULT,
    setNodeEditSection = () => {},
    selectedFlow = null,
    selectedNode = null,
    nodeList = [],    
    keywordList = [],    
    entityList = [],    
    responseList = [],    
    actionList = [],    
    parameterList = [],    
    updateNodeAction = null,
    removeNodeAction = null,
    cancelEditAction = null,
    openEditSettingAction = null,
    getFlowSettingById = null,
}) => {

    // const [isEdit, setIsEdit] = useState(false);

    const panelScrollBar = useRef(null);

    const [errorMap, setErrorMap] = useState({});
    const [editableNode, setEditableNode] = useState({})

    const [showNodeConditionList, setShowNodeConditionList] = useState(false);
    const [showNodeParameterList, setShowNodeParameterList] = useState(false);
    const [showNodeExternalActionList, sestShowNodeExternalActionList] = useState(false);

    const [nodeConditionList, setNodeConditionList] = useState([])
    const [nodeConditionErrorList, setNodeConditionErrorList] = useState([]);

    const [nodeParameterList, setNodeParameterList] = useState([])
    const [nodeParameterErrorList, setNodeParameterErrorList] = useState([]);

    const [nodeExternalActionList, setNodeExternalActionList] = useState([])
    const [nodeExternalActionErrorList, setNodeExternalActionErrorList] = useState([]);

    const [showResponseMsgMenu, setShowResponseMsgMenu] = useState(false);
    const [responseMsgMenuPos, setResponseMsgMenuPos] = useState(null);

    const [selectedResponse, setSelectedResponse] = useState(null);

    const intl = useIntl();
    const { language } = useLanguage()

    let jumpToNodeList = JSON.parse(JSON.stringify(nodeList))

    jumpToNodeList = jumpToNodeList.filter(node => !node.isGeneralNode && (!!selectedNode.id ? node.id !== selectedNode.id : true))

    const LANGUAGE_NAME = Object.freeze({
        "default": `${settingPrefix}.${editNodePrefix}.nameList.language.default`,
        "ENGLISH": `${generalPrefix}.nameList.language.english`,
        "TRADITIONAL_CHINESE": `${generalPrefix}.nameList.language.traditionalChinese`,
    })
    
    const CONDITION_TYPE_NAME = Object.freeze({
        "OTHERS": `${settingPrefix}.${editNodePrefix}.nameList.conditionType.others`,
        "ANY_MESSAGE": `${settingPrefix}.${editNodePrefix}.nameList.conditionType.anyMessage`,
        "ANY_MEDIA": `${settingPrefix}.${editNodePrefix}.nameList.conditionType.anyMedia`,
    })

    const RESPONSE_TYPE_NAME = Object.freeze({
        "ALL_MESSAGE": `${settingPrefix}.${editNodePrefix}.nameList.responseType.allMessage`,
        "RANDOM_DISPLAY": `${settingPrefix}.${editNodePrefix}.nameList.responseType.randomDisplay`,
    }) 

    const ACTION_TYPE_NAME = Object.freeze({
        "DO_NOTHING": `${settingPrefix}.${editNodePrefix}.nameList.action.doNothing`,
        "REQUEST_LIVE_CHAT": `${settingPrefix}.${editNodePrefix}.nameList.action.liveChat`,
        "JUMP_NODE": `${settingPrefix}.${editNodePrefix}.nameList.action.jumpNode`,
        "RUN_ACTION": `${settingPrefix}.${editNodePrefix}.nameList.action.runAction`,
    }) 
        
    const getOptions = (fieldType, fieldName, options) => {
        if(fieldType !== EDITABLE_TYPE.PICKLIST)
            return []

        if(fieldName === "language")
            return options.map(option => ({label: option ? intl.formatMessage({ "id": LANGUAGE_NAME[option] }) : intl.formatMessage({ "id": LANGUAGE_NAME.default }), value: option}))

        if(fieldName === "conditionType")
            return options.map(option => ({label: intl.formatMessage({ "id": CONDITION_TYPE_NAME[option] }), value: option}))

        if(fieldName === "responseId"){
            let options = [{label: RESPONSE_MESSAGE[""], value: ""}]
            return [...options, ...responseList.map(repsonse => ({label: repsonse.name, value: repsonse.id}))]
        }

        if(fieldName === "invalidResponseId"){
            let options = [{label: RESPONSE_MESSAGE[""], value: ""}]
            return [...options, ...responseList.map(repsonse => ({label: repsonse.name, value: repsonse.id}))]
        }

        if(fieldName === "responseType")
            return options.map(option => ({label: intl.formatMessage({ "id": RESPONSE_TYPE_NAME[option] }), value: option}))

        if(fieldName === "afterAction")
            return options.map(action => ({label: intl.formatMessage({ "id": ACTION_TYPE_NAME[action] }), value: action}))

        if(fieldName === "jumpNodeId"){
            let options = [{label: ACTION_JUMP_NODE[""], value: ""}]
            return [...options, ...jumpToNodeList.map(node => ({label: node.name, value: node.id}))]
        }

        return options.map(option => ({label: option, value: option}))
    }


    const onChangeHandler = (isCreate, field, e) => {
        
        let value = e.target.value;
        let errorMap = {};

        switch (field.fieldType) {
            case EDITABLE_TYPE.BOOLEAN:
            case EDITABLE_TYPE.BOOLEAN_CHECKBOX:
                value = !editableNode[field.fieldName]
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;        
            default:
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;
        }
        
        console.log(field.fieldName)

        switch (field.fieldName) {
            case "conditionType":
                if(value === CONDITION_TYPE.OTHERS)
                    addCondition()
                else{
                    setNodeConditionList([])
                    setNodeConditionErrorList([])
                }
                break;
            case "afterAction":                
                if(value === ACTION_TYPE.RUN_ACTION){
                    if(nodeExternalActionList.length < 1)
                        addExternalAction()
                }
                else{
                    setNodeExternalActionList([])
                    setNodeExternalActionErrorList([])            
                }
                break;        
            default:
                break;
        }

        if(errorMap && Object.keys(errorMap).length > 0)
            setErrorMap((erMap) => ({...erMap, ...errorMap}))
        else{
            setErrorMap((erMap) => {
                let newErrorMap = {...erMap}
                delete newErrorMap[field.fieldName]
                return newErrorMap 
            })
        }

        setEditableNode((editableFields) => ({...editableFields, [field.fieldName]: value}))
    }

    const addCondition = () => addFieldItemAction({itemTemplate: CONDITION_SUB_TEMPLATE, setFieldList: setNodeConditionList, setFieldErrorList: setNodeConditionErrorList})
    const updateCondition = (field, error, index) => updateFieldItemAction({field, error, index, fieldList: nodeConditionList, fieldErrorList: nodeConditionErrorList, setFieldList: setNodeConditionList, setFieldErrorList: setNodeConditionErrorList})
    const removeCondition = (index) => removeFieldItemAction({index, setFieldList: setNodeConditionList, setFieldErrorList: setNodeConditionErrorList})

    const addParameter = () => addFieldItemAction({itemTemplate: PARAMETER_FIELD_TEMPLATE, setFieldList: setNodeParameterList, setFieldErrorList: setNodeParameterErrorList})
    const updateParameter = (field, error, index) => updateFieldItemAction({field, error, index, fieldList: nodeParameterList, fieldErrorList: nodeParameterErrorList, setFieldList: setNodeParameterList, setFieldErrorList: setNodeParameterErrorList})
    const removeParameter = (index) => removeFieldItemAction({index, setFieldList: setNodeParameterList, setFieldErrorList: setNodeParameterErrorList})

    const addExternalAction = () => addFieldItemAction({itemTemplate: EXTERNAL_ACTION_FIELD_TEMPLATE, setFieldList: setNodeExternalActionList, setFieldErrorList: setNodeExternalActionErrorList})
    const updateExternalAction = (field, error, index) => updateFieldItemAction({field, error, index, fieldList: nodeExternalActionList, fieldErrorList: nodeExternalActionErrorList, setFieldList: setNodeExternalActionList, setFieldErrorList: setNodeExternalActionErrorList})
    const removeExternalAction = (index) => removeFieldItemAction({index, setFieldList: setNodeExternalActionList, setFieldErrorList: setNodeExternalActionErrorList})

    const validateFields = (isCreate) => {
        let errorMap = {}

        let mapping = [
            ...NODE_SETUP_FIELD_TYPE, 
            ...CONDITION_SETUP_FIELD_TYPE, 
            ...NODE_REPSONSE_FIELD_TYPE, 
            ...NODE_ACTION_FIELD_TYPE
        ]

        if(!editableNode.preNodeId){
            mapping = [...mapping, ...EXTRA_CONDITION_SETUP_FIELD_TYPE]
        }

        if(editableNode.afterAction === ACTION_TYPE.JUMP_NODE){
            mapping = [...mapping, ...EXTRA_NODE_ACTION_FIELD_TYPE]
        }

        for(let field of mapping){
            if(!editableNode[field.fieldName])
                setDefaultField(field, editableNode, field.fieldName, isCreate)
            errorMap = {...errorMap, ...validateField(field, editableNode[field.fieldName], intl.formatMessage, isCreate)}
        }

        let nodeConditionErrorList = []
        let nodeParameterErrorList = []
        let nodeExternalActionErrorList = []

        if(editableNode.conditionType === CONDITION_TYPE.OTHERS)
            nodeConditionErrorList = validateConditionListItems(intl.formatMessage, isCreate, nodeConditionList)

        if(nodeParameterList.length > 0)
            nodeParameterErrorList = validateParameterListItems(intl.formatMessage, isCreate, nodeParameterList)

        if(editableNode.afterAction === ACTION_TYPE.RUN_ACTION)
            nodeExternalActionErrorList = validateExternalActionListItems(intl.formatMessage, isCreate, nodeExternalActionList)

        let hasError = false;

        if(errorMap && Object.keys(errorMap).length > 0)
            hasError = true;

        console.log(
            nodeConditionErrorList.filter(error => Object.keys(error).length > 0).length > 0
            || nodeParameterErrorList.filter(error => Object.keys(error).length > 0).length > 0
            || nodeExternalActionErrorList.filter(error => Object.keys(error).length > 0).length > 0
        )

        if(
            nodeConditionErrorList.filter(error => Object.keys(error).length > 0).length > 0
            || nodeParameterErrorList.filter(error => Object.keys(error).length > 0).length > 0
            || nodeExternalActionErrorList.filter(error => Object.keys(error).length > 0).length > 0
        )
            hasError = true;

        if(hasError){
            console.log(errorMap)
            console.log(nodeConditionErrorList)
            console.log(nodeParameterErrorList)
            console.log(nodeExternalActionErrorList)
            setNodeConditionErrorList(nodeConditionErrorList)
            setNodeParameterErrorList(nodeParameterErrorList)
            setNodeExternalActionErrorList(nodeExternalActionErrorList)
            setErrorMap(errorMap)
            return;
        }


        let completeObject = {
            flowId: editableNode.flowId,
            name: editableNode.name ?? "",
            language: editableNode.language ?? "",
            preNodeId: editableNode.preNodeId ?? "",
            isGeneralNode: false,
            isFirstNode: !!editableNode.isFirstNode,
            isLastNode: false,
            priority: parseInt(editableNode.priority),
            // conditionType: CONDITION_TYPE.ANY_MESSAGE,
            chatbotNodeConditionProcessList: [{responseType: editableNode.responseType, afterAction: editableNode.afterAction}],

            keywordIdSet: [],
            entityIdValueMap: {},
            parameterMap: {},
        }

        if(!isCreate)
            completeObject.id = editableNode.id

        if(editableNode.preNodeId)
            completeObject.preNodeId = editableNode.preNodeId ?? ""

        if(editableNode.responseId)
            completeObject.chatbotNodeConditionProcessList[0].responseId = editableNode.responseId;

        if(editableNode.invalidResponseId)
            completeObject.chatbotNodeConditionProcessList[0].invalidResponseId = editableNode.invalidResponseId;

        switch (editableNode.conditionType) {
            case CONDITION_TYPE.OTHERS:
                let keywordIdSet = []
                let entityIdValueMap = {}
                let executeCondition = {}
            
                nodeConditionList.forEach(condition => {
                    switch (condition.conditionSubType) {
                        case CONDITION_SUB_TYPE.KEYWORD:
                            keywordIdSet.push(condition.keywordId)
                            break;
                        case CONDITION_SUB_TYPE.ENTITY:
                            entityIdValueMap[condition.entityId] = condition.value && condition.value.trim().length > 0 ? condition.value : "<?ANY>"
                            break;
                        case CONDITION_SUB_TYPE.PARAMETER:
                            executeCondition.parameterName = condition.parameterName
                            executeCondition.condition = "EQUALS"
                            break;                    
                        default:
                            break;
                    }
                })

                if(keywordIdSet.length > 0){
                    completeObject.keywordIdSet = keywordIdSet
                }

                if(Object.keys(entityIdValueMap).length > 0){
                    completeObject.entityIdValueMap = entityIdValueMap
                }

                if(Object.keys(executeCondition).length > 0){
                    completeObject.executeCondition = executeCondition
                }
                
                completeObject.isAcceptMedia = false
                completeObject.isAcceptAnyMessage = false

                break;
                
            case CONDITION_TYPE.ANY_MEDIA:
                completeObject.isAcceptMedia = true
                completeObject.isAcceptAnyMessage = false
                break;        
            default:
                completeObject.isAcceptMedia = false
                completeObject.isAcceptAnyMessage = true
                break;
        }

        switch (editableNode.afterAction) {
            case ACTION_TYPE.RUN_ACTION:
                completeObject.chatbotNodeConditionProcessList[0].nodeExternalActionList = JSON.parse(JSON.stringify(nodeExternalActionList))
                if(editableNode.jumpNodeId)
                    completeObject.chatbotNodeConditionProcessList[0].jumpNodeId = editableNode.jumpNodeId

                break;
            case ACTION_TYPE.JUMP_NODE:
                completeObject.chatbotNodeConditionProcessList[0].jumpNodeId = editableNode.jumpNodeId
                break;        
            default:
                break;
        }

        if(nodeParameterList.length > 0){
            completeObject.parameterMap = {}
            nodeParameterList.forEach(parameter => {
                if(parameter.type === "CUSTOM")
                    completeObject.parameterMap[parameter.label] = parameter.value
                else
                    completeObject.parameterMap[parameter.label] = parameter.type
            })
        }

        console.log(completeObject)
        updateNodeAction(completeObject)
    }

    const openResponseMsg = (e, responseId) => {
        console.log(responseId)
        let setting = getFlowSettingById(SETTING_SUB_MENU_TYPE.RESPONSE, responseId)

        if(!setting)
            return

        setSelectedResponse(setting)
        let pos = e.target.closest('.responseMsg-pos').getBoundingClientRect()
        console.log(pos)
        setResponseMsgMenuPos({x: pos.left, y: pos.top + 5})
        setShowResponseMsgMenu(true)
    }

    const closeResponseMsg = () => {
        setSelectedResponse(null)
        setResponseMsgMenuPos(null)
        setShowResponseMsgMenu(false)
    }

    const refresh = () => {
        let currentSelectedNode = JSON.parse(JSON.stringify(selectedNode))
        
        let editableNode = {
            flowId: currentSelectedNode.flowId,
            name: currentSelectedNode.name ?? "",
            language: currentSelectedNode.language ?? "",
            isFirstNode: !currentSelectedNode.preNodeId,
            priority: "0",
            preNodeId: currentSelectedNode.preNodeId ?? "",
            conditionType: CONDITION_TYPE.ANY_MESSAGE,
            responseId: "",
            responseType: RESPONSE_TYPE.ALL_MESSAGE,
            afterAction: ACTION_TYPE.DO_NOTHING
        }

        if(currentSelectedNode.priority !== undefined)
            editableNode.priority = `${currentSelectedNode.priority}`

        let nodeConditionList = [];
        let parameterList = [];
        let nodeExternalActionList = [];

        if(currentSelectedNode.isAcceptMedia)
            editableNode.conditionType = CONDITION_TYPE.ANY_MEDIA

        if(!!currentSelectedNode.keywordIdSet && currentSelectedNode.keywordIdSet.length > 0){
            currentSelectedNode.keywordIdSet.forEach(id => nodeConditionList.push({conditionSubType: CONDITION_SUB_TYPE.KEYWORD, keywordId: id}) );
        }

        if(!!currentSelectedNode.entityIdValueMap && Object.keys(currentSelectedNode.entityIdValueMap).length > 0){
            Object.keys(currentSelectedNode.entityIdValueMap).forEach(id => nodeConditionList.push({conditionSubType: CONDITION_SUB_TYPE.ENTITY, entityId: id, value: currentSelectedNode.entityIdValueMap[id] !== "<?ANY>" ? currentSelectedNode.entityIdValueMap[id] : ""}) );
        }

        if(!!currentSelectedNode.executeCondition && Object.keys(currentSelectedNode.executeCondition).length > 0){
            nodeConditionList.push({conditionSubType: CONDITION_SUB_TYPE.PARAMETER, parameterName: currentSelectedNode.executeCondition.parameterName})
        }

        if(nodeConditionList.length > 0)
            editableNode.conditionType = CONDITION_TYPE.OTHERS

        if(currentSelectedNode.parameterMap){
            parameterList = Object.keys(currentSelectedNode.parameterMap)
                .filter(parameter => parameter !== 'SYS_LANGUAGE')
                .map(parameter => 
                {
                    let type = 'CUSTOM'
            
                    if(PARAMETER_MAP_TYPE_LIST.includes(currentSelectedNode.parameterMap[parameter]))
                        type = currentSelectedNode.parameterMap[parameter]
            
                    return {label: parameter, type: type, value: currentSelectedNode.parameterMap[parameter]}
                }
            )
        }
    
        if(currentSelectedNode.chatbotNodeConditionProcessList && currentSelectedNode.chatbotNodeConditionProcessList[0]){
            editableNode.responseId = currentSelectedNode.chatbotNodeConditionProcessList[0].responseId ?? ""
            editableNode.invalidResponseId = currentSelectedNode.chatbotNodeConditionProcessList[0].invalidResponseId ?? ""
            editableNode.responseType = currentSelectedNode.chatbotNodeConditionProcessList[0].responseType ?? RESPONSE_TYPE.ALL_MESSAGE

            editableNode.afterAction = currentSelectedNode.chatbotNodeConditionProcessList[0].afterAction ?? ACTION_TYPE.DO_NOTHING
            editableNode.jumpNodeId = currentSelectedNode.chatbotNodeConditionProcessList[0].jumpNodeId ?? ""
            
            nodeExternalActionList = currentSelectedNode.chatbotNodeConditionProcessList[0].nodeExternalActionList ?? [{...EXTERNAL_ACTION_FIELD_TEMPLATE}]
        }

        setNodeConditionList(nodeConditionList)
        setNodeConditionErrorList(Array(nodeConditionList.length).fill({}))

        setNodeParameterList(parameterList)
        setNodeParameterErrorList(Array(parameterList.length).fill({}))

        setNodeExternalActionList(nodeExternalActionList)
        setNodeExternalActionErrorList(Array(nodeExternalActionList.length).fill({}))

        setEditableNode(editableNode)
        setErrorMap({})

        console.log(!!selectedNode.isCreate)
        setIsEdit(!!selectedNode.isCreate)
    }

    useEffect(() => {
        console.log('responseList')
        closeResponseMsg();
    }, [responseList])

    useEffect(() => {
        if(panelScrollBar.current)
            panelScrollBar.current.scrollToTop();
    }, [isEdit, selectedNode])

    useEffect(() => {
        console.log("change")
        refresh()
    }, [selectedNode])

    return (
        <>
            <div className="w-full h-full flex flex-col border-l-[2px] shadow bg-white">
                <div className="flex justify-between items-center px-6 text-grey06 font-light pt-3 h-[45px]">
                    <CusEditableLeftLabel
                        readOnly={(!isEdit || selectedFlow.type === "RELEASED") || (!!selectedNode.id ? !NODE_NAME_FIELD.editable : !NODE_NAME_FIELD.creatable)}
                        fieldType={NODE_NAME_FIELD.fieldType}
                        label="Untitled Node"
                        value={editableNode[NODE_NAME_FIELD.fieldName] ?? ""}
                        errors={errorMap}
                        required={NODE_NAME_FIELD.required}
                        errorName={NODE_NAME_FIELD.fieldName}
                        showLabelOnLeft={false}
                        textSize=" text-2xl "
                        paddingType=" h-[30px] "
                        placeholderSize=" placeholder:text-2xl "
                        onChange={(e) => onChangeHandler(selectedNode.isCreate, NODE_NAME_FIELD, e)}
                    />
                    {isEdit ?
                        <div className="pl-2">
                            <div className="w-[24px] h-[24px] flex justify-center items-center cursor-pointer text-[#64748B]" onClick={selectedNode.isCreate ? () => cancelEditAction() : () => refresh()}>
                                <Cross className="w-[11px] h-[11px]"/>
                            </div>
                        </div>
                    :
                        <div className="pl-2">
                            <div className="w-[24px] h-[24px] flex justify-center items-center cursor-pointer text-[#64748B]" onClick={() => cancelEditAction()}>
                                <Cross className="w-[11px] h-[11px]"/>
                            </div>
                        </div>
                    }

                </div>
                <div className={`w-full flex flex-col ${selectedFlow.type === "RELEASED" ? "h-[calc(100%-45px)]" : "h-[calc(100%-45px-72px)]"}`}>
                    <Scrollbars
                        ref={panelScrollBar}
                        className="w-full h-full"
                        autoHide
                        autoHideDuration={100}
                    >
                        <div className='px-6 pb-1 flex items-center'>
                            <div className='w-1/2'>
                                <CusEditableLeftLabel
                                    readOnly={(!isEdit || selectedFlow.type === "RELEASED") || (!!selectedNode.id ? !NODE_PRIORITY_FIELD.editable : !NODE_PRIORITY_FIELD.creatable)}
                                    fieldType={NODE_PRIORITY_FIELD.fieldType}
                                    label="Priority"
                                    labelSize={45}
                                    value={editableNode[NODE_PRIORITY_FIELD.fieldName] ?? ""}
                                    errors={errorMap}
                                    required={NODE_PRIORITY_FIELD.required}
                                    errorName={NODE_PRIORITY_FIELD.fieldName}
                                    onChange={(e) => onChangeHandler(selectedNode.isCreate, NODE_PRIORITY_FIELD, e)}
                                />
                            </div>
                            <div className='w-1/2'>
                                <CusEditableLeftLabel
                                    readOnly={(!isEdit || selectedFlow.type === "RELEASED") || (!!selectedNode.id ? !NODE_LANGUAGE_FIELD.editable : !NODE_LANGUAGE_FIELD.creatable)}
                                    fieldType={NODE_LANGUAGE_FIELD.fieldType}
                                    label="Language"
                                    value={editableNode[NODE_LANGUAGE_FIELD.fieldName] ?? ""}
                                    options={getOptions(NODE_LANGUAGE_FIELD.fieldType, NODE_LANGUAGE_FIELD.fieldName, NODE_LANGUAGE_FIELD.options)}
                                    errors={errorMap}
                                    required={NODE_LANGUAGE_FIELD.required}
                                    errorName={NODE_LANGUAGE_FIELD.fieldName}
                                    showLabelOnLeft={false}
                                    onChange={(e) => onChangeHandler(selectedNode.isCreate, NODE_LANGUAGE_FIELD, e)}
                                />
                            </div>
                        </div>
                        {/* {!selectedNode.preNodeId && EXTRA_CONDITION_SETUP_FIELD_TYPE.map((field) => (
                            <div className='px-6'>
                                <CusEditableLeftLabel
                                    key={field.fieldName}
                                    readOnly={(!isEdit || selectedFlow.type === "RELEASED") || (!!selectedNode.id ? !field.editable : !field.creatable)}
                                    fieldType={field.fieldType}
                                    label={intl.formatMessage({ "id": field.displayNameKey }) + (field.required ? "*" : "")}
                                    value={!!editableNode[field.fieldName]}
                                    errors={errorMap}
                                    required={field.required}
                                    errorName={field.fieldName}
                                    showLabelOnLeft={false}
                                    textSize=" text-[13px] "
                                    onChange={(e) => onChangeHandler(selectedNode.isCreate, field, e)}
                                />
                            </div>
                        ))} */}
                        <div className='w-full pt-3'><hr/></div>
                        <Expandable
                            className='px-6 py-[18px]'
                            expandedClassName=" text-greyblack "
                            expand={nodeEditSection === NODE_EDIT_SECTION_TYPE.CONDITIONS}
                            setExpand={(expand) => setNodeEditSection(expand ? NODE_EDIT_SECTION_TYPE.CONDITIONS : "")}
                            right
                        >
                            <span className='text-base'>CHAT CONDITIONS</span>
                            <>
                                <div className='px-6 pt-[18px]'/>
                                {CONDITION_SETUP_FIELD_TYPE.map((field) => (
                                    <CusEditableLeftLabel
                                        key={field.fieldName}
                                        readOnly={(!isEdit || selectedFlow.type === "RELEASED") || (!!selectedNode.id ? !field.editable : !field.creatable)}
                                        fieldType={field.fieldType}
                                        label={field.displayNameEn}
                                        value={editableNode[field.fieldName] ?? field.defaultValue}
                                        options={getOptions(field.fieldType, field.fieldName, field.options)}
                                        errors={errorMap}
                                        required={field.required}
                                        errorName={field.fieldName}
                                        onChange={(e) => onChangeHandler(selectedNode.isCreate, field, e)}
                                    />
                                ))}
                                {(editableNode.conditionType === CONDITION_TYPE.OTHERS) && (isEdit || nodeConditionList.length > 0) ?
                                        <Expandable
                                            className='py-[18px]'
                                            expand={showNodeConditionList}
                                            setExpand={(expand) => setShowNodeConditionList(expand)}
                                            right
                                        >
                                            <span className='text-base'>Conditions</span>
                                            <>
                                                <div className='px-6 pt-[18px]'/>
                                                <div className='px-4 w-full flex flex-col border-[#CBD5E1] border-[1px] rounded-[8px]'>
                                                    {nodeConditionList.map((condition, index) => (
                                                        <ConditionListItem
                                                            key={`Condition-${index}`}
                                                            isCreate={selectedNode.isCreate}
                                                            readOnly={(!isEdit || selectedFlow.type === "RELEASED")}
                                                            itemIndex={index}
                                                            keywordList={keywordList}
                                                            entityList={entityList}
                                                            parameterList={parameterList}
                                                            conditionListItem={condition}
                                                            error={nodeConditionErrorList[index]}
                                                            updateCondition={(field, error) => updateCondition(field, error, index)}
                                                            removeCondition={() => removeCondition(index)}
                                                            openEditSettingAction={openEditSettingAction}
                                                            getFlowSettingById={getFlowSettingById}
                                                        />
                                                    ))}
                                                    {(!isEdit || selectedFlow.type === "RELEASED") ?
                                                        null
                                                        :
                                                        <div className="flex w-full py-3">
                                                            <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex text-base flex-1 h-[40px] items-center rounded-[5px]'
                                                                color={ButtonColor.darkNoBorder}
                                                                onClick={() => addCondition()}
                                                            >
                                                                <Plus/>
                                                                <span className='pl-2 text-base'>{intl.formatMessage({ "id": `${settingPrefix}.${editNodePrefix}.button.addCondition` })}</span>
                                                            </CusRoundedButton>
                                                        </div>
                                                    }
                                                </div>
                                            </>
                                        </Expandable>
                                    :
                                    null
                                }
                                {isEdit || nodeParameterList.length > 0 ?
                                        <Expandable
                                            className='py-[18px]'
                                            expand={showNodeParameterList}
                                            setExpand={(expand) => setShowNodeParameterList(expand)}
                                            right
                                        >
                                            <span className='text-base'>Variable</span>
                                            <>
                                                <div className='px-6 pt-[18px]'/>
                                                <div className='px-4 w-full flex flex-col border-[#CBD5E1] border-[1px] rounded-[8px]'>
                                                    {nodeParameterList.map((parameter, index) => (
                                                        <ParameterListItem
                                                            key={`Parameter-${index}`}
                                                            isCreate={selectedNode.isCreate}
                                                            readOnly={(!isEdit || selectedFlow.type === "RELEASED")}
                                                            itemIndex={index}
                                                            parameterList={parameterList}
                                                            parameterListItem={parameter}
                                                            error={nodeParameterErrorList[index]}
                                                            updateParameter={(field, error) => updateParameter(field, error, index)}
                                                            removeParameter={() => removeParameter(index)}
                                                        />
                                                    ))}
                                                    {(!isEdit || selectedFlow.type === "RELEASED") ?
                                                        null
                                                        :
                                                        <div className="flex w-full py-3">
                                                            <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex text-base flex-1 h-[40px] items-center rounded-[5px]'
                                                                color={ButtonColor.darkNoBorder}
                                                                onClick={() => addParameter()}
                                                            >
                                                                <Plus/>
                                                                <span className='pl-2 text-base'>{intl.formatMessage({ "id": `${settingPrefix}.${editNodePrefix}.button.addVariable` })}</span>
                                                            </CusRoundedButton>
                                                        </div>
                                                    }
                                                </div>
                                            </>
                                        </Expandable>
                                    :
                                    null
                                }
                                {/* <div className='px-4 py-3 w-full flex flex-col border-[#CBD5E1] border-[1px] rounded-[8px]'>

                                </div> */}
                            </>
                        </Expandable>
                        <div className='w-full'><hr/></div>
                        <Expandable
                            className='px-6 py-[18px]'
                            expandedClassName=" text-greyblack "
                            expand={nodeEditSection === NODE_EDIT_SECTION_TYPE.RESPONSE}
                            setExpand={(expand) => setNodeEditSection(expand ? NODE_EDIT_SECTION_TYPE.RESPONSE : "")}
                            right
                        >
                            <span className='text-base'>RESPONSES</span>
                            <>
                                <div className='px-6 pt-[18px]'/>
                                {NODE_REPSONSE_FIELD_TYPE.map((field) => (
                                    field.fieldName === "responseId" ?
                                        <div
                                            key={field.fieldName}
                                            className="flex items-center"
                                        >
                                            <CusEditableLeftLabel
                                                readOnly={(!isEdit || selectedFlow.type === "RELEASED") || (!!selectedNode.id ? !field.editable : !field.creatable)}
                                                fieldType={field.fieldType}
                                                label={field.displayNameEn}
                                                value={editableNode[field.fieldName] ?? field.defaultValue}
                                                options={getOptions(field.fieldType, field.fieldName, field.options)}
                                                errors={errorMap}
                                                required={field.required}
                                                errorName={field.fieldName}
                                                onChange={(e) => onChangeHandler(selectedNode.isCreate, field, e)}
                                            />
                                            <div className={`p-2 text-[#94A3B8] responseMsg-pos`}>
                                                <div className={`${(editableNode[field.fieldName] ?? field.defaultValue) ? "cursor-pointer" : "opacity-50" }`} onClick={(editableNode[field.fieldName] ?? field.defaultValue) ? (e) => openResponseMsg(e, editableNode[field.fieldName] ?? field.defaultValue) : (e) => {} }>
                                                    <span className="text-[13px]">PREVIEW</span>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        field.fieldName === "invalidResponseId" ?
                                            <div
                                                key={field.fieldName}
                                                className="flex items-center"
                                            >
                                                <CusEditableLeftLabel
                                                    readOnly={(!isEdit || selectedFlow.type === "RELEASED") || (!!selectedNode.id ? !field.editable : !field.creatable)}
                                                    fieldType={field.fieldType}
                                                    label={field.displayNameEn}
                                                    value={editableNode[field.fieldName] ?? field.defaultValue}
                                                    options={getOptions(field.fieldType, field.fieldName, field.options)}
                                                    errors={errorMap}
                                                    required={field.required}
                                                    errorName={field.fieldName}
                                                    onChange={(e) => onChangeHandler(selectedNode.isCreate, field, e)}
                                                />
                                                <div className={`p-2 text-[#94A3B8] responseMsg-pos`}>
                                                    <div
                                                        className={`${(editableNode[field.fieldName] ?? field.defaultValue) ? "cursor-pointer" : "opacity-50"}`}
                                                        onClick={(editableNode[field.fieldName] ?? field.defaultValue) ? (e) => openResponseMsg(e, editableNode[field.fieldName] ?? field.defaultValue) : (e) => {
                                                        }}>
                                                        <span className="text-[13px]">PREVIEW</span>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <CusEditableLeftLabel
                                                key={field.fieldName}
                                                readOnly={(!isEdit || selectedFlow.type === "RELEASED") || (!!selectedNode.id ? !field.editable : !field.creatable)}
                                                fieldType={field.fieldType}
                                                label={field.displayNameEn}
                                                value={editableNode[field.fieldName] ?? field.defaultValue}
                                                options={getOptions(field.fieldType, field.fieldName, field.options)}
                                                errors={errorMap}
                                                required={field.required}
                                                errorName={field.fieldName}
                                                onChange={(e) => onChangeHandler(selectedNode.isCreate, field, e)}
                                            />
                                ))}
                            </>
                        </Expandable>
                        <div className='w-full'>
                            <hr/>
                        </div>
                        <Expandable
                            className='px-6 py-[18px]'
                            expandedClassName=" text-greyblack "
                            expand={nodeEditSection === NODE_EDIT_SECTION_TYPE.ACTION}
                            setExpand={(expand) => setNodeEditSection(expand ? NODE_EDIT_SECTION_TYPE.ACTION : "")}
                            right
                        >
                            <span className='text-base'>ACTIONS</span>
                            <>
                                <div className='px-6 pt-[18px]'/>
                                {NODE_ACTION_FIELD_TYPE.map((field) => (
                                    <CusEditableLeftLabel
                                        key={field.fieldName}
                                        readOnly={(!isEdit || selectedFlow.type === "RELEASED") || (!!selectedNode.id ? !field.editable : !field.creatable)}
                                        fieldType={field.fieldType}
                                        label={field.displayNameEn}
                                        value={editableNode[field.fieldName] ?? field.defaultValue}
                                        options={getOptions(field.fieldType, field.fieldName, field.options)}
                                        errors={errorMap}
                                        required={field.required}
                                        errorName={field.fieldName}
                                        onChange={(e) => onChangeHandler(selectedNode.isCreate, field, e)}
                                    />
                                ))}
                                {editableNode.afterAction === ACTION_TYPE.RUN_ACTION ?
                                    <Expandable
                                        className='py-[18px]'
                                        expand={showNodeExternalActionList}
                                        setExpand={(expand) => sestShowNodeExternalActionList(expand)}
                                        right
                                    >
                                        <span className='text-base'>Actions</span>
                                        <>
                                            <div className='px-6 pt-[18px]'/>
                                            <div className='px-4 w-full flex flex-col border-[#CBD5E1] border-[1px] rounded-[8px]'>
                                                {nodeExternalActionList.map((action, index) => (
                                                    <ExternalActionListItem
                                                        key={`ExternalAction-${index}`}
                                                        isCreate={selectedNode.isCreate}
                                                        readOnly={(!isEdit || selectedFlow.type === "RELEASED")}
                                                        itemIndex={index}
                                                        actionList={actionList}
                                                        actionListItem={action}
                                                        error={nodeExternalActionErrorList[index]}
                                                        updateExternalAction={(field, error) => updateExternalAction(field, error, index)}
                                                        removeExternalAction={() => removeExternalAction(index)}
                                                        openEditSettingAction={openEditSettingAction}
                                                        getFlowSettingById={getFlowSettingById}
                                                    />
                                                ))}
                                                {(!isEdit || selectedFlow.type === "RELEASED") ?
                                                    null
                                                    :
                                                    <div className="flex w-full py-3">
                                                        <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex text-base flex-1 h-[40px] items-center rounded-[5px]'
                                                            color={ButtonColor.darkNoBorder}
                                                            onClick={() => addExternalAction()}
                                                        >
                                                            <Plus/>
                                                            <span className='pl-2 text-base'>{intl.formatMessage({ "id": `${settingPrefix}.${editNodePrefix}.button.addAction` })}</span>
                                                        </CusRoundedButton>
                                                    </div>
                                                }

                                            </div>
                                        </>
                                    </Expandable>
                                :
                                    null                 
                                }
                                {(editableNode.afterAction === ACTION_TYPE.JUMP_NODE || editableNode.afterAction === ACTION_TYPE.RUN_ACTION) ?
                                    EXTRA_NODE_ACTION_FIELD_TYPE.map((field) => (
                                        <CusEditableLeftLabel
                                            key={field.fieldName}
                                            readOnly={(!isEdit || selectedFlow.type === "RELEASED") || (!!selectedNode.id ? !field.editable : !field.creatable)}
                                            fieldType={field.fieldType}
                                            label={field.displayNameEn}
                                            labelSize={90}
                                            value={editableNode[field.fieldName] ?? field.defaultValue}
                                            options={getOptions(field.fieldType, field.fieldName, field.options)}
                                            errors={errorMap}
                                            required={editableNode.afterAction === ACTION_TYPE.JUMP_NODE}
                                            errorName={field.fieldName}
                                            onChange={(e) => onChangeHandler(selectedNode.isCreate, field, e)}
                                        />
                                    ))
                                    :
                                    null                 
                                }
                            </>
                        </Expandable>
                    </Scrollbars>
                </div>
                {isEdit ?
                    <div className="p-4 w-full flex justify-center items-center h-[72px] border-t-[1px] border-[#CBD5E140]">
                        <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex justify-center text-base flex-1 px-4 h-[40px] items-center rounded-[5px]' color={ButtonColor.primary}
                            onClick={() => validateFields(selectedNode.isCreate)}
                        >
                            <span className="px-4">{selectedNode.isCreate ? "Create" : "Update"}</span>
                        </CusRoundedButton>
                        {/* <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex text-xs flex-shrink-0 ml-4 px-12 h-[40px] items-center rounded-[5px]' color={ButtonColor.primaryFrame}
                            onClick={selectedNode.isCreate ? () => cancelEditAction() : () => refresh()}
                        >
                            <span className="px-4">{intl.formatMessage({ "id": `${generalPrefix}.button.cancel`})}</span>
                        </CusRoundedButton> */}
                    </div>
                :
                    <div className="p-4 w-full flex justify-between items-center h-[72px] border-t-[1px] border-[#CBD5E140]">
                        <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex justify-center text-base flex-shrink-0 px-4 h-[40px] items-center rounded-[5px]' color={ButtonColor.darkNoBorder}
                            onClick={() => removeNodeAction()}
                        >
                            <span className="">Remove</span>
                        </CusRoundedButton>
                        <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex justify-center text-base flex-shrink-0 px-4 h-[40px] items-center rounded-[5px]' color={ButtonColor.primary}
                            onClick={() => setIsEdit(true)}
                        >
                            <span className="">Edit</span>
                        </CusRoundedButton>
                        {/* <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex text-xs flex-shrink-0 ml-4 px-8 h-[40px] items-center rounded-[5px]' color={ButtonColor.primaryFrame}
                            onClick={() => cancelEditAction()}
                        >
                            <span className="">{intl.formatMessage({ "id": `${generalPrefix}.button.cancel`})}</span>
                        </CusRoundedButton> */}

                    </div>
                }
            </div>
            <ResponsePreviewItem
                isEditable
                response={selectedResponse}
                showMenu={showResponseMsgMenu}
                setShowMenu={setShowResponseMsgMenu}
                menuPos={responseMsgMenuPos}
                setMenuPos={setResponseMsgMenuPos}
                selectAction={(response) => openEditSettingAction(response, SETTING_SUB_MENU_TYPE.RESPONSE)}
                closeAction={closeResponseMsg}
            />
        </>
    )
}

NodeEditPanel.propTypes = {
    isEdit: PropTypes.bool,
    setIsEdit: PropTypes.func,
    nodeEditSection: PropTypes.string,
    setNodeEditSection: PropTypes.func,
    selectedFlow: PropTypes.object,
    selectedNode: PropTypes.object,
    nodeList: PropTypes.arrayOf(PropTypes.object),
    keywordList: PropTypes.arrayOf(PropTypes.object),
    entityList: PropTypes.arrayOf(PropTypes.object),
    responseList: PropTypes.arrayOf(PropTypes.object),
    actionList: PropTypes.arrayOf(PropTypes.object),
    parameterList: PropTypes.arrayOf(PropTypes.object),
    updateNodeAction: PropTypes.func,
    removeNodeAction: PropTypes.func,
    cancelEditAction: PropTypes.func,
    openEditSettingAction: PropTypes.func,
    getFlowSettingById: PropTypes.func,
};

export default NodeEditPanel;

