const List = (rest) => {
    return(
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M26.8688 16.9694L6.81797 25.4228C6.38508 25.5959 5.97387 25.5587 5.58432 25.3111C5.19477 25.0633 5 24.7037 5 24.2323V7.2974C5 6.82597 5.19477 6.46635 5.58432 6.21854C5.97387 5.97097 6.38508 5.93374 6.81797 6.10685L26.8688 14.5603C27.4029 14.7961 27.67 15.1976 27.67 15.7648C27.67 16.332 27.4029 16.7336 26.8688 16.9694ZM7.15484 22.9476L24.1781 15.7648L7.15484 8.58204V13.8862L14.9453 15.7648L7.15484 17.6435V22.9476Z" fill="#64748B"/>
            <path d="M26.8688 16.9694L6.81797 25.4228C6.38508 25.5959 5.97387 25.5587 5.58432 25.3111C5.19477 25.0633 5 24.7037 5 24.2323V7.2974C5 6.82597 5.19477 6.46635 5.58432 6.21854C5.97387 5.97097 6.38508 5.93374 6.81797 6.10685L26.8688 14.5603C27.4029 14.7961 27.67 15.1976 27.67 15.7648C27.67 16.332 27.4029 16.7336 26.8688 16.9694ZM7.15484 22.9476L24.1781 15.7648L7.15484 8.58204V13.8862L14.9453 15.7648L7.15484 17.6435V22.9476Z" fill="black" fillOpacity="0.2"/>
            <path d="M26.8688 16.9694L6.81797 25.4228C6.38508 25.5959 5.97387 25.5587 5.58432 25.3111C5.19477 25.0633 5 24.7037 5 24.2323V7.2974C5 6.82597 5.19477 6.46635 5.58432 6.21854C5.97387 5.97097 6.38508 5.93374 6.81797 6.10685L26.8688 14.5603C27.4029 14.7961 27.67 15.1976 27.67 15.7648C27.67 16.332 27.4029 16.7336 26.8688 16.9694ZM7.15484 22.9476L24.1781 15.7648L7.15484 8.58204V13.8862L14.9453 15.7648L7.15484 17.6435V22.9476Z" fill="black" fillOpacity="0.2"/>
            <path d="M26.8688 16.9694L6.81797 25.4228C6.38508 25.5959 5.97387 25.5587 5.58432 25.3111C5.19477 25.0633 5 24.7037 5 24.2323V7.2974C5 6.82597 5.19477 6.46635 5.58432 6.21854C5.97387 5.97097 6.38508 5.93374 6.81797 6.10685L26.8688 14.5603C27.4029 14.7961 27.67 15.1976 27.67 15.7648C27.67 16.332 27.4029 16.7336 26.8688 16.9694ZM7.15484 22.9476L24.1781 15.7648L7.15484 8.58204V13.8862L14.9453 15.7648L7.15484 17.6435V22.9476Z" fill="black" fillOpacity="0.2"/>
        </svg>
    )
}

export default List;