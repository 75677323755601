import PropTypes from 'prop-types';
import { MESSAGE_TYPE, onDownloadMedia } from 'util/helper';
import MediaDownloadable from '../MediaAction/MediaDownloadable';
import { MESSAGE_CONTAINER_TYPE } from "components/ChatMessage/util";
import { useEffect, useState } from 'react';
import TextMessage from './TextMessage';

const FileMessage = ({
    downloading = false,
    messageId = "",
    message = "",
    messageMedia = null,
    mimeType = "",
    disabled = false,
    onClick = null,
    onContextMenu = null,
    containerType = MESSAGE_CONTAINER_TYPE.AGENT,
    keyword = "",
    multiLines = true,
}) => {

    const [requestDownload, setRequestDownload] = useState(false)
    const [localFileAva, setLocalFileAva] = useState(false);

    useEffect(() => {
        if(messageMedia && messageMedia.success === 1){
            if(messageMedia.local && !localFileAva){
                setLocalFileAva(true)
                return
            }

            if(requestDownload)
                onDownloadMedia(messageMedia.mimeType, messageMedia.base64, message)
        }
    }, [messageMedia])

    const downloadMedia = () => {
        onDownloadMedia(messageMedia.mimeType, messageMedia.base64, message)
    }

    const tryDownloadMedia = () => {
        setRequestDownload(true)

        if(messageMedia && messageMedia.success === 1){
            downloadMedia();
        }
        else
            onClick()
    }

    return (
        <div className="flex flex-col">
            <MediaDownloadable
                // loading={false}
                loading={!disabled && downloading}
                disabled={disabled}
                messageId={messageId}
                messageType={MESSAGE_TYPE.FILE}
                mimeType={mimeType}
                onDownload={tryDownloadMedia}
                onContextMenu={onContextMenu}
                containerType={containerType}
            />
            {message ?
                <TextMessage message={message} keyword={keyword} multiLines={multiLines}/>
                :
                null
            }
        </div>        
    )
}

FileMessage.propTypes = {
    downloading: PropTypes.bool,
    messageId: PropTypes.string,
    message: PropTypes.string,
    mimeType: PropTypes.string,
    disabled: PropTypes.bool,
    
    onClick: PropTypes.func,
    onContextMenu: PropTypes.func,
    containerType: PropTypes.string,

    keyword: PropTypes.string,
    multiLines: PropTypes.bool    
};

export default FileMessage;