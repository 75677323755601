import PropTypes from 'prop-types';

import { EDITABLE_TYPE, setDefaultField, validateField } from 'components/CusEditable';
import CusEditableLeftLabel from 'components/CusEditable/CusEditableLeftLabel';
import NonExpanableListItem from 'components/NonExpanableListItem';
import { useLanguage } from 'hooks/useLanguage';
import { capitalise, checkListItemDuplication } from 'util/helper';
import Info2 from 'components/Icon/Info2';
import { useIntl } from 'react-intl';
import { editActionPrefix, settingPrefix } from 'lang/locales/prefix';

const CONDITION_RESPONSE_TYPE_NAME_LIST = Object.freeze([
    "RECORD_NOT_FOUND", "INSERT_FAILED", "UPDATE_FAILED", "UPLOAD_FAILED"
])

export const ACTION_CONDITION_RESPONSE_FIELD_TEMPLATE = Object.freeze({
    "condition" : "",
    "value" : "",
})

export const CONDITION_RESPONSE_FIELD_TYPE = [
    {
        "fieldName": "value",
        "displayNameKey": `${settingPrefix}.${editActionPrefix}.field.response.additional.value`,
        "displayNameEn": "Response",
        "displayNameTc": "響應",
        "displayNameSc": "响应",
        "fieldType": EDITABLE_TYPE.PICKLIST,
        "options": [],
        "required": true,
        "editable": true,
        "creatable": true,
    },
    {
        "fieldName": "condition",
        "displayNameKey": `${settingPrefix}.${editActionPrefix}.field.response.additional.condition`,
        "displayNameEn": "Condition",
        "displayNameTc": "條件",
        "displayNameSc": "条件",
        "fieldType": EDITABLE_TYPE.PICKLIST,
        "options": CONDITION_RESPONSE_TYPE_NAME_LIST,
        "required": true,
        "editable": true,
        "creatable": true,
    },
]

export const validateConditionResponseListItems = (errorFormater, isCreate, fieldList) => {
    let errorList = [];

    for(let index = 0; index < fieldList.length; index++){
        let fieldListItem = {...fieldList[index]}
        let errorMap = {}

        let mapping = [...CONDITION_RESPONSE_FIELD_TYPE]

        for(let field of mapping){
            if(!fieldListItem[field.fieldName])
                setDefaultField(field, fieldListItem, field.fieldName, isCreate)
            errorMap = {...errorMap, ...validateField(field, fieldListItem[field.fieldName], errorFormater, isCreate)}
        }

        errorList[index] = errorMap
    }

    let fieldSet = fieldList.map(field => field.condition)

    let duplicates = checkListItemDuplication(fieldSet)
    duplicates.forEach(i => {
        if(!errorList[i])
            errorList[i] = {}

        errorList[i].condition = { message: errorFormater({ "id": `${settingPrefix}.${editActionPrefix}.duplicates.additional` }) }
    })

    return errorList
}


const ActionConditionResponseListItem = ({
    isCreate = false,
    isEdit = false,
    isEditable = false,
    itemIndex = -1,
    fieldListItem = null,
    responseList = [],
    error = null,
    updateField = null,
    removeField = null,
    openResponseMsg = null,
}) => {

    const intl = useIntl();
    const { language } = useLanguage()

    const CONDITION_RESPONSE_TYPE_NAME = Object.freeze({
        "RECORD_NOT_FOUND": `${settingPrefix}.${editActionPrefix}.nameList.additional.condition.recordNotFound`,
        "INSERT_FAILED": `${settingPrefix}.${editActionPrefix}.nameList.additional.condition.insertFailed`,
        "UPDATE_FAILED": `${settingPrefix}.${editActionPrefix}.nameList.additional.condition.updateFailed`,
        "UPLOAD_FAILED": `${settingPrefix}.${editActionPrefix}.nameList.additional.condition.uploadFailed`,
    })
    
    const getOptions = (fieldType, fieldName, options) => {
        if(fieldType !== EDITABLE_TYPE.PICKLIST)
            return []

        if(fieldName === "condition")
            return options.map(option => ({label: option ? intl.formatMessage({ "id": CONDITION_RESPONSE_TYPE_NAME[option] }) : "", value: option}))

        if(fieldName === "value")
            return responseList.map(response => ({label: response.name, value: response.id}))

        return options.map(option => ({label: option, value: option}))
    }

    const onChangeHandler = (isCreate, field, e) => {
        
        let value = e.target.value;
        let currentErrorMap = {...error};
        let errorMap = {};

        switch (field.fieldType) {
            case EDITABLE_TYPE.BOOLEAN:
            case EDITABLE_TYPE.BOOLEAN_CHECKBOX:
                value = !fieldListItem[field.fieldName]
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;        
            default:
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;
        }

        if(errorMap && Object.keys(errorMap).length > 0)
            currentErrorMap = {...currentErrorMap, ...errorMap}
        else
            delete currentErrorMap[field.fieldName]

        console.log(currentErrorMap)

        updateField({...fieldListItem, [field.fieldName]: value}, currentErrorMap)
    }

    return(
        <NonExpanableListItem
            title={`${intl.formatMessage({ "id": `${settingPrefix}.${editActionPrefix}.expanable.text.additional.response`})}#${itemIndex + 1}`}
            remove={(!isEdit || !isEditable) ? null : removeField}
            readOnly={(!isEdit || !isEditable)}

        >
            {CONDITION_RESPONSE_FIELD_TYPE.map((field) => (
                field.fieldName === "value" ?                    
                    <div
                        key={field.fieldName}
                        className="py-2 flex"
                    >
                        <CusEditableLeftLabel
                            readOnly={(!isEdit || !isEditable) || (!isCreate ? !field.editable : !field.creatable)}
                            fieldType={field.fieldType}
                            showLabelOnLeft={false}
                            label={field.displayNameEn}
                            options={getOptions(field.fieldType, field.fieldName, field.options)}
                            errors={error}
                            required={field.required}
                            errorName={field.fieldName}
                            onChange={(e) => onChangeHandler(isCreate, field, e)}
                        />
                        <div className={`p-2 text-[#94A3B8] responseMsg-pos`}>
                            <div className={`${(fieldListItem[field.fieldName] ?? field.defaultValue) ? "cursor-pointer" : "opacity-50" }`} onClick={(fieldListItem[field.fieldName] ?? field.defaultValue) ? (e) => openResponseMsg(e, fieldListItem[field.fieldName] ?? field.defaultValue) : (e) => {} }>
                                <span className="text-[13px]">PREVIEW</span>
                            </div>
                        </div>
                    </div>
                :
                    <div key={field.fieldName} className='py-2'>
                        <CusEditableLeftLabel
                            readOnly={(!isEdit || !isEditable) || (!isCreate ? !field.editable : !field.creatable)}
                            fieldType={field.fieldType}
                            showLabelOnLeft={false}
                            label={field.displayNameEn}
                            value={fieldListItem[field.fieldName] ?? field.defaultValue}
                            options={getOptions(field.fieldType, field.fieldName, field.options)}
                            errors={error}
                            required={field.required}
                            errorName={field.fieldName}
                            onChange={(e) => onChangeHandler(isCreate, field, e)}
                        />
                    </div>
            ))}
        </NonExpanableListItem>
    )
}

ActionConditionResponseListItem.propTypes = {
    isCreate: PropTypes.bool,
    isEdit: PropTypes.bool,
    isEditable: PropTypes.bool,
    itemIndex: PropTypes.number,
    fieldListItem: PropTypes.object,
    responseList: PropTypes.arrayOf(PropTypes.object),
    error: PropTypes.object,
    updateField: PropTypes.func,
    removeField: PropTypes.func,
    openResponseMsg: PropTypes.func,
};

export default ActionConditionResponseListItem;
