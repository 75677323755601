
const AppConfig = {
    backend_url: 'https://playground.api.recur-omni.io',
    locale: {
      languageId: 'english',
      locale: 'en',
      value: 'en',
      name: 'English',
      label: 'English',
      icon: 'en',
      short: "EN",
      mapping: "En",
   },
   
};

export default AppConfig;