

import CheckBoxTick from "components/Icon/CheckBoxTick";
import CheckBox from "components/Icon/NewChatbotIcon/CheckBox";
import PropTypes from "prop-types";
import React from 'react';

const TextSize = Object.freeze(
    {
        "default": " text-sm",
        "flat": " "
    }
)

const LabelContentPaddingType = Object.freeze(
    {
        "noPadding": " ",
        "normalPadding": " py-1 h-[40px] pl-3 pr-3 ",
        "flat": " py-1 h-[48px] px-8 ",
    }
)

const PaddingType = Object.freeze(
    {
        "noPadding": " ",
        "normalPadding": " w-[16px] h-[16px] ",
        "flat": " w-[16px] h-[16px] ",
    }
)

const LabelPaddingType = Object.freeze(
    {
        "noPadding": " ",
        "normalPadding": " pt-1 pl-3 pr-3 ",
        "flat": " pt-1 px-8 ",
    }
)

const CusCheckBox = React.forwardRef(({
    checked = false,
    disabled = false,
    readOnly = false,
    onChange = ()=>{},
    className = "",
    label = "",
    paddingType = PaddingType.normalPadding,
    labelPaddingType = "",
    labelContentPaddingType = LabelContentPaddingType.normalPadding,
    textSize = "",
    ...rest
}, ref) => {
    console.log(textSize)
    return(
        <div className={`
            ${label ? `flex flex-col rounded-md outline-none 
                ${disabled ? "" : "pointer-events-none"}`
            : "flex items-center"}           
            ${className}`}
        >
            {label ?
                <span
                    className={
                        "text-sm text-grey06"
                        + (labelPaddingType? labelPaddingType: LabelPaddingType.normalPadding)
                    }                     
                >
                    {label}
                </span>
                :
                null
            }
            <div className={"flex items-center" + (label ? (labelContentPaddingType? labelContentPaddingType: LabelContentPaddingType.normalPadding) : "")}>
                {/* {disabled ?
                        <div 
                            className={"w-full truncate rounded-md outline-none text-greyblack placeholder:text-greyblack placeholder:text-sm"
                                + (textSize? textSize: TextSize.default)
                                + (label ? "  disabled:bg-transparent" : "disabled:bg-disabled disabled:text-black disabled:opacity-100 ")
                            } 
                            {...rest}
                        >
                            {checked ? "True" : "False"}
                        </div>
                    :
                } */}
                <div className="relative w-full flex items-center">
                    <input 
                        ref={ref}
                        className={"appearance-none border-none pointer-events-none"
                            + (textSize? textSize: TextSize.default)
                            + (paddingType? paddingType: PaddingType.normalPadding)
                        } 
                        checked={checked}
                        type="checkbox"
                        readOnly={readOnly}
                        disabled={disabled || readOnly}
                        onChange={readOnly || disabled ? null : onChange}
                        {...rest}
                    />
                    <div className={`absolute top-0 left-0 flex items-center pointer-events-none justify-center ${!!checked ? "text-blue04" : "text-[#94A3B8]"} ${paddingType? paddingType: PaddingType.normalPadding} ${(readOnly || disabled) ? "opacity-50" : ""}`}>
                        <CheckBox className={`${paddingType? paddingType: PaddingType.normalPadding}`}/>
                    </div>
                </div>
            </div>
        </div>

    )
});

CusCheckBox.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  paddingType: PropTypes.string,
  textSize: PropTypes.string,
  labelContentPaddingType: PropTypes.string,

}

export {
    CusCheckBox,
    PaddingType,
    LabelPaddingType,
    LabelContentPaddingType
};