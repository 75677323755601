import { errorPrefix } from "../prefix";

export const errors = {
    [`${errorPrefix}.defaulterror.default`]: "Unexpected Error. Please try again later",
    [`${errorPrefix}.10001`]: "Username and password does not match the policy",
    [`${errorPrefix}.10002`]: "Invalid username and password",
    [`${errorPrefix}.10003`]: "User is not confirmed",
    [`${errorPrefix}.10004`]: "Username already exist",
    [`${errorPrefix}.10005`]: "Confirmation code is expired",
    [`${errorPrefix}.10006`]: "Confirmation code is not matched",
    [`${errorPrefix}.10007`]: "Member is not activated",
    [`${errorPrefix}.10008`]: "User does not exist",
    [`${errorPrefix}.10009`]: "New Password Required",
    [`${errorPrefix}.10010`]: "Session not found",
    [`${errorPrefix}.10011`]: "User is disabled. Please contact admin",
    [`${errorPrefix}.10012`]: "Password attempts exceeded. Please try again later",
    [`${errorPrefix}.10013`]: "User Expired. Please change password",
 
    [`${errorPrefix}.permission`]: "您没有访问权限",
    [`${errorPrefix}.getUserSession`]: "获取用户会话失败",
    [`${errorPrefix}.updateUser`]: "更新用户失败",
    [`${errorPrefix}.updateUserSession`]: "更新用户会话失败",
    [`${errorPrefix}.updatePassword`]: "更新密码失败",
    [`${errorPrefix}.updateUserImage`]: "更新用户图像失败",
        
    [`${errorPrefix}.required`]: "是必须的",
    [`${errorPrefix}.invalid`]: "无效的",
    [`${errorPrefix}.passwordMismatch`]: "您的密码不匹配",

    [`${errorPrefix}.requiredField`]: "必填字段",
    [`${errorPrefix}.requiredOption`]: "至少应选择一个选项",
    [`${errorPrefix}.invalidOption`]: "请选择一个有效的选项",
    [`${errorPrefix}.invalidNumber`]: "字段不是数字",
    [`${errorPrefix}.maxNumber`]: "最大值：",
    [`${errorPrefix}.minNumber`]: "最小值：",
    [`${errorPrefix}.maxNumberChars`]: "最大整数字符数：",
    [`${errorPrefix}.maxDecimalPlace`]: "最大小数位数：",
    [`${errorPrefix}.invalidRegex`]: "正则表达式条件不匹配",
    [`${errorPrefix}.invalidRegex.safeRegex`]: "字段只能包含字母数字、空格或下划线",
    [`${errorPrefix}.maxChars`]: "最大字符数：",
    [`${errorPrefix}.invalidDateRange`]: "日期超出范围",
    [`${errorPrefix}.invalidDateTimeRange`]: "日期和时间超出范围",
    [`${errorPrefix}.duplicates`]: "请删除重复项",

    [`${errorPrefix}.invalid.file`]: "无效文件",
    [`${errorPrefix}.invalid.file.size`]: "文件大小超出支持限制",
    [`${errorPrefix}.invalid.image`]: "无效的图像格式",

    [`${errorPrefix}.no.image`]: "找不到图片",
 }