import PropTypes from 'prop-types';

import DefaultAvatar from 'components/Avatar';

const ContactMessage = ({
    messageId = "",
    message = "",
    onClick = null,
    onContextMenu = null,
}) => (
    <div className={`relative flex flex-col min-w-0 break-words rounded box-border bg-clip-border w-full h-full cursor-pointer`} onClick={() => onClick(messageId)} onContextMenu={() => onContextMenu(messageId)}>
        <div className={`flex items-center flex-1 min-h-[1px] p-5`}>
            <div className={`relative w-12 h-12 flex flex-shrink-0 justify-center items-center`}>
                <div className="w-11 h-11">
                    <DefaultAvatar name={JSON.parse(message).name.substring(0, 2)}/>
                </div>
            </div>
            <div className="ml-2">
                <b>{JSON.parse(message).name}</b>
                <div>{JSON.parse(message).phone}</div>
            </div>                
        </div>
    </div>
)


ContactMessage.propTypes = {
    messageId: PropTypes.string,
    message: PropTypes.string,
    onClick: PropTypes.func,
    onContextMenu: PropTypes.func,
};

export default ContactMessage;