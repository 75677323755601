import PropTypes from 'prop-types';

import React from 'react';

import "./index.css"

const Overlay = ({
    show = false,
    full = false,
    children = null,
    className = "",
    ...rest}) => {

    return (
        <div tabIndex={0} className={`${full ? "fixed" : "absolute"} w-full h-full top-0 left-0 loading-overlay ${show ? "show-overlay " : ""} ${className}`} {...rest}>
            {children}
        </div>
    )
}

Overlay.propTypes = {
    show: PropTypes.bool,
    full: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
};

export default Overlay;