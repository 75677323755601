import CusEditable, { EDITABLE_TYPE, setDefaultField, validateField } from 'components/CusEditable';
import CusModal from 'components/CusModal';
import { Color as ButtonColor, CusRoundedButton, PaddingType as ButtonPadding, RoundType as ButtonRoundType } from 'components/CusRoundedButton';
import ExpanableListItem from 'components/ExpanableListItem';
import Bin from 'components/Icon/Bin';
import Table from 'components/Table';
import { useLanguage } from 'hooks/useLanguage';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { capitalise, checkListItemDuplication, matchRegex } from 'util/helper';
import EnityOptionListItem, { ENTITY_OPTION_FIELD_TEMPLATE, validateOptionListItems } from './FlowSettingList/EnityOptionListItem';
import EnityRegexListItem, { ENTITY_REGEX_FIELD_TEMPLATE, validateRegexListItems } from './FlowSettingList/EnityRegexListItem';
import CusRoundedSearch from 'components/CusRoundedInput/CusRoundedSearch';
import Cancel from 'components/Icon/Cancel';
import { addFieldItemAction, removeFieldItemAction, updateFieldItemAction } from 'util/helper';
import { useIntl } from 'react-intl';
import { generalPrefix, settingPrefix, editChatbotPrefix, editEntityPrefix } from 'lang/locales/prefix';
import Plus from 'components/Icon/Plus';
import Scrollbars from 'react-custom-scrollbars-2';
import CusEditableLeftLabel from 'components/CusEditable/CusEditableLeftLabel';

const ENTITY_TYPE = Object.freeze({
    "REGEX": "REGEX",
    "LIST": "LIST",
}) 

export const ENTITY_TYPE_NAME_LIST = Object.freeze([
    "REGEX"
])

const ENTITY_SETUP_FIELD_TYPE = [
    {
        "fieldName": "name",
        "displayNameKey": `${settingPrefix}.${editEntityPrefix}.field.setup.name`,
        "displayNameEn": "Name",
        "displayNameTc": "實體名稱",
        "displayNameSc": "实体名称",
        "fieldType": EDITABLE_TYPE.TEXT,
        "required": true,
        "editable": true,
        "creatable": true,
    },
    {
        "fieldName": "entityType",
        "displayNameKey": `${settingPrefix}.${editEntityPrefix}.field.setup.entityType`,
        "displayNameEn": "List",
        "displayNameTc": "實體類型",
        "displayNameSc": "实体类型",
        "fieldType": EDITABLE_TYPE.PICKLIST,
        "options": ENTITY_TYPE_NAME_LIST,
        "required": true,
        "editable": true,
        "creatable": true,
    },
]

const HEADER_FIELD_TITLE_LENGTH = 3

const EditEntity = ({
    isEditable = false,
    isEdit = false,
    setIsEdit = () => {},
    item = null,
    updateAction = null,
    removeAction = null
}) => {

    const [errorMap, setErrorMap] = useState({});
    const [editableEntity, setEditableEntity] = useState({})

    const [entityRegexList, setEntityRegexList] = useState([])
    const [entityRegexErrorList, setEntityRegexErrorList] = useState([]);

    const [entityOptionList, setEntityOptionList] = useState([])
    const [entityOptionErrorList, setEntityOptionErrorList] = useState([]);

    const [searchInput, setSearchInput] = useState("");

    const intl = useIntl();
    const { language } = useLanguage()

    const ENTITY_TYPE_NAME = Object.freeze({
        "REGEX": `${settingPrefix}.${editEntityPrefix}.nameList.entityType.regex`
    })     

    const HEADER_FIELD_TITLE_LIST = Array(HEADER_FIELD_TITLE_LENGTH).fill().map((_,index) => intl.formatMessage({ "id": `${settingPrefix}.${editEntityPrefix}.table.displayName.${index}`}))

    const getOptions = (fieldType, fieldName, options) => {
        if(fieldType !== EDITABLE_TYPE.PICKLIST)
            return []

        if(fieldName === "entityType")
            return options.map(option => ({label: option ? intl.formatMessage({ "id": ENTITY_TYPE_NAME[option] }) : "", value: option}))

        return options.map(option => ({label: option, value: option}))
    }

    const onChangeHandler = (isCreate, field, e) => {
        
        let value = e.target.value;
        let errorMap = {};

        switch (field.fieldType) {
            case EDITABLE_TYPE.BOOLEAN:
                value = !editableEntity[field.fieldName]
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;        
            default:
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;
        }

        if(errorMap && Object.keys(errorMap).length > 0)
            setErrorMap((erMap) => ({...erMap, ...errorMap}))
        else{
            setErrorMap((erMap) => {
                let newErrorMap = {...erMap}
                delete newErrorMap[field.fieldName]
                return newErrorMap 
            })
        }

        if(field.fieldName === "entityType"){
            if(value === ENTITY_TYPE.LIST){
                setEntityOptionList([{...ENTITY_OPTION_FIELD_TEMPLATE}])
                setEntityOptionErrorList([{}])        
            }
            else{
                setEntityRegexList([{...ENTITY_REGEX_FIELD_TEMPLATE}])
                setEntityRegexErrorList([{}])
            }
        }

        setEditableEntity((editableFields) => ({...editableFields, [field.fieldName]: value}))
    }

    const addEntityRegex = () => addFieldItemAction({itemTemplate: ENTITY_REGEX_FIELD_TEMPLATE, setFieldList: setEntityRegexList, setFieldErrorList: setEntityRegexErrorList})
    const updateEntityRegex = (field, error, index) => updateFieldItemAction({field, error, index, fieldList: entityRegexList, fieldErrorList: entityRegexErrorList, setFieldList: setEntityRegexList, setFieldErrorList: setEntityRegexErrorList})
    const removeEntityRegex = (index) => removeFieldItemAction({index, setFieldList: setEntityRegexList, setFieldErrorList: setEntityRegexErrorList})

    const addEntityOption = () => addFieldItemAction({itemTemplate: ENTITY_OPTION_FIELD_TEMPLATE, setFieldList: setEntityOptionList, setFieldErrorList: setEntityOptionErrorList})
    const updateEntityOption = (field, error, index) => updateFieldItemAction({field, error, index, fieldList: entityOptionList, fieldErrorList: entityOptionErrorList, setFieldList: setEntityOptionList, setFieldErrorList: setEntityOptionErrorList})
    const removeEntityOption = (index) => removeFieldItemAction({index, setFieldList: setEntityOptionList, setFieldErrorList: setEntityOptionErrorList})

    const validateFields = (isCreate) => {
        let errorMap = {}

        let mapping = [ ...ENTITY_SETUP_FIELD_TYPE ]

        for(let field of mapping){
            if(!editableEntity[field.fieldName])
                setDefaultField(field, editableEntity, field.fieldName, isCreate)
            errorMap = {...errorMap, ...validateField(field, editableEntity[field.fieldName], intl.formatMessage, isCreate)}
        }

        let entityErrorList = []

        if(editableEntity.entityType === ENTITY_TYPE.LIST)
            entityErrorList = validateOptionListItems(intl.formatMessage, isCreate, entityOptionList)
        else
            entityErrorList = validateRegexListItems(intl.formatMessage, isCreate, entityRegexList)

        let hasError = false;

        if(errorMap && Object.keys(errorMap).length > 0)
            hasError = true;

        console.log(entityErrorList)
        
        if(editableEntity.entityType === ENTITY_TYPE.LIST){
            for(let error of entityErrorList){
                const {list, ...rest} = error

                console.log(Object.keys(rest), Object.keys(list))

                if(Object.keys(rest).length > 0){
                    hasError = true;
                    break;
                }

                if(list.filter(error => Object.keys(error).length > 0).length > 0){
                    hasError = true;
                    break;
                }
            }
        }
        else{
            if(entityErrorList.filter(error => Object.keys(error).length > 0).length > 0)
                hasError = true;
        }

        if(hasError){
            console.log(errorMap)

            if(editableEntity.entityType === ENTITY_TYPE.LIST)
                setEntityOptionErrorList(entityErrorList)
            else
                setEntityRegexErrorList(entityErrorList)

            setErrorMap(errorMap)
            return;
        }

        let completeObject = {...editableEntity}
        let entityNameRegexMap = {}
        let entityNameOptionListMap = {}

        if(editableEntity.entityType === ENTITY_TYPE.LIST)
            entityNameOptionListMap = entityOptionList.reduce((map, entity) => ({...map, [entity.name]: entity.list}), {})
        else
            entityNameRegexMap = entityRegexList.reduce((map, entity) => ({...map, [entity.name]: entity.value}), {})

        updateAction({...completeObject, entityNameRegexMap, entityNameOptionListMap})
    }

    const refresh = () => {
        let editableEntity = JSON.parse(JSON.stringify(item))

        console.log(editableEntity)

        let entityRegexList = [];
        let entityOptionList = [];

        if(!editableEntity.entityType)
            editableEntity.entityType = ENTITY_TYPE.REGEX

        if(editableEntity.entityType === ENTITY_TYPE.LIST){
            if(editableEntity.entityNameOptionListMap && Object.keys(editableEntity.entityNameOptionListMap).length > 0)
                Object.entries(editableEntity.entityNameOptionListMap).forEach(([name, list]) => entityOptionList.push({name, list}))
            else
                entityOptionList.push({...ENTITY_OPTION_FIELD_TEMPLATE})
        }
        else{
            if(editableEntity.entityNameRegexMap && Object.keys(editableEntity.entityNameRegexMap).length > 0)
                Object.entries(editableEntity.entityNameRegexMap).forEach(([name, value]) => entityRegexList.push({name, value}))
            else
                entityRegexList.push({...ENTITY_REGEX_FIELD_TEMPLATE})
        }
        
        setEntityRegexList(entityRegexList)
        setEntityRegexErrorList(Array(entityRegexList.length).fill({}))

        setEntityOptionList(entityOptionList)
        setEntityOptionErrorList(Array(entityOptionList.length).fill({}))

        setEditableEntity(editableEntity)
        setErrorMap({})

        setIsEdit(isEditable && !item.id)
    }

    useEffect(() => {
        setSearchInput("")
    }, [entityRegexList])

    useEffect(() => {
        refresh()
    }, [item])

    let validEntityRegexList = entityRegexList.filter(regex => (!!regex.name && !!regex.value));

    return (
        <div className="w-full h-full">
            <div className='w-full'><hr/></div>
            <div className={`w-full ${isEditable ? "h-[calc(100%-72px)]" : "h-full"}`}>
                <Scrollbars
                    className="w-full h-full"
                    autoHide={false}
                    autoHideDuration={100}
                >
                    <div className='w-full h-full flex flex-col'>
                        <span className='px-4 pt-[18px] text-[#64748B]'>DETAILS</span>
                        <div className='px-4'>
                            {ENTITY_SETUP_FIELD_TYPE.map((field) => (
                                <div key={field.fieldName} className='py-2'>
                                    <CusEditableLeftLabel
                                        readOnly={!isEdit || !isEditable || (!!item.id ? !field.editable : !field.creatable)}
                                        fieldType={field.fieldType}
                                        label={field.displayNameEn}
                                        value={editableEntity[field.fieldName] ?? field.defaultValue}
                                        options={getOptions(field.fieldType, field.fieldName, field.options)}
                                        errors={errorMap}
                                        required={field.required}
                                        errorName={field.fieldName}
                                        onChange={(e) => onChangeHandler(item.isCreate, field, e)}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className='w-full'><hr/></div>
                        <span className='px-4 pt-[18px] text-[#64748B]'>SETUP</span>
                        <div className='p-4'>
                            {isEditable && isEdit && editableEntity.entityType === ENTITY_TYPE.REGEX &&
                                <div className="flex flex-col">
                                    <div className="flex w-full items-center">
                                        <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex text-base h-[40px] items-center'
                                            color={ButtonColor.primaryNoBorder}
                                            onClick={() => addEntityRegex()}
                                        >
                                            <Plus/>
                                            <span className='pl-2 text-base'>{intl.formatMessage({ "id": `${settingPrefix}.${editEntityPrefix}.button.addRegex` })}</span>
                                        </CusRoundedButton>
                                    </div>
                                </div>
                            }
                            {isEditable && isEdit && editableEntity.entityType === ENTITY_TYPE.LIST &&
                                <div className="flex flex-col">
                                    <div className="flex w-full items-center">
                                        <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex text-base h-[40px] items-center'
                                            color={ButtonColor.primaryNoBorder}
                                            onClick={() => addEntityOption()}
                                        >
                                            <Plus/>
                                            <span className='pl-2 text-base'>{intl.formatMessage({ "id": `${settingPrefix}.${editEntityPrefix}.button.addOptionList` })}</span>
                                        </CusRoundedButton>
                                    </div>
                                </div>
                            }
                            {((editableEntity.entityType === ENTITY_TYPE.REGEX && entityRegexList) || (editableEntity.entityType === ENTITY_TYPE.LIST && entityOptionList)) &&
                                <div className='px-4 w-full flex flex-col border-[#CBD5E1] border-[1px] rounded-[8px]'>
                                    {editableEntity.entityType === ENTITY_TYPE.REGEX && entityRegexList.length > 0 &&
                                        entityRegexList.map((field, index) => (
                                            <EnityRegexListItem
                                                key={`${ENTITY_TYPE.REGEX}-${index}`}
                                                isCreate={!item.id}
                                                isEdit={isEdit}
                                                isEditable={isEditable}
                                                itemIndex={index}
                                                fieldListItem={field}
                                                error={entityRegexErrorList[index]}
                                                updateField={(field, error) => updateEntityRegex(field, error, index)}
                                                removeField={() => removeEntityRegex(index)}
                                            />
                                        ))
                                    }
                                    {editableEntity.entityType === ENTITY_TYPE.LIST && entityOptionList.length > 0 &&
                                        entityOptionList.map((field, index) => (
                                            <EnityOptionListItem
                                                key={`${ENTITY_TYPE.LIST}-${index}`}
                                                isCreate={!item.id}
                                                isEdit={isEdit}
                                                isEditable={isEditable}
                                                itemIndex={index}
                                                fieldListItem={field}
                                                error={entityOptionErrorList[index]}
                                                updateField={(field, error) => updateEntityOption(field, error, index)}
                                                removeField={() => removeEntityOption(index)}
                                            />
                                        ))
                                    }
                                </div>
                            }
                        </div>
                        {editableEntity.entityType === ENTITY_TYPE.REGEX &&
                            <>
                                <div className='w-full'><hr/></div>
                                <span className='px-4 pt-[18px] text-[#64748B]'>TEST</span>
                                <div className='px-4 pb-4'>
                                    <Table
                                        className="h-[500px]"
                                        background="bg-transparent"
                                        hideFooter
                                    >
                                        <div className="flex flex-col">
                                        </div>
                                        <div className="flex items-center w-full">
                                            <div className="py-[10px] w-full">
                                                <CusRoundedSearch
                                                    placeholder={intl.formatMessage({ "id": `${settingPrefix}.${editEntityPrefix}.table.placeholder.test` })}
                                                    value={searchInput}
                                                    onChange={(e) => setSearchInput(e.target.value)}
                                                    cancelSearch={() => setSearchInput("")}
                                                />
                                            </div>                                
                                        </div>
                                        <Table.Header itemClassName={'px-[16px]'}>
                                            {HEADER_FIELD_TITLE_LIST.map((name) => 
                                                <Fragment key={`header ${name}`}><Table.TextItem text={name}/></Fragment>
                                            )}
                                        </Table.Header>
                                        <>
                                            {validEntityRegexList.map((regex, regexIndex) => 
                                                <Table.Row
                                                    rowIndex={regexIndex}
                                                    key={`row ${regexIndex}`}
                                                >
                                                    <Table.Item className={'px-[16px]'}><Table.TextItem text={regex.name}/></Table.Item>
                                                    <Table.Item className={'px-[16px]'}><Table.TextItem text={regex.value}/></Table.Item>
                                                    <Table.Item className={'px-[16px]'}><Table.TextItem className={`font-bold ${searchInput.length > 0 ? (!!matchRegex(regex.value, searchInput) ? "text-green02" : " text-red01") : ""}`} text={searchInput.length > 0 ? (matchRegex(regex.value, searchInput) ? "✔" : "✖") : "-"}/></Table.Item>
                                                </Table.Row>
                                            )}
                                        </>
                                    </Table>
                                </div>
                            </>
                        }
                    </div>
                </Scrollbars>
            </div>
            {isEditable && 
                isEdit ?
                    <div className="p-4 w-full flex justify-center items-center h-[72px] border-t-[1px] border-[#CBD5E140]">
                            <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex justify-center text-base flex-1 px-4 h-[40px] items-center rounded-[5px]' color={ButtonColor.primary}
                                    onClick={() => validateFields(!item.id)}
                            >
                                    <span className="px-4">{!item.id ? "Create" : "Update"}</span>
                            </CusRoundedButton>
                    </div>
                :
                    <div className="p-4 w-full flex justify-between items-center h-[72px] border-t-[1px] border-[#CBD5E140]">
                            <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex justify-center text-base flex-shrink-0 px-4 h-[40px] items-center rounded-[5px]' color={ButtonColor.darkNoBorder}
                                    onClick={() => removeAction()}
                            >
                                    <span className="">Delete</span>
                            </CusRoundedButton>
                            <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex justify-center text-base flex-shrink-0 px-4 h-[40px] items-center rounded-[5px]' color={ButtonColor.primary}
                                    onClick={() => setIsEdit(true)}
                            >
                                    <span className="">Edit</span>
                            </CusRoundedButton>
                    </div>
            }
        </div>
    )
}

EditEntity.propTypes = {
    isEditable: PropTypes.bool,
    isEdit: PropTypes.bool,
    setIsEdit: PropTypes.func,
    item: PropTypes.object,
    updateAction: PropTypes.func,
    removeAction: PropTypes.func
};

export default EditEntity;

