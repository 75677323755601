import * as _ from 'lodash';
import moment from 'moment';

import FACEBOOK from 'assets/img/omni/omni-facebook.svg';
import LINE from 'assets/img/omni/omni-line.svg';
import LIVECHAT from 'assets/img/omni/livechat.svg';
import WECHAT from 'assets/img/omni/omni-wechat.svg';
import WHATSAPP from 'assets/img/omni/omni-whatsapp.svg';
import { generalPrefix } from 'lang/locales/prefix';

export function isSuccessResponse(response) {
    const statusCode = response.data.statusCode;
    if(statusCode === 200) return true;
    else return false;
}

export function isJson(item) {
    item = typeof item !== "string"
        ? JSON.stringify(item)
        : item;

    try {
        item = JSON.parse(item);
    } catch (e) {
        return false;
    }

    if (typeof item === "object" && item !== null) {
        return true;
    }

    return false;
}

export const currentTimestamp = () => {
    return moment().valueOf()
}

export const onDownloadMedia = (mimeType, base64, filename) => {
    // console.log(mimeType, base64, filename)
    if(!mimeType || !base64)
        return;

    let downloadElement = document.createElement('a');
    // This  encodeURI encodes special characters, except: , / ? : @ & = + $ # (Use encodeURIComponent() to encode these characters).
    downloadElement.href = `data:${mimeType};base64,${base64}`;
    downloadElement.target = '_self';
    downloadElement.download = filename ? filename : moment(new Date()).format("DD-MM-YYYY h:mm A") + '.' + mimeType.split('/')[1];
    document.body.appendChild(downloadElement);
    downloadElement.click();
}

export const getChatFormatedDate = (timestamp) => {
    let momentDateTime = moment(timestamp).startOf('day');
    let momentToday = moment().startOf('day');

    let dateformat = momentDateTime.format("DD/MM/YYYY");

    if(momentToday.isSame(momentDateTime))
        dateformat = "Today, " + dateformat

    return dateformat;
}

export const getChatFormatedFullDateTime = (timestamp) => {
    return moment(timestamp).format("DD/MM/YYYY HH:mm");
}

export const isToday = (timestamp) => {
    let momentDateTime = moment(timestamp);
    let momentDateTimeClone = momentDateTime.clone();

    return momentDateTimeClone.startOf('day').isSame(moment().startOf('day'), 'd')
}

export const getChatFormatedTime = (timestamp) => {
    let momentDateTime = moment(timestamp);
    
    if(isToday(timestamp))
        return momentDateTime.format("HH:mm");
    else
        return momentDateTime.format("DD/MM HH:mm");
}

export const MESSAGE_STATUS = Object.freeze({
    'SENT': 'SENT',
    'RECEIVED': 'RECEIVED',
    'FAILED': 'FAILED',
    'DELIVERED': 'DELIVERED',
    'PENDING': 'PENDING'
})

export const MESSAGE_TYPE = Object.freeze({
    'TEXT': 'TEXT',
    'IMAGE': 'IMAGE',
    'AUDIO': 'AUDIO',
    'VIDEO': 'VIDEO',
    'LOCATION': 'LOCATION',
    'STICKER': 'STICKER',
    'FILE': 'FILE',
    'CONTACT': 'CONTACT'
})

export const EXTENDED_MESSAGE_TYPE = Object.freeze({
    ...MESSAGE_TYPE,
    "TEMPLATE": "TEMPLATE"
})

export const getMessageTypeFromMime = (mimetype) => {
    if(mimetype){
        if(mimetype.split('/')[0] === 'image')
            return MESSAGE_TYPE.IMAGE
        else if(mimetype.split('/')[0] === 'audio')
            return MESSAGE_TYPE.AUDIO
        else if(mimetype.split('/')[0] === 'video')
            return MESSAGE_TYPE.VIDEO
    }    

    return MESSAGE_TYPE.FILE
}

export const convertChatbotMessage = (message) => {
    let newMessage = '';

    let msgObj = JSON.parse(message);

    if(msgObj['message'])
        newMessage += msgObj['message']

    if(msgObj['optionList'])
        newMessage += '\n' + msgObj['optionList'].join('\n');

    return newMessage;
}

export const LANGUAGE = Object.freeze({
    "ENGLISH": "ENGLISH",
    "TRADITIONAL_CHINESE": "TRADITIONAL_CHINESE",
    "SIMPLIFIED_CHINESE": "SIMPLIFIED_CHINESE",
})

export const LANGUAGE_NAME = Object.freeze({
    "ENGLISH": `${generalPrefix}.nameList.language.english`,
    "TRADITIONAL_CHINESE": `${generalPrefix}.nameList.language.traditionalChinese`,
    "SIMPLIFIED_CHINESE": `${generalPrefix}.nameList.language.simplifiedChinese`,
})

export const LANGUAGE_NAME_LIST = Object.freeze([
    "ENGLISH", "TRADITIONAL_CHINESE", "SIMPLIFIED_CHINESE"
])

export const SEARCH_LANGUAGE = Object.freeze({
    "ALL": "ALL",
    ...LANGUAGE
})

export const SEARCH_LANGUAGE_NAME = Object.freeze({
    "ALL": `${generalPrefix}.nameList.language.all`,
    ...LANGUAGE_NAME
})

export const SEARCH_LANGUAGE_NAME_LIST = Object.freeze([
    "ALL", ...LANGUAGE_NAME_LIST
])


export const CHANNEL_URLS = Object.freeze({
    FACEBOOK: FACEBOOK,
    LINE: LINE,
    LIVECHAT: LIVECHAT,
    WECHAT: WECHAT,
    WHATSAPP : WHATSAPP,
})

export const AUTOMATION_ACTION_EMAIL_TYPE_LIST = Object.freeze([
    "userId",
    "userGroupId",
    "toOwner",
    "fieldConfigIdList",
    "toEmailList"
]);

export const onEnter = (e, callback, validation) => {
    if(e.key === 'Enter' && !e.shiftKey && validation && !!callback){
        callback(e)
    }
}

export const isNumeric = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export const matchRegex = (regex, value) => {
    let regExp;

    try{
        // regExp = new RegExp('^' + regex + '$');
        regExp = new RegExp(regex);
        return regExp.test(value)
    }
    catch(e){            
        return false
    }

}

export const capitalise = (str, replaceAsSpace) => {
    if(replaceAsSpace)
        str = str.replaceAll(replaceAsSpace, " ");

    if(str.trim().length < 1)
        return ""

    let strList = str.split(" ");
    strList = strList.map(str => str = str[0].toUpperCase() + str.slice(1).toLowerCase())

    return strList.join(" ")
}

export const addFieldItemAction = ({itemTemplate, setFieldList, setFieldErrorList}) => {
    setFieldList((fieldList) => [...fieldList, {...itemTemplate}])

    if(setFieldErrorList)
        setFieldErrorList((errorList) => [...errorList, {}])
}

export const addFieldItemWithErrorAction = ({itemTemplate, defaultError, setFieldList, setFieldErrorList}) => {
    setFieldList((fieldList) => [...fieldList, {...itemTemplate}])
    setFieldErrorList((errorList) => [...errorList, {...defaultError}])
}

export const updateFieldItemAction = ({field, error, index, fieldList, fieldErrorList, setFieldList, setFieldErrorList}) => {
    let newFieldList = JSON.parse(JSON.stringify(fieldList))
    
    newFieldList.splice(index, 1, field)
    
    if(fieldErrorList && setFieldErrorList){
        let newErrorList = JSON.parse(JSON.stringify(fieldErrorList))
        newErrorList.splice(index, 1, error)
    
        setFieldErrorList(newErrorList)
    }
    
    setFieldList(newFieldList)
}

export const removeFieldItemAction = ({index, setFieldList, setFieldErrorList}) => {
    setFieldList((fieldList) => fieldList.filter((_, i) => i !== index))
    
    if(setFieldErrorList)
        setFieldErrorList((errorList) => errorList.filter((_, i) => i !== index))
}

export const checkListItemDuplication = (list) => {
    let items = {}

    for(let index = 0; index < list.length; index++){
        if(items[list[index]] !== undefined){
            return [items[list[index]], index]
        }

        items[list[index]] = index
    }

    console.log(items)

    return []
}

export const checkListItemFullDuplication = (list) => {
    let items = {}
    let duplications = new Set()

    for(let index = 0; index < list.length; index++){
        if(items[list[index]] !== undefined){
            duplications.add(items[list[index]])
            duplications.add(index)
        }

        items[list[index]] = index
    }

    console.log(items)

    return [...duplications]
}