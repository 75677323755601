import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import List from 'components/Icon/NewChatbotIcon/List';
import Keywords from 'components/Icon/NewChatbotIcon/Keywords';
import Parameter from 'components/Icon/NewChatbotIcon/Parameter';
import Entity from 'components/Icon/NewChatbotIcon/Entity';
import Response from 'components/Icon/NewChatbotIcon/Response';
import Action from 'components/Icon/NewChatbotIcon/Action';

import { useLanguage } from 'hooks/useLanguage';
import { useIntl } from 'react-intl';
import { chatbotIconBarPrefix, settingPrefix } from 'lang/locales/prefix';

import { SETTING_SUB_MENU_TYPE } from '../EditFlow';

import './index.css'
import Scrollbars from 'react-custom-scrollbars-2';

const IconBar = ({
    activeIcon = '',
    selectIcon = () => {},
}) => {

	const intl = useIntl();
	const { language } = useLanguage()

    return(
        <div className='icon-bar-container'>
					<Scrollbars
							autoHide={false}
							className="w-full h-full"
					>
						<div className='flex w-full h-full justify-center'>
							<div className='flex flex-col h-full w-[80px]'>
								<div className='icon-bar-sub-container hr-bar'>
									<div className='flex flex-col items-center'>
										<div
											className={`icon-bar-button ${activeIcon === SETTING_SUB_MENU_TYPE.LIST ? 'icon-bar-button-active' : ''}`}
											onClick={() => selectIcon(SETTING_SUB_MENU_TYPE.LIST)}
										>
											<List/>
										</div>
										<div className='icon-bar-button-text'>{
											intl.formatMessage({ "id": `${settingPrefix}.${chatbotIconBarPrefix}.list`})
										}</div>
									</div>
								</div>
								<div className='icon-bar-sub-container'>
									<div className='flex flex-col items-center'>
										<div
											className={`icon-bar-button ${activeIcon === SETTING_SUB_MENU_TYPE.KEYWORD ? 'icon-bar-button-active' : ''}`}
											onClick={() => selectIcon(SETTING_SUB_MENU_TYPE.KEYWORD)}
										>
											<Keywords/>
										</div>
										<div className='icon-bar-button-text'>{
											intl.formatMessage({ "id": `${settingPrefix}.${chatbotIconBarPrefix}.keywords`})
										}</div>
									</div>
								</div>
								<div className='icon-bar-sub-container'>
									<div className='flex flex-col items-center'>
										<div
											className={`icon-bar-button ${activeIcon === SETTING_SUB_MENU_TYPE.PARAMETER ? 'icon-bar-button-active' : ''}`}
											onClick={() => selectIcon(SETTING_SUB_MENU_TYPE.PARAMETER)}
										>
											<Parameter/>
										</div>
										<div className='icon-bar-button-text'>{
											intl.formatMessage({ "id": `${settingPrefix}.${chatbotIconBarPrefix}.parameter`})
										}</div>
									</div>
								</div>
								<div className='icon-bar-sub-container hr-bar'>
									<div className='flex flex-col items-center'>
										<div
											className={`icon-bar-button ${activeIcon === SETTING_SUB_MENU_TYPE.ENTITY ? 'icon-bar-button-active' : ''}`}
											onClick={() => selectIcon(SETTING_SUB_MENU_TYPE.ENTITY)}
										>
											<Entity/>
										</div>
										<div className='icon-bar-button-text'>{
											intl.formatMessage({ "id": `${settingPrefix}.${chatbotIconBarPrefix}.entity`})
										}</div>
									</div>
								</div>
								<div className='icon-bar-sub-container'>
									<div className='flex flex-col items-center'>
										<div
											className={`icon-bar-button ${activeIcon === SETTING_SUB_MENU_TYPE.RESPONSE ? 'icon-bar-button-active' : ''}`}
											onClick={() => selectIcon(SETTING_SUB_MENU_TYPE.RESPONSE)}
										>
											<Response/>
										</div>
										<div className='icon-bar-button-text'>{
											intl.formatMessage({ "id": `${settingPrefix}.${chatbotIconBarPrefix}.response`})
										}</div>
									</div>
								</div>
								<div className='icon-bar-sub-container'>
									<div className='flex flex-col items-center'>
										<div
											className={`icon-bar-button ${activeIcon === SETTING_SUB_MENU_TYPE.ACTION ? 'icon-bar-button-active' : ''}`}
											onClick={() => selectIcon(SETTING_SUB_MENU_TYPE.ACTION)}
										>
											<Action/>
										</div>
										<div className='icon-bar-button-text'>{
											intl.formatMessage({ "id": `${settingPrefix}.${chatbotIconBarPrefix}.action`})
										}</div>
									</div>
								</div>
							</div>
						</div>
					</Scrollbars>


        </div>
    )
}

IconBar.propTypes = {
		activeIcon: PropTypes.string,
    selectIcon: PropTypes.func,
};

export default IconBar;
