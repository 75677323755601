import {httpPost} from 'lib/requests';

const getKeyword = (data, authToken) => {
    return httpPost('chatbot/keyword/get', data, authToken);
}

const bulkKeyword = (data, authToken) => {
    return httpPost('chatbot/keyword/bulk', data, authToken);
}

export {
    getKeyword,
    bulkKeyword,
};