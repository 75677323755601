const Location = (rest) => {
    return(
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path fill="currentColor" d="M12 20.9998C10.7369 19.9224 9.56619 18.7413 8.5 17.4688C6.9 15.5578 5 12.7118 5 9.99981C4.99858 7.16735 6.70425 4.6132 9.32107 3.5292C11.9379 2.44521 14.9501 3.04505 16.952 5.04881C18.2685 6.35941 19.0059 8.14219 19 9.99981C19 12.7118 17.1 15.5578 15.5 17.4688C14.4338 18.7413 13.2631 19.9224 12 20.9998ZM12 6.99981C10.9282 6.99981 9.93782 7.5716 9.40193 8.49981C8.86603 9.42801 8.86603 10.5716 9.40193 11.4998C9.93782 12.428 10.9282 12.9998 12 12.9998C13.6569 12.9998 15 11.6567 15 9.99981C15 8.34295 13.6569 6.99981 12 6.99981Z"/>
        </svg>
    )
}

export default Location;