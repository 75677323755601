import DownArrow from 'components/Icon/DownArrow';
import UpArrow from 'components/Icon/UpArrow';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';


const Group = ({
	label = "",
	defaultshow = true,
	children = null,
}) => {

    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(defaultshow)
    }, [defaultshow])

    return (
        <>
            <div
                className="flex cursor-pointer m-4 text-sm justify-between items-center"
                onClick={(e) => {
                    e.stopPropagation()
                    setShow(!show)
                }}
            >
                <span className="text-greyblack">{label}</span>
                <span className="text-blue04 pr-4">{show ? <UpArrow/> : <DownArrow className="w-[14px] h-[8px]"/>}</span>
            </div>
            {show ?
                children
                :
                null
            }
        </>
    )
}

Group.propTypes = {
    label: PropTypes.string,
    defaultshow: PropTypes.bool,
	children: PropTypes.node
};

export default Group;