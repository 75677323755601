import BottomHandle from 'components/Flow/Handle/BottomHandle';
import TopHandle from 'components/Flow/Handle/TopHandle';
import FlowNode from 'components/Icon/FlowNode';
import Plus from 'components/Icon/Plus';
import AnyText from 'components/Icon/NewChatbotIcon/AnyText';
import AnyMedia from 'components/Icon/NewChatbotIcon/AnyMedia';
import Keywords from 'components/Icon/NewChatbotIcon/Keywords';
import Entity from 'components/Icon/NewChatbotIcon/Entity';
import Parameter from 'components/Icon/NewChatbotIcon/Parameter';
import Response from 'components/Icon/NewChatbotIcon/Response';
import Action from 'components/Icon/NewChatbotIcon/Action';
import { editChatbotPrefix, settingPrefix } from 'lang/locales/prefix';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';

const NormalNode = memo(({data}) => {

    const intl = useIntl();

    let name = data.name ?? "Untitled Node"

    let response = data.response;
    let action = data.action;
    let chatCondition = data.chatCondition;
    let chatConditionType = data.chatConditionType;

    console.log({
        response,
        action,
        chatCondition,
        chatConditionType,
    })

    return (
        <>
            <TopHandle/>
            <div title={name} className={`noDrag flex items-center justify-center rounded-[8px] bg-[#7FDCEB] w-full`}
            >
                <div className='w-full h-full flex flex-col p-1'>
                    <span className={`px-1 text-[13px] ${data.name ? "text-greyblack" : "text-[#94A3B8]"}`}>{name}</span>
                    <div onClick={(e) => data.onSelectNode(() => data.onShowChatCondition())} className={`flex cursor-pointer truncate overflow-hidden items-center rounded-[8px] bg-white w-full h-[32px] my-1 px-1 ${!!chatConditionType ? "text-greyblack" : "text-[#94A3B8]"}`}>
                        {chatConditionType === "ANY_MESSAGE" && <AnyText className="w-[24px] h-[24px]"/>}
                        {chatConditionType === "ANY_MEDIA" && <AnyMedia className="w-[24px] h-[24px]"/>}
                        {chatConditionType === "KEYWORDS" && <Keywords className="w-[24px] h-[24px]"/>}
                        {chatConditionType === "ENTITY" && <Entity className="w-[24px] h-[24px]"/>}
                        {chatConditionType === "PARAMETER" && <Parameter className="w-[24px] h-[24px]"/>}
                        {!chatConditionType && <Plus className="w-[24px] h-[24px]"/>}
                        <span className={`truncate px-2 py-1 text-[13px]`}>{chatCondition ? chatCondition : "Chat Condition"}</span>
                    </div>
                    <div onClick={(e) => data.onSelectNode(() => data.onShowResponse())} className={`flex cursor-pointer truncate overflow-hidden items-center rounded-[8px] bg-white w-full h-[32px] my-1 px-1 ${!!response ? "text-greyblack" : "text-[#94A3B8]"}`}>
                        {!!response && <Response className="w-[24px] h-[24px]"/>}
                        {!response && <Plus className="w-[24px] h-[24px]"/>}
                        <span className={`truncate px-2 py-1 text-[13px]`}>{response ? response : "Response"}</span>
                    </div>
                    <div onClick={(e) => data.onSelectNode(() => data.onShowAction())} className={`flex cursor-pointer truncate overflow-hidden items-center rounded-[8px] bg-white w-full h-[32px] my-1 px-1 ${!!action ? "text-greyblack" : "text-[#94A3B8]"}`}>
                        {!!action && <Action className="w-[24px] h-[24px]"/>}
                        {!action && <Plus className="w-[24px] h-[24px]"/>}
                        <span className={`truncate px-2 py-1 text-[13px]`}>{action ? action : "Action"}</span>
                    </div>
                </div>
            </div>
            <BottomHandle/>
        </>
    );
    // return (
    //     <>
    //         <TopHandle/>
    //         <div title={data.name} className={`noDrag flex flex-col items-center cursor-pointer justify-center w-full text-yellow01 p-2 m-2 hover:bg-blue06 ${data.selected ? "bg-blue06" : ""}`}
    //             onClick={(e) => data.onSelectNode()}
    //         >
    //             <FlowNode/>
    //             <span className='pt-2 text-grey06 font-semibold text-sm'>{intl.formatMessage({ "id": `${settingPrefix}.${editChatbotPrefix}.text.node` })}</span>
    //             <span className='p-2 text-greyblack font-bold text-sm w-full truncate text-center'>{data.name}</span>
    //         </div>
    //         <BottomHandle/>
    //     </>
    // );
});

export default NormalNode