import { editNodePrefix, editTemplatePrefix, rolePrefix, roleSettingPrefix, templatePrefix } from "../prefix"
import { editChatbotPrefix } from "../prefix"
import { editKeywordPrefix } from "../prefix"
import { editLivechatSettingPrefix } from "../prefix"
import { editEntityPrefix } from "../prefix"
import { editResponsePrefix } from "../prefix"
import { editActionPrefix } from "../prefix"
import { editParameterPrefix } from "../prefix"
import { businessGeneralSettingPrefix, businessPrefix, channelAccountPrefix, chatAutomationPrefix, chatbotPrefix, chatbotIconBarPrefix, chatGroupPrefix, chatRoutingPrefix, editChannelAccountPrefix, editChatAutomationPrefix, createOrCloneChatbotPrefix, editChatRoutingPrefix, editFieldConfigPrefix, editOpenaiPrefix, editQuickTextPrefix, editSystemMessagePrefix, fieldConfigPrefix, livechatSettingPrefix, messagePrefix, openaiPrefix, planSubscriptionPrefix, quickTextPrefix, settingPrefix, systemGeneralSettingPrefix, systemMessagePrefix, systemPrefix, taggingPrefix, userListPrefix, wrapUpPrefix } from "../prefix"

const layout = {
    [`${settingPrefix}.general`]: "通用設置",
    [`${settingPrefix}.general.business`]: "商業",
    [`${settingPrefix}.general.user`]: "用戶",
    [`${settingPrefix}.general.systemFeature`]: "系統功能",
    [`${settingPrefix}.general.role`]: "角色",

    [`${settingPrefix}.chat`]: "聊天設置",
    [`${settingPrefix}.chat.channelAccount`]: "頻道賬號",
    [`${settingPrefix}.chat.chatGroup`]: "聊天組",
    [`${settingPrefix}.chat.chatRouting`]: "聊天路由",

    [`${settingPrefix}.conversations`]: "對話",
    [`${settingPrefix}.conversations.message`]: "消息",
    [`${settingPrefix}.conversations.tagging`]: "標記",
    [`${settingPrefix}.conversations.wrapUp`]: "總結",

    [`${settingPrefix}.automation`]: "自動化",
    [`${settingPrefix}.automation.chatAutomation`]: "聊天自動化",
    [`${settingPrefix}.automation.chatbot`]: "聊天機器人設置",
    [`${settingPrefix}.automation.openaiSetting`]: "OpenAI 設置",

    [`${settingPrefix}.widget`]: "小工具",
    [`${settingPrefix}.widget.liveChatbot`]: "實時聊天設置",

}

const business = {
    [`${settingPrefix}.${businessPrefix}.page.text.title`]: "業務設置",

    [`${settingPrefix}.${businessPrefix}.tab.title.general`]: "一般",
    [`${settingPrefix}.${businessPrefix}.tab.title.planSubscription`]: "計劃訂閱",
}

const businessGeneralSetting = {
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.title.profile`]: "業務簡介",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.subTitle.profile`]: "告訴我們您的業務",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.title.logo`]: "企業標誌",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.subTitle.logo`]: "此徽標將僅應用於系統。它應為 PNG 或 SVG 格式，最大分辨率為 120px x 35px。",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.title.address`]: "公司地址",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.subTitle.address`]: "默認為系統關鍵業務地址",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.title.timeAndLang`]: "時區和語言",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.subTitle.timeAndLang`]: "默認為系統的關鍵時區和語言",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.title.regularHours`]: "正常營業時間和日期",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.section.text.subTitle.regularHours`]: "默認為聊天交互的正常營業時間和日期",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.profile.name`]: "商家名稱",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.profile.email`]: "電子郵件",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.address.address`]: "地址",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.address.zipCode`]: "郵政編碼",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.address.country`]: "地區/國家",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.extra.timezone`]: "時區",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.extra.defaultLanguage`]: "語言",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.regularHours.name`]: "姓名",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.regularHours.day`]: "天",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.regularHours.timeFrom`]: "時間從",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.field.regularHours.timeTo`]: "時間到",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.nameList.type.mondays`]: "星期一",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.nameList.type.tuesdays`]: "星期二",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.nameList.type.wednesdays`]: "星期三",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.nameList.type.thursdays`]: "星期四",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.nameList.type.fridays`]: "星期五",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.nameList.type.saturdays`]: "星期六",
    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.nameList.type.sundays`]: "星期天",

    [`${settingPrefix}.${businessPrefix}.${businessGeneralSettingPrefix}.button.addDay`]: "添加日期",
}

const planSubscription = {
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.table.text.title`]: "計劃訂閱",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.button.contact`]: "現在聯繫",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.button.modify`]: "修改計劃",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.button.subscribe`]: "訂閱計劃",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.modal.warning`]: "聯繫 Recur 升級或取消計劃訂閱",

    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.table.displayName.0`]: "計劃",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.table.displayName.1`]: "訂閱日期",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.table.displayName.2`]: "到期日",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.table.displayName.3`]: "許可證總數",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.table.displayName.4`]: "狀態",

    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.card.text.current`]: "當前計劃",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.card.text.total`]: "許可證總數",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.card.text.quota`]: "許可證",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.card.text.used`]: "已用許可證",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.card.text.unused`]: "未使用的許可證",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.card.text.expiry`]: "到期日（即將到來）",

    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.nameList.status.expired`]: "已過期",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.nameList.status.canceled`]: "已取消",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.nameList.status.pending`]: "待定",

    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.text.title`]: "選擇您的許可證",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.text.subTitle`]: "為您的業務選擇正確的解決方案",

    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.card.text.priceTag`]: "每月",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.card.text.subscribe`]: "訂閱",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.card.text.back`]: "返回",

    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.field.priceTag`]: "價格標籤",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.field.quantity`]: "數量",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.field.option.monthly`]: "每月",
    [`${settingPrefix}.${businessPrefix}.${planSubscriptionPrefix}.licenseOptions.field.option.yearly`]: "每年",    
}

const userList = {
    [`${settingPrefix}.${userListPrefix}.table.text.title`]: "用戶設置",
    [`${settingPrefix}.${userListPrefix}.table.text.subTitle`]: "在此處編輯用戶的角色和個人資料",
    [`${settingPrefix}.${userListPrefix}.table.placeholder.search`]: "搜索用戶",

    [`${settingPrefix}.${userListPrefix}.modal.warning.disable`]: "你確認禁用這個用戶嗎？",
    [`${settingPrefix}.${userListPrefix}.modal.warning.remove`]: "你確認刪除這個用戶嗎？",

    [`${settingPrefix}.${userListPrefix}.table.displayName.0`]: "顯示名稱",
    [`${settingPrefix}.${userListPrefix}.table.displayName.1`]: "用戶名",
    [`${settingPrefix}.${userListPrefix}.table.displayName.2`]: "電子郵件",
    [`${settingPrefix}.${userListPrefix}.table.displayName.3`]: "角色",
    [`${settingPrefix}.${userListPrefix}.table.displayName.4`]: "活動",

    [`${settingPrefix}.${userListPrefix}.button.addUsers`]: "添加用戶",
}

const system = {
    [`${settingPrefix}.${systemPrefix}.page.text.title`]: "系統功能設置",

    [`${settingPrefix}.${systemPrefix}.tab.title.general`]: "一般",
    [`${settingPrefix}.${systemPrefix}.tab.title.fieldConfig`]: "字段配置",
}

const systemGeneralSetting = {
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.section.text.title.scheduler`]: "調度器配置",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.section.text.subTitle.scheduler`]: "設置調度程序配置",

    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.section.text.title.iframe`]: "iframe 域配置",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.section.text.subTitle.iframe`]: "設置 Iframe 域配置",

    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.section.text.title.crossDomain`]: "跨域配置",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.section.text.subTitle.crossDomain`]: "設置跨域配置",

    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.section.text.title.integration`]: "系統集成",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.section.text.subTitle.integration`]: "設置系統集成配置",

    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.button.addDomain`]: "添加域",

    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.scheduler.autoOffline`]: "自動離線",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.scheduler.chatbotConversationAutoClose`]: "聊天機器人對話自動關閉",

    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.enableExternalSystem.isEnableExternalSystem`]: "啟用外部系統",

    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.externalSystem.externalSystemType`]: "外部系統類型",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.externalSystem.endpoint`]: "服務器端點",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.externalSystem.clientId`]: "客戶編號",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.externalSystem.secretKey`]: "密鑰",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.externalSystem.username`]: "用戶名",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.externalSystem.password`]: "密碼",
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.externalSystem.createConversation`]: "創建對話",
    
    [`${settingPrefix}.${systemPrefix}.${systemGeneralSettingPrefix}.field.domain.domain`]: "域",

}

const fieldConfig = {
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.text.title`]: "字段配置",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.placeholder.search`]: "搜索字段",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.modal.warning`]: "確認禁用該字段？",

    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.displayName.0`]: "字段名",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.displayName.1`]: "表名",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.displayName.2`]: "顯示名稱",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.displayName.3`]: "字段類型",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.displayName.4`]: "必填",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.displayName.5`]: "可編輯",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.displayName.6`]: "可創建",
    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.table.displayName.7`]: "狀態",

    [`${settingPrefix}.${systemPrefix}.${fieldConfigPrefix}.button.addConfig`]: "添加配置",
}

const editfieldConfig = {
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.text.title.edit`]: "編輯字段配置",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.text.title.add`]: "新字段配置",

    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.title.type`]: "字段類型配置設置",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.subTitle.type`]: "設置字段類型配置",

    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.title.field`]: "字段配置設置",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.subTitle.field`]: "設置字段配置",

    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.title.option`]: "字段额外选项设置",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.subTitle.option`]: "设置字段额外选项",

    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.title.extra`]: "字段額外配置設置",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.subTitle.extra`]: "設置特定於類型的字段配置",

    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.title.status`]: "字段狀態",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.section.text.subTitle.status`]: "啟用或禁用字段配置",

    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.progress.0`]: "字段類型設置",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.progress.1`]: "字段配置設置",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.progress.2`]: "字段配置可選設置",

    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.fieldName`]: "字段名",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.tableName`]: "表名",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.displayNameEn`]: "顯示名稱（英文）",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.displayNameTc`]: "顯示名稱(繁體中文)",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.displayNameSc`]: "顯示名稱（簡體中文）",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.displayOrder`]: "顯示順序",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.listViewOrder`]: "列表視圖順序",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.required`]: "必填字段",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.editable`]: "可編輯字段",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.searchable`]: "可搜索字段",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.creatable`]: "可創建字段",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.traceable`]: "可追溯字段",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.displayInListview`]: "在列表視圖中顯示",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.field.fieldType`]: "字段類型",

    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.extra.length`]: "長度",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.extra.validationRegex`]: "驗證正則表達式",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.extra.decimalPlace`]: "小數位",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.extra.format`]: "流水號格式",
    [`${settingPrefix}.${systemPrefix}.${editFieldConfigPrefix}.field.extra.picklist`]: "選擇列表選項",

}

const role = {
    [`${settingPrefix}.${rolePrefix}.table.text.title`]: "角色設置",
    [`${settingPrefix}.${rolePrefix}.table.text.subTitle`]: "設置角色權限",
   
    [`${settingPrefix}.${rolePrefix}.table.placeholder.search`]: "搜索角色",
    [`${settingPrefix}.${rolePrefix}.text.type`]: "類型",

    [`${settingPrefix}.${rolePrefix}.table.displayName.0`]: "名稱",
    [`${settingPrefix}.${rolePrefix}.table.displayName.1`]: "顯示名稱",
    [`${settingPrefix}.${rolePrefix}.table.displayName.2`]: "類型",

    [`${settingPrefix}.${rolePrefix}.nameList.type.system`]: "系統",
    [`${settingPrefix}.${rolePrefix}.nameList.type.custom`]: "自定義",
}

const roleSetting = {
    [`${settingPrefix}.${roleSettingPrefix}.progress.0`]: "常規配置",
    [`${settingPrefix}.${roleSettingPrefix}.progress.1`]: "權限",

    [`${settingPrefix}.${roleSettingPrefix}.modal.warning`]: "您確定要重置嗎？\n 將不會保存更改。",
    [`${settingPrefix}.${roleSettingPrefix}.modal.warning.permission`]: "您確定要重置權限嗎？",

    [`${settingPrefix}.${roleSettingPrefix}.field.config.name`]: "名稱",
    [`${settingPrefix}.${roleSettingPrefix}.field.config.displayName`]: "顯示名稱",

    [`${settingPrefix}.${roleSettingPrefix}.text.title.new`]: "新角色",
   
    [`${settingPrefix}.${roleSettingPrefix}.section.text.title.general`]: "常規配置",
    [`${settingPrefix}.${roleSettingPrefix}.section.text.subTitle.general`]: "設置通用角色配置",

    [`${settingPrefix}.${roleSettingPrefix}.table.text.title`]: "權限",
    [`${settingPrefix}.${roleSettingPrefix}.table.text.subTitle`]: "設置角色權限",

    [`${settingPrefix}.${roleSettingPrefix}.table.displayName.0`]: "請求模型",
    [`${settingPrefix}.${roleSettingPrefix}.table.displayName.1`]: "讀取",
    [`${settingPrefix}.${roleSettingPrefix}.table.displayName.2`]: "編輯",
    [`${settingPrefix}.${roleSettingPrefix}.table.displayName.3`]: "刪除",
    [`${settingPrefix}.${roleSettingPrefix}.table.displayName.4`]: "標籤",

    [`${settingPrefix}.${roleSettingPrefix}.table.displayName.5`]: "全部",
}

const channelAccount = {
    [`${settingPrefix}.${channelAccountPrefix}.page.text.title`]: "頻道賬戶設置",

    [`${settingPrefix}.${channelAccountPrefix}.tab.title.general`]: "常規",
    [`${settingPrefix}.${channelAccountPrefix}.tab.title.advanced`]: "高級",

    [`${settingPrefix}.${channelAccountPrefix}.text.region`]: "地區",
    [`${settingPrefix}.${channelAccountPrefix}.nameList.region.all`]: "所有地區",

    [`${settingPrefix}.${channelAccountPrefix}.table.text.title`]: "高級頻道賬戶",
    [`${settingPrefix}.${channelAccountPrefix}.table.text.subTitle`]: "為不同的業務、語言和渠道等添加多個渠道帳戶。",
    [`${settingPrefix}.${channelAccountPrefix}.table.placeholder.search`]: "搜索賬戶",
    [`${settingPrefix}.${channelAccountPrefix}.button.addAccounts`]: "添加賬戶",

    [`${settingPrefix}.${channelAccountPrefix}.modal.warning.disable`]: "確認禁用此頻道？之後將無法接收聊天。",
    [`${settingPrefix}.${channelAccountPrefix}.modal.warning.remove`]: "確認刪除這個頻道？",

    [`${settingPrefix}.${channelAccountPrefix}.table.displayName.0`]: "頻道 ID",
    [`${settingPrefix}.${channelAccountPrefix}.table.displayName.1`]: "頻道",
    [`${settingPrefix}.${channelAccountPrefix}.table.displayName.2`]: "賬戶名",
    [`${settingPrefix}.${channelAccountPrefix}.table.displayName.3`]: "地區",
    [`${settingPrefix}.${channelAccountPrefix}.table.displayName.4`]: "語言",
    [`${settingPrefix}.${channelAccountPrefix}.table.displayName.5`]: "有效",

    [`${settingPrefix}.${channelAccountPrefix}.list.text.title`]: "頻道設置",
    [`${settingPrefix}.${channelAccountPrefix}.list.text.subTitle`]: "在這裡設置基本的聊天頻道帳戶。對於多個帳戶，請轉到“高級”",
    [`${settingPrefix}.${channelAccountPrefix}.button.addChannel`]: "添加頻道",
}

const editChannelAccount = {
    [`${settingPrefix}.${editChannelAccountPrefix}.text.title.edit`]: "編輯頻道賬戶",
    [`${settingPrefix}.${editChannelAccountPrefix}.text.title.add`]: "新頻道賬戶",

    [`${settingPrefix}.${editChannelAccountPrefix}.section.text.title.setup`]: "頻道設置",
    [`${settingPrefix}.${editChannelAccountPrefix}.section.text.subTitle.setup`]: "為聊天互動設置新頻道",

    [`${settingPrefix}.${editChannelAccountPrefix}.section.text.title.mapping`]: "頻道映射",
    [`${settingPrefix}.${editChannelAccountPrefix}.section.text.subTitle.mapping`]: "將頻道映射到業務",

    [`${settingPrefix}.${editChannelAccountPrefix}.section.text.title.profile`]: "聊天資料",
    [`${settingPrefix}.${editChannelAccountPrefix}.section.text.subTitle.profile`]: "信息將被客戶看到。標誌必須是 SVG 或 PNG 格式，最大 100px x 100px",

    [`${settingPrefix}.${editChannelAccountPrefix}.section.text.title.status`]: "賬戶狀態",
    [`${settingPrefix}.${editChannelAccountPrefix}.section.text.subTitle.status`]: "啟用或禁用頻道帳戶",

    [`${settingPrefix}.${editChannelAccountPrefix}.field.setup.id`]: "ID (自動生成)",
    [`${settingPrefix}.${editChannelAccountPrefix}.field.setup.channelId`]: "頻道 ID",
    [`${settingPrefix}.${editChannelAccountPrefix}.field.setup.omniChannel`]: "頻道",
    [`${settingPrefix}.${editChannelAccountPrefix}.field.setup.displayName`]: "賬戶名",
    [`${settingPrefix}.${editChannelAccountPrefix}.field.setup.url`]: "URL",
    [`${settingPrefix}.${editChannelAccountPrefix}.field.setup.quickAccessUrl`]: "快速訪問網址",

    [`${settingPrefix}.${editChannelAccountPrefix}.field.mapping.timezone`]: "時區",
    [`${settingPrefix}.${editChannelAccountPrefix}.field.mapping.defaultLanguage`]: "語言",

    [`${settingPrefix}.${editChannelAccountPrefix}.field.profile.chatbotFlowName`]: "聊天機器人名稱",
    [`${settingPrefix}.${editChannelAccountPrefix}.field.profile.aIChatbotId`]: "OpenAI Bot",

    [`${settingPrefix}.${editChannelAccountPrefix}.button.addChannel`]: "添加頻道",
    [`${settingPrefix}.${editChannelAccountPrefix}.button.editAccount`]: "編輯賬戶",
    [`${settingPrefix}.${editChannelAccountPrefix}.button.removeAccount`]: "刪除賬戶",
}

const chatGroup = {
    [`${settingPrefix}.${chatGroupPrefix}.table.text.title`]: "聊天組設置",
    [`${settingPrefix}.${chatGroupPrefix}.table.text.subTitle`]: "為聊天路由創建聊天組",
    [`${settingPrefix}.${chatGroupPrefix}.table.placeholder.search`]: "搜索群組",

    [`${settingPrefix}.${chatGroupPrefix}.modal.warning`]: "確認刪除這個聊天組？",

    [`${settingPrefix}.${chatGroupPrefix}.table.displayName.0`]: "群組標籤",
    [`${settingPrefix}.${chatGroupPrefix}.table.displayName.1`]: "群組名稱",
    [`${settingPrefix}.${chatGroupPrefix}.table.displayName.2`]: "# 用戶數",

    [`${settingPrefix}.${chatGroupPrefix}.button.addGroups`]: "添加組",

    [`${settingPrefix}.${chatGroupPrefix}.text.title.edit`]: "編輯聊天組",
    [`${settingPrefix}.${chatGroupPrefix}.text.title.add`]: "創建聊天組",

    [`${settingPrefix}.${chatGroupPrefix}.field.group.groupName`]: "群組名稱",
    [`${settingPrefix}.${chatGroupPrefix}.field.group.groupLabel`]: "群組標籤",
    [`${settingPrefix}.${chatGroupPrefix}.field.group.method`]: "路由方法",

    [`${settingPrefix}.${chatGroupPrefix}.groupUser.text.title.edit`]: "編輯聊天組",
    [`${settingPrefix}.${chatGroupPrefix}.groupUser.text.title.add`]: "創建聊天組",

    [`${settingPrefix}.${chatGroupPrefix}.groupUser.table.placeholder.search`]: "搜索用戶",

    [`${settingPrefix}.${chatGroupPrefix}.groupUser.table.displayName.0`]: "顯示名稱",
    [`${settingPrefix}.${chatGroupPrefix}.groupUser.table.displayName.1`]: "用戶名",
    [`${settingPrefix}.${chatGroupPrefix}.groupUser.table.displayName.2`]: "電子郵件",
    [`${settingPrefix}.${chatGroupPrefix}.groupUser.table.displayName.3`]: "角色",

    [`${settingPrefix}.${chatGroupPrefix}.groupUser.table.footer.total`]: "總計",
    [`${settingPrefix}.${chatGroupPrefix}.groupUser.table.footer.selected`]: "已選擇收件人",
}

const chatRouting = {
    [`${settingPrefix}.${chatRoutingPrefix}.table.text.title`]: "聊天路由",
    [`${settingPrefix}.${chatRoutingPrefix}.table.text.subTitle`]: "根據條件將聊天分配到不同的聊天組和通道路由設定",
    [`${settingPrefix}.${chatRoutingPrefix}.table.placeholder.search`]: "搜索路由",

    [`${settingPrefix}.${chatRoutingPrefix}.text.type`]: "路由類型",

    [`${settingPrefix}.${chatRoutingPrefix}.nameList.type.default`]: "默認",
    [`${settingPrefix}.${chatRoutingPrefix}.nameList.type.condition`]: "條件",

    [`${settingPrefix}.${chatRoutingPrefix}.modal.warning.disable`]: "確認禁用此路由？",
    [`${settingPrefix}.${chatRoutingPrefix}.modal.warning.remove`]: "確認刪除此路由？",

    [`${settingPrefix}.${chatRoutingPrefix}.table.displayName.0`]: "優先級",
    [`${settingPrefix}.${chatRoutingPrefix}.table.displayName.1`]: "路由名稱",
    [`${settingPrefix}.${chatRoutingPrefix}.table.displayName.2`]: "類型",
    [`${settingPrefix}.${chatRoutingPrefix}.table.displayName.3`]: "分配的聊天組",
    [`${settingPrefix}.${chatRoutingPrefix}.table.displayName.4`]: "有效",

    [`${settingPrefix}.${chatRoutingPrefix}.button.addRouting`]: "添加路由",
}

const editChatRouting = {
    [`${settingPrefix}.${editChatRoutingPrefix}.text.title.edit`]: "編輯聊天路由",
    [`${settingPrefix}.${editChatRoutingPrefix}.text.title.add`]: "添加聊天路由",

    [`${settingPrefix}.${editChatRoutingPrefix}.section.text.title.setup`]: "路由設置",
    [`${settingPrefix}.${editChatRoutingPrefix}.section.text.subTitle.setup`]: "根據條件將聊天分配到不同的聊天組和頻道帳戶",

    [`${settingPrefix}.${editChatRoutingPrefix}.section.text.title.condition`]: "路由條件",
    [`${settingPrefix}.${editChatRoutingPrefix}.section.text.subTitle.condition`]: "設置路由條件",

    [`${settingPrefix}.${editChatRoutingPrefix}.section.text.title.allocation`]: "聊天組分配",
    [`${settingPrefix}.${editChatRoutingPrefix}.section.text.subTitle.allocation`]: "選擇聊天組進行聊天分配",

    [`${settingPrefix}.${editChatRoutingPrefix}.section.text.title.users`]: "聊天組用戶",
    [`${settingPrefix}.${editChatRoutingPrefix}.section.table.placeholder.search`]: "搜索用戶",

    [`${settingPrefix}.${editChatRoutingPrefix}.progress.0`]: "路由設置",
    [`${settingPrefix}.${editChatRoutingPrefix}.progress.1`]: "選擇聊天組進行聊天分配",

    [`${settingPrefix}.${editChatRoutingPrefix}.field.setup.name`]: "路由名稱",
    [`${settingPrefix}.${editChatRoutingPrefix}.field.setup.type`]: "路由類型",
    [`${settingPrefix}.${editChatRoutingPrefix}.field.setup.priority`]: "優先級",

    [`${settingPrefix}.${editChatRoutingPrefix}.field.condition.fieldType`]: "字段類型",
    [`${settingPrefix}.${editChatRoutingPrefix}.field.condition.fieldName`]: "字段名稱",
    [`${settingPrefix}.${editChatRoutingPrefix}.field.condition.condition`]: "條件",
    [`${settingPrefix}.${editChatRoutingPrefix}.field.condition.value`]: "值",

    [`${settingPrefix}.${editChatRoutingPrefix}.field.allocation.groupId`]: "聊天組",

    [`${settingPrefix}.${editChatRoutingPrefix}.error.condition.fieldName.invalidOption`]: "字段已刪除",

    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.fieldType.conversationStandardField`]: "對話",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.fieldType.prechatFormField`]: "預聊天表單",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.fieldType.contact`]: "聯繫人",
   
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.fieldName.name`]: "姓名",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.fieldName.language`]: "語言",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.fieldName.omniChannel`]: "全渠道",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.fieldName.omniExternal id`]: "Omni 外部 id",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.fieldName.omniChannel id`]: "全渠道id",

    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.equalsTo`]: "等於",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.notEqualsTo`]: "不等於",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.greaterThan`]: "大於",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.greaterThanOrEquals`]: "大於或等於",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.lessThan`]: "小於",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.lessThanOrEqualsTo`]: "小於或等於",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.startWith`]: "開始於",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.endWith`]: "結束於",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.empty`]: "空",
    [`${settingPrefix}.${editChatRoutingPrefix}.nameList.condition.condition.notEmpty`]: "非空",

    [`${settingPrefix}.${editChatRoutingPrefix}.button.addCondition`]: "添加條件",
    [`${settingPrefix}.${editChatRoutingPrefix}.button.addRouting`]: "添加路由",
    [`${settingPrefix}.${editChatRoutingPrefix}.button.editRouting`]: "編輯路由",
}

const message = {
    [`${settingPrefix}.${messagePrefix}.page.text.title`]: "消息設置",

    [`${settingPrefix}.${messagePrefix}.tab.title.quickText`]: "快速文本",
    [`${settingPrefix}.${messagePrefix}.tab.title.template`]: "模板",
    [`${settingPrefix}.${messagePrefix}.tab.title.systemMessage`]: "系統消息",
}

const quickText = {
    [`${settingPrefix}.${messagePrefix}.${quickTextPrefix}.table.text.title`]: "快速文本設置",
    [`${settingPrefix}.${messagePrefix}.${quickTextPrefix}.table.text.subTitle`]: "在公共文件夾中設置快速文本以進行聊天交互",
    [`${settingPrefix}.${messagePrefix}.${quickTextPrefix}.table.placeholder.search`]: "搜索快速文本",
    [`${settingPrefix}.${messagePrefix}.${quickTextPrefix}.modal.warning`]: "確認刪除這個快速文本？",

    [`${settingPrefix}.${messagePrefix}.${quickTextPrefix}.table.displayName.0`]: "標籤",
    [`${settingPrefix}.${messagePrefix}.${quickTextPrefix}.table.displayName.1`]: "語言",
    [`${settingPrefix}.${messagePrefix}.${quickTextPrefix}.table.displayName.2`]: "所有者",

    [`${settingPrefix}.${messagePrefix}.${quickTextPrefix}.button.addQuickText`]: "添加快速文本",
}

const editQuickText = {
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.text.title.edit`]: "編輯快速文本",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.text.title.add`]: "添加快速文本",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.section.text.title.setup`]: "快速文本設置",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.section.text.subTitle.setup`]: "設置代理在聊天交互期間使用的快速文本",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.section.text.title.list`]: "快速文本列表",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.section.text.subTitle.list`]: "設置快速文本",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.section.text.title.subCategory`]: "子類別快速文本列表",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.section.text.subTitle.subCategory`]: "設置子類別快速文本",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.expanable.text.quickText`]: "快速文本",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.expanable.text.subCategory`]: "子類別",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.progress.0`]: "快捷文本設置",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.progress.1`]: "快速文本列表",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.progress.2`]: "子目錄快速文本列表",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.field.setup.label`]: "快速文本標題",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.field.setup.language`]: "語言",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.field.setup.userId`]: "分配給",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.field.item.title`]: "標題",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.field.item.text`]: "文本",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.field.subCategory.label`]: "標籤",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.button.addItem`]: "添加項目",
    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.button.addCategory`]: "添加類別",

    [`${settingPrefix}.${messagePrefix}.${editQuickTextPrefix}.button.addQuickText`]: "添加快速文本",
}

const template = {
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.table.text.title`]: "模板設置",
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.table.text.subTitle`]: "在公用文件夾中設置用於聊天發起或廣播的模板。",
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.table.placeholder.search`]: "搜索模板",
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.modal.warning`]: "確認刪除此模板？",
   
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.table.displayName.0`]: "名稱",
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.table.displayName.1`]: "全渠道",
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.table.displayName.2`]: "渠道 ID",
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.table.displayName.3`]: "模板 ID",
    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.table.displayName.4`]: "語言",

    [`${settingPrefix}.${messagePrefix}.${templatePrefix}.button.addTemplate`]: "添加模板",
}

const editTemplate = {
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.text.title.edit`]: "編輯模板",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.text.title.add`]: "添加模板",

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.section.text.title.setup`]: "模板設置",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.section.text.subTitle.setup`]: "設置聊天發起或廣播模板",

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.section.text.title.content`]: "模板內容",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.section.text.subTitle.content`]: "模板的草稿內容",
   
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.section.text.title.list`]: "模板數據配置",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.section.text.subTitle.list`]: "設置模板數據配置",
   
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.expanable.text.template`]: "模板",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.expanable.text.subCategory`]: "子類別",

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.progress.0`]: "模板設置",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.progress.1`]: "模板內容",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.progress.2`]: "模板數據配置",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.progress.last`]: "預覽並確認",

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.setup.name`]: "模板名稱",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.setup.omniChannelId`]: "全渠道 ID",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.setup.templateId`]: "模板 ID",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.setup.language`]: "語言",

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.content.templateFormat`]: "模板消息",
   
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.item.componentType`]: "組件類型",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.item.messageType`]: "消息類型",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.item.dataFormat`]: "數據格式",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.field.item.imagePath`]: "圖片網址",

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.nameList.item.componentType.header`]: "標頭",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.nameList.item.componentType.body`]: "正文",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.nameList.item.componentType.footer`]: "頁腳",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.nameList.item.messageType.text`]: "文本",
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.nameList.item.messageType.image`]: "圖片",

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.notice.empty`]: `沒有數據密鑰需要配置，請跳過當前步驟`,

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.error.empty`]: `"{{" 和 "}}"之間的數據鍵不能為空`,
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.error.regex`]: `數據鍵只能包含字母數字、空格或下劃線`,
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.error.same`]: `"{{" 和 "}}"之間的數據鍵不能與配置鍵相同`,
    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.error.duplicates`]: `請刪除"{{" 和 "}}"之間的重複數據鍵`,

    [`${settingPrefix}.${messagePrefix}.${editTemplatePrefix}.button.addTemplate`]: "添加模板",
}

const systemMessage = {
    [`${settingPrefix}.${messagePrefix}.${systemMessagePrefix}.table.text.title`]: "系統消息設置",
    [`${settingPrefix}.${messagePrefix}.${systemMessagePrefix}.table.text.subTitle`]: "為聊天路由創建系統消息集",
    [`${settingPrefix}.${messagePrefix}.${systemMessagePrefix}.table.placeholder.search`]: "搜索集",
    [`${settingPrefix}.${messagePrefix}.${systemMessagePrefix}.modal.warning`]: "確認刪除這條系統消息？",

    [`${settingPrefix}.${messagePrefix}.${systemMessagePrefix}.table.displayName.0`]: "語言",

    [`${settingPrefix}.${messagePrefix}.${systemMessagePrefix}.button.addSets`]: "添加集",
}

const editSystemMessage = {
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.text.title.edit`]: "編輯系統消息集",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.text.title.add`]: "添加系統消息集",

    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.section.text.title.setup`]: "系統消息設置",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.section.text.subTitle.setup`]: "為聊天路由設置一組新的系統消息",

    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.section.text.title.content`]: "系統消息內容",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.section.text.subTitle.content`]: "不同場景的草稿內容",

    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.setup.language`]: "語言",

    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.blockedConversationMessage`]: "被阻止的對話信息",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.closeConversationByAgentMessage`]: "通過代理消息關閉對話",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.closeConversationBySystemMessage`]: "通過系統消息關閉對話",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.closeConversationByUserMessage`]: "通過用戶消息關閉對話",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.conversationAssignedMessage`]: "對話無響應消息",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.conversationNoResponseMessage`]: "對話無響應消息",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.fileSizeOverLimitMessage`]: "文件大小超過限制消息",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.holidayAlert`]: "假期提醒",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.liveChatClientDisconnected`]: "實時聊天客戶端斷開連接",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.liveChatClientReconnected`]: "實時聊天客戶端已重新連接",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.messageTooLongMessage`]: "消息太長消息",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.noActiveAgentMessage`]: "沒有活動代理消息",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.openLiveChatMessage`]: "打開實時聊天消息",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.specialDate`]: "特殊日期",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.subscriptionGreetingMessage`]: "訂閱問候語",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.templateMessageBlockedByUser`]: "模板消息被用戶阻止",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.templateMessageFailed`]: "模板消息失敗",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.templateMessageSuccessSend`]: "模板消息發送成功",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.unsupportMediaTypeMessage`]: "不支持媒體類型消息",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.userSessionTimeout`]: "用戶會話超時",
    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.field.content.workingDateAlert`]: "工作日期提醒",

    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.expanable.text.systemLabels`]: "系統標籤",

    [`${settingPrefix}.${messagePrefix}.${editSystemMessagePrefix}.button.addSystemMessage`]: "添加系統消息",
}

const tagging = {
    [`${settingPrefix}.${taggingPrefix}.page.text.title`]: "標記設置",
    [`${settingPrefix}.${taggingPrefix}.page.text.subTitle`]: "標籤",

    [`${settingPrefix}.${taggingPrefix}.text.addTags`]: "在此處添加標籤。",
    [`${settingPrefix}.${taggingPrefix}.button.addTags`]: "添加標籤",
}

const wrapUp = {
    [`${settingPrefix}.${wrapUpPrefix}.page.text.title`]: "總結設置",

    [`${settingPrefix}.${wrapUpPrefix}.tab.title.wrapUp`]: "總結",
    [`${settingPrefix}.${wrapUpPrefix}.tab.title.autoEmail`]: "自動電子郵件",

    [`${settingPrefix}.${wrapUpPrefix}.table.text.title`]: "自動電子郵件設置",
    [`${settingPrefix}.${wrapUpPrefix}.table.text.subTitle`]: "為聊天后互動設置自動電子郵件模板",
    [`${settingPrefix}.${wrapUpPrefix}.button.addTemplates`]: "添加模板",

    [`${settingPrefix}.${wrapUpPrefix}.modal.warning`]: "確認刪除此模板？",

    [`${settingPrefix}.${wrapUpPrefix}.table.displayName.0`]: "顯示名稱",
    [`${settingPrefix}.${wrapUpPrefix}.table.displayName.1`]: "主題",

    [`${settingPrefix}.${wrapUpPrefix}.list.text.title`]: "總結設置",
    [`${settingPrefix}.${wrapUpPrefix}.list.text.subTitle`]: "為聊天后分析和報告創建和管理聊天目標",

    [`${settingPrefix}.${wrapUpPrefix}.text.title.edit`]: "編輯電子郵件模板",
    [`${settingPrefix}.${wrapUpPrefix}.text.title.add`]: "添加電子郵件模板",

    [`${settingPrefix}.${wrapUpPrefix}.section.text.title.setup`]: "郵件模板設置",
    [`${settingPrefix}.${wrapUpPrefix}.section.text.subTitle.setup`]: "為聊天后互動設置自動電子郵件模板",

    [`${settingPrefix}.${wrapUpPrefix}.field.setup.name`]: "顯示名稱",
    [`${settingPrefix}.${wrapUpPrefix}.field.setup.subject`]: "主題",
    [`${settingPrefix}.${wrapUpPrefix}.field.setup.content`]: "郵件內容",

    [`${settingPrefix}.${wrapUpPrefix}.button.addtemplate`]: "添加模板"
}

const chatAutomation = {
    [`${settingPrefix}.${chatAutomationPrefix}.table.text.title`]: "聊天自動化",
    [`${settingPrefix}.${chatAutomationPrefix}.table.text.subTitle`]: "在此處應用自動化。對於聊天分配，請轉到聊天路由。",
    [`${settingPrefix}.${chatAutomationPrefix}.table.placeholder.search`]: "搜索規則",

    [`${settingPrefix}.${chatAutomationPrefix}.modal.warning.disable`]: "你確認禁用這個自動化嗎？",
    [`${settingPrefix}.${chatAutomationPrefix}.modal.warning.remove`]: "你確認刪除這個自動化嗎？",

    [`${settingPrefix}.${chatAutomationPrefix}.table.displayName.0`]: "規則名稱",
    [`${settingPrefix}.${chatAutomationPrefix}.table.displayName.1`]: "表名",
    [`${settingPrefix}.${chatAutomationPrefix}.table.displayName.2`]: "條件",
    [`${settingPrefix}.${chatAutomationPrefix}.table.displayName.3`]: "動作",
    [`${settingPrefix}.${chatAutomationPrefix}.table.displayName.4`]: "有效",

    [`${settingPrefix}.${chatAutomationPrefix}.button.addRules`]: "添加規則",
}

const editChatAutomation = {
    [`${settingPrefix}.${editChatAutomationPrefix}.text.and`]: "和",

    [`${settingPrefix}.${editChatAutomationPrefix}.text.title.edit`]: "編輯自動化",
    [`${settingPrefix}.${editChatAutomationPrefix}.text.title.add`]: "新自動化",

    [`${settingPrefix}.${editChatAutomationPrefix}.input.placeholder`]: "輸入規則名稱",
    [`${settingPrefix}.${editChatAutomationPrefix}.text.defaultName`]: "新自動化",

    [`${settingPrefix}.${editChatAutomationPrefix}.button.activate`]: "激活",

    [`${settingPrefix}.${editChatAutomationPrefix}.field.attribute.tableName`]: "表名",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.attribute.executeType`]: "執行類型",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.attribute.executeActionTypeSet`]: "執行操作類型集",

    [`${settingPrefix}.${editChatAutomationPrefix}.field.criteria.fieldConfigId`]: "字段配置",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.criteria.conditionOperator`]: "條件運算符",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.criteria.value1`]: "值 1",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.criteria.value2`]: "值 2",

    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.base.type`]: "類型",

    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.update.retrigger`]: "重新觸發",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.update.fieldConfigId`]: "字段配置",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.update.value`]: "值",

    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.error.message`]: "錯誤消息",

    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.sendEmail.emailTemplateId`]: "電子郵件模板 ID",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.sendEmail.emailFieldType`]: "電子郵件字段類型",

    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.sendEmail.userId`]: "用戶 ID",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.sendEmail.userGroupId`]: "用戶組 ID",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.sendEmail.fieldConfigIdList`]: "字段配置列表",
    [`${settingPrefix}.${editChatAutomationPrefix}.field.action.sendEmail.toEmailList`]: "電子郵件（以 , 分隔）",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.start.title`]: "自動化開始",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.end.title`]: "自動化結束",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.addFlow.title`]: "添加流",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.addCriteria.title`]: "添加條件",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.addAction.title`]: "添加操作（且僅）",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.criteriaTop.title`]: "條件",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.actionTop.title`]: "動作",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.attribute.title`]: "流",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.criteria.edit.title`]: "條件 (如果)",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.action.edit.title`]: "行動（然后）",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.executeType.realtime`]: "實時",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.executeType.schedule`]: "日程",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.equalsTo`]: "等於",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.notEqualsTo`]: "不等於",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.greaterThan`]: "大於",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.greaterThanOrEquals`]: "大於或等於",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.lessThan`]: "小於",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.lessThanOrEqualsTo`]: "小於或等於",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.startWith`]: "開始於",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.endWith`]: "結束於",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.isNull`]: "為空",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.notNull`]: "不為空",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.between`]: "之間",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.contains`]: "包含",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.criteria.within`]: "之內",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.actionType.title`]: "動作類型",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.actionType.fieldUpdate`]: "字段更新",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.actionType.throwError`]: "拋出錯誤",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.actionType.sendEmail`]: "發送郵件",

    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.action.emailFieldTypeuserId`]: "用戶 ID",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.action.emailFieldTypeuserGroupId`]: "用戶組 ID",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.action.emailFieldTypetoOwner`]: "致所有者",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.action.emailFieldTypefieldConfigIdList`]: "字段配置 ID 列表",
    [`${settingPrefix}.${editChatAutomationPrefix}.flow.nameList.action.emailFieldTypetoEmailList`]: "至電子郵件列表",
}

const chatbot = {
    [`${settingPrefix}.${chatbotPrefix}.page.text.title`]: "聊天機器人設置",

    [`${settingPrefix}.${chatbotPrefix}.tab.title.chatbotAutomation`]: '聊天機器人自動化',
    [`${settingPrefix}.${chatbotPrefix}.tab.title.nodes`]: '節點',
    [`${settingPrefix}.${chatbotPrefix}.tab.title.variables`]: '變量',
    [`${settingPrefix}.${chatbotPrefix}.tab.title.labelling`]: '標籤',

    [`${settingPrefix}.${chatbotPrefix}.table.text.title`]: "聊天機器人流程",
    [`${settingPrefix}.${chatbotPrefix}.table.text.subTitle`]: "設置由聊天機器人發起的自動對話。",
    [`${settingPrefix}.${chatbotPrefix}.table.placeholder.search`]: "搜索流程",

    [`${settingPrefix}.${chatbotPrefix}.modal.warning.remove`]: "確認刪除此流？無法恢復。",

    [`${settingPrefix}.${chatbotPrefix}.table.displayName.0`]: "流程名稱",
    [`${settingPrefix}.${chatbotPrefix}.table.displayName.1`]: "類型",
    [`${settingPrefix}.${chatbotPrefix}.table.displayName.2`]: "版本",
    [`${settingPrefix}.${chatbotPrefix}.table.displayName.3`]: "更新於",

    [`${settingPrefix}.${chatbotPrefix}.button.createFlows`]: "創建流",

    [`${settingPrefix}.${chatbotPrefix}.nameList.flowType.draft`]: "草稿",
    [`${settingPrefix}.${chatbotPrefix}.nameList.flowType.released`]: "已發布",
}

const createOrCloneChatbot = {
    [`${settingPrefix}.${createOrCloneChatbotPrefix}.modal.page.create.text.title`]: "創建聊天機器人流程",
    [`${settingPrefix}.${createOrCloneChatbotPrefix}.field.create.name`]: "流名稱",

    [`${settingPrefix}.${createOrCloneChatbotPrefix}.modal.page.clone.text.title`]: "克隆聊天機器人流程",
    [`${settingPrefix}.${createOrCloneChatbotPrefix}.field.clone.name`]: "来自流",
    [`${settingPrefix}.${createOrCloneChatbotPrefix}.field.clone.version`]: "版本",
    [`${settingPrefix}.${createOrCloneChatbotPrefix}.field.clone.newFlowName`]: "流名稱",

    [`${settingPrefix}.${createOrCloneChatbotPrefix}.button.clone`]: "克隆",
}

const chatbotIconBar = {
    [`${settingPrefix}.${chatbotIconBarPrefix}.list`]: "List",
    [`${settingPrefix}.${chatbotIconBarPrefix}.keywords`]: "Keywords",
    [`${settingPrefix}.${chatbotIconBarPrefix}.parameter`]: "Parameter",
    [`${settingPrefix}.${chatbotIconBarPrefix}.entity`]: "Entity",
    [`${settingPrefix}.${chatbotIconBarPrefix}.response`]: "Response",
    [`${settingPrefix}.${chatbotIconBarPrefix}.action`]: "Action",
    
    [`${settingPrefix}.${chatbotIconBarPrefix}.edit.keywords`]: "Edit Keywords",
    [`${settingPrefix}.${chatbotIconBarPrefix}.edit.parameter`]: "Edit Parameter",
    [`${settingPrefix}.${chatbotIconBarPrefix}.edit.entity`]: "Edit Entity",
    [`${settingPrefix}.${chatbotIconBarPrefix}.edit.response`]: "Edit Response",
    [`${settingPrefix}.${chatbotIconBarPrefix}.edit.action`]: "Edit Action",
}

const editChatbot = {
    [`${settingPrefix}.${editChatbotPrefix}.page.extra.time`]: "最後更新於 - ",
    [`${settingPrefix}.${editChatbotPrefix}.page.placeholder.search`]: "搜索節點",

    [`${settingPrefix}.${editChatbotPrefix}.modal.title.import`]: "導入",

    [`${settingPrefix}.${editChatbotPrefix}.modal.warning.publish`]: "確認發布流程？",
    [`${settingPrefix}.${editChatbotPrefix}.modal.warning.remove`]: "確認刪除這個",
    [`${settingPrefix}.${editChatbotPrefix}.modal.warning.exitEdit`]: "確認取消編輯？",
    [`${settingPrefix}.${editChatbotPrefix}.modal.warning.exitCreate`]: "確認取消創建？",

    [`${settingPrefix}.${editChatbotPrefix}.text.search`]: "搜索",
    [`${settingPrefix}.${editChatbotPrefix}.text.new`]: "新建",

    [`${settingPrefix}.${editChatbotPrefix}.text.node`]: "節點",

    [`${settingPrefix}.${editChatbotPrefix}.nameList.setting.keyword`]: "關鍵字",
    [`${settingPrefix}.${editChatbotPrefix}.nameList.setting.entity`]: "實體",
    [`${settingPrefix}.${editChatbotPrefix}.nameList.setting.response`]: "回應",
    [`${settingPrefix}.${editChatbotPrefix}.nameList.setting.action`]: "動作",
    [`${settingPrefix}.${editChatbotPrefix}.nameList.setting.parameter`]: "參數",

    [`${settingPrefix}.${editChatbotPrefix}.button.import`]: "Import",
    [`${settingPrefix}.${editChatbotPrefix}.button.export`]: "Export",
    [`${settingPrefix}.${editChatbotPrefix}.button.preview`]: "預覽流程",
    [`${settingPrefix}.${editChatbotPrefix}.button.publish`]: "發布流程",

    [`${settingPrefix}.${editChatbotPrefix}.flow.start.title`]: "聊天開始",
    [`${settingPrefix}.${editChatbotPrefix}.flow.add.title`]: "添加節點",
    [`${settingPrefix}.${editChatbotPrefix}.flow.first.title`]: "第一個節點",

    [`${settingPrefix}.${editChatbotPrefix}.flow.preview.title`]: "流程預覽",
    [`${settingPrefix}.${editChatbotPrefix}.flow.preview.loading`]: "聊天機器人處理",
}

const editNode = {
    [`${settingPrefix}.${editNodePrefix}.text.title.edit`]: "編輯節點",
    [`${settingPrefix}.${editNodePrefix}.text.title.add`]: "創建節點",

    [`${settingPrefix}.${editNodePrefix}.section.text.title.setup`]: "節點設置",
    [`${settingPrefix}.${editNodePrefix}.section.text.subTitle.setup`]: "為聊天機器人自動化創建一個新節點",

    [`${settingPrefix}.${editNodePrefix}.section.text.title.conditions`]: "聊天條件",
    [`${settingPrefix}.${editNodePrefix}.section.text.subTitle.conditions`]: "設置傳入客戶對話的條件",

    [`${settingPrefix}.${editNodePrefix}.section.text.title.variable`]: "變量",
    [`${settingPrefix}.${editNodePrefix}.section.text.subTitle.variable`]: "設置全局變量",

    [`${settingPrefix}.${editNodePrefix}.section.text.title.response`]: "響應",
    [`${settingPrefix}.${editNodePrefix}.section.text.subTitle.response`]: "設置響應",

    [`${settingPrefix}.${editNodePrefix}.section.text.title.action`]: "動作",
    [`${settingPrefix}.${editNodePrefix}.section.text.subTitle.action`]: "設置滿足條件時的動作。",

    [`${settingPrefix}.${editNodePrefix}.field.setup.name`]: "節點名稱",
    [`${settingPrefix}.${editNodePrefix}.field.setup.priority`]: "優先級",
    [`${settingPrefix}.${editNodePrefix}.field.setup.language`]: "節點語言",
    [`${settingPrefix}.${editNodePrefix}.field.setup.isFirstNode`]: "必須是對話中的第一個節點",

    [`${settingPrefix}.${editNodePrefix}.field.conditions.conditionType`]: "條件類型",

    [`${settingPrefix}.${editNodePrefix}.field.variable.option`]: "選項",
    [`${settingPrefix}.${editNodePrefix}.field.externalAction.externalActionId`]: "動作",

    [`${settingPrefix}.${editNodePrefix}.field.extra.type`]: "類型",
    [`${settingPrefix}.${editNodePrefix}.field.extra.value`]: "值",
    [`${settingPrefix}.${editNodePrefix}.field.extra.parameterName`]: "變量名",

    [`${settingPrefix}.${editNodePrefix}.field.response.responseId`]: "響應消息",
    [`${settingPrefix}.${editNodePrefix}.field.response.responseType`]: "響應方式",

    [`${settingPrefix}.${editNodePrefix}.field.action.afterAction`]: "動作",
    [`${settingPrefix}.${editNodePrefix}.field.action.jumpNodeId`]: "跳轉到節點",

    [`${settingPrefix}.${editNodePrefix}.nameList.language.default`]: "節點語言",

    [`${settingPrefix}.${editNodePrefix}.nameList.conditionType.others`]: "其他",
    [`${settingPrefix}.${editNodePrefix}.nameList.conditionType.anyMessage`]: "任意短信",
    [`${settingPrefix}.${editNodePrefix}.nameList.conditionType.anyMedia`]: "任何媒體",

    [`${settingPrefix}.${editNodePrefix}.nameList.responseType.allMessage`]: "返回所有消息",
    [`${settingPrefix}.${editNodePrefix}.nameList.responseType.randomDisplay`]: "隨機顯示消息",

    [`${settingPrefix}.${editNodePrefix}.nameList.action.doNothing`]: "什麼都不做",
    [`${settingPrefix}.${editNodePrefix}.nameList.action.liveChat`]: "請求實時聊天",
    [`${settingPrefix}.${editNodePrefix}.nameList.action.jumpNode`]: "跳轉到節點",
    [`${settingPrefix}.${editNodePrefix}.nameList.action.runAction`]: "外部操作",

    [`${settingPrefix}.${editNodePrefix}.button.addCondition`]: "添加條件",
    [`${settingPrefix}.${editNodePrefix}.button.addVariable`]: "添加變量",
    [`${settingPrefix}.${editNodePrefix}.button.addAction`]: "添加外部操作",

    [`${settingPrefix}.${editNodePrefix}.expanable.text.condition`]: "條件",
    [`${settingPrefix}.${editNodePrefix}.expanable.text.variable`]: "變量",
    [`${settingPrefix}.${editNodePrefix}.expanable.text.action`]: "動作",

    [`${settingPrefix}.${editNodePrefix}.text.response.preview`]: "響應消息",

    [`${settingPrefix}.${editNodePrefix}.button.createNode`]: "創建節點",
    [`${settingPrefix}.${editNodePrefix}.button.updateNode`]: "更新節點",
    [`${settingPrefix}.${editNodePrefix}.button.editNode`]: "編輯節點",
    [`${settingPrefix}.${editNodePrefix}.button.removeNode`]: "刪除節點",
}

const editKeyword = {
    [`${settingPrefix}.${editKeywordPrefix}.text.title.edit`]: "編輯關鍵詞",
    [`${settingPrefix}.${editKeywordPrefix}.text.title.add`]: "新關鍵詞",

    [`${settingPrefix}.${editKeywordPrefix}.section.text.title.setup`]: "關鍵字設置",
    [`${settingPrefix}.${editKeywordPrefix}.section.text.subTitle.setup`]: "為聊天機器人自動化創建新關鍵字",

    [`${settingPrefix}.${editKeywordPrefix}.section.text.title.list`]: "關鍵字列表",
    [`${settingPrefix}.${editKeywordPrefix}.section.text.subTitle.list`]: "設置關鍵字列表",

    [`${settingPrefix}.${editKeywordPrefix}.field.setup.name`]: "關鍵詞名稱",
    [`${settingPrefix}.${editKeywordPrefix}.field.setup.list`]: "值",

    [`${settingPrefix}.${editKeywordPrefix}.expanable.text.List`]: "關鍵字列表",

    [`${settingPrefix}.${editKeywordPrefix}.button.addkeyword`]: "添加關鍵字",

    [`${settingPrefix}.${editKeywordPrefix}.button.createKeyword`]: "創建關鍵字",
    [`${settingPrefix}.${editKeywordPrefix}.button.updateKeyword`]: "更新節點",
    [`${settingPrefix}.${editKeywordPrefix}.button.cloneKeyword`]: "克隆關鍵字",
    [`${settingPrefix}.${editKeywordPrefix}.button.editKeyword`]: "編輯關鍵字",
}

const editEntity = {
    [`${settingPrefix}.${editEntityPrefix}.text.title.edit`]: "編輯實體",
    [`${settingPrefix}.${editEntityPrefix}.text.title.add`]: "新實體",

    [`${settingPrefix}.${editEntityPrefix}.section.text.title.setup`]: "實體設置",
    [`${settingPrefix}.${editEntityPrefix}.section.text.subTitle.setup`]: "為聊天機器人自動化創建一個新實體",

    [`${settingPrefix}.${editEntityPrefix}.section.text.title.option`]: "實體選項",
    [`${settingPrefix}.${editEntityPrefix}.section.text.subTitle.option`]: "設置實體選項",

    [`${settingPrefix}.${editEntityPrefix}.section.text.title.regex`]: "實體正則表達式",
    [`${settingPrefix}.${editEntityPrefix}.section.text.subTitle.regex`]: "設置實體正則表達式",

    [`${settingPrefix}.${editEntityPrefix}.section.text.title.test`]: "實體正則表達式測試",
    [`${settingPrefix}.${editEntityPrefix}.section.text.subTitle.test`]: "測試實體正則表達式",

    [`${settingPrefix}.${editEntityPrefix}.field.setup.name`]: "實體名稱",
    [`${settingPrefix}.${editEntityPrefix}.field.setup.entityType`]: "實體類型",

    [`${settingPrefix}.${editEntityPrefix}.field.regex.name`]: "正則表達式名稱",
    [`${settingPrefix}.${editEntityPrefix}.field.regex.value`]: "正則表達式值",

    [`${settingPrefix}.${editEntityPrefix}.field.option.name`]: "選項名稱",
    [`${settingPrefix}.${editEntityPrefix}.field.option.value`]: "選項",

    [`${settingPrefix}.${editEntityPrefix}.table.placeholder.test`]: "文本輸入",

    [`${settingPrefix}.${editEntityPrefix}.table.displayName.0`]: "名稱",
    [`${settingPrefix}.${editEntityPrefix}.table.displayName.1`]: "正則表達式",
    [`${settingPrefix}.${editEntityPrefix}.table.displayName.2`]: "結果",

    [`${settingPrefix}.${editEntityPrefix}.nameList.entityType.regex`]: "匹配正則表達式",
    [`${settingPrefix}.${editEntityPrefix}.nameList.entityType.option`]: "匹配選項列表",

    [`${settingPrefix}.${editEntityPrefix}.expanable.text.regexlist`]: "實體正則表達式",
    [`${settingPrefix}.${editEntityPrefix}.expanable.text.regex`]: "正則表達式",
    [`${settingPrefix}.${editEntityPrefix}.expanable.text.optionlist`]: "實體選項",
    [`${settingPrefix}.${editEntityPrefix}.expanable.text.option`]: "選項",

    [`${settingPrefix}.${editEntityPrefix}.button.addRegex`]: "添加正則表達式",
    [`${settingPrefix}.${editEntityPrefix}.button.addOptionList`]: "添加選項列表",
    [`${settingPrefix}.${editEntityPrefix}.button.addOption`]: "添加選項",

    [`${settingPrefix}.${editEntityPrefix}.button.createEntity`]: "創建實體",
    [`${settingPrefix}.${editEntityPrefix}.button.updateEntity`]: "更新實體",
    [`${settingPrefix}.${editEntityPrefix}.button.editEntity`]: "編輯實體",
    [`${settingPrefix}.${editEntityPrefix}.button.cloneEntity`]: "克隆實體",

    [`${settingPrefix}.${editEntityPrefix}.duplicates.regex`]: "正則表達式名稱已經存在",
    [`${settingPrefix}.${editEntityPrefix}.duplicates.option`]: "選項已經存在",
}

const editResponse = {
    [`${settingPrefix}.${editResponsePrefix}.text.title.edit`]: "編輯回复",
    [`${settingPrefix}.${editResponsePrefix}.text.title.add`]: "新回复",

    [`${settingPrefix}.${editResponsePrefix}.section.text.title.setup`]: "響應設置",
    [`${settingPrefix}.${editResponsePrefix}.section.text.subTitle.setup`]: "為聊天機器人自動化創建一個新的響應",

    [`${settingPrefix}.${editResponsePrefix}.section.text.title.message`]: "回复信息",
    [`${settingPrefix}.${editResponsePrefix}.section.text.subTitle.message`]: "設置響應消息",

    [`${settingPrefix}.${editResponsePrefix}.section.text.title.option`]: "響應選項",
    [`${settingPrefix}.${editResponsePrefix}.section.text.subTitle.option`]: "設置響應選項",

    [`${settingPrefix}.${editResponsePrefix}.field.setup.name`]: "響應名稱",
    [`${settingPrefix}.${editResponsePrefix}.field.setup.attachmentId`]: "附件",

    [`${settingPrefix}.${editResponsePrefix}.field.message.value`]: "響應消息",

    [`${settingPrefix}.${editResponsePrefix}.field.option.name`]: "選項名稱",
    [`${settingPrefix}.${editResponsePrefix}.field.option.value`]: "選項值",

    [`${settingPrefix}.${editResponsePrefix}.expanable.text.message`]: "響應消息",
    [`${settingPrefix}.${editResponsePrefix}.expanable.text.optionList`]: "響應選項",
    [`${settingPrefix}.${editResponsePrefix}.expanable.text.option`]: "動作",

    [`${settingPrefix}.${editResponsePrefix}.button.addMessage`]: "添加消息",
    [`${settingPrefix}.${editResponsePrefix}.button.addOption`]: "添加選項",

    [`${settingPrefix}.${editResponsePrefix}.button.createResponse`]: "創建響應",
    [`${settingPrefix}.${editResponsePrefix}.button.updateResponse`]: "更新響應",
    [`${settingPrefix}.${editResponsePrefix}.button.editResponse`]: "編輯回复",
    [`${settingPrefix}.${editResponsePrefix}.button.cloneResponse`]: "克隆響應",
}

const editAction = {
    [`${settingPrefix}.${editActionPrefix}.text.title.edit`]: "編輯操作",
    [`${settingPrefix}.${editActionPrefix}.text.title.add`]: "新動作",

    [`${settingPrefix}.${editActionPrefix}.section.text.title.setup`]: "動作設置",
    [`${settingPrefix}.${editActionPrefix}.section.text.subTitle.setup`]: "為聊天機器人自動化創建一個新的動作",

    [`${settingPrefix}.${editActionPrefix}.section.text.title.conditions.get`]: "獲取條件",
    [`${settingPrefix}.${editActionPrefix}.section.text.subTitle.conditions.get`]: "設置獲取條件",

    [`${settingPrefix}.${editActionPrefix}.section.text.title.conditions.insert`]: "插入條件",
    [`${settingPrefix}.${editActionPrefix}.section.text.subTitle.conditions.insert`]: "設置插入條件",

    [`${settingPrefix}.${editActionPrefix}.section.text.title.conditions.update`]: "更新條件",
    [`${settingPrefix}.${editActionPrefix}.section.text.subTitle.conditions.update`]: "設置更新條件",

    [`${settingPrefix}.${editActionPrefix}.section.text.title.conditions.options`]: "選項條件",
    [`${settingPrefix}.${editActionPrefix}.section.text.subTitle.conditions.options`]: "設置選項條件",

    [`${settingPrefix}.${editActionPrefix}.section.text.title.conditions.identifyContact`]: "識別聯繫條件",
    [`${settingPrefix}.${editActionPrefix}.section.text.subTitle.conditions.identifyContact`]: "設置識別聯繫人條件",

    [`${settingPrefix}.${editActionPrefix}.section.text.title.response`]: "動作響應",
    [`${settingPrefix}.${editActionPrefix}.section.text.subTitle.response`]: "設置動作響應",

    [`${settingPrefix}.${editActionPrefix}.field.setup.name`]: "動作名稱",
    [`${settingPrefix}.${editActionPrefix}.field.setup.action`]: "動作類型",
    [`${settingPrefix}.${editActionPrefix}.field.setup.targetTable`]: "目標表",

    [`${settingPrefix}.${editActionPrefix}.field.conditions.queryString`]: "查詢字符串",
    [`${settingPrefix}.${editActionPrefix}.field.conditions.recordCount`]: "記錄數",
    [`${settingPrefix}.${editActionPrefix}.field.conditions.resultAsOptions`]: "結果作為選項",
    [`${settingPrefix}.${editActionPrefix}.field.conditions.optionLabelField`]: "選項標籤",
    [`${settingPrefix}.${editActionPrefix}.field.conditions.optionValueField`]: "選項值",
    [`${settingPrefix}.${editActionPrefix}.field.conditions.mediaParameterName`]: "媒體參數名稱",
    [`${settingPrefix}.${editActionPrefix}.field.conditions.optionTableField`]: "選項目標表",

    [`${settingPrefix}.${editActionPrefix}.field.conditions.fieldList.name`]: "返回字段名稱",
    [`${settingPrefix}.${editActionPrefix}.field.conditions.inputField.name`]: "輸入字段名稱",
    [`${settingPrefix}.${editActionPrefix}.field.conditions.inputField.value`]: "輸入字段值",

    [`${settingPrefix}.${editActionPrefix}.field.response.responseId`]: "響應",
    [`${settingPrefix}.${editActionPrefix}.field.response.failedResponseId`]: "響應失敗",
    [`${settingPrefix}.${editActionPrefix}.field.response.additional.condition`]: "響應",
    [`${settingPrefix}.${editActionPrefix}.field.response.additional.value`]: "響應失敗",

    [`${settingPrefix}.${editActionPrefix}.nameList.action.get`]: "獲取",
    [`${settingPrefix}.${editActionPrefix}.nameList.action.insert`]: "插入",
    [`${settingPrefix}.${editActionPrefix}.nameList.action.update`]: "更新",
    [`${settingPrefix}.${editActionPrefix}.nameList.action.options`]: "選項",
    [`${settingPrefix}.${editActionPrefix}.nameList.action.identifyContact`]: "識別聯繫人",
    [`${settingPrefix}.${editActionPrefix}.nameList.action.uploadAttachment`]: "上傳附件",

    [`${settingPrefix}.${editActionPrefix}.nameList.additional.condition.recordNotFound`]: "記錄未找到",
    [`${settingPrefix}.${editActionPrefix}.nameList.additional.condition.insertFailed`]: "插入失敗",
    [`${settingPrefix}.${editActionPrefix}.nameList.additional.condition.updateFailed`]: "更新失敗",
    [`${settingPrefix}.${editActionPrefix}.nameList.additional.condition.uploadFailed`]: "上傳失敗",    
    
    [`${settingPrefix}.${editActionPrefix}.expanable.text.fieldList`]: "返回字段列表",
    [`${settingPrefix}.${editActionPrefix}.expanable.text.inputField`]: "輸入字段列表",
    [`${settingPrefix}.${editActionPrefix}.expanable.text.additional`]: "額外的失敗響應",

    [`${settingPrefix}.${editActionPrefix}.expanable.text.inputField.defaultValue`]: "默認值",
    [`${settingPrefix}.${editActionPrefix}.expanable.text.additional.response`]: "條件響應",

    [`${settingPrefix}.${editActionPrefix}.button.addFieldList`]: "添加返回字段",
    [`${settingPrefix}.${editActionPrefix}.button.addInputField`]: "添加輸入字段",
    [`${settingPrefix}.${editActionPrefix}.button.addAdditional`]: "添加失敗響應",

    [`${settingPrefix}.${editActionPrefix}.button.createAction`]: "創建動作",
    [`${settingPrefix}.${editActionPrefix}.button.updateAction`]: "更新操作",
    [`${settingPrefix}.${editActionPrefix}.button.editAction`]: "編輯操作",
    [`${settingPrefix}.${editActionPrefix}.button.cloneAction`]: "克隆操作",

    [`${settingPrefix}.${editActionPrefix}.duplicates.inputField`]: "輸入字段名稱已存在",
    [`${settingPrefix}.${editActionPrefix}.duplicates.additional`]: "條件已經存在",    
}

const editParameter = {
    [`${settingPrefix}.${editParameterPrefix}.text.title.edit`]: "編輯參數",
    [`${settingPrefix}.${editParameterPrefix}.text.title.add`]: "新參數",

    [`${settingPrefix}.${editParameterPrefix}.section.text.title.setup`]: "參數設置",
    [`${settingPrefix}.${editParameterPrefix}.section.text.subTitle.setup`]: "為聊天機器人自動化創建一個新參數",

    [`${settingPrefix}.${editParameterPrefix}.field.setup.key`]: "參數鍵",
    [`${settingPrefix}.${editParameterPrefix}.field.setup.type`]: "類型",
    [`${settingPrefix}.${editParameterPrefix}.field.setup.value`]: "值",

    [`${settingPrefix}.${editParameterPrefix}.nameList.type.input`]: "輸入",
    [`${settingPrefix}.${editParameterPrefix}.nameList.type.keyword`]: "關鍵字",
    [`${settingPrefix}.${editParameterPrefix}.nameList.type.entity`]: "實體",
    [`${settingPrefix}.${editParameterPrefix}.nameList.type.option`]: "選項",
    [`${settingPrefix}.${editParameterPrefix}.nameList.type.media`]: "媒體",
    [`${settingPrefix}.${editParameterPrefix}.nameList.type.custom`]: "自定義",

    [`${settingPrefix}.${editParameterPrefix}.button.createParameter`]: "創建參數",
    [`${settingPrefix}.${editParameterPrefix}.button.updateParameter`]: "更新參數",
    [`${settingPrefix}.${editParameterPrefix}.button.editParameter`]: "編輯參數",
    [`${settingPrefix}.${editParameterPrefix}.button.cloneParameter`]: "克隆參數",
}

const openai = {
    [`${settingPrefix}.${openaiPrefix}.table.text.title`]: "OpenAI 機器人",
    [`${settingPrefix}.${openaiPrefix}.table.text.subTitle`]: "設置由 openAI 發起的自動對話。",
    [`${settingPrefix}.${openaiPrefix}.table.placeholder.search`]: "搜索機器人",

    [`${settingPrefix}.${openaiPrefix}.dropdown.lang.search`]: "搜索語言",
    [`${settingPrefix}.${openaiPrefix}.dropdown.lang.add`]: "添加語言",

    [`${settingPrefix}.${openaiPrefix}.modal.warning.remove`]: "確認刪除這個機器人？它無法恢復。",
    [`${settingPrefix}.${openaiPrefix}.modal.warning.removeConfig`]: "確認刪除這個支持的語言？它無法恢復。",

    [`${settingPrefix}.${openaiPrefix}.table.displayName.0`]: "機器人名稱",
    [`${settingPrefix}.${openaiPrefix}.table.displayName.1`]: "支持的語言",

    [`${settingPrefix}.${openaiPrefix}.button.createBots`]: "創建機器人",

    [`${settingPrefix}.${openaiPrefix}.modal.page.text.title`]: "創建 OPENAI 機器人",
    [`${settingPrefix}.${openaiPrefix}.field.create.name`]: "機器人名稱",    
}

const editOpenai = {
    [`${settingPrefix}.${editOpenaiPrefix}.flow.preview.title`]: "機器人預覽",
    [`${settingPrefix}.${editOpenaiPrefix}.flow.preview.loading`]: "OpenAI 處理",

    [`${settingPrefix}.${editOpenaiPrefix}.button.preview`]: "預覽機器人",

    [`${settingPrefix}.${editOpenaiPrefix}.progress.0`]: "常規配置",
    [`${settingPrefix}.${editOpenaiPrefix}.progress.1`]: "個性",
    [`${settingPrefix}.${editOpenaiPrefix}.progress.2`]: "服務",

    [`${settingPrefix}.${editOpenaiPrefix}.modal.warning`]: "您確定要退出創建配置嗎？\n 將不會保存更改。",
    [`${settingPrefix}.${editOpenaiPrefix}.modal.warning.remove`]: "確認刪除此服務？",

    [`${settingPrefix}.${editOpenaiPrefix}.section.text.title.config`]: "常規配置",
    [`${settingPrefix}.${editOpenaiPrefix}.section.text.subTitle.config`]: "OpenAI 聊天機器人的一般配置",

    [`${settingPrefix}.${editOpenaiPrefix}.section.text.title.personalities`]: "個性",
    [`${settingPrefix}.${editOpenaiPrefix}.section.text.subTitle.personalities`]: "設置 OpenAI 聊天機器人的個性",

    [`${settingPrefix}.${editOpenaiPrefix}.table.text.title`]: "服務",
    [`${settingPrefix}.${editOpenaiPrefix}.table.text.subTitle`]: "設置 OpenAI 聊天機器人的服務",

    [`${settingPrefix}.${editOpenaiPrefix}.table.displayName.0`]: "服務名稱",
    [`${settingPrefix}.${editOpenaiPrefix}.table.displayName.1`]: "表名",
    [`${settingPrefix}.${editOpenaiPrefix}.table.displayName.2`]: "服務類型",    

    [`${settingPrefix}.${editOpenaiPrefix}.section.text.title.service.config`]: "服務通用配置",
    [`${settingPrefix}.${editOpenaiPrefix}.section.text.subTitle.service.config`]: "OpenAI 服務的一般配置",

    [`${settingPrefix}.${editOpenaiPrefix}.section.text.title.service.response`]: "服務響應配置",
    [`${settingPrefix}.${editOpenaiPrefix}.section.text.subTitle.service.response`]: "OpenAI 服務的響應配置",

    [`${settingPrefix}.${editOpenaiPrefix}.section.text.title.service.predicate`]: "謂詞配置",
    [`${settingPrefix}.${editOpenaiPrefix}.section.text.subTitle.service.predicate`]: "OpenAI 服務的謂詞配置",

    [`${settingPrefix}.${editOpenaiPrefix}.section.text.title.service.field`]: "字段配置",
    [`${settingPrefix}.${editOpenaiPrefix}.section.text.subTitle.service.field`]: "OpenAI 服務的字段配置",
    
    [`${settingPrefix}.${editOpenaiPrefix}.field.select.useExistingConfig`]: "使用現有配置",
    [`${settingPrefix}.${editOpenaiPrefix}.field.select.id`]: "聊天機器人配置",

    [`${settingPrefix}.${editOpenaiPrefix}.field.config.name`]: "配置名稱",
    [`${settingPrefix}.${editOpenaiPrefix}.field.config.chatbotName`]: "AI 聊天機器人名稱",
    [`${settingPrefix}.${editOpenaiPrefix}.field.config.coreTemplateId`]: "核心模板",
   
    [`${settingPrefix}.${editOpenaiPrefix}.field.personality.personality`]: "個性",

    [`${settingPrefix}.${editOpenaiPrefix}.text.title.edit`]: "編輯服務",
    [`${settingPrefix}.${editOpenaiPrefix}.text.title.add`]: "新服務",

    [`${settingPrefix}.${editOpenaiPrefix}.field.service.name`]: "服務名稱",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.tableName`]: "表名",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.query`]: "額外查詢",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.type`]: "服務類型",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.serviceTemplateEntryId`]: "服務模板入口",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.serviceTemplateId`]: "服務模板",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.successResponse`]: "成功響應",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.failedResponse`]: "響應失敗",

    [`${settingPrefix}.${editOpenaiPrefix}.field.service.item.name`]: "顯示名稱",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.item.apiName`]: "API 名稱",

    [`${settingPrefix}.${editOpenaiPrefix}.field.service.predicate.type`]: "謂詞類型",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.predicate.operator`]: "謂詞運算符",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.predicate.defaultValue`]: "默認值",

    [`${settingPrefix}.${editOpenaiPrefix}.field.service.field.valueType`]: "字段值類型",
    [`${settingPrefix}.${editOpenaiPrefix}.field.service.field.value`]: "選項",
    
    [`${settingPrefix}.${editOpenaiPrefix}.nameList.personality.polite`]: "禮貌",
    [`${settingPrefix}.${editOpenaiPrefix}.nameList.personality.proactive`]: "積極主動的",

    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.query`]: "查詢記錄",
    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.create`]: "創建記錄",

    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.predicate.type.text`]: "文本",
    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.predicate.type.number`]: "數字",

    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.predicate.operator.equal`]: "等於",
    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.predicate.operator.greaterThan`]: "大於",
    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.predicate.operator.greaterThanOrEqualTo`]: "大於或等於",
    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.predicate.operator.lessThan`]: "小於",
    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.predicate.operator.lessThanOrEqualTo`]: "小於或等於",

    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.field.type.text`]: "文本",
    [`${settingPrefix}.${editOpenaiPrefix}.nameList.service.field.type.picklist`]: "選擇列表",
    
    [`${settingPrefix}.${editOpenaiPrefix}.expanable.text.personality`]: "個性",
    [`${settingPrefix}.${editOpenaiPrefix}.expanable.text.service.field`]: "字段",
    [`${settingPrefix}.${editOpenaiPrefix}.expanable.text.service.predicate`]: "謂詞",

    [`${settingPrefix}.${editOpenaiPrefix}.button.addPersonality`]: "添加個性",

    [`${settingPrefix}.${editOpenaiPrefix}.button.addPredicates`]: "添加謂詞",
    [`${settingPrefix}.${editOpenaiPrefix}.button.addFields`]: "添加字段",
    [`${settingPrefix}.${editOpenaiPrefix}.button.addOption`]: "添加選項",
   
    [`${settingPrefix}.${editOpenaiPrefix}.button.addService`]: "添加服務",
    [`${settingPrefix}.${editOpenaiPrefix}.button.saveService`]: "保存服務",
    [`${settingPrefix}.${editOpenaiPrefix}.button.removeService`]: "刪除服務",

    [`${settingPrefix}.${editOpenaiPrefix}.button.addServices`]: "添加服務",    
}

const livechatSetting = {
    [`${settingPrefix}.${livechatSettingPrefix}.page.text.title`]: "實時聊天設置",

    [`${settingPrefix}.${livechatSettingPrefix}.modal.warning`]: "確認刪除這個聊天前表單？",

    [`${settingPrefix}.${livechatSettingPrefix}.modal.title.copy`]: "COPY PRE-CHAT SCRIPT",
    [`${settingPrefix}.${livechatSettingPrefix}.modal.subTitle.copy`]: "將此腳本添加到 <body></body>",

    [`${settingPrefix}.${livechatSettingPrefix}.tab.title.prechatForm`]: '聊天前表格',

    [`${settingPrefix}.${livechatSettingPrefix}.table.text.title`]: "聊天前表單設置",
    [`${settingPrefix}.${livechatSettingPrefix}.table.text.subTitle`]: "管理實時聊天機器人的預聊天表單",
    [`${settingPrefix}.${livechatSettingPrefix}.table.placeholder.search`]: "搜索表單",

    [`${settingPrefix}.${livechatSettingPrefix}.table.displayName.0`]: "表單名稱",
    [`${settingPrefix}.${livechatSettingPrefix}.table.displayName.1`]: "語言",

    [`${settingPrefix}.${livechatSettingPrefix}.text.preview`]: "表單預覽",
    [`${settingPrefix}.${livechatSettingPrefix}.text.preview.tilte`]: "Live Chat",
    [`${settingPrefix}.${livechatSettingPrefix}.text.preview.empty`]: "請草擬模板內容",
    [`${settingPrefix}.${livechatSettingPrefix}.text.preview.button`]: "開始聊天",

    [`${settingPrefix}.${livechatSettingPrefix}.button.addForms`]: "添加表單",

    [`${settingPrefix}.${livechatSettingPrefix}.modal.page.clone.text.title`]: "克隆聊天前表格",
    [`${settingPrefix}.${livechatSettingPrefix}.field.settings.name`]: "新表單名稱",
}

const editLivechatSetting = {
    [`${settingPrefix}.${editLivechatSettingPrefix}.text.title.edit`]: "編輯聊天前表格",
    [`${settingPrefix}.${editLivechatSettingPrefix}.text.title.add`]: "添加聊天前表格",

    [`${settingPrefix}.${editLivechatSettingPrefix}.modal.warning`]: "確認刪除該字段？",

    [`${settingPrefix}.${editLivechatSettingPrefix}.progress.0`]: "表單設置",
    [`${settingPrefix}.${editLivechatSettingPrefix}.progress.1`]: "表單內容",
    [`${settingPrefix}.${editLivechatSettingPrefix}.progress.2`]: "表單標籤設置",
    [`${settingPrefix}.${editLivechatSettingPrefix}.progress.3`]: "表單顯示頻道",

    [`${settingPrefix}.${editLivechatSettingPrefix}.section.text.title.settings`]: "表單設置",
    [`${settingPrefix}.${editLivechatSettingPrefix}.section.text.subTitle.settings`]: "為聊天轉移設置聊天前表單",

    [`${settingPrefix}.${editLivechatSettingPrefix}.section.text.title.content`]: "表單內容",
    [`${settingPrefix}.${editLivechatSettingPrefix}.section.text.subTitle.content`]: "為客戶提交添加字段",

    [`${settingPrefix}.${editLivechatSettingPrefix}.section.text.title.label`]: "表單標籤設置",
    [`${settingPrefix}.${editLivechatSettingPrefix}.section.text.subTitle.label`]: "設置聊天前表單標籤",

    [`${settingPrefix}.${editLivechatSettingPrefix}.section.text.title.channel`]: "表單顯示頻道",
    [`${settingPrefix}.${editLivechatSettingPrefix}.section.text.subTitle.channel`]: "設置聊天前表單顯示通道",
    
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.settings.name`]: "表單名稱",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.settings.language`]: "語言",

    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.apiName`]: "字段 API",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.displayName`]: "顯示名稱",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.type`]: "字段類型",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.length`]: "文字長度",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.regex`]: "正則表達式",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.option`]: "選項",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.isName`]: "是名稱字段",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.isRequired`]: "必填字段",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.content.errorMsg`]: "錯誤信息",
    
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.attachmentNotSupportWarning`]: "附件不支持警告",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.attachmentOverSizeWarning`]: "附件超大警告",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.attachmentPanelTitle`]: "附件面板標題",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.attachmentTooltip`]: "附件工具提示",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.cancelButton`]: "取消按鈕",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.closeConversationTitle`]: "關閉對話標題",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.confirmButton`]: "確認按鈕",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.conversationPanelTitle`]: "對話面板標題",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.conversationTitle`]: "對話標題",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.emojiTooltip`]: "表情工具提示",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.feedbackCommentTitle`]: "反饋評論標題",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.feedbackPanelTitle`]: "反饋面板標題",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.firstMessage`]: "第一條消息",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.invalidMessageInput`]: "無效消息輸入",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.liveChatTitle`]: "實時聊天標題",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.messageSendFailedWarning`]: "消息發送失敗警告",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.prechatRequiredFieldWarning`]: "預聊天必填字段警告",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.searchKnowledgeBasedTitle`]: "搜索基於知識的標題",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.serverReconnectingWarning`]: "服務器重新連接警告",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.serverUnavailableWarning`]: "服務器不可用警告",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.startConversationButton`]: "開始對話按鈕",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.label.typeMessagePlaceholder`]: "鍵入消息佔位符",
    
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.channel.type`]: "頻道類型",
    [`${settingPrefix}.${editLivechatSettingPrefix}.field.channel.channelAccount`]: "頻道賬戶",

    [`${settingPrefix}.${editLivechatSettingPrefix}.nameList.content.text`]: "文本",
    [`${settingPrefix}.${editLivechatSettingPrefix}.nameList.content.number`]: "數字",
    [`${settingPrefix}.${editLivechatSettingPrefix}.nameList.content.textarea`]: "文本區域",
    [`${settingPrefix}.${editLivechatSettingPrefix}.nameList.content.select`]: "下拉選擇",
    [`${settingPrefix}.${editLivechatSettingPrefix}.nameList.content.checkbox`]: "複選框",
    [`${settingPrefix}.${editLivechatSettingPrefix}.nameList.content.link`]: "超鏈接",

    [`${settingPrefix}.${editLivechatSettingPrefix}.expanable.text.content`]: "表單內容",
    [`${settingPrefix}.${editLivechatSettingPrefix}.expanable.text.label`]: "表單標籤",
    [`${settingPrefix}.${editLivechatSettingPrefix}.expanable.text.channel`]: "顯示頻道",
   
    [`${settingPrefix}.${editLivechatSettingPrefix}.expanable.text.content.item`]: "字段",
    [`${settingPrefix}.${editLivechatSettingPrefix}.expanable.text.channel.item`]: "頻道",

    [`${settingPrefix}.${editLivechatSettingPrefix}.button.addField`]: "添加字段",
    [`${settingPrefix}.${editLivechatSettingPrefix}.button.addAccount`]: "添加頻道帳戶",

    [`${settingPrefix}.${editLivechatSettingPrefix}.button.addOption`]: "添加選項",    
}

export const setting = {
    ...layout,

    ...business,
    ...businessGeneralSetting,
    ...planSubscription,

    ...userList,

    ...system,
    ...systemGeneralSetting,
    ...fieldConfig,
    ...editfieldConfig,

    ...role,
    ...roleSetting,

    ...channelAccount,
    ...editChannelAccount,

    ...chatGroup,

    ...chatRouting,
    ...editChatRouting,

    ...message,
    ...quickText,
    ...editQuickText,

    ...template,
    ...editTemplate,

    ...systemMessage,
    ...editSystemMessage,

    ...tagging,
    ...wrapUp,

    ...chatAutomation,
    ...editChatAutomation,

    ...chatbot,
    ...createOrCloneChatbot,
    ...chatbotIconBar,
    ...editChatbot,
    ...editNode,
    ...editKeyword,
    ...editEntity,
    ...editResponse,
    ...editAction,
    ...editParameter,

    ...openai,
    ...editOpenai,

    ...livechatSetting,
    ...editLivechatSetting,
}