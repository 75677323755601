import {httpPost} from 'lib/requests';

const getParameter = (data, authToken) => {
    return httpPost('chatbot/parameter/get', data, authToken);
}

const bulkParameter = (data, authToken) => {
    return httpPost('chatbot/parameter/bulk', data, authToken);
}

export {
    getParameter,
    bulkParameter,
};