import PropTypes from 'prop-types';
import Cancel from 'components/Icon/Cancel';

const Title = ({
    className = "",
    title = "",
    closeAction = null
}) => (
    <div className={`flex justify-between items-center pl-7 pr-5 pt-5 text-blue04 text-xs ${className}`}>
        <div className="truncate">{title}</div>
        {closeAction ? <div className="cursor-pointer" onClick={closeAction}><Cancel/></div> : null}        
    </div>
)

Title.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    closeAction: PropTypes.func,
};

export default Title