
import PageTitle from 'components/PageTitle';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import Action from './Action';
import ContactItem from './ContactItem';
import Footer from './Footer';
import Header from './Header';
import "./index.css";
import Item from './Item';
import Row from './Row';
import StatusItem from './StatusItem';
import TextItem from './TextItem';
import TimeItem from './TimeItem';

const TableHeight = Object.freeze({
    "default": "h-[calc(100%-71px)]",
    "hideFooter": "h-full",
})

const Table = 
({
    title = "",
    className = "",
    background = "bg-grey01 shadow-[inset_0_3px_10px_-3px_rgb(0,0,0,0.05)]",
    minTableWidth = "",
    tableHeight = TableHeight.default,
    children = null,
    resizeFactor = -1,
    pageChangeFactor = 0,
    getTableSize = null,
    hideFooter = false,
}) => {

    const tableFrameRef = useRef(null);
    const tableRef = useRef(null);

    useEffect(() => {
        if(tableRef.current && getTableSize)
            getTableSize(tableRef.current.getBoundingClientRect())
    }, [resizeFactor, getTableSize])

    useEffect(() => {
        console.log(pageChangeFactor)
        if(tableFrameRef.current && pageChangeFactor){
            console.log(tableFrameRef.current)
            tableFrameRef.current.scrollTo({top: 0})
        }
    }, [pageChangeFactor])

    return (
        <>
            <div className={`flex flex-col w-full h-full ${background} ${className}`}>
                <div className="flex justify-between items-center w-full h-[78px]">
                    {title ? <PageTitle title={title}/> : children[0]}
                    {children[1]}
                </div>
                <div className="flex flex-col w-full h-[calc(100%-78px)]">
                    {children[5] ? children[5] : null}
                    {/* <div className={`pageTable drop-shadow w-full ${hideFooter ? TableHeight.hideFooter : tableHeight} overflow-y-auto overflow-x-hidden rounded-[5px] bg-white`}> */}
                    <div className={`pageTable drop-shadow w-full ${hideFooter ? TableHeight.hideFooter : tableHeight} overflow-y-auto ${minTableWidth ? "overflow-x-auto" : "overflow-x-hidden"}  rounded-[5px] bg-white`}>
                        {/* <div ref={tableFrameRef} className='pageTable w-full max-h-full overflow-y-auto overflow-x-hidden'> */}
                        <div ref={tableFrameRef} className={`pageTable w-full max-h-full overflow-y-auto ${minTableWidth ? `${minTableWidth} max-w-full overflow-x-auto` : "overflow-x-hidden"}`}>
                            <table ref={tableRef} className="pageTable w-full h-full table-fixed border-spacing-0 border-collapse">
                                {children[2]}
                                <tbody className={`pageTable w-full h-[calc(100%-64px)]`}>
                                    {children[3]}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {hideFooter ?
                        null
                        :
                        <div className="pb-4 flex justify-between items-center w-full h-[71px] flex-shrink-0">
                            {children[4]}
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

Table.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    background: PropTypes.string,
    minTableWidth: PropTypes.string,
    tableHeight: PropTypes.string,
    children: PropTypes.node,
    resizeFactor: PropTypes.number,
    pageChangeFactor: PropTypes.number,
    getTableSize: PropTypes.func,
    hideFooter: PropTypes.bool,
};

Table.Footer = Footer;
Table.Header = Header;
Table.Action = Action;
Table.Item = Item;
Table.TextItem = TextItem;
Table.TimeItem = TimeItem;
Table.ContactItem = ContactItem;
Table.StatusItem = StatusItem;
Table.Row = Row;

export default Table;