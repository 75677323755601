const MoreOptions = (rest) => {
    return(
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path fill="currentColor" d="M4.87888 14.1519C3.83378 13.7944 3.27642 12.6573 3.63397 11.6122C3.99152 10.5671 5.12859 10.0097 6.17369 10.3673C7.21879 10.7248 7.77615 11.8619 7.4186 12.907C7.2469 13.4089 6.88286 13.822 6.40657 14.0555C5.93028 14.2889 5.38076 14.3236 4.87888 14.1519ZM11.2032 14.2018C10.1581 13.8443 9.60077 12.7072 9.95832 11.6621C10.3159 10.617 11.4529 10.0596 12.498 10.4172C13.5431 10.7747 14.1005 11.9118 13.743 12.9569C13.5713 13.4588 13.2072 13.8719 12.7309 14.1053C12.2546 14.3388 11.7051 14.3735 11.2032 14.2018ZM17.5276 14.2517C16.4825 13.8941 15.9251 12.7571 16.2827 11.712C16.6402 10.6669 17.7773 10.1095 18.8224 10.4671C19.8675 10.8246 20.4249 11.9617 20.0673 13.0068C19.8956 13.5087 19.5316 13.9218 19.0553 14.1552C18.579 14.3887 18.0295 14.4234 17.5276 14.2517Z"/>
        </svg>

    )
}

export default MoreOptions;