import PropTypes from 'prop-types';
import CusCard from 'components/CusCard';

export const FrameHeight = Object.freeze(
    {
        "small": "h-[100vh] md:h-[500px] md:max-h-[75vh]",
        "noraml": "h-[100vh] md:h-[768px] md:max-h-[75vh]",
        "large": "h-[100vh] md:h-[1280px] md:max-h-[75vh]",
        "none": ""
    }
)


const Frame = ({className = "", children = null}) => (
    <CusCard className={`bg-white ${className}`}>
        {children}
    </CusCard>
)

Frame.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

export default Frame