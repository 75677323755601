import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Chatbot from 'pages/setting/automation/chatbot/chatbot';
import NoPermission from 'pages/404/404';

const SettingRoute = () => {
    return (
        <Routes>
            <Route
                index
                element={<Chatbot/>}
            />
            <Route path="*" element={<NoPermission/>} />
        </Routes>
    );    
}

const HomeSetting = () => (
    <div className="w-full h-full">
        <Routes>
            <Route path="*" element={<SettingRoute/>} />
        </Routes>
    </div>
)

export default HomeSetting;