import PropTypes from "prop-types";
import React from 'react';

import Scrollbars from "react-custom-scrollbars-2";
import CusLabelSwitch from "./CusLabelSwitch";

const BorderType = Object.freeze(
  {
      "none": " ",
      "Default": " border-2 focus-within:border-gray-700 border-gray-300 ",
      "Thin": " border-[1px] focus-within:border-gray-700 border-gray-300 ",
      "Error": " border-2 border-red01 focus-within:border-red01 "
  }
)

const CusMultiSelectSwitch = React.forwardRef(({
  className = "",
  label = "",
  value = [],
  options = [],
  checked = false,
  readOnly = false,
  required = false,
  framed = true,
  onChange = null,
  disabled = false,
  ...rest 
}, ref) => {

  return (
    <div className={`
      ${label ? `flex flex-col rounded-md select-none outline-none
          ${framed ? BorderType.Default : " "}
          ${(readOnly && !disabled) ? `opacity-100 bg-grey02`: "bg-white"}`
          : "flex items-center"}           
      ${className}`}
    >
      {label ?
        <span
          className={
            "text-sm text-grey06 pt-1 pl-5 pr-5"
          }
        >
          {label}
        </span>
        :
        null
      }
      {!!options && options.length > 0 ?
        <Scrollbars
          autoHeight
          autoHeightMax="150px"
          className="w-full"
        >
            <div className={"flex flex-col items-center" + (label ? " py-1 pl-5 pr-5 " : "")}>
              {options.map((option, index) => (
                <CusLabelSwitch
                  key={`${option.value}-${index}`}
                  oneLine
                  label={option.label}
                  checked={value.includes(option.value)}
                  onChange={() => onChange({ target: { value: option.value } })}
                  disabled={disabled}
                  readOnly={readOnly}
                />
              ))}
            </div>
        </Scrollbars>
        :
        <div className={"text-grey06 " + (label ? " py-1 pl-5 pr-5 " : "")}>
          No available options
        </div>
      }
    </div>
  )
});

CusMultiSelectSwitch.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.object),
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  framed: PropTypes.bool,
  onChange: PropTypes.func,
}

export default CusMultiSelectSwitch;