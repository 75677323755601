import { createSlice } from '@reduxjs/toolkit'

const INIT_STATE = {
    selectedFlow: null,
    flowList: [],
    loading: false,
    error: null,
    notice: null,
    selectedFlowLoading: false,
    selectedFlowError: null
}

const ChatbotFlow = createSlice({
    name: 'ChatbotFlow',
    initialState: INIT_STATE,
    reducers: {
        SET_FLOW: (state) => {
            state.selectedFlowLoading = true
            state.selectedFlowError = null        
        },
        SET_FLOW_SUCCESS: (state, action) => {
            state.selectedFlowLoading = false
            state.selectedFlow = action.payload;
            state.selectedFlowError = null        
        },
        SET_FLOW_FAILURE: (state, action) => {
            state.selectedFlowLoading = false
            state.selectedFlow = null;
            state.selectedFlowError = action.payload.error;  
        },

        GET_FLOWS: (state) => {
            state.loading = true;
            state.error = null;
        },
        GET_FLOWS_SUCCESS: (state, action) => {
            state.loading = false;
            state.flowList = action.payload;
            state.error = null;

            state.selectedFlow = null;
            state.selectedFlowLoading = false
            state.selectedFlowError = null            
        },
        GET_FLOWS_FAILURE: (state, action) => {
            state.loading = false;
            state.flowList = [];
            state.error = action.payload.error;
        },

        CREATE_FLOW: (state) => {
            state.loading = true;
            state.error = null;
        },
        CREATE_FLOW_SUCCESS: (state, action) => {
            state.loading = false;
            state.flowList.push(action.payload);
            state.error = null;
        },
        CREATE_FLOW_FAILURE: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
        },

        CLONE_FLOW: (state) => {
            state.loading = true;
            state.error = null;
        },
        CLONE_FLOW_SUCCESS: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        CLONE_FLOW_FAILURE: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
        },
        
        CLEAR_PUBLISH_FLOW_MSG: (state) => {
            state.notice = null        
        },
        PUBLISH_FLOW: (state) => {
            state.loading = true;
            state.error = null;
            state.notice = null;
        },
        PUBLISH_FLOW_SUCCESS: (state, action) => {
            state.loading = false;
            state.flowList.push(action.payload);
            state.error = null;
            state.notice = "This flow is successfully published.";
        },
        PUBLISH_FLOW_FAILURE: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
            state.notice = null;
        },
        
        IMPORT_FLOW: (state) => {
            state.loading = true;
            state.error = null;
        },
        IMPORT_FLOW_SUCCESS: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        IMPORT_FLOW_FAILURE: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
        },
        
        EXPORT_FLOW: (state) => {
            state.loading = true;
            state.error = null;
        },
        EXPORT_FLOW_SUCCESS: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        EXPORT_FLOW_FAILURE: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
        },

        REMOVE_FLOW: (state) => {
            state.loading = true;
            state.error = null;
        },
        REMOVE_FLOW_SUCCESS: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        REMOVE_FLOW_FAILURE: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
        },

    }
})

export const {
    SET_FLOW,
    SET_FLOW_SUCCESS,
    SET_FLOW_FAILURE,
    GET_FLOWS,
    GET_FLOWS_FAILURE,
    GET_FLOWS_SUCCESS,
    CREATE_FLOW,
    CREATE_FLOW_FAILURE,
    CREATE_FLOW_SUCCESS,
    CLONE_FLOW,
    CLONE_FLOW_FAILURE,
    CLONE_FLOW_SUCCESS,
    CLEAR_PUBLISH_FLOW_MSG,
    PUBLISH_FLOW,
    PUBLISH_FLOW_FAILURE,
    PUBLISH_FLOW_SUCCESS,
    IMPORT_FLOW,
    IMPORT_FLOW_FAILURE,
    IMPORT_FLOW_SUCCESS,
    EXPORT_FLOW,
    EXPORT_FLOW_FAILURE,
    EXPORT_FLOW_SUCCESS,
    REMOVE_FLOW,
    REMOVE_FLOW_FAILURE,
    REMOVE_FLOW_SUCCESS,
} = ChatbotFlow.actions;


export default ChatbotFlow.reducer;

export const getSelectedFlow = state => state.selectedFlow;
export const getSelectedFlowLoading = state => state.selectedFlowLoading;
export const getSelectedFlowError = state => state.selectedFlowError;
export const getFlowList = state => state.flowList;
export const getLoading = state => state.loading;
export const getError = state => state.error;
export const getNotice = state => state.notice;