import Cancel from 'components/Icon/Cancel';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars-2';

const Page = ({className = "", title = "", closeAction = null, children = null}) => (
    <div className={`w-full h-full flex flex-col ${className}`}>
        <div className="flex justify-between items-end w-full py-4">
            <div className="truncate">{title}</div>
            {closeAction ? <div className="cursor-pointer text-blue04" onClick={() => closeAction()}><Cancel className="w-[32px] h-[32px]"/></div> : null}
        </div>
        <Scrollbars
            className="relative h-full flex-1 my-2"
            autoHide
            autoHideDuration={100}
        >
            {children}
        </Scrollbars>
    </div>            
)

Page.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    closeAction: PropTypes.func,
    children: PropTypes.node,
};

export default Page