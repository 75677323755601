import { configureStore } from '@reduxjs/toolkit'
import reducers from '../reducers';

export const toolkitConfigureStore = (initialState) => {

    const rootReducer = (state, action) => {
        if(action.type ===  "User/VAILDATE_USER_FAILURE")
            state = undefined
        return reducers(state, action)
    }

    const store = configureStore({
        reducer: rootReducer,
        preloadedState: initialState
    })

    return store;

}