const PDF = (rest) => {
    return(
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path fill="currentColor" d="M18 22.0009H6C4.89543 22.0009 4 21.1054 4 20.0009V4.00087C4 2.8963 4.89543 2.00087 6 2.00087H13C13.009 1.99971 13.018 1.99971 13.027 2.00087H13.033C13.0424 2.00383 13.0522 2.00584 13.062 2.00687C13.1502 2.01252 13.2373 2.02967 13.321 2.05787H13.336H13.351H13.363C13.3815 2.0708 13.3988 2.08518 13.415 2.10087C13.5239 2.1493 13.6232 2.21706 13.708 2.30087L19.708 8.30087C19.7918 8.38566 19.8596 8.48493 19.908 8.59387C19.917 8.61587 19.924 8.63687 19.931 8.65987L19.941 8.68787C19.9689 8.77126 19.9854 8.85805 19.99 8.94587C19.9909 8.95583 19.9932 8.96561 19.997 8.97487V8.98087C19.9986 8.98743 19.9996 8.99412 20 9.00087V20.0009C20 20.5313 19.7893 21.04 19.4142 21.4151C19.0391 21.7902 18.5304 22.0009 18 22.0009ZM14.424 14.0009V19.0009H15.364V16.9609H16.824V16.1229H15.364V14.8419H17V14.0009H14.424ZM10.724 14.0009V19.0009H11.93C12.4359 19.0257 12.9258 18.8198 13.262 18.4409C13.6069 18.0008 13.7797 17.4501 13.748 16.8919V16.0819C13.7712 15.5295 13.5936 14.9874 13.248 14.5559C12.9226 14.1855 12.4476 13.9816 11.955 14.0009H10.724ZM7 14.0009V19.0009H7.94V17.2419H8.566C8.98402 17.265 9.39232 17.1103 9.69 16.8159C9.97408 16.4983 10.1214 16.0814 10.1 15.6559C10.1186 15.2201 9.97135 14.7935 9.688 14.4619C9.40772 14.1511 9.00309 13.9819 8.585 14.0009H7ZM13 4.00087V9.00087H18L13 4.00087ZM11.946 18.1629H11.664V14.8419H12.006C12.2489 14.8276 12.4824 14.9375 12.626 15.1339C12.7726 15.4372 12.8354 15.7741 12.808 16.1099V16.9789C12.83 17.2986 12.7606 17.618 12.608 17.8999C12.4441 18.0912 12.1965 18.1896 11.946 18.1629ZM8.585 16.4009H7.939V14.8419H8.594C8.75752 14.8436 8.90863 14.9294 8.994 15.0689C9.10222 15.2474 9.15447 15.4543 9.144 15.6629C9.15654 15.8574 9.1049 16.0506 8.997 16.2129C8.89754 16.3377 8.74447 16.4076 8.585 16.4009Z"/>
        </svg>
    )
}

export default PDF;