import {
    GET_ENTITYS,
    GET_ENTITYS_FAILURE,
    GET_ENTITYS_SUCCESS,
    BULK_ENTITY,
    BULK_ENTITY_SUCCESS,
    BULK_ENTITY_FAILURE
} from "store/reducers/setting/automation/chatbot/entity";

import {
    getEntity as getEntityService,
    bulkEntity as bulkEntityService
} from "services/chatbot/entity";
import { isJson, isSuccessResponse } from "util/helper";
import { handleAuthError } from "store/actions/auth/user";
import { ERROR_REPSONSE_LOCALE_MAP, ERROR_RESPONSE_MAP, getErrorByLocale } from "util/ErrorResponse";
import { errorPrefix } from "lang/locales/prefix";
import { getToken } from "store/reducers/auth/user";

export const getEntityList = (data) => (dispatch, getState) => {
    dispatch(GET_ENTITYS());    

    let authToken = getToken(getState().auth.user)
    getEntityService(data, authToken)
    .then(response => {
        const bodyString = response.data.body;
        const body = isJson(bodyString) ? JSON.parse(bodyString) : bodyString;

        console.log(body)

        if(isSuccessResponse(response)) {
            dispatch(GET_ENTITYS_SUCCESS(body));
        } else {
            if(body === ERROR_RESPONSE_MAP.AUTH){
                dispatch(handleAuthError())
                return
            }
            if(body === ERROR_RESPONSE_MAP.PERMISSION){
                dispatch(GET_ENTITYS_FAILURE({error: getErrorByLocale(getState, ERROR_REPSONSE_LOCALE_MAP.PERMISSION)}))
                return
            }
            
            dispatch(GET_ENTITYS_FAILURE({error: body.message }));
        }

    })
    .catch(error=> {
        const message = (typeof error.response === "string") ? error.response : 'System Error, Please contact admin for more information'
        dispatch(GET_ENTITYS_FAILURE({error: message}))
    })

}

export const createEntity = ({flowId, entity}) => (dispatch, getState) => {
    dispatch(bulkEntity({insertList: [{flowId, ...entity}], flowId}))
}

export const updateEntity = ({flowId, entity}) => (dispatch, getState) => {
    dispatch(bulkEntity({updateList: [{flowId, ...entity}], flowId}))
}

export const removeEntity = ({flowId, id}) => (dispatch, getState) => {
    dispatch(bulkEntity({removeIdList: [id], flowId}))
}

export const bulkEntity = ({insertList, updateList, removeIdList, flowId}) => (dispatch, getState) => {
    dispatch(BULK_ENTITY());

    insertList = insertList ? insertList : []
    updateList = updateList ? updateList : []
    removeIdList = removeIdList ? removeIdList : []

    let authToken = getToken(getState().auth.user)
    bulkEntityService({insertEntityList: insertList, updateEntityList: updateList, deleteEntityIdList: removeIdList}, authToken)
        .then(response => {
            console.log(response)
            const bodyString = response.data.body;
            const body = isJson(bodyString) ? JSON.parse(bodyString) : bodyString;

            console.log(body)

            if(isSuccessResponse(response)){
                dispatch(BULK_ENTITY_SUCCESS())
                if(flowId)
                    dispatch(getEntityList({flowId: flowId}))

            }
            else{
                if(body === ERROR_RESPONSE_MAP.AUTH){
                    dispatch(handleAuthError())
                    return
                }
                if(body === ERROR_RESPONSE_MAP.PERMISSION){
                    dispatch(BULK_ENTITY_FAILURE({error: getErrorByLocale(getState, ERROR_REPSONSE_LOCALE_MAP.PERMISSION)}))
                    return
                }
    
                dispatch(BULK_ENTITY_FAILURE({error: body.message}))
            }

        })
        .catch((error) => {
            const message = (typeof error.response === "string") ? error.response : 'System Error, Please contact admin for more information'
            dispatch(BULK_ENTITY_FAILURE({error: message}))
        })
}
