import { createSlice } from '@reduxjs/toolkit'

const INIT_STATE = {
    parameterList: [],
    loading: false,
    error: null
}

const Parameter = createSlice({
    name: 'Parameter',
    initialState: INIT_STATE,
    reducers: {
        GET_PARAMETERS: (state) => {
            state.loading = true;
            state.error = null;
        },
        GET_PARAMETERS_SUCCESS: (state, action) => {
            state.loading = false;
            state.parameterList = action.payload;
            state.error = null;
        },
        GET_PARAMETERS_FAILURE: (state, action) => {
            state.loading = false;
            state.parameterList = [];
            state.error = action.payload.error;
        },

        BULK_PARAMETER: (state) => {
            state.loading = true;
            state.error = null;
        },
        BULK_PARAMETER_SUCCESS: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        BULK_PARAMETER_FAILURE: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
        },

    }
})

export const {
    GET_PARAMETERS,
    GET_PARAMETERS_FAILURE,
    GET_PARAMETERS_SUCCESS,
    BULK_PARAMETER,
    BULK_PARAMETER_SUCCESS,
    BULK_PARAMETER_FAILURE,
} = Parameter.actions;


export default Parameter.reducer;

export const getParameterList = state => state.parameterList;
export const getLoading = state => state.loading;
export const getError = state => state.error;