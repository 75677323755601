const List = (rest) => {
    return(
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M13.4485 19.6254H19.2215L20.3115 22.6793C20.3757 22.8521 20.4805 22.989 20.6259 23.0901C20.7711 23.1911 20.9362 23.2416 21.1214 23.2416C21.4274 23.2416 21.6702 23.1177 21.8498 22.8699C22.0295 22.6221 22.0629 22.3491 21.9497 22.0508L17.427 9.81857C17.3582 9.66107 17.2531 9.5337 17.1117 9.43646C16.9702 9.33942 16.8145 9.29089 16.6446 9.29089H16.0254C15.8555 9.29089 15.6998 9.33942 15.5583 9.43646C15.4169 9.5337 15.3118 9.66107 15.243 9.81857L10.7203 22.0508C10.6071 22.3368 10.6374 22.6067 10.811 22.8607C10.9846 23.1146 11.2243 23.2416 11.5302 23.2416C11.7276 23.2416 11.896 23.1911 12.0352 23.0901C12.1744 22.989 12.2821 22.8461 12.3585 22.6611L13.4485 19.6254ZM14.0039 18.0191L16.2753 11.6083H16.3947L18.6661 18.0191H14.0039ZM7.15693 27.67C6.55419 27.67 6.04401 27.4612 5.62641 27.0436C5.2088 26.626 5 26.1158 5 25.5131V7.15693C5 6.55419 5.2088 6.04401 5.62641 5.62641C6.04401 5.2088 6.55419 5 7.15693 5H25.5131C26.1158 5 26.626 5.2088 27.0436 5.62641C27.4612 6.04401 27.67 6.55419 27.67 7.15693V25.5131C27.67 26.1158 27.4612 26.626 27.0436 27.0436C26.626 27.4612 26.1158 27.67 25.5131 27.67H7.15693ZM7.15693 25.8803H25.5131C25.6049 25.8803 25.6891 25.842 25.7654 25.7654C25.842 25.6891 25.8803 25.6049 25.8803 25.5131V7.15693C25.8803 7.06506 25.842 6.98094 25.7654 6.90458C25.6891 6.82802 25.6049 6.78974 25.5131 6.78974H7.15693C7.06506 6.78974 6.98094 6.82802 6.90458 6.90458C6.82802 6.98094 6.78974 7.06506 6.78974 7.15693V25.5131C6.78974 25.6049 6.82802 25.6891 6.90458 25.7654C6.98094 25.842 7.06506 25.8803 7.15693 25.8803Z" fill="#64748B"/>
            <path d="M13.4485 19.6254H19.2215L20.3115 22.6793C20.3757 22.8521 20.4805 22.989 20.6259 23.0901C20.7711 23.1911 20.9362 23.2416 21.1214 23.2416C21.4274 23.2416 21.6702 23.1177 21.8498 22.8699C22.0295 22.6221 22.0629 22.3491 21.9497 22.0508L17.427 9.81857C17.3582 9.66107 17.2531 9.5337 17.1117 9.43646C16.9702 9.33942 16.8145 9.29089 16.6446 9.29089H16.0254C15.8555 9.29089 15.6998 9.33942 15.5583 9.43646C15.4169 9.5337 15.3118 9.66107 15.243 9.81857L10.7203 22.0508C10.6071 22.3368 10.6374 22.6067 10.811 22.8607C10.9846 23.1146 11.2243 23.2416 11.5302 23.2416C11.7276 23.2416 11.896 23.1911 12.0352 23.0901C12.1744 22.989 12.2821 22.8461 12.3585 22.6611L13.4485 19.6254ZM14.0039 18.0191L16.2753 11.6083H16.3947L18.6661 18.0191H14.0039ZM7.15693 27.67C6.55419 27.67 6.04401 27.4612 5.62641 27.0436C5.2088 26.626 5 26.1158 5 25.5131V7.15693C5 6.55419 5.2088 6.04401 5.62641 5.62641C6.04401 5.2088 6.55419 5 7.15693 5H25.5131C26.1158 5 26.626 5.2088 27.0436 5.62641C27.4612 6.04401 27.67 6.55419 27.67 7.15693V25.5131C27.67 26.1158 27.4612 26.626 27.0436 27.0436C26.626 27.4612 26.1158 27.67 25.5131 27.67H7.15693ZM7.15693 25.8803H25.5131C25.6049 25.8803 25.6891 25.842 25.7654 25.7654C25.842 25.6891 25.8803 25.6049 25.8803 25.5131V7.15693C25.8803 7.06506 25.842 6.98094 25.7654 6.90458C25.6891 6.82802 25.6049 6.78974 25.5131 6.78974H7.15693C7.06506 6.78974 6.98094 6.82802 6.90458 6.90458C6.82802 6.98094 6.78974 7.06506 6.78974 7.15693V25.5131C6.78974 25.6049 6.82802 25.6891 6.90458 25.7654C6.98094 25.842 7.06506 25.8803 7.15693 25.8803Z" fill="black" fillOpacity="0.2"/>
            <path d="M13.4485 19.6254H19.2215L20.3115 22.6793C20.3757 22.8521 20.4805 22.989 20.6259 23.0901C20.7711 23.1911 20.9362 23.2416 21.1214 23.2416C21.4274 23.2416 21.6702 23.1177 21.8498 22.8699C22.0295 22.6221 22.0629 22.3491 21.9497 22.0508L17.427 9.81857C17.3582 9.66107 17.2531 9.5337 17.1117 9.43646C16.9702 9.33942 16.8145 9.29089 16.6446 9.29089H16.0254C15.8555 9.29089 15.6998 9.33942 15.5583 9.43646C15.4169 9.5337 15.3118 9.66107 15.243 9.81857L10.7203 22.0508C10.6071 22.3368 10.6374 22.6067 10.811 22.8607C10.9846 23.1146 11.2243 23.2416 11.5302 23.2416C11.7276 23.2416 11.896 23.1911 12.0352 23.0901C12.1744 22.989 12.2821 22.8461 12.3585 22.6611L13.4485 19.6254ZM14.0039 18.0191L16.2753 11.6083H16.3947L18.6661 18.0191H14.0039ZM7.15693 27.67C6.55419 27.67 6.04401 27.4612 5.62641 27.0436C5.2088 26.626 5 26.1158 5 25.5131V7.15693C5 6.55419 5.2088 6.04401 5.62641 5.62641C6.04401 5.2088 6.55419 5 7.15693 5H25.5131C26.1158 5 26.626 5.2088 27.0436 5.62641C27.4612 6.04401 27.67 6.55419 27.67 7.15693V25.5131C27.67 26.1158 27.4612 26.626 27.0436 27.0436C26.626 27.4612 26.1158 27.67 25.5131 27.67H7.15693ZM7.15693 25.8803H25.5131C25.6049 25.8803 25.6891 25.842 25.7654 25.7654C25.842 25.6891 25.8803 25.6049 25.8803 25.5131V7.15693C25.8803 7.06506 25.842 6.98094 25.7654 6.90458C25.6891 6.82802 25.6049 6.78974 25.5131 6.78974H7.15693C7.06506 6.78974 6.98094 6.82802 6.90458 6.90458C6.82802 6.98094 6.78974 7.06506 6.78974 7.15693V25.5131C6.78974 25.6049 6.82802 25.6891 6.90458 25.7654C6.98094 25.842 7.06506 25.8803 7.15693 25.8803Z" fill="black" fillOpacity="0.2"/>
            <path d="M13.4485 19.6254H19.2215L20.3115 22.6793C20.3757 22.8521 20.4805 22.989 20.6259 23.0901C20.7711 23.1911 20.9362 23.2416 21.1214 23.2416C21.4274 23.2416 21.6702 23.1177 21.8498 22.8699C22.0295 22.6221 22.0629 22.3491 21.9497 22.0508L17.427 9.81857C17.3582 9.66107 17.2531 9.5337 17.1117 9.43646C16.9702 9.33942 16.8145 9.29089 16.6446 9.29089H16.0254C15.8555 9.29089 15.6998 9.33942 15.5583 9.43646C15.4169 9.5337 15.3118 9.66107 15.243 9.81857L10.7203 22.0508C10.6071 22.3368 10.6374 22.6067 10.811 22.8607C10.9846 23.1146 11.2243 23.2416 11.5302 23.2416C11.7276 23.2416 11.896 23.1911 12.0352 23.0901C12.1744 22.989 12.2821 22.8461 12.3585 22.6611L13.4485 19.6254ZM14.0039 18.0191L16.2753 11.6083H16.3947L18.6661 18.0191H14.0039ZM7.15693 27.67C6.55419 27.67 6.04401 27.4612 5.62641 27.0436C5.2088 26.626 5 26.1158 5 25.5131V7.15693C5 6.55419 5.2088 6.04401 5.62641 5.62641C6.04401 5.2088 6.55419 5 7.15693 5H25.5131C26.1158 5 26.626 5.2088 27.0436 5.62641C27.4612 6.04401 27.67 6.55419 27.67 7.15693V25.5131C27.67 26.1158 27.4612 26.626 27.0436 27.0436C26.626 27.4612 26.1158 27.67 25.5131 27.67H7.15693ZM7.15693 25.8803H25.5131C25.6049 25.8803 25.6891 25.842 25.7654 25.7654C25.842 25.6891 25.8803 25.6049 25.8803 25.5131V7.15693C25.8803 7.06506 25.842 6.98094 25.7654 6.90458C25.6891 6.82802 25.6049 6.78974 25.5131 6.78974H7.15693C7.06506 6.78974 6.98094 6.82802 6.90458 6.90458C6.82802 6.98094 6.78974 7.06506 6.78974 7.15693V25.5131C6.78974 25.6049 6.82802 25.6891 6.90458 25.7654C6.98094 25.842 7.06506 25.8803 7.15693 25.8803Z" fill="black" fillOpacity="0.2"/>
        </svg>
    )
}

export default List;