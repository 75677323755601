import DownArrow from "components/Icon/DownArrow";
import UpArrow from "components/Icon/UpArrow";
import PropTypes from "prop-types";
import React from 'react';

export const ArrowColor = Object.freeze(
    {
        "black": " text-greyblack",
        "blue": " text-blue04",
    }
)


const TextMenu = ({ 
    title = "",
    className = "",
    arrowColor = ArrowColor.black,
    onClick = null,
    active = true,
    ...rest
}) => (
    <div className={`menu-pos ${className}`} onClick={onClick}>
        <div className="text-blue04 text-sm">{title}</div>
        <div className={`${arrowColor} px-2`}>{active ? <DownArrow/> : <UpArrow/>}</div>
    </div>
)

TextMenu.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    arrowColor: PropTypes.string,
    onClick: PropTypes.func,
    active: PropTypes.bool,
}

export default TextMenu