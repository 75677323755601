import { createSlice } from '@reduxjs/toolkit'

const INIT_STATE = {
    entityList: [],
    loading: false,
    error: null
}

const Entity = createSlice({
    name: 'Entity',
    initialState: INIT_STATE,
    reducers: {
        GET_ENTITYS: (state) => {
            state.loading = true;
            state.error = null;
        },
        GET_ENTITYS_SUCCESS: (state, action) => {
            state.loading = false;
            state.entityList = action.payload;
            state.error = null;
        },
        GET_ENTITYS_FAILURE: (state, action) => {
            state.loading = false;
            state.entityList = [];
            state.error = action.payload.error;
        },

        BULK_ENTITY: (state) => {
            state.loading = true;
            state.error = null;
        },
        BULK_ENTITY_SUCCESS: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        BULK_ENTITY_FAILURE: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
        },

    }
})

export const {
    GET_ENTITYS,
    GET_ENTITYS_FAILURE,
    GET_ENTITYS_SUCCESS,
    BULK_ENTITY,
    BULK_ENTITY_SUCCESS,
    BULK_ENTITY_FAILURE,
} = Entity.actions;


export default Entity.reducer;

export const getEntityList = state => state.entityList;
export const getLoading = state => state.loading;
export const getError = state => state.error;