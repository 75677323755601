const File = (rest) => {
    return(
        <svg 
            width="16"
            height="20"
            viewBox="0 0 16 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path fill="currentColor" d="M14 20.0009H2C0.89543 20.0009 0 19.1054 0 18.0009V2.00087C0 0.896302 0.89543 0.000871474 2 0.000871474H9C9.00896 -0.000290491 9.01804 -0.000290491 9.027 0.000871474H9.033C9.04244 0.00382953 9.05216 0.00583946 9.062 0.00687147C9.15019 0.0125247 9.23726 0.0296696 9.321 0.0578715H9.336H9.351H9.363C9.38145 0.0707972 9.39884 0.0851763 9.415 0.100871C9.52394 0.149295 9.62321 0.217056 9.708 0.300871L15.708 6.30087C15.7918 6.38566 15.8596 6.48493 15.908 6.59387C15.917 6.61587 15.924 6.63687 15.931 6.65987L15.941 6.68787C15.9689 6.77126 15.9854 6.85805 15.99 6.94587C15.9909 6.95583 15.9932 6.96561 15.997 6.97487V6.98087C15.9986 6.98743 15.9996 6.99412 16 7.00087V18.0009C16 18.5313 15.7893 19.04 15.4142 19.4151C15.0391 19.7902 14.5304 20.0009 14 20.0009ZM9 2.00087V7.00087H14L9 2.00087Z"/>
        </svg>

    )
}

export default File;