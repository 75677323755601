import PropTypes from 'prop-types';

import LeftArrow from "components/Icon/LeftArrow";
import { WarningSnackbar } from "components/WarningSnackbar";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


import CusModal from 'components/CusModal';
import { Color as ButtonColor, PaddingType as ButtonPadding, RoundType as ButtonRoundType, CusRoundedButton } from 'components/CusRoundedButton';

import Export from 'components/Icon/NewChatbotIcon/Export';
import Import from 'components/Icon/NewChatbotIcon/Import';
import Refresh from 'components/Icon/Refresh';
import { getAttachmentList as fetchAttachmentList } from 'store/actions/setting/automation/chatbot/attachment';
import { exportFlow, importFlow, publishFlow, selectFlow } from 'store/actions/setting/automation/chatbot/flow';
import { getChatFormatedFullDateTime } from 'util/helper';
import FlowGraphPanel from './FlowGraphPanel';
import NodeEditPanel from './NodeEditPanel';

import { createNode, expandNode, foucsNode, removeNode, selectNode, updateNode } from 'store/actions/setting/automation/chatbot/node';

import Scrollbars from 'react-custom-scrollbars-2';
import { getError as getActionError, getActionList, getLoading as getActionLoading } from 'store/reducers/setting/automation/chatbot/action';
import { getError as getAttachmentError, getAttachmentList, getLoading as getAttachmentLoading } from 'store/reducers/setting/automation/chatbot/attachment';
import { getError as getEntityError, getEntityList, getLoading as getEntityLoading } from 'store/reducers/setting/automation/chatbot/entity';
import { getError as getKeywordError, getKeywordList, getLoading as getKeywordLoading } from 'store/reducers/setting/automation/chatbot/keyword';
import { getFocusedNode, getError as getNodeError, getNodeExpandedMap, getNodeList, getLoading as getNodeLoading, getNodeMap, getSelectedNode } from 'store/reducers/setting/automation/chatbot/node';
import { getError as getParameterError, getParameterList, getLoading as getParameterLoading } from 'store/reducers/setting/automation/chatbot/parameter';
import { getError as getResponseError, getResponseList, getLoading as getResponseLoading } from 'store/reducers/setting/automation/chatbot/response';

import {
    getError as getTestError,
    getLanguage as getTestLanguage,
    getLoading as getTestLoading,
    getMessages as getTestMessages,
    getNodeHistory as getTestNodeHistory,
    getNodeOptionValueMap as getTestNodeOptionValueMap,
    getParameterMap as getTestParameterMap
} from 'store/reducers/setting/automation/chatbot/test';

import { createAction, removeAction, updateAction } from 'store/actions/setting/automation/chatbot/action';
import { createEntity, removeEntity, updateEntity } from 'store/actions/setting/automation/chatbot/entity';
import { createKeyword, removeKeyword, updateKeyword } from 'store/actions/setting/automation/chatbot/keyword';
import { createParameter, removeParameter, updateParameter } from 'store/actions/setting/automation/chatbot/parameter';
import { createResponse, removeResponse, updateResponse } from 'store/actions/setting/automation/chatbot/response';
import EditAction from './FlowSettingModal/EditAction';
import EditEntity from './FlowSettingModal/EditEntity';
import EditKeyword from './FlowSettingModal/EditKeyword';
import EditParameter from './FlowSettingModal/EditParameter';
import EditResponse from './FlowSettingModal/EditResponse';

import CusCard from 'components/CusCard';
import { chatbotIconBarPrefix, editChatbotPrefix, generalPrefix, settingPrefix } from 'lang/locales/prefix';
import { useIntl } from 'react-intl';
import { resetTestChatbot, testChatbot } from 'store/actions/setting/automation/chatbot/test';
import PreviewFlow from './PreviewFlow';
import "./index.css";

import Preview from 'components/Icon/Preview';
import Overlay from 'components/Overlay';
import FlowSettingList from './FlowSettingList';
import FlowSettingPanel from './FlowSettingPanel';
import IconBar from './IconBar';
import NodeSettingFolder from './NodeSettingFolder';

export const NODE_EDIT_SECTION_TYPE = Object.freeze({
    "DEFAULT": "",
    "CONDITIONS": "CONDITIONS",
    "RESPONSE": "RESPONSE",
    "ACTION": "ACTION",
})

export const SETTING_SUB_MENU_TYPE = Object.freeze({
    "LIST": "LIST",
    "KEYWORD": "KEYWORD",
    "ENTITY": "ENTITY",
    "RESPONSE": "RESPONSE",
    "ACTION": "ACTION",
    "PARAMETER": "PARAMETER",
})

export const FLOW_SETTING_SUB_MENU_TYPE_NAME = Object.freeze({
    "LIST": `${settingPrefix}.${chatbotIconBarPrefix}.nameList.setting.list`,
    "KEYWORD": `${settingPrefix}.${editChatbotPrefix}.nameList.setting.keyword`,
    "ENTITY": `${settingPrefix}.${editChatbotPrefix}.nameList.setting.entity`,
    "RESPONSE": `${settingPrefix}.${editChatbotPrefix}.nameList.setting.response`,
    "ACTION": `${settingPrefix}.${editChatbotPrefix}.nameList.setting.action`,
    "PARAMETER": `${settingPrefix}.${editChatbotPrefix}.nameList.setting.parameter`,
})

export const FLOW_SETTING_MENU_TYPE_NAME = Object.freeze({
    "LIST": `${settingPrefix}.${chatbotIconBarPrefix}.list`,
    "KEYWORD": `${settingPrefix}.${chatbotIconBarPrefix}.keywords`,
    "ENTITY": `${settingPrefix}.${chatbotIconBarPrefix}.entity`,
    "RESPONSE": `${settingPrefix}.${chatbotIconBarPrefix}.response`,
    "ACTION": `${settingPrefix}.${chatbotIconBarPrefix}.action`,
    "PARAMETER": `${settingPrefix}.${chatbotIconBarPrefix}.parameter`,
})

export const FLOW_SETTING_MENU_TYPE_EDIT_NAME = Object.freeze({
    "KEYWORD": `${settingPrefix}.${chatbotIconBarPrefix}.edit.keywords`,
    "ENTITY": `${settingPrefix}.${chatbotIconBarPrefix}.edit.entity`,
    "RESPONSE": `${settingPrefix}.${chatbotIconBarPrefix}.edit.response`,
    "ACTION": `${settingPrefix}.${chatbotIconBarPrefix}.edit.action`,
    "PARAMETER": `${settingPrefix}.${chatbotIconBarPrefix}.edit.parameter`,
})

const MODAL_ACTION = Object.freeze({
    "PUBLISH": "PUBLISH",
    "REMOVE": "REMOVE",
    "REMOVE_SETTING": "REMOVE_SETTING",
    "EXIT_EDIT": "EXIT_EDIT",
    "EXIT_SETTING": "EXIT_SETTING",
    "EXIT_EDIT_SETTING": "EXIT_EDIT_SETTING"
})

const useChatbotNode = () => {
    const {
        focusedNode,
        selectedNode,
        nodeExpandedMap,
        nodeMap,
        nodeList,
        nodeLoading,    
        nodeError
    } = useSelector(state => ({
        focusedNode: getFocusedNode(state.setting.automation.chatbot.node),
        selectedNode: getSelectedNode(state.setting.automation.chatbot.node),
        nodeExpandedMap: getNodeExpandedMap(state.setting.automation.chatbot.node),
        nodeMap: getNodeMap(state.setting.automation.chatbot.node),
        nodeList: getNodeList(state.setting.automation.chatbot.node),
        nodeLoading: getNodeLoading(state.setting.automation.chatbot.node),
        nodeError: getNodeError(state.setting.automation.chatbot.node),
    }));

    return {
        focusedNode,
        selectedNode,
        nodeList,
        nodeExpandedMap,
        nodeMap,
        nodeLoading,    
        nodeError
    }
}

const useKeyword = () => {
    const {
        keywordList,
        keywordLoading,    
        keywordError
    } = useSelector(state => ({
        keywordList: getKeywordList(state.setting.automation.chatbot.keyword),
        keywordLoading: getKeywordLoading(state.setting.automation.chatbot.keyword),
        keywordError: getKeywordError(state.setting.automation.chatbot.keyword),
    }));

    return {
        keywordList,
        keywordLoading,    
        keywordError
    }
}

const useEntity = () => {
    const {
        entityList,
        entityLoading,    
        entityError
    } = useSelector(state => ({
        entityList: getEntityList(state.setting.automation.chatbot.entity),
        entityLoading: getEntityLoading(state.setting.automation.chatbot.entity),
        entityError: getEntityError(state.setting.automation.chatbot.entity),
    }));

    return {
        entityList,
        entityLoading,    
        entityError
    }
}

const useResponse = () => {
    const {
        responseList,
        responseLoading,    
        responseError
    } = useSelector(state => ({
        responseList: getResponseList(state.setting.automation.chatbot.response),
        responseLoading: getResponseLoading(state.setting.automation.chatbot.response),
        responseError: getResponseError(state.setting.automation.chatbot.response),
    }));

    return {
        responseList,
        responseLoading,    
        responseError
    }
}

const useAction = () => {
    const {
        actionList,
        actionLoading,    
        actionError
    } = useSelector(state => ({
        actionList: getActionList(state.setting.automation.chatbot.action),
        actionLoading: getActionLoading(state.setting.automation.chatbot.action),
        actionError: getActionError(state.setting.automation.chatbot.action),
    }));

    return {
        actionList,
        actionLoading,    
        actionError
    }
}

const useParameter = () => {
    const {
        parameterList,
        parameterLoading,    
        parameterError
    } = useSelector(state => ({
        parameterList: getParameterList(state.setting.automation.chatbot.parameter),
        parameterLoading: getParameterLoading(state.setting.automation.chatbot.parameter),
        parameterError: getParameterError(state.setting.automation.chatbot.parameter),
    }));

    return {
        parameterList,
        parameterLoading,    
        parameterError
    }
}

const useTestChatbot = () => {
    const {
        testLanguage,
        testNodeHistory,
        testMessages,
        testNodeOptionValueMap,
        testParameterMap,
        testLoading,
        testError
    } = useSelector(state => ({
        testLanguage: getTestLanguage(state.setting.automation.chatbot.test),
        testNodeHistory: getTestNodeHistory(state.setting.automation.chatbot.test),
        testMessages: getTestMessages(state.setting.automation.chatbot.test),
        testNodeOptionValueMap: getTestNodeOptionValueMap(state.setting.automation.chatbot.test),
        testParameterMap: getTestParameterMap(state.setting.automation.chatbot.test),
        testLoading: getTestLoading(state.setting.automation.chatbot.test),
        testError: getTestError(state.setting.automation.chatbot.test)
    }));

    return {
        testLanguage,
        testNodeHistory,
        testMessages,
        testNodeOptionValueMap,
        testParameterMap,
        testLoading,
        testError
     }
}

const useAttachment = () => {
    const {
        attachmentList,
        attachmentLoading,    
        attachmentError
    } = useSelector(state => ({
        attachmentList: getAttachmentList(state.setting.automation.chatbot.attachment),
        attachmentLoading: getAttachmentLoading(state.setting.automation.chatbot.attachment),
        attachmentError: getAttachmentError(state.setting.automation.chatbot.attachment),
    }));

    return {
        attachmentList,
        attachmentLoading,    
        attachmentError
    }
}

const EditFlow = ({
    selectedFlow = null,
    loading = true,
    openExitEditAction = null,
}) => {

    const intl = useIntl();
    const dispatch = useDispatch();

    const [isEditNode, setIsEditNode] = useState(false)

    const [warningOpen, setWarningOpen] = useState(false);
    const [warning, setWarning] = useState("");

    const realFileInput = useRef(null);
    const [importObj, setImportObj] = useState(null);

    // const [selectedNode, dispatch(selectNode] = useState(null)
    const [modalOpen, setModalOpen] = useState(false);
    const [modal, setModal] = useState(null);
    
    const [importModalOpen, setImportModalOpen] = useState(false);
    const [importModal, setImportModal] = useState(null);

    const [settingPanelType, setSettingPanelType] = useState("");
    const [editsettingPanelOpen, setEditSettingPanelOpen] = useState(false);

    // const [editModalOpen, setEditModalOpen] = useState(false);
    const [flowSetting, setFlowSetting] = useState(null);
    const [isEditflowSetting, setIsEditflowSetting] = useState(false);

    const [showPreviewFlow, setShowPreviewFlow] = useState(false);

    const [nodeEditSection, setNodeEditSection] = useState('');

    const [keywordNameMap, setKeywordNameMap] = useState({})
    const [entityNameMap, setEntityNameMap] = useState({})
    const [responseNameMap, setResponseNameMap] = useState({})
    const [actionNameMap, setActionNameMap] = useState({})
    const [parameterNameMap, setParameterNameMap] = useState({})

    const {
        focusedNode,
        selectedNode,
        nodeList,
        nodeExpandedMap,
        nodeMap,
        nodeLoading,    
        nodeError
    } = useChatbotNode()

    const {
        keywordList,
        keywordLoading,    
        keywordError
    } = useKeyword()

    const {
        entityList,
        entityLoading,    
        entityError
    } = useEntity()

    const {
        responseList,
        responseLoading,    
        responseError
    } = useResponse()

    const {
        actionList,
        actionLoading,    
        actionError
    } = useAction()

    const {
        parameterList,
        parameterLoading,    
        parameterError
    } = useParameter()

    const {
        testLanguage,
        testNodeHistory,
        testMessages,
        testNodeOptionValueMap,
        testParameterMap,
        testLoading,
        testError
    } = useTestChatbot()

    const {
        attachmentList,
        attachmentLoading,    
        attachmentError
    } = useAttachment()

    const openSettingPanelAction = (type) => {
        if(flowSetting && isEditflowSetting){
            openExitSettingAction(type)
            return;
        }

        if(type === settingPanelType){
            setSettingPanelType('')
            closeEditSettingPanelAction()
            return;
        }

        closeEditSettingAction()
        setSettingPanelType(type)
    }

    const openEditSettingPanelAction =  (setting, type=null) => {
        let {isNew, ...rest} = setting;

        setEditSettingPanelOpen(true)
        setFlowSetting(rest)
        setIsEditflowSetting(!!isNew)

        if(type)
            setSettingPanelType(type)
    }

    const closeEditSettingPanelAction =  () => {
        if(flowSetting && isEditflowSetting){
            openExitEditSettingAction()
            return;
        }

        closeEditSettingAction()
    }

    const switchExit = () => {
        openExitEditAction()
    }

    const graphSelectNodeAction = (node, callback) => {
        if(!!selectedNode){
            if(node.isCreate && selectedNode.isCreate){
                if(selectedNode.preNodeId === node.preNodeId)
                    return;
            }
            else if(selectedNode.id === node.id)
                return;
        }

        if(selectedFlow.type !== "RELEASED" && !!selectedNode && isEditNode)
            openExitNodeEditAction(node, callback)
        else{
            dispatch(selectNode(node))
            if(callback)
                callback()
        }

    }

    const getNodeById = (id) => {
        let filteredNodeList = JSON.parse(JSON.stringify(nodeList))
        let index = filteredNodeList.findIndex(node => node.id === id)

        if(index === -1)
            return null;
        
        return filteredNodeList[index]
    }

    const nodeFolderExpand = (id) => {
        dispatch(expandNode({id}));
    }

    const selectNodeFromId = (id) => {
        let node = getNodeById(id)

        if(!!node){
            if(!!selectedNode)
                if(selectedNode.id === node.id)
                    return;

            if(selectedFlow.type !== "RELEASED" && !!selectedNode && isEditNode)
                openExitNodeEditAction(node)
            else
                dispatch(selectNode(node))
        }
    }
    
    const addNodeFromId = (id) => {
        let node = {
            flowId: selectedFlow.id,
            isCreate: true,
            preNodeId: id
        }

        if(!!selectedNode){
            if(selectedNode.isCreate){
                if(selectedNode.preNodeId === node.preNodeId)
                    return;
            }
        }

        if(selectedFlow.type !== "RELEASED" && !!selectedNode && isEditNode)
            openExitNodeEditAction(node)
        else{
            dispatch(selectNode(node))
        }

    }

    const openExitNodeEditAction = (node, callback) => {
        setModal({
            action: MODAL_ACTION.EXIT_EDIT,
            content: selectedNode.isCreate ? intl.formatMessage({ "id": `${settingPrefix}.${editChatbotPrefix}.modal.warning.exitCreate` }) : intl.formatMessage({ "id": `${settingPrefix}.${editChatbotPrefix}.modal.warning.exitEdit` }),
            callback,
            switchNode: node ?? null
        })
        setModalOpen(true)
    }

    const openExitSettingAction = (type) => {
        setModal({
            action: MODAL_ACTION.EXIT_SETTING,
            type,
            content: flowSetting.id ? intl.formatMessage({ "id": `${settingPrefix}.${editChatbotPrefix}.modal.warning.exitEdit` }) : intl.formatMessage({ "id": `${settingPrefix}.${editChatbotPrefix}.modal.warning.exitCreate` }),
        })
        setModalOpen(true)
    }

    const openExitEditSettingAction = () => {
        setModal({
            action: MODAL_ACTION.EXIT_EDIT_SETTING,
            content: flowSetting.id ? intl.formatMessage({ "id": `${settingPrefix}.${editChatbotPrefix}.modal.warning.exitEdit` }) : intl.formatMessage({ "id": `${settingPrefix}.${editChatbotPrefix}.modal.warning.exitCreate` }),
        })
        setModalOpen(true)
    }

    const openPublishAction = () => {
        setModal({
            action: MODAL_ACTION.PUBLISH,
            content: intl.formatMessage({ "id": `${settingPrefix}.${editChatbotPrefix}.modal.warning.publish` })
        })
        setModalOpen(true)
    }

    const openRemoveNodeAction = () => {
        setModal({
            action: MODAL_ACTION.REMOVE,
            content: `${intl.formatMessage({ "id": `${settingPrefix}.${editChatbotPrefix}.modal.warning.remove` })}${intl.formatMessage({ "id": `${settingPrefix}.${editChatbotPrefix}.text.node` })}?`
        })
        setModalOpen(true)
    }

    const closeEditSettingAction = () => {
        setEditSettingPanelOpen(false)
        setFlowSetting(null)
    }

    const openRemoveSettingAction = (type, setting) => {
        setModal({
            action: MODAL_ACTION.REMOVE_SETTING,
            content: `${intl.formatMessage({ "id": `${settingPrefix}.${editChatbotPrefix}.modal.warning.remove` })}${intl.formatMessage({ "id": FLOW_SETTING_SUB_MENU_TYPE_NAME[type] })}?`
        })
        setModalOpen(true)
        setFlowSetting(setting)
    }

    const cloneSettingAction = (setting) => {

        const {id, ...cloneable} = JSON.parse(JSON.stringify(setting))

        switch (settingPanelType) {
            case SETTING_SUB_MENU_TYPE.KEYWORD:
                dispatch(createKeyword({flowId: selectedFlow.id, keyword: cloneable}))
                break;
            case SETTING_SUB_MENU_TYPE.ENTITY:
                dispatch(createEntity({flowId: selectedFlow.id, entity: cloneable}))
                break;
            case SETTING_SUB_MENU_TYPE.RESPONSE:
                dispatch(createResponse({flowId: selectedFlow.id, response: cloneable}))
                break;
            case SETTING_SUB_MENU_TYPE.ACTION:
                dispatch(createAction({flowId: selectedFlow.id, action: cloneable}))
                break;
            case SETTING_SUB_MENU_TYPE.PARAMETER:
                dispatch(createParameter({flowId: selectedFlow.id, parameter: cloneable}))
                break;
            default:
                break;
        }   
    }

    const updateNodeAction = (node) => {
        if(selectedNode.isCreate)
            dispatch(createNode(node))
        else
            dispatch(updateNode({...node, id: selectedNode.id}))
    }

    const modalConfirmAction = () => {
        switch (modal.action) {
            case MODAL_ACTION.EXIT_EDIT:
                dispatch(selectNode(modal.switchNode))
                if(modal.callback){
                    modal.callback()
                }
                closeWarningModalAction()
                break;        
            case MODAL_ACTION.PUBLISH:
                dispatch(publishFlow(selectedFlow))
                closeWarningModalAction()
                break;
            case MODAL_ACTION.REMOVE:
                dispatch(removeNode({ id: selectedNode.id, flowId: selectedNode.flowId }))
                closeWarningModalAction()
                break;        
            case MODAL_ACTION.REMOVE_SETTING:
                removeFlowSetting(settingPanelType, flowSetting)
                closeEditSettingAction()
                closeWarningModalAction()
                // closeSettingAction()
                break;
            case MODAL_ACTION.EXIT_SETTING:
                setSettingPanelType(modal.type === settingPanelType ? '' : modal.type)
                // setNodeEditSection(NODE_EDIT_SECTION_TYPE.DEFAULT)
                setIsEditflowSetting(false)
                closeEditSettingAction()
                closeWarningModalAction()
                break;        
            case MODAL_ACTION.EXIT_EDIT_SETTING:
                setIsEditflowSetting(false)

                if(!flowSetting.id)
                    closeEditSettingAction()
                
                closeWarningModalAction()
                break;        
            default:
                break;
        }
    }

    const getFlowSettingSubMenuTitle = (type, action) => {
        switch (action) {
            case "searchTitle":
                return `${intl.formatMessage({ "id": `${settingPrefix}.${editChatbotPrefix}.text.search` })}${intl.formatMessage({ "id": FLOW_SETTING_SUB_MENU_TYPE_NAME[type] })}`;
            case "addTitle":
                return `${intl.formatMessage({ "id": `${settingPrefix}.${editChatbotPrefix}.text.new` })}${intl.formatMessage({ "id": FLOW_SETTING_SUB_MENU_TYPE_NAME[type] })}`;
            default:
                return "";
        }
    }

    const sortSettingList = (b, a, fieldName) => {
        return b[fieldName].toLowerCase().localeCompare(a[fieldName].toLowerCase());
    }

    const getSortedSettingList = (settingList, fieldName) => {
        let filteredSettingList = JSON.parse(JSON.stringify(settingList));

        if(filteredSettingList.length < 1)
            return filteredSettingList;

        filteredSettingList.sort((a, b) => sortSettingList(a, b, fieldName))

        return filteredSettingList;

    }

    const getFlowSettingList = (type) => {
        switch (type) {
            case SETTING_SUB_MENU_TYPE.KEYWORD:
                return getSortedSettingList(keywordList, "name");
            case SETTING_SUB_MENU_TYPE.ENTITY:
                return getSortedSettingList(entityList, "name");
            case SETTING_SUB_MENU_TYPE.RESPONSE:
                return getSortedSettingList(responseList, "name");
            case SETTING_SUB_MENU_TYPE.ACTION:
                return getSortedSettingList(actionList, "name");
            case SETTING_SUB_MENU_TYPE.PARAMETER:
                return getSortedSettingList(parameterList.filter(parameter => !parameter.isSystemVariable), "key");
            default:
                return [];
        }        
    }

    const getFlowSettingById = (type, id) => {
        let flowSettingList = []

        switch (type) {
            case SETTING_SUB_MENU_TYPE.KEYWORD:
                flowSettingList = keywordList
                break;
            case SETTING_SUB_MENU_TYPE.ENTITY:
                flowSettingList = entityList
                break;
            case SETTING_SUB_MENU_TYPE.RESPONSE:
                flowSettingList = responseList
                break;
            case SETTING_SUB_MENU_TYPE.ACTION:
                flowSettingList = actionList
                break;
            case SETTING_SUB_MENU_TYPE.PARAMETER:
                flowSettingList = parameterList
                break;
            default:
                break;
        }        

        let index = flowSettingList.findIndex(setting => setting.id === id)

        if(index === -1)
            return null
            
        return flowSettingList[index]
    }

    const editFlowSetting = () => {
        if(!settingPanelType || !flowSetting){
            return null;
        }

        let isEditable = selectedFlow.type !== "RELEASED"

        switch (settingPanelType) {
            case SETTING_SUB_MENU_TYPE.KEYWORD:
                return <EditKeyword
                            isEditable={isEditable}
                            isEdit={isEditflowSetting}
                            setIsEdit={setIsEditflowSetting}
                            item={flowSetting}
                            updateAction={(setting) => {
                                if(flowSetting.id)
                                    dispatch(updateKeyword({flowId: selectedFlow.id, keyword: setting}))
                                else
                                    dispatch(createKeyword({flowId: selectedFlow.id, keyword: setting}))

                                closeEditSettingAction()
                                setIsEditflowSetting(false)
                            }}
                            removeAction={() => openRemoveSettingAction(settingPanelType, flowSetting)}
                        />
            case SETTING_SUB_MENU_TYPE.ENTITY:
                return <EditEntity
                            isEditable={isEditable}
                            isEdit={isEditflowSetting}
                            setIsEdit={setIsEditflowSetting}
                            item={flowSetting}
                            updateAction={(setting) => {
                                if(flowSetting.id)
                                    dispatch(updateEntity({flowId: selectedFlow.id, entity: setting}))
                                else
                                    dispatch(createEntity({flowId: selectedFlow.id, entity: setting}))

                                closeEditSettingAction()
                                setIsEditflowSetting(false)
                            }}
                            removeAction={() => openRemoveSettingAction(settingPanelType, flowSetting)}
                        />
            case SETTING_SUB_MENU_TYPE.RESPONSE:
                return <EditResponse
                            isEditable={isEditable}
                            isEdit={isEditflowSetting}
                            setIsEdit={setIsEditflowSetting}
                            item={flowSetting}
                            parameterList={getSortedSettingList(parameterList, "key")}
                            attachmentList={getSortedSettingList(attachmentList, "fileName")}
                            updateAction={(setting) => {
                                if(flowSetting.id)
                                    dispatch(updateResponse({flowId: selectedFlow.id, response: setting}))
                                else
                                    dispatch(createResponse({flowId: selectedFlow.id, response: setting}))

                                closeEditSettingAction()
                                setIsEditflowSetting(false)
                            }}
                            removeAction={() => openRemoveSettingAction(settingPanelType, flowSetting)}
                        />
            case SETTING_SUB_MENU_TYPE.ACTION:
                return <EditAction
                            isEditable={isEditable}
                            isEdit={isEditflowSetting}
                            setIsEdit={setIsEditflowSetting}
                            item={flowSetting}
                            parameterList={getSortedSettingList(parameterList, "key")}
                            responseList={getSortedSettingList(responseList, "name")}
                            updateAction={(setting) => {
                                if(flowSetting.id)
                                    dispatch(updateAction({flowId: selectedFlow.id, action: setting}))
                                else
                                    dispatch(createAction({flowId: selectedFlow.id, action: setting}))

                                closeEditSettingAction()
                                setIsEditflowSetting(false)
                            }}
                            removeAction={() => openRemoveSettingAction(settingPanelType, flowSetting)}
                        />
            case SETTING_SUB_MENU_TYPE.PARAMETER:
                return <EditParameter
                            isEditable={isEditable}
                            isEdit={isEditflowSetting}
                            setIsEdit={setIsEditflowSetting}
                            item={flowSetting}
                            updateAction={(setting) => {
                                if(flowSetting.id)
                                    dispatch(updateParameter({flowId: selectedFlow.id, parameter: setting}))
                                else
                                    dispatch(createParameter({flowId: selectedFlow.id, parameter: setting}))

                                closeEditSettingAction()
                                setIsEditflowSetting(false)
                            }}
                            removeAction={() => openRemoveSettingAction(settingPanelType, flowSetting)}
                        />
            default:
                return null;
        }        
    }

    const removeFlowSetting = (type, setting) => {
        switch (type) {
            case SETTING_SUB_MENU_TYPE.KEYWORD:
                dispatch(removeKeyword({flowId: selectedFlow.id, id: setting.id}))
                break;
            case SETTING_SUB_MENU_TYPE.ENTITY:
                dispatch(removeEntity({flowId: selectedFlow.id, id: setting.id}))
                break;
            case SETTING_SUB_MENU_TYPE.RESPONSE:
                dispatch(removeResponse({flowId: selectedFlow.id, id: setting.id}))
                break;
            case SETTING_SUB_MENU_TYPE.ACTION:
                dispatch(removeAction({flowId: selectedFlow.id, id: setting.id}))
                break;
            case SETTING_SUB_MENU_TYPE.PARAMETER:
                dispatch(removeParameter({flowId: selectedFlow.id, id: setting.id}))
                break;
            default:
                break;
        }        
    }
    
    const getFlowSettingFieldName = (type) => {
        switch (type) {
            case SETTING_SUB_MENU_TYPE.PARAMETER:
                return "key";
            default:
                return "name";
        }        
    }
    
    // const getFlowSettingRemoveable = (type, item) => {
    //     switch (type) {
    //         case SETTING_SUB_MENU_TYPE.PARAMETER:
    //             return !item.isSystemVariable;
    //         default:
    //             return true;
    //     }        
    // }

    const getFlowGraphContainerSize = () => {
        if(selectedNode && settingPanelType)
            return "flow-graph-container-all"
        else if(selectedNode)
            return "flow-graph-container-node"
        else if(settingPanelType)
            return "flow-graph-container-setting"

        return "flow-graph-container"
    }

    const confirmImportFlow = () => {
        if(!importObj.flow){
            setWarning('missing import flow')
            setWarningOpen(true)
            return;
        }
        
        if(!importObj.flow.name){
            setWarning('missing import flow name')
            setWarningOpen(true)        
            return;
        }

        let newFlow = {...importObj.flow};
    
        newFlow.remark = newFlow.remark ? newFlow.remark : ''
    
        dispatch(importFlow({
            flow: newFlow,
            actionList: importObj.actionList ? importObj.actionList : [],
            entityList: importObj.entityList ? importObj.entityList : [],
            keywordList: importObj.keywordList ? importObj.keywordList : [],
            filterList: importObj.filterList ? importObj.filterList : [],
            parameterList: importObj.parameterList ? importObj.parameterList : [],
            nodeList: importObj.nodeList ? importObj.nodeList : [],
            responseList: importObj.responseList ? importObj.responseList : [],
        }));

        closeImportAction()
    }

    const onImportFlow = (event) => {
        const file = event.target.files[0];

        if(file){
            if(file.type !== 'application/json'){
                // this.showToastMessage('Warning', 'Upload should be a Json file', "warning");
                setWarning('Upload should be a Json file')
                setWarningOpen(true)        
                return;
            }

            let fileReader = new FileReader();

            fileReader.onload = (e) => {
                let importObj = null;

                try{
                    importObj = JSON.parse(e.target.result);

                    setImportObj(importObj);
                    setImportModal({
                        content: JSON.stringify(importObj, null, 2)
                    })
                    setImportModalOpen(true)
                }
                catch(e){
                    setWarning('File contains invalid json data')
                    setWarningOpen(true)            
                    return;
                }
                    
            }

            fileReader.readAsText(file);
        }        

        event.target.value = null;
    }

    const focusNodeInGraph = (focusingNode) => {
        let filteredNodeList = JSON.parse(JSON.stringify(nodeList)).filter(node => !node.isGeneralNode);

        let index = filteredNodeList.findIndex(node => node.id === focusingNode.id)

        if(index === -1){
            setWarning("Node does not exist")
            setWarningOpen(true)
            return;
        }

        dispatch(foucsNode(focusingNode))
    }

    const sendTestChatbotMessage = (msgText) => {
        dispatch(testChatbot({flowId: selectedFlow.id, message: msgText}))
    }

    const closeWarningModalAction = () => {        
        setModalOpen(false)
        setModal(null)
    }

    const closeImportAction = () => {        
        setImportModalOpen(false)
        setImportModal(null)
    }

    const refresh = () => {
        setNodeEditSection('')
        closeEditSettingAction()
        closeImportAction()
        closeWarningModalAction()
        setShowPreviewFlow(false)
        dispatch(resetTestChatbot())
        dispatch(selectFlow(selectedFlow))
        dispatch(fetchAttachmentList())
    }

    useEffect(() => {
        if(nodeError){
            setWarning(nodeError)
            setWarningOpen(true)
        }
        else if(keywordError){
            setWarning(keywordError)
            setWarningOpen(true)
        }
        else if(entityError){
            setWarning(entityError)
            setWarningOpen(true)
        }
        else if(responseError){
            setWarning(responseError)
            setWarningOpen(true)
        }
        else if(actionError){
            setWarning(actionError)
            setWarningOpen(true)
        }
        else if(parameterError){
            setWarning(parameterError)
            setWarningOpen(true)
        }
        else if(testError){
            setWarning(testError)
            setWarningOpen(true)
        }
        else if(attachmentError){
            setWarning(attachmentError)
            setWarningOpen(true)
        }
    }, [nodeError, keywordError, entityError, responseError, actionError, parameterError, testError, attachmentError])

    // useEffect(() => {
    //     dispatch(selectNode(null))
    // }, [nodeList])

    useEffect(() => {
        let readOnlyKeywordList = JSON.parse(JSON.stringify(keywordList));
        setKeywordNameMap(readOnlyKeywordList.reduce((map, item) => ({...map, [item.id]: item.name}), {}))
    }, [keywordList])

    useEffect(() => {
        let readOnlyEntityList = JSON.parse(JSON.stringify(entityList));
        setEntityNameMap(readOnlyEntityList.reduce((map, item) => ({...map, [item.id]: item.name}), {}))
    }, [entityList])

    useEffect(() => {
        let readOnlyResponseList = JSON.parse(JSON.stringify(responseList));
        setResponseNameMap(readOnlyResponseList.reduce((map, item) => ({...map, [item.id]: item.name}), {}))
    }, [responseList])

    useEffect(() => {
        let readOnlyActionList = JSON.parse(JSON.stringify(actionList));
        setActionNameMap(readOnlyActionList.reduce((map, item) => ({...map, [item.id]: item.name}), {}))
    }, [actionList])

    useEffect(() => {
        let readOnlyParameterList = JSON.parse(JSON.stringify(parameterList));
        setParameterNameMap(readOnlyParameterList.reduce((map, item) => ({...map, [item.id]: item.key}), {}))
    }, [parameterList])

    useEffect(() => {
        if(!selectedNode)
            setNodeEditSection('')
    }, [selectedNode])

    useEffect(() => {
        refresh()
    }, [selectedFlow, dispatch])

    let dateStr = getChatFormatedFullDateTime(selectedFlow.deploymentTimestamp);
    let dateList = dateStr.split(" ")

    let editSetting = editFlowSetting()

    let sortedNodeList = getSortedSettingList(nodeList, "name")
    let sortedKeywordList = getSortedSettingList(keywordList, "name")
    let sortedEntityList = getSortedSettingList(entityList, "name")
    let sortedResponseList = getSortedSettingList(responseList, "name")
    let sortedActionList = getSortedSettingList(actionList, "name")
    let sortedParameterList = getSortedSettingList(parameterList, "key")

    return (
        <>
            {/* <CusModal full show={editModalOpen && !!editSetting}>
                {editModalOpen && !!editSetting ?
                    <CusModal.Frame className={`w-[100vw] md:w-[630px] ${getFlowSettingContainerSize(subMenuType)}`}>
                        {editSetting}
                    </CusModal.Frame>
                    :
                    null
                }
            </CusModal> */}
            <CusModal full show={modalOpen}>
                {modal ? 
                    <CusModal.Frame className={`w-[348px]`}>
                        <CusModal.Title title={intl.formatMessage({ "id": `${generalPrefix}.modal.title.reminder`})} closeAction={closeWarningModalAction}/>
                        <div className="min-h-[71px] pl-7 pr-5 pt-2 pb-4 text-lg text-greyblack">{modal.content}</div>
                        <hr/>
                        <div className="pt-[23px] px-4 w-full flex min-h-[91.82px]">
                            <>
                                <CusRoundedButton className='w-full flex px-2 mx-2 justify-center items-center' color={ButtonColor.primaryFrame}
                                    onClick={closeWarningModalAction}
                                >
                                    <span>{intl.formatMessage({ "id": `${generalPrefix}.button.cancel`})}</span>
                                </CusRoundedButton>
                                <CusRoundedButton className='w-full flex px-2 mx-2 justify-center items-center' color={ButtonColor.primary}
                                    onClick={modalConfirmAction}
                                >
                                    <span>{intl.formatMessage({ "id": `${generalPrefix}.button.confirm`})}</span>
                                </CusRoundedButton>
                            </>
                        </div>
                    </CusModal.Frame>
                    :
                    null
                }                
            </CusModal>    
            <CusModal full show={importModalOpen}>
                {importModalOpen ?
                    <CusModal.Frame className={`w-[100vw] md:w-[630px] h-[100vh] md:h-[75vh] flex flex-col`}>
                        <CusModal.Title title={intl.formatMessage({ "id": `${settingPrefix}.${editChatbotPrefix}.modal.title.import`})} closeAction={closeImportAction}/>
                        <div className="whitespace-pre-wrap flex w-full flex-1 p-5 text-lg text-greyblack">
                            <CusCard tabIndex={1} className=" text-base p-2 w-full flex-1 border-2 border-gray-300 focus-within:border-gray-700 select-text">
                                <Scrollbars
                                    autoHide={false}
                                    className="w-full h-full"
                                >
                                    {importModal.content}
                                </Scrollbars>
                            </CusCard>
                        </div>
                        <hr/>
                        <div className="pt-[23px] px-4 w-full flex min-h-[91.82px]">
                            <>
                                <CusRoundedButton className='w-full flex px-2 mx-2 justify-center items-center' color={ButtonColor.primaryFrame}
                                    onClick={closeImportAction}
                                >
                                    <span>{intl.formatMessage({ "id": `${generalPrefix}.button.cancel`})}</span>
                                </CusRoundedButton>
                                <CusRoundedButton className='w-full flex px-2 mx-2 justify-center items-center' color={ButtonColor.primary}
                                    onClick={confirmImportFlow}
                                >
                                    <span>{intl.formatMessage({ "id": `${generalPrefix}.button.confirm`})}</span>
                                </CusRoundedButton>
                            </>
                        </div>
                    </CusModal.Frame>
                    :
                    null
                }
            </CusModal>                               
            <WarningSnackbar message={warning} open={warningOpen} setOpen={setWarningOpen} />
            <div className="absolute overflow-hidden w-full h-full top-0 left-0 right-0 bottom-0 z-[2] bg-white z-2">
                <div className="flex w-full h-[64px] justify-between items-center py-2 px-4">
                    <div className="flex items-center">
                        <div className="px-3">
                            <div className="cursor-pointer text-greyblack" onClick={switchExit}>
                                <LeftArrow />
                            </div>
                        </div>
                        <div className="flex items-center">
                            <span className="flex items-center text-[20px] font-medium text-greyblack px-2">{selectedFlow.name}</span>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <input className="hidden" ref={realFileInput} type="file" accept="application/json" onChange={onImportFlow} />
                        <div className="flex text-greyblack pl-6 pr-4">
                            <div
                                title="Refresh"
                                className="cursor-pointer"
                                onClick={refresh}
                            >
                                <Refresh/>
                            </div>
                        </div>
                        <div className='w-[1px] h-[40px] border-[#E2E8F0] border-r-[1px]'/>
                        <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex flex-shrink-0 text-base ml-2 px-2 h-[40px] items-center rounded-[5px]' color={ButtonColor.darkNoBorder}
                            onClick={() => realFileInput.current.click()}
                        >
                            <Import/>
                            <span className="pl-2 pr-3">{intl.formatMessage({ "id": `${settingPrefix}.${editChatbotPrefix}.button.import` })}</span>
                        </CusRoundedButton>
                        <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex flex-shrink-0 text-base ml-4 px-2 h-[40px] items-center rounded-[5px]' color={ButtonColor.darkNoBorder}
                            onClick={() => dispatch(exportFlow({id: selectedFlow.id}))}
                        >
                            <Export/>
                            <span className="pl-2 pr-3">{intl.formatMessage({ "id": `${settingPrefix}.${editChatbotPrefix}.button.export` })}</span>
                        </CusRoundedButton>

                        <CusRoundedButton paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex flex-shrink-0 text-base ml-4 px-2 h-[40px] items-center rounded-[5px]' color={ButtonColor.darkFrame}
                            onClick={() => setShowPreviewFlow(!showPreviewFlow)}
                        >
                            <Preview/>
                            <span className="pl-2 pr-3">{intl.formatMessage({ "id": `${settingPrefix}.${editChatbotPrefix}.button.preview` })}</span>
                        </CusRoundedButton>
                        <CusRoundedButton disabled={selectedFlow.type === "RELEASED" || !!selectedNode} paddingType={ButtonPadding.noPadding} roundType={ButtonRoundType.none} className='flex flex-shrink-0 text-base ml-4 px-2 h-[40px] items-center rounded-[5px]' color={ButtonColor.primary}
                            onClick={openPublishAction}
                        >
                            <span className="px-3">{intl.formatMessage({ "id": `${settingPrefix}.${editChatbotPrefix}.button.publish` })}</span>
                        </CusRoundedButton>
                    </div>
                </div>
                <hr />
                <div className="flex w-full h-[calc(100%-64px)]">
                    <IconBar
                        activeIcon={settingPanelType}
                        selectIcon={(type) => openSettingPanelAction(type)}
                    />
                    {!editsettingPanelOpen && settingPanelType &&
                        <FlowSettingPanel
                            title={settingPanelType ? intl.formatMessage({ "id": FLOW_SETTING_MENU_TYPE_NAME[settingPanelType]}) : ""}
                            closeAction={() => openSettingPanelAction(settingPanelType)}
                        >
                            {!!settingPanelType && (settingPanelType === SETTING_SUB_MENU_TYPE.LIST) &&
                                // <NodeSettingListItem
                                //     isChild
                                //     title={sortedNodeList[0].name}
                                //     onExpand={() => {}}
                                //     selectAction={() => {}}
                                //     addNewAction={() => {}}
                                // />
                                <div className="w-full h-full pr-3 py-2">
                                    <Scrollbars
                                            autoHide={false}
                                            className='w-full h-full'
                                    >
                                        <NodeSettingFolder
                                            readOnly={selectedFlow.type === "RELEASED"}
                                            nodeExpandedMap={nodeExpandedMap}
                                            nodeMapping={nodeMap}
                                            selectedNode={focusedNode}
                                            onExpand={(id) => nodeFolderExpand(id)}
                                            selectAction={(id) => selectNodeFromId(id)}
                                            addNewAction={(id) => addNodeFromId(id)}
                                        />
                                    </Scrollbars>
                                </div>
                            }
                            {!!settingPanelType && (settingPanelType !== SETTING_SUB_MENU_TYPE.LIST) &&
                                <FlowSettingList
                                    readOnly={selectedFlow.type === "RELEASED"}
                                    searchTitle={getFlowSettingSubMenuTitle(settingPanelType, "searchTitle")}
                                    addTitle={getFlowSettingSubMenuTitle(settingPanelType, "addTitle")}
                                    settingList={getFlowSettingList(settingPanelType)}
                                    fieldName={getFlowSettingFieldName(settingPanelType)}
                                    addNewAction={() => openEditSettingPanelAction({flowId: selectedFlow.flowId, isNew: true})}
                                    editAction={(setting) => openEditSettingPanelAction(setting)}
                                    // addNewAction={() => {}}
                                    // editAction={() => {}}
                                    cloneAction={(setting) => cloneSettingAction(setting)}
                                    removeAction={(setting) => openRemoveSettingAction(settingPanelType, setting)}
                                />
                            }
                        </FlowSettingPanel>
                    }
                    {editsettingPanelOpen && settingPanelType &&
                        <FlowSettingPanel
                            title={intl.formatMessage({ "id": FLOW_SETTING_MENU_TYPE_EDIT_NAME[settingPanelType]})}
                            cloneAction={isEditflowSetting ? null : () => {cloneSettingAction(flowSetting);closeEditSettingAction()}}
                            backAction={() => closeEditSettingPanelAction()}
                            closeAction={() => openSettingPanelAction(settingPanelType)}
                        >
                            {editSetting}
                        </FlowSettingPanel>
                    }
                    <div className={`relative flex h-full ${settingPanelType ? "w-[calc(100%-320px-96px)]" : "w-[calc(100%-96px)]"}`}>
                        <Overlay className={`bg-[#F1F5F9] opacity-80 z-[1000]`} full={false} show={isEditflowSetting}/>
                        <div className={`overflow-hidden bg-[#E2E8F0] flex h-full ${selectedNode ? "w-[calc(100%-360px)]" : "w-full"}`}>
                            <FlowGraphPanel
                                isEdit={isEditNode}
                                nodeList={nodeList}
                                keywordNameMap={keywordNameMap}
                                entityNameMap={entityNameMap}
                                responseNameMap={responseNameMap}
                                actionNameMap={actionNameMap}
                                parameterNameMap={parameterNameMap}
                                nodeEditSection={nodeEditSection}
                                setNodeEditSection={setNodeEditSection}
                                selectedNode={focusedNode}
                                selectNode={graphSelectNodeAction}
                                selectedFlow={selectedFlow}
                            />
                            <PreviewFlow
                                show={showPreviewFlow}
                                setShow={setShowPreviewFlow}
                                loading={testLoading}
                                messages={testMessages}
                                nodeHistory={testNodeHistory}
                                selectedNode={focusedNode}
                                focusNode={(node) => focusNodeInGraph(node)}
                                sendAction={(text) => sendTestChatbotMessage(text)}
                                refresh={() => dispatch(resetTestChatbot())}
                            />
                        </div>
                        {selectedNode ?
                            <div className="flex h-full w-[360px]">
                                <NodeEditPanel
                                    isEdit={isEditNode}
                                    setIsEdit={setIsEditNode}
                                    nodeEditSection={nodeEditSection}
                                    setNodeEditSection={setNodeEditSection}
                                    selectedFlow={selectedFlow}
                                    selectedNode={selectedNode}
                                    nodeList={sortedNodeList}
                                    keywordList={sortedKeywordList}
                                    entityList={sortedEntityList}
                                    responseList={sortedResponseList}
                                    actionList={sortedActionList}
                                    parameterList={sortedParameterList}
                                    updateNodeAction={(node) => updateNodeAction(node)}
                                    removeNodeAction={openRemoveNodeAction}
                                    cancelEditAction={(selectedFlow.type !== "RELEASED" && !!selectedNode && isEditNode) ? (node) => openExitNodeEditAction(node) : () => dispatch(selectNode(null))}
                                    openEditSettingAction={openEditSettingPanelAction}
                                    getFlowSettingById={getFlowSettingById}  
                                />
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
            </div>          
            {/* <DynamicContent screenX={menuPos ? menuPos.x : undefined} screenY={menuPos ? menuPos.y : -1} show={showMenu} setShow={setShowMenu} onUnFocus={closeSettingAction}>
                <CusGroupDropdown cardSize="w-[160px] h-auto">
                    <>
                        <CusGroupDropdown.Item
                            className="sub-menu-pos"
                            onClick={(e) => openSubMenuAction(e, SETTING_SUB_MENU_TYPE.KEYWORD)}
                        >
                            <span className='mx-[2px] text-sm text-greyblack'>{intl.formatMessage({ "id": FLOW_SETTING_SUB_MENU_TYPE_NAME.KEYWORD })}</span>
                        </CusGroupDropdown.Item>
                        <CusGroupDropdown.Item
                            className="sub-menu-pos"
                            onClick={(e) => openSubMenuAction(e, SETTING_SUB_MENU_TYPE.ENTITY)}
                        >
                            <span className='mx-[2px] text-sm text-greyblack'>{intl.formatMessage({ "id": FLOW_SETTING_SUB_MENU_TYPE_NAME.ENTITY })}</span>
                        </CusGroupDropdown.Item>
                        <CusGroupDropdown.Item
                            className="sub-menu-pos"
                            onClick={(e) => openSubMenuAction(e, SETTING_SUB_MENU_TYPE.RESPONSE)}
                        >
                            <span className='mx-[2px] text-sm text-greyblack'>{intl.formatMessage({ "id": FLOW_SETTING_SUB_MENU_TYPE_NAME.RESPONSE })}</span>
                        </CusGroupDropdown.Item>
                        <CusGroupDropdown.Item
                            className="sub-menu-pos"
                            onClick={(e) => openSubMenuAction(e, SETTING_SUB_MENU_TYPE.ACTION)}
                        >
                            <span className='mx-[2px] text-sm text-greyblack'>{intl.formatMessage({ "id": FLOW_SETTING_SUB_MENU_TYPE_NAME.ACTION })}</span>
                        </CusGroupDropdown.Item>
                        <CusGroupDropdown.Item
                            className="sub-menu-pos"
                            onClick={(e) => openSubMenuAction(e, SETTING_SUB_MENU_TYPE.PARAMETER)}
                        >
                            <span className='mx-[2px] text-sm text-greyblack'>{intl.formatMessage({ "id": FLOW_SETTING_SUB_MENU_TYPE_NAME.PARAMETER })}</span>
                        </CusGroupDropdown.Item>
                        {subMenuPos ?
                            <div
                                className="absolute"
                                style={{"left": `${subMenuPos ? subMenuPos.x : 0}px`, "top": `${subMenuPos ? subMenuPos.y : 0}px`}}
                                onClick={(e) => e.stopPropagation()}
                                onContextMenu={closeSettingAction}                
                            >
                                <FlowSettingDropdown
                                    readOnly={selectedFlow.type === "RELEASED"}
                                    searchTitle={getFlowSettingSubMenuTitle(subMenuType, "searchTitle")}
                                    addTitle={getFlowSettingSubMenuTitle(subMenuType, "addTitle")}
                                    settingList={getFlowSettingList(subMenuType)}
                                    fieldName={getFlowSettingFieldName(subMenuType)}
                                    addNewAction={() => openEditSettingAction({flowId: selectedFlow.flowId})}
                                    editAction={(setting) => openEditSettingAction(setting)}
                                    // checkRemoveable={(item) => getFlowSettingRemoveable(subMenuType, item)}
                                    removeAction={(setting) => openRemoveSettingAction(subMenuType, setting)}
                                />                              
                            </div>
                            :
                            null
                        }                     
                    </>
                </CusGroupDropdown>
            </DynamicContent>  */}
        </>
    )
}

EditFlow.propTypes = {
    selectedFlow: PropTypes.object,
    loading: PropTypes.bool,
    openExitEditAction: PropTypes.func,
};

export default EditFlow;