import ChatMessage from "components/ChatMessage";
import { MESSAGE_CONTAINER_TYPE } from "components/ChatMessage/util";
import { MESSAGE_STATUS } from "util/helper";

const ReceivedMessage = ({
    fromUser,
    active,
    message,
    messageMedia,
    containerSize,
    status,
    isBookmarked,
    readOnlyBookmark,
    onClick,
    bookmarkAction,
}) => (
    <ChatMessage
        name={fromUser ?? ""}
        messageId={message.messageId}
        messageType={message.messageType}
        mimeType={message.mimeType}
        downloading={messageMedia && messageMedia.downloading}
        timestamp={message.timestamp}
        message={message.message}
        containerSize={containerSize ?? "max-w-[75%]"}

        status={(messageMedia && (messageMedia.success === -1) ? MESSAGE_STATUS.FAILED : "")}
        containerType={MESSAGE_CONTAINER_TYPE.CUSTOMER}
        messageMedia={!!messageMedia && (messageMedia.success === 1) ? messageMedia : null}
        downloadDisabled={status === MESSAGE_STATUS.FAILED || (messageMedia ? (messageMedia.success === -1) : false)}
        isBookmarked={isBookmarked}
        readOnlyBookmark={readOnlyBookmark}
        onClick={onClick}
        bookmarkAction={status === MESSAGE_STATUS.FAILED ? null : bookmarkAction}
        active={active}
    />
)

export default ReceivedMessage;