import PropTypes from 'prop-types';

import { EDITABLE_TYPE, setDefaultField, validateField } from 'components/CusEditable';
import CusEditableLeftLabel from 'components/CusEditable/CusEditableLeftLabel';
import NonExpanableListItem from 'components/NonExpanableListItem';
import { useLanguage } from 'hooks/useLanguage';
import { checkListItemDuplication } from 'util/helper';
import Info2 from 'components/Icon/Info2';
import { useIntl } from 'react-intl';
import { editNodePrefix, settingPrefix, errorPrefix, editChatbotPrefix } from 'lang/locales/prefix';
import { FLOW_SETTING_SUB_MENU_TYPE_NAME, SETTING_SUB_MENU_TYPE } from '../EditFlow';

export const CONDITION_SUB_TYPE = Object.freeze({
    "KEYWORD": "KEYWORD",
    "ENTITY": "ENTITY",
    "PARAMETER": "PARAMETER",
}) 

const CONDITION_SUB_TYPE_LIST = Object.freeze([
    "KEYWORD", "ENTITY", "PARAMETER"
])

export const CONDITION_SUB_TEMPLATE = Object.freeze({
    "conditionSubType" : "",
})

const CONDITION_SUB_FIELD_TYPE = [
    {
        "fieldName": "conditionSubType",
        "displayNameKey": `${settingPrefix}.${editNodePrefix}.field.extra.type`,
        "displayNameEn": "Type",
        "displayNameTc": "類型",
        "displayNameSc": "类型",
        "fieldType": EDITABLE_TYPE.PICKLIST,
        "options": CONDITION_SUB_TYPE_LIST,
        "required": true,
        "editable": true,
        "creatable": true,
    },
]

export const CONDITION_SUB_KEYWORD_FIELD_TYPE = [
    {
        "fieldName": "keywordId",
        "displayNameKey": `${settingPrefix}.${editChatbotPrefix}.nameList.setting.keyword`,
        "displayNameEn": "Keyword",
        "displayNameTc": "關鍵詞",
        "displayNameSc": "关键词",
        "fieldType": EDITABLE_TYPE.PICKLIST,
        "options": [],
        "required": true,
        "editable": true,
        "creatable": true,
    },
]

export const CONDITION_SUB_ENTITY_FIELD_TYPE = [
    {
        "fieldName": "entityId",
        "displayNameKey": `${settingPrefix}.${editChatbotPrefix}.nameList.setting.entity`,
        "displayNameEn": "Entity",
        "displayNameTc": "實體",
        "displayNameSc": "实体",
        "fieldType": EDITABLE_TYPE.PICKLIST,
        "options": [],
        "required": true,
        "editable": true,
        "creatable": true,
    },
    {
        "fieldName": "value",
        "displayNameKey": `${settingPrefix}.${editNodePrefix}.field.extra.value`,
        "displayNameEn": "Value",
        "displayNameTc": "值",
        "displayNameSc": "价值",
        "fieldType": EDITABLE_TYPE.TEXT,
        "required": false,
        "editable": true,
        "creatable": true,
    },
]

export const CONDITION_SUB_PARAMETER_FIELD_TYPE = [
    {
        "fieldName": "parameterName",
        "displayNameKey": `${settingPrefix}.${editNodePrefix}.field.extra.parameterName`,
        "displayNameEn": "Variable name",
        "displayNameTc": "變量名",
        "displayNameSc": "变量名",
        "fieldType": EDITABLE_TYPE.PICKLIST,
        "options": [],
        "required": true,
        "editable": true,
        "creatable": true,
    },
]

export const getSubTypeFieldByCondition = (type) => {
    switch (type) {
        case CONDITION_SUB_TYPE.KEYWORD:
            return [...CONDITION_SUB_FIELD_TYPE, ...CONDITION_SUB_KEYWORD_FIELD_TYPE];
        case CONDITION_SUB_TYPE.ENTITY:
            return [...CONDITION_SUB_FIELD_TYPE, ...CONDITION_SUB_ENTITY_FIELD_TYPE];
        case CONDITION_SUB_TYPE.PARAMETER:
            return [...CONDITION_SUB_FIELD_TYPE, ...CONDITION_SUB_PARAMETER_FIELD_TYPE];
        default:
            return [...CONDITION_SUB_FIELD_TYPE]
    }
}

export const getSubTypeFieldByFieldName = (fieldName) => {
    switch (fieldName) {
        case "keywordId":
            return SETTING_SUB_MENU_TYPE.KEYWORD;
        case "entityId":
            return SETTING_SUB_MENU_TYPE.ENTITY;
        default:
            return ""
    }
}

export const validateConditionListItems = (errorFormater, isCreate, fieldList) => {
    let errorList = [];

    for(let index = 0; index < fieldList.length; index++){
        let conditionListItem = {...fieldList[index]}
        let errorMap = {}

        let mapping = [...getSubTypeFieldByCondition(conditionListItem.conditionSubType)]

        for(let field of mapping){
            if(!conditionListItem[field.fieldName])
                setDefaultField(field, conditionListItem, field.fieldName, isCreate)
            errorMap = {...errorMap, ...validateField(field, conditionListItem[field.fieldName], errorFormater, isCreate)}
        }

        errorList[index] = errorMap
    }

    let fieldSet = fieldList.filter(field => field.conditionSubType === CONDITION_SUB_TYPE.PARAMETER).map(field => field.conditionSubType)

    let duplicates = checkListItemDuplication(fieldSet)
    duplicates.forEach(i => {
        if(!errorList[i])
            errorList[i] = {}

        errorList[i].conditionSubType = { message: "Can only set one Variable per Condition" }
    })

    let keywordSet = fieldList.filter(field => !!field.keywordId).map(field => field.keywordId)

    let keywordSetDuplicates = checkListItemDuplication(keywordSet)
    keywordSetDuplicates.forEach(i => {
        if(!errorList[i])
            errorList[i] = {}

        errorList[i].keywordId = { message: errorFormater({ "id": `${errorPrefix}.duplicates` }) }
    })

    let entitySet = fieldList.filter(field => !!field.entityId).map(field => field.entityId)

    let entitySetDuplicates = checkListItemDuplication(entitySet)
    entitySetDuplicates.forEach(i => {
        if(!errorList[i])
            errorList[i] = {}

        errorList[i].entityId = { message: errorFormater({ "id": `${errorPrefix}.duplicates` }) }
    })

    return errorList
}

const ConditionListItem = ({
    isCreate = false,
    readOnly = false,
    itemIndex = -1,
    parameterList = [],
    keywordList = [],
    entityList = [],
    conditionListItem = null,
    error = null,
    updateCondition = null,
    removeCondition = null,
    openEditSettingAction = null,
    getFlowSettingById = null,
}) => {

    const intl = useIntl();
    const { language } = useLanguage()

    const CONDITION_SUB_FIELD_TYPE_NAME = Object.freeze({
        "KEYWORD": FLOW_SETTING_SUB_MENU_TYPE_NAME.KEYWORD,
        "ENTITY": FLOW_SETTING_SUB_MENU_TYPE_NAME.ENTITY,
        "PARAMETER": FLOW_SETTING_SUB_MENU_TYPE_NAME.PARAMETER,
    }) 
    
    const getOptions = (fieldType, fieldName, options) => {
        if(fieldType !== EDITABLE_TYPE.PICKLIST)
            return []

        if(fieldName === "conditionSubType")
            return options.map(option => ({label: option ? intl.formatMessage({ "id": CONDITION_SUB_FIELD_TYPE_NAME[option] }) : "", value: option}))

        if(fieldName === "parameterName")
            return parameterList.filter(parameter => parameter.key !== "SYS_LANGUAGE").map(parameter => ({label: parameter.key, value: parameter.key}))

        if(fieldName === "keywordId")
            return keywordList.map(keyword => ({label: keyword.name, value: keyword.id}))

        if(fieldName === "entityId")
            return entityList.map(entity => ({label: entity.name, value: entity.id}))

        return options.map(option => ({label: option, value: option}))
    }

    const onChangeHandler = (isCreate, field, e) => {
        
        let value = e.target.value;
        let currentErrorMap = {...error};
        let errorMap = {};

        switch (field.fieldType) {
            case EDITABLE_TYPE.BOOLEAN:
            case EDITABLE_TYPE.BOOLEAN_CHECKBOX:
                value = !conditionListItem[field.fieldName]
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;        
            default:
                errorMap = validateField(field, value, intl.formatMessage, isCreate)
                break;
        }

        if(errorMap && Object.keys(errorMap).length > 0)
            currentErrorMap = {...currentErrorMap, ...errorMap}
        else
            delete currentErrorMap[field.fieldName]
        
        if(field.fieldName === "conditionSubType"){
            let newFieldListItem = {...conditionListItem}
            newFieldListItem[field.fieldName] = value

            updateCondition(newFieldListItem, currentErrorMap)
            return;
        }

        updateCondition({...conditionListItem, [field.fieldName]: value}, currentErrorMap)
    }

    return(
        <NonExpanableListItem
            title={`${intl.formatMessage({ "id": `${settingPrefix}.${editNodePrefix}.expanable.text.condition`})}#${itemIndex + 1}`}
            remove={readOnly ? null : removeCondition}
            readOnly={readOnly || itemIndex === 0}
        >
            <div className="flex flex-col py-4">
                {getSubTypeFieldByCondition(conditionListItem.conditionSubType).map((field) => (
                    getSubTypeFieldByFieldName(field.fieldName) ?
                        <div
                            key={field.fieldName}
                            className="py-2 flex"
                        >
                            <CusEditableLeftLabel
                                key={field.fieldName}
                                readOnly={readOnly || (!isCreate ? !field.editable : !field.creatable)}
                                fieldType={field.fieldType}
                                label={intl.formatMessage({ "id": field.displayNameKey })}
                                value={conditionListItem[field.fieldName] ?? field.defaultValue}
                                options={getOptions(field.fieldType, field.fieldName, field.options)}
                                errors={error}
                                required={field.required}
                                errorName={field.fieldName}
                                onChange={(e) => onChangeHandler(isCreate, field, e)}
                            />
                            <div className={`p-2 text-grey051 responseMsg-pos`}>
                                <div className={`${(conditionListItem[field.fieldName] ?? field.defaultValue) ? "cursor-pointer" : "opacity-50" }`} onClick={(conditionListItem[field.fieldName] ?? field.defaultValue) ? (e) => openEditSettingAction(getFlowSettingById(getSubTypeFieldByFieldName(field.fieldName), conditionListItem[field.fieldName] ?? field.defaultValue), getSubTypeFieldByFieldName(field.fieldName)) : (e) => {} }>
                                    <span className="text-[13px]">EDIT</span>
                                </div>
                            </div>
                        </div>
                    :
                        <CusEditableLeftLabel
                            key={field.fieldName}
                            readOnly={readOnly || (!isCreate ? !field.editable : !field.creatable)}
                            fieldType={field.fieldType}
                            label={intl.formatMessage({ "id": field.displayNameKey })}
                            value={conditionListItem[field.fieldName] ?? field.defaultValue}
                            options={getOptions(field.fieldType, field.fieldName, field.options)}
                            errors={error}
                            required={field.required}
                            errorName={field.fieldName}
                            onChange={(e) => onChangeHandler(isCreate, field, e)}
                        />
                ))}
            </div>
        </NonExpanableListItem>
    )
}

ConditionListItem.propTypes = {
    isCreate: PropTypes.bool,
    readOnly: PropTypes.bool,
    itemIndex: PropTypes.number,
    parameterList: PropTypes.arrayOf(PropTypes.object),
    keywordList: PropTypes.arrayOf(PropTypes.object),
    entityList: PropTypes.arrayOf(PropTypes.object),
    conditionListItem: PropTypes.object,
    error: PropTypes.object,
    updateCondition: PropTypes.func,
    removeCondition: PropTypes.func,
    openEditSettingAction: PropTypes.func,
    getFlowSettingById: PropTypes.func,
};

export default ConditionListItem;
