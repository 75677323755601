

import Calendar from "components/Icon/Calendar";
import HidePreview from "components/Icon/HidePreview";
import Preview from "components/Icon/Preview";
import moment from "moment";
import PropTypes from "prop-types";
import React from 'react';
import { currentTimestamp } from "util/helper";
import './index.css';

const LabelType = Object.freeze(
    {
        "labelOnLeft": "labelOnLeft",
        "NoLabel": "NoLabel",
    }
)

const RoundType = Object.freeze(
    {
        "none": " ",
        "normal": " rounded-md "
    }
)

const BorderType = Object.freeze(
    {
        "none": " ",
        "Default": " border-2 focus-within:border-gray-700 border-gray-300 ",
        "Thin": " border-[1px] focus-within:border-gray-700 border-gray-300 ",
        "Error": " border-2 border-red01 focus-within:border-red01 "
    }
)

const TextSize = Object.freeze(
    {
        "default": " text-sm ",
        "flat": " text-[15px] "
    }
)

const PaddingType = Object.freeze(
    {
        "noPadding": " ",
        "normalPadding": " h-[40px] px-5 ",
        // "labelPadding": " pb-1 h-[34px] px-5 ",
        "labelPadding": " pb-1 h-[58px] px-5 pt-5",
        "labelPaddingFlat": " py-1 h-[58px] px-5 ",
        "flat": " py-1 h-[48px] px-5 ",
    }
)

const LabelPaddingType = Object.freeze(
    {
        "noPadding": " ",
        "normalPadding": " pt-1 px-5 ",
        "flat": " pt-1 px-8 ",
    }
)

const PickerType = Object.freeze(
    {
        "date": "date",
        "dateTime": "dateTime-local",
    }
)

const IconPaddingType = Object.freeze(
    {
        "noPadding": " ",
        "normalPadding": " right-3 ",
        "flat": " right-8 ",
    }
)

// https://stackoverflow.com/questions/2500588/maximum-year-in-expiry-date-of-credit-card

const CusRoundedDatePicker = React.forwardRef(({
    hasError = false,
    value = undefined,
    placeholder = "",
    type = PickerType.date,
    disabled = false,
    readOnly = false,
    onChange = ()=>{},
    labelType = LabelType.NoLabel,
    label = "",
    className = "",
    list = "",
    paddingType = PaddingType.normalPadding,
    labelPaddingType = "",
    textSize = TextSize.default,
    borderType = BorderType.Default,
    roundType = RoundType.normal,
    iconPaddingType = "",
    ...rest
}, ref) => {

    const now = currentTimestamp();
    const maxDate = moment(now).startOf('day').add(20, 'y').year()

    return(
        <div className={`
            ${label ? `relative flex flex-col outline-none 
                ${roundType}
                "pointer-events-none"
                ${borderType ? borderType: BorderType.Default}
                ${(readOnly && !disabled) ? `opacity-100 bg-grey02`: "bg-white"}`
            : ""}           
            ${className}`}
        >
            {label ?
                <span
                    className={
                        "text-sm absolute h-auto opacity-100 "
                        + (labelPaddingType? labelPaddingType: LabelPaddingType.normalPadding)
                        + (hasError ? " text-red01 " : " text-grey06 ")
                    }
                    // style={(disabled || value) ?
                    //     { "opacity": 1, "transform": "translateY(0)", "transitionProperty": "opacity, transform", "transitionDuration": "150ms" }
                    //     : { "opacity": 0, "transform": "translateY(-3)", "transitionProperty": "opacity, transform", "transitionDuration": "150ms" }
                    // }
                >
                    {label}
                </span>
                :
                null
            }
            {/* {label && (disabled || value) ?
                <span
                    className={
                        "text-sm "
                        + (labelPaddingType? labelPaddingType: LabelPaddingType.normalPadding)
                        + (hasError ? " text-red01 " : " text-grey06 ")
                    }                     
                >
                    {label}
                </span>
                :
                null
            } */}
            <div className={` ${!label ? "relative w-full" : ""}`}>
                {disabled ?
                        <div 
                            className={"flex items-center w-full truncate outline-none text-greyblack placeholder:text-greyblack placeholder:text-sm"
                                + roundType
                                + (textSize? textSize: TextSize.default)
                                // + (label ? PaddingType.labelPadding : (paddingType? paddingType: PaddingType.normalPadding))
                                + (label ? PaddingType.labelPadding : (paddingType? paddingType: PaddingType.normalPadding))
                                + (label ? BorderType.none : (borderType? borderType: BorderType.Default))
                                + (label ? "  disabled:bg-transparent" : "disabled:bg-disabled disabled:text-black disabled:opacity-100 ")
                            } 
                            {...rest}
                        >
                            {`${type === PickerType.dateTime ? (value ? moment(value).format("yyyy-MM-DD HH:mm") : value) : value}`}
                        </div>
                    :
                        <input 
                            ref={ref}
                            lang="en"
                            className={"datepicker-input w-full outline-none text-greyblack "
                                + roundType
                                + (textSize? textSize: TextSize.default)
                                + (hasError ? " placeholder:text-red01 " : " placeholder:text-grey06 ")
                                + (label ? "placeholder:text-base" : "")
                                + (label ? PaddingType.labelPadding : (paddingType? paddingType: PaddingType.normalPadding))
                                + (label ? BorderType.none : (borderType? borderType: BorderType.Default))
                                + (label ? " pointer-events-auto read-only:bg-transparent" : " read-only:bg-grey02 disabled:bg-disabled disabled:text-black disabled:opacity-100 ")
                            }
                            // style={(disabled || value) ?
                            //     { "transition": "150ms ease" }
                            //     : { "transition": "150ms ease" }
                            // }}
                            value={value}
                            placeholder={placeholder}
                            min={`${type === PickerType.dateTime ? "1970-01-01T00:00" : "1970-01-01"}`}
                            max={`${type === PickerType.dateTime ? `${maxDate}-12-31T23:59` : `${maxDate}-12-31`}`}
                            pattern={`${type === PickerType.dateTime ? "[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}" : "[0-9]{4}/[0-9]{2}/[0-9]{2}"}`}
                            type={type}
                            disabled={disabled}
                            readOnly={readOnly}
                            onChange={(e) => {onChange(e);console.log(e)}}
                            {...rest}
                        />
                }
                {(label || disabled) ?
                    null :
                    <div className={`${hasError ? " text-red01" : " text-blue04"} absolute top-1/2 -translate-y-1/2 ${iconPaddingType? iconPaddingType: IconPaddingType.normalPadding} cursor-pointer`}>
                        <Calendar className={`w-[14px] h-[14px]`}/>
                    </div>                        
                }
            </div>
            {(label && !disabled) ?
                <div className={`${hasError ? " text-red01" : " text-blue04"} flex items-center absolute top-1/2 -translate-y-1/2 ${IconPaddingType.flat} cursor-pointer`}>
                    <Calendar className={`w-[14px] h-[14px]`}/>
                </div>                        
                : null
            }
        </div>
    )
});

CusRoundedDatePicker.propTypes = {
  className: PropTypes.string,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  label: PropTypes.string,
  labelType: PropTypes.string,
  list: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf([PickerType.date, PickerType.dateTime]),
  value: PropTypes.string,
  borderType: PropTypes.string,
  roundType: PropTypes.string,
  paddingType: PropTypes.string,
  textSize: PropTypes.string,
  icon: PropTypes.element,
  iconPaddingType: PropTypes.string,
}

export {
    CusRoundedDatePicker,
    LabelType,
    BorderType,
    PaddingType,
    LabelPaddingType,
    RoundType,
    PickerType,
    TextSize
};
