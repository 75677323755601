const FlowNode = (rest) => {
    return(
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <circle cx="15.5" cy="15" r="15" fill="currentColor"/>
        <rect width="18" height="18" transform="translate(6.5 6)" fill="currentColor"/>
        <path d="M8 9V19.5L11.6 16.8C11.8594 16.6047 12.1753 16.4994 12.5 16.5H18.5C19.3284 16.5 20 15.8284 20 15V9C20 8.17157 19.3284 7.5 18.5 7.5H9.5C8.67157 7.5 8 8.17157 8 9ZM9.5 16.5V9H18.5V15H12.0005C11.6758 14.9991 11.3597 15.1045 11.1005 15.3L9.5 16.5Z" fill="white"/>
        <path d="M23 22.5V12.75C23 11.9216 22.3284 11.25 21.5 11.25V19.5L19.8995 18.3C19.6403 18.1045 19.3242 17.9991 18.9995 18H11.75C11.75 18.8284 12.4216 19.5 13.25 19.5H18.5C18.8247 19.4994 19.1406 19.6047 19.4 19.8L23 22.5Z" fill="white"/>
        </svg>
    )
}

export default FlowNode;