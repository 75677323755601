import { combineReducers } from '@reduxjs/toolkit'

// chatbot
import SettingReducer from './ui/setting'

import UserReducer from './auth/user';

import ChatbotFlowReducer from './setting/automation/chatbot/flow'
import ChatbotNodeReducer from './setting/automation/chatbot/node'
import KeywordReducer from './setting/automation/chatbot/keyword'
import EntityReducer from './setting/automation/chatbot/entity'
import ResponseReducer from './setting/automation/chatbot/response'
import ActionReducer from './setting/automation/chatbot/action'
import ParameterReducer from './setting/automation/chatbot/parameter'
import TsetChatbotReducer from './setting/automation/chatbot/test'

import AttachmentReducer from './setting/automation/chatbot/attachment'

const rootReducer =
    combineReducers({
    // {
        ui : combineReducers({
            setting: SettingReducer,
        }),
        auth:  combineReducers({
            user: UserReducer,
        }),
        setting:combineReducers({
            automation: combineReducers({

                chatbot: combineReducers({
                    flow: ChatbotFlowReducer,
                    node: ChatbotNodeReducer,
                    keyword: KeywordReducer,
                    entity: EntityReducer,
                    response: ResponseReducer,
                    action: ActionReducer,
                    parameter: ParameterReducer,
                    test: TsetChatbotReducer,
    
                    attachment: AttachmentReducer,
                }),

            })
        }),
        // companyInfo: CompanyInfoReducer,
    })
    // }

export default rootReducer;