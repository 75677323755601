

import PropTypes from "prop-types";
import React, { useEffect, useRef } from 'react';
import Scrollbars from "react-custom-scrollbars-2";
import './index.css';

const LabelType = Object.freeze(
    {
        "labelOnLeft": "labelOnLeft",
        "NoLabel": "NoLabel",
    }
)

const BorderType = Object.freeze(
    {
        "none": " ",
        "Default": " border-2 focus-within:border-gray-700 border-gray-300 ",
        "Thin": " border-[1px] focus-within:border-gray-700 border-gray-300 ",
        "Error": " border-2 border-red01 focus-within:border-red01 "
    }
)

const TextSize = Object.freeze(
    {
        "default": " text-sm",
        "flat": " text-[15px] "
    }
)

const PaddingType = Object.freeze(
    {
        "noPadding": " ",
        "normalPadding": " mt-7 pb-1 pl-5 pr-5 ",
        "labelPaddingFlat": " py-3 pl-5 pr-5 ",
        "flat": " py-3 px-8 ",
    }
)

const LabelPaddingType = Object.freeze(
    {
        "noPadding": " ",
        "normalPadding": " py-1 pl-5 pr-5 ",
        "flat": " py-1 px-8 ",
    }
)

const CusRoundedTextArea = React.forwardRef(({
    hasError = false,
    value = undefined,
    placeholder = "",
    type = "text",
    disabled = false,
    readOnly = false,
    onChange = ()=>{},
    labelType = LabelType.NoLabel,
    label = "",
    className = "",
    list = "",
    paddingType = PaddingType.normalPadding,
    labelPaddingType = "",
    textSize = TextSize.default,
    borderType = BorderType.Default,
    innerContainerSize = "",
    ...rest
}, ref) => {        

        return(
        <div className={`
            ${label ? `relative flex flex-col rounded-md select-none outline-none 
                ${disabled ? "" : "pointer-events-none"}
                ${borderType ? borderType: BorderType.Default}
                ${(readOnly && !disabled) ? `opacity-100 bg-grey02`: "bg-white"}`
            : ""}
            ${className}`}
        >
            {label ?
                <span
                    className={
                        "text-sm absolute w-full "
                        + ((disabled || value) ? ` h-auto opacity-100 translate-y-0 transition-[opacity,transform] delay-100` : " opacity-0 h-0 -translate-y-[3px] transition-[opacity,transform] delay-100")
                        + (labelPaddingType? labelPaddingType: LabelPaddingType.normalPadding)
                        + (hasError ? " text-red01 " : " text-grey06 ")
                    }
                    // style={(disabled || value) ?
                    //     { "opacity": 1, "transform": "translateY(0)", "transitionProperty": "opacity, transform", "transitionDuration": "150ms" }
                    //     : { "opacity": 0, "transform": "translateY(-3)", "transitionProperty": "opacity, transform", "transitionDuration": "150ms" }
                    // }
                >
                    {label}
                </span>
                :
                null
            }            
            {/* {label && (disabled || value) ?
                <span
                    className={
                        "text-sm "
                        + (labelPaddingType? labelPaddingType: LabelPaddingType.normalPadding)
                        + (hasError ? " text-red01 " : " text-grey06 ")
                    }                     
                >
                    {label}
                </span>
                :
                null
            } */}
            <div>
                {disabled ?
                    <div
                        className={"w-full whitespace-pre-wrap rounded-md outline-none text-greyblack placeholder:text-greyblack placeholder:text-sm "
                            + (textSize? textSize: TextSize.default)
                            // + (label ? PaddingType.normalPadding : (paddingType? paddingType: PaddingType.normalPadding))
                            + (label ? (value? PaddingType.normalPadding : PaddingType.labelPaddingFlat) : (paddingType? paddingType: PaddingType.normalPadding))
                            + (label ? BorderType.none : (borderType? borderType: BorderType.Default))
                            + (label ? "pointer-events-auto disabled:bg-transparent" : "disabled:bg-disabled disabled:text-black disabled:opacity-100 ")
                        }
                        {...rest}
                    >
                        <Scrollbars
                            autoHeightMin="50px"
                            autoHeightMax={innerContainerSize ?? "100px"}
                            autoHeight
                            autoHide
                            autoHideDuration={100}
                        >
                            {value}
                        </Scrollbars>
                    </div>
                    :
                    <textarea 
                        ref={ref}
                        className={"resize-none cus-textarea w-full rounded-md outline-none text-greyblack "
                            + innerContainerSize
                            + (textSize? textSize: TextSize.default)
                            + (hasError ? " placeholder:text-red01 " : " placeholder:text-grey06 ")
                            + (label ? (value? "placeholder:text-sm" : "placeholder:text-base") : "")
                            + (label ? (value? PaddingType.normalPadding : PaddingType.labelPaddingFlat) : (paddingType? paddingType: PaddingType.normalPadding))
                            + (label ? BorderType.none : (borderType? borderType: BorderType.Default))
                            + (label ? " pointer-events-auto read-only:bg-transparent" : "disabled:bg-disabled disabled:text-black disabled:opacity-100 ")
                        } 
                        list={list}
                        value={value}
                        placeholder={placeholder}
                        disabled={disabled}
                        readOnly={readOnly}
                        onChange={(e) => onChange(e)}
                        {...rest}
                    />
                }
            </div>
        </div>

    )
});

CusRoundedTextArea.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  label: PropTypes.string,
  labelType: PropTypes.string,
  list: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  borderType: PropTypes.string,
  paddingType: PropTypes.string,
  textSize: PropTypes.string,
  innerContainerSize: PropTypes.string,
}

export {
    CusRoundedTextArea,
    LabelType,
    BorderType,
    PaddingType,
    LabelPaddingType,
    TextSize
};
