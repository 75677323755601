const Import = (rest) => {
    return(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M6.3 22.5C5.8 22.5 5.4 22.3 5 22C4.6 21.7 4.5 21.2 4.5 20.7V10.3C4.5 9.79999 4.7 9.39999 5 8.99999C5.3 8.59999 5.8 8.49999 6.3 8.49999H8.1C8.3 8.49999 8.5 8.49999 8.6 8.69999C8.7 8.79999 8.8 8.99999 8.8 9.19999C8.8 9.39999 8.8 9.59999 8.6 9.69999C8.5 9.79999 8.3 9.89999 8.1 9.89999H6.3C6.3 9.89999 6.2 9.89999 6.1 9.89999C6.1 9.89999 6.1 9.99999 6.1 10.1V20.5C6.1 20.5 6.1 20.6 6.1 20.7C6.1 20.7 6.2 20.7 6.3 20.7H17.7C17.7 20.7 17.8 20.7 17.9 20.7C17.9 20.7 17.9 20.6 17.9 20.5V10.1C17.9 10.1 17.9 9.99999 17.9 9.89999C17.9 9.89999 17.8 9.89999 17.7 9.89999H15.9C15.7 9.89999 15.5 9.89999 15.4 9.69999C15.3 9.49999 15.2 9.39999 15.2 9.19999C15.2 8.99999 15.2 8.79999 15.4 8.69999C15.6 8.59999 15.7 8.49999 15.9 8.49999H17.7C18.2 8.49999 18.6 8.69999 19 8.99999C19.4 9.29999 19.5 9.79999 19.5 10.3V20.7C19.5 21.2 19.3 21.6 19 22C18.7 22.4 18.2 22.5 17.7 22.5H6.3ZM12.7 13.2L14 11.9C14.1 11.8 14.3 11.7 14.5 11.7C14.7 11.7 14.9 11.7 15 11.9C15.1 12.1 15.2 12.2 15.2 12.4C15.2 12.6 15.2 12.8 15 12.9L12.5 15.4C12.3 15.6 12.1 15.7 11.9 15.7C11.7 15.7 11.4 15.7 11.3 15.4L8.8 12.9C8.7 12.8 8.6 12.6 8.6 12.4C8.6 12.2 8.6 12 8.8 11.9C9 11.7 9.1 11.7 9.3 11.7C9.5 11.7 9.7 11.7 9.8 11.9L11.1 13.2V2.99999C11.1 2.79999 11.1 2.59999 11.3 2.49999C11.4 2.39999 11.6 2.29999 11.8 2.29999C12 2.29999 12.2 2.29999 12.3 2.49999C12.4 2.59999 12.5 2.79999 12.5 2.99999V13.3L12.7 13.2Z" fill="currentColor"/>
            <path d="M6.3 22.5C5.8 22.5 5.4 22.3 5 22C4.6 21.7 4.5 21.2 4.5 20.7V10.3C4.5 9.79999 4.7 9.39999 5 8.99999C5.3 8.59999 5.8 8.49999 6.3 8.49999H8.1C8.3 8.49999 8.5 8.49999 8.6 8.69999C8.7 8.79999 8.8 8.99999 8.8 9.19999C8.8 9.39999 8.8 9.59999 8.6 9.69999C8.5 9.79999 8.3 9.89999 8.1 9.89999H6.3C6.3 9.89999 6.2 9.89999 6.1 9.89999C6.1 9.89999 6.1 9.99999 6.1 10.1V20.5C6.1 20.5 6.1 20.6 6.1 20.7C6.1 20.7 6.2 20.7 6.3 20.7H17.7C17.7 20.7 17.8 20.7 17.9 20.7C17.9 20.7 17.9 20.6 17.9 20.5V10.1C17.9 10.1 17.9 9.99999 17.9 9.89999C17.9 9.89999 17.8 9.89999 17.7 9.89999H15.9C15.7 9.89999 15.5 9.89999 15.4 9.69999C15.3 9.49999 15.2 9.39999 15.2 9.19999C15.2 8.99999 15.2 8.79999 15.4 8.69999C15.6 8.59999 15.7 8.49999 15.9 8.49999H17.7C18.2 8.49999 18.6 8.69999 19 8.99999C19.4 9.29999 19.5 9.79999 19.5 10.3V20.7C19.5 21.2 19.3 21.6 19 22C18.7 22.4 18.2 22.5 17.7 22.5H6.3ZM12.7 13.2L14 11.9C14.1 11.8 14.3 11.7 14.5 11.7C14.7 11.7 14.9 11.7 15 11.9C15.1 12.1 15.2 12.2 15.2 12.4C15.2 12.6 15.2 12.8 15 12.9L12.5 15.4C12.3 15.6 12.1 15.7 11.9 15.7C11.7 15.7 11.4 15.7 11.3 15.4L8.8 12.9C8.7 12.8 8.6 12.6 8.6 12.4C8.6 12.2 8.6 12 8.8 11.9C9 11.7 9.1 11.7 9.3 11.7C9.5 11.7 9.7 11.7 9.8 11.9L11.1 13.2V2.99999C11.1 2.79999 11.1 2.59999 11.3 2.49999C11.4 2.39999 11.6 2.29999 11.8 2.29999C12 2.29999 12.2 2.29999 12.3 2.49999C12.4 2.59999 12.5 2.79999 12.5 2.99999V13.3L12.7 13.2Z" fill="currentColor" fillOpacity="0.2"/>
            <path d="M6.3 22.5C5.8 22.5 5.4 22.3 5 22C4.6 21.7 4.5 21.2 4.5 20.7V10.3C4.5 9.79999 4.7 9.39999 5 8.99999C5.3 8.59999 5.8 8.49999 6.3 8.49999H8.1C8.3 8.49999 8.5 8.49999 8.6 8.69999C8.7 8.79999 8.8 8.99999 8.8 9.19999C8.8 9.39999 8.8 9.59999 8.6 9.69999C8.5 9.79999 8.3 9.89999 8.1 9.89999H6.3C6.3 9.89999 6.2 9.89999 6.1 9.89999C6.1 9.89999 6.1 9.99999 6.1 10.1V20.5C6.1 20.5 6.1 20.6 6.1 20.7C6.1 20.7 6.2 20.7 6.3 20.7H17.7C17.7 20.7 17.8 20.7 17.9 20.7C17.9 20.7 17.9 20.6 17.9 20.5V10.1C17.9 10.1 17.9 9.99999 17.9 9.89999C17.9 9.89999 17.8 9.89999 17.7 9.89999H15.9C15.7 9.89999 15.5 9.89999 15.4 9.69999C15.3 9.49999 15.2 9.39999 15.2 9.19999C15.2 8.99999 15.2 8.79999 15.4 8.69999C15.6 8.59999 15.7 8.49999 15.9 8.49999H17.7C18.2 8.49999 18.6 8.69999 19 8.99999C19.4 9.29999 19.5 9.79999 19.5 10.3V20.7C19.5 21.2 19.3 21.6 19 22C18.7 22.4 18.2 22.5 17.7 22.5H6.3ZM12.7 13.2L14 11.9C14.1 11.8 14.3 11.7 14.5 11.7C14.7 11.7 14.9 11.7 15 11.9C15.1 12.1 15.2 12.2 15.2 12.4C15.2 12.6 15.2 12.8 15 12.9L12.5 15.4C12.3 15.6 12.1 15.7 11.9 15.7C11.7 15.7 11.4 15.7 11.3 15.4L8.8 12.9C8.7 12.8 8.6 12.6 8.6 12.4C8.6 12.2 8.6 12 8.8 11.9C9 11.7 9.1 11.7 9.3 11.7C9.5 11.7 9.7 11.7 9.8 11.9L11.1 13.2V2.99999C11.1 2.79999 11.1 2.59999 11.3 2.49999C11.4 2.39999 11.6 2.29999 11.8 2.29999C12 2.29999 12.2 2.29999 12.3 2.49999C12.4 2.59999 12.5 2.79999 12.5 2.99999V13.3L12.7 13.2Z" fill="currentColor" fillOpacity="0.2"/>
            <path d="M6.3 22.5C5.8 22.5 5.4 22.3 5 22C4.6 21.7 4.5 21.2 4.5 20.7V10.3C4.5 9.79999 4.7 9.39999 5 8.99999C5.3 8.59999 5.8 8.49999 6.3 8.49999H8.1C8.3 8.49999 8.5 8.49999 8.6 8.69999C8.7 8.79999 8.8 8.99999 8.8 9.19999C8.8 9.39999 8.8 9.59999 8.6 9.69999C8.5 9.79999 8.3 9.89999 8.1 9.89999H6.3C6.3 9.89999 6.2 9.89999 6.1 9.89999C6.1 9.89999 6.1 9.99999 6.1 10.1V20.5C6.1 20.5 6.1 20.6 6.1 20.7C6.1 20.7 6.2 20.7 6.3 20.7H17.7C17.7 20.7 17.8 20.7 17.9 20.7C17.9 20.7 17.9 20.6 17.9 20.5V10.1C17.9 10.1 17.9 9.99999 17.9 9.89999C17.9 9.89999 17.8 9.89999 17.7 9.89999H15.9C15.7 9.89999 15.5 9.89999 15.4 9.69999C15.3 9.49999 15.2 9.39999 15.2 9.19999C15.2 8.99999 15.2 8.79999 15.4 8.69999C15.6 8.59999 15.7 8.49999 15.9 8.49999H17.7C18.2 8.49999 18.6 8.69999 19 8.99999C19.4 9.29999 19.5 9.79999 19.5 10.3V20.7C19.5 21.2 19.3 21.6 19 22C18.7 22.4 18.2 22.5 17.7 22.5H6.3ZM12.7 13.2L14 11.9C14.1 11.8 14.3 11.7 14.5 11.7C14.7 11.7 14.9 11.7 15 11.9C15.1 12.1 15.2 12.2 15.2 12.4C15.2 12.6 15.2 12.8 15 12.9L12.5 15.4C12.3 15.6 12.1 15.7 11.9 15.7C11.7 15.7 11.4 15.7 11.3 15.4L8.8 12.9C8.7 12.8 8.6 12.6 8.6 12.4C8.6 12.2 8.6 12 8.8 11.9C9 11.7 9.1 11.7 9.3 11.7C9.5 11.7 9.7 11.7 9.8 11.9L11.1 13.2V2.99999C11.1 2.79999 11.1 2.59999 11.3 2.49999C11.4 2.39999 11.6 2.29999 11.8 2.29999C12 2.29999 12.2 2.29999 12.3 2.49999C12.4 2.59999 12.5 2.79999 12.5 2.99999V13.3L12.7 13.2Z" fill="currentColor" fillOpacity="0.2"/>
        </svg>
    )
}

export default Import;