import {
    getLanguage,
    getNodeHistory,
    getNodeOptionValueMap,
    getParameterMap,
    RESET_TEST_CHATBOT,
    SEND_TEST_MESSAGE,
    TEST_CHATBOT,
    TEST_CHATBOT_FAILURE,
    TEST_CHATBOT_SUCCESS
} from 'store/reducers/setting/automation/chatbot/test';

import {
    testChatbot as testChatbotServices
} from "services/chatbot/test";

import { isJson, isSuccessResponse } from "util/helper";
import { handleAuthError } from 'store/actions/auth/user';
import { ERROR_REPSONSE_LOCALE_MAP, ERROR_RESPONSE_MAP, getErrorByLocale } from 'util/ErrorResponse';
import { errorPrefix } from 'lang/locales/prefix';
import { getToken } from 'store/reducers/auth/user';

export const testChatbot = ({flowId, message}) => (dispatch, getState) => {
    dispatch(SEND_TEST_MESSAGE(message));
    dispatch(TEST_CHATBOT());

    if(!flowId){
        dispatch(TEST_CHATBOT_FAILURE('no flow id'))
        return;
    }

    if(!message){
        dispatch(TEST_CHATBOT_FAILURE('no message'))
        return;
    }

    const language = JSON.parse(JSON.stringify(getLanguage(getState().setting.automation.chatbot.test)));
    const parameterMap = JSON.parse(JSON.stringify(getParameterMap(getState().setting.automation.chatbot.test)));
    const nodeOptionValueMap = JSON.parse(JSON.stringify(getNodeOptionValueMap(getState().setting.automation.chatbot.test)));
    const nodeHistory = JSON.parse(JSON.stringify(getNodeHistory(getState().setting.automation.chatbot.test)));

    let currentNodeId = "";

    if(nodeHistory.length > 0){
        currentNodeId = nodeHistory[nodeHistory.length - 1].node.id
    }

    let authToken = getToken(getState().auth.user)
    testChatbotServices({flowId, language, currentNodeId, parameterMap, nodeOptionValueMap, message}, authToken)
        .then((response) => {
            const bodyString = response.data.body;
            const body = isJson(bodyString) ? JSON.parse(bodyString) : bodyString;
    
            console.log(body);
    
            if(isSuccessResponse(response))
                dispatch(TEST_CHATBOT_SUCCESS(body))
            else{
                if(body === ERROR_RESPONSE_MAP.AUTH){
                    dispatch(handleAuthError())
                    return
                }
                if(body === ERROR_RESPONSE_MAP.PERMISSION){
                    dispatch(TEST_CHATBOT_FAILURE({error: getErrorByLocale(getState, ERROR_REPSONSE_LOCALE_MAP.PERMISSION)}))
                    return
                }
    
                dispatch(TEST_CHATBOT_FAILURE({error: body.message }))
            }

        })
        .catch((e) => {
            if(e.response)
                console.log(e.response)

            dispatch(TEST_CHATBOT_FAILURE(e.response ? e.response : 'System Error, Please contact admin for more information'))
        })
}

export const resetTestChatbot = () => (dispatch) => {
    dispatch(RESET_TEST_CHATBOT())
}