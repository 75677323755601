import { chatpanelPrefix, chatpanelConversationsPrefix, chatpanelMessagesPrefix, chatTransferPrefix } from "../prefix";

const chatpanelBase = {
    [`${chatpanelPrefix}.modal.content.warning.offline`]: "打开在线开始聊天。",
    [`${chatpanelPrefix}.modal.content.warning.reconnect`]: "重新连接",

    [`${chatpanelPrefix}.button.keepOffline`]: "保持离线状态",

    [`${chatpanelPrefix}.loadingMessage.offline`]: "请将用户会话更改为在线以激活聊天面板",
    [`${chatpanelPrefix}.loadingMessage.networkDisconnected`]: "网络断开",
    [`${chatpanelPrefix}.loadingMessage.pending`]: "正在更新会话...",
    [`${chatpanelPrefix}.loadingMessage.webSocketClosed`]: "聊天面板已关闭",
}

const chatPanelConversation = {
    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.tab.title.open`]: "打开",
    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.tab.title.closed`]: "关闭",

    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.notice.message.search`]: "已找到消息",

    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.notice.message.empty`]: "找不到消息。",
    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.notice.conversation.closed`]: "1天内关闭的聊天记录",
    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.notice.conversation.empty`]: "没有打开聊天。",

    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.nameList.sorting.timestamp`]: "时间（最近）",
    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.nameList.sorting.channel`]: "频道",

    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.nameList.optionMenu.pin`]: "Pin Chat",
    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.nameList.optionMenu.unPin`]: "Unpin Chat",
    [`${chatpanelPrefix}.${chatpanelConversationsPrefix}.nameList.optionMenu.markUnread`]: "标记为未读",
}

const chatPanelMessage = {
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.button.close`]: "关闭聊天",

    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.modal.title.warning`]: "警告",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.modal.content.warning.close`]: "关闭聊天？",

    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.text.chatTags`]: "聊天标签",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.text.preview`]: "预览",

    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.notice.conversation.empty`]: "没有打开或选择聊天。",

    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.search.text.pinned`]: "PINNED MESSAGE",

    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.quickText.expandAll`]: "全部展开",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.quickText.folderType.public`]: "公开",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.quickText.folderType.personal`]: "个人",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.quickText.categories`]: "所有类别",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.quickText.subCategories`]: "所有子类别",
    
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.messageInput.textarea.placeholder.typeMessage`]: "输入消息",

    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.messageInput.button.quickText`]: "快速文本",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.messageInput.button.send`]: "发送",

    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.messageInput.nameList.menu.title`]: "分享",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.messageInput.nameList.menu.document`]: "文档",
    [`${chatpanelPrefix}.${chatpanelMessagesPrefix}.messageInput.nameList.menu.image`]: "图片",
}

const chatTransfer = {
    [`${chatpanelPrefix}.${chatTransferPrefix}.nameList.menu.title`]: "代理或队列",
    [`${chatpanelPrefix}.${chatTransferPrefix}.nameList.menu.all`]: "所有代理/队列",
    [`${chatpanelPrefix}.${chatTransferPrefix}.nameList.menu.queue`]: "队列",
    [`${chatpanelPrefix}.${chatTransferPrefix}.nameList.menu.agent`]: "个人代理",

    [`${chatpanelPrefix}.${chatTransferPrefix}.info.count`]: "在线: ",

    [`${chatpanelPrefix}.${chatTransferPrefix}.text.transferMessage`]: "转移消息",
    [`${chatpanelPrefix}.${chatTransferPrefix}.input.placeholder.transferMessage`]: "写消息",
}

export const chatpanel = {
    ...chatpanelBase,
    ...chatPanelConversation,
    ...chatPanelMessage,
    ...chatTransfer,
}