import PropTypes from 'prop-types';
import { MESSAGE_TYPE } from 'util/helper';
import { MESSAGE_CONTAINER_TYPE } from "components/ChatMessage/util";
import MediaDownloadable from '../MediaAction/MediaDownloadable';
import TextMessage from './TextMessage';

const ImageMessage = ({
    messageId = "",
    message = "",
    messageMedia = null,
    downloading = false,
    disabled = false,
    onClick = null,
    onContextMenu = null,
    containerType = MESSAGE_CONTAINER_TYPE.AGENT,
    keyword = "",
    multiLines = true
}) => (
    <div className="flex flex-col">
        {messageMedia ?
            <div>
                <img className={`w-full h-full max-w-[250px] max-h-[152px] object-cover`} draggable={false}
                    alt="loading" src={`data:${messageMedia.mimeType};base64,${messageMedia.base64}`}/>
            </div>
            :
            <MediaDownloadable
                loading={downloading}
                disabled={disabled}
                messageId={messageId}
                messageType={MESSAGE_TYPE.IMAGE}
                onDownload={onClick}
                onContextMenu={onContextMenu}
                containerType={containerType}
            />
        }
        {message ?
            <TextMessage message={message} keyword={keyword} multiLines={multiLines}/>
            :
            null
        }
    </div>
)


ImageMessage.propTypes = {
    messageId: PropTypes.string,
    message: PropTypes.string,
    messageMedia: PropTypes.object,
    downloading: PropTypes.bool,
    disabled: PropTypes.bool,
    
    onClick: PropTypes.func,
    onContextMenu: PropTypes.func,
    containerType: PropTypes.string,

    keyword: PropTypes.string,
    multiLines: PropTypes.bool
};

export default ImageMessage;