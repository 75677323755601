
import PropTypes from 'prop-types';
import React from 'react';

const Item = ({
    className = "",
    children = null,
    isHeader = false,
    ...rest
}) => {
    return (
        <>
            {
                isHeader ?
                    <th scope="col" className={`sticky top-0 text-left text-grey07 text-sm truncate h-[64px] px-8 ${className}`} {...rest}>{children}</th>
                :
                    <td className={`text-greyblack text-sm truncate h-[64px] px-8 ${className}`} {...rest}>{children}</td>
            }
        </>
    )
}

Item.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    isHeader: PropTypes.bool,
};

export default Item;