import React from 'react';
import PropTypes from 'prop-types';

import './index.css';
// import './styles.scss';

const Divider = ({
  size = 'cus-dropdown-sm',
  ...props
}) => (
  <li
    role='separator'
    className={"cus-dropdown-divider" + (props.size === "cus-dropdown-sm" ? " cus-dropdown-sm" : " cus-dropdown-lg")}
    {...props}
  />
);

Divider.propTypes = {
  size: PropTypes.oneOf(['cus-dropdown-sm', 'cus-dropdown-lg']),
};

export default Divider;