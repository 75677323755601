// import appLocaleData from 'react-intl/locale-data/zh';
import {
   general,
   table,
   errors,
   info,
   chatpanel,
   
   setting
} from '../locales/zh-Hans';

const ZhLan = {
   messages: {
      ...general,
      ...table,
      ...errors,
      ...info,
      ...chatpanel,

      ...setting
  },
   locale: 'zh-Hans-CN',
   // data: appLocaleData
};
export default ZhLan;