import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CusDropdown from 'components/CusDropdown';
import Overlay from 'components/Overlay';
import "./index.css"

const DynamicContent = ({
    aboveOverlay = false,
	show = false,
	setShow = null,
	onUnFocus = null,
	screenX = undefined,
	screenY = undefined,
	defaultPos = null,
	children = null,
}) => {

    const [pos, setPos] = useState({
        hPos: "Left",
        vPos: "Bottom"
    })

    const unfocus = (e) => {
        e.preventDefault()
        e.stopPropagation()
        document.activeElement.blur();
        setShow(false)
        onUnFocus()
    }

	useLayoutEffect(() => {
        if(!show || screenX < 0 || screenY < 0)
            return;

        let hPos = null;
        let vPos = null;

        console.log(window.innerWidth, window.innerHeight)
        
        hPos = screenX > window.innerWidth / 2 ? "Left" : "Right"
        vPos = screenY > window.innerHeight / 2 ? "Top" : "Bottom"

        setPos({ hPos: defaultPos?.hPos ?? hPos, vPos: defaultPos?.vPos ?? vPos })
	}, [show, screenY, screenX])

    return (
        <Overlay full show={show && screenX !== undefined && screenY !== undefined} className={`cursor-auto ${aboveOverlay ? "z-[1151]" : "z-[1100]"}`}
            onClick={unfocus}
            onContextMenu={unfocus}
        >
            <div className={`fixed z-[1101] `} style={{"top": `${screenY}px`, "left": `${screenX}px`}}
                onClick={(e) => e.stopPropagation()}
                onContextMenu={(e) => e.stopPropagation()}
            >
                <div className={`absolute dynamicContent-${pos.vPos}${pos.hPos}`}
                    onClick={(e) => e.stopPropagation()}
                    onContextMenu={(e) => e.stopPropagation()}                
                >
                    {children}
                </div>
            </div>
        </Overlay>
    )
}

DynamicContent.propTypes = {
    show: PropTypes.bool,
	setShow: PropTypes.func,
	onUnFocus: PropTypes.func,
	screenX: PropTypes.number,
	screenY: PropTypes.number,
    defaultPos: PropTypes.object,
	children: PropTypes.node
};

export default DynamicContent;