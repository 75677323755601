import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import dagre from 'dagre';
import ReactFlow, {
    MiniMap,
    Panel,
    ReactFlowProvider, useEdgesState, useNodesState, useReactFlow, useStoreApi
} from "reactflow";
import "reactflow/dist/style.css";


import AddNode from "./Node/AddNode";
import NormalNode from "./Node/NormalNode";
// import EndNode from "./Node/EndNode";
import StartNode from "./Node/StartNode";
import AddNode2 from "./Node/AddNode2";
import NodeOptionDropdown from "./NodeOptionDropdown";
import { NODE_EDIT_SECTION_TYPE } from "./EditFlow";
import { useIntl } from "react-intl";
import { useLanguage } from "hooks/useLanguage";
import { editNodePrefix, settingPrefix } from "lang/locales/prefix";

const ACTION_TYPE = Object.freeze({
    "DO_NOTHING": "DO_NOTHING",
    "REQUEST_LIVE_CHAT": "REQUEST_LIVE_CHAT",
    "JUMP_NODE": "JUMP_NODE",
    "RUN_ACTION": "RUN_ACTION",
}) 

const CONDITION_TYPE_NAME = Object.freeze({
    "OTHERS": ``,
    "ANY_MESSAGE": `${settingPrefix}.${editNodePrefix}.nameList.conditionType.anyMessage`,
    "ANY_MEDIA": `${settingPrefix}.${editNodePrefix}.nameList.conditionType.anyMedia`,
})

const ACTION_TYPE_NAME = Object.freeze({
    "DO_NOTHING": ``,
    "REQUEST_LIVE_CHAT": `${settingPrefix}.${editNodePrefix}.nameList.action.liveChat`,
    "JUMP_NODE": `${settingPrefix}.${editNodePrefix}.nameList.action.jumpNode`,
    "RUN_ACTION": `${settingPrefix}.${editNodePrefix}.nameList.action.runAction`,
}) 

const START_NODE_TEMPALTE = {
    className: "flex items-center justify-center"
}

const DEFAULT_NODE_TEMPALTE = {
    className: "flex items-center justify-center noDrag noPan"
}

// const DEFAULT_NODE_SIZE = { width: 125, height: 32 }
const DEFAULT_NODE_SIZE = { width: 125, height: 24 }
const DEFAULT_NAMED_NODE_SIZE = { width: 200, height: 132 }

const NODE_SIZE = {
    start: DEFAULT_NODE_SIZE,
    add: DEFAULT_NAMED_NODE_SIZE,
    add2: DEFAULT_NAMED_NODE_SIZE,
    normal: DEFAULT_NAMED_NODE_SIZE,
}

const NODE_COLOR = {
    start: "text-greyblack",
    add: "text-blue04",
    normal: "text-yellow01",
}

const EDGE_TEMPLATE = {
    type: 'step',
    position: {x: 0, y: 0},
    style: { stroke: "#94A3B8", strokeWidth: "2" },
}

const NODE_TEMPALTE = {
    start: {...START_NODE_TEMPALTE, type: "start", id: "START_NODE"},
    add: {...DEFAULT_NODE_TEMPALTE, type: "add"},
    add2: {...DEFAULT_NODE_TEMPALTE, type: "add2"},
    normal: {...DEFAULT_NODE_TEMPALTE, type: "normal"},
}

const nodeTypes = {
    start: StartNode,
    add: AddNode,
    add2: AddNode2,
    normal: NormalNode,
}

const FlowGraphPanel = ({
    isEdit,
    nodeList,
    keywordNameMap,
    entityNameMap,
    responseNameMap,
    // actionNameMap,
    // parameterNameMap,
    selectedFlow,
    selectedNode,
    selectNode,
    setNodeEditSection
}) => {
    const intl = useIntl();
    const { language } = useLanguage()

    const [isInit, setIsInit] = useState(false);
    const [preNodeId, setPreNodeId] = useState("");

    const [showMenu, setShowMenu] = useState(false);
    const [menuPos, setMenuPos] = useState(null);

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const [flowCenter, setFlowCenter] = useState(null)    

    const [searchInput, setSearchInput] = useState("");

    const sortNodeByPreId = (a, b) => {
        return (a.preNodeId ?? "").toLowerCase().localeCompare((b.preNodeId ?? "").toLowerCase())
    }
  

    const recenter = (nodes) => {
        console.log("recenter")
        console.log(isInit)
        if (nodes.length > 0) {
            let newRootNode = null

            let index = isInit ? -1 : nodes.findIndex(node => node.id === "START_NODE")

            if(selectedNode){
                if(nodeList.length < 1)
                    index = nodes.findIndex(node => node.id === `ADD_NODE_2`)
                else
                    index = nodes.findIndex(node => node.id === (selectedNode.isCreate ? `ADD_NODE${!!selectedNode.preNodeId ? `_${selectedNode.preNodeId}` : ""}` : selectedNode.id))
                setIsInit(true)
            }                

            if(index !== -1){
                newRootNode = { x: nodes[index].position.x, y: nodes[index].position.y }
                setFlowCenter({ x: newRootNode.x + 100, y: newRootNode.y + 200 })
            }
        }
    }

    const initAutoLayout = (nodes, edges, setNodes, setEdges) => {
        let dagreGraph = new dagre.graphlib.Graph();
        dagreGraph.setDefaultEdgeLabel(() => ({}));

        dagreGraph.setGraph({ rankdir: 'TD', ranksep: 100, nodesep: 100 });

        nodes.forEach((node) => dagreGraph.setNode(node.id, {...NODE_SIZE[node.type]}));

        edges.forEach((edge) => {
            dagreGraph.setEdge(edge.source, edge.target);
        });

        dagre.layout(dagreGraph);

        nodes.forEach((node) => {
            const nodeWithPosition = dagreGraph.node(node.id);
            if(node.subType)
                node.position = { x: nodeWithPosition.x - (NODE_SIZE[node.subType].width/2), y: nodeWithPosition.y - (NODE_SIZE[node.subType].height/2) };
            else
                node.position = { x: nodeWithPosition.x - (NODE_SIZE[node.type].width/2), y: nodeWithPosition.y - (NODE_SIZE[node.type].height/2) };
        });

        setNodes(nodes)
        setEdges(edges)

        recenter(nodes)
    }

    const handleOnViewFlow = () => {
        let newNodes = [];

        let editableNodeList = JSON.parse(JSON.stringify(nodeList))
        let sortedNodeList = editableNodeList.sort(sortNodeByPreId).filter(node => !node.isGeneralNode);
        let nodeIdList = sortedNodeList.map(node => node.id);

        console.log(sortedNodeList)
        
        const onSelectNode = (node, callback) => selectNode(node, callback)

        const onAddNode =  (id, callback) => {
            console.log(selectedNode)
            console.log(id)
            if(!!selectedNode && selectedNode.isCreate && selectedNode.preNodeId === id)
                return;


            // setPreNodeId(id)
            // setMenuPos({x: e.clientX, y: e.clientY})
            // setShowMenu(true)                                    

            selectNode({
                flowId: selectedFlow.id,
                isCreate: true,
                preNodeId: id
            }, callback)            
        }

        const onShowChatCondition = () => {
            setNodeEditSection(NODE_EDIT_SECTION_TYPE.CONDITIONS)
        }

        const onShowResponse = () => {
            setNodeEditSection(NODE_EDIT_SECTION_TYPE.RESPONSE)
        }

        const onShowAction = () => {
            setNodeEditSection(NODE_EDIT_SECTION_TYPE.ACTION)
        }

        newNodes.push({...NODE_TEMPALTE.start, style: {...NODE_SIZE.start}})

        sortedNodeList.forEach(node => {

            let response = node.chatbotNodeConditionProcessList[0].responseId ? (responseNameMap[node.chatbotNodeConditionProcessList[0].responseId] ?? "") : ""
            let action = ACTION_TYPE_NAME[node.chatbotNodeConditionProcessList[0].afterAction] ? intl.formatMessage({ "id": ACTION_TYPE_NAME[node.chatbotNodeConditionProcessList[0].afterAction] }) : ""
            let chatCondition = ""
            let chatConditionType = ""

            if(node.isAcceptAnyMessage){
                chatCondition = intl.formatMessage({ "id": CONDITION_TYPE_NAME.ANY_MESSAGE })
                chatConditionType = "ANY_MESSAGE"
            }
            else if(node.isAcceptMedia){
                chatCondition = intl.formatMessage({ "id": CONDITION_TYPE_NAME.ANY_MEDIA })
                chatConditionType = "ANY_MEDIA"
            }
            else{
                if(!!node.keywordIdSet && node.keywordIdSet.length > 0){
                    chatCondition = (keywordNameMap[node.keywordIdSet[0]] ?? "")
                    chatConditionType = "KEYWORDS"
                }
                else if (Object.keys(node.entityIdValueMap).length > 0){
                    chatCondition = (entityNameMap[Object.keys(node.entityIdValueMap)[0]] ?? "")
                    chatConditionType = "ENTITY"
                }
                else if (!!node.executeCondition){
                    chatCondition = node.executeCondition.parameterName
                    chatConditionType = "PARAMETER"
                }
            }

            newNodes.push({
                ...NODE_TEMPALTE.normal,
                id: node.id,
                style: {...NODE_SIZE.normal},
                data: {
                    selected: !!selectedNode && (selectedNode.id === node.id),
                    name: node.name,
                    chatCondition,
                    chatConditionType,
                    response,
                    action,
                    onSelectNode: (callback) => onSelectNode(node, callback),
                    onShowChatCondition,
                    onShowResponse,
                    onShowAction
                }
            })
        })

        if(selectedFlow.type !== "RELEASED"){
            if(sortedNodeList.length < 1)
                newNodes.push({
                    ...NODE_TEMPALTE.add2,
                    id: "ADD_NODE_2",
                    style: {...NODE_SIZE.add2},
                    data: {selected: !!selectedNode && selectedNode.isCreate && (selectedNode.preNodeId === ""),
                    onAddNode: (callback) => onAddNode("", callback),
                    onShowChatCondition,
                    onShowResponse,
                    onShowAction
                }})

            newNodes.push({...NODE_TEMPALTE.add, id: "ADD_NODE", style: {...NODE_SIZE.add}, data: {selected: !!selectedNode && selectedNode.isCreate && (selectedNode.preNodeId === ""), onAddNode: (callback) => onAddNode("", callback)}})
        }


        let newEdges = [];

        sortedNodeList.forEach(node => {
            if(node.preNodeId && nodeIdList.includes(node.preNodeId)){
                newEdges.push({
                    ...EDGE_TEMPLATE,
                    id: `${node.preNodeId}-${node.id}`,
                    source: node.preNodeId,
                    target: node.id,
                })
            }
            else{
                newEdges.push({
                    ...EDGE_TEMPLATE,
                    id: `START_NODE-${node.id}`,
                    source: "START_NODE",
                    target: node.id,
                })

            }

        })

        sortedNodeList.forEach(node => {
            if(selectedFlow.type !== "RELEASED"){
                newNodes.push({...NODE_TEMPALTE.add, id: `ADD_NODE_${node.id}`, style: {...NODE_SIZE.add}, data: {selected: !!selectedNode && selectedNode.isCreate && (selectedNode.preNodeId === node.id), onAddNode: (callback) => onAddNode(node.id, callback)}})

                newEdges.push({
                    ...EDGE_TEMPLATE,
                    id: `${node.id}-ADD_NODE_${node.id}`,
                    source: node.id,
                    target: `ADD_NODE_${node.id}`,
                })
            }

        })


        if(selectedFlow.type !== "RELEASED"){

            if(sortedNodeList.length < 1){
                newEdges.push({
                    ...EDGE_TEMPLATE,
                    id: `START_NODE-ADD_NODE_2`,
                    source: "START_NODE",
                    target: "ADD_NODE_2",
                })

                newEdges.push({
                    ...EDGE_TEMPLATE,
                    id: `ADD_NODE_2-ADD_NODE`,
                    source: "ADD_NODE_2",
                    target: "ADD_NODE",
                })

            }
            else{
                newEdges.push({
                    ...EDGE_TEMPLATE,
                    id: `START_NODE-ADD_NODE`,
                    source: "START_NODE",
                    target: "ADD_NODE",
                })
            }
    
        }

        console.log(newNodes)

        initAutoLayout(newNodes, newEdges, setNodes, setEdges)
    }
    
    useEffect(() => {
        console.log("isEdit")
    }, [isEdit])
    
    useEffect(() => {
        console.log("selectedFlow")
    }, [selectedFlow])
    
    useEffect(() => {
        console.log("selectedNode")
    }, [selectedNode])
    
    useEffect(() => {
        console.log("nodeList")
    }, [nodeList])

    useEffect(() => {
        // setPreNodeId("")
        handleOnViewFlow()
    }, [isEdit, selectedFlow, selectedNode, nodeList])

    useEffect(() => {
        setPreNodeId("")
    }, [selectedFlow, selectedNode])

    return (
                <div className="w-full h-full">
                    <ReactFlowProvider>
                        <ReactFlow
                            nodes={nodes}
                            edges={edges}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            // onClick={() => selectNode(null)}
                            onInit={(data) => console.log(data)}
                            style={{ background: "fff" }}
                            nodeTypes={nodeTypes}
                            // edgeTypes={edgeTypes}
                            connectionLineStyle={{ stroke: "#fff" }}
                            snapToGrid={true}
                            snapGrid={[20, 20]}
                            defaultzoom={1}
                            maxZoom={1.25}
                            minZoom={0.25}
                            // fitView
                            attributionPosition="bottom-left"
                            nodesDraggable={false}
                            nodesConnectable={false}
                            // elementsSelectable={false}
                            noDragClassName="noDrag"
                            noWheelClassName="noWheel"
                            noPanClassName="noPan"
                            nodesFocusable={false}
                            edgesFocusable ={false}    
                            disableKeyboardA11y={true}
                        >
                            {/* <MiniMap
                                zoomable
                                pannable
                                position="bottom-left"
                                nodeBorderRadius={9999}
                                nodeColor={(node) => `${node.type === "add" ? "#fff" : "currentColor"}`}
                                nodeStrokeColor="currentColor"
                                nodeClassName={(node) => `w-[100px] h-[100px] ${NODE_COLOR[node.type]}`}
                            /> */}
                            <SetCenterHandler data={flowCenter} />
                        </ReactFlow>
                    </ReactFlowProvider>
                    <NodeOptionDropdown
                        showMenu={showMenu}
                        setShowMenu={setShowMenu}
                        menuPos={menuPos}
                        setMenuPos={setMenuPos}
                        nodeAction={() => selectNode({
                            flowId: selectedFlow.id,
                            isCreate: true,
                            preNodeId: preNodeId
                        })}
                    />
                </div>        
    )
}

const SetCenterHandler = ({ data }) => {
    const store = useStoreApi();
    const { zoomIn, zoomOut, setCenter, fitView } = useReactFlow();

    useEffect(() => {
        console.log(data)
        if (!data)
            return;
        else {
            console.log(data.x, data.y)
            setCenter(data.x, data.y, { zoom: data.zoom ? data.zoom : 1 })
        }
    }, [data])

    return <></>
}

export default FlowGraphPanel;