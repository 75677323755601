import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const Avatar = ({
    name = '',
    url = '',
    outline = false,
    bgColor = "bg-grey03",
    textColor = "text-grey07",
    ...props
}) => {

    const [portraitClassName, setPortraitClassName] = useState("");
    const imgRef = useRef(null);

    const imgSize = () => {
        console.log(imgRef.current.naturalWidth, imgRef.current.naturalHeight)

        if(imgRef.current.naturalWidth > imgRef.current.naturalHeight)
            setPortraitClassName("max-w-none w-auto max-h-[100%] h-full")
        else
            setPortraitClassName("")
    }

    return (
        <div className={`${bgColor} ${outline ? "outline-2 outline outline-white" : ""} w-full h-full flex justify-center items-center rounded-full overflow-hidden`} {...props}>
            {url ?
                <img onLoad={imgSize} ref={imgRef} className={`object-cover ${portraitClassName}`} alt={name.substring(0,2)} src={url} />
                : <div className={`${textColor}`}>{name.substring(0,2)}</div>
            }
        </div>
    )
}

Avatar.propTypes = {
    name: PropTypes.string,
    url: PropTypes.string,
    outline: PropTypes.bool,
    bgColor: PropTypes.string,
    textColor: PropTypes.string,
};

export default Avatar;