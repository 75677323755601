import {
    SET_FLOW,
    SET_FLOW_SUCCESS,
    SET_FLOW_FAILURE,
    GET_FLOWS,
    GET_FLOWS_FAILURE,
    GET_FLOWS_SUCCESS,
    CREATE_FLOW,
    CREATE_FLOW_FAILURE,
    CREATE_FLOW_SUCCESS,
    CLONE_FLOW,
    CLONE_FLOW_FAILURE,
    CLONE_FLOW_SUCCESS,
    PUBLISH_FLOW,
    PUBLISH_FLOW_FAILURE,
    PUBLISH_FLOW_SUCCESS,
    IMPORT_FLOW,
    IMPORT_FLOW_FAILURE,
    IMPORT_FLOW_SUCCESS,
    EXPORT_FLOW,
    EXPORT_FLOW_FAILURE,
    EXPORT_FLOW_SUCCESS,
    REMOVE_FLOW,
    REMOVE_FLOW_FAILURE,
    REMOVE_FLOW_SUCCESS,
    CLEAR_PUBLISH_FLOW_MSG,
} from "store/reducers/setting/automation/chatbot/flow";

import {
    getFlow as getFlowService,
    createFlow as createFlowService,
    cloneFlow as cloneFlowService,
    publishFlow as publishFlowService,
    importFlow as importFlowService,
    exportFlow as exportFlowService,
    removeFlow as removeFlowService,
} from "services/chatbot/flow";
import { isJson, isSuccessResponse } from "util/helper";
import { GET_KEYWORDS_SUCCESS } from "store/reducers/setting/automation/chatbot/keyword";
import { GET_PARAMETERS_SUCCESS } from "store/reducers/setting/automation/chatbot/parameter";
import { GET_ENTITYS_SUCCESS } from "store/reducers/setting/automation/chatbot/entity";
import { GET_RESPONSES_SUCCESS } from "store/reducers/setting/automation/chatbot/response";
import { GET_ACTIONS_SUCCESS } from "store/reducers/setting/automation/chatbot/action";
import { GET_NODES_SUCCESS, SELECT_NODE } from "store/reducers/setting/automation/chatbot/node";
import { handleAuthError } from "store/actions/auth/user";
import { ERROR_REPSONSE_LOCALE_MAP, ERROR_RESPONSE_MAP, getErrorByLocale } from "util/ErrorResponse";
import { errorPrefix } from "lang/locales/prefix";
import { getToken } from "store/reducers/auth/user";

export const selectFlow = (data) => (dispatch, getState) => {
    if(!data){
        dispatch(SET_FLOW_SUCCESS(null))
        dispatch(SELECT_NODE(null))
        return;
    }

    dispatch(SET_FLOW());

    if(!data.id){
        dispatch(SET_FLOW_FAILURE({error: 'no existing flow'}))
        return;
    }

    let authToken = getToken(getState().auth.user)
    exportFlowService({id: data.id}, authToken)
    .then(response => {
        const bodyString = response.data.body;
        const body = isJson(bodyString) ? JSON.parse(bodyString) : bodyString;

        console.log(body);
        
        if(isSuccessResponse(response)) {
            dispatch(GET_KEYWORDS_SUCCESS(body.keywordList))
            dispatch(GET_PARAMETERS_SUCCESS(body.parameterList))
            dispatch(GET_ENTITYS_SUCCESS(body.entityList))
            dispatch(GET_RESPONSES_SUCCESS(body.responseList))
            dispatch(GET_ACTIONS_SUCCESS(body.actionList))
            dispatch(GET_NODES_SUCCESS(body.nodeList))
            dispatch(SELECT_NODE(null))
            dispatch(SET_FLOW_SUCCESS(data))
        }else {
            if(body === ERROR_RESPONSE_MAP.AUTH){
                dispatch(handleAuthError())
                return
            }
            if(body === ERROR_RESPONSE_MAP.PERMISSION){
                dispatch(SET_FLOW_FAILURE({error: getErrorByLocale(getState, ERROR_REPSONSE_LOCALE_MAP.PERMISSION)}))
                return
            }

            dispatch(SET_FLOW_FAILURE({error: body.message }));
        }
    })
    .catch(error=> {
        const message = (typeof error.response === "string") ? error.response : 'System Error, Please contact admin for more information'
        dispatch(SET_FLOW_FAILURE({error: message}))
    })    
}

export const getFlowList = () => (dispatch, getState) => {
    dispatch(GET_FLOWS());

    let authToken = getToken(getState().auth.user)
    getFlowService(authToken)
    .then(response => {
        const bodyString = response.data.body;
        const body = isJson(bodyString) ? JSON.parse(bodyString) : bodyString;

        console.log(body)

        if(isSuccessResponse(response)) {
            dispatch(GET_FLOWS_SUCCESS(body));
        } else {
            if(body === ERROR_RESPONSE_MAP.AUTH){
                dispatch(handleAuthError())
                return
            }
            if(body === ERROR_RESPONSE_MAP.PERMISSION){
                dispatch(GET_FLOWS_FAILURE({error: getErrorByLocale(getState, ERROR_REPSONSE_LOCALE_MAP.PERMISSION)}))
                return
            }

            dispatch(GET_FLOWS_FAILURE({error: body.message }));
        }

    })
    .catch(error=> {
        const message = (typeof error.response === "string") ? error.response : 'System Error, Please contact admin for more information'
        dispatch(GET_FLOWS_FAILURE({error: message}))
    })

}
 
export const createFlow = (data) => (dispatch, getState) => {

    dispatch(CREATE_FLOW());

    console.log(data)
    
    let authToken = getToken(getState().auth.user)
    createFlowService(data, authToken)
    .then(response => {
        const bodyString = response.data.body;
        const body = isJson(bodyString) ? JSON.parse(bodyString) : bodyString;

        console.log(body);
        
        if(isSuccessResponse(response)) {
            dispatch(CREATE_FLOW_SUCCESS(body))
            dispatch(selectFlow(body))
        }else {
            if(body === ERROR_RESPONSE_MAP.AUTH){
                dispatch(handleAuthError())
                return
            }
            if(body === ERROR_RESPONSE_MAP.PERMISSION){
                dispatch(CREATE_FLOW_FAILURE({error: getErrorByLocale(getState, ERROR_REPSONSE_LOCALE_MAP.PERMISSION)}))
                return
            }

            dispatch(CREATE_FLOW_FAILURE({error: body.message }));
        }
    })
    .catch(error=> {
        const message = (typeof error.response === "string") ? error.response : 'System Error, Please contact admin for more information'
        dispatch(CREATE_FLOW_FAILURE({error: message}))
    })

}

export const cloneFlow = (data) => (dispatch, getState) => {

    dispatch(CLONE_FLOW());

    console.log(data)
    
    let authToken = getToken(getState().auth.user)
    cloneFlowService(data, authToken)
    .then(response => {
        const bodyString = response.data.body;
        const body = isJson(bodyString) ? JSON.parse(bodyString) : bodyString;

        console.log(body);
        
        if(isSuccessResponse(response)) {
            dispatch(CLONE_FLOW_SUCCESS())
            dispatch(getFlowList())
        }else {
            if(body === ERROR_RESPONSE_MAP.AUTH){
                dispatch(handleAuthError())
                return
            }
            if(body === ERROR_RESPONSE_MAP.PERMISSION){
                dispatch(CLONE_FLOW_FAILURE({error: getErrorByLocale(getState, ERROR_REPSONSE_LOCALE_MAP.PERMISSION)}))
                return
            }

            dispatch(CLONE_FLOW_FAILURE({error: body.message }));
        }
    })
    .catch(error=> {
        const message = (typeof error.response === "string") ? error.response : 'System Error, Please contact admin for more information'
        dispatch(CLONE_FLOW_FAILURE({error: message}))
    })

}

export const clearPublishFlowNotice = () => (dispatch, getState) => {
    dispatch(CLEAR_PUBLISH_FLOW_MSG());
}

export const publishFlow = (data) => (dispatch, getState) => {

    dispatch(PUBLISH_FLOW());

    console.log(data)
    
    let authToken = getToken(getState().auth.user)
    publishFlowService(data, authToken)
    .then(response => {
        const bodyString = response.data.body;
        const body = isJson(bodyString) ? JSON.parse(bodyString) : bodyString;

        console.log(body);
        
        if(isSuccessResponse(response)) {
            dispatch(PUBLISH_FLOW_SUCCESS(body))
            dispatch(selectFlow(body))

            setTimeout(() => {
                dispatch(clearPublishFlowNotice())
            }, 5000);

        }else {
            if(body === ERROR_RESPONSE_MAP.AUTH){
                dispatch(handleAuthError())
                return
            }
            if(body === ERROR_RESPONSE_MAP.PERMISSION){
                dispatch(PUBLISH_FLOW_FAILURE({error: getErrorByLocale(getState, ERROR_REPSONSE_LOCALE_MAP.PERMISSION)}))
                return
            }

            dispatch(PUBLISH_FLOW_FAILURE({error: body.message }));
        }
    })
    .catch(error=> {
        const message = (typeof error.response === "string") ? error.response : 'System Error, Please contact admin for more information'
        dispatch(PUBLISH_FLOW_FAILURE({error: message}))
    })

}

export const importFlow = (data) => (dispatch, getState) => {

    dispatch(IMPORT_FLOW());

    console.log(data)
    
    let authToken = getToken(getState().auth.user)
    importFlowService(data, authToken)
    .then(response => {
        const bodyString = response.data.body;
        const body = isJson(bodyString) ? JSON.parse(bodyString) : bodyString;

        console.log(body);
        
        if(isSuccessResponse(response)) {
            dispatch(IMPORT_FLOW_SUCCESS())
            dispatch(getFlowList())
        }else {
            if(body === ERROR_RESPONSE_MAP.AUTH){
                dispatch(handleAuthError())
                return
            }
            if(body === ERROR_RESPONSE_MAP.PERMISSION){
                dispatch(IMPORT_FLOW_FAILURE({error: getErrorByLocale(getState, ERROR_REPSONSE_LOCALE_MAP.PERMISSION)}))
                return
            }

            dispatch(IMPORT_FLOW_FAILURE({error: body.message }));
        }
    })
    .catch(error=> {
        const message = (typeof error.response === "string") ? error.response : 'System Error, Please contact admin for more information'
        dispatch(IMPORT_FLOW_FAILURE({error: message}))
    })

}

export const exportFlow = ({id}) => (dispatch, getState) => {

    dispatch(EXPORT_FLOW());

    let authToken = getToken(getState().auth.user)
    exportFlowService({id}, authToken)
    .then(response => {
        const bodyString = response.data.body;
        const body = isJson(bodyString) ? JSON.parse(bodyString) : bodyString;

        console.log(body);
        
        if(isSuccessResponse(response)) {
            dispatch(EXPORT_FLOW_SUCCESS())

            let downloadElement = document.createElement('a');
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
            // This  encodeURI encodes special characters, except: , / ? : @ & = + $ # (Use encodeURIComponent() to encode these characters).
            downloadElement.href = 'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(body));
            downloadElement.target = '_self';
            downloadElement.download = body.flow.name + '.json';
            document.body.appendChild(downloadElement);
            downloadElement.click();  
    

        }else {
            if(body === ERROR_RESPONSE_MAP.AUTH){
                dispatch(handleAuthError())
                return
            }
            if(body === ERROR_RESPONSE_MAP.PERMISSION){
                dispatch(EXPORT_FLOW_FAILURE({error: getErrorByLocale(getState, ERROR_REPSONSE_LOCALE_MAP.PERMISSION)}))
                return
            }

            dispatch(EXPORT_FLOW_FAILURE({error: body.message }));
        }
    })
    .catch(error=> {
        const message = (typeof error.response === "string") ? error.response : 'System Error, Please contact admin for more information'
        dispatch(EXPORT_FLOW_FAILURE({error: message}))
    })

}

export const removeFlow = ({id}) => (dispatch, getState) => {

    dispatch(REMOVE_FLOW());

    console.log(id)

    if(!id){
        dispatch(REMOVE_FLOW_FAILURE({error: 'no existing flow'}))
        return;
    }

    let authToken = getToken(getState().auth.user)
    removeFlowService({id}, authToken)
    .then(response => {
        const bodyString = response.data.body;
        const body = isJson(bodyString) ? JSON.parse(bodyString) : bodyString;

        console.log(body);
        
        if(isSuccessResponse(response)) {
            dispatch(REMOVE_FLOW_SUCCESS({}))
            dispatch(getFlowList())
        } else {
            if(body === ERROR_RESPONSE_MAP.AUTH){
                dispatch(handleAuthError())
                return
            }
            if(body === ERROR_RESPONSE_MAP.PERMISSION){
                dispatch(REMOVE_FLOW_FAILURE({error: getErrorByLocale(getState, ERROR_REPSONSE_LOCALE_MAP.PERMISSION)}))
                return
            }

            dispatch(REMOVE_FLOW_FAILURE({error: body.message }));
        }
    })
    .catch(error=> {
        const message = (typeof error.response === "string") ? error.response : 'System Error, Please contact admin for more information'
        dispatch(REMOVE_FLOW_FAILURE({error: message}))
    })

}
