
import { CusCheckBox } from 'components/CusCheckBox';
import PropTypes from 'prop-types';
import React from 'react';
import Action from './Action';
import Item from './Item';

const Header = ({
    className = "",
    itemClassName = "",
    langMap = "En",
    children = null,
    hasAction = false,
    selectable = false,
    selected = false,
    colWidths = [],
    selectAction = () => {},
}) => {

    let itemWidth = children ? Math.round(100 / children.length + hasAction) : 100

    const getItemWidthStyle = (itemWidth) => {
        if(itemWidth.exact)
            return { "width": `${itemWidth.value}` }

        return { "width": `${itemWidth.value}%` }
    }

    return (
        <>
            <colgroup>
                {selectable ?
                    <col className="w-[80px]"/>
                    : null
                }
                {children ?
                    Array(children.length + hasAction).fill().map((_, index) =>
                        <col
                            key={`col-${index}`}
                            className="truncate"
                            style={colWidths && (colWidths.length === children.length + hasAction) ? getItemWidthStyle(colWidths[index]) : getItemWidthStyle({value: itemWidth})}
                        />
                    )
                    :
                    null
                }
            </colgroup>
            <thead className={`z-[2] sticky top-0 w-full h-[64px]`}>
                <tr className={`sticky top-0 w-full h-[64px] bg-blue06 border-b-[1px] ${className}`}>
                    {/* {children} */}
                    {selectable ?
                        <Item className="rounded-tl-[5px]" key={`header-select`} onClick={() => selectAction()}>
                            <CusCheckBox
                                checked={!!selected}
                                onChange={() => {}}/>
                        </Item>
                        : null
                    }                
                    {children.map((child, index) => 
                        <Item
                            isHeader
                            key={`header-${index}`}
                            className={`h-[64px] ${!selectable && index === 0 ? "rounded-tl-[5px]" : ""}
                            ${(!hasAction && index === children.length - 1) ? "rounded-tr-[5px]" : ""}
                            ${itemClassName}
                            `}
                        >
                            {child}
                        </Item>
                    )}
                    {hasAction ?
                        <Action langMap={langMap} className="rounded-tr-[5px]" isHeader/>
                        :
                        null
                    }              
                </tr>
            </thead>  
        </>      
    )
}

Header.propTypes = {
    className: PropTypes.string,
    itemClassName: PropTypes.string,
    langMap: PropTypes.string,
    children: PropTypes.node,
    hasAction: PropTypes.bool,
    selectable: PropTypes.bool,
    selected: PropTypes.bool,
    colWidths: PropTypes.array,
    selectAction: PropTypes.func,    
};

export default Header;